import React, { useEffect } from "react";

export default function Child({ isDataLoaded, stickyIndex }) {
    let isWidthSet = false;
    useEffect(() => {
        if (isDataLoaded) { 
            document.querySelector('body').addEventListener('scroll', handleScroll);
            // createStickyHeader();

            // sticky left
            if(stickyIndex && stickyIndex !== ''){
            const tableref = document.querySelector('.table-ref');
            const table = tableref.querySelectorAll(`.table-ref th:nth-child(-n+${stickyIndex}), .table-ref td:nth-child(-n+${stickyIndex})`);
            let leftOffset = 0;
    
            table.forEach((column, index) => {
                if (index % stickyIndex === 0) leftOffset = 0; // Reset for each row 
                column.style.left = `${leftOffset}px`;
                column.style.zIndex = '10';
                column.style.position = 'sticky'
                column.style.backgroundColor = '#ffffff';
            
                leftOffset += column.offsetWidth;
            });
        }

            return () => {
                console.log("Removing event listener");
                document.querySelector('body').removeEventListener('scroll', handleScroll);
            };

          
        }
    }, [isDataLoaded]);

    const handleScroll = () => {
        const table = document.querySelector('.table-ref');
        if (!table) return;

        const tableRect = table.getBoundingClientRect();
        const stickyHeader = document.querySelector('.sticky-header');

        if (tableRect.top <= 0 && tableRect.bottom >= 30) {
            stickyHeader.classList.add('visible');
            if (!isWidthSet) {
                createStickyHeader();
                isWidthSet = true;
            }
        } else {
            stickyHeader.classList.remove('visible'); 
        }
    };


    const handleTableScroll = () => {
        const scrollLeft = document.querySelector('.MuiTableContainer-root').scrollLeft;
        const clonedThead = document.querySelector('#clonedHeader');
        if (clonedThead) {
            clonedThead.style.transform = `translateX(-${scrollLeft}px)`;
        }

        const stickyLeft = document.querySelector('.table-left-sticky'); 
        // sticky left
        if (stickyLeft) {
            const stickyColumns = clonedThead.querySelectorAll(`.table-left-sticky th:nth-child(-n+${stickyIndex})`);
            let leftOffset = 0;

            stickyColumns.forEach((column, index) => {
                if (index % stickyIndex === 0) leftOffset = 0; // Reset for each row 
                column.style.left = `calc(${scrollLeft}px + ${leftOffset}px`;
                column.style.zIndex = '12';
                column.style.position = 'sticky'
                column.style.backgroundColor = '#ffffff';
          
                leftOffset += column.offsetWidth;
            });
        }
    };

    const createStickyHeader = () => {
        const table = document.querySelector('.table-ref');
        if (!table) return;

        const thead = table.querySelector('thead');
        const clonedThead = thead.cloneNode(true);

        const originalThs = thead.querySelectorAll('th');
        const clonedThs = clonedThead.querySelectorAll('th');
        originalThs.forEach((originalTh, index) => { 
            const width = window.getComputedStyle(originalTh).width;
            clonedThs[index].style.width = width;
        });

        
  
 
        const stickyTable = document.querySelector('.table-bordered table');
        stickyTable.style.width = `${document.querySelector('.table-bordered').offsetWidth}px`;  

        clonedThead.id = 'clonedHeader';
        document.querySelector('.sticky-header').appendChild(clonedThead);
        document.querySelector('.MuiTableContainer-root').addEventListener('scroll', handleTableScroll); 

        const tableborder = document.querySelector('.table-bordered')
        console.log(tableborder)
    }; 
 
    return (
        <table className="sticky-header"></table>
    );
}
