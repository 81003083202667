import React, { useEffect } from 'react';
const Moment = require("moment");
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from "@material-ui/lab/Pagination";
import Paper from '@material-ui/core/Paper';
import Axios from "axios";
import loadingicon from "../../../images/loading.gif";
import Tooltip  from '@mui/material/Tooltip';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { toast } from "react-toastify";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";


const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});


export default function AllClientMeetingReminderErrorTable({ ObjClientMeetingReminderError }) {
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("ClientName");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [sortedColumn, setSortedColumn] = React.useState(null);
  const [IncludeArchiveMeetingError, SetIncludeArchiveMeetingError] = React.useState(false);
  const [IncludeInActiveMeetingError, SetIncludeInActiveMeetingError] = React.useState(false);
  const [AllClientMeetingErrorData, SetAllClientMeetingErrorData] = React.useState([]);
  const [Rlen, SetRlen] = React.useState(0);
  const [Flen, SetFlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [UserID, SetUserID] = React.useState(false);


  useEffect(() => {
    document.title = `All MeetingError | SalesHive`;
    var UserDetails = GetUserDetails();
    if (UserDetails != null) {
      SetUserID(UserDetails.ParentUserID);
    }
    GetMeetingErrorsList(UserDetails.ParentUserID);
  }, [Search, Page, RowsPerPage,IncludeArchiveMeetingError,IncludeInActiveMeetingError]);
  
  useEffect(() => {
    var UserDetails = GetUserDetails();
    if (UserDetails != null) {
      if (ObjClientMeetingReminderError.IsAnalyze) {
        GetMeetingErrorsList(UserDetails.ParentUserID);
      }
    }
  }, [ObjClientMeetingReminderError.FromDate,ObjClientMeetingReminderError.ToDate, ObjClientMeetingReminderError.IsAnalyze])
  
  const GetMeetingErrorsList = (UID) => {
    
    SetIsLoading(true)
    var requestData = {
      UserID: UID,
      FromDate: ObjClientMeetingReminderError.FromDate,
      ToDate: ObjClientMeetingReminderError.ToDate,
      IncludeArchiveMeetingError: IncludeArchiveMeetingError,
      IncludeInActiveMeetingError: IncludeInActiveMeetingError,
      Page: Page,
      RowsPerPage: RowsPerPage,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/meetingremindersetting/MeetingReminderErrorList",
      method: "POST",
      data: requestData,
    }).then(response => {
      if (response.data.StatusMessage == "SUCCESS") {
        
        if (response.data.PageData.length > 0) {
          SetAllClientMeetingErrorData(response.data.PageData);
          SetRlen(response.data.TotalCount);
          SetFlen(response.data.TotalCount);
          SetCountPage(response.data.PageCount);
          SetIsLoading(false)
        } else {
          SetAllClientMeetingErrorData([])
          SetRlen(0)
          SetFlen(0)
          SetCountPage(0)
          SetIsLoading(false)
        }
      } else {
        SetAllClientMeetingErrorData([])
        SetRlen(0)
        SetFlen(0)
        SetCountPage(0)
        SetIsLoading(false)
      }
    }).catch(error => {
      console.error('Error fetching Get All Client MeetingError Get:', error);
    });
  };
  

 
  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
    
    }
  };

  

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    //document.getElementById("hideloding").style.display = "block";
  };

 


  const SortData = (Field) => {
    try {
      SetIsLoading(true);
      const SearchedVal = document.getElementById("search").value;
      let SerchBox;
      let SortBy;
  
      if (SearchedVal === "") {
        SerchBox = false;
        SetFlen(false);
      } else {
        SerchBox = true;
        SetFlen(true);
      }
  
      if (SortedBy === 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
  
      SetSortField(Field);
      const requestData = {
        UserID: UserID,
        FromDate: ObjClientMeetingReminderError.FromDate,
        ToDate: ObjClientMeetingReminderError.ToDate,
        IncludeArchiveMeetingError: IncludeArchiveMeetingError,
        IncludeInActiveMeetingError: IncludeInActiveMeetingError,
        Page: Page,
        RowsPerPage: RowsPerPage,
        Field: Field,
        SortBy: SortBy,
        Search: SearchedVal,
      };
  
      Axios({
        url: CommonConstants.MOL_APIURL + "/meetingremindersetting/MeetingReminderErrorList",
        method: "POST",
        data: requestData,
      })
        .then((response) => {
          if (response.data.StatusMessage === "SUCCESS") {
            if (response.data.PageData.length > 0) {
              SetAllClientMeetingErrorData(response.data.PageData);
              SetRlen(response.data.TotalCount);
              SetFlen(response.data.TotalCount);
              SetCountPage(response.data.PageCount);
            } else {
              SetAllClientMeetingErrorData([]);
              SetRlen(0);
              SetFlen(0);
              SetCountPage(0);
            }
          } else {
            SetAllClientMeetingErrorData([]);
            SetRlen(0);
            SetFlen(0);
            SetCountPage(0);
          }
          SetIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching Get All Client MeetingError Get:", error);
         // toast.error("An error occurred. Please try again.");
          SetIsLoading(false);
        });
    } catch (error) {
    //  toast.error("An error occurred while searching. Please try again.");
      SetIsLoading(false);
    }
  };

  return (
    <div>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}

    
      <div className="row px-2">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries</label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" onKeyPress={RequestSearch} id="search" /></label>
        </div>
      </div>

      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
          <TableHead>
                <TableRow>
                  <TableCell className="w-250" onClick={() => { SortData("Date"); setSortedColumn("Date"); }}>
                    Date
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "Date" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "Date" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("ClientName"); setSortedColumn("ClientName"); }}>
                    Client Name
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "ClientName" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "ClientName" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("SalesStrategistString"); setSortedColumn("SalesStrategistString"); }}>
                    Strategist
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "SalesStrategistString" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "SalesStrategistString" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("MeetingReminderNumber"); setSortedColumn("MeetingReminderNumber"); }}>
                    Meeting Reminder Number
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "MeetingReminderNumber" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "MeetingReminderNumber" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("OpenCount"); setSortedColumn("OpenCount"); }}>
                    Error Message
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "OpenCount" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "OpenCount" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("SenderEmail"); setSortedColumn("SenderEmail"); }}>
                    Sender Email
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "SenderEmail" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "SenderEmail" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell className="w-250" onClick={() => { SortData("ReceiverEmail"); setSortedColumn("ReceiverEmail"); }}>
                  Recipient Email
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "ReceiverEmail" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "ReceiverEmail" ? "active" : null} />
                    </span>
                  </TableCell>
                  
                  {/* <TableCell>Action</TableCell> */}
                </TableRow>
              </TableHead>
            <TableBody>
              {AllClientMeetingErrorData?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                AllClientMeetingErrorData.map((row) => (
                  <TableRow>
                    <TableCell>{row?.Date != null? Moment(row?.Date).format("MM/DD/YYYY"): ""}</TableCell>

                    <TableCell>{row?.ClientName}</TableCell>
                    <TableCell>{row.SalesStrategistString}</TableCell>
                    <TableCell>{row?.MeetingReminderNumber}</TableCell>
                    <TableCell>{row?.ErrorMessage}</TableCell>
                    <TableCell>{row?.SenderEmail}</TableCell>
                    <TableCell>{row?.ReceiverEmail}</TableCell>
                   
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="row">
        <div className="col dataTables_info">
          <p>
            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
            entries
          </p>
        </div>
        <div className="col pageright">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </div>
  );
}
