import React, { useEffect } from "react";
import Axios from "axios";
import Popup from "reactjs-popup";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import AccountAllTable from "../../../_components/user/Accounts/AccountAllTable";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
const Fileupload = require("../../../_helpers/fileupload");

toast.configure();

// Create Data
function CreateData(Name, Calories, Fat, Carbs, Protein) {
  return { Name, Calories, Fat, Carbs, Protein };
}

const rows = [CreateData(""), CreateData(""), CreateData("")];

// TabPanels
function TabPanel(Props) {
  const { children, value, index, ...other } = Props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// TabPanel PropTypes
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// A11 Props
function A11yProps(Index) {
  return {
    id: `simple-tab-${Index}`,
    "aria-controls": `simple-tabpanel-${Index}`,
  };
}

// UseStyles
const UseStyles = makeStyles((Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: Theme.palette.background.paper,
    paddingTop: 20,
  },
}));

export default function SimpleTabs() {
  const Classes = UseStyles();
  const [Value, SetValue] = React.useState(0);
  const [PopupVal, SetPopup] = React.useState(false);
  const [Images, SetImages] = React.useState([]);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");

  useEffect(() => {
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
  }, []);



  // HandleChange for Value
  const HandleChange = (Event, NewValue) => {
    SetValue(NewValue);
  };

  // Close TermsConditional Modal
  const CloseTermsConditionModal = () => {
    //
    SetPopup(false);
  };

  return (
    <div className="tabdes">
      <Popup open={PopupVal} modal>
        <div className="modal-black"></div>
        <div className="filterPopup">
          <div className="paddingboxTerms">
            <div className="modal-header py-4">
              <h5>Filter</h5>
              <a className="close-ion" onClick={CloseTermsConditionModal}>
                <span aria-hidden="true" class="la la-remove"></span>
              </a>
            </div>
            <div className="modal-content bordernone">
              <div className="row">
                <div className="col-xl-6 allcentertext">
                  <i class="la la-filter iconsfonts"></i>
                  <a
                    id="lnkAddNewFilter"
                    href="javascript:void(0);"
                    class="btn btn-md btn-primary btnColor"
                  >
                    Add New Filter
                  </a>
                </div>
                <div className="col-xl-6 allcentertext">
                  <i class="la la-filter iconsfonts"></i>
                  <a
                    id="lnkAddNewFilter"
                    href="javascript:void(0);"
                    class="btn btn-md btn-primary btnColor"
                  >
                    Select from existing Filter
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>

    
        <AppBar position="static">
          <Tabs
            value={Value}
            onChange={HandleChange} variant="scrollable"
            aria-label="simple tabs example"
          >
            <Tab label="All" {...A11yProps(0)}>
              Overviewasdasd
            </Tab>
            <Tab label="Prospects" {...A11yProps(1)} />
            <Tab label="Current Clients" {...A11yProps(2)} />
            <Tab label="Previous Clients" {...A11yProps(3)} />
            <Tab label="Competitors" {...A11yProps(4)} />
            <Tab label="Investors" {...A11yProps(5)} />
            <Tab label="Partners" {...A11yProps(6)} />
            <Tab label="Unqualified" {...A11yProps(7)} />
          </Tabs>
        </AppBar>
        <div className="bg-white">
        <TabPanel value={Value} className="tagpaddnone" index={0}>
          <div className="row">
            <div className="col">
              <AccountAllTable AccountFieldType={null} AccountFieldName="All" />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={Value} index={1}>
          <div className="row">
            <div className="col">
              <AccountAllTable AccountFieldType={1} AccountFieldName="Prospects"/>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={Value} index={2}>
          <div className="row">
            <div className="col">
              <AccountAllTable AccountFieldType={2} AccountFieldName="Current Clients"/>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={Value} index={3}>
          <div className="row">
            <div className="col">
              <AccountAllTable AccountFieldType={3} AccountFieldName="Previous Clients"/>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={Value} index={4} >
          <div className="row">
            <div className="col">
              <AccountAllTable AccountFieldType={4} AccountFieldName="Competitors"/>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={Value} index={5}>
          <div className="row">
            <div className="col">
              <AccountAllTable AccountFieldType={5} AccountFieldName="Investors"/>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={Value} index={6}>
          <div className="row">
            <div className="col">
              <AccountAllTable AccountFieldType={6} AccountFieldName="Partners"/>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={Value} index={7}>
          <div className="row">
            <div className="col">
              <AccountAllTable AccountFieldType={7} AccountFieldName="Unqualified"/>
            </div>
          </div>
        </TabPanel>
      </div>
    </div>
  );
}
