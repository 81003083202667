const moment = require("moment");
import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { toast } from "react-toastify";

import { history } from "../../../_helpers";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

class AddDirectMailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reset: false,
      currentDate:new Date().toISOString().slice(0, 10),
      fields: {},
      formData: {
        MailType: "",
        Spend: "",
        Quantity: "",
        Description: "",
        OrderDate:"",
      },
      errors: {
        "MailType":'',
        "Spend":'',
        "Quantity":'',
        "Description":'',
        "MeetingDate":''
      },
      ClientID: null,
      UserID: null,
      CUserID: null,
      MeetingDate: null,
      WeeklyMeetingStatusId: null,
      DueDate: null,
      Notes: "",
      ToDoItems: "",
      MailTypeList: [],
      Role:"",
      IsButtonDisabled:false,
      IsLoading:false
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title="Add Direct Mail | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.setState({Role:Details.Role})
    }
    this.GetMailTypeDropDown();
    document.getElementById("OrderDate").valueAsDate = new Date();
    
  }

  // HandleValidation = () => {
  //   let formIsValid = true;
  //   const errors = {};

  //   // Validate Type field
  //   if (!this.state.formData.MailType) {
  //     formIsValid = false;
  //     errors.MailType = "Please select type";
  //   }else{
  //     errors.MailType = "";
  //   }

  //   // Validate Spend field
  //   if (!this.state.formData.Spend) {
  //     formIsValid = false;
  //     errors.Spend = "Please enter spend";
  //   } else if (isNaN(this.state.formData.Spend)) {
  //     formIsValid = false;
  //     errors.Spend = "Spend field must be a number";
  //   }else if (this.state.formData.Spend <= 0){
  //     formIsValid = false;
  //     errors.Spend = "Please enter a value greater than or equal to 1.";
  //   }else{
  //     errors.Spend = "";
  //   }

  //   // Validate Quantity field
  //   if (!this.state.formData.Quantity) {
  //     formIsValid = false;
  //     errors.Quantity = "Quantity field is required";
  //   } else if (isNaN(this.state.formData.Quantity)) {
  //     formIsValid = false;
  //     errors.Quantity = "Quantity field must be a number";
  //   }else if (this.state.formData.Quantity <= 0){
  //     formIsValid = false;
  //     errors.Quantity = "Please enter a value greater than or equal to 1.";
  //   }else{
  //     errors.Quantity = "";
  //   }

  //   // Validate Description field
  //   if (!this.state.formData.Description) {
  //     formIsValid = false;
  //     errors.Description = "Description field is required";
  //   }else{
  //     errors.Description = "";
  //   }

  //   this.setState({ errors });

  //   return formIsValid;
  // };

  HandleBlur = (event) => {
    const { name, value } = event.target;
    const { errors } = this.state;
    if (!value) {
      errors[name] = `Please enter ${name.toLowerCase()}`;
    } else {
  
      if (name === "Spend" || name === "Quantity") {
        if (isNaN(value)) {
          errors[name] = `${name} field must be a number`;
        } else if (value <= 0){
          errors[name] = "Please enter a value greater than or equal to 1."
        } else if (name === "Quantity" && !Number.isInteger(Number(value))) {
          errors[name] = "Quantity should be a whole number, no decimals allowed";
        }
        else {
          delete errors[name];
        }
      } else {
        delete errors[name];
      }
    }
    this.setState({errors})
  }
  
  // Get Mail Type Drop Down
  GetMailTypeDropDown() {
    this.setState({IsLoading:true})
    Axios({
      url: CommonConstants.MOL_APIURL + "/directmail/MailTypeGet",
      method: "POST",
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        const data = res.data.Data;
        const option = data.map((d) => ({
          FieldID: d.FieldID,
          FieldName: d.FieldName,
        }));
        this.setState({ MailTypeList: option });
            this.setState({IsLoading:false})

      } else {
        toast.error(res.data.Message);
            this.setState({IsLoading:false})

      }
    });
  }

  HandleChange = (event) => {
  //  console.log("")
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    console.log(formData,"formDatafsd")
    this.setState({ formData });
  };
  // Form Validation
  FormValidation() {
    let formIsValid = false;
    let MailIsValid = true;
    let QuantityIsValid = true;
    let SpendIsValid = true;
    let DescriptionIsValid = true;
    let OrderDateIsValid = true;
    var MailType = document.getElementById("MailType").value;
    var Quantity = document.getElementById("Quantity").value;
    var Spend = document.getElementById("Spend").value;
    var Description = document.getElementById("Description").value;
    var OrderDate = document.getElementById("OrderDate").value;
    
    let errors = {};
    if (MailType == undefined || MailType == null || MailType == "" || MailType == "--Select--") {
      MailIsValid = false;
      errors["MailType"] = "Please select type";
    } else {
      MailIsValid = true;
    }

    if (Spend === "") {
      SpendIsValid = false;
      errors["Spend"] = "Please enter spend";
    } else if (Spend <= 0) {
      SpendIsValid = false;
      errors["Spend"] = "Please enter a value greater than or equal to 1.";
    }else {
      SpendIsValid = true;
    }

    if (Quantity == null || Quantity == undefined || Quantity == "") {
      QuantityIsValid = false;
      errors["Quantity"] = "Please enter quantity";
    } else if (Quantity <= 0) {
      QuantityIsValid = false;
      errors["Quantity"] = "Please enter a value greater than or equal to 1.";
    }else if (!Number.isInteger(Number(Quantity))) {
      QuantityIsValid = false;
      errors["Quantity"] = "Quantity should be a whole number, no decimals allowed";
    }else {
      QuantityIsValid = true;
    }
    if (Description == null || Description == undefined || Description == "") {
      DescriptionIsValid = false;
      errors["Description"] = "Please enter description";
    } else {
      DescriptionIsValid = true;
    }
    if (OrderDate == null || OrderDate == undefined || OrderDate == "") {
      OrderDateIsValid = false;
      errors["OrderDate"] = "Please enter OrderDate";
    } else {
      OrderDateIsValid = true;
    }
    if (
      MailIsValid &&
      QuantityIsValid &&
      SpendIsValid &&
      DescriptionIsValid &&
      OrderDateIsValid
    ) {
      formIsValid = true;
    } else {
      formIsValid = false;
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  // handlechange
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    var ErrorData = {...this.state.errors};
    this.setState({ fields });
    if (fields.MailType != "" && fields.MailType != null && fields.MailType.indexOf(" ") != 0 && fields.MailType != undefined) {
      ErrorData.MailType = null;
    } else {
      ErrorData.MailType = "Please select type";
    }
    if (fields.Spend != "" && fields.Spend != null && fields.Spend.indexOf(" ") != 0 && fields.Spend != undefined) {
      ErrorData.Spend = null;
    } else {
      ErrorData.Spend = "Please enter spend";
    }
    if (fields.Quantity != "" && fields.Quantity != null && fields.Quantity.indexOf(" ") != 0 && fields.Quantity != undefined) {
      ErrorData.Quantity = null;
    } else {
      ErrorData.Quantity = "Please enter quantity";
    }
    if (fields.Description != "" && fields.Description != null && fields.Description.indexOf(" ") != 0 && fields.Description != undefined) {
      ErrorData.Description = null;
    } else {
      ErrorData.Description = "Please enter description";
    }
    this.setState({errors:ErrorData})
  }

  BackBtn() {
    history.push("/directmail");
  }

  //Save Direct mail in Database
  SaveBtn(e) {
    this.setState({IsButtonDisabled:true});
    if (this.FormValidation()) {
      this.setState({IsButtonDisabled:true});
      var MailType = document.getElementById("MailType").value;
      var Spend = document.getElementById("Spend").value;
      var OrderDate = document.getElementById("OrderDate").value;
      var Quantity = document.getElementById("Quantity").value;
      var Description = document.getElementById("Description").value;
      var InputData = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        MailType: MailType,
        Quantity: Quantity,
        Spend: Spend,
        Description: Description,
        OrderDate: OrderDate,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
        Role:this.state.Role

      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/directmail/DirectMailAdd",
        method: "POST",
        data: InputData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div className="toastsize">DirectMail Add<br/>DirectMail added successfully.</div>);
          history.push("/directmail");
        } else {
          toast.error(res.data.Message);
        }
      });
    }else{
      this.setState({IsButtonDisabled:false});

    }
  }

  render() {
    return (
      <>
       {
        this.state.IsLoading == true ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : <></>
      }
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 

          <div className="bodyhome">

              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left py-4 mb-0">
                    Add Direct Mail{" "}
                  </h4>
                </div>
              </div>

            <div className="bg-white"> 
              <div className="row pt-4 px-4 pb-3">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-3 view-lable-meet">Type</label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field Status must be a number."
                        id="MailType"
                        name="MailType"
                        value={this.state.formData.MailType}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                        //onBlur={this.handleChange.bind(this, "MailType")}
                      >
                        <option>--Select--</option>
                        {this.state.MailTypeList.map((val) => (
                          <option key={val.FieldID} value={val.FieldID}>{val.FieldName}</option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["MailType"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  px-4">
                    <label className="col-lg-3 view-lable-meet">Spend</label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Spend"
                        name="Spend"
                        placeholder="Enter spend"
                        type="number"
                       // min="1"
                        value={this.state.formData.Spend}
                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Spend"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-3 view-lable-meet">Order Date</label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="OrderDate"
                        name="OrderDate"
                        placeholder="Enter Order date"
                        type="date"
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["OrderDate"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6   boxsinput_group ">
                  <div className="row max-cols  px-4">
                    <label className="col-lg-3 view-lable-meet ">Quantity</label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Quantity"
                        name="Quantity"
                        placeholder="Enter quantity"
                        type="number"
                        min="1"
                        value={this.state.formData.Quantity}
                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                       <span style={{ color: "red" }}>
                      {this.state.errors["Quantity"]}
                    </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-3 view-lable-meet">Description</label>
                    <div className="col-lg-7">
                      <textarea
                        className="form-control m-input"
                        type="text"
                        id="Description"
                        name="Description"
                        value={this.state.formData.Description}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      ></textarea>
                      <span style={{ color: "red" }}>
                        {this.state.errors["Description"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div> 
            </div>

              <div className="row mb-5 py-4">
                <div className="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.IsButtonDisabled}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddDirectMailPage = connect(
  mapState,
  actionCreators
)(AddDirectMailPage);
export { connectedAddDirectMailPage as AddDirectMailPage };
