import React, { useEffect } from "react";
import { CSVDownloader } from "react-papaparse";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

toast.configure();

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable({ ToDate, FromDate }) {
  const [Rows, SetRows] = React.useState([]);
  const [ExportData, SetExportData] = React.useState([]);
  const [Search, SetSearch] = React.useState("");
  const [SortValue, SetSortValue] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [ParentUserID, SetParentUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsLoading,SetIsLoading] = React.useState(true)

  useEffect(() => {
    var ResearchPageUserInfo = GetUserDetails();
    if (ResearchPageUserInfo != null) {
      SetClientID(ResearchPageUserInfo.ClientID);
      SetUserID(ResearchPageUserInfo.ChildUserID);
      SetParentUserID(ResearchPageUserInfo.ParentUserID);
    }
    GetResponsesofWeek(
      ResearchPageUserInfo.ParentUserID,
      ResearchPageUserInfo.ClientID
    );
  }, [ClientID, Role, Search, ToDate, FromDate]);

  //get insight page in Responses of week
  const GetResponsesofWeek = (PUID, CID) => {
    SetIsLoading(true)
    function getPreviousMonth() {
      const date = new Date();
      const prevMonth = date.getMonth() - 1;
      const firstDay = date.getDate() - 1;

      return new Date(date.getFullYear(), prevMonth, firstDay);
    }
    const  FromDate1  = new Date(
      getPreviousMonth()
    )
    var InputParameter = {
      Search: Search,
      ToDate: ToDate!=""?ToDate:new Date(),
      FromDate: FromDate!=""?FromDate:FromDate1,
      Type: "User",
      ClientID: CID,
      UserID: PUID,
      Role: Role,
    };
    // console.log(InputParameter)
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/insight/ResponsesDayOfWeekGet",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      // console.log(Result)
      SetRows(Result.data.PageData);
      SetExportData(Result.data.PageData);
      SetIsLoading(false)
    });
  };

  const ExportBtn = () => {
    toast.success(<div>Data exported successfully.</div>);
  };
  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true)
    if (SortValue == 1) {
      Rows.sort(function (a, b) {
        return parseFloat(a.Response) - parseFloat(b.Response);
      });
      SetSortValue(-1);
      SetRows(Rows);
      SetIsLoading(false)
    } else if (SortValue == -1) {
      Rows.sort(function (a, b) {
        return parseFloat(b.Response) - parseFloat(a.Response);
      });
      SetSortValue(1);
      SetRows(Rows);
      SetIsLoading(false)
    }
  };

  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      let lowercaseSearchedVal = SearchedVal.toLowerCase(); // Convert the search value to lowercase
  
      // Filter the original data based on the search and reset to the original data if the search input is empty
      var Result = SearchedVal !== ""
        ? ExportData.filter(function (el) {
            // Convert the "Day" and "Response" properties to lowercase for a case-insensitive match
            return (
              (el.Day.toLowerCase() === lowercaseSearchedVal || el.Day.toLowerCase().startsWith(lowercaseSearchedVal)) ||
              (el.Response.toString().toLowerCase() === lowercaseSearchedVal) // Case-insensitive match for Response
            );
          })
        : ExportData; // Use ExportData to reset to the original data
  
      SetRows(Result);
    }
  };
  

  return (
    <div>
       {
        IsLoading ?  <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>:<></>
      }
      <div className="row pl-4 d-flex align-items-center justify-content-center pt-3 pb-1 mb-2">
        <div className="col ">
          <h4 className="headertitlenop float-left py-3 px-2">
            Responses day of week{" "}
          </h4>
        </div>
        <div className="col ">
          <div className="listing-li float-right ">
            <ul className="mt-0">
              <li>
                <CSVDownloader
                  data={ExportData}
                  filename={"ResponseDayOfWeek"}
                  bom={true}
                >
                  <a
                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                    onClick={ExportBtn}
                  >
                    <i class="la la-icon-font-size-13 la-download"></i>
                    <span>Export</span>
                  </a>
                </CSVDownloader>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="px-4">
        <div className="row border-top pt-4">
          <div className="col-sm-12 col-md-6"></div>
          <div className="col-sm-12 col-md-6 full-right">
            <label className="textlabelte">
              Search:
              <input
                type="search"
                id="search"
                onKeyPress={RequestSearch}
                className="form-control form-control-sm ml-2"
                placeholder=""
                aria-controls="tbl_meeting"
              />
            </label>
          </div>
        </div>
        <div className="table-bordered">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>Day</TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        SortData("Response");
                      }}
                    >
                      Response
                    </a>
                  </TableCell>
                  {/* <TableCell>Response</TableCell> */}
                  <TableCell>Response %</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Rows?.length === 0 ? (
                  <p className="text-center">No data available in table</p>
                ) : (
                  Rows.map((row) => (
                    <TableRow key={row.name} row={row}>
                      <TableCell>{row.Day}</TableCell>
                      <TableCell>{row.Response}</TableCell>
                      <TableCell>
                        {typeof row.ResponsePercentage === "number"
                          ? row.ResponsePercentage.toFixed(2)
                          : row.ResponsePercentage}{" "}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
