import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { toast } from "react-toastify";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import "react-toastify/dist/ReactToastify.css";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { CommonConstants } from "../../../_constants/common.constants";
import { arrayMoveImmutable } from "array-move";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers/history";
import loadingicon from "../../../images/loading.gif";

toast.configure();

//Sorted each item
const SortableItem = SortableElement(({ value }) => (
  <li className="boxcorder">{value}</li>
));

//Sorted List name
const SortableList = SortableContainer(({ Items }) => {
  return (
    <ul>
      {Items?.map((Value, Index) => (
        <SortableItem
          id={Index}
          key={`item-${Index}`}
          index={Index}
          value={Value.Name}
        />
      ))}
    </ul>
  );
});

class CAccountStatusOrderPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Items: [],
      Pager: {},
      PageOfItems: [],
      Files: [],
      List: [],
      Columns: [],
      ReSet: false,
      ClientID: null,
      UserID: null,
      Role: null,
      BtnDisabled:false
    };
    this.SaveOrder = this.SaveOrder.bind(this);
    this.BackBtn = this.BackBtn.bind(this);
  }
  componentDidMount() {
    document.title= "Account Client Status Order | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.Role = Details.Role;
    }
    this.GetAccountClientSetOrder(Details.ClientID,Details.Role);
  }

  //Get set order
  GetAccountClientSetOrder(CID,Role) {
    var InputParameter = {
      Page: 1,
      RowsPerPage: 100,
      Field: "OrderBy",
      SortBy: 1,
      Type: "User",
      ClientID: CID,
      Role: Role
    };
    const Rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +"/account_client_status/AccountClientStatusGet",
      method: "POST",
      data: InputParameter,
    });
    Rows1.then((Result) => {
      this.setState({ Items: Result.data?.PageData });
      document.getElementById("hideloding").style.display = "none";
    });
  }

  //Sort End
  OnSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      Items: arrayMoveImmutable(this.state.Items, oldIndex, newIndex),
    });
  };

  //save order
  SaveOrder() {
    let OrderBy = 0;
    for (let i = 0; i < this.state.Items.length; i++) {
      this.state.Items[i].OrderBy = OrderBy + 1;
      OrderBy++;
    }
    this.SaveData();
  }

  //add order
  SaveData() {
    this.setState({BtnDisabled:true})
    let InputParameter = {
      AccountClientStatusList: this.state?.Items,
      Role:this.state.Role
    };
    const Rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/account_client_status/AccountClientStatusSetOrder",
      method: "POST",
      data: InputParameter,
    });
    Rows1.then((Result) => {
      if (Result.data.StatusMessage == "SUCCESS") {
        toast.success(<div className="toastsize" >Account client status<br/>Account client status order updated successfully.</div>);
        history.push("/CAccountStatus");
      } else {
        this.setState({BtnDisabled:false})
        toast.error(Result.data.Message);
      }
      this.GetAccountClientSetOrder(this.state.ClientID,this.state.Role);
    });
  }

  //back
  BackBtn() {
    history.push("/CAccountStatus");
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div class="paddcols">
              <div className="row borderbtnm">
                <div className="col padd-0">
                  <h4 className="headertitlepad float-left">
                    Account Client Status Order
                  </h4>
                </div>
              </div>

              <div className="row pt-4">
                <div className="col-xl-12 offset-xl-12 p-0">
                  <span className="alertinfo">
                    &nbsp;<b>NOTE</b> : Please drag &amp; drop item to change
                    order of client status and press save button.
                  </span>

                  <div class="spacupgroup">
                    <SortableList
                      Items={this.state.Items}
                      onSortEnd={this.OnSortEnd}
                    />
                  </div>
                </div>
              </div>
              <div id="hideloding" className="loding-display">
                <img src={loadingicon} />
              </div>
              <div class="row py-4 border-top">
                <div class="pull-left">
                  <button
                    id="submit"
                    disabled={this.state.BtnDisabled}
                    onClick={() => {
                      this.SaveOrder();
                    }}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save order
                  </button>
                  <a
                    id="backtoList"
                    onClick={() => {
                      this.BackBtn();
                    }}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCAccountStatusOrderPage = connect(
  mapState,
  actionCreators
)(CAccountStatusOrderPage);
export { connectedCAccountStatusOrderPage as CAccountStatusOrderPage };
