
import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  IconButton,
  Collapse,
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";

function PotentialMeeting({UserID,ClientID,Role,AccountID,Domain}) {

    const [PlistData, setPlistData] = useState([]);
  const [PlistRows, setPlistRows] = useState([]);
  const [PlRlen, setPlRlen] = useState(0);
  const [PlFlen, setPlFlen] = useState(0);
  const [PlCountPage, setPlCountPage] = useState(0);
  const [PageP, setPageP] = useState(1);
  const [RowsPerPageP, setRowsPerPageP] = useState(15);
  const [SearchP, setSearchP] = useState("");
  const [SortFieldP, setSortFieldP] = useState("CreatedDate");
  const [SortedByP, setSortedByP] = useState(-1);
  const [SflagP, setSflagP] = useState(false);
  const [Open, setOpen] = useState(false);
  const [PotentialNotes, setPotentialNotes] = useState([]);

  useEffect(() => {
    PotentialListsGet(SearchP, PageP, RowsPerPageP);
  }, [SearchP, PageP, RowsPerPageP, SortFieldP, SortedByP,Domain,AccountID,ClientID]);

  const PotentialListsGet = (search, page, rowsPerPage) => {
    if(Domain?.trim() != "" && AccountID?.trim() != "" && ClientID?.trim() != ""){
        const inputParameters = {
            ClientID: ClientID,
            Domain: Domain,
            Page: page,
            RowsPerPage: rowsPerPage,
            Sort: true,
            Field: SortFieldP,
            Role: Role,
            SortBy: SortedByP,
            Search: search,
            Type: 'User',
          };
      
          Axios({
            url: CommonConstants.MOL_APIURL + '/accounts/PotentialMeetingGet',
            method: 'POST',
            data: inputParameters,
          }).then((PListResult) => {
            setPlistData(PListResult.data?.PageData);
            setPlistRows(PListResult.data?.PageData);
            setPlRlen(PListResult.data?.TotalCount);
            setPlFlen(PListResult.data?.TotalCount);
            setPlCountPage(PListResult.data?.PageCount);
            setPotentialNotes(PListResult.data?.PotentialNotes);
          });
    }
    
  };

  const PotentialSorting = (Field) => {
    const SearchedVal = SearchP;
    const SortBy = SortedByP === 1 ? -1 : 1;

    setSortFieldP(Field);
    setSortedByP(SortBy);

    PotentialListsGet(SearchedVal, PageP, RowsPerPageP);
  };

  const RequestSearchListP = (Event) => {
    if (Event.key === 'Enter') {
      const SearchedVal = Event.target.value;
      setSearchP(SearchedVal);

      PotentialListsGet(SearchedVal, PageP, RowsPerPageP);
    }
  };

  // change display rows
 const ChangeRowSelectedListP = (Event) => {
    setRowsPerPageP(Number(Event.target.value))
    setPageP(Number(1))
    var SearchedVal = document.getElementById("PotentialMeetingSearch").value;
    setSearchP(SearchedVal)
    var InputParameter = {
      ClientID: ClientID,
      Domain: Domain,
      Page: 1,
      RowsPerPage: Number(Event.target.value),
      Sort: true,
      Field: SortFieldP,
      Role: Role,
      SortBy: SortedByP,
      Search: SearchedVal,
      Type: "User",
    };
    const AccountContactList = Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/PotentialMeetingGet",
      method: "POST",
      data: InputParameter,
    });
    AccountContactList.then((PListResult) => {
      if(PListResult.data.StatusMessage === "SUCCESS"){
        setPlistData(PListResult.data?.PageData);
        setPlistRows(PListResult.data?.PageData);
        setPlRlen(PListResult.data?.TotalCount);
        setPlFlen(PListResult.data?.TotalCount);
        setPlCountPage(PListResult.data?.PageCount);
        setPotentialNotes(PListResult.data?.PotentialNotes);
      }else{
        toast.error(PListResult.data.Message)
      }
     
    });
  };

  // change page
 const HandleChangePageListP = (Event, NewPage) => {
    if (NewPage == PageP) {
      setPageP(NewPage)
    } else {
      setPageP(NewPage)
      var SearchedVal = document.getElementById("PotentialMeetingSearch").value;
      var InputParameter = {
        ClientID: ClientID,
        Domain: Domain,
        Page: NewPage,
        RowsPerPage: RowsPerPageP,
        Sort: true,
        Field: SortFieldP,
        Role: Role,
        SortBy: SortedByP,
        Search: SearchedVal,
        Type: "User",
      };
      const AccountContactList = Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/PotentialMeetingGet",
        method: "POST",
        data: InputParameter,
      });
      AccountContactList.then((PListResult) => {
        if(PListResult.data.StatusMessage === "SUCCESS"){
          setPlistData(PListResult.data?.PageData);
          setPlistRows(PListResult.data?.PageData);
          setPlRlen(PListResult.data?.TotalCount);
          setPlFlen(PListResult.data?.TotalCount);
          setPlCountPage(PListResult.data?.PageCount);
          setPotentialNotes(PListResult.data?.PotentialNotes);
        }else{
          toast.error(PListResult.data.Message)
        }
      });
    }
  };

  // edit contact page
 const PotenialEditPages = (id) => {
    history.push({
      pathname: "/cviewpotentialmeetings",
      state: {
        data: id,
        Pagename: "AccountContact",
        AccountData: AccountID,
      },
    });
  };

  // add contact page
 const PotentialAdd = () => {
    history.push({
      pathname: "/caddpotentialmeetings",
      state: { data: AccountID, Pagename: "AccountPotential" },
    });
  };

  const ViewPotentialMeeting = (id) => {
    history.push({
        pathname: "/cviewpotentialmeetings",
        state: {
            data: id,
            Pagename: 'AccountContact',
            AccountData: AccountID,
          },
      });
  }


  return (
    <>
            <div className="row pt-3">
                <div className="col">
                  <h3 className=" float-left addpotential_header mb-0">
                    Potential meeting
                  </h3>
                </div>
                <div className="col text-right">
                  <button
                    onClick={() => {
                      PotentialAdd();
                    }}
                    className="btn btngroup m-btn "
                  >
                    <i className="la la-plus la-icon-font-size-13"></i>
                    <span className="Mobile_button">Add</span>
                  </button>
                </div>
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={ChangeRowSelectedListP}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="PotentialMeetingSearch"
                    onKeyPress={(event) => RequestSearchListP(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            PotentialSorting("ContactName");
                          }}
                        >
                          Contact Name
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            PotentialSorting("Email");
                          }}
                        >
                          Email
                        </a>
                      </TableCell>

                      <TableCell>
                        <a
                          onClick={() => {
                            PotentialSorting("Company");
                          }}
                        >
                          Company
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            PotentialSorting("Title");
                          }}
                        >
                          Title
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            PotentialSorting("EngagamentStatus");
                          }}
                        >
                          Engagement Status
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            ContactSorting("MeetingSourceName");
                          }}
                        >
                          Meeting Source Name
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            PotentialSorting("MeetingOwnerName");
                          }}
                        >
                          Owner Name
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            PotentialSorting("ReminderEmailAccount");
                          }}
                        >
                          Reminder Email Account
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            PotentialSorting("CreatedDate");
                          }}
                        >
                          Created Date
                        </a>
                      </TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {PlistRows?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      PlistRows?.map((row) => (
                        <>
                          <TableRow>
                            <TableCell align="center">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() =>
                                    setOpen((prev) => ({
                                        ...prev,
                                        [row._id]: !prev[row._id],
                                      }))
                                }
                              >
                                {Open[row._id] ? (
                                  <i
                                    className="fa fa-minus-circle minusl"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-plus-circle plusbl"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell> <a onClick={()=>{ViewPotentialMeeting(row._id)}}><u>{row?.ContactName}</u></a></TableCell>
                            <TableCell>{row.Email}</TableCell>
                            <TableCell>{row.Company}</TableCell>
                            <TableCell>{row.Title}</TableCell>
                            <TableCell>{row?.EngagamentStatus}</TableCell>
                            <TableCell>{row?.MeetingSourceName}</TableCell>
                            <TableCell>{row?.MeetingOwnerName}</TableCell>
                            <TableCell>{row?.ReminderEmailAccount}</TableCell>
                            <TableCell>
                              {row.CreatedDate
                                ? moment(
                                    new Date(row.CreatedDate).toDateString()
                                  ).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>

                            <TableCell>
                              <a
                                onClick={() => {
                                  PotenialEditPages(row._id);
                                }}
                                className="btn-eyesicon"
                              >
                                <i className="la flaticon-edit-1 edit-icon"></i>
                              </a>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={6}
                            >
                              <Collapse
                                in={Open[row._id]}
                                timeout="auto"
                                unmountOnExit
                              >
                                {Open[row._id] && (
                                  <Box margin={1} className="innertables">
                                    <Table size="small" aria-label="purchases">
                                      <TableHead></TableHead>
                                      {PotentialNotes?.map((note) =>
                                        note.PotentialMeetingID.toString() ==
                                        row._id.toString() ? (
                                          <TableRow>
                                            <TableCell><b>{note?.Title}</b></TableCell>
                                            <TableCell><b> {note.CreatedDate
                                ? moment(
                                    new Date(note.CreatedDate).toDateString()
                                  ).format("MM/DD/YYYY")
                                : ""}</b></TableCell>
                                            <TableCell scope="row">
                                              {note?.Note}
                                            </TableCell>
                                            
                                          </TableRow>
                                        ) : null
                                      )}
                                      {!PotentialNotes?.some(
                                        (note) =>
                                          note.PotentialMeetingID.toString() ==
                                          row._id.toString()
                                      ) && (
                                        <TableRow>
                                          <TableCell colSpan={4}>
                                            No data available
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </Table>
                                  </Box>
                                )}
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {/* {SflagP ? (
              <div className="row">
                <Pagination
                  component="div"
                  count={PlCountPage}
                  onChange={HandleChangePageListP}
                  showFirstButton
                  showLastButton
                />
                <div className="col dataTables_info">
                  <p>
                    Showing{" "}
                    {PlRlen == 0
                      ? 0
                      : (PageP - 1) * RowsPerPageP +
                        1}{" "}
                    to{" "}
                    {PageP * RowsPerPageP >
                    PlRlen
                      ? PlRlen
                      : PageP * RowsPerPageP}{" "}
                    of {PlRlen} entries (filtered from{" "}
                    {PlFlen} total entries)
                  </p>
                </div>
              </div>
            ) : ( */}
              <div className="row">
                <div className="col dataTables_info">
                  <p>
                    Showing{" "}
                    {PlRlen == 0
                      ? 0
                      : (PageP - 1) * RowsPerPageP +
                        1}{" "}
                    to{" "}
                    {PageP * RowsPerPageP >
                    PlRlen
                      ? PlRlen
                      : PageP * RowsPerPageP}{" "}
                    of {PlRlen} entries
                  </p>
                </div>
                <div className="col pageright">
                  <Pagination
                    component="div"
                    count={PlCountPage}
                    onChange={HandleChangePageListP}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            {/* )} */}
    </>
  )
}

export default PotentialMeeting