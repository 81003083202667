import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import Axios from "axios";
import { history } from "../../../_helpers";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class EditCustomVariablePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      NameAvailable: null,
      ClientID: null,
      UserID: null,
      PUserID: null,
      Role: null,
      VariableType: [],
      VariableTypeID: null,
      CustomVariable: {},
      IsExist: false,
      Checked: true,
      VariableTypeName: "",
      DefaultSelectChannelType: "",
      DefaultSelectEng: null,
      OldName: null,
      IsButtonDisabled: false,
      IsLoading: true,
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    var Details = GetUserDetails();
    if (Details != null) {
      this.setState({ ClientID: Details.ClientID });
      this.setState({ UserID: Details.ChildUserID });
      this.setState({ PUserID: Details.ParentUserID });
      this.setState({ Role: Details.Role });
    }
    this.CustomVariableEdit();
  }
  // form validation
  async FromValidations() {
    let FormIsValid = true;
    let Errors = {};
    var Name = document.getElementById("Name").value.trim();
    var VariableType = document.getElementById("VariableType").value;
    var IsExist = await this.CustomVariableCheckExist(Name);
    if (Name == "") {
      FormIsValid = false;
      Errors["Name"] = "The Name field is required.";
    }
    if (VariableType == "") {
      FormIsValid = false;
      Errors["VariableType"] = "Please select variable type";
    }
    if (IsExist == true) {
      FormIsValid = false;
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

  // handle change
  HandleChange(Field, E) {
    let Fields = this.state.Fields;
    Fields[Field] = E.target.value;
    this.setState({ Fields });
    if (Fields.Name != "") {
      this.state.Errors.Name = null;
      this.state.NameAvailable = null;
      this.CustomVariableCheckExist(E.target.value);
    } else {
      this.state.NameAvailable = null;
      this.state.Errors.Name = null;
    }
  }

  // find check exits meeting source
  async CustomVariableCheckExist(Name) {
    var InputParaMeter = {
      ClientID: this.state.ClientID,
      UserID: this.state.PUserID,
      Name: Name,
    };
    var resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/customvariable/CustomVariableExists",
      method: "POST",
      data: InputParaMeter,
    });

    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (this.state.OldName == Name) {
          this.setState({ IsExist: false });
          return false;
        } else {
          this.setState({ NameAvailable: resdata.data.Data[0].Name });
          this.setState({ IsExist: true });
          return true;
        }
      } else {
        this.setState({ IsExist: false });
        return false;
      }
    }
  }

  // edit custom variable
  CustomVariableEdit() {
    const id = this.props.location.state?.data;
    this.setState({ id: id });
    var InputParaMeter = {
      CustomVariableID: id,
    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/customvariable/CustomVariableByID",
      method: "POST",
      data: InputParaMeter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById("Name").value = res.data.Data[0]?.Name;
        this.setState({
          VariableTypeID: res.data.Data[0]?.VariableTypeID,
        });
        this.setState({
          VariableTypeID: res.data.Data[0]?.VariableTypeID,
        });
        this.setState({ OldName: res.data.Data[0]?.Name });
        Axios({
          url:
            CommonConstants.MOL_APIURL + "/customvariable/GetVariableTypeList",
          method: "POST",
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            this.setState({ VariableType: res.data.Data });
            this.setState({ IsLoading: false });
          } else {
            toast.error(res.data.Message);
            this.setState({ IsLoading: false });
          }
        });
      } else {
        toast.error(res.data.Message);
        this.setState({ IsLoading: false });
      }
    });
  }
  async VariableTypeselect(event) {
    this.setState({ VariableTypeID: event.target.value });
    await this.FromValidations()
  }

  BackBtn() {
    history.push("/customvariable");
  }
  async SaveBtn(e) {
    e.preventDefault();

    this.setState({ IsButtonDisabled: true });
    var Final_flag = await this.FromValidations();
    if (Final_flag == true) {
      var Name = document.getElementById("Name").value.trim();
      var InputParaMeter = {
        CustomVariableID: this.state.id,
        Name: Name,
        VariableTypeID: this.state.VariableTypeID,
        LastUpdatedBy: this.state.UserID,
        LastUpdatedDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/customvariable/CustomVariableUpdate",
        method: "POST",
        data: InputParaMeter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Custom Variable Edit
              <br />
              Custom variable updated successfully.
            </div>
          );
          history.push("/customvariable");
        } else {
          toast.error(res.data.Message);
        }
      });
    } else {
      this.setState({ IsButtonDisabled: false });

    }
  }

  render() {
    return (
      <>
        {
          this.state.IsLoading == true ? <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div> : null
        }

        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome px-3">
            <div className="row">
              <div className="col">
                <h4 className="headertitlepad float-left">
                  Edit Custom Variable
                </h4>
              </div>
            </div>

            <div class="bg-white pb-4">
              <div className="px-2">
                <div className="row pt-3 px-4">
                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-3 view-lable-meet">Name</label>
                      <div class="col-lg-7">
                        <input
                          class="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter name"
                          onBlur={this.HandleChange.bind(this, "Name")}
                          id="Name"
                          name="Name"
                          placeholder="Enter Name"
                          type="text"
                        />
                        <span style={{ color: "red" }}>
                          {this.state.Errors["Name"]}
                        </span>
                        {this.state.NameAvailable && (
                          <span style={{ color: "red" }}>
                            Custom variable already exist.
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6"></div>
                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-3 view-lable-meet">
                        Variable Type
                      </label>
                      <div class="col-lg-7">
                        <select
                          onChange={(e) => {
                            this.VariableTypeselect(e);
                          }}
                          className="form-control  m-input"
                          id="VariableType"
                          name="VariableType"
                          autoComplete="off"
                          value={this.state.VariableTypeID}
                        >
                          {<option value="">--Select--</option>}
                          {this.state.VariableType.map((value) => (
                            <option value={value.FieldID}>
                              {value.FieldName}
                            </option>
                          ))}
                        </select>
                        <span style={{ color: "red" }}>
                          {this.state.Errors["VariableType"]}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4 mt-4">
              <div class="col-lg-12 pull-left">
                <button
                  id="submit"
                  onClick={this.SaveBtn}
                  class="btn btn-primary btn-lightgreen mr-1"
                  disabled={this.state.IsButtonDisabled}
                >
                  <i class="la la-save"></i> Save
                </button>
                <button
                  id="backtolist"
                  onClick={this.BackBtn}
                  class="btn btn-secondary"
                >
                  <i class="la la-arrow-circle-left"></i> Back
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditCustomVariablePage = connect(
  mapState,
  actionCreators
)(EditCustomVariablePage);
export { connectedEditCustomVariablePage as EditCustomVariablePage };
