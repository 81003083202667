import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../../_actions';
import Sidebar from '../../../_components/user/NavigationSidebar/Sidebar';
import PotentialMeetingStatus from '../../../_components/user/PotentialMeetingStatus/PotentialMeetingStatus'
import { CommonConstants } from '../../../_constants/common.constants';
import { history } from '../../../_helpers';



class PotentialMeetingStatusPage extends React.Component {

    addpotentailmeetingstatus() {
        history.push("/addpotentialmeetingstatus")
    }

    setOrderPotentialmeetingstatus() {
        history.push("/setpotentialmeeting")
    }

    render() {
        return (
            <>


                <div className="adminmain"> 
                    <Sidebar className="" /> 

                    <div className="row">
                        <div className="col">
                            <h4 className="headertitle float-left">Potential Meeting Status</h4>
                        </div>
                    </div>

                    <div className="bodyhome">
                        <div className="row">
                            <div className="col border-bottom">

                                <div className="listing-li float-right padb-15">
                                    <ul>
                                        <li>
                                            <a onClick={this.setOrderPotentialmeetingstatus(this)} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                                <i class="la la-icon-font-size-13 flaticon-settings"></i>
                                                <span>Set Order</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a onClick={this.addpotentailmeetingstatus.bind(this)} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                                <i class="la la-icon-font-size-13 la-plus"></i>
                                                <span>Add</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="paddcols">
                            <div className="row">
                                <div className="col">
                                    <PotentialMeetingStatus />
                                </div>
                            </div>
                        </div>

                    </div>





                </div>
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedPotentialMeetingStatusPage = connect(mapState, actionCreators)(PotentialMeetingStatusPage);
export { connectedPotentialMeetingStatusPage as PotentialMeetingStatusPage };