import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Avatar } from "@material-ui/core";
import { toast } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import Userimg from "../../../images/avatar/1.jpg";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable(props) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDt");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [Notes, SetNotes] = React.useState([]);
  const [ResponderData, SetResponderData] = React.useState([]);
  const [OldClients, SetOldClients] = React.useState(false);
  const [ResponderID, SetResponderID] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [RecallEffect, SetRecallEffect] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);
  const [sortedColumn, setSortedColumn] = React.useState(null);

  const classes = useRowStyles();

  useEffect(() => {
    document.title = `Client Rescheduling | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    ClientReschedulingGet(Details.ClientID, Details.ParentUserID, Details.Role);
  }, [Search, Page, RowsPerPage, ResponderID, OldClients, RecallEffect]);

  useEffect(() => {
    var Details = GetUserDetails();
    ResponderDropDown(Details.ParentUserID, Details.Role);
  }, []);

  // Client rescheduling get list
  const ClientReschedulingGet = (CID, UID, URole) => {
    SetIsLoading(true);
    var InputParameters = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      ResponderID: ResponderID,
      OldClient: OldClients,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL + "/client_reschedules/ClientReschedulesGet",
      method: "POST",
      data: InputParameters,
    });
    rows1
      .then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        //props.updateFromChild(Result.data.TotalCount);
        SetIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        SetIsLoading(false);
      });
  };

  // Reponder Drop down list
  const ResponderDropDown = (UId, RoleID) => {
    var InputParameters = {
      UserID: UId,
      Role: RoleID,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL + "/client_reschedules/ClientResponderGet",
      method: "POST",
      data: InputParameters,
    });
    rows1
      .then((Result) => {
        SetResponderData(Result.data.Data);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  //get sort field data
  const SortData = (FieldName) => {
    let Field;
    if (FieldName == "Title") {
      Field = "JobTitle";
    } else if (FieldName == "MeetingSetDate") {
      Field = "EventCreatedDt";
    } else if (FieldName == "MeetingDate") {
      Field = "EventStartDt";
    } else if (FieldName == "CreatedDate") {
      Field = "CreatedDt";
    } else if (FieldName == "MeetingStatus") {
      Field = "Status";
    } else if (FieldName == "Owner") {
      Field = "MeetingOwner";
    } else if (FieldName == "QualifiedDate") {
      Field = "QulifiedDate";
    } else if (FieldName == "SendReminder") {
      Field = "IsSendMeetingReminder";
    } else if (FieldName == "MeetingSetBy") {
      Field = "ResponserName";
    } else {
      Field = FieldName;
    }
    var SearchedVal = document.getElementById("search").value;
    SetIsLoading(true);
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        ResponderID: ResponderID,
        OldClient: OldClients,
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/client_reschedules/ClientReschedulesGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        ResponderID: ResponderID,
        OldClient: OldClients,
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/client_reschedules/ClientReschedulesGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      });
    }
  };

  const viewpage = (id) => {
    history.push({
      pathname: "/viewbookedmeeting",
      state: { data: id, Pagename: "allClientReschedule" },
    });
  };

  // Reset Filter
  const ResetFilter = () => {
    SetRecallEffect(!RecallEffect);
    SetResponderID("");
    SetOldClients(false);
    document.getElementById("ClientCheck").checked = false;
    document.getElementById("ResponderID").value = "";
  };

  //  Advance Filter
  const FilterResponder = () => {
    let Responder = document.getElementById("ResponderID").value;
    let ClientCheckBox = document.getElementById("ClientCheck").checked;
    SetResponderID(Responder);
    SetOldClients(ClientCheckBox);
  };

  //Get Notes
  const GetNotes = (ID) => {
    var InputParameter = {
      EventID: ID,
      Role: Role,
    };
    const AccountCategoriesList = Axios({
      url:
        CommonConstants.MOL_APIURL + "/client_reschedules/MeetingNoteGetByID",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      SetNotes(Result.data.Data);
    }).catch((error) => {
      toast.error(error.message);
    });
  };

  const EditAccountHandle = (id) => {
    if (id !== undefined && id !== null && id !== "000000000000000000000000") {
      history.push({
        pathname: "/viewaccount",
        state: { data: id, Pagename: "ClientRescheduleMeeting" },
      });
    }
  };

  const EditContactHandle = (id, Email) => {
    if (id !== undefined && id !== null && id !== "000000000000000000000000") {
      history.push({
        pathname: "/viewcontacts",
        state: {
          data: id,
          EmailData: Email,
          Pagename: "ClientRescheduleMeeting",
        },
      });
    }
  };

  //Export details
  const Exportdetails = async (UID, CID, URole) => {
    var str_in = {
      UserID: UID,
      ClientID: CID,
      Role: URole,
      ResponderID: ResponderID,
      OldClient: OldClients,
    };

    const rows1 = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/client_reschedules/ClientReschedulesExport",
      method: "POST",
      data: str_in,
    });
    if (rows1.data.StatusMessage === "SUCCESS") {
      return rows1.data;
    }
  };

  const exportcsv = async () => {
    try {
      SetIsLoading(true);
      // document.getElementById("hidelod").style.display = "block";
      const result = await Exportdetails(UserID, ClientID, Role);
      if (result.StatusMessage === "SUCCESS") {
        const data = result.Data;
        if (data.length > 0) {
          const downloader = document.createElement("a");
          const csvContent =
            "data:text/csv;charset=utf-8," +
            encodeURIComponent(convertToCSV(data));
          downloader.setAttribute("href", csvContent);
          downloader.setAttribute("download", "AllClients-Rescheduling.csv");
          downloader.click();
          toast.success(<div>Data exported successfully.</div>);
          SetIsLoading(false);
          //document.getElementById("hidelod").style.display = "none";
        } else {
          toast.error("No data available for export.");
          SetIsLoading(false);
          // document.getElementById("hidelod").style.display = "none";
        }
      } else {
        toast.error("Failed to export data. Please try again.");
        SetIsLoading(false);
        // document.getElementById("hidelod").style.display = "none";
      }
    } catch (error) {
      // console.error("ExportCsv error:", error);
      toast.error("An error occurred while exporting data.");
    }
  };

  function convertToCSV(data) {
    const headers = Object.keys(data[0]);

    const csvRows = [];
    csvRows.push(headers.join(","));

    data.forEach((item) => {
      const values = headers.map((header) => {
        // Check if the value is a string before attempting to replace characters
        const value =
          typeof item[header] === "string"
            ? item[header]
            : String(item[header]);
        const escapedValue = value.replace(/"/g, '""'); // Escape double quotes
        return `"${escapedValue}"`; // Enclose value in double quotes
      });
      csvRows.push(values.join(","));
    });

    return csvRows.join("\n");
  }

  return (
    <>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}

      <div className="row">
        <div className="col">
          <div className="listing-li float-right padb-15">
            <ul>
              <li>
                <a
                  onClick={exportcsv}
                  className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                >
                  <i class="la la-icon-font-size-13 la-download"></i>
                  <span>Export</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row pt-4 pb-4 whiterow">
        <div className="col col-lg-3">
          <div className="boxborders">
            <label class="check_bx">
              <input type="checkbox" id="ClientCheck" />
              <span className="checkmark"></span>
              Include Old Clients
            </label>
          </div>
        </div>

        <div className="col col-lg-1 py-2"> Responder </div>

        <div className="col col-lg-3">
          <select
            class="form-control w-100 m-input"
            id="ResponderID"
            name="ResponderID"
            autocomplete="off"
          >
            <option value="">--Select--</option>
            {ResponderData.map((value) => (
              <option value={value.Name}>{value.Name}</option>
            ))}
          </select>
        </div>
        <div className="col col-lg-5">
          <button
            // href="javascript:void(0);"
            onClick={FilterResponder}
            class="btn btn-primary btnColor"
          >
            <i class="la la-search"></i>Search
          </button>
          <button
            // href="javascript:void(0);"
            onClick={ResetFilter}
            class="ml-3 btn btn-secondary"
          >
            <i class="la la-close"></i> Reset
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>

      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Notes</TableCell>
                <TableCell>Action</TableCell>
                <TableCell
                  onClick={() => {
                    SortData("EventStatus");
                    setSortedColumn("EventStatus");
                  }}
                  className="w-250"
                >
                  Meeting Status
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "EventStatus" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "EventStatus" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("ClientName");
                    setSortedColumn("ClientName");
                  }}
                >
                  Client Name
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "ClientName" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "ClientName" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("AccountName");
                    setSortedColumn("AccountName");
                  }}
                >
                  Account Name
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "AccountName" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "AccountName" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("Company");
                    setSortedColumn("Company");
                  }}
                >
                  Company
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "Company" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "Company" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("JobTitle");
                    setSortedColumn("JobTitle");
                  }}
                >
                  Title
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "JobTitle" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "JobTitle" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("LastName");
                    setSortedColumn("LastName");
                  }}
                >
                  Contact Name
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "LastName" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "LastName" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("Email");
                    setSortedColumn("Email");
                  }}
                >
                  Email
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "Email" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "Email" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("Domain");
                    setSortedColumn("Domain");
                  }}
                >
                  Domain
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "Domain" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "Domain" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("MeetingSource");
                    setSortedColumn("MeetingSource");
                  }}
                >
                  Meeting Source
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "MeetingSource" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "MeetingSource" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("MeetingOwner");
                    setSortedColumn("MeetingOwner");
                  }}
                >
                  Owner
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "MeetingOwner" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "MeetingOwner" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("EventStartDt");
                    setSortedColumn("EventStartDt");
                  }}
                >
                  Meeting Date
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "EventStartDt" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "EventStartDt" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("EventCreatedDt");
                    setSortedColumn("EventCreatedDt");
                  }}
                >
                  Meeting Set Date
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "EventCreatedDt" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "EventCreatedDt" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("IsSendMeetingReminder");
                    setSortedColumn("IsSendMeetingReminder");
                  }}
                >
                  Send Reminder
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "IsSendMeetingReminder" &&
                        SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "IsSendMeetingReminder" &&
                        SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("LinkedInUrl");
                    setSortedColumn("LinkedInUrl");
                  }}
                >
                  LinkedIn Url
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "LinkedInUrl" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "LinkedInUrl" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("ContractValue");
                    setSortedColumn("ContractValue");
                  }}
                >
                  Contract Value
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "ContractValue" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "ContractValue" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("Industry");
                    setSortedColumn("Industry");
                  }}
                >
                  Industry
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "Industry" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "Industry" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("DirectPhone");
                    setSortedColumn("DirectPhone");
                  }}
                >
                  Direct Phone
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "DirectPhone" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "DirectPhone" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("CompanyPhone");
                    setSortedColumn("CompanyPhone");
                  }}
                >
                  Company Phone
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "CompanyPhone" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "CompanyPhone" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("DayInStage");
                    setSortedColumn("DayInStage");
                  }}
                >
                  Days In Stage
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "DayInStage" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "DayInStage" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("LastStatusChangeDate");
                    setSortedColumn("LastStatusChangeDate");
                  }}
                >
                  Last Status Change Date
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "LastStatusChangeDate" &&
                        SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "LastStatusChangeDate" &&
                        SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("CreatedDt");
                    setSortedColumn("CreatedDt");
                  }}
                >
                  Created Date
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "CreatedDt" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "CreatedDt" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("QulifiedDate");
                    setSortedColumn("QulifiedDate");
                  }}
                >
                  Qualified Date
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "QulifiedDate" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "QulifiedDate" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("OpportunityDate");
                    setSortedColumn("OpportunityDate");
                  }}
                >
                  Opportunity Date
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "OpportunityDate" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "OpportunityDate" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("ClosedWonDate");
                    setSortedColumn("ClosedWonDate");
                  }}
                >
                  Closed Won Date
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "ClosedWonDate" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "ClosedWonDate" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("ClosedLostDate");
                    setSortedColumn("ClosedLostDate");
                  }}
                >
                  Closed Lost Date
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "ClosedLostDate" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "ClosedLostDate" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("UnqualifiedDate");
                    setSortedColumn("UnqualifiedDate");
                  }}
                >
                  Unqualified Date
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "UnqualifiedDate" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "UnqualifiedDate" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("MeetingStatusType");
                    setSortedColumn("MeetingStatusType");
                  }}
                >
                  Meeting Status Type
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "MeetingStatusType" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "MeetingStatusType" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("LastStatusChangeDate");
                    setSortedColumn("LastStatusChangeDate");
                  }}
                >
                  Meeting Set By
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "LastStatusChangeDate" &&
                        SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "LastStatusChangeDate" &&
                        SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("Seniority");
                    setSortedColumn("Seniority");
                  }}
                >
                  Seniority
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedColumn === "Seniority" && SortedBy === 1
                          ? "active"
                          : ""
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedColumn === "Seniority" && SortedBy === -1
                          ? "active"
                          : ""
                      }
                    />
                  </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows.map((row) => (
                  <>
                    <TableRow key={row._id}>
                      <TableCell align="center">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            setOpen((prev) => ({
                              ...prev,
                              [row._id]: !prev[row._id],
                            }));
                            GetNotes(row._id);
                          }}
                        >
                          {open[row._id] ? (
                            <i
                              className="fa fa-minus-circle minusl"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              className="fa fa-plus-circle plusbl"
                              aria-hidden="true"
                            ></i>
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <a
                          className="btn-eyesicon"
                          onClick={() => {
                            viewpage(row._id);
                          }}
                        >
                          <i class="la flaticon-eye edit-icon"></i>
                        </a>
                      </TableCell>
                      <TableCell>{row.Status}</TableCell>
                      <TableCell>{row.ClientName}</TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            EditAccountHandle(row?.AccountNameID);
                          }}
                        >
                          <u>
                            {row?.AccountName == undefined ||
                            row?.AccountName == ""
                              ? row?.Company
                              : row?.AccountName}
                          </u>
                        </a>
                      </TableCell>
                      <TableCell>{row.Company}</TableCell>
                      <TableCell>{row.Title}</TableCell>
                      <TableCell>
                        {" "}
                        <a
                          onClick={() => {
                            EditContactHandle(row?.Prospectid, row.Email);
                          }}
                        >
                          <u>{row?.ContactName}</u>
                        </a>
                      </TableCell>
                      <TableCell>{row.Email}</TableCell>
                      <TableCell>
                        {" "}
                        <a
                          href={`http://${row?.Domain}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: "underline",
                            color: "inherit",
                          }}
                        >
                          <u> {row.Domain} </u>
                        </a>
                      </TableCell>
                      <TableCell>{row.MeetingSource}</TableCell>
                      <TableCell>{row.MeetingOwner}</TableCell>
                      <TableCell>
                        {" "}
                        {row?.EventStartDt != null &&
                        row?.EventStartDt !== "Invalid date"
                          ? row?.EventStartDt
                          : ""}
                      </TableCell>
                      <TableCell>
                        {row?.EventCreatedDt != null ? row?.EventCreatedDt : ""}
                      </TableCell>

                      <TableCell>
                        {row.IsSendMeetingReminder == true ? "Yes" : "No"}
                      </TableCell>
                      <TableCell>{row.LinkedInUrl}</TableCell>
                      <TableCell>{row.ContractValue}</TableCell>
                      <TableCell>{row.Industry}</TableCell>
                      <TableCell>{row.DirectPhone}</TableCell>
                      <TableCell>{row.CompanyPhone}</TableCell>
                      <TableCell>
                        {row?.DaysInStage && Math.floor(row.DaysInStage)}
                      </TableCell>
                      <TableCell>
                        {row?.LastStatusChangeDate != null
                          ? moment(new Date(row?.LastStatusChangeDate)).format(
                              "MM/DD/YYYY"
                            )
                          : ""}
                      </TableCell>
                      <TableCell>
                        {row?.CreatedDt != null
                          ? moment(new Date(row?.CreatedDt)).format(
                              "MM/DD/YYYY"
                            )
                          : ""}
                      </TableCell>
                      <TableCell>
                        {row?.QulifiedDate != null
                          ? moment(new Date(row?.QulifiedDate)).format(
                              "MM/DD/YYYY"
                            )
                          : ""}
                      </TableCell>
                      <TableCell>
                        {row?.OpportunityDate != null
                          ? moment(new Date(row?.OpportunityDate)).format(
                              "MM/DD/YYYY"
                            )
                          : ""}
                      </TableCell>
                      <TableCell>
                        {row?.ClosedWonDate != null
                          ? moment(new Date(row?.ClosedWonDate)).format(
                              "MM/DD/YYYY"
                            )
                          : ""}
                      </TableCell>
                      <TableCell>
                        {row?.ClosedLostDate != null
                          ? moment(new Date(row?.ClosedLostDate)).format(
                              "MM/DD/YYYY"
                            )
                          : ""}
                      </TableCell>
                      <TableCell>
                        {row?.UnqualifiedDate != null
                          ? moment(new Date(row?.UnqualifiedDate)).format(
                              "MM/DD/YYYY"
                            )
                          : ""}
                      </TableCell>
                      <TableCell>{row?.MeetingStatusType}</TableCell>
                      <TableCell>
                        {row?.ResponderColdCallersType == "R"
                          ? row?.ResponserName
                          : row?.ResponserName}
                      </TableCell>
                      <TableCell>{row.Seniority}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse
                          in={open[row?._id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {open[row?._id] && (
                            <Box margin={1} className="innertables">
                              <Table size="small" aria-label="purchases">
                                <TableHead></TableHead>
                                {Notes.map((note) =>
                                  note?.MeetingID == row?._id ? (
                                    <TableRow key={note._id}>
                                      <TableCell className="font-weight-bold">
                                        {note?.Title}
                                      </TableCell>
                                      <TableCell className="labeltds font-weight-bold">
                                        {moment(
                                          new Date(
                                            note?.CreatedDate
                                          ).toDateString()
                                        ).format("MM/DD/YYYY")}
                                      </TableCell>
                                      <TableCell scope="row">
                                        {note?.Note}
                                      </TableCell>
                                    </TableRow>
                                  ) : null
                                )}

                                {!Notes.some(
                                  (note) => note?.MeetingID == row?._id
                                ) && (
                                  <TableRow>
                                    <TableCell colSpan={4}>
                                      No data available
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Table>
                            </Box>
                          )}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
