import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Axios from "axios";
const Moment = require("moment");
import Select from "react-select";

import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import { Tooltip } from "@material-ui/core";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

export default function CollapsibleTable(props) {
  const [bmddata, setfbmddata] = React.useState([]);
  const [Open, SetOpen] = React.useState(false);
  const [data, setdata] = React.useState([]);
  const [flen, setflen] = React.useState(0);
  const [sflag, setsflag] = React.useState(false);
  const [rlen, setrlen] = React.useState(0);
  const [countpage, setCountPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [search, setSearch] = React.useState("");
  const [checkS, setcheckS] = React.useState(false);
  const [sortField, setsortField] = React.useState("EventCreatedDate1");
  const [sortedColumn, setSortedColumn] = React.useState("Meeting Set Date");
  const [sortedBy, setsortedBy] = React.useState(-1); // 0 for default, 1 for ascending, -1 for descending
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [status, setStatus] = React.useState([]);
  const [defaultselect, setDefaultselect] = React.useState([]);
  const [meeting, setMeetingOwner] = React.useState([]);
  const [ownerselected, setownerselect] = React.useState("");
  const [notes, setNotes] = React.useState([]);
  const [HeaderList, SetHeaderlist] = React.useState([]);
  const [CheckOwner, SetCheckOwner] = React.useState(false);
  const [OwnerIDs, SetOwnerIDs] = React.useState([]);
  const [OwnerIDFinal, SetOwnerIDFinal] = React.useState([]);
  const [OwnerIDFinalRemove, SetOwnerIDFinalRemove] = React.useState([]);
  const [Role, setRole] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);

  useEffect(() => {
    document.title = `Booked Meetings | SalesHive`;
    var getaccountcatelist = GetUserDetails();
    if (getaccountcatelist != null) {
      setClientID(getaccountcatelist.ClientID);
      setUserID(getaccountcatelist.ParentUserID);
      SetCUserID(getaccountcatelist.ChildUserID);
      setRole(getaccountcatelist.Role);
    }

    //document.getElementById("hideloding").style.display = "none";
    BookedmeetingListGet(
      getaccountcatelist.ClientID,
      getaccountcatelist.ParentUserID,
      props.IsApply,
      props.FilterParameters,
      getaccountcatelist.Role,
      page
    );
    // mapdata();
    OwnerDropDown(getaccountcatelist.ClientID, getaccountcatelist.Role);
  }, [
    search,
    page,
    RowsPerPage,
    props.FilterParameters,
    props.IsApply,
    OwnerIDFinal,
    CheckOwner,
    props.UpdateYourList,
  ]);


  useEffect(() => {
    //document.getElementById("hideloding").style.display = "none";
    setPage(1)
  }, [
    props.FilterParameters,
    props.IsApply,
    //props.UpdateYourList,
  ]);


  

  //Get booked meeting list
  const BookedmeetingListGet = (CID, UID, IsApply, FilterParams, URole,PageNumber) => {
    SetIsLoading(true);
    var str_in = {
      page: PageNumber,
      rowsPerPage: RowsPerPage,
      sort: true,
      field: sortField,
      sortby: sortedBy,
      search: search,
      type: "User",
      ClientID: CID,
      UserID: UID,
      MeetingOwnerID: OwnerIDs,
      Checker: CheckOwner,
      IsApply: IsApply,
      BookMeetingFilterArray: FilterParams,
      Role: URole,
    };

    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetBookedMeeting",
      method: "POST",
      data: str_in,
    });
    rows1
      .then((result) => {
        setdata(result.data.pagedata);
        setRows(result.data.pagedata);
        setrlen(result.data.totalcount);
        setflen(result.data.totalcount);
        setCountPage(result.data.pagecount);
        SetIsLoading(false);
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
  };

  // Reset Filter
  const ResetFilter = () => {
      SetOwnerIDs([]);
      SetOwnerIDFinal([]);
      SetOwnerIDFinalRemove([])
      SetCheckOwner(false);
      SetIsLoading(false);
      setPage(1);
      document.getElementById("include_owner").checked=false
      props.ResetFilterMethod();
  };

  //  Advance Filter
  // const FilterResponder = () => {
  //   try {
  //     let OwnerDetails = document.getElementById("MeetingOwnerID1").value;
  //     let ClientCheckBox = document.getElementById("include_owner").checked;
  //     SetOwnerID(OwnerDetails);
  //     SetCheckOwner(ClientCheckBox);
  //     if (OwnerDetails == "" && CheckOwner == false) {
  //       setPage(1);
  //       SetIsLoading(false);
  //     } else if (OwnerID === OwnerDetails) {
  //       setPage(1);
  //       SetIsLoading(false);
  //     } else {
  //       setPage(1);
  //       SetIsLoading(true);
  //       // BookedmeetingListGet(ClientID, UserID, false, [], Role);
  //     }
  //   } catch (error) {
  //     toast.error("An error occurred. Please try again.");
  //     SetIsLoading(false);
  //   }
  // };
  const FilterResponder = () => {
    try {
      let ClientCheckBox = document.getElementById("include_owner").checked;
      SetCheckOwner(ClientCheckBox);
      SetOwnerIDFinal(OwnerIDs)
      setPage(1);
    } catch (error) {
     // toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    }
  };

  //OwnerData Drop down list
  const OwnerDropDown = (CId, URole) => {
    var InputParameters = {
      ClientID: CId,
      Role: URole,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/MeetingOwnerGet",
      method: "POST",
      data: InputParameters,
    });
    rows1
      .then((Result) => {
        const mappedOptions = Result.data?.Data.map((val) => {
          return {
            value: val.Name,
            label: `${val.Name}`,
          };
        });

        setMeetingOwner(mappedOptions);
        //setMeetingOwner(Result.data?.Data);
        setStatus(Result.data?.meetingstatus);
        setNotes(Result.data?.notesdetails);
        SetHeaderlist(Result.data?.meetingheader);
        // SetStatusData(Result.data.DataStatusGet);
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
  };

  //search for record
  const RequestSearch = (Event) => {
    try {
      if (Event.key === "Enter") {
        var SearchedVal = document.getElementById("search").value;
        if (SearchedVal == search) {
          setSearch(SearchedVal);
          setPage(1);
        } else {
          setSearch(SearchedVal);
          setPage(1);
        }
      }
    } catch (error) {
      toast.error("An error occurred while searching. Please try again.");
      SetIsLoading(false);
    }
  };

  //Delete booked meeting
  const DeleteBtn = (id) => {
    var SearchedVal = document.getElementById("search").value;
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete booked meeting.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          SetIsLoading(true)
          var data = {
            _id: id,
            LastUpdatedDate: new Date(),
            LastUpdatedBy: CUserID,
            Role: Role,
          };

          Axios({
            url:
              CommonConstants.MOL_APIURL + "/bookedmeeting/DeleteBookedMeeting",
            method: "POST",
            data: data,
          }).then((res) => {
            if (res) {
              if ((res.statusText = "Ok")) {
                Swal.fire(
                  "Deleted!",
                  "Booked meeting deleted successfully.",
                  "success"
                );
                 if(SearchedVal===""){
                  BookedmeetingListGet(ClientID, UserID, false, [], Role,1);
                }
                setPage(1);
                setSearch("");
                props.updateFromChild(true);
                document.getElementById("search").value = "";
                // SetIsLoading(false)
              } else {
              }
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
  };

  //Pagination
  const HandleChangePage = (event, newPage) => {
    if (newPage == page) {
      setPage(newPage);
    } else {
      setPage(newPage);
    }
  };

  //selection pagination
  const ChangeRowSelected = (event) => {
    SetRowsPerPage(Number(event.target.value));
    setPage(1);
  };

  const Bookedstatusselect = (event, EventID, UserID, StatusIDs,EmailID) => {
    SetIsLoading(true);
    let MeetingIDs = null;
    MeetingIDs = event.target.value;
    //find the meeting status name by id
    var data = {
      ClientID: ClientID,
      MeetingStatusID: event.target.value,
      Role: Role,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/bookedmeeting/findBookedMeetingStatus",
      method: "POST",
      data: data,
    })
      .then((res) => {
        var data = {
          // _id: id,
          EventID: EventID,
          ClientID: ClientID,
          UserID: UserID,
          MeetingStatusID: res.data.BookedStatus[0]?._id,
          OldMeetingStatusID: StatusIDs,
          Status: res.data.BookedStatus[0]?.Status,
          Role: Role,
          StatusName: document.getElementById(`MeetingStatusID${EventID}`)
            .options[
            document.getElementById(`MeetingStatusID${EventID}`).selectedIndex
          ].textContent,
          Email: EmailID,
        };
        
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/bookedmeeting/UpdateBookedMeetingStatusHistory",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            props.updateFromChild(true);
            BookedmeetingListGet(ClientID, UserID, false, [], Role,1);
            toast.success(
              <div>
                Meeting <br />
                Status successfully changed.
              </div>
            );
          } else {
            toast.error(res.data.Message);
            SetIsLoading(false);
          }
        });
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
  };

  const viewpage = (id) => {
    history.push({
      pathname: "/cviewbookedmeetings",
      state: { data: id, Pagename: "" },
    });
  };

  const resetView = () => {
    BookedmeetingListGet();
    document.getElementById("resetview").style.display = "none";
  };

  //get sort field data
  const SortData = (FieldName) => {
    try {
      let Field 
      if(FieldName=="Title"){
        Field="JobTitle"
      }else if(FieldName=="MeetingSetDate"){
        Field="EventCreatedDate1"
      }else if(FieldName=="MeetingDate"){
        Field="EventStartDt"
      } else if(FieldName=="CreatedDate"){
        Field="CreatedDt"
       } else if(FieldName=="MeetingStatus"){
        Field="Status"
       } else if(FieldName=="Owner"){
        Field="MeetingOwner"
       } else if(FieldName=="SendReminder"){
        Field="IsSendMeetingReminder"
       } else if(FieldName=="QualifiedDate"){
        Field="QulifiedDate"
       } else if(FieldName=="MeetingSetBy"){
        Field="ResponserName"
       }else{
        Field=FieldName
       }
      var searchedVal = document.getElementById("search").value;
      SetIsLoading(true);
      var SerchBox;
      if (searchedVal == "") {
        SerchBox = false;
        setsflag(false);
        var SortField = Field;
        var SortBy;
        if (sortedBy == 1) {
          SortBy = -1;
          setsortedBy(-1);
        } else {
          SortBy = 1;
          setsortedBy(1);
        }
        setsortField(Field);
        var InputParameter = {
          page: page,
          rowsPerPage: RowsPerPage,
          sort: true,
          field: SortField,
          sortby: SortBy,
          search: search,
          type: "User",
          ClientID: ClientID,
          UserID: UserID,
          MeetingOwnerID: OwnerIDs,
          Checker: CheckOwner,
          Role: Role,
          IsApply: props.IsApply,
          BookMeetingFilterArray: props.FilterParameters,
        };
        const UserGetList = Axios({
          url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetBookedMeeting",
          method: "POST",
          data: InputParameter,
        });
        UserGetList.then((result) => {
          setdata(result.data.pagedata);
          setRows(result.data.pagedata);
          setrlen(result.data.totalcount);
          setflen(result.data.totalcount);
          setCountPage(result.data.pagecount);
          SetIsLoading(false);
        }).catch((error) => {
          toast.error("An error occurred. Please try again.");
          SetIsLoading(false);
        });
      } else {
        SerchBox = true;
        setsflag(true);
        var SortField = Field;
        var SortBy;
        if (sortedBy == 1) {
          SortBy = -1;
          setsortedBy(-1);
        } else {
          SortBy = 1;
          setsortedBy(1);
        }
        setsortField(Field);
        var InputParameter = {
          page: page,
          rowsPerPage: RowsPerPage,
          sort: true,
          field: SortField,
          sortby: SortBy,
          search: search,
          type: "User",
          ClientID: ClientID,
          UserID: UserID,
          MeetingOwnerID: OwnerIDs,
          Checker: CheckOwner,
          Role: Role,
          IsApply: props.IsApply,
          BookMeetingFilterArray: props.FilterParameters,
        };

        const UserGetList = Axios({
          url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetBookedMeeting",
          method: "POST",
          data: InputParameter,
        });
        UserGetList.then((result) => {
          setdata(result.data.pagedata);
          setRows(result.data.pagedata);
          setrlen(result.data.totalcount);
          setflen(result.data.totalcount);
          setCountPage(result.data.pagecount);
          SetIsLoading(false);
        }).catch((error) => {
          toast.error("An error occurred. Please try again.");
          SetIsLoading(false);
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    }
  };

  const EditAccountHandle = (id) => {
    if (id !== undefined && id !== null && id !== "000000000000000000000000") {
    history.push({
      pathname: "/cviewaccounts",
      state: { data: id, Pagename: "BookedMeeting" },
    });
  }
  };

  const EditContactHandle = (id, Email) => {
    if (id !== undefined && id !== null && id !== "000000000000000000000000") {
      // history.push({
      //   pathname: "/viewcontacts",
      //   state: { data: id, EmailData: Email, Pagename: "BookedMeeting" },
      // });
      const url = `/cviewcontacts?id=${id}&email=${encodeURIComponent(Email)}&pagename=BookedMeeting`;
      window.open(url, '_blank');
  }
  };

  const handleOwnerChange = (selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions?.map(option => option.value) : [];
    SetOwnerIDs(selectedValues);
    SetOwnerIDFinalRemove(selectedOptions)
  };
  

  return (
    <>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row my-5">
        <div className="col col-lg-1">
          <label className="labeltexts my-2">Owner</label>
        </div>
        <div className="col col-lg-4">
        <Select
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          options={meeting}
          id="MeetingOwnerID1"
          placeholder={"--Select--"}
          isMulti
          onChange={handleOwnerChange}
          value={OwnerIDFinalRemove}
        />
        </div>
        <div className="col col-lg-3">
          <div className="boxborders">
            <label className="check_bx">
              <input id="include_owner" type="checkbox" />
              <span className="checkmark"></span>
              Open Only
            </label>
          </div>
        </div>
        <div className="col col-lg-4 pt-1">
          <button
            onClick={FilterResponder}
            className="btn btn-primary btnColor"
          >
            Search
          </button>
          <button onClick={ResetFilter} className="ml-3 btn btn-secondary">
            <i className="la la-refresh"></i> Reset
          </button>
        </div>
      </div>
      <div className="borderboxstp"></div>

      <div className="row">
        <div className="col-sm-12" id="resetview" style={{ display: "none" }}>
          <a
            onClick={() => {
              resetView();
            }}
          >
            Reset
          </a>
        </div>
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Notes</TableCell>
                <TableCell>Action</TableCell>
                {HeaderList?.map((row) => (
                  <TableCell key={row._id} onClick={() => {
                    SortData(row.ColumnName.replace(/\s+/g, ""));
                    setSortedColumn(row.ColumnName); // Set the sorted column
                  }}>
                    {row.ColumnName}
                    <span className="shorting">
                      <ArrowUpward className={sortedBy === 1 && sortedColumn === row.ColumnName ? "active" : null} />
                      <ArrowDownward className={sortedBy === -1 && sortedColumn === row.ColumnName ? "active" : null} />
                    </span>
                  </TableCell>
                ))}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                rows?.map((value1) => (
                  <React.Fragment key={value1._id}>
                    <TableRow>
                      <TableCell align="center">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() =>
                            SetOpen((prev) => ({
                              ...prev,
                              [value1._id]: !prev[value1._id],
                            }))
                          }
                        >
                          {Open[value1._id] ? (
                            <i
                              className="fa fa-minus-circle minusl"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              className="fa fa-plus-circle plusbl"
                              aria-hidden="true"
                            ></i>
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            viewpage(value1?._id);
                          }}
                          className="btn-eyesicon"
                          title="Edit"
                        >
                          <i className="la flaticon-eye edit-icon"></i>
                        </a>
                      </TableCell>
                      {HeaderList.map((row) => {
                        if (row.ColumnName == "Title") {
                          return <TableCell>{value1?.Title}</TableCell>;
                        } else if (row.ColumnName == "Meeting Date") {
                          return (
                            <TableCell>
                              {value1?.EventStartDate1 != null && value1?.EventStartDate1 !== "Invalid date"
                                ? value1?.EventStartDate1
                                : ""}
                            </TableCell>
                          );
                        } else if (row?.ColumnName == "Domain") {
                          return (
                            <TableCell>
                              <a
                                href={`http://${value1?.Email?.substring(
                                  value1?.Email?.lastIndexOf("@") + 1
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  textDecoration: "underline",
                                  color: "inherit",
                                }}
                              >
                                <u>
                                  {" "}
                                  {value1?.Email?.substring(
                                    value1?.Email?.lastIndexOf("@") + 1
                                  )}
                                </u>
                              </a>
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Opportunity Date") {
                          return (
                            <TableCell>
                              {value1?.OpportunityDate != null
                                ? Moment(
                                    new Date(value1?.OpportunityDate)
                                  ).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Meeting Set Date") {
                          return (
                            <TableCell>
                              {value1?.EventCreatedDt1 != null && value1?.EventCreatedDt1 != "Invalid date"
                                ? value1?.EventCreatedDt1
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Created Date") {
                          return (
                            <TableCell>
                              {value1?.CreatedDt != null
                                ? Moment(new Date(value1?.CreatedDt)).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "LinkedIn Url") {
                          return <TableCell>{value1?.LinkedInUrl}</TableCell>;
                        } else if (
                          row.ColumnName == "Last Status Change Date"
                        ) {
                          return (
                            <TableCell>
                              {value1?.LastStatusChangeDate != null
                                ? Moment(
                                    new Date(value1?.LastStatusChangeDate)
                                  ).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Industry") {
                          return <TableCell>{value1?.Industry}</TableCell>;
                        } else if (row.ColumnName == "Direct Phone") {
                          return <TableCell>{value1?.DirectPhone}</TableCell>;
                        } else if (row.ColumnName == "Meeting Set By") {
                          return (
                            <TableCell>
                              {value1?.ResponderColdCallersType == "R"
                                ? value1?.ResponserName
                                : value1?.ResponserName}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Meeting Status") {
                          return (
                            <TableCell>
                              {
                                <select
                                  onChange={(e) => {
                                    Bookedstatusselect(
                                      e,
                                      value1?._id,
                                      value1?.UserID,
                                      value1?.StatusID,
                                      value1?.Email
                                    );
                                  }}
                                  className="form-control  m-input w-auto"
                                  id={`MeetingStatusID${value1?._id}`}
                                  name="MeetingStatusID"
                                  autocomplete="off"
                                  value={value1?.StatusID}
                                >
                                  {status.map((value) => (
                                    <option value={value?._id}>
                                      {value?.Status}
                                    </option>
                                  ))}
                                </select>
                              }
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Company") {
                          return <TableCell>{value1?.Company}</TableCell>;
                        } else if (row.ColumnName == "Contact Name") {
                          return (
                            <TableCell>
                                <a
                                  href={`/viewcontacts?id=${value1?.Prospectid}&email=${encodeURIComponent(value1?.Email)}&pagename=BookedMeeting`}
                                  onClick={(e) => {
                                    // If it's a left-click, prevent the default behavior and call EditContactHandle
                                   // if (e.button === 0 && !(e.metaKey || e.ctrlKey || e.shiftKey)) {
                                      e.preventDefault();
                                      EditContactHandle(value1?.Prospectid, value1?.Email);
                                   // }
                                    // Otherwise, allow the default behavior (e.g., right-click, middle-click, Ctrl+Click, etc.)
                                  }}
                                >
                                  <u>{value1?.ContactName}</u>
                                </a>
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Days In Stage") {
                          return (
                            <TableCell>
                              {value1?.DaysInStage &&
                                Math.floor(value1.DaysInStage)}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Company Phone") {
                          return <TableCell>{value1?.CompanyPhone}</TableCell>;
                        } else if (row.ColumnName == "Closed Won Date") {
                          return (
                            <TableCell>
                              {value1?.ClosedWonDate != null
                                ? Moment(
                                    new Date(value1?.ClosedWonDate)
                                  ).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Unqualified Date") {
                          return (
                            <TableCell>
                              {value1?.UnqualifiedDate != null
                                ? Moment(
                                    new Date(value1?.UnqualifiedDate)
                                  ).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Contract Value") {
                          return <TableCell>{value1?.ContractValue}</TableCell>;
                        } else if (row.ColumnName == "Email") {
                          return <TableCell>{value1?.Email}</TableCell>;
                        } else if (row.ColumnName == "Meeting Status Type") {
                          return (
                            <TableCell>{value1?.MeetingStatusType}</TableCell>
                          );
                        } else if (row.ColumnName == "Send Reminder") {
                          return (
                            <TableCell>
                              {value1?.IsSendMeetingReminder === true
                                ? "Yes"
                                : "No"}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Seniority") {
                          return <TableCell>{value1?.Seniority}</TableCell>;
                        } else if (row.ColumnName == "Meeting Source") {
                          return <TableCell>{value1?.MeetingSource}</TableCell>;
                        } else if (row.ColumnName == "Account Name") {
                          return (
                            <TableCell>
                              <a
                                onClick={() => {
                                  EditAccountHandle(value1?.AccountNameID);
                                }}
                              >
                                <u>
                                  {value1?.AccountName == undefined || value1?.AccountName == ""
                                    ? ""
                                    : value1?.AccountName}
                                </u>
                              </a>
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Owner") {
                          return <TableCell>{value1?.MeetingOwner}</TableCell>;
                        } else if (row.ColumnName == "Qualified Date") {
                          return (
                            <TableCell>
                              {value1?.QulifiedDate != null
                                ? Moment(new Date(value1?.QulifiedDate)).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Closed Lost Date") {
                          return (
                            <TableCell>
                              {value1?.ClosedLostDate != null
                                ? Moment(
                                    new Date(value1?.ClosedLostDate)
                                  ).format("MM/DD/YYYY")
                                : ""}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Last Email Step") {
                          return (
                            <TableCell>
                              {value1?.LastEmailStep}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Last Email Campaign") {
                          return (
                            <TableCell>
                              {value1?.LastEmailCampaign}
                            </TableCell>
                          );
                        }else if (row.ColumnName == "Contact Source") {
                          return (
                            <TableCell>
                              {value1?.ContactSource}
                            </TableCell>
                          );
                        }else if (row.ColumnName == "Reminder Email Account") {
                          return (
                            <TableCell>
                              {value1?.ReminderEmailAccount}
                            </TableCell>
                          );
                        } else if (row.ColumnName == "Direct Phone Ext") {
                          return (
                            <TableCell>{value1?.DirectPhoneExt}</TableCell>
                          );
                        } else if (row.ColumnName == "Website") {
                          return  <TableCell><a href={`http://${value1?.Website}`} target="_blank" rel="noopener noreferrer"><u>{value1?.Website}</u></a></TableCell>
                        } else if (
                          row.CustomFieldID != null &&
                          row.CustomFieldID != ""
                        ) {
                          let valcust = value1.CustomField?.filter(
                            (item) => item.CustomFieldID == row?.CustomFieldID
                          );
                          if (valcust?.length > 0) {
                            return (
                              <TableCell>{valcust[0]?.Value}</TableCell>
                            );
                          } else {
                            return <TableCell></TableCell>;
                          }
                        } else {
                          return <TableCell></TableCell>;
                        }
                      })}

                      <TableCell>
                        <a
                          onClick={() => {
                            viewpage(value1?._id);
                          }}
                          className="btn-eyesicon"
                          title="Edit"
                        >
                          <i className="la flaticon-eye edit-icon"></i>
                        </a>

                        <a
                          onClick={() => {
                            DeleteBtn(value1?._id);
                          }}
                          className="btn-eyesicon"
                          title="Delete"
                        >
                          <i className="la flaticon-delete-1 delete-icon"></i>
                        </a>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={10}
                      >
                        <Collapse
                          in={Open[value1?._id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {Open[value1?._id] && (
                            <Box margin={1} className="innertables">
                              <Table size="small" aria-label="purchases">
                                <TableHead></TableHead>
                                {notes.map((note) =>
                                  note?.MeetingID == value1?._id ? (
                                    <TableRow key={note._id}>
                                      <TableCell className="font-weight-bold">
                                        {note?.Title}
                                      </TableCell>
                                      <TableCell className="labeltds font-weight-bold">
                                        {Moment(
                                          new Date(
                                            note?.CreatedDate
                                          ).toDateString()
                                        ).format("MM/DD/YYYY")}
                                      </TableCell>
                                      <TableCell className="notes" scope="row">
                                        {note?.Note}
                                      </TableCell>
                                    </TableRow>
                                  ) : null
                                )}

                                {!notes.some(
                                  (note) => note?.MeetingID == value1?._id
                                ) && (
                                  <TableRow>
                                    <TableCell colSpan={4}>
                                      No data available
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Table>
                            </Box>
                          )}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={countpage}
            onChange={HandleChangePage}
            page={page}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
              {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of {rlen}{" "}
              entries (filtered from {flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
              {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of {rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={countpage}
              onChange={HandleChangePage}
              page={page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
