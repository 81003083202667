import React, { useEffect } from "react";
import { toast } from "react-toastify";
import Axios from "axios";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import loadingicon from "../../../images/loading.gif";
import { CommonConstants } from "../../../_constants/common.constants";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { GetUserDetails } from "../../../_helpers/Utility";
import ReactApexChart from 'react-apexcharts';
import { Link } from "@material-ui/core";
import StyleHeader from "../StickyHeader/StickyHeader";



export default function BackLinks({ ObjBacklinks, DisplayDomain }) {
  const [SeoBackLinkData, SetSeoBackLinkData] = React.useState([]);
  const [SeoBackLinkCount, SetSeoBackLinkCount] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Rlen, SetRlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [CountPage, SetCountPage] = React.useState(0);

  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("first_seen");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(false)
  const [TotalLinkCount, SetTotalLinkCount] = React.useState(0);
  const [BacklinkWeek, SetBacklinkWeek] = React.useState([]);
  const [TotalBacklink, SetTotalBacklink] = React.useState([]);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  // start Document ready Fuction

  useEffect(() => {
    var UserDetails = GetUserDetails();
    if (UserDetails != null) {
      if (ObjBacklinks.IsAnalyze) {
        GetBackLinks(UserDetails.ClientID);
        GetBackLinksCount(UserDetails.ClientID);
      }
    }
  }, [ObjBacklinks.date, ObjBacklinks.IsAnalyze])

  useEffect(() => {
    document.title = `Backlink Pipeline | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
    }

    GetBackLinks(Details.ClientID);

  }, [Search, Page, RowsPerPage, SortedBy]);

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
    }

    GetBackLinksCount(Details.ClientID);
    GetBacklinkgraph(Details.ClientID);

  }, []);


  const GetBackLinks = (ClientID) => {
    SetIsLoading(true)
    var requestData = {
      RowsPerPage: RowsPerPage,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      ClientID: ClientID,
      Page: Page,
      Date: ObjBacklinks.IsAnalyze == true ? ObjBacklinks.date : null

    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/seoreporting/seobacklinkget",
      method: "POST",
      data: requestData,
    }).then(response => {
      if (response.data.StatusMessage == "SUCCESS") {
        if (response.data.PageData.length > 0) {
          DisplayDomain(response.data.Website)
          SetSeoBackLinkData(response.data.PageData);
          SetRlen(response.data.TotalCount);
          SetFlen(response.data.TotalCount);
          SetCountPage(response.data.PageCount);
          SetIsLoading(false)
          setIsDataLoaded(true);

        } else {
          SetSeoBackLinkData([])
          SetRlen(0)
          SetFlen(0)
          SetCountPage(0)
          SetIsLoading(false)
          setIsDataLoaded(true);

        }
      } else {
        SetSeoBackLinkData([])
        SetRlen(0)
        SetFlen(0)
        SetCountPage(0)
        SetIsLoading(false)
        setIsDataLoaded(true);

      }
    }).catch(error => {
      console.error('Error fetching Get BackLinks:', error);
    });
  };

  const GetBackLinksCount = (ClientID) => {
    var requestData = {
      ClientID: ClientID,
      Date: ObjBacklinks.IsAnalyze == true ? ObjBacklinks.date : null

    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/seoreporting/seobacklinkcountget",
      method: "POST",
      data: requestData,
    }).then(response => {
      if (response.data.StatusMessage == "SUCCESS") {
        if (response.data.PageData.length > 0) {
          SetSeoBackLinkCount(response.data.PageData);
          SetTotalLinkCount(response.data.TotalLinkCount)
        } else {
          SetSeoBackLinkCount([])
          SetTotalLinkCount(0)
        }
      } else {
        SetSeoBackLinkCount([])
        SetTotalLinkCount(0)
      }
    }).catch(error => {
      console.error('Error fetching Get BackLinks count:', error);
    });
  };

  //page change
  const PageChange = (data, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };
  //Search table list
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      var SearchedVal = document.getElementById("Search").value;
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };




  //sort data with title
  const SortData = (Field) => {
    if (SortedBy == 1) {
      SetSortedBy(-1);
    } else {
      SetSortedBy(1);
    }
    SetSortField(Field);

  };
  const ExportBacklinks = (ClientID) => {
    SetIsLoading(true)
    var requestData = {
      ClientID: ClientID,
      date: ObjBacklinks.IsAnalyze == true ? ObjBacklinks.date : null
    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/seoreporting/exportSeoBacklink",
      method: "POST",
      data: requestData,
    }).then((Result) => {
      if (Result.data.StatusMessage = "SUCCESS") {
        var url = Result.data.PageData;
        const a = document.createElement('a');
        a.href = url;
        a.click();
        URL.revokeObjectURL(url);

       
        toast.success(<div className="toastsize">Backlinks  <br />  Data exported successfully.</div>);
        SetIsLoading(false)
      }
      else {
        SetIsLoading(false)
      }

    }).catch(error => {
      console.error('Error fetching export BackLinks', error);
      SetIsLoading(false)
    });
  }

  const GetBacklinkgraph = (ClientID) => {
    var requestData = {
      ClientID: ClientID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/seoreporting/seobacklinkgraph",
      method: "POST",
      data: requestData,
    }).then(response => {
      if (response.data.StatusMessage == "SUCCESS") {
        if (response.data.PageData.length > 0) {
          var lstbacklinkweek = [], lstTotalBacklink = [];
          for (var i = 0; i < response.data.PageData.length; i++) {
            var item = response.data.PageData[i]
            lstbacklinkweek.push(item.WeekDay)
            lstTotalBacklink.push(item.TotalBackLinkCount)
          }
          SetBacklinkWeek(lstbacklinkweek)
          SetTotalBacklink(lstTotalBacklink);


        } else {
          SetBacklinkWeek([])
          SetTotalBacklink([]);

        }
      } else {
        SetBacklinkWeek([])
        SetTotalBacklink([]);

      }
    }).catch(error => {
      console.error('Error fetching Get Backlink graph:', error);
    });
  };

  var graphkeywordcount =
    [

      {
        name: 'Backlinks',
        color: '#404968',
        data: TotalBacklink
      },

    ]
  var graphkeywordopation = {
    chart: {
      type: 'bar',
      height: 380,
      stacked: true,
      toolbar: {
        show: false,  
      } 
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: 0,
          offsetY: 0
        }
      }
    }],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '13px',
              fontWeight: 900
            }
          }
        }
      },
    },
    xaxis: {
      //type: 'datetime',
      categories: BacklinkWeek,
    },
    legend: {
      position: "top",
      offsetY: 0,
      offsetX: 0,
    },
    fill: {
      opacity: 1
    }
  }

  return (

    <>
      {
        IsLoading ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : <></>
      }
      <div>

        <div className="row">
          <div className="col">
            <div className="py-0 pb-2">
              <div className="row">
                <div className="col-md-6 mb-2">
                  <div className="bg-white h-100 p-2 px-3">
                    <div className="chartboxsmet">
                      <h6 className="chartheading mb-5">Backlink Count</h6>
                      <ReactApexChart options={graphkeywordopation} series={graphkeywordcount} type="bar" height={350} />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-2">
                  <div className="h-100">
                  <div className="row">
                    <h4 className="chartheading mt-0 mb-3 pt-0">Backlink Profile Summary</h4>
                  </div>
                  <div className="nodebored">
                    <div className="table-bordered my-0">
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Rank</TableCell>
                            <TableCell>Link Count</TableCell>
                            <TableCell>Percentage</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>

                          <TableRow>
                            <TableCell>0-9</TableCell>
                            <TableCell>{SeoBackLinkCount[0]?.totalDomains || 0}</TableCell>
                            <TableCell>{SeoBackLinkCount[0]?.totalDomains > 0 ? Math.round((SeoBackLinkCount[0]?.totalDomains / TotalLinkCount) * 100) : 0}%</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>10-19</TableCell>
                            <TableCell>{SeoBackLinkCount[1]?.totalDomains || 0}</TableCell>
                            <TableCell>{SeoBackLinkCount[1]?.totalDomains > 0 ? Math.round((SeoBackLinkCount[1]?.totalDomains / TotalLinkCount) * 100) : 0}%</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>20-29</TableCell>
                            <TableCell>{SeoBackLinkCount[2]?.totalDomains || 0}</TableCell>
                            <TableCell>{SeoBackLinkCount[2]?.totalDomains > 0 ? Math.round((SeoBackLinkCount[2]?.totalDomains / TotalLinkCount) * 100) : 0}%</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>30-39</TableCell>
                            <TableCell>{SeoBackLinkCount[3]?.totalDomains || 0}</TableCell>
                            <TableCell>{SeoBackLinkCount[3]?.totalDomains > 0 ? Math.round((SeoBackLinkCount[3]?.totalDomains / TotalLinkCount) * 100) : 0}%</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>40-49</TableCell>
                            <TableCell>{SeoBackLinkCount[4]?.totalDomains || 0}</TableCell>
                            <TableCell>{SeoBackLinkCount[4]?.totalDomains > 0 ? Math.round((SeoBackLinkCount[4]?.totalDomains / TotalLinkCount) * 100) : 0}%</TableCell>
                          </TableRow>


                          <TableRow>
                            <TableCell>50-59</TableCell>
                            <TableCell>{SeoBackLinkCount[5]?.totalDomains || 0}</TableCell>
                            <TableCell>{SeoBackLinkCount[5]?.totalDomains > 0 ? Math.round((SeoBackLinkCount[5]?.totalDomains / TotalLinkCount) * 100) : 0}%</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>60-69</TableCell>
                            <TableCell>{SeoBackLinkCount[6]?.totalDomains || 0}</TableCell>
                            <TableCell>{SeoBackLinkCount[6]?.totalDomains > 0 ? Math.round((SeoBackLinkCount[6]?.totalDomains / TotalLinkCount) * 100) : 0}%</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>70-79</TableCell>
                            <TableCell>{SeoBackLinkCount[7]?.totalDomains || 0}</TableCell>
                            <TableCell>{SeoBackLinkCount[7]?.totalDomains > 0 ? Math.round((SeoBackLinkCount[7]?.totalDomains / TotalLinkCount) * 100) : 0}%</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>80-89</TableCell>
                            <TableCell>{SeoBackLinkCount[8]?.totalDomains || 0}</TableCell>
                            <TableCell>{SeoBackLinkCount[8]?.totalDomains > 0 ? Math.round((SeoBackLinkCount[8]?.totalDomains / TotalLinkCount) * 100) : 0}%</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>90-100</TableCell>
                            <TableCell>{SeoBackLinkCount[9]?.totalDomains || 0}</TableCell>
                            <TableCell>{SeoBackLinkCount[9]?.totalDomains > 0 ? Math.round((SeoBackLinkCount[9]?.totalDomains / TotalLinkCount) * 100) : 0}%</TableCell>
                          </TableRow>
                          <TableRow className="footer-table">
                            <TableCell>Total</TableCell>
                            <TableCell>{TotalLinkCount}</TableCell>
                            <TableCell>100%</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                 </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white px-4 pb-4">
          <div>
            <div className="row">
              <div className="col"></div>
              <div className="col-lg-6">
                <div className="listing-li float-right pt-2">
                  <ul>
                    <li>
                      <a onClick={() => ExportBacklinks(ClientID)} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                        <i class="la la-icon-font-size-13 la-download"></i>Export
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>


          </div>
          <div className="row padt-25">
            <div className="col-sm-12 col-md-6">
              <label className="textlabelte">
                Show
                <select
                  name="tbl_meeting_length"
                  onChange={ChangeRowSelected}
                  aria-controls="tbl_meeting"
                  class="form-control form-control-sm"
                  value={RowsPerPage}
                >
                  {CommonConstants.show_rows.map((value) => (
                    <option value={value}>{value}</option>
                  ))}
                </select>
                entries
              </label>
            </div>
            <div className="col-sm-12 col-md-6 full-right">
              <label class="textlabelte">
                Search:{" "}
                <input
                  type="Search"
                  id="Search"
                  onKeyPress={RequestSearch}
                  class="form-control form-control-sm ml-2"
                  placeholder=""
                  aria-controls="tbl_meeting"
                />
              </label>
            </div>
          </div>
          <div className="table-bordered">
            <TableContainer component={Paper}>
            <StyleHeader isDataLoaded={isDataLoaded} />
              <Table className="table-ref" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell><a onClick={() => { SortData("first_seen"); }}>First Seen Date<span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "first_seen" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "first_seen" ? "active" : null} />
                    </span></a></TableCell>
                    <TableCell className="wrap"><a onClick={() => { SortData("source_url"); }}>Source URL<span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "source_url" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "source_url" ? "active" : null} />
                    </span></a></TableCell>
                    <TableCell className="wrap"><a onClick={() => { SortData("target_url"); }}>Target URL<span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "target_url" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "target_url" ? "active" : null} />
                    </span></a></TableCell>
                    <TableCell className="wrap"><a onClick={() => { SortData("anchor"); }}>Anchor<span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "anchor" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "anchor" ? "active" : null} />
                    </span></a></TableCell>
                    <TableCell className="wrap"><a onClick={() => { SortData("source_title"); }}>Source Title<span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "source_title" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "source_title" ? "active" : null} />
                    </span></a></TableCell>
                    <TableCell><a onClick={() => { SortData("page_ascore"); }}>Page Ascore<span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "page_ascore" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "page_ascore" ? "active" : null} />
                    </span></a></TableCell>
                    <TableCell><a onClick={() => { SortData("response_code"); }}>Response Code<span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "response_code" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "response_code" ? "active" : null} />
                    </span></a></TableCell>
                    <TableCell><a onClick={() => { SortData("source_size"); }}>Source Size<span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "source_size" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "source_size" ? "active" : null} />
                    </span></a></TableCell>
                    <TableCell><a onClick={() => { SortData("nofollow"); }}>Nofollow<span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "nofollow" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "nofollow" ? "active" : null} />
                    </span></a></TableCell>
                    <TableCell><a onClick={() => { SortData("newlink"); }}>Newlink<span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "newlink" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "newlink" ? "active" : null} />
                    </span></a></TableCell>
                    <TableCell><a onClick={() => { SortData("lostlink"); }}>Lostlink<span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "lostlink" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "lostlink" ? "active" : null} />
                    </span></a></TableCell>
                    <TableCell>Domain</TableCell>


                    <TableCell><a onClick={() => { SortData("last_seen"); }}>Last Seen Date<span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "last_seen" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "last_seen" ? "active" : null} />
                    </span></a></TableCell>
                    <TableCell>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {SeoBackLinkData.length === 0 ? (
                    <p className="text-center">No data available in table</p>
                  ) : (
                    SeoBackLinkData?.map((row) => (
                      <TableRow>
                        <TableCell>{row?.first_seen}</TableCell>
                        <TableCell className="wrap"><a className="uline" href={row?.source_url} target="_blank">{row?.source_url}</a></TableCell>
                        <TableCell className="wrap"><a className="uline" href={row?.target_url} target="_blank">{row?.target_url}</a></TableCell>
                        <TableCell className="wrap">{row?.anchor}</TableCell>
                        <TableCell className="wrap">{row?.source_title}</TableCell>
                        <TableCell>{row?.page_ascore}</TableCell>
                        <TableCell>{row?.response_code}</TableCell>
                        <TableCell>{row?.source_size}</TableCell>
                        <TableCell>{row?.nofollow}</TableCell>
                        <TableCell>{row?.newlink}</TableCell>
                        <TableCell>{row?.lostlink}</TableCell>
                        <TableCell>{row?.DomainName}</TableCell>

                        <TableCell>{row?.last_seen}</TableCell>
                        <TableCell>{row?.Date}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {Sflag ? (
            <div class="row">
              <Pagination
                component="div"
                count={CountPage}
                page={Page}
                onChange={PageChange}
                showFirstButton
                showLastButton
              />
              <div class="col dataTables_info">
                <p>
                  Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                  {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
                  entries (filtered from {Flen} total entries)
                </p>
              </div>
            </div>
          ) : (
            <div class="row">
              <div class="col dataTables_info">
                <p>
                  Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                  {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
                  entries
                </p>
              </div>
              <div class="col pageright">
                <Pagination
                  component="div"
                  count={CountPage}
                  page={Page}
                  onChange={PageChange}
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          )}
        </div>

      </div>
    </>
  );
}