import React, { useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
const Moment = require("moment");
import "react-toastify/dist/ReactToastify.css";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Pagination from "@material-ui/lab/Pagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import contactlistloadingicon from "../../../images/loading.gif";

export default function ListTable({
  handClose,
  CampaignId,
  onDataFromContact,
  updateFromChild,
}) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("ProspectID");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [contactListData, setContactListData] = React.useState([]);
  const [selectAllChecked, setSelectAllChecked] = React.useState(false);
  const [individualChecks, setIndividualChecks] = React.useState({});
  const [IsloadingEss, SetIsloadingEss] = React.useState(true);
  const [CUserID, SetCUserID] = React.useState(0);
  useEffect(() => {
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    ListGet(GetUserData.ClientID, GetUserData.ParentUserID, GetUserData.Role);
  }, [Search, Page, RowsPerPage, handClose]);

  //get contact list
  const ListGet = (CID, UID, Role) => {
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: Role,
    };
    const listDatas = Axios({
      url: CommonConstants.MOL_APIURL + "/list/ListGet",
      method: "POST",
      data: InputParameter,
    });
    listDatas.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      // document.getElementById("hideloding").style.display = "none";
      SetIsloadingEss(false);
    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1);
      // document.getElementById("hideloding").style.display = "none";
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      // document.getElementById("hideloding").style.display = "block";
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    // document.getElementById("hideloding").style.display = "block";
  };

  // All check handler
  const checkHandler = (e) => {
    const isChecked = e.target.checked;
    setSelectAllChecked(isChecked);

    if (isChecked) {
      setContactListData(Rows.map((item) => ({ ListID: item.ListID })));
    } else {
      setContactListData([]);
    }

    // Update individual checkboxes state
    const newChecks = {};
    Rows.forEach((row) => {
      newChecks[row.ListID] = isChecked;
    });
    setIndividualChecks(newChecks);
  };

  // Add or remove from the list contact
  const checkBoxAdd = (e, ID) => {
    const isChecked = e.target.checked;

    // Update individual checkbox state
    setIndividualChecks((prevChecks) => ({
      ...prevChecks,
      [ID]: isChecked,
    }));

    if (isChecked) {
      setContactListData((prevData) => [...prevData, { ListID: ID }]);
    } else {
      setContactListData((prevData) =>
        prevData.filter((item) => item.ListID !== ID)
      );
    }

    // Update select all checkbox state
    const areAllChecked = Rows.every((row) => individualChecks[row.ListID]);
    setSelectAllChecked(areAllChecked);
  };

  //Add and Update list contact with multiple check
  const SaveListBtn = () => {
    let IsMoveExistToCurrentCampaign = document.getElementById(
      "IsMoveExistToCurrentCampaign"
    ).checked;
    if (contactListData.length > 0) {
      // Step 1: Set loading to true
      SetIsloadingEss(true);
      var data = {
        CheckedArrayList: contactListData,
        CampaignID: CampaignId,
        UserID: UserID,
        ClientID: ClientID,
        CreatedBy: CUserID,
        CreatedDate: new Date(),
        IsMoveExistToCurrentCampaign: IsMoveExistToCurrentCampaign,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/prospect_campaign/CampaginAddToListsFromContact",
        method: "POST",
        data: data,
      })
        .then((Res) => {
          if (Res.data.StatusMessage === "SUCCESS") {
            toast.success("Added contact successfully");
            onDataFromContact(false);
            updateFromChild(CampaignId);
            ListGet(ClientID, UserID, Role);
          } else {
            toast.error(Res.data.Message);
            onDataFromContact(false);
            updateFromChild(CampaignId);
            ListGet(ClientID, UserID, Role);
          }
        })
        .catch((error) => {
          toast.error("An error occurred while saving.");
        })
        .finally(() => {
          // Step 3: Set loading back to false when the request is complete
          SetIsloadingEss(false);
        });
    } else {
      toast.error("Please select at least one contact from the list.");
      // Step 3: Set loading back to false when there's no data to save
      SetIsloadingEss(false);
    }
  };

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("search").value;
    // SetPage(1);
    // SetRowsPerPage(10);
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };

      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/list/ListGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };

      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/list/ListGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
      });
    }
  };
  const popupclose = () => {
    onDataFromContact(false);
  };
  console.log(contactListData, "vdfvd");
  return (
    <div>
      <div className="row">
        <div className="col">
          <label class="d-flex">
            <div class="toggleswich mr-2">
              <input
                type="checkbox"
                class="checktogle"
                id="IsMoveExistToCurrentCampaign"
              />
              <b class="switch grey">
                <span class="checkion"></span>
                <span class="uncheckion"></span>
              </b>
              <b class="track"></b>
            </div>
            Move existing contacts to current Campaign
          </label>
        </div>
      </div>
      {IsloadingEss ? (
        <div id="hideloding" className="loding-display">
          <img src={contactlistloadingicon} />
        </div>
      ) : null}
      <div className="row pb-3 pt-2">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte pull-left mt-2">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <input
                    name="select_all_contacts"
                    type="checkbox"
                    checked={selectAllChecked}
                    onChange={(e) => checkHandler(e)}
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <a
                    className="px-0"
                    onClick={() => {
                      SortData("Name");
                    }}
                  >
                    Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    className="px-0"
                    onClick={() => {
                      SortData("TotalCount");
                    }}
                  >
                    Total Contact
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    className="px-0"
                    onClick={() => {
                      SortData("CreatedDate");
                    }}
                  >
                    Created Date
                  </a>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length == 0 ? (
                <p>No data available in table</p>
              ) : (
                Rows.map((row) => (
                  <TableRow>
                    <TableCell>
                      <input
                        type="checkbox"
                        id={row.ListID}
                        checked={individualChecks[row.ListID] || false}
                        onChange={(e) => checkBoxAdd(e, row.ListID)}
                      />
                    </TableCell>
                    <TableCell>{row?.Name}</TableCell>
                    <TableCell>{row?.TotalProspect}</TableCell>
                    <TableCell>
                      {Moment(new Date(row.CreatedDate).toDateString()).format(
                        "MM/DD/YYYY"
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}

      <div className="modal-footer px-0">
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={popupclose}
        >
          Cancel
        </button>
        <button
          type="button"
          id="popup-close"
          className="btn btn-primary btn-lightgreen"
          onClick={SaveListBtn}
        >
          <i className="la la-save"></i> Save
        </button>
      </div>
    </div>
  );
}
