import React from "react";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import DefaultCountryBlacklist from "../../_components/user/DefaultCountryBlackList/DefaultCountryBlackList";
import Footer from "../../_components/user/footer/footer";

import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { history } from "../../../src/_helpers";

class DefaultCountryBlacklistPage extends React.Component {
  // add button
  AddBtn() {
    history.push("/adddefaultcountryblacklist");
  }
  componentDidMount(){

  }

 //Reload page while delete perform
UpdateFromChild = (value) => {
  if(value == true){
    this.componentDidMount()
  }
}

  render() {
    return (
      <>
        <div className="adminmain mheight">
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitle float-left py-4 pl-0">
                Country Blacklist 
                </h4>
                <div className="listing-li float-right padb-15">
                  <ul>
                    <li>
                      <a
                        onClick={this.AddBtn.bind(this)}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="paddcols px-5">
              <div className="row">
                <div className="col px-0"> 
                    <DefaultCountryBlacklist updateFromChild={this.UpdateFromChild}/> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedDefaultCountryBlacklistPage = connect(
  mapState,
  actionCreators
)(DefaultCountryBlacklistPage);
export { connectedDefaultCountryBlacklistPage as DefaultCountryBlacklistPage };
