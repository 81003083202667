import React, { useEffect, useRef } from "react";
const moment = require("moment");
import Axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Pagination from "@material-ui/lab/Pagination";
import Popup from "reactjs-popup";
import $ from "jquery";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { GetClientDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
const Fileupload = require("../../../_helpers/fileupload");

import StyleHeader from "../StickyHeader/StickyHeader";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Popover from '@mui/material/Popover';

// UseRowStyles
const UseRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable({ AccountFieldType, AccountFieldName }) {
  const [Open, SetOpen] = React.useState(false);
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [SFlag, SetSFlag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [AID, SetAID] = React.useState("");
  const [Checks, SetChecks] = React.useState(false);
  const [OriginalData, SetOriginalData] = React.useState([]);
  const [SortField, SetSortField] = React.useState("AccountName");
  const [sortedColumn, setSortedColumn] = React.useState("Account Name");
  const [SortBy, SetSortBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [TypeList, SetTypeList] = React.useState([]);
  const [HeaderList, SetHeaderList] = React.useState([]);
  const [Reset, SetReset] = React.useState(false);
  const [DropboxData, SetDropboxData] = React.useState([]);
  const [FileName, SetFileName] = React.useState("");
  const [Csvdata1, SetCsvdata1] = React.useState([]);
  const [Files, SetFiles] = React.useState([]);
  const [AddArray, SetAddArray] = React.useState([]);
  const [ExportData, SetExportData] = React.useState([]);
  const [AccountName, SetAccountName] = React.useState(null);
  // const [Popup, SetPopup] = React.useState(false);
  const [Cname, SetCname] = React.useState("");
  const [AccountNameLong, SetAccountNameLong] = React.useState(null);
  const [EmailDomains, SetEmailDomains] = React.useState(null);
  const [Website, SetWebsite] = React.useState(null);
  const [Address1, SetAddress1] = React.useState(null);
  const [Address2, SetAddress2] = React.useState(null);
  const [City, SetCity] = React.useState(null);
  const [State, SetState] = React.useState(null);
  const [Zip, SetZip] = React.useState(null);
  const [Country, SetCountry] = React.useState(null);
  const [AccountCategory, SetAccountCategory] = React.useState(null);
  const [Industry, SetIndustry] = React.useState(null);
  const [AccountOwner, SetAccountOwner] = React.useState(null);
  const [AccountStatus, SetAccountStatus] = React.useState(null);
  const [ReasonUnqualified, SetReasonUnqualified] = React.useState(null);
  const [ClientStatus, SetClientStatus] = React.useState(null);
  const [AccountType, SetAccountType] = React.useState(null);
  const [CompanyPhone, SetCompanyPhone] = React.useState(null);
  const [CompanyRevenue, SetCompanyRevenue] = React.useState(null);
  const [EmployeeCount, SetEmployeeCount] = React.useState(null);
  const [AccountNotes, SetAccountNotes] = React.useState(null);
  const [TotalDisplayCount, SetTotalDisplayCount] = React.useState(false);
  const [TotalResult, SetTotalResult] = React.useState(0);
  const [TotalDuplicate, SetTotalDuplicate] = React.useState(0);
  const [TotalError, SetTotalError] = React.useState(0);
  const [TotalSuccess, SetTotalSuccess] = React.useState(0);
  const [TotalSkipped, SetTotalSkipped] = React.useState(0);
  const [TotalInvalid, SetTotalInvalid] = React.useState(0);
  const [AccountCount, SetAccountCount] = React.useState([]);
  const [ShowMergePopup, SetShowMergePopup] = React.useState(false);
  const [OwnerMergeDetail, SetOwnerMergeDetail] = React.useState([]);
  const [AccountCategoryMergeDetail, SetAccountCategoryMergeDetail] = React.useState([]);
  const [MergeDomainName, SetMergeDomainName] = React.useState("");
  const [AccountCustomMergeDetail, SetAccountCustomMergeDetail] = React.useState([]);
  const [AccountCustomMergeValueDetail, SetAccountCustomMergeValueDetail] = React.useState([]);

  //Filter States
  const [OpenFilterModel, SetOpenFilterModel] = React.useState(false);
  const [AddFilterModel, SetAddFilterModel] = React.useState(false);
  const [OpenSelectFilterModel, SetOpenSelectFilterModel] = React.useState(false);
  const [CustomColumnData, SetCustomColumnData] = React.useState([]);
  const [FieldAttributes, SetFieldAttributes] = React.useState([
    {
      id: Math.floor(Math.random() * 1000000000),
      ColumnName: "AccountCategory",
      InputType: "Contains",
      IsCustom: false,
      IsDate: false,
      IsBool: false,
    },
  ]);
  const [FilterParameters, SetFilterParameters] = React.useState([]);
  const [IsApply, SetIsApply] = React.useState(false);
  const [FilterName, SetFilterName] = React.useState([]);
  const [EditFieldAttributes, SetEditFieldAttributes] = React.useState([]);
  const [DeletedFilterProperty, SetDeletedFilterProperty] = React.useState([]);
  const [FilterID, SetFilterID] = React.useState(null);
  const [Role, SetRole] = React.useState(null);
  const [ParentUserID, SetParentUserID] = React.useState(null);
  const [SelectOptionValue, SetSelectOptionValue] = React.useState("Contains");
  const [ImportDisabled, SetImportDisabled] = React.useState(false);
  const [Key, SetKey] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(true);
  const [isSaveDisabled, setIsSaveDisabled] = React.useState(false);
  const [CsvFileName,setCsvFileName] =React.useState("")
  const [isVisible, setIsVisible] = React.useState(false); 
  const [anchorEl, setAnchorEl] = React.useState(null);

  const settinghandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const settinghandleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  //Merge Account State
  const [MergeData, SetMergeData] = React.useState({
    AccountName: [],
    AccountNameLong: [],
    Website: [],
    Industry: [],
    Address1: [],
    Address2: [],
    City: [],
    State: [],
    Zip: [],
    Country: [],
    CustomColumn: []
  });

  const [DisabledSaveFilter, SetDisabledSaveFilter] = React.useState(false)
  const [DisabledBulkDelete, SetDisabledBulkDelete] = React.useState(false)
  const [SubTabData, SetSubTabData] = React.useState([])

  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  useEffect(() => {
    document.title = `Account | SalesHive`;
    var GetAccountcateList = GetUserDetails();
    if (GetAccountcateList != null) {
      SetClientID(GetAccountcateList.ClientID);
      SetUserID(GetAccountcateList.ParentUserID);
      SetParentUserID(GetAccountcateList.ChildUserID);
      SetRole(GetAccountcateList.Role);
    }

    GetClientDetails(GetAccountcateList.ClientID).then((result) => {
      SetCname(result[0]?.Name);
      GenrateCsvFilename(result[0]?.Name)
    });
    HeaderListData(GetAccountcateList.ClientID);
    GetAccounlist(GetAccountcateList.ClientID, GetAccountcateList.ParentUserID);
   // Exportdetails(GetAccountcateList.ClientID,GetAccountcateList.ParentUserID,IsApply,FilterParameters);
    GetCustomFieldsData(GetAccountcateList.ClientID);
    Getfilterlist(GetAccountcateList.ClientID);
  }, [Search, Page, RowsPerPage]);
  // }, [Search, Page, RowsPerPage, IsApply,FilterParameters]);

  //Genrate csv file name
  const GenrateCsvFilename = (ClientName) => {
    let Tabname = AccountFieldName?.split(" ")?.join("_");
    if(AccountFieldName === 'All'){
      setCsvFileName(`${ClientName}-Accounts`)
    }else{
      setCsvFileName(`${ClientName}-Accounts-${Tabname}`)
    } 
  }

  const HeaderListData = (CId) => {
    var InputParameters = {
      ClientID: CId,
      Role: Role,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/HeaderListGet",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((Result) => {
      if (Result.data.StatusMessage === "SUCCESS") {
        SetHeaderList(Result.data.HeaderDetails);
        SetAccountNotes(Result.data.Notesdetails);
      } else {
        SetHeaderList([]);
        SetAccountNotes([]);
        toast.error("Something wnent wrong !")
      }

    });
  };

  // GetAccounlist
  const GetAccounlist = (CID, UID) => {
    SetIsLoading(true)
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      AccountTypeID: AccountFieldType,
      FilterParameters: FilterParameters,
      IsApply: IsApply,
      Role: Role,
    };

    const GetAllAccountList = Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/GetAccountlist",
      method: "POST",
      data: InputParameter,
    });
    GetAllAccountList.then((Result) => {
      if (Result.data.StatusMessage === 'SUCCESS') {
        SetData(Result.data.PageData);
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false)
        setIsDataLoaded(true);
      } else {
        SetData([]);
        SetRows([]);
        SetRlen(0);
        SetFlen(0);
        SetCountPage(0);
        SetIsLoading(false)
        toast.error("Something went wrong !")
        setIsDataLoaded(true);
      }

      //document.getElementById("hideloding").style.display = "none";
    });
  };

  //Search document
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value.trim();

      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
        //document.getElementById("hideloding").style.display = "block";
      }
    }
  };

  //Pagination document
  const HandleChangePage = (event, newPage) => {
    document.body.scrollTop = 0;
    if (newPage == Page) {
      SetPage(newPage);
    } else {
      SetPage(newPage);
      // document.getElementById("hideloding").style.display = "block";
    }
  };

  // Change Row Selected
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    // document.getElementById("hideloding").style.display = "block";
  };

  //Delete document meeting
  const DeleteClientName = (ID, data) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a Account.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {

        var InputParameter = {
          _id: ID,
          EmailDomain: data.EmailDomains,
          ClientID: data.ClientID,
          UserID: data.UserID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: UserID,
          Role: Role,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/accounts/DeleteAccountList",
          method: "POST",
          data: InputParameter,
        }).then((res) => {

          if ((res.data.StatusMessage = "SUCCESS")) {
            Swal.fire("Deleted!", "Account deleted successfully.", "success");
            // document.getElementById("hideloding").style.display = "block";
            GetAccounlist(ClientID, UserID);
            history.push("/accounts");
            SetPage(1);
            SetSearch("");
            document.getElementById("search").value = "";
          } else {
            toast.error("Something Went Wrong !")
          }

        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // Export details
  const Exportdetails = async(CID,UID,FIsApply,FilterParameters) => {
    let InputParameter = {
      ClientID: CID,
      UserID:UID,
      AccountTypeID: AccountFieldType,
      Role: Role,
      IsApply:FIsApply,
      FilterParameters:FilterParameters
    };
    const RowsAccounts = await Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/ExportAccount",
      method: "POST",
      data: InputParameter,
    })
      if (RowsAccounts.data.StatusMessage === "SUCCESS") {
        return RowsAccounts?.data
      }
  };


  const exportcsv = async() => {
    try {
     SetIsLoading(true)
      const result = await Exportdetails(ClientID,UserID,IsApply,FilterParameters);
      if (result.StatusMessage === "SUCCESS") {
        const data = result.Data;
        if (data.length > 0) {
          SetExportData(data);
          const downloader = document.createElement("a");
          const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(convertToCSV(data));
          downloader.setAttribute("href", csvContent);
          downloader.setAttribute("download", Cname+"-Accounts.csv");
          downloader.click();
          toast.success(<div>Data exported successfully.</div>);
         SetIsLoading(false)
        } else {
          toast.error("No data available for export.");
         SetIsLoading(false)
        }
      } else {
        toast.error("Failed to export data. Please try again.");
       SetIsLoading(false)
      }
    } catch (error) {
      SetIsLoading(false)
      toast.error("An error occurred while exporting data.");
    }
  };

  
  function convertToCSV(data) {
    const headers = Object.keys(data[0]);

    const csvRows = [];
    csvRows.push(headers.join(','));

    data.forEach(item => {
        const values = headers.map(header => {
            // Check if the value is a string before attempting to replace characters
            const value = typeof item[header] === 'string' ? item[header] : String(item[header]);
            const escapedValue = value.replace(/"/g, '""'); // Escape double quotes
            return `"${escapedValue}"`; // Enclose value in double quotes
        });
        csvRows.push(values.join(','));
    });

    return csvRows.join('\n');
}

  const ExportDataTemp = (e) => {
    e.preventDefault();
    toast.success(<div>Data exported successfully.</div>);
  };

  // handle on drop
  const HandleOnDrop = (data, fileInfo) => {
    var FileName = fileInfo.name;
    SetFiles(fileInfo);
    var sep = FileName.split(".");
    if (sep[sep.length - 1] != "csv") {
      SetReset(true);
      ResetCsv();
    } else {
      var csvfile = sep.slice(0, -1).join(".") + new Date().getTime() + "." + sep[sep.length - 1];
      // Filter out rows with all empty strings
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''));
      SetFileName(csvfile);
      SetCsvdata1(filteredData);
      SetDropboxData(filteredData[0].data);
    }
  };

  // handle on error
  const HandleOnError = (err, file, inputElem, reason) => { };

  // handle on remove file
  const HandleOnRemoveFile = (data) => { };

  //Hide used option
  const hideCSVHeaderOptions = () => {
    var $select = $(".csvcontent");
    $select.find("option").show();
    $select.each(function () {
      var $this = $(this);
      var value = $this.val();
      var $options = $this
        .parents("#divAccountSelection")
        .find(".csvcontent")
        .not(this)
        .find("option");
      var $option = $options.filter('[value="' + value + '"]');
      if (value) {
        $option.hide();
      }
    });
  };

  $("select").on("change", function () {
    var $this = $(this);
    var value = $this.val();
    var Name = $(this).attr("id");

    if (Name == "Account Name") {
      SetAccountName(value);
    } else if (Name == "Account Name Long") {
      SetAccountNameLong(value);
    } else if (Name == "EmailDomains") {
      SetEmailDomains(value);
    } else if (Name == "Website") {
      SetWebsite(value);
    } else if (Name == "Address 1") {
      SetAddress1(value);
    } else if (Name == "Address 2") {
      SetAddress2(value);
    } else if (Name == "City") {
      SetCity(value);
    } else if (Name == "State") {
      SetState(value);
    } else if (Name == "Zip") {
      SetZip(value);
    } else if (Name == "Country") {
      SetCountry(value);
    } else if (Name == "AccountCategory") {
      SetAccountCategory(value);
    } else if (Name == "Industry") {
      SetIndustry(value);
    } else if (Name == "AccountStatus") {
      SetAccountStatus(value);
    } else if (Name == "Account Client Status") {
      SetClientStatus(value);
    } else if (Name == "AccountOwner") {
      SetAccountOwner(value);
    } else if (Name == "ReasonUnqualified") {
      SetReasonUnqualified(value);
    } else if (Name == "AccountType") {
      SetAccountType(value);
    } else if (Name == "CompanyPhone") {
      SetCompanyPhone(value);
    } else if (Name == "CompanyRevenue") {
      SetCompanyRevenue(value);
    } else if (Name == "EmployeeCount") {
      SetEmployeeCount(value);
    }
    hideCSVHeaderOptions();
  });

  // reset function
  const ResetCsv = () => {
    SetReset(!Reset);
  };

  // cancel button import
  const CancleAccountBtn = () => {
    ResetCsv();
    SetTotalDisplayCount(false);
    document.getElementById("map_attribute").style.display = "none";
    document.getElementById("import_Account_csv").style.display = "none";
  };

  // map csv
  const AccountMapCsv = () => {
    SetImportDisabled(false);
    document.getElementById("IsUpdateExistAccounts").disabled = false;
    document.getElementById("IsInsertNullValues").disabled = false;
    if (Csvdata1.length != 0) {
      document.getElementById("map_attribute").style.display = "block";

      ResetCsv();
      SetTotalDisplayCount(false);

      DropboxData.map((dddd, index) => { });
    } else {
      ResetCsv();
      toast.error(
        <div className="toastsize">
          Accounts <br />
          Please select file
        </div>
      );
      SetImportDisabled(true);
      document.getElementById("IsUpdateExistAccounts").disabled = true;
      document.getElementById("IsInsertNullValues").disabled = true;
    }
    hideCSVHeaderOptions();
  };

  // import csv
  const AccountImportCsv = () => {
    document.getElementById("import_Account_csv").style.display = "block";
    document.getElementById("map_attribute").style.display = "none";
    SetTotalDisplayCount(false);
    document.getElementById("delete_csv").style.display = "none";
    SetCsvdata1([]);
    SetImportDisabled(false);
    SetTotalDisplayCount(false)
  };

  const AccountSaveCsv = () => {
    //document.getElementById("hideloding").style.display = "block";
    SetIsLoading(true)


    // if (Csvdata1.length >= 100) {
    //   toast.success("Account file size is more than 100 successfully.");
    //   document.getElementById("hideloding").style.display = "none";
    // } else {
    var saveAllAccounts = [];
    var AccountName = parseInt(document.getElementById("AccountName").value);
    if (AccountName >= 0) {
      var ObjAccountName = {
        FieldAlias: "Account Name",
        Fieldname: "AccountName",
        Customfield: null,
        index: AccountName,
      };
      saveAllAccounts.push(ObjAccountName);
    }
    var AccountNameLong = parseInt(
      document.getElementById("AccountNameLong").value
    );
    if (AccountNameLong >= 0) {
      var ObjAccountNameLong = {
        FieldAlias: "Account Name Long",
        Fieldname: "AccountNameLong",
        Customfield: null,
        index: AccountNameLong,
      };
      saveAllAccounts.push(ObjAccountNameLong);
    }
    var EmailDomains = parseInt(
      document.getElementById("EmailDomains").value
    );
    if (EmailDomains >= 0) {
      var ObjEmailDomains = {
        FieldAlias: "Email Domains",
        Fieldname: "EmailDomains",
        Customfield: null,
        index: EmailDomains,
      };
      saveAllAccounts.push(ObjEmailDomains);
    }
    var Website = parseInt(document.getElementById("Website").value);
    if (Website >= 0) {
      var ObjWebsite = {
        FieldAlias: "Website",
        Fieldname: "Website",
        Customfield: null,
        index: Website,
      };
      saveAllAccounts.push(ObjWebsite);
    }
    var Industry = parseInt(document.getElementById("Industry").value);
    if (Industry >= 0) {
      var ObjIndustry = {
        FieldAlias: "Industry",
        Fieldname: "Industry",
        Customfield: null,
        index: Industry,
      };
      saveAllAccounts.push(ObjIndustry);
    }

    var Address1 = parseInt(document.getElementById("Address1").value);
    if (Address1 >= 0) {
      var ObjAddress1 = {
        FieldAlias: "Address 1",
        Fieldname: "Address1",
        Customfield: null,
        index: Address1,
      };
      saveAllAccounts.push(ObjAddress1);
    }

    var Address2 = parseInt(document.getElementById("Address2").value);
    if (Address2 >= 0) {
      var ObjAddress2 = {
        FieldAlias: "Address 2",
        Fieldname: "Address2",
        Customfield: null,
        index: Address2,
      };
      saveAllAccounts.push(ObjAddress2);
    }
    var City = parseInt(document.getElementById("City").value);
    if (City >= 0) {
      var ObjCity = {
        FieldAlias: "City",
        Fieldname: "City",
        Customfield: null,
        index: City,
      };

      saveAllAccounts.push(ObjCity);
    }
    var State = parseInt(document.getElementById("State").value);
    if (State >= 0) {
      var ObjState = {
        FieldAlias: "State",
        Fieldname: "State",
        Customfield: null,
        index: State,
      };
      saveAllAccounts.push(ObjState);
    }

    var Zip = parseInt(document.getElementById("Zip").value);
    if (Zip >= 0) {
      var ObjZip = {
        FieldAlias: "Zip",
        Fieldname: "Zip",
        Customfield: null,
        index: Zip,
      };
      saveAllAccounts.push(ObjZip);
    }

    var Country = parseInt(document.getElementById("Country").value);
    if (Country >= 0) {
      var ObjCountry = {
        FieldAlias: "Country",
        Fieldname: "Country",
        Customfield: null,
        index: Country,
      };
      saveAllAccounts.push(ObjCountry);
    }
    var AccountCategory = parseInt(
      document.getElementById("AccountCategory").value
    );
    if (AccountCategory >= 0) {
      var ObjAccountCategory = {
        FieldAlias: "Account Category",
        Fieldname: "AccountCategory",
        Customfield: null,
        index: AccountCategory,
      };
      saveAllAccounts.push(ObjAccountCategory);
    }
    var AccountOwner = parseInt(
      document.getElementById("AccountOwner").value
    );
    if (AccountOwner >= 0) {
      var ObjAccountOwner = {
        FieldAlias: "Account Owner",
        Fieldname: "AccountOwner",
        Customfield: null,
        index: AccountOwner,
      };
      saveAllAccounts.push(ObjAccountOwner);
    }
    var AccountStatus = parseInt(
      document.getElementById("AccountStatus").value
    );
    if (AccountStatus >= 0) {
      var ObjAccountStatus = {
        FieldAlias: "Account Status",
        Fieldname: "AccountStatus",
        Customfield: null,
        index: AccountStatus,
      };
      saveAllAccounts.push(ObjAccountStatus);
    }
    var ClientStatus = parseInt(
      document.getElementById("ClientStatus").value
    );
    if (ClientStatus >= 0) {
      var ObjClientStatus = {
        FieldAlias: "Client Status",
        Fieldname: "ClientStatus",
        Customfield: null,
        index: ClientStatus,
      };
      saveAllAccounts.push(ObjClientStatus);
    }
    var ReasonUnqualified = parseInt(
      document.getElementById("ReasonUnqualified").value
    );
    if (ReasonUnqualified >= 0) {
      var ObjReasonUnqualified = {
        FieldAlias: "Reason Unqualified",
        Fieldname: "ReasonUnqualified",
        Customfield: null,
        index: ReasonUnqualified,
      };
      saveAllAccounts.push(ObjReasonUnqualified);
    }
    var AccountType = parseInt(document.getElementById("AccountType").value);
    if (AccountType >= 0) {
      var ObjAccountType = {
        FieldAlias: "Account Type",
        Fieldname: "AccountType",
        Customfield: null,
        index: AccountType,
      };
      saveAllAccounts.push(ObjAccountType);
    }
    var CompanyRevenue = parseInt(
      document.getElementById("CompanyRevenue").value
    );
    if (CompanyRevenue >= 0) {
      var ObjCompanyRevenue = {
        FieldAlias: "Company Revenue",
        Fieldname: "CompanyRevenue",
        Customfield: null,
        index: CompanyRevenue,
      };
      saveAllAccounts.push(ObjCompanyRevenue);
    }
    var CompanyPhone = parseInt(
      document.getElementById("CompanyPhone").value
    );
    if (CompanyPhone >= 0) {
      var ObjCompanyPhone = {
        FieldAlias: "Company Phone",
        Fieldname: "CompanyPhone",
        Customfield: null,
        index: CompanyPhone,
      };
      saveAllAccounts.push(ObjCompanyPhone);
    }
    var EmployeeCount = parseInt(
      document.getElementById("EmployeeCount").value
    );
    if (EmployeeCount >= 0) {
      var ObjEmployeeCount = {
        FieldAlias: "Employee Count",
        Fieldname: "EmployeeCount",
        Customfield: null,
        index: EmployeeCount,
      };
      saveAllAccounts.push(ObjEmployeeCount);
    }

    var IsUpdateExistAccounts = document.getElementById(
      "IsUpdateExistAccounts"
    ).checked;
    if (IsUpdateExistAccounts == false) {
      IsUpdateExistAccounts = true;
    } else {
      IsUpdateExistAccounts = false;
    }
    var IsInsertNullValues =
      document.getElementById("IsInsertNullValues").checked;

    const emailDomainToCheck = 'Email Domains';

    const emailDomainExists = saveAllAccounts.some(field => field.FieldAlias === emailDomainToCheck);

    if (emailDomainExists) {
      var Csvdata = Csvdata1;
      var Csvdata2 = Csvdata1.length;
      Fileupload.getBase64(Files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];
        var ObjData = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/UserContacts",
          File: _base64,
          ImageName: FileName,
          FileData: Csvdata1,
          saveAllAccounts: saveAllAccounts,
          ClientID: ClientID,
          UserID: UserID,
          CreatedBy: UserID,
          CreatedDate: new Date(),
          IsUpdateExistAccounts: IsUpdateExistAccounts,
          IsInsertNullValues: IsInsertNullValues,
          Role: Role,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/accounts/ImportCSVAccountData",
          method: "POST",
          data: ObjData,
        }).then((res) => {
          SetIsLoading(false)
          if (res.data.StatusMessage == "SUCCESS") {
            if (res.data.TotalSuccess > 0) {
              toast.success(
                <div className="toastsize">Account import successfully.</div>
              );
            }
            // var TotalUpdated = res.data.TotalUpdated;
            SetImportDisabled(true);
            SetTotalResult(res.data.Total);
            SetTotalDuplicate(res.data.TotalDuplication);
            SetTotalError(res.data.TotalError);
            SetTotalSuccess(res.data.TotalSuccess);
            SetTotalSkipped(res.data.TotalSkipped);
            SetTotalInvalid(res.data.TotalInValid);
            SetTotalDisplayCount(true);
            Exportdetails(ClientID,UserID,IsApply,FilterParameters);
            SetCsvdata1([]);
            document.getElementById("map_attribute").style.display = "block";
            document.getElementById("import_Account_csv").style.display =
              "block";
            document.getElementById("IsUpdateExistAccounts").disabled = true;
            document.getElementById("IsInsertNullValues").disabled = true;
            GetAccounlist(ClientID, UserID);
          } else {
            SetImportDisabled(true);
            SetTotalResult(0);
            SetTotalDuplicate(0);
            SetTotalError(0);
            SetTotalSuccess(0);
            SetTotalSkipped(0);
            SetTotalInvalid(0);
            SetTotalDisplayCount(true);
            Exportdetails(ClientID,UserID,IsApply,FilterParameters);
            SetCsvdata1([]);
            document.getElementById("map_attribute").style.display = "block";
            document.getElementById("import_Account_csv").style.display = "block";
            document.getElementById("IsUpdateExistAccounts").disabled = true;
            document.getElementById("IsInsertNullValues").disabled = true;
            GetAccounlist(ClientID, UserID);
            toast.error("Something Went Wrong !")
          }
          //SetIsLoading(true)
        });
      });
    } else {
      SetIsLoading(false)

      toast.error(<div className="toastsize">
        <div>Account</div>
        <div>Email Domain is required!</div>
      </div>)
    }
    // }
  };

  const CheckBoxClick = (e, row) => {
    const trElement = e.target.closest('tr');
    if (e.target.checked) {
      // If a checkbox is checked individually, add the row to AddArray.
      SetAddArray([...AddArray, row]);
      trElement.classList.add("highlight");
    } else {
      // If a checkbox is unchecked individually, remove the row from AddArray.
      const updatedAddArray = AddArray.filter(item => item !== row);
      SetAddArray(updatedAddArray);
      trElement.classList.remove("highlight");
    }
  };

  // all check handler
  const CheckHandler = (e) => {
    {
      Rows?.map((val) => {
        if (e.target.checked) {

          SetAddArray([...Rows]);
          document.getElementById(val?._id).checked = true;
          const trElement = document.getElementById(val?._id).closest('tr');
          if (trElement) {
            trElement.classList.add("highlight");
          }
        } else if (!e.target.checked) {
          SetAddArray([]);
          document.getElementById(val?._id).checked = false;
          const trElement = document.getElementById(val?._id).closest('tr');
          if (trElement) {
            trElement.classList.remove("highlight");
          }
        }
      });
    }
  };

  const CancelBulkAccountBtn = () => {
    document.getElementById("delete_csv").style.display = "none";
    // document.getElementById("import_csv").style.display = "none";
    document.getElementById("map_attribute_domain_Accountlist").style.display =
      "none";
  };

  //bulk delete
  const BulkDeleteAccountBtn = () => {
    if (Csvdata1.length != 0) {
      document.getElementById(
        "map_attribute_domain_Accountlist"
      ).style.display = "block";
      SetReset(true)
      DropboxData.map((dddd, index) => { });
    } else {
      SetReset(true)
      toast.error(
        <div className="toastsize">
          Please select file
        </div>
      );
    }
  };

  //open bulkdelete csv
  const AccountBulkDeleteCsv = () => {
    document.getElementById("delete_csv").style.display = "block";
    document.getElementById("import_Account_csv").style.display = "none";
    SetTotalDisplayCount(false)
  };

  // Bulk delete Account csv
  const DeleteBulkAccountCsv = () => {
    SetDisabledBulkDelete(true)
    var DomainName = document.getElementById("DomainName").value;
    if (DomainName == "not mapped") {
      toast.error(
        <div className="toastsize">
          Not mapped!
        </div>
      )
      SetDisabledBulkDelete(false)

    } else {
      Fileupload.getBase64(Files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var BulkDeleteAccountData = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/UserContacts",
          File: _base64,
          ImageName: FileName,
          ClientID: ClientID,
          FileData: Csvdata1,
          DomainName: DomainName,
          Role: Role,
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL + "/accounts/ImportCSVAccountBulkDelete",
          method: "POST",
          data: BulkDeleteAccountData,
        }).then((Res) => {
          if (Res.data.StatusMessage == "SUCCESS") {
            toast.success(<div>Account deleted successfully.</div>);
            SetPage(1)
            document.getElementById("delete_csv").style.display = "none";
            document.getElementById("map_attribute_domain_Accountlist").style.display = "none";
            GetAccounlist(ClientID, UserID);
            Exportdetails(ClientID,UserID,IsApply,FilterParameters);
            SetDisabledBulkDelete(false)

            // history.push("/accounts");
          } else {
            toast.error(Res.data.Message);
            SetDisabledBulkDelete(false)

          }
        });
      });
    }

  };

  //Check box update data
  // const CheckBoxClick = (e, row) => {
  //   if (e.target.checked) {
  //     var temArray = AddArray;
  //     temArray.push(row);
  //     SetAddArray(temArray);
  //   } else if (!e.target.checked) {
  //     AddArray.splice(row, 1);
  //   }
  // };


  //Uncheck checkbox
  const Uncheck = () => {
    // Create a new array with the elements you want to uncheck
    const updatedAddArray = AddArray.map((el) => ({
      ...el,
      checked: false
    }));

    // Update the state with the new array
    SetAddArray(updatedAddArray);
  };
  // Account add to blacklist Domain
  const AccountAddToBlackList = () => {
    SetTotalDisplayCount(false)
    SetIsLoading(true)
    if (AddArray.length > 0) {
      var AccountParamData = {
        ClientID: ClientID,
        LastUpdatedBy: UserID,
        AccountBlackListArray: AddArray,
        Role: Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/AccountToAddToBlackList",
        method: "POST",
        data: AccountParamData,
      }).then((res) => {

        if (res.data.StatusMessage === "SUCCESS") {
          if (res.data.BlackListlength === null) {
            // Handle the case where BlackListlength is null
            toast.error("Domain Not Exists");
          } else if (res.data.BlackListlength.length === 0) {
            Swal.fire(
              "Blacklisted",
              "Selected accounts blacklisted successfully.",
              "success"
            );
            Uncheck();
            SetIsLoading(false)
            GetAccounlist(ClientID, UserID)
            SetAddArray([])
          } else {
            Uncheck();
            toast.error("Accounts already Blacklisted!");
            SetIsLoading(false)
            SetAddArray([])

          }
        } else {
          toast.error("Error while blacklisting accounts.");
          SetIsLoading(false)
        }
      });
    } else {
      toast.error(
        <div className="toastsize">
          Please select at least one account from the list.
        </div>
      );
      SetIsLoading(false)
    }
  };

  // View page
  const ViewPage = (ID) => {
    history.push({ pathname: "/viewaccount", state: { data: ID, Pagename: "accounts" } });
  };

  //get sort field data
  const SortData = (Field) => {
    let SearchedVal = document.getElementById("search").value?.trim();
    let SerchBox;
    SetIsLoading(true)
    if(Field !== SortField){
      SetSortBy(1);
      SerchBox = false;
      SetSFlag(false);
      let SortField = Field;
      let SortedBy=1;
      SetSortField(Field);
      let InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: SearchedVal,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        FilterParameters: FilterParameters,
        IsApply: IsApply,
        AccountTypeID: AccountFieldType,
        Role: Role,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/GetAccountlist",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {

        if (Result.data.StatusMessage === 'SUCCESS') {
          SetData(Result.data.PageData);
          SetRows(Result.data.PageData);
          SetRlen(Result.data.TotalCount);
          SetFlen(Result.data.TotalCount);
          SetCountPage(Result.data.PageCount);
          SetIsLoading(false)
        } else {
          SetData([]);
          SetRows([]);
          SetRlen(0);
          SetFlen(0);
          SetCountPage(0);
          SetIsLoading(false)
          toast.error("Something went wrong !")
        }
        SetPage(1)
      });
    }else{
      if (SearchedVal == "") {
        SerchBox = false;
        SetSFlag(false);
        let SortField = Field;
        let SortedBy;
        if (SortBy == 1) {
          SortedBy = -1;
          SetSortBy(-1);
        } else {
          SortedBy = 1;
          SetSortBy(1);
        }
        SetSortField(Field);
        let InputParameter = {
          Page: Page,
          RowsPerPage: RowsPerPage,
          SerchBox: false,
          Sort: true,
          Field: SortField,
          SortBy: SortedBy,
          Search: SearchedVal,
          Type: "User",
          ClientID: ClientID,
          UserID: UserID,
          FilterParameters: FilterParameters,
          IsApply: IsApply,
          AccountTypeID: AccountFieldType,
          Role: Role,
        };
        const AccountCategoriesList = Axios({
          url: CommonConstants.MOL_APIURL + "/accounts/GetAccountlist",
          method: "POST",
          data: InputParameter,
        });
        AccountCategoriesList.then((Result) => {
  
          if (Result.data.StatusMessage === 'SUCCESS') {
            SetData(Result.data.PageData);
            SetRows(Result.data.PageData);
            SetRlen(Result.data.TotalCount);
            SetFlen(Result.data.TotalCount);
            SetCountPage(Result.data.PageCount);
            SetIsLoading(false)
          } else {
            SetData([]);
            SetRows([]);
            SetRlen(0);
            SetFlen(0);
            SetCountPage(0);
            SetIsLoading(false)
            toast.error("Something went wrong !")
          }
          SetPage(1)
        });
      } else {
        SerchBox = true;
        SetSFlag(true);
        let SortField = Field;
        let SortedBy;
        if (SortBy == 1) {
          SortedBy = -1;
          SetSortBy(-1);
        } else {
          SortedBy = 1;
          SetSortBy(1);
        }
        SetSortField(Field);
        let InputParameter = {
          Page: Page,
          RowsPerPage: RowsPerPage,
          Search: SearchedVal,
          SerchBox: SerchBox,
          Sort: true,
          Field: SortField,
          SortBy: SortedBy,
          Type: "User",
          ClientID: ClientID,
          UserID: UserID,
          Role: Role,
          FilterParameters: FilterParameters,
          IsApply: IsApply,
          AccountTypeID: AccountFieldType,
        };
        const AccountCategoriesList = Axios({
          url: CommonConstants.MOL_APIURL + "/accounts/GetAccountlist",
          method: "POST",
          data: InputParameter,
        });
        AccountCategoriesList.then((Result) => {
          if (Result.data.StatusMessage === 'SUCCESS') {
            SetData(Result.data.PageData);
            SetRows(Result.data.PageData);
            SetRlen(Result.data.TotalCount);
            SetFlen(Result.data.TotalCount);
            SetCountPage(Result.data.PageCount);
            SetIsLoading(false)
          } else {
            SetData([]);
            SetRows([]);
            SetRlen(0);
            SetFlen(0);
            SetCountPage(0);
            SetIsLoading(false)
            toast.error("Something went wrong !")
          }
          SetPage(1);
        });
      }
    }
  };

  //Filter methods starts from here

  //Close Main filter model box
  const CloseFilterModel = () => {
    SetOpenFilterModel(false);
    SetAddFilterModel(false);
    SetOpenSelectFilterModel(false);
    SetFieldAttributes([
      {
        id: Math.floor(Math.random() * 1000000000),
        ColumnName: "AccountCategory",
        InputType: "Contains",
        IsCustom: false,
        IsDate: false,
        IsBool: false,
      },
    ]);
  };

  //Open Add Filter model
  const OpenAddFilterModel = () => {
    SetAddFilterModel(true);
  };

  //Close OpenAdd Filter model
  const CloseAddFilterModel = () => {
    SetOpenFilterModel(false);
    SetAddFilterModel(false);
    SetOpenSelectFilterModel(false);
    SetFieldAttributes([
      {
        id: Math.floor(Math.random() * 1000000000),
        ColumnName: "AccountCategory",
        InputType: "Contains",
        IsCustom: false,
        IsDate: false,
        IsBool: false,
      },
    ]);
  };

  //Open select exist filter model
  const OpenSelectExistFilterMode = () => {
    SetEditFieldAttributes([]);
    if (FilterName.length > 0) {

      Getfilterpropertylist(FilterName[0]._id);
      SetOpenSelectFilterModel(true);
    } else {
      toast.error(
        <div className="toastsize">
          Filter
          <br />
          No filter available
        </div>
      );
    }
  };

  //Close Select exist filter model
  const CloseSelectExistFilterMode = () => {
    SetOpenFilterModel(false);
    SetAddFilterModel(false);
    SetOpenSelectFilterModel(false);
  };

  //Get Custom fields
  const GetCustomFieldsData = (CID) => {
    var InputParameters = {
      ClientID: CID,
      Role: Role,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/GetCustomFields",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((Result) => {
      let Arr = [
        {
          FieldValue: "AccountCategory",
          ColumnName: "Account Category",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "AccountClientStatus",
          ColumnName: "Account Client Status",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "AccountName",
          ColumnName: "Account Name",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "AccountOwner",
          ColumnName: "Account Owner",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "AccountType",
          ColumnName: "Account Type",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Address1",
          ColumnName: "Address1",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Address2",
          ColumnName: "Address2",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "City",
          ColumnName: "City",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "CompanyPhone",
          ColumnName: "Company Phone",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Country",
          ColumnName: "Country",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "CreatedDate",
          ColumnName: "Created Date",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "EmailDomains",
          ColumnName: "Email Domains",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Industry",
          ColumnName: "Industry",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "ReasonUnqualified",
          ColumnName: "Reason Unqualified",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "State",
          ColumnName: "State",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Website",
          ColumnName: "Website",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
        {
          FieldValue: "Zip",
          ColumnName: "Zip",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
        },
      ];
      Result.data.Data.forEach((el) => {
        Arr.push({
          FieldValue: el.FieldName,
          ColumnName: el.FieldName,
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
          IsCustom: true,
          CustomFieldID: el._id
        });
      });
      let ChangedArr = [];
      Arr.forEach((el, i) => {
        if (el.FieldValue == "CreatedDate") {
          ChangedArr.push({
            id: i,
            FieldValue: el.FieldValue,
            ColumnName: el.ColumnName,
            Expression: "EqualTo",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            IsCustom: false,
            IsDate: true,
          });
        } else {
          if (el.IsCustom == true) {
            ChangedArr.push({
              id: i,
              FieldValue: el.FieldValue,
              ColumnName: el.ColumnName,
              Expression: "Contains",
              FieldType: "Text",
              FilterPropertyValue: "",
              FilterPropertyValue1: "",
              IsCustom: true,
              IsDate: false,
              CustomFieldID: el.CustomFieldID
            });
          } else {
            ChangedArr.push({
              id: i,
              FieldValue: el.FieldValue,
              ColumnName: el.ColumnName,
              Expression: "Contains",
              FieldType: "",
              FilterPropertyValue: "",
              FilterPropertyValue1: "",
              IsCustom: false,
              IsDate: false,
            });
          }
        }
      });

      let SortedArr = ChangedArr.sort(function (a, b) {
        return a.ColumnName.toLowerCase().localeCompare(
          b.ColumnName.toLowerCase()
        );
      });

      SetCustomColumnData(SortedArr);
    });
  };

  //Column handled Dropdown Change
  const ColumnhandledDropdownChange = (id, Index) => {

    SetSelectOptionValue("Contains");
    let ColumnName = document.getElementById("ColumnName" + id).value;
    document.getElementById("searchvalue" + id).value = "";
    let FieldAttributesDatas = [];
    FieldAttributes.forEach((elem) => {
      FieldAttributesDatas.push(elem);
    });
    let indexed = CustomColumnData.findIndex((v) => v.FieldValue === ColumnName);

    FieldAttributesDatas[Index].ColumnName = ColumnName;
    FieldAttributesDatas[Index].InputType = "Contains";
    document.getElementById("expression" + id).value = "Contains"

    FieldAttributesDatas[Index].IsCustom = CustomColumnData[indexed].IsCustom;
    FieldAttributesDatas[Index].IsDate = CustomColumnData[indexed].IsDate;
    if (CustomColumnData[indexed].IsCustom == true) {
      FieldAttributesDatas[Index].CustomFieldID = CustomColumnData[indexed].CustomFieldID;
    } else {
      FieldAttributesDatas[Index].CustomFieldID = undefined;
    }

    SetFieldAttributes(FieldAttributesDatas);
  };

  // Expression Handler drop down change
  const ExpressionHandler = (id) => {
    let StateArr = [];
    FieldAttributes.forEach((elem) => {
      StateArr.push(elem);
    });
    let ColumnName = document.getElementById("ColumnName" + id).value;
    let InputType = document.getElementById("expression" + id).value;
    let indexed = StateArr.findIndex((v) => v.id === id);
    if (indexed == -1) {
      StateArr.push({ id: id, ColumnName: ColumnName, InputType: InputType });
    } else {
      StateArr[indexed].ColumnName = ColumnName;
      StateArr[indexed].InputType = InputType;
    }

    SetFieldAttributes(StateArr);
  };

  //Add filter property
  const handleClick = () => {
    let StateArray = [];
    FieldAttributes.forEach((elem) => {
      StateArray.push(elem);
    });
    StateArray.push({
      id: Math.floor(Math.random() * 1000000000),
      ColumnName: '"AccountCategory"',
      InputType: "Contains",
      IsCustom: false,
      IsDate: false,
      IsBool: false,
    });
    SetFieldAttributes(StateArray);
  };

  //remove filter property
  const handleRemoveClick = (id) => {
    var countArray2 = [];
    FieldAttributes.map((element) => {
      if (element.id != id) {
        countArray2.push(element);
      }
    });

    SetFieldAttributes(countArray2);
  };

  // filter validation
  const FilterValidation = () => {
    let ValidationArr = [];

    FieldAttributes.map((newvalue) => {
      if (newvalue.IsBool == false) {
        var searchedVal1 = document.getElementById(
          "searchvalue" + newvalue.id
        ).value;
        if (searchedVal1.trim() == "") {
          ValidationArr.push(false);
        } else {
          ValidationArr.push(true);
        }
        var expressionval = document.getElementById(
          "expression" + newvalue.id
        ).value;
        if (expressionval == "between") {
          let FilterPropertyValue2 = document.getElementById(
            "EndsWith" + newvalue.id
          ).value;
          if (FilterPropertyValue2.trim() == "") {
            ValidationArr.push(false);
          } else {
            ValidationArr.push(true);
          }
        }
      }
    });

    return ValidationArr.includes(false);
  };

  const filterSearch = () => {
    if (FilterValidation() == false) {
      if (FieldAttributes.length <= 0) {
        toast.error(
          <div className="toastsize">
            Filter
            <br />
            Please add filter property at least one
          </div>
        );
      } else {
        var AccountsFilterSecArray = [];
        FieldAttributes.map((newvalue) => {

          var searchedVal1 = document.getElementById(
            "searchvalue" + newvalue.id
          ).value?.trim();
          var expressionval = document.getElementById(
            "expression" + newvalue.id
          ).value?.trim();
          var ColumnName = document.getElementById(
            "ColumnName" + newvalue.id
          ).value?.trim();
          if (expressionval == "between") {
            var searchedVal2 = document.getElementById(
              "EndsWith" + newvalue.id
            ).value?.trim();
            AccountsFilterSecArray.push({
              ColumnName: ColumnName,
              expression: expressionval,
              searchvalue: searchedVal1,
              searchvalue1: searchedVal2,
              IsCustom: newvalue.IsCustom,
              CustomFieldID: newvalue.CustomFieldID
            });
          } else {
            AccountsFilterSecArray.push({
              ColumnName: ColumnName,
              expression: expressionval,
              searchvalue: searchedVal1,
              searchvalue1: null,
              IsCustom: newvalue.IsCustom,
              CustomFieldID: newvalue.CustomFieldID
            });
          }
        });

        GetAccounlistForFilter(ClientID, UserID, AccountsFilterSecArray, true);
        Exportdetails(ClientID,UserID,true,AccountsFilterSecArray);
        SetFilterParameters(AccountsFilterSecArray);
        SetIsApply(true);
        SetFieldAttributes([]);
        SetAddFilterModel(false);
        SetOpenFilterModel(false);
        SetFieldAttributes([
          {
            id: Math.floor(Math.random() * 1000000000),
            ColumnName: "AccountCategory",
            InputType: "Contains",
            IsCustom: false,
            IsDate: false,
          },
        ]);
        SetPage(1)
        toast.success(
          <div className="toastsize">
            Filter
            <br />
            Filter Apply SuccessFully.
          </div>
        );
      }
    } else {
      toast.error(
        <div className="toastsize">
          Filter
          <br />
          Property value can not be an empty
        </div>
      );
    }
  };

  //Reset Filter data
  const ResetFilter = () => {
    SetFieldAttributes([
      {
        id: Math.floor(Math.random() * 1000000000),
        ColumnName: '"AccountCategory"',
        InputType: "Contains",
        IsCustom: false,
        IsDate: false,
        IsBool: false,
      },
    ]);
    GetAccounlistForFilter(ClientID, UserID, [], false);
    Exportdetails(ClientID,UserID,false,[]);

    SetPage(1)
    SetIsApply(false);
  };

  //Check filter name exists or not
  const CheckFilterName = async () => {
    let FilterName = document.getElementById("FilterName").value.trim();
    let flag = false;
    const InputParameters = {
      ClientID: ClientID,
      FilterName: FilterName,
      Role: Role,
    };
    let res = await Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/CheckAccountsFilterName",
      method: "POST",
      data: InputParameters,
    });
    if (res.data.StatusMessage == "SUCCESS") {
      if (res.data.Data <= 0) {
        flag = true;
      } else {
        flag = false;
      }
    } else {
      toast.error("Something went wrong in filter name exists api");
    }

    return flag;
  };

  // Save Filter Property
  const addfilterAccountfilter = async () => {
    SetDisabledSaveFilter(true)
    let FilterName = document.getElementById("FilterName").value.trim();
    if (FilterName == "") {
      toast.error(
        <div className="toastsize">
          Filter
          <br />
          Please enter filter name
        </div>
      );
      SetDisabledSaveFilter(false)
    } else {
      if ((await CheckFilterName()) == false) {
        toast.error(
          <div className="toastsize">
            Filter
            <br />
            Filter value already exist
          </div>
        );
        SetDisabledSaveFilter(false)
      } else {
        if (FilterValidation() == false) {
          if (FieldAttributes.length <= 0) {
            toast.error(
              <div className="toastsize">
                Filter
                <br />
                Please add filter property at least one
              </div>
            );
            SetDisabledSaveFilter(false)

          } else {
            var AccountFilterSecArray = [];
            FieldAttributes.map((newvalue) => {
              var searchedVal1 = document.getElementById(
                "searchvalue" + newvalue.id
              ).value;
              var expressionval = document.getElementById(
                "expression" + newvalue.id
              ).value;
              var Cloumnval = document.getElementById(
                "ColumnName" + newvalue.id
              ).value;
              let indexed = CustomColumnData.findIndex(
                (v) => v.FieldValue == Cloumnval
              );
              if (expressionval == "between") {
                var searchedVal2 = document.getElementById(
                  "EndsWith" + newvalue.id
                ).value;
                AccountFilterSecArray.push({
                  ColumnName: Cloumnval,
                  expression: expressionval,
                  searchvalue: searchedVal1,
                  searchvalue1: searchedVal2,
                  FieldType: CustomColumnData[indexed].FieldType,
                  IsCustom: newvalue.IsCustom,
                  CustomFieldID: newvalue.CustomFieldID
                });
              } else {
                AccountFilterSecArray.push({
                  ColumnName: Cloumnval,
                  expression: expressionval,
                  searchvalue: searchedVal1,
                  searchvalue1: null,
                  FieldType: CustomColumnData[indexed].FieldType,
                  IsCustom: newvalue.IsCustom,
                  CustomFieldID: newvalue.CustomFieldID
                });
              }
            });

            const InputParameters = {
              ClientID: ClientID,
              FilterName: FilterName,
              FilterParameters: AccountFilterSecArray,
              CreatedDate: new Date(),
              UserID: UserID,
              Role: Role,
            };

            let res = await Axios({
              url: CommonConstants.MOL_APIURL + "/accounts/SaveAccountsFilter",
              method: "POST",
              data: InputParameters,
            });

            if (res.data.StatusMessage == "SUCCESS") {
              GetAccounlistForFilter(ClientID, UserID, AccountFilterSecArray, true);
              Exportdetails(ClientID,UserID,true,AccountFilterSecArray);
              SetFilterParameters(AccountFilterSecArray);
              SetIsApply(true);
              SetFieldAttributes([]);
              SetAddFilterModel(false);
              SetOpenFilterModel(false);
              SetFieldAttributes([
                {
                  id: Math.floor(Math.random() * 1000000000),
                  ColumnName: '"AccountCategory"',
                  InputType: "Contains",
                  IsCustom: false,
                  IsDate: false,
                },
              ]);
              SetPage(1)
              Getfilterlist(ClientID);
              toast.success(
                <div className="toastsize">
                  Filter
                  <br />
                  Filter Added SuccessFully.
                </div>
              );
              SetDisabledSaveFilter(false)

            } else {
              toast.error("Error in save filter");
              SetDisabledSaveFilter(false)

            }
          }
        } else {
          toast.error(
            <div className="toastsize">
              Filter
              <br />
              Property value can not be an empty
            </div>
          );
          SetDisabledSaveFilter(false)

        }
      }
    }
  };

  // Get Filter List
  const Getfilterlist = (CID) => {
    var InputParameters = {
      ClientID: CID,
      Role: Role,
    };

    const rows3 = Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/GetAccountsFilterDropDown",
      method: "POST",
      data: InputParameters,
    });
    rows3.then((result) => {
      SetFilterName(result.data.Data);
      if (result.data.Data.length > 0) {
        Getfilterpropertylist(result.data.Data[0]._id);
      }
    });
  };

  //Edit Accounts Filter Change
  const handleChangeFilter = () => {
    var filterdropoid = document.getElementById("FilterID").value;
    Getfilterpropertylist(filterdropoid);
  };

  // Get FilteProperty list with dropdown
  const Getfilterpropertylist = (filterid) => {
    // SetIsLoading(true);
    SetFilterID(filterid);

    var InputParameters = {
      ClientID: ClientID,
      FilterID: filterid,
      IsDeleted: false,
      Role: Role,
    };
    const rows4 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/accounts/GetAccountsEditFilterProperties",
      method: "POST",
      data: InputParameters,
    });
    rows4.then((result) => {

      let FilteredArr = [];
      result.data?.Data?.forEach((el, i) => {
        let SearchDataInColumns = CustomColumnData.find((v) => v.FieldValue === el.FilterPropertyName);
        el.id = i;
        el.IsSaved = true;
        if (el.FilterPropertyName == "CreatedDate") {
          el.IsDate = true;
        } else {
          if (el.FieldType == "Text") {
            el.IsCustom = true;
            el.CustomFieldID = SearchDataInColumns.CustomFieldID
          } else {
            el.IsCustom = false;
          }
          el.IsDate = false;
          el.IsBool = false;
        }


        FilteredArr.push(el);
      });

      SetEditFieldAttributes(FilteredArr);
      // SetIsLoading(false);
    });
  };

  //Edit column name
  const EditColumnName = (ID) => {
    let SavedData = [];
    EditFieldAttributes.forEach((elem) => {
      SavedData.push(elem);
    });
    let FilterPropertyName = document.getElementById(
      "EditColumnName" + ID
    ).value;

    let indexed = SavedData.findIndex((v) => v.id === ID);
    let Customs = CustomColumnData.findIndex(
      (v) => v.FieldValue == FilterPropertyName
    );
    document.getElementById("Editexpression" + ID).value = "Contains";
    document.getElementById("Editsearchvalue" + ID).value = "";
    SavedData[indexed].FilterPropertyName = FilterPropertyName;
    SavedData[indexed].ExpressionName = "Contains";
    SavedData[indexed].FilterPropertyValue1 = "";

    if (SavedData[indexed].FilterPropertyName == "CreatedDate") {
      SavedData[indexed].IsDate = true;
    } else {
      SavedData[indexed].IsDate = false;

      if (CustomColumnData[Customs].IsCustom == true) {
        SavedData[indexed].IsCustom = true;
        SavedData[indexed].CustomFieldID = CustomColumnData[Customs].CustomFieldID;
      } else {
        SavedData[indexed].IsCustom = false;
        SavedData[indexed].CustomFieldID = undefined;
      }
    }
    SetEditFieldAttributes(SavedData);
  };

  //Edit Expression value
  const EditExpressValue = (ID) => {
    let SavedData = [];
    EditFieldAttributes.forEach((elem) => {
      SavedData.push(elem);
    });
    let ExpressionName = document.getElementById("Editexpression" + ID).value;

    let indexed = SavedData.findIndex((v) => v.id === ID);
    SavedData[indexed].ExpressionName = ExpressionName;

    SetEditFieldAttributes(SavedData);
  };

  //Edit FilterPropertyValue1
  const EditFilterPropertyValue1 = (ID) => {
    let SavedData = [];
    EditFieldAttributes.forEach((elem) => {
      SavedData.push(elem);
    });
    let FilterPropertyValue1 = document.getElementById(
      "Editsearchvalue" + ID
    ).value;
    let indexed = SavedData.findIndex((v) => v.id === ID);
    SavedData[indexed].FilterPropertyValue1 = FilterPropertyValue1;
    // SavedData[indexed].FilterPropertyValue2 = "";

    SetEditFieldAttributes(SavedData);
  };

  //Edit FilterPropertyValue2
  const EditFilterPropertyValue2 = (ID) => {
    let SavedData = [];
    EditFieldAttributes.forEach((elem) => {
      SavedData.push(elem);
    });
    let FilterPropertyValue2 = document.getElementById(
      "EditEndsWith" + ID
    ).value;
    let indexed = SavedData.findIndex((v) => v.id === ID);
    SavedData[indexed].FilterPropertyValue2 = FilterPropertyValue2;
    SetEditFieldAttributes(SavedData);
  };

  //Add new filter property
  const handleeditPropertyClick = () => {
    let StateArray = [];
    EditFieldAttributes.forEach((elem) => {
      StateArray.push(elem);
    });
    StateArray.push({
      id: StateArray.length,
      FilterPropertyName: "AccountCategory",
      ExpressionName: "Contains",
      IsCustom: false,
      IsDate: false,
      IsBool: false,
      IsSave: false,
    });
    SetEditFieldAttributes(StateArray);
  };

  //Remove filter property
  const handleEditRemoveClick = (id) => {
    let SavedData = [];
    EditFieldAttributes.forEach((elem) => {
      SavedData.push(elem);
    });
    if (SavedData.length <= 1) {
      toast.error(
        <div>
          Filter
          <br />
          Required filter property at least one
        </div>
      );
    } else {
      let DeletedArr = DeletedFilterProperty;
      const FilterPropertyID = SavedData.findIndex((item) => item.id == id);

      if (SavedData[FilterPropertyID].IsSaved == true) {
        DeletedArr.push(SavedData[FilterPropertyID]._id);
        SetDeletedFilterProperty(DeletedArr);
      }
      const FilteredData = SavedData.filter((item) => item.id !== id);

      SetEditFieldAttributes(FilteredData);
    }
  };

  //Apply Edit Filter
  const ApplyEditFilter = () => {

    if (UpdateFilterValidation() == false) {

      var AccountsFilterSecArray = [];
      EditFieldAttributes.map((el) => {
        if (el.ExpressionName == "between") {
          AccountsFilterSecArray.push({
            ColumnName: el.FilterPropertyName,
            expression: el.ExpressionName,
            searchvalue: el.FilterPropertyValue1?.trim(),
            searchvalue1: el.FilterPropertyValue2?.trim(),
            IsCustom: el.IsCustom,
            CustomFieldID: el.CustomFieldID
          });
        } else {
          AccountsFilterSecArray.push({
            ColumnName: el.FilterPropertyName,
            expression: el.ExpressionName,
            searchvalue: el.FilterPropertyValue1?.trim(),
            searchvalue1: null,
            IsCustom: el.IsCustom,
            CustomFieldID: el.CustomFieldID
          });
        }
      });
      GetAccounlistForFilter(ClientID, UserID, AccountsFilterSecArray, true);
      Exportdetails(ClientID,UserID,true,AccountsFilterSecArray);
      SetFilterParameters(AccountsFilterSecArray);
      SetIsApply(true);
      SetOpenFilterModel(false);
      SetAddFilterModel(false);
      Getfilterlist(ClientID);
      SetOpenSelectFilterModel(false);
      SetPage(1)
      toast.success(
        <div className="toastsize">
          Filter <br /> Filter Apply SuccessFully.
        </div>
      );
    } else {
      toast.error(
        <div className="toastsize">
          Filter
          <br />
          Property value can not be an empty
        </div>
      );
    }
  }

  //Update Filter validation
  const UpdateFilterValidation = () => {
    let Flags = [];
    EditFieldAttributes.forEach((el) => {
      var searchedVal1 = document.getElementById(
        "Editsearchvalue" + el.id
      ).value;

      if (searchedVal1.trim() == "") {
        Flags.push(false);
      } else {
        Flags.push(true);
      }
      if (el.ExpressionName == "between") {
        let FilterPropertyValue2 = document.getElementById(
          "EditEndsWith" + el.id
        ).value;
        if (FilterPropertyValue2.trim() == "") {
          Flags.push(false);
        } else {
          Flags.push(true);
        }
      }
    });

    return Flags.includes(false);
  };

  //Update and apply filter
  const editapplyfilterSearch = async () => {
    if (UpdateFilterValidation() == false) {
      let UpdatingParams = [];
      EditFieldAttributes.forEach((elem) => {
        if (elem.IsCustom == true) {
          elem.FieldType = "Text";
          UpdatingParams.push(elem);
        } else {
          UpdatingParams.push(elem);
        }
      });

      const InputParameters = {
        ClientID: ClientID,
        FilterParameters: UpdatingParams,
        LastUpdatedDate: new Date(),
        UserID: ParentUserID,
        FilterID: FilterID,
        DeletedFilterProperty: DeletedFilterProperty,
        Role: Role,
      };

      let res = await Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/accounts/UpdateAccountsFilterProperties",
        method: "POST",
        data: InputParameters,
      });
      if (res.data.StatusMessage == "SUCCESS") {
        Getfilterlist(ClientID);
        toast.success(
          <div className="toastsize">
            Filter <br />
            Filter Updated SuccessFully.
          </div>
        );
        var AccountsFilterSecArray = [];
        EditFieldAttributes.map((el) => {
          if (el.ExpressionName == "between") {
            AccountsFilterSecArray.push({
              ColumnName: el.FilterPropertyName,
              expression: el.ExpressionName,
              searchvalue: el.FilterPropertyValue1?.trim(),
              searchvalue1: el.FilterPropertyValue2?.trim(),
              IsCustom: el.IsCustom
            });
          } else {
            AccountsFilterSecArray.push({
              ColumnName: el.FilterPropertyName,
              expression: el.ExpressionName,
              searchvalue: el.FilterPropertyValue1?.trim(),
              searchvalue1: null,
              IsCustom: el.IsCustom
            });
          }
        });
        GetAccounlistForFilter(ClientID, UserID, AccountsFilterSecArray, true);
        Exportdetails(ClientID,UserID,true,AccountsFilterSecArray);
        SetPage(1)
        SetFilterParameters(AccountsFilterSecArray);
        SetIsApply(true);
      } else {
        toast.error("Error in save filter");
      }

      SetOpenFilterModel(false);
      SetAddFilterModel(false);
      SetOpenSelectFilterModel(false);
    } else {
      toast.error(
        <div className="toastsize">
          Filter
          <br />
          Property value can not be an empty
        </div>
      );
    }
  };


  //=========Merge Account ===========//

  //Data Serialization for Merage Account
  const DataSerialization = (DataModel, fieldname) => {
    // Step 1: Filter out objects with null or blank data
    const filteredData = DataModel.filter((item) => item[fieldname] !== null && item[fieldname] !== "" && item[fieldname] !== undefined);
    // Step 2: Create an array of unique data
    const uniqueData = [...new Set(filteredData.map((item) => item[fieldname]))];
    return uniqueData;
  };

  //Set state for all merge field and mapping
  const AccountMergeState = (addArray) => {

    let AccountNameData = DataSerialization(addArray, "AccountName")
    let AccountNameLongData = DataSerialization(addArray, "AccountNameLong")
    let Address1Data = DataSerialization(addArray, "Address1")
    let Address2Data = DataSerialization(addArray, "Address2")
    let CityData = DataSerialization(addArray, "City")
    let CountryData = DataSerialization(addArray, "Country")
    let StateData = DataSerialization(addArray, "State")
    let WebsiteData = DataSerialization(addArray, "Website")
    let ZipData = DataSerialization(addArray, "Zip")
    let IndustryData = DataSerialization(addArray, "Industry")
    let CustomArr = [];

    addArray.map((elem) => {
      if (elem.CustomField.length != 0) {
        elem.CustomField.map((el) => {
          if (el.FieldValue !== null && el.FieldValue !== "" && el.FieldValue !== undefined) {
            CustomArr.push(el.FieldValue)
          }
        })
      }
    })




    // const filteredData = addArray.filter((item) => item[fieldname] !== null && item[fieldname] !== "" && item[fieldname] !== undefined) ;
    // Step 2: Create an array of unique data
    // const uniqueData = [...new Set(filteredData.map((item) => item[fieldname]))];

    let ObjectData = {
      AccountName: AccountNameData,
      AccountNameLong: AccountNameLongData,
      Website: WebsiteData,
      Industry: IndustryData,
      Address1: Address1Data,
      Address2: Address2Data,
      City: CityData,
      State: StateData,
      Zip: ZipData,
      Country: CountryData,
      CustomColumn: CustomArr
    }
    SetMergeData(ObjectData)











  }


  //Merge Data
  const OwnerDetails = (
    OwnerArray,
    AccountCategoryArray,
    SalesReplyIDArray,
    DomainArray
  ) => {
    SetMergeDomainName(DomainArray)
    var InputParameter = {
      ClientID: ClientID,
      SalesReplyIDArray: SalesReplyIDArray,
      OwnerArray: OwnerArray,
      CategoryAccount: AccountCategoryArray,
      Role: Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/OwnerMergeGet",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        SetOwnerMergeDetail(res.data?.DataOwnerAccount);
        SetAccountCategoryMergeDetail(res.data?.DataCateAccount);
        SetAccountCustomMergeDetail(res.data?.DataCustomField);
        SetAccountCustomMergeValueDetail(res.data?.DataCustomFieldValue);
      }
    });
  };

  //Merge domain lists
  const MergeDomain = () => {

    SetTotalDisplayCount(false)
    AccountMergeState(AddArray)
    let DomainArray = [];
    let OwnerArray = [];
    let AccountCategoryArray = [];
    let SalesReplyIDArray = [];



    AddArray.forEach((value) => {
      DomainArray.push(value.Salesreply_Accountdetails?.Domain);
      OwnerArray.push(value?.AccountOwnerID);
      AccountCategoryArray.push(value?.AccountCategoryID);
      SalesReplyIDArray.push(value?._id);
    });

    OwnerDetails(OwnerArray, AccountCategoryArray, SalesReplyIDArray, DomainArray);
    //---------------------------------------------------------------------//
    if (AddArray.length > 1) {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to merge an accounts.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, merge it!",
        reverseButtons: true,
      }).then((Result) => {
        if (Result.isConfirmed) {
          SetShowMergePopup(true);
        } else if (Result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      toast.error(
        <div className="toastsize">
          Merge Account
          <br />
          Please select at least two accounts from list.
        </div>
      );
    }
  };

  // Account add to Merge
  const AccountAddToMerge = () => {
    if (isSaveDisabled) {
      return;
    }
    setIsSaveDisabled(true);
    const AccountName = document.getElementById("AccountNameMerge").value;
    const AccountNameLong = document.getElementById("AccountNameLongMerge").value;
    const Website = document.getElementById("WebsiteMerge").value;
    const Industry = document.getElementById("IndustryMerge").value;
    const AccountCategory = document.getElementById("AccountCategoryMerge").value;
    const AccountOwner = document.getElementById("AccountOwnerMerge").value;
    const Address1 = document.getElementById("Address1Merge").value;
    const Address2 = document.getElementById("Address2Merge").value;
    const City = document.getElementById("CityMerge").value;
    const State = document.getElementById("StateMerge").value;
    const Zip = document.getElementById("ZipMerge").value;
    const Country = document.getElementById("CountryMerge").value;
    const Domains = document.getElementById("EmailDomainsMerge").value;

    var AccountCustomFieldValueArray = [];
    if (AccountCustomMergeValueDetail.length == 0) {
      AccountCustomMergeDetail.map((value) => {
        let customval = document.getElementById(
          value.CustomFieldID + "inp"
        ).value;
        let customvalid = value.CustomFieldID;
        if (customval.length !== 0) {
          AccountCustomFieldValueArray.push({
            customval,
            customvalid,
          });
        }
      });
    } else {
      AccountCustomMergeDetail.map((value) => {
        let customval = document.getElementById(
          value.CustomFieldID + "sel"
        ).value;
        let customvalid = value.CustomFieldID;
        if (customval.length !== 0) {
          AccountCustomFieldValueArray.push({
            customval,
            customvalid,
          });
        }
      });
    }

    var AccountParamData = {
      ClientID: ClientID,
      Domain: Domains,
      AccountName: AccountName,
      AccountNameLong: AccountNameLong,
      Website: Website,
      Industry: Industry,
      AccountCategory: AccountCategory,
      AccountOwner: AccountOwner,
      Address1: Address1,
      Address2: Address2,
      City: City,
      State: State,
      Zip: Zip,
      Country: Country,
      LastUpdatedBy: UserID,
      AccountMergeArray: AddArray,
      AccountCustomArr: AccountCustomFieldValueArray,
      Role: Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/AccountToAddtoMerge",
      method: "POST",
      data: AccountParamData,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        toast.success("Account merged successfully.");
        GetAccounlist(ClientID, UserID);
        SetAddArray([]);
        SetShowMergePopup(false);
        setIsSaveDisabled(false);

      } else {
        setIsSaveDisabled(false);
        toast.error("Something went wrong !");
      }
    });
  };


  //Get counts details in sub table
  const GetCountDetailsInSubTable = (AccountID) => {
    SetIsLoading(true)
    let arr = [...SubTabData];
    const InputParameter = {
      SalesReplyAccountID: AccountID,
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/GetCountDetailsForTable",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage === "SUCCESS") {
        const newData = res.data.Data[0];
        const existingIndex = arr.findIndex(item => item._id === newData._id);
        if (existingIndex !== -1) {
          arr.splice(existingIndex, 1);
        }
        arr.push(newData);
        SetSubTabData(arr);
        SetIsLoading(false)
      } else {
        SetIsLoading(false)
      }
    });

  }

  //Retrieve Account Details for Fitler
  // GetAccounlist
  const GetAccounlistForFilter = (CID, UID, FilterParameters, IsApply) => {
    SetIsLoading(true)
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      AccountTypeID: AccountFieldType,
      FilterParameters: FilterParameters,
      IsApply: IsApply,
      Role: Role,
    };

    const GetAllAccountList = Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/GetAccountlist",
      method: "POST",
      data: InputParameter,
    });
    GetAllAccountList.then((Result) => {
      SetData(Result.data.PageData);
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)
      SetPage(1)
      //document.getElementById("hideloding").style.display = "none";
    });
  };


  return (
    <>
    {console.log({ AccountFieldType, AccountFieldName })}
      {
        IsLoading ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : <></>
      }
      <div className="px-3 pt-3">
        <div className="row">
          <div className="col border-bottom">
            <h4 className="headertitle lg-headertitle float-left py-1 pl-1">{AccountFieldName}</h4>
          </div>
          <div className="col border-bottom pb-3">
            <div className="listing-li float-right"> 
              <div className="settingdrop">
                <ul className="mt-0">
                    <li>
                        <a aria-describedby={id} variant="contained" onClick={settinghandleClick}
                          className="btn btngroup m-btn m-btn--custom"
                        >
                          <i className="la la-icon-font-size-13 flaticon-settings"></i>
                        </a>
                    </li>
                  </ul>
              </div>
            
              <Popover id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={settinghandleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
          <div className="dropsettings" >
                {/* <label className="w-100 mb-2 settingtitel">Settings</label> */}
                <ul className="mt-0">
                  <li>
                    {/* <Tooltip title="Add"> */}
                      <a
                        onClick={() => {
                          history.push("/addaccount", { AccountType: AccountFieldType });
                        }}
                        className="btn btngroup m-btn m-btn--custom"
                      >
                        <i className="la la-icon-font-size-13 la-plus"></i> Add
                      </a>
                    {/* </Tooltip> */}
                  </li>
                  <li>
                    {/* <Tooltip title="Import"> */}
                      <a
                        onClick={AccountImportCsv}
                        className="btn btngroup m-btn m-btn--custom"
                      >
                        <i className="la la-icon-font-size-13 la-upload"></i> Import
                      </a>
                    {/* </Tooltip> */}
                  </li>
                  <li>
                      {/* <Tooltip title="Export"> */}
                        <a
                          onClick={exportcsv}
                          className="btn btngroup m-btn m-btn--custom"
                        >
                          <i className="la la-icon-font-size-13 la-download"></i> Export
                        </a>
                      {/* </Tooltip> */}
                  </li>
                  <li>
                    {/* <Tooltip title="Merge"> */}
                      {/* <a href="" className="btn btngroup m-btn m-btn--custom">
                      <i className="la la-icon-font-size-13 la-arrows-h"></i>
                    </a> */}
                      <button
                        onClick={MergeDomain}
                        className="btn btngroup m-btn m-btn--custom"
                      >
                        <i className="la la-icon-font-size-13 la-arrows-h"></i> Merge
                      </button>
                    {/* </Tooltip> */}
                    <Popup open={ShowMergePopup}>
                      <div>
                        <div className="modal-black"></div>
                        <div className="filterPopup maxpopupfull">
                          <div className="modal-header py-4 px-3">
                            <div className="w-100 d-flex px-3">
                              <h5 className="mb-0">Merge Accounts</h5>

                              <button
                                className="close"
                                onClick={() => {
                                  SetShowMergePopup(false);
                                }}
                              >
                                <span
                                  aria-hidden="true"
                                  className="la la-remove"
                                ></span>
                              </button>
                            </div>
                          </div>

                          <div className="paddingboxTerms">
                            <div className="modal-body cardtempls">
                              <div className="row">
                                <label className="col-lg-3 form-label max-w-144">
                                  Email Domains
                                </label>
                                <div className="col-lg-9 nopaddingLeft">
                                  <input
                                    className="form-control m-input"
                                    disabled="disabled"
                                    id="EmailDomainsMerge"
                                    type="text"
                                    value={MergeDomainName}
                                  />
                                </div>
                              </div>

                              <div className="row mt-4">
                                <div className="col-lg-6 row">
                                  <label className="col-lg-5 form-label">
                                    Account Name
                                  </label>
                                  <div className="col-lg-7">
                                    {
                                      MergeData.AccountName.length > 0 ? (
                                        <select className="form-control m-input" name="AccountNameMerge" id="AccountNameMerge">
                                          {
                                            MergeData.AccountName.map((val, i) => (
                                              <option value={val} key={i}>{val}</option>
                                            ))
                                          }
                                        </select>
                                      ) : (
                                        <input
                                          className="form-control m-input"
                                          name="AccountNameMerge"
                                          type="text"
                                          id="AccountNameMerge"
                                        />
                                      )
                                    }

                                  </div>
                                </div>

                                <div className="col-lg-6 row">
                                  <label className="col-lg-5 form-label">
                                    Account Name Long
                                  </label>
                                  <div className="col-lg-7">
                                    {
                                      MergeData.AccountNameLong.length > 0 ? (
                                        <select className="form-control m-input" name="AccountNameLongMerge" id="AccountNameLongMerge">
                                          {
                                            MergeData.AccountNameLong.map((val, i) => (
                                              <option value={val} key={i}>{val}</option>
                                            ))
                                          }
                                        </select>
                                      ) : (
                                        <input
                                          className="form-control m-input"
                                          name="AccountNameLongMerge"
                                          type="text"
                                          id="AccountNameLongMerge"
                                        />
                                      )
                                    }

                                  </div>
                                </div>
                              </div>

                              <div className="row mt-4">
                                <div className="col-lg-6 row">
                                  <label className="col-lg-5 form-label">
                                    Website
                                  </label>
                                  <div className="col-lg-7">
                                    {
                                      MergeData.Website.length > 0 ? (
                                        <select className="form-control m-input" name="WebsiteMerge" id="WebsiteMerge">
                                          {
                                            MergeData.Website.map((val, i) => (
                                              <option value={val} key={i}>{val}</option>
                                            ))
                                          }
                                        </select>
                                      ) : (
                                        <input
                                          className="form-control m-input"
                                          name="WebsiteMerge"
                                          type="text"
                                          id="WebsiteMerge"
                                        />
                                      )
                                    }

                                  </div>
                                </div>

                                <div className="col-lg-6 row">
                                  <label className="col-lg-5 form-label">
                                    Industry
                                  </label>
                                  <div className="col-lg-7">
                                    {
                                      MergeData.Industry.length > 0 ? (
                                        <select className="form-control m-input" name="IndustryMerge" id="IndustryMerge">
                                          {
                                            MergeData.Industry.map((val, i) => (
                                              <option value={val} key={i}>{val}</option>
                                            ))
                                          }
                                        </select>
                                      ) : (
                                        <input
                                          className="form-control m-input"
                                          name="IndustryMerge"
                                          type="text"
                                          id="IndustryMerge"
                                        />
                                      )
                                    }

                                  </div>
                                </div>
                              </div>

                              <div className="row mt-4">
                                <div className="col-lg-6 row">
                                  <label className="col-lg-5 form-label">
                                    Account Category
                                  </label>
                                  <div className="col-lg-7">
                                    <select
                                      autocomplete="off"
                                      className="form-control m-input"
                                      id="AccountCategoryMerge"
                                      name="Website"
                                    >
                                      {AccountCategoryMergeDetail.map((val) => (
                                        <option key={val?._id} value={val?._id}>
                                          {val?.Name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-6 row">
                                  <label className="col-lg-5 form-label">
                                    Account Owner
                                  </label>
                                  <div className="col-lg-7">
                                    <select
                                      autocomplete="off"
                                      className="form-control m-input"
                                      id="AccountOwnerMerge"
                                      name="Website"
                                    >
                                      {OwnerMergeDetail.map((val) => (
                                        <option key={val?._id} value={val?._id}>
                                          {val?.Name} {" "} {val?.LastName}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className="row mt-4">
                                <div className="col-lg-6 row ">
                                  <label className="col-lg-5 form-label">
                                    Address1
                                  </label>
                                  <div className="col-lg-7">
                                    {
                                      MergeData.Address1.length > 0 ? (
                                        <select className="form-control m-input" name="Address1Merge" id="Address1Merge">
                                          {
                                            MergeData.Address1.map((val, i) => (
                                              <option value={val} key={i}>{val}</option>
                                            ))
                                          }
                                        </select>
                                      ) : (
                                        <input
                                          className="form-control m-input"
                                          name="Address1Merge"
                                          type="text"
                                          id="Address1Merge"
                                        />
                                      )
                                    }

                                  </div>
                                </div>

                                <div className="col-lg-6 row ">
                                  <label className="col-lg-5 form-label">
                                    Address2
                                  </label>
                                  <div className="col-lg-7">
                                    {
                                      MergeData.Address2.length > 0 ? (
                                        <select className="form-control m-input" name="Address2Merge" id="Address2Merge">
                                          {
                                            MergeData.Address2.map((val, i) => (
                                              <option value={val} key={i}>{val}</option>
                                            ))
                                          }
                                        </select>
                                      ) : (
                                        <input
                                          className="form-control m-input"
                                          name="Address2Merge"
                                          type="text"
                                          id="Address2Merge"
                                        />
                                      )
                                    }


                                  </div>
                                </div>
                              </div>

                              <div className="row mt-4">
                                <div className="col-lg-6 row ">
                                  <label className="col-lg-5 form-label">City</label>
                                  <div className="col-lg-7">
                                    {
                                      MergeData.City.length > 0 ? (
                                        <select className="form-control m-input" name="CityMerge" id="CityMerge">
                                          {
                                            MergeData.City.map((val, i) => (
                                              <option value={val} key={i}>{val}</option>
                                            ))
                                          }
                                        </select>
                                      ) : (
                                        <input
                                          className="form-control m-input"
                                          name="CityMerge"
                                          type="text"
                                          id="CityMerge"
                                        />
                                      )
                                    }

                                  </div>
                                </div>

                                <div className="col-lg-6 row ">
                                  <label className="col-lg-5 form-label">State</label>
                                  <div className="col-lg-7">
                                    {
                                      MergeData.State.length > 0 ? (
                                        <select className="form-control m-input" name="StateMerge" id="StateMerge">
                                          {
                                            MergeData.State.map((val, i) => (
                                              <option value={val} key={i}>{val}</option>
                                            ))
                                          }
                                        </select>
                                      ) : (
                                        <input
                                          className="form-control m-input"
                                          name="StateMerge"
                                          type="text"
                                          id="StateMerge"
                                        />
                                      )
                                    }

                                  </div>
                                </div>
                              </div>

                              <div className="row mt-4">
                                <div className="col-lg-6 row ">
                                  <label className="col-lg-5 form-label">Zip</label>
                                  <div className="col-lg-7">
                                    {
                                      MergeData.Zip.length > 0 ? (
                                        <select className="form-control m-input" name="ZipMerge" id="ZipMerge">
                                          {
                                            MergeData.Zip.map((val, i) => (
                                              <option value={val} key={i}>{val}</option>
                                            ))
                                          }
                                        </select>
                                      ) : (
                                        <input
                                          className="form-control m-input"
                                          name="ZipMerge"
                                          type="text"
                                          id="ZipMerge"
                                        />
                                      )
                                    }

                                  </div>
                                </div>

                                <div className="col-lg-6 row ">
                                  <label className="col-lg-5 form-label">
                                    Country
                                  </label>
                                  <div className="col-lg-7">
                                    {
                                      MergeData.Country.length > 0 ? (
                                        <select className="form-control m-input" name="CountryMerge" id="CountryMerge">
                                          {
                                            MergeData.Country.map((val, i) => (
                                              <option value={val} key={i}>{val}</option>
                                            ))
                                          }
                                        </select>
                                      ) : (
                                        <input
                                          className="form-control m-input"
                                          name="CountryMerge"
                                          type="text"
                                          id="CountryMerge"
                                        />
                                      )
                                    }

                                  </div>
                                </div>


                                {
                                  AccountCustomMergeDetail.length != 0 ? (

                                    AccountCustomMergeDetail.map((mergeval) => {
                                      return (
                                        <div className="col-lg-6 row mt-4">
                                          <label className="col-lg-5 form-label">
                                            {mergeval?.FieldName}
                                          </label>
                                          {AccountCustomMergeValueDetail.length ==
                                            0 ? (
                                            <div className="col-lg-7">
                                              <input
                                                className="form-control m-input"
                                                name="CustomMerge"
                                                type="text"
                                                id={mergeval.CustomFieldID + "inp"}
                                              />
                                            </div>
                                          ) : (
                                            <div className="col-lg-7">
                                              <select
                                                autocomplete="off"
                                                className="form-control m-input"
                                                name="Website"
                                                id={mergeval.CustomFieldID + "sel"}
                                              >
                                                {AccountCustomMergeValueDetail.map(
                                                  (val) => {
                                                    return (
                                                      val?.FieldValue?.trim().length != 0 ? <option value={val?._id}>
                                                        {val?.FieldValue}
                                                      </option> : <></>
                                                    )
                                                  }


                                                )}
                                              </select>
                                            </div>
                                          )}
                                        </div>
                                      );
                                    })
                                  ) : (<></>)
                                }
                              </div>
                            </div>
                          </div>

                          <div className="modal-footer">
                            <button
                              onClick={() => {
                                SetShowMergePopup(false);
                              }}
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <a
                              onClick={
                                AccountAddToMerge}
                              id="SubmitMerge"
                              href="javascript:void(0);"
                              className="btn btn-primary btn-lightgreen"
                              disabled={isSaveDisabled}
                            >
                              <i className="la la-save"></i> Save
                            </a>
                          </div>
                        </div>
                      </div>
                    </Popup>
                  </li>
                  <li>
                    {/* <Tooltip title="Bulk Delete"> */}
                      <a
                        onClick={AccountBulkDeleteCsv}
                        className="btn btngroup m-btn m-btn--custom"
                      >
                        <i className="la flaticon-delete-1"></i> Bulk Delete
                      </a>
                    {/* </Tooltip> */}
                  </li>
                  <li>
                    {/* <Tooltip title="Add to BlackList"> */}
                      <a
                        onClick={AccountAddToBlackList}
                        className="btn btngroup m-btn m-btn--custom"
                      >
                        <i className="fa la-icon-font-size-13 fa-ban"></i> Add to BlackList
                      </a>
                    {/* </Tooltip> */}
                  </li>
                  <li>
                    {/* <Tooltip title="Add Filter"> */}
                      <a
                        onClick={() => {
                          SetOpenFilterModel(true);
                          SetTotalDisplayCount(false);
                        }}
                        className="btn btngroup m-btn m-btn--custom"
                      >
                        <i className="fa la-icon-font-size-13 fa-filter"></i> Add Filter
                      </a>
                    {/* </Tooltip> */}
                  </li>
                  <li>
                    {/* <Tooltip title="Custom Column"> */}
                      <a
                        // href="/accountcustomcolumn"
                        onClick={() => {
                          history.push("/accountcustomcolumn");
                        }}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i className="la flaticon-interface-8"></i> Custom Column
                      </a>
                    {/* </Tooltip> */}
                  </li>
                </ul>
                </div>
              </Popover>
            
          </div>
          </div>
        </div>

        <div
          className="portletbody px-0"
          id="import_Account_csv"
          style={{ display: "none" }}
        >
          <div className="row">
            <div className="col-xl-8 offset-xl-2 border-bottom pb-3">
              <h3 className="uploadheadingtitle">Import All</h3>
              <div className="uplodfilesbox">
                <CSVReader
                  accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                  onDrop={HandleOnDrop}
                  onError={HandleOnError}
                  addRemoveButton
                  removeButtonColor="#659cef"
                  onRemoveFile={HandleOnRemoveFile}
                  isReset={Reset}
                >
                  <span>Select .CSV file</span>
                  <p>Add All data from csv file to import.</p>
                </CSVReader>
              </div>
            </div>
            <div className="col-xl-10 offset-xl-1 pt-3">
              <div className="alignright">
                <a
                  onClick={AccountMapCsv}
                  className="btn btn-primary btnColor mr-1"
                >
                  <i className="la la-upload"></i> Upload
                </a>
                <a onClick={CancleAccountBtn} className="btn btn-secondary">
                  <i className="la flaticon-cancel"></i> Cancel
                </a>
              </div>
            </div>
          </div>
          {/* Mapped div */}
          <div
            className="portletbody portletshadow p-4 m-1 map_attribute"
            id="map_attribute"
            style={{ display: "none" }}
          >
            <div className="row col-12">
              <h4 className="headertitle float-left pl-0">Map attributes</h4>
            </div>
            <div className="">
              <div className="row" id="divAccountSelection">
                <div className="form-group m-form__group d-flex col-md-6">
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                        id="dbColumn1"
                      >
                        <option selected="" value="AccountName">
                          Account Name
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        id="AccountName"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled={ImportDisabled}
                        value={
                          AccountName == null
                            ? DropboxData.findIndex(
                              (item) =>
                                item.toLowerCase() === "accountname" ||
                                item.toLowerCase() === "account name"
                            )
                            : AccountName
                        }
                      >
                        <option>Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group m-form__group d-flex col-md-6">
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                        id="dbColumn2"
                      >
                        <option selected="" value="AccountNameLong">
                          Account Name Long
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        id="AccountNameLong"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled={ImportDisabled}
                        value={
                          AccountNameLong == null
                            ? DropboxData.findIndex(
                              (item) =>
                                item.toLowerCase() === "accountnamelong" ||
                                item.toLowerCase() === "account name long"
                            )
                            : AccountNameLong
                        }
                      >
                        <option>Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group m-form__group d-flex col-md-6">
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                        id="dbColumn3"
                      >
                        <option selected="" value="EmailDomains">
                          Email Domains
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        id="EmailDomains"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled={ImportDisabled}
                        value={
                          EmailDomains == null
                            ? DropboxData.findIndex(
                              (item) =>
                                item.toLowerCase() === "emaildomains" ||
                                item.toLowerCase() === "email domains"
                            )
                            : EmailDomains
                        }
                      >
                        <option>Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group m-form__group d-flex col-md-6">
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                        id="dbColumn4"
                      >
                        <option selected="" value="Website">
                          Website
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        id="Website"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled={ImportDisabled}
                        value={
                          Website == null ? DropboxData.findIndex(
                            (item) =>
                              item.toLowerCase() === "website" ||
                              item.toLowerCase() === "web site"
                          )
                            : Website
                        }

                      >
                        <option>Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group m-form__group d-flex col-md-6">
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                        id="dbColumn5"
                      >
                        <option selected="" value="Address1">
                          Address 1
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        id="Address1"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled={ImportDisabled}
                        value={
                          Address1 == null
                            ? DropboxData.findIndex(
                              (item) =>
                                item.toLowerCase() === "address1" ||
                                item.toLowerCase() === "address 1"
                            )
                            : Address1
                        }
                      >
                        <option>Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group m-form__group d-flex col-md-6">
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                        id="dbColumn6"
                      >
                        <option selected="" value="Address2">
                          Address 2
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        id="Address2"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled={ImportDisabled}
                        value={
                          Address2 == null
                            ? DropboxData.findIndex(
                              (item) =>
                                item.toLowerCase() === "address2" ||
                                item.toLowerCase() === "address 2"
                            )
                            : Address2
                        }
                      >
                        <option>Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group m-form__group d-flex col-md-6">
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                        id="dbColumn7"
                      >
                        <option selected="" value="City">
                          City
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        id="City"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled={ImportDisabled}
                        value={City == null ? DropboxData.findIndex(
                          (item) =>
                            item.toLowerCase() === "city"
                        )
                          : City
                        }
                      >
                        <option>Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group m-form__group d-flex col-md-6">
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                        id="dbColumn8"
                      >
                        <option selected="" value="State">
                          State
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        id="State"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled={ImportDisabled}
                        value={State == null ? DropboxData.findIndex(
                          (item) =>
                            item.toLowerCase() === "state"
                        )
                          : State
                        }
                      >
                        <option>Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group m-form__group d-flex col-md-6">
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                        id="dbColumn9"
                      >
                        <option selected="" value="Zip">
                          Zip
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        id="Zip"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled={ImportDisabled}
                        value={Zip == null ? DropboxData.findIndex(
                          (item) =>
                            item.toLowerCase() === "zip"
                        )
                          : Zip
                        }
                      >
                        <option>Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group m-form__group d-flex col-md-6">
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                        id="dbColumn10"
                      >
                        <option selected="" value="Country">
                          Country
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        id="Country"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled={ImportDisabled}
                        value={
                          Country == null ? DropboxData.findIndex(
                            (item) =>
                              item.toLowerCase() === "country"
                          )
                            : Country
                        }
                      >
                        <option>Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group m-form__group d-flex col-md-6">
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                        id="dbColumn11"
                      >
                        <option selected="" value="AccountCategory">
                          Account Category
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        id="AccountCategory"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled={ImportDisabled}
                        value={
                          AccountCategory == null
                            ? DropboxData.findIndex(
                              (item) =>
                                item.toLowerCase() === "account category"
                            )
                            : AccountCategory
                        }
                      >
                        <option>Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group m-form__group d-flex col-md-6">
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                        id="dbColumn12"
                      >
                        <option selected="" value="Industry">
                          Industry
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        id="Industry"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled={ImportDisabled}
                        value={
                          Industry == null
                            ? DropboxData.findIndex(
                              (item) =>
                                item.toLowerCase() === "industry"
                            )
                            : Industry
                        }
                      >
                        <option>Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group m-form__group d-flex col-md-6">
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                        id="dbColumn13"
                      >
                        <option selected="" value="AccountOwner">
                          Account Owner
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        id="AccountOwner"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled={ImportDisabled}
                        value={
                          AccountOwner == null
                            ? DropboxData.findIndex(
                              (item) =>
                                item.toLowerCase() === "account owner" ||
                                item.toLowerCase() === "accountowner"
                            )
                            : AccountOwner
                        }
                      >
                        <option>Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group m-form__group d-flex col-md-6">
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                        id="dbColumn14"
                      >
                        <option selected="" value="AccountStatus">
                          Account Status
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        id="AccountStatus"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled={ImportDisabled}
                        value={
                          AccountStatus == null
                            ? DropboxData.findIndex(
                              (item) =>
                                item.toLowerCase() === "account status" ||
                                item.toLowerCase() === "accountstatus"
                            )
                            : AccountStatus
                        }
                      >
                        <option>Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group m-form__group d-flex col-md-6">
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                        id="dbColumn15"
                      >
                        <option selected="" value="ReasonUnqualified">
                          Reason Unqualified
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        id="ReasonUnqualified"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled={ImportDisabled}
                        value={
                          ReasonUnqualified == null
                            ? DropboxData.findIndex(
                              (item) =>
                                item.toLowerCase() === "reason unqualified" ||
                                item.toLowerCase() === "reasonunqualified"
                            )
                            : ReasonUnqualified
                        }
                      >
                        <option>Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group m-form__group d-flex col-md-6">
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                        id="dbColumn16"
                      >
                        <option selected="" value="ClientStatus">
                          Client Status
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        id="ClientStatus"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled={ImportDisabled}
                        value={
                          ClientStatus == null
                            ? DropboxData.findIndex(
                              (item) =>
                                item.toLowerCase() === "account client status" ||
                                item.toLowerCase() === "accountclientstatus"
                            )
                            : ClientStatus
                        }

                      >
                        <option>Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group m-form__group d-flex col-md-6">
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                        id="dbColumn18"
                      >
                        <option selected="" value="AccountType">
                          Account Type
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        id="AccountType"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled={ImportDisabled}
                        value={
                          AccountType == null
                            ? DropboxData.findIndex(
                              (item) =>
                                item.toLowerCase() === "accounttype" ||
                                item.toLowerCase() === "account type"
                            )
                            : AccountType
                        }
                      >
                        <option>Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group m-form__group d-flex col-md-6">
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                        id="dbColumn18"
                      >
                        <option selected="" value="CompanyPhone">
                          Company Phone
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        id="CompanyPhone"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled={ImportDisabled}
                        value={
                          CompanyPhone == null
                            ? DropboxData.findIndex(
                              (item) =>
                                item.toLowerCase() === "companyphone" ||
                                item.toLowerCase() === "company phone"
                            )
                            : CompanyPhone
                        }
                      >
                        <option>Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group m-form__group d-flex col-md-6">
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                        id="dbColumn19"
                      >
                        <option selected="" value="CompanyRevenue">
                          Company Revenue
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        id="CompanyRevenue"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled={ImportDisabled}
                        value={
                          CompanyRevenue == null
                            ? DropboxData.findIndex(
                              (item) =>
                                item.toLowerCase() === "company revenue" ||
                                item.toLowerCase() === "companyrevenue"
                            )
                            : CompanyRevenue
                        }
                      >
                        <option>Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group m-form__group d-flex col-md-6">
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        className="dbcontent form-control  m-input"
                        disabled="disabled"
                        id="dbColumn20"
                      >
                        <option selected="" value="EmployeeCount">
                          Employee Count
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 text-center">
                    <span className="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div className="col-lg-5 nopadding">
                    <div>
                      <select
                        id="EmployeeCount"
                        className="csvcontent form-control  m-input"
                        autocomplete="off"
                        disabled={ImportDisabled}
                        value={
                          EmployeeCount == null
                            ? DropboxData.findIndex(
                              (item) =>
                                item.toLowerCase() === "employee count" ||
                                item.toLowerCase() === "employeecount" ||
                                item.toLowerCase() === "companyemployeecount" || 
                                item.toLowerCase() === "company employee count"
                            )
                            : EmployeeCount
                        }
                      >
                        <option>Not Mapped</option>
                        {DropboxData.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group m-form__group d-flex px-4 col-md-6">
                  <div className="toggleswich mr-1">
                    <input
                      type="checkbox"
                      className="checktogle"
                      id="IsUpdateExistAccounts"
                    />
                    <b className="switch">
                      <span className="checkion"></span>
                      <span className="uncheckion"></span>
                    </b>
                    <b className="track"></b>
                  </div>
                  <label> Skip updating existing accounts</label>
                </div>
                <div className="form-group m-form__group d-flex px-4 col-md-6">
                  <div className="toggleswich mr-1">
                    <input
                      type="checkbox"
                      className="checktogle"
                      id="IsInsertNullValues"
                    />
                    <b className="switch">
                      <span className="checkion"></span>
                      <span className="uncheckion"></span>
                    </b>
                    <b className="track"></b>
                  </div>
                  <label> Insert Null Values</label>
                </div>
                <div className="px-4 col-12">
                  <div className="col-xs-12 border-top py-4">
                    <button
                      disabled={ImportDisabled}
                      onClick={AccountSaveCsv}
                      className="btn btn-primary btn-lightgreen"
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>



        {/* Delete for csv */}
        {/* Bulk Csv for */}
        <div
          className="portletbody"
          id="delete_csv"
          style={{ display: "none" }}
        >
          <div className="row">
            <div className="col-xl-8 offset-xl-2 border-bottom py-3 pb-2">
              <h3 className="uploadheadingtitle">Bulk Delete Accounts</h3>
              <div className="uplodfilesbox">
                <CSVReader
                  accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                  onDrop={HandleOnDrop}
                  onError={HandleOnError}
                  addRemoveButton
                  removeButtonColor="#659cef"
                  onRemoveFile={HandleOnRemoveFile}
                  isReset={Reset}
                >
                  <span>Select .CSV file</span>
                  <p>Add accounts data from csv file to bulk delete.</p>
                </CSVReader>
              </div>
            </div>
            <div className="col-xl-10 offset-xl-1 pt-3">
              <div className="alignright">
                <a
                  onClick={BulkDeleteAccountBtn}
                  className="btn btn-primary btnColor mr-1"
                >
                  <i className="la la-upload"></i> Upload
                </a>
                <a onClick={CancelBulkAccountBtn} className="btn btn-secondary">
                  <i className="la flaticon-cancel"></i> Cancel
                </a>
              </div>
            </div>
          </div>
        </div>

        {TotalDisplayCount == true ? (
          <div className="portletbody px-0" id=" ">
            <div className="row col-xs-12">
              <h4 className="headertitle float-left">Results</h4>
            </div>

            <div className="col-xs-12">
              <div className="row colfive">
                <div className="col-md-3">
                  <div className="shadowcard boxcardcounter">
                    <div className="d-flex align-center">
                      <div className="contectboxes w-100">
                        <div className="line-title mb-2">
                          <span className="box-main-title font-primary">
                            {TotalResult}
                          </span>
                        </div>
                        <div className="box-inner-title">
                          <string className="clr-sky">Total</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="shadowcard boxcardcounter">
                    <div className="d-flex align-center">
                      <div className="contectboxes w-100">
                        <div className="line-title mb-2">
                          <span className="box-main-title font-success">
                            {TotalSuccess}
                          </span>
                        </div>
                        <div className="box-inner-title">
                          <string className="clr-sky">Total success</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="shadowcard boxcardcounter">
                    <div className="d-flex align-center">
                      <div className="contectboxes w-100">
                        <div className="line-title mb-2">
                          <span className="box-main-title font-danger">
                            {TotalError}
                          </span>
                        </div>
                        <div className="box-inner-title">
                          <string className="clr-sky">Total error</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="shadowcard boxcardcounter">
                    <div className="d-flex align-center">
                      <div className="contectboxes w-100">
                        <div className="line-title mb-2">
                          <span className="box-main-title font-warning">
                            {TotalDuplicate}
                          </span>
                        </div>
                        <div className="box-inner-title">
                          <string className="clr-sky">Total Duplicate</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="shadowcard boxcardcounter">
                    <div className="d-flex align-center">
                      <div className="contectboxes w-100">
                        <div className="line-title mb-2">
                          <span className="box-main-title font-warning">
                            {TotalSkipped}
                          </span>
                        </div>
                        <div className="box-inner-title">
                          <string className="clr-sky">Total Skipped</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="shadowcard boxcardcounter">
                    <div className="d-flex align-center">
                      <div className="contectboxes w-100">
                        <div className="line-title mb-2">
                          <span className="box-main-title font-warning">
                            {TotalInvalid}
                          </span>
                        </div>
                        <div className="box-inner-title">
                          <string className="clr-sky">Total Invalid</string>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {/* Mapped div */}
        <div
          className="portletbody portletshadow p-4 m-1 map_attribute"
          id="map_attribute_domain_Accountlist"
          style={{ display: "none" }}
        >
          <div className="row col-xs-12">
            <h4 className="headertitle float-left pl-0">Map attributes</h4>
          </div>
          <div className="row col-xs-12">
            <div className="form-group m-form__group d-flex col-lg-6 mx-0">
              <div className="col-lg-5 nopadding">
                <div>
                  <select
                    className="dbcontent form-control  m-input"
                    disabled="disabled"
                  >
                    <option selected="" value="Email">
                      Domain
                    </option>
                  </select>
                </div>
              </div>
              <div className="col-lg-2 text-center">
                <span className="fa fa-arrows-h fa-2x "></span>
              </div>
              <div className="col-lg-5 nopadding">
                <div>
                  <select
                    id="DomainName"
                    className="csvcontent form-control  m-input"
                    autocomplete="off"
                  >
                    <option value="not mapped">Not Mapped</option>
                    {DropboxData.map((dbdata, index) => (
                      <option value={index}>{dbdata}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 border-top pt-4 mt-2 px-2">
            <button
              disabled={DisabledBulkDelete}
              onClick={DeleteBulkAccountCsv}
              className="btn btn-primary btn-lightgreen"
            >
              <i className="la la-save"></i>Delete
            </button>
          </div>
        </div>
        {IsApply == true ? (
          <a
            className="float-right"
            onClick={() => {
              ResetFilter();
            }}
          >
            Reset
          </a>
        ) : null}
        <div className="row mt-4 px-1 d-flex align-items-center justify-content-between pt-4">
          <div className="col-sm-12 col-md-6 ">
            <label className="textlabelte">
              Show
              <select
                name="tbl_meeting_length"
                onChange={ChangeRowSelected}
                aria-controls="tbl_meeting"
                className="form-control form-control-sm"
                value={RowsPerPage}
              >
                {CommonConstants.show_rows.map((value) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
              entries
            </label>
          </div>
          <div className="col-sm-12 col-md-6 full-right">
            <label className="textlabelte">
              Search:{" "}
              <input
                type="search"
                id="search"
                onKeyPress={RequestSearch}
                className="form-control form-control-sm ml-2"
                placeholder=""
                aria-controls="tbl_meeting"
              />
            </label>
          </div>
        </div>
        <Popup open={OpenFilterModel}>
          <div>
            <div className="modal-black"></div>
            <div className="filterPopup">
              <div className="paddingboxTerms">
                <div className="modal-header py-4 px-4">
                  <h5>Filter</h5>
                  <a
                    className="close-ion text-black-50"
                    onClick={() => {
                      CloseFilterModel();
                    }}
                  >
                    <span aria-hidden="true" className="la la-remove"></span>
                  </a>

                  {/* <button className="close" onClick={close}></button> */}
                </div>
                <div className="modal-content bordernone p-0">
                  <div className="row">
                    <div className="col-xl-6 allcentertext">
                      <i className="la la-filter iconsfonts"></i>
                      <a
                        id="lnkAddNewFilter"
                        onClick={() => {
                          OpenAddFilterModel();
                        }}
                        className="btn btn-md btn-primary btnColor"
                      >
                        Add New Filter
                      </a>
                    </div>
                    <div className="col-xl-6 allcentertext">
                      <i className="la la-filter iconsfonts"></i>
                      <a
                        id="lnkAddNewFilter"
                        onClick={() => {
                          OpenSelectExistFilterMode();
                        }}
                        className="btn btn-md btn-primary btnColor"
                      >
                        Select from existing Filter
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Popup>
        <Popup open={AddFilterModel}>
          <div>
            <div className="modal-black"></div>
            <div className="filterPopup largerPopup1000">
              <div className="paddingboxTerms">
                <div className="modal-header py-4 px-3">
                  <div className="w-100 d-flex px-3">
                    <h5 className="mb-0">New Filter</h5>
                    <button
                      className="close"
                      onClick={() => {
                        CloseAddFilterModel();
                      }}
                    >
                      <span aria-hidden="true" className="la la-remove"></span>
                    </button>
                  </div>
                </div>
                <div className="p-3">
                  <div className="modal-body">
                    <div className="col-sm-6 mb-5 px-0">
                      <div className="row mb-3">
                        <label className="col-lg-5 col-form-label text-left">
                          Filter Name
                        </label>
                        <div className="col-lg-7">
                          <input
                            className="form-control m-input"
                            data-val-required="Please enter title"
                            id="FilterName"
                            name="FilterName"
                            placeholder="Filter Name"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="table-bordered">
                      <table className="table  table-hover" id="tab_logic">
                        <TableContainer component={Paper}>
                          <Table size="small" aria-label="a dense table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Property </TableCell>
                                <TableCell align="right"> </TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {FieldAttributes.map((itemcount, i) => (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell align="right">
                                    <select
                                      className="form-control m-input"
                                      id={"ColumnName" + itemcount.id}
                                      onChange={() => {
                                        ColumnhandledDropdownChange(
                                          itemcount.id,
                                          i
                                        );
                                      }}
                                      // value={this.state.selectedvalue}
                                      autocomplete="off"
                                    >
                                      {CustomColumnData.map((el) => (
                                        <option key={el.FieldValue} value={el.FieldValue}>
                                          {" "}
                                          {el.ColumnName}{" "}
                                        </option>
                                      ))}
                                    </select>
                                  </TableCell>

                                  <TableCell>
                                    {itemcount.IsCustom == true ? (
                                      <select
                                        className="form-control m-input"
                                        id={"ColumnName" + itemcount.id}
                                        // onChange={()=>{ColumnhandledDropdownChange(itemcount.id)}}
                                        // value={selectedvalue}
                                        autocomplete="off"
                                      >
                                        <option id="text" value="Text">
                                          {" "}
                                          Text
                                        </option>
                                      </select>
                                    ) : null}
                                  </TableCell>

                                  <TableCell align="right">
                                    {FieldAttributes[i].ColumnName ==
                                      "CreatedDate" ? (
                                      <select
                                        id={"expression" + itemcount.id}
                                        className="form-control m-input"
                                        onChange={() => {
                                          ExpressionHandler(itemcount.id);
                                        }}
                                      >
                                        <option id="Equals" value="EqualTo">
                                          Equals
                                        </option>
                                        <option
                                          id="Lessthan"
                                          value="LessThan"
                                        >
                                          {" "}
                                          Less Than{" "}
                                        </option>
                                        <option
                                          id="LessThanEqualTo"
                                          value="LessThanEqualTo"
                                        >
                                          {" "}
                                          Less Than Equal To{" "}
                                        </option>
                                        <option
                                          id="greaterthan"
                                          value="GreaterThan"
                                        >
                                          {" "}
                                          Greater Than{" "}
                                        </option>
                                        <option
                                          id="greaterthanequalto"
                                          value="GreaterThanEqualTo"
                                        >
                                          {" "}
                                          Greater Than Eqaul To{" "}
                                        </option>
                                        <option id="between" value="between">
                                          {" "}
                                          Between{" "}
                                        </option>
                                      </select>
                                    ) : (
                                      <select
                                        id={"expression" + itemcount.id}
                                        defaultValue={SelectOptionValue}
                                        onChange={() => {
                                          ExpressionHandler(itemcount.id);
                                        }}
                                        className="form-control m-input"
                                      >
                                        <option
                                          id={"ContainTo" + itemcount}
                                          value="Contains"
                                        >
                                          Contains
                                        </option>
                                        <option
                                          id={"EqualTo" + itemcount}
                                          value="EqualTo"
                                        >
                                          Equals
                                        </option>
                                      </select>
                                    )}
                                  </TableCell>
                                  <TableCell align="right">
                                    {itemcount.ColumnName == "CreatedDate" ? (
                                      itemcount.InputType == "between" ? (
                                        <>
                                          <input
                                            type="date"
                                            id={"searchvalue" + itemcount.id}
                                            className="form-control m-input required"
                                          />
                                          <input
                                            type="date"
                                            id={"EndsWith" + itemcount.id}
                                            className="form-control m-input required"
                                          />
                                        </>
                                      ) : (
                                        <input
                                          type="date"
                                          id={"searchvalue" + itemcount.id}
                                          className="form-control m-input required"
                                        />
                                      )
                                    ) : (
                                      <input
                                        type="text"
                                        id={"searchvalue" + itemcount.id}
                                        className="form-control m-input required"
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell align="right"></TableCell>
                                  <TableCell align="right">
                                    <button
                                      type="button"
                                      id={"delete" + itemcount.id}
                                      className="btn btngroup"
                                      onClick={() => {
                                        handleRemoveClick(itemcount.id);
                                      }}
                                    >
                                      <i className="la flaticon-delete-1"></i>
                                    </button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 text-left pb-5">
                        <button
                          type="button"
                          className="btn btngroup"
                          value="ADD Row"
                          onClick={() => {
                            handleClick();
                          }}
                        >
                          <i className="la la-plus la-icon-font-size-13"></i> Add
                          Property{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="border-top modal-content text-right mt-5">
                    <div className="row ">
                      <div className="col-lg-12 pull-right">
                        <a
                          id="backtolist"
                          className="btn btn-secondary mr-2"
                          onClick={() => {
                            CloseAddFilterModel();
                          }}
                        >
                          Cancel
                        </a>
                        <a
                          id="submit"
                          className="btn btn-primary btn-lightgreen mr-1"
                          onClick={() => {
                            filterSearch();
                          }}
                          value="Apply"
                        >
                          Apply
                        </a>
                        <button
                          id="submit"
                          className="btn btn-primary btn-lightgreen mr-1"
                          onClick={() => {
                            addfilterAccountfilter();
                          }}
                          disabled={DisabledSaveFilter}
                          value="Save"
                        >
                          <i className="la la-save"></i> Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Popup>

        <Popup open={OpenSelectFilterModel}>
          <div>
            <div className="modal-black"></div>
            <div className="filterPopup largerPopup1000">
              <div className="paddingboxTerms">
                <div className="modal-header py-4 px-3">
                  <div className="w-100 d-flex px-3">
                    <h5 className="mb-0">Edit Filter</h5>
                    <button
                      className="close"
                      onClick={() => {
                        CloseSelectExistFilterMode();
                      }}
                    >
                      <span aria-hidden="true" className="la la-remove"></span>
                    </button>
                  </div>
                </div>
                <div className="p-3">
                  <div className="modal-body">
                    <div className="col-sm-6 mb-5 px-0">
                      <div className="row mb-3">
                        <label className="col-lg-5 col-form-label text-left">
                          Filter Name
                        </label>
                        <div className="col-lg-7">
                          <select
                            className="form-control m-input m--hide"
                            id="FilterID"
                            name="FilterID"
                            onChange={() => {
                              handleChangeFilter();
                            }}
                          >
                            {FilterName.map((valuefilter) => (
                              <option value={valuefilter._id}>
                                {valuefilter.FilterName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="table-bordered">
                      <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Property </TableCell>
                              <TableCell>  </TableCell>
                              <TableCell>  </TableCell>
                              <TableCell>  </TableCell>
                              <TableCell>  </TableCell>
                              <TableCell>  </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {EditFieldAttributes.map(
                              (itemcount2, index) => (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell>
                                    <select
                                      className="form-control m-input"
                                      id={"EditColumnName" + itemcount2.id}
                                      onChange={() => {
                                        EditColumnName(itemcount2.id);
                                      }}
                                      value={itemcount2.FilterPropertyName}
                                      autocomplete="off"
                                    >
                                      {CustomColumnData.map((el) => (
                                        <option value={el.FieldValue}>
                                          {" "}
                                          {el.ColumnName}{" "}
                                        </option>
                                      ))}
                                    </select>
                                  </TableCell>
                                  <TableCell>
                                    {itemcount2.IsCustom == true ? (
                                      <select
                                        className="form-control m-input"
                                        id={"ColumnName" + itemcount2.id}
                                        // onChange={()=>{ColumnhandledDropdownChange(itemcount.id)}}
                                        // value={selectedvalue}
                                        autocomplete="off"
                                      >
                                        <option id="text" value="Text">
                                          {" "}
                                          Text
                                        </option>
                                      </select>
                                    ) : null}
                                  </TableCell>
                                  <TableCell align="right">
                                    {itemcount2.FilterPropertyName ==
                                      "CreatedDate" ? (
                                      <select
                                        id={
                                          "Editexpression" + itemcount2.id
                                        }
                                        className="form-control m-input"
                                        value={itemcount2.ExpressionName}
                                        onChange={() => {
                                          EditExpressValue(itemcount2.id);
                                        }}
                                      >
                                        <option id="Equals" value="EqualTo">
                                          Equals
                                        </option>
                                        <option
                                          id="Lessthan"
                                          value="LessThan"
                                        >
                                          {" "}
                                          Less Than{" "}
                                        </option>
                                        <option
                                          id="LessThanEqualTo"
                                          value="LessThanEqualTo"
                                        >
                                          {" "}
                                          Less Than Equal To{" "}
                                        </option>
                                        <option
                                          id="greaterthan"
                                          value="GreaterThan"
                                        >
                                          {" "}
                                          Greater Than{" "}
                                        </option>
                                        <option
                                          id="greaterthanequalto"
                                          value="GreaterThanEqualTo"
                                        >
                                          {" "}
                                          Greater Than Equal To{" "}
                                        </option>
                                        <option
                                          id="between"
                                          value="between"
                                        >
                                          {" "}
                                          Between{" "}
                                        </option>
                                      </select>
                                    ) : (
                                      <select
                                        id={
                                          "Editexpression" + itemcount2.id
                                        }
                                        value={itemcount2.ExpressionName}
                                        onChange={() => {
                                          EditExpressValue(itemcount2.id);
                                        }}
                                        className="form-control m-input"
                                      >
                                        <option
                                          id={"ContainTo" + itemcount2}
                                          value="Contains"
                                        >
                                          Contains
                                        </option>
                                        <option
                                          id={"EqualTo" + itemcount2}
                                          value="EqualTo"
                                        >
                                          Equals
                                        </option>
                                      </select>
                                    )}
                                  </TableCell>

                                  <TableCell align="right">
                                    {itemcount2.FilterPropertyName ==
                                      "CreatedDate" ? (
                                      itemcount2.ExpressionName ==
                                        "between" ? (
                                        <>
                                          <input
                                            type="date"
                                            id={
                                              "Editsearchvalue" +
                                              itemcount2.id
                                            }
                                            onChange={() => {
                                              EditFilterPropertyValue1(
                                                itemcount2.id
                                              );
                                            }}
                                            value={
                                              itemcount2.FilterPropertyValue1
                                            }
                                            className="form-control m-input required"
                                          />
                                          <input
                                            type="date"
                                            id={
                                              "EditEndsWith" + itemcount2.id
                                            }
                                            onChange={() => {
                                              EditFilterPropertyValue2(
                                                itemcount2.id
                                              );
                                            }}
                                            value={
                                              itemcount2.FilterPropertyValue2
                                            }
                                            className="form-control m-input required"
                                          />
                                        </>
                                      ) : (
                                        <input
                                          type="date"
                                          id={
                                            "Editsearchvalue" +
                                            itemcount2.id
                                          }
                                          onChange={() => {
                                            EditFilterPropertyValue1(
                                              itemcount2.id
                                            );
                                          }}
                                          value={
                                            itemcount2.FilterPropertyValue1
                                          }
                                          className="form-control m-input required"
                                        />
                                      )
                                    ) : (
                                      <input
                                        type="text"
                                        id={
                                          "Editsearchvalue" + itemcount2.id
                                        }
                                        onChange={() => {
                                          EditFilterPropertyValue1(
                                            itemcount2.id
                                          );
                                        }}
                                        value={
                                          itemcount2.FilterPropertyValue1
                                        }
                                        className="form-control m-input required"
                                      />
                                    )}
                                  </TableCell>

                                  <TableCell align="right">
                                    <button
                                      type="button"
                                      id={"delete" + itemcount2.id}
                                      className="btn btngroup"
                                      onClick={() => {
                                        handleEditRemoveClick(
                                          itemcount2.id
                                        );
                                      }}
                                    >
                                      <i className="la flaticon-delete-1"></i>
                                    </button>
                                  </TableCell>
                                  <TableCell align="right"></TableCell>
                                  <TableCell align="right"></TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 text-left">
                        <button
                          type="button"
                          className="btn btngroup"
                          onClick={() => {
                            handleeditPropertyClick();
                          }}
                        >
                          <i className="la la-plus la-icon-font-size-13"></i> Add
                          Property{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="border-top modal-content text-right">
                    <div className="row">
                      <div className="col-lg-12 pull-right">
                        <a
                          id="backtolist"
                          className="btn btn-secondary mr-2"
                          onClick={() => {
                            CloseSelectExistFilterMode();
                          }}
                        >
                          cancel
                        </a>
                        <a
                          id="submit"
                          className="btn btn-primary btn-lightgreen mr-1"
                          onClick={() => {
                            ApplyEditFilter();
                          }}
                        >
                          Apply
                        </a>
                        <a
                          id="submit"
                          className="btn btn-primary btn-lightgreen mr-1"
                          onClick={() => {
                            editapplyfilterSearch();
                          }}
                        >
                          <i className="la la-save"></i> Save
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Popup>

        <div className="table-bordered">
          <TableContainer component={Paper}>
            <StyleHeader isDataLoaded={isDataLoaded} />
            <Table className="table-ref" aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" className="px-3">
                    <input
                      type="checkbox"
                      onChange={(e) => CheckHandler(e)}
                      checked={((Rows.length === AddArray.length) && (Rows.length !== 0)) ? true : false}
                    />
                  </TableCell>
                  <TableCell></TableCell>
                 
                    {HeaderList?.map((row) => (
                      <TableCell className={row.ColumnName.replace(/\s+/g, "-")} key={row._id} onClick={() => { SortData(row.ColumnName.replace(/\s+/g, ""));setSortedColumn(row.ColumnName);}}>
                          {row.ColumnName}
                         <span className="shorting">
                         <ArrowUpward className={SortBy === 1 && sortedColumn === row.ColumnName ? "active" : null} />
                         <ArrowDownward className={SortBy === -1 && sortedColumn === row.ColumnName ? "active" : null} />
                          </span>
                      </TableCell>
                    ))}
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Rows.length === 0 ? (
                    <p className="text-center">No data available in table</p>
                  ) : (
                    Rows?.map((value1) => (
                      <React.Fragment key={value1?._id}>
                        <TableRow>
                          <TableCell align="center">
                            <input
                              id={value1._id}
                              type="checkbox"
                              // onChange={(e) => CheckBoxClick(e, value1)}
                              checked={AddArray.includes(value1)}
                              onChange={(e) => CheckBoxClick(e, value1)}
                            />
                          </TableCell>
                          <TableCell>
                            {" "}
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() =>
                                {
                                  SetOpen((prev) => ({
                                  ...prev,
                                  [value1._id]: !prev[value1._id],
                                }))
                                GetCountDetailsInSubTable(value1._id)
                              }
                              }
                            >
                              {Open[value1._id] ? (
                                <i
                                  className="fa fa-minus-circle minusl"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-plus-circle plusbl"
                                  aria-hidden="true"
                                ></i>
                              )}
                            </IconButton>
                          </TableCell>
                          {HeaderList.map((row) => {
                            if (row.ColumnName == "Account Name") {
                              return (
                                <TableCell><a 
                                onClick={() => {
                                  ViewPage(value1._id);
                                }}

                              ><u>{value1?.AccountName}</u></a></TableCell>
                            );
                          } else if (row.ColumnName == "Email Domains") {
                            return (
                              <TableCell>{value1?.EmailDomains}</TableCell>
                            );
                          } else if (row.ColumnName == "Website") {
                            return <TableCell><a href={`//${value1?.Website}`} target="_blank" rel="noopener noreferrer"><u>{value1?.Website}</u></a></TableCell>;
                          } else if (row.ColumnName == "Address 1") {
                            return <TableCell>{value1?.Address1}</TableCell>;
                          } else if (row.ColumnName == "Address 2") {
                            return <TableCell>{value1?.Address2}</TableCell>;
                          } else if (row.ColumnName == "City") {
                            return <TableCell>{value1?.City}</TableCell>;
                          } else if (row.ColumnName == "State") {
                            return <TableCell>{value1?.State}</TableCell>;
                          } else if (row.ColumnName == "Zip") {
                            return <TableCell>{value1?.Zip}</TableCell>;
                          } else if (row.ColumnName == "Country") {
                            return <TableCell>{value1?.Country}</TableCell>;
                          } else if (row.ColumnName == "Account Category") {
                            return (
                              <TableCell>{value1?.AccountCategory}</TableCell>
                            );
                          } else if (row.ColumnName == "Industry") {
                            return <TableCell>{value1.Industry}</TableCell>;
                          } else if (row.ColumnName == "Account Owner") {
                            return (
                              <TableCell>{value1?.AccountOwner}</TableCell>
                            );
                          } else if (row.ColumnName == "Reason Unqualified") {
                            return (
                              <TableCell>
                                {value1?.ReasonUnqualified}
                              </TableCell>
                            );
                          } else if (row.ColumnName == "Company Phone") {
                            return (
                              <TableCell>{value1?.CompanyPhone}</TableCell>
                            );
                          } else if (
                            row.ColumnName == "Account Client Status"
                          ) {
                            return (
                              <TableCell>
                                {value1?.AccountClientStatus}
                              </TableCell>
                            );
                          } else if (row.ColumnName == "Account Type" || row.ColumnName == "AccountType") {
                            return (
                              <TableCell>{value1?.AccountType}</TableCell>
                            );
                          } else if (row.ColumnName == "Created Date") {
                            return (
                              <TableCell>
                                {
                                  value1?.CreatedDate == null ? "" : moment.utc(
                                    new Date(value1?.CreatedDate)
                                  ).format("MM/DD/YYYY")
                                }
                                {/* {value1?.CreatedDate} */}
                              </TableCell>
                            );
                          } else if (row.ColumnName == "Company Revenue") {
                            return (
                              <TableCell>{value1?.CompanyRevenue}</TableCell>
                            );
                          }
                          // else if (row.ColumnName == "Account Status") {
                          //   return (
                          //     <TableCell>{value1?.AccountStatus}</TableCell>
                          //   );
                          // } 
                          else if (row.ColumnName == "Employee Count") {
                            return (
                              <TableCell>
                                {value1?.CompanyEmployeeCount}
                              </TableCell>
                            );
                          } else if (row.ColumnName == "Total Call Count") {
                            return <TableCell>{value1?.TotalCallCount}</TableCell>;
                          } else if (
                            row.CustomFieldID != null ||
                            row.CustomFieldID != undefined
                          ) {
                            let valcust = value1.CustomField?.filter(
                              (item) => {
                                return (
                                  item.CustomFieldID == row.CustomFieldID
                                );
                              }
                            );
                            if (valcust.length > 0) {
                              return (
                                <TableCell>{valcust[0].FieldValue}</TableCell>
                              );
                            } else {
                              return <TableCell></TableCell>;
                            }
                          }
                          //----------------------------------------------//
                          else {
                            return <TableCell></TableCell>;
                          }
                        })}

                        <TableCell>
                          <a title="View"
                            onClick={() => {
                              ViewPage(value1._id);
                            }}
                            className="btn-eyesicon"
                          >
                            <i className="la flaticon-eye edit-icon"></i>
                          </a>
                          <a title="Delete"
                            onClick={() => {
                              DeleteClientName(value1._id, value1);
                            }}
                            className="btn-eyesicon"
                          >
                            <i className="la flaticon-delete-1 delete-icon"></i>
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={15}
                        >
                          <Collapse
                            in={Open[value1._id]}
                            timeout="auto"
                            unmountOnExit
                          >
                            {Open[value1._id] && (
                              <Box margin={1} className="innertables">
                                <Table size="small" aria-label="purchases">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Account Status </TableCell>
                                      <TableCell>Last Send Date</TableCell>
                                      <TableCell>
                                        Last Response Date
                                      </TableCell>
                                      <TableCell>Sent Count</TableCell>
                                      <TableCell>Open Count</TableCell>
                                      <TableCell>Reply Count</TableCell>
                                      <TableCell>Soft Bounce Count</TableCell>
                                      <TableCell>
                                        Hard Bounces Count
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableRow>
                                    {SubTabData?.map((Value) =>
                                      Value._id.toString() === value1._id.toString() ? (

                                        <>
                                          <TableCell>
                                            {Value?.AccountStatus}
                                          </TableCell>
                                          <TableCell>
                                            {Value?.LastSendDate}
                                          </TableCell>
                                          <TableCell>
                                            {Value?.LastResponsesDate}
                                          </TableCell>
                                          <TableCell>
                                            {Value?.SentCount}
                                          </TableCell>
                                          <TableCell>
                                            {Value?.OpenCount}
                                          </TableCell>
                                          <TableCell>
                                            {Value?.ReplyCount}
                                          </TableCell>
                                          <TableCell>
                                            {Value?.SoftBounceCount}
                                          </TableCell>
                                          <TableCell>
                                            {Value?.HardBouncesCount}
                                          </TableCell>
                                        </>



                                      ) : (
                                        <React.Fragment key={Value.someUniqueKey}> </React.Fragment>
                                      )
                                    )}
                                  </TableRow>

                                </Table>
                              </Box>
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
              {Rlen} entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>

      </div>
    </>
  );
}
