import React, { useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Pagination from "@material-ui/lab/Pagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import contactlistloadingicon from "../../../images/loading.gif";
import Swal from "sweetalert2";
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function Contactlist({ ListID, handClose,updateFromChild }) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("ProspectID");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [DataInfo, SetDataInfo] = React.useState([]);
  const [UpdateInfo, SetUpdateInfo] = React.useState([]);
  const [SaveList, SetSaveList] = React.useState(false);
  const [IsChecked, SetIsChecked] = React.useState(false);
  const [ContactListData, SetContactListData] = React.useState([]);
  const [UnContactListData, SetUnContactListData] = React.useState([]);
  const [CUserID, SetCUserID] = React.useState(0);

  useEffect(() => {
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    ContactListGet(GetUserData.ClientID, GetUserData.ParentUserID, ListID);
  }, [Search, Page, RowsPerPage, handClose]);

  //get contact list
  const ContactListGet = async (CID, UID, LID) => {
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: Role,
      ListID: LID,
    };
    const ContactLists = await Axios({
      url: CommonConstants.MOL_APIURL + "/list/ContactList",
      method: "POST",
      data: InputParameter,
    });
    if (ContactLists.data.StatusMessage === "Succuss") {
      let Result = ContactLists;

      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      document.getElementById("contactlist-hideloading").style.display = "none";
      var NewArray = [];

      Result.data.PageData.map((item) => {
        if (ContactListData.length == 0) {
          if (item.ContactList?.ListID === ListID) {
            NewArray.push({
              ProspectListID: item.ContactList?._id,
              ProspectID: item._id,
            });
            SetContactListData(NewArray);
          }
        } else {
          ContactListData.map((doc) => {
            if (doc.ProspectID === item._id) {
              item.ContactList.ListID = ListID;
            }
          });
          NewArray.push({
            ProspectListID: item.ContactList?._id,
            ProspectID: item._id,
          });
          SetContactListData([...ContactListData, ...NewArray]);
        }
      });
      SetRows(Result.data.PageData);
    }
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("searchcontact").value;
      SetSearch(SearchedVal);
      SetPage(1);
      document.getElementById("contactlist-hideloading").style.display =
        "block";
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      document.getElementById("contactlist-hideloading").style.display =
        "block";
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
    document.getElementById("contactlist-hideloading").style.display = "none";
  };

  // all check handler
  const CheckHandler = async (e) => {
    if (e.target.checked) {
      document.getElementById("AllCheck").checked = true;

      const updatedArrayOfObjects = await Promise.all(
        Rows.map(async (obj) => {
          obj.ContactList.ListID = ListID;
          return obj;
        })
      );

      SetContactListData(
        updatedArrayOfObjects.map((row) => ({
          ProspectListID: row.ContactList._id,
          ProspectID: row._id,
        }))
      );

      SetRows(updatedArrayOfObjects);
    } else if (!e.target.checked) {
      document.getElementById("AllCheck").checked = false;

      const updatedArrayOfObjects = await Promise.all(
        Rows.map(async (obj) => {
          obj.ContactList.ListID = null;
          return obj;
        })
      );

      SetUnContactListData(
        updatedArrayOfObjects.map((row) => ({
          ProspectListID: row.ContactList._id,
          ProspectID: row._id,
        }))
      );

      SetContactListData(
        ContactListData.filter((val) => val.ProspectID !== val._id)
      );

      SetRows(updatedArrayOfObjects);
    }
  };

  //Add list contact
  const CheckBoxAdd = async (e, ProspectListID, ID) => {
    if (e.target.checked) {
      // Add the checked item to ContactListData
      ContactListData.push({ ProspectListID: ProspectListID, ProspectID: ID });
  
      // Update the ListID property in the nested ContactList object for the checked item
      const updatedArrayOfObjects = await Promise.all(
        Rows.map(async (obj) => {
          if (obj._id === ID) {
            await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate asynchronous operation
            obj.ContactList.ListID = ListID;
          }
          return obj;
        })
      );
  
      // Update the state with the updated array of objects
      SetRows(updatedArrayOfObjects);
    } else if (!e.target.checked) {
      // Remove the unchecked item from ContactListData
      const newArray = ContactListData.filter((val) => val.ProspectID !== ID);
      SetContactListData(newArray);
  
      // Add the unchecked item to UnContactListData
      const uncheckedItem = {
        ProspectListID: ProspectListID,
        ProspectID: ID,
      };
      SetUnContactListData((prevArray) => [...prevArray, uncheckedItem]);
  
      // Update the ListID property in the nested ContactList object for the unchecked item
      const updatedArrayOfObjects = await Promise.all(
        Rows.map(async (obj) => {
          if (obj._id === ID) {
            await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate asynchronous operation
            obj.ContactList.ListID = null;
          }
          return obj;
        })
      );
  
      // Update the state with the updated array of objects
      SetRows(updatedArrayOfObjects);
    }
  };
  

  const HandelClose = () => {
    handClose;
  };

  //Add and Update list contact with multiple check
  const SaveListBtn = () => {
    if (ContactListData.length > 0) {
      var data = {
        CheckedArrayList: ContactListData,
        UnCheckedArrayList: UnContactListData,
        ListID: ListID,
        UserID: UserID,
        ClientID: ClientID,
        Role: Role,
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/list/ContactListUpdate",
        method: "POST",
        data: data,
      }).then((Res) => {
        if (Res.data.StatusMessage == "SUCCESS") {
          Swal.fire(
            "Contact List",
            "Selected Contact added successfully.",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              // The "OK" button was clicked, you can put your code here
              // window.location.reload();
              updateFromChild(true);
              // Add your custom logic here
            }
          });

          //  handClose
        } else {
          toast.error(Res.data.Message);
        }
      });
    } else {
      toast.error("Please select at least one contacts from list.");
    }
  };

  //get sort field data
  const SortData = (Field) => {
    document.getElementById("contactlist-hideloading").style.display = "block";
    var SearchedVal = document.getElementById("searchcontact").value;
    // SetPage(1);
    // SetRowsPerPage(10);
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        ListID: ListID,
        Role: Role,
      };

      const ContactListData = Axios({
        url: CommonConstants.MOL_APIURL + "/list/ContactList",
        method: "POST",
        data: InputParameter,
      });
      ContactListData.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        document.getElementById("contactlist-hideloading").style.display =
          "none";
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        ListID: ListID,
        Role: Role,
      };

      const ContactListData = Axios({
        url: CommonConstants.MOL_APIURL + "/list/ContactList",
        method: "POST",
        data: InputParameter,
      });
      ContactListData.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        document.getElementById("contactlist-hideloading").style.display =
          "none";
      });
    }
  };

  return (
    <div>
      <div id="contactlist-hideloading" className="loding-display">
        <img src={contactlistloadingicon} />
      </div>
      <div className="row pb-3">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="searchcontact"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <input
                    name="select_all_contacts"
                    type="checkbox"
                    checked={Rows.every(
                      (ele) => ele.ContactList?.ListID === ListID
                    )}
                    onChange={(e) => CheckHandler(e)}
                  />
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("FirstName");
                    }}
                  >
                    <b>Name</b>
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Title");
                    }}
                  >
                    <b>Title</b>
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Company");
                    }}
                  >
                    <b>Company</b>
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Email");
                    }}
                  >
                    <b>Email</b>
                  </a>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length == 0 ? (
                <p>No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow>
                    <TableCell>
                      <input
                        type="checkbox"
                        id="AllCheck"
                        onChange={(e) =>
                          CheckBoxAdd(e, row.ContactList?._id, row._id)
                        }
                        checked={
                          row.ContactList?.ListID === ListID ? true : false
                        }
                      />
                    </TableCell>
                    <TableCell>{row?.FirstName}</TableCell>
                    <TableCell>{row?.Title}</TableCell>
                    <TableCell>{row?.Company}</TableCell>
                    <TableCell>{row?.Email}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info py-3">
            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
            entries (filtered from {Flen} total entries)
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info py-3 mb-3">
            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
            entries
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}

      <div className="modal-footer px-0 mt-3 pt-3 pb-0">
        <a
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={handClose}
        >
          Cancel
        </a>
        <a
          type="button"
          id="popup-close"
          className="btn btn-primary btn-lightgreen"
          onClick={SaveListBtn}
        >
          <i className="la la-save text-white"></i> Save
        </a>
      </div>
    </div>
  );
}
