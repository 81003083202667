import React, { useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Pagination from "@material-ui/lab/Pagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import contactlistloadingicon from "../../../images/loading.gif";
import Swal from "sweetalert2";
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function Contactlist({ CCampaignID,UpdateChoiceChildToPreview,UpdateChildToPreviewCancle}) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Email");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID, SetCUserID] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [sortedColumn, setSortedColumn] = React.useState("EventCreatedDt");
  const [selectedRow, SetselectedRow] = React.useState(null);
  const [SelectedContactList, SetSelectedContactList] = React.useState([]);

  useEffect(() => {
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    ContactListGet(
      GetUserData.ClientID,
      GetUserData.ParentUserID,
      GetUserData.Role
    );
  }, [Search, Page, RowsPerPage, CCampaignID]);

  //get contact list
  const ContactListGet = async (CID, UID, URole) => {
    SetIsLoading(true);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      CampaignID: CCampaignID,
    };
    const GetConfigEmailAccount = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/campaignstep/PreviewCampignContactPreviewGet",
      method: "POST",
      data: InputParameter,
    });
    if (GetConfigEmailAccount.data.StatusMessage === "SUCCESS") {
      SetRows(GetConfigEmailAccount.data.PageData);
      SetRlen(GetConfigEmailAccount.data.TotalCount);
      SetFlen(GetConfigEmailAccount.data.TotalCount);
      SetCountPage(GetConfigEmailAccount.data.PageCount);
      // SetTableRowLength(GetConfigEmailAccount.data?.PageData?.length);
      SetIsLoading(false);
    } else {
      SetIsLoading(false);
    }
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("searchcontact").value;
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("searchcontact").value;
    SetIsLoading(true);
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        CampaignID: CCampaignID,
      };

      const ContactListData = Axios({
        url: CommonConstants.MOL_APIURL + "/campaignstep/PreviewCampignContactPreviewGet",
        method: "POST",
        data: InputParameter,
      });
      ContactListData.then((GetConfigEmailAccount) => {
        SetRows(GetConfigEmailAccount.data.PageData);
        SetRlen(GetConfigEmailAccount.data.TotalCount);
        SetFlen(GetConfigEmailAccount.data.TotalCount);
        SetCountPage(GetConfigEmailAccount.data.PageCount);
        // SetTableRowLength(GetConfigEmailAccount.data?.PageData?.length);
        SetIsLoading(false);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        CampaignID: CCampaignID,
      };

      const ContactListData = Axios({
        url: CommonConstants.MOL_APIURL + "/campaignstep/PreviewCampignContactPreviewGet",
        method: "POST",
        data: InputParameter,
      });
      ContactListData.then((GetConfigEmailAccount) => {
        SetRows(GetConfigEmailAccount.data.PageData);
        SetRlen(GetConfigEmailAccount.data.TotalCount);
        SetFlen(GetConfigEmailAccount.data.TotalCount);
        SetCountPage(GetConfigEmailAccount.data.PageCount);
        // SetTableRowLength(GetConfigEmailAccount.data?.PageData?.length);
        SetIsLoading(false);
      });
    }
  };

  const handleRowSelection = (index,ProspectChoiceList) => {
    SetselectedRow(selectedRow === index ? null : index);
    SetSelectedContactList(selectedRow===index?[]:[ProspectChoiceList])
  };

  const HandlePreviewChoiceList=()=>{
    UpdateChoiceChildToPreview(SelectedContactList)
  }

  const HandlePreviewChoiceListCancle=()=>{
    UpdateChildToPreviewCancle()
  }

  return (
    <>
      {IsLoading === true ? (
        <div id="hideloding23" className="loding-display">
          <img src={contactlistloadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="modal-body p-5">
        <div>
          <div className="row pb-3">
            <div className="col-sm-12 col-md-6 d-flex">
              <label className="textlabelte">
                Show
                <select
                  name="tbl_meeting_length"
                  onChange={ChangeRowSelected}
                  aria-controls="tbl_meeting"
                  className="form-control form-control-sm"
                  value={RowsPerPage}
                >
                  {CommonConstants.show_rows.map((value) => (
                    <option value={value}>{value}</option>
                  ))}
                </select>
                entries
              </label>
            </div>
            <div className="col-sm-12 col-md-6 full-right">
              <label className="textlabelte">
                Search:{" "}
                <input
                  type="search"
                  id="searchcontact"
                  onKeyPress={RequestSearch}
                  className="form-control form-control-sm ml-2"
                  placeholder=""
                  aria-controls="tbl_meeting"
                />
              </label>
            </div>
          </div>
          <div className="table-bordered">
            <TableContainer component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell
                      className="w-250"
                      onClick={() => {
                        SortData("Email");
                        setSortedColumn("Email");
                      }}
                    >
                      Email
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && sortedColumn === "Email"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && sortedColumn === "Email"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </TableCell>
                    <TableCell
                      className="w-250"
                      onClick={() => {
                        SortData("Name");
                        setSortedColumn("Name");
                      }}
                    >
                      Name
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && sortedColumn === "Name"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && sortedColumn === "Name"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Rows?.length == 0 ? (
                    <p>No data available in table</p>
                  ) : (
                    Rows?.map((row,index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <input
                            type="checkbox"
                            id="AllCheck"
                            checked={selectedRow === index}
                            onChange={() => handleRowSelection(index,row)}
                          />
                        </TableCell>
                        <TableCell>{row?.Email}</TableCell>
                        <TableCell>{row?.Name}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {Sflag ? (
            <div className="row">
              <Pagination
                component="div"
                count={CountPage}
                onChange={HandleChangePage}
                showFirstButton
                showLastButton
              />
              <div className="col dataTables_info py-3">
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                {Rlen} entries (filtered from {Flen} total entries)
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col dataTables_info py-3 mb-3">
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                {Rlen} entries
              </div>
              <div className="col pageright">
                <Pagination
                  component="div"
                  count={CountPage}
                  onChange={HandleChangePage}
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="modal-content text-right p-4">
        <div class="row">
          <div class="col-lg-12 pull-right">
            <button id="backtolist" class="btn btn-secondary mr-2" onClick={HandlePreviewChoiceListCancle}>
              Cancel
            </button>
            <button
              id="submitupdate"
              class="btn btn-primary btn-lightgreen mr-1"
              href="javascript:void(0);"
              onClick={HandlePreviewChoiceList}
            >
              <i class="la la-search"></i> Preview
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
