import React, { useEffect } from "react";
import { CSVReader, CSVDownloader } from "react-papaparse";
import Axios from "axios";
const moment = require("moment");

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LastdaysTabing from "../../user/Kpis/LastdaysTabing";

import RespondersTable from "../../../_components/user/LeaderBoard/RespondersTable";
import StrategistTable from "../../../_components/user/LeaderBoard/StrategistTable";
import ColdCallersTable from "../../../_components/user/LeaderBoard/ColdCallersTable";

import ImageUploading from "react-images-uploading";
import Popup from "reactjs-popup";

import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import ReactApexChart from "react-apexcharts";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";

charts(FusionCharts);
const dataSource = {
  chart: {
    caption: "Meeting Status",
    //subcaption: "For a net-worth of $1M",
    subCaption: " ",
    formatnumberscale: "0",
    showLegend: "1",
    showLabels: "0",
    showValues: "1",
    valuePosition: "inside",
    numbersuffix: "%",
    plothighlighteffect: "false",
    legendcaptionbold: "1",
    legendcaptionfontsize: "15",
    legendCaptionFontColor: "#000",
    showPercentValues: "0",
    showPercentInToolTip: "0",
    enableSlicing: "1",
    legendPosition: "bottom",
    legendCaption: "Meeting Status: ",
    legendBgColor: "#FFF",
    legendBorderColor: "#ffF",
    legendShadow: "0",
    legendItemFontSize: "14",
    legendWidth: "300",
  },
  data: [
    {
      label: "Set",
      value: "100000",
      color: "#360947",
    },
    {
      label: "Contract Sent",
      value: "50000",
      color: "#F8BF58",
    },
  ],
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs({ ToDate, FromDate, IsOldClientss }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [popupval, setpopup] = React.useState(false);
  const [FromDates, SetFromDates] = React.useState("");
  const [ToDates, SetToDates] = React.useState("");
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID, SetCUserID] = React.useState(0);

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    SetFromDates(FromDate);
    SetToDates(ToDate);
  }, [ToDate, FromDate, IsOldClientss]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };
  const openTermsConditionPopup = () => {
    setpopup(true);
  };

  const closeTermsConditionModal = () => {
    setpopup(false);
  };

  const SubmitTermsConditionModal = () => {
    setpopup(false);
  };

  document.addEventListener("mouseup", function (e) {
    if (popupval) {
      setpopup(false);
    }
  });

  return (
    <div className="tabdes mt-3">
      <div>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            aria-label="simple tabs example"
          >
            <Tab label="Responders" {...a11yProps(0)}>
              Overviewasdasd
            </Tab>
            <Tab label="Strategist" {...a11yProps(1)} />
            <Tab label="Sales Development Reps" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone whitebg" index={0}>
          <RespondersTable
            ToDatess={ToDate}
            FromDatess={FromDate}
            IsOldClientss={IsOldClientss}
          />
        </TabPanel>
        <TabPanel
          value={value}
          className="tagpaddnone inpt-0 whitebg"
          index={1}
        >
          <StrategistTable
            ToDatess={ToDate}
            FromDatess={FromDate}
            IsOldClientss={IsOldClientss}
          />
        </TabPanel>
        <TabPanel
          value={value}
          className="tagpaddnone inpt-0 whitebg"
          index={2}
        >
          <ColdCallersTable
            ToDatess={ToDate}
            FromDatess={FromDate}
            IsOldClientss={IsOldClientss}
          />
        </TabPanel>
      </div>
    </div>
  );
}
