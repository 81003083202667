import React, { useEffect } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import Paper from '@material-ui/core/Paper';
import Axios from 'axios';
import { CommonConstants } from '../../../_constants/common.constants';
import { history } from '../../../_helpers';
import Swal from "sweetalert2";
import { GetUserDetails } from "../../../_helpers/Utility";
const moment = require("moment");
import loadingicon from "../../../images/loading.gif";

export default function SendingSchedularTable(props) {
  const [data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("ScheduleStartTime");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role,SetRole] = React.useState("")
  const [CUserID,SetCUserID] = React.useState(0)

  // start Document ready Fuction
  useEffect(() => {
    document.title = "Sending Schedules | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role)
    }
    SendingSchedularlistGet(Details.ClientID, Details.ParentUserID,Details.Role);
  }, [Search, Page, RowsPerPage]);

//get sending schedular
   const SendingSchedularlistGet = (CId,UId,role) => {
    var str_in = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CId,
      UserID:UId,
      Role:role
    };

    const Rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/sendingschedular/SendingSchedularGet",
      method: "POST",
      data: str_in,
    });
    Rows1.then((result) => {
      
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      document.getElementById("hideloding").style.display = "none";

    });
  };

//delete Schedular
  // const DeleteBtn = async (id) => {
  //   const InputParameter = {
  //     SendingSchedularID:id,
  //     ClientID:ClientID,
  //     UserID:UserID,
  //     Role:Role
  //   }
  //   Axios({
  //     url: CommonConstants.MOL_APIURL +"/sendingschedular/CheckSendingSchedularAIDExists",
  //     method: "POST",
  //     data: InputParameter,
  //   }).then((res) => {
  
  //       if ((res.data.Status === "SUCCESS")) {

  //         if(res.data.Data != 0){
  //           DeleteBtn1(id)
  //         }else{
  //           Swal.fire({
  //             title: "Are you sure?",
  //             text: "you want to delete a Sending Schedules.",
  //             icon: "warning",
  //             showCancelButton: true,
  //             confirmButtonColor: "#34bfa3",
  //             cancelButtonText:"No, cancel!",
  //             cancelButtonColor: "#d33",
  //             confirmButtonText: "Yes, delete it!",
  //             reverseButtons: true,
  //           }).then((result) => {
  //             if (result.isConfirmed) {
  //               var data = {
  //                 SendingSchedularID: id,
  //                 IsDeleted: true,
  //                 NewSendingSchedularID:0,
  //                 LastUpdatedBy:CUserID,
  //                 LastUpdatedDate:new Date(),
  //                 ClientID:ClientID,
  //                 UserID:UserID,
  //                 Role:Role
  //               };
  //               Axios({
  //                 url:
  //                   CommonConstants.MOL_APIURL +
  //                   "/sendingschedular/SendingSchedularDelete",
  //                 method: "POST",
  //                 data: data,
  //               }).then((res) => {
  //                 if (res) {
  //                   if ((res.statusText = "Ok")) {
  //                     Swal.fire(
  //                       "Deleted!",
  //                       "Sending Schedules deleted successfully.",
  //                       "success"
  //                     );
  //                     SetSearch("")
  //                     SetPage(1)
  //                     document.getElementById("hideloding").style.display = "block";
  //                     document.getElementById("Search").value = ""
  //                     SendingSchedularlistGet(ClientID,UserID,Role);
  //                   } else {
  //                   }
  //                 }
  //               });
  //             } else if (result.dismiss === Swal.DismissReason.cancel) {
  //               Swal.fire("Cancelled", "Your data is safe :)", "error");
  //             }
  //           });
  //         }
  //       } else {
  //         //console.log(res)
  //       }
      
  //   });

    
  // };

  const DeleteBtn = async (id) => {
    document.getElementById("hideloding").style.display = "block";
    const InputParameter = {
      SendingSchedularID: id,
      ClientID: ClientID,
      UserID: UserID,
      Role: Role
    };
  
    try {
      const res = await Axios({
        url: CommonConstants.MOL_APIURL + "/sendingschedular/CheckSendingSchedularAIDExists",
        method: "POST",
        data: InputParameter,
      });
  
      if (res.data.StatusMessage === "SUCCESS") {
        if (res.data.Data !== 0) {
         await DeleteBtn1(id);
        } else {
         let result = await Swal.fire({
            title: "Are you sure?",
            text: "You want to delete Sending Schedules.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34bfa3",
            cancelButtonText: "No, cancel!",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            reverseButtons: true,
          });
          //console.log(result)
          if (result.isConfirmed) {
            const data = {
              SendingSchedularID: id,
              IsDeleted: true,
              NewSendingSchedularID: 0,
              LastUpdatedBy: CUserID,
              LastUpdatedDate: new Date(),
              ClientID: ClientID,
              UserID: UserID,
              Role: Role
            };
  
            try {
              const deleteRes = await Axios({
                url: CommonConstants.MOL_APIURL + "/sendingschedular/SendingSchedularDelete",
                method: "POST",
                data: data,
              });
  
              if (deleteRes.statusText === "OK") {
                Swal.fire(
                  "Deleted!",
                  "Sending Schedules deleted successfully.",
                  "success"
                );
                SetSearch("");
                SetPage(1);
                document.getElementById("hideloding").style.display = "block";
                document.getElementById("Search").value = "";
                SendingSchedularlistGet(ClientID, UserID, Role);
              }
            } catch (error) {
              console.error("Error deleting Sending Schedules:", error);
               document.getElementById("hideloding").style.display = "none";

            }
          } else if (result.isDismissed === Swal.DismissReason.cancel) {
            document.getElementById("hideloding").style.display = "none";
            Swal.fire("Cancelled", "Your data is safe :)", "error");

          }
        }
      document.getElementById("hideloding").style.display = "none";

      } else {
        console.error(res);
        document.getElementById("hideloding").style.display = "none";
      }
    } catch (error) {
      document.getElementById("hideloding").style.display = "none";
      console.error("Error checking Sending Schedular AID exists:", error);
    }
  };
  

    // delete btn
    // const DeleteBtn1= async (id) => {
    //   let DeleteDataDropdown = [...Rows]
    //   let Datas = DeleteDataDropdown.filter(obj => obj._id !== id);;
    //   var SendingSchedularOption =[]
    //   Datas.map((SendingSchedulars) => {
    //     SendingSchedularOption[SendingSchedulars._id] = `${SendingSchedulars?.ScheduleStartUTCTime!=null?moment(SendingSchedulars?.ScheduleStartUTCTime,'HH:mm').format('h:mm A'):("")} To ${row?.ScheduleEndUTCTime!=null?moment(row?.ScheduleEndUTCTime,'HH:mm').format('h:mm A'):("")}`;
    //   });
    //   Swal.fire({
    //     title: "Are you sure?",
    //     text: "you want to delete a Sending Schedules.",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#34bfa3",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Yes, delete it!",
    //     reverseButtons: true
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //      Swal.fire({
    //       title:"Replace Sending Schedules",
    //       // text:"Which value replace with '"+CSName+"' contact source?",
    //       confirmButtonColor: "#34bfa3",
    //       cancelButtonColor: "#34bfa3",
    //       confirmButtonText: "Save",
    //       cancelButtonText: "Cancel",
    //       reverseButtons: true,
    //       showCancelButton: true,
    //       input: "select",
    //       inputOptions: SendingSchedularOption,
    //       inputPlaceholder: SendingSchedularOption[0],
    //       position: 'top',
    //       showCloseButton: true,
    //       customClass: { 
    //         popup: 'replaceaccountpopup', 
    //         confirmButton: 'btn btn-primary btnColor',
    //       }
  
    //      }).then((Suecces)=>{
    //       if(Suecces.isConfirmed){
    //         var UpdateId = Suecces.value;
    //         var ContactSourceData = {
    //           _id: id,
    //           IsDeleted: true,
    //           ClientID:ClientID,
    //           UserID:UserID,
    //           LastUpdatedBy: CUserID,
    //           LastUpdatedDt: new Date(),
    //           Role: Role,
    //         };
    //         Axios({
    //           url: CommonConstants.MOL_APIURL + "/sendingschedular/SendingSchedularDelete",
    //           method: "POST",
    //           data: ContactSourceData,
    //         }).then(async (res) => {
    //           if(res.data.StatusMessage == "SUCCESS"){
    //                 Swal.fire(
    //                   "Deleted!",
    //                   "Sending Schedules deleted successfully.",
    //                   "success"
    //                 );
    //                 SetSearch("")
    //                 SetPage(1)
    //                 SetIsLoading(true)
    //                 document.getElementById("search").value = ""
    //           }
    //         });
    //       }else if (Suecces.dismiss === Swal.DismissReason.cancel) {
    //         Swal.fire("Cancelled", "Your data is safe :)", "error");
    //       }
  
    //      }) 
    //     } else if (result.dismiss === Swal.DismissReason.cancel) {
    //       Swal.fire("Cancelled", "Your data is safe :)", "error");
    //     }
    //   });
    // };

    const DeleteBtn1 = async (id) => {
      document.getElementById("hideloding").style.display = "block";
      let DeleteDataDropdown = [...Rows];
      let Datas = DeleteDataDropdown.filter(obj => obj._id !== id);
      var SendingSchedularOption = {}
      
      Datas.map((SendingSchedulars) => {
        SendingSchedularOption[SendingSchedulars._id] = `${SendingSchedulars?.ScheduleStartUTCTime != null ? moment(SendingSchedulars?.ScheduleStartUTCTime, 'HH:mm').format('h:mm A') : ""} To ${SendingSchedulars?.ScheduleEndUTCTime != null ? moment(SendingSchedulars?.ScheduleEndUTCTime, 'HH:mm').format('h:mm A') : ""}`;
      });
    
      Swal.fire({
        title: "Are you sure?",
        text: "you want to delete a Sending Schedules.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Replace Sending Schedules",
            confirmButtonColor: "#34bfa3",
            cancelButtonColor: "#34bfa3",
            confirmButtonText: "Save",
            cancelButtonText: "Cancel",
            reverseButtons: true,
            showCancelButton: true,
            input: "select",
            inputOptions: SendingSchedularOption,
            inputPlaceholder: SendingSchedularOption[0],
            position: 'top',
            showCloseButton: true,
            customClass: {
              popup: 'replaceaccountpopup',
              confirmButton: 'btn btn-primary btnColor',
            }
          }).then((Success) => {
            //console.log(Success)
            if (Success.isConfirmed) {
              var UpdateId = Success.value;
              var ContactSourceData = {
                SendingSchedularID: id,
                NewSendingSchedularID: (Success.value !== undefined && Success.value?.trim() !== "") ? Success.value : SendingSchedularOption[0]?._id,
                IsDeleted: true,
                ClientID: ClientID,
                UserID: UserID,
                LastUpdatedBy: CUserID,
                LastUpdatedDate: new Date(),
                Role: Role,
              };
              Axios({
                url: CommonConstants.MOL_APIURL + "/sendingschedular/SendingSchedularDelete",
                method: "POST",
                data: ContactSourceData,
              }).then(async (res) => {
                if (res.data.StatusMessage == "SUCCESS") {
                  Swal.fire(
                    "Deleted!",
                    "Sending Schedules deleted successfully.",
                    "success"
                  );
                  SetSearch("");
                SetPage(1);
                document.getElementById("hideloding").style.display = "block";
                document.getElementById("Search").value = "";
                SendingSchedularlistGet(ClientID, UserID, Role);
                }
              });
            } else if (Success.dismiss === Swal.DismissReason.cancel) {
              Swal.fire("Cancelled", "Your data is safe :)", "error");
              document.getElementById("hideloding").style.display = "none";

            }
          })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          document.getElementById("hideloding").style.display = "none";
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
        document.getElementById("hideloding").style.display = "none";
      });
    };



//serach schedular list
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      var SearchedVal = document.getElementById("Search").value;
      if(SearchedVal==Search){
        SetSearch(SearchedVal);
        SetPage(1);
      }else{
        SetSearch(SearchedVal);
        SetPage(1);
        document.getElementById("hideloding").style.display = "block";
      }
    }
  };

//change row
  const ChangeRowSelected = (event) => {
    SetRowsPerPage(Number(event.target.value));
    SetPage(1);
    SendingSchedularlistGet(ClientID,UserID,Role);
    document.getElementById("hideloding").style.display = "none";

  };

//change Page
  const HandleChangePage = (event, newPage) => {
    if(newPage==Page){
      SetPage(newPage);
    }else{
        SetPage(newPage);
       document.getElementById("hideloding").style.display = "block";
    }

  };

      //get sort field data
const SortData = (Field) => {
  var SearchedVal = document.getElementById("Search").value;
  var SerchBox;
  if (SearchedVal == "") {
    SerchBox = false;
    SetSflag(false);
    var SortField = Field;
    var SortBy;
    if (SortedBy == 1) {
      SortBy = -1;
      SetSortedBy(-1);
    } else {
      SortBy = 1;
      SetSortedBy(1);
    }
    SetSortField(Field);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Search: SearchedVal,
      SerchBox: false,
      Archive: false,
      Sort: true,
      Field: SortField,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role:Role

    };

    const AccountCategoriesList = Axios({
      url:CommonConstants.MOL_APIURL +"/sendingschedular/SendingSchedularGet",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
  
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
    });
  } else {
    SerchBox = true;
    SetSflag(true);
    var SortField = Field;
    var SortBy;
    if (SortedBy == 1) {
      SortBy = -1;
      SetSortedBy(-1);
    } else {
      SortBy = 1;
      SetSortedBy(1);
    }
    SetSortField(Field);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Search: SearchedVal,
      SerchBox: SerchBox,
      Archive: false,
      Sort: true,
      Field: SortField,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role:Role

    };
  
    const AccountCategoriesList = Axios({
      url:CommonConstants.MOL_APIURL +"/sendingschedular/SendingSchedularGet",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
    });
  }
}

//edit
  const EditSendingSchedular = (id) => {
    history.push({pathname:"/CEditSendingSchedular", state:{ data: id }});
  };

  return (
    <div>
          <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_sendingschedular_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_sendingschedular"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="Search"
              id="Search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ScheduleStartTime");
                    }}
                  >
                    Schedule Start Time
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("ScheduleEndTime");
                    }}
                  >
                    Schedule End Time
                  </a>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ): (
                Rows?.map((row) => (
                <TableRow>

                  <TableCell>{row?.ScheduleStartTime!=null?moment(row?.ScheduleStartTime,'HH:mm').format('h:mm A'):("")}</TableCell>
                  <TableCell>{row?.ScheduleEndTime!=null?moment(row?.ScheduleEndTime,'HH:mm').format('h:mm A'):("")}</TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        EditSendingSchedular(row._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i class="la flaticon-edit-1 edit-icon"></i>
                    </a>
                    {
                      Rows?.length === 1 ? <></> : <a
                      onClick={() => {
                        DeleteBtn(row._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i class="la flaticon-delete-1 delete-icon"></i>
                    </a>
                    }
                    
                  </TableCell>
                </TableRow>
              ))
              )}

            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
  );
}