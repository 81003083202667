import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from "../../_components/user/footer/footer";
import ResearchTeams from '../../_components/user/ResearchTeams/ResearchTeamsTable.js';
//import CollapsibleTable from '../_components/usertable';

import { history } from "../../_helpers";


class ResearchTeamsPage extends React.Component {
   
    AddBtn(){
        history.push('/addResearchTeam');
    }

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitle float-left py-4 pl-0">Research Teams</h4>
                            <div className="listing-li float-right padb-15 pr-3">
                                <ul> 
                                    <li>
                                        <a onClick={()=>{this.AddBtn()}} className="btn btngroup m-btn">
                                            <i class="la la-icon-font-size-13 la-plus"></i>
                                            <span>Add</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> 
                    
                    <div class="paddcols py-4"> 
                        <div className="row">
                            <div className="col">
                                    <ResearchTeams />
                            </div>
                        </div>      
                    </div>

                </div>  
                </div>
            <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedResearchTeamsPage = connect(mapState, actionCreators)(ResearchTeamsPage);
export { connectedResearchTeamsPage as ResearchTeamsPage };