import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { Link } from "react-router-dom";
import { CSVReader, CSVDownloader } from "react-papaparse";

import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { GetClientDetails } from "../../../_helpers/Utility";
const Fileupload = require("../../../_helpers/fileupload");
import { CommonConstants } from "../../../_constants/common.constants";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import MeetingCustomField from "../../../_components/user/MeetingCustomField/MeetingCustomField";

class MeetingCustomFieldPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Pager: {},
      PageOfItems: [],
      Files: [],
      List: [],
      CName: "",
      Columns: [],
      ReSet: false,
      CsvData: [],
      DropBoxData: [],
      FileName: "",
      ExportData: [],
    };
    this.HandleOnDrop = this.HandleOnDrop.bind(this);
    this.HandleOnError = this.HandleOnError.bind(this);
    this.HandleOnRemoveFile = this.HandleOnRemoveFile.bind(this);
    this.Importcsv = this.Importcsv.bind(this);
    this.MapCsv = this.MapCsv.bind(this);
    this.CancleCsv = this.CancleCsv.bind(this);
    this.SaveCsv = this.SaveCsv.bind(this);
    this.Addac = this.Addac.bind(this);
    this.ExportCsv = this.ExportCsv.bind(this);
  }

  componentDidMount() {
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
    GetClientDetails(Details.ClientID).then((result) => {
      this.setState({ CName: result[0].Name });
    });
    var str_in = {
        ClientID: this.state.ClientID,
        Role:this.state.Role
      };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/meetingcustom/MeetingCustomExport",
      method: "POST",
      data: str_in,
    }).then((res) => {
      this.setState({ ExportData: res.data });
    });
  }

  componentDidUpdate() {
    //this.loadPage();
  }

//Reset csv
  ReSetCsv() {
    this.setState({ ReSet: false });
  }

//Handle Drop
  HandleOnDrop(data, fileInfo) {
    var FileName = fileInfo.name;
    this.setState({ Files: fileInfo });
    var sep = FileName.split(".");
    if (sep[sep.length-1]!= "csv") {
      this.setState({ ReSet: true });
      this.ReSetCsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      this.setState({ FileName: csvfile });
      this.setState({ CsvData: data });
      this.setState({ DropBoxData: data[0].data });
    }
  }

//handle
  HandleOnError(err, file, inputElem, reason) {
    
  }

//handle
  HandleOnRemoveFile(data) {
  
  }

//Import csv
  Importcsv() {
    document.getElementById("import_csv").style.display = "block";
  }

//Map csv
  MapCsv() {
    if (this.state.CsvData.length != 0) {
     
      document.getElementById("map_attribute").style.display = "block";
      
      this.state.DropBoxData.map((dddd, index) => {
        
      });
    } else {
     
    }
  }

//cancle Csv
  CancleCsv() {
    this.ReSetCsv();
    document.getElementById("import_csv").style.display = "none";
  }

//Save Csv
  SaveCsv() {
    var AccountCategory = document.getElementById("AccountCategory").value;
    var EmailDomain = document.getElementById("EmailDomain").value;
    var add_arr = [];
    this.state.CsvData.map((Filedata) => {
      if (Filedata.data.length > 3) {
        add_arr.push(Filedata.data[AccountCategory]);
      }
    });
    add_arr.map((arr) => {
      var data = {
        Name: arr,
        IsDeleted: false,
        ClientID: null,
        UserID: this.state.UserID,
        FileName: this.state.FileName,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
        LastUpdatedBy: null,
        LastUpdatedDate: null,
        Role:this.state.Role
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/account_categories/AddAccountCategories",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res) {
          //register_u.style.display = "block";
          //window.location = CommonConstants.home+"accountcategory";
          Fileupload.getBase64(this.state.Files, (result) => {
            var rs = result;
            var splirs = rs.split("base64,");
            var _base64 = splirs[1];
            var myresult = {
              fields: "Coverimg",
              path: "./Content/UploadedFiles/UserAccountCategories",
              file: _base64,
              imageName: this.state.FileName,
            };
            Axios({
              url: CommonConstants.MOL_APIURL + "/newdocumentUpload/save",
              method: "POST",
              data: myresult,
            }).then((res) => {});
          });
          //window.location.reload();
        }
      });
    });
  }

    //Reload page while delete perform
    UpdateFromChild = (value) => {
      if(value == true){
        this.componentDidMount()
      }
    }

  //Add
  Addac() {
    history.push("/addmeetingcustomfield");
  }

  //Export
  ExportCsv() {}

  render() {
    const { Pager, PageOfItems } = this.state;
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row d-flex align-item-center">
              <div className="col">
                <h4 className="headertitle float-left py-4 pl-2">Custom Meeting Field</h4>
              </div>
                <div className="col py-1"> 
                  <div className="listing-li float-right padb-15 pr-2">
                    <ul>
                      <li>
                      <CSVDownloader className="px-0"
                              data={this.state.ExportData}
                              filename={this.state.CName + " Meeting-Custom-Field"}
                              bom={true}
                            >
                        <a
                          onClick={this.ExportCsv}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-download"></i>
                          <span>
                            
                              Export
                            
                          </span>
                        </a>
                        </CSVDownloader>
                      </li>
                      <li>
                        <a
                          onClick={this.Addac}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-plus"></i>
                          <span>Add</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>

            <div class="paddcols">
              <div className="row">
                <div className="col">
                  <MeetingCustomField updateFromChild={this.UpdateFromChild}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedMeetingCustomFieldPage = connect(
  mapState,
  actionCreators
)(MeetingCustomFieldPage);
export { connectedMeetingCustomFieldPage as MeetingCustomFieldPage };
