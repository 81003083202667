import React from "react";
import Axios from "axios";

import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import "react-toastify/dist/ReactToastify.css";

import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";

import { toast } from "react-toastify";
toast.configure();

class CAddMeetingOwnerPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      MeetingEmailAvailable: null,
      ClientID: null,
      UserID: null,
      CUserID: null,
      Role: null,
      IsExist: false,
      IsValid: true,
      IsButtonDisabled: false,
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
  }
  componentDidMount() {
    document.title = `Add Meeting Owner | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
  }

  // form validation
  async FromValidation() {
    let FormIsValid = true;
    let Fields = this.state.Fields;
    let Errors = {};
    var Name = document.getElementById("Name").value.trim();
    var Email = document.getElementById("Email").value.trim();
    let IsExist = await this.MeetingOwnersCheckExist(Email);
    if (Name == "") {
      FormIsValid = false;
      Errors["Name"] = "Please enter first name";
    }

    if (IsExist == "InvalidEmail") {
      FormIsValid = false;
      Errors["Email"] = "invalid email.";
    }
    if (IsExist == "ExistEmail") {
      FormIsValid = false;
      Errors["Email"] = "Email Already Exist";
    }

    if (IsExist == "BlankData" && Email == "") {
      FormIsValid = false;
      Errors["Email"] = "Please enter email";
    }

    this.setState({ Errors: Errors });

    return FormIsValid;
  }

  // form validation
  async HandleChangeBlur(NameEmail) {
    let Errors = {...this.state.Errors};
    var Name = document.getElementById("Name").value.trim();
    var Email = document.getElementById("Email").value.trim();

    if(NameEmail=="EmailDetails"){
      let IsExist = await this.MeetingOwnersCheckExist(Email);
      if (IsExist == "InvalidEmail") {
        Errors["Email"] = "invalid email.";
      }
      if (IsExist == "ExistEmail") {
        Errors["Email"] = "Email Already Exist";
      }
  
      if (IsExist == "BlankData" && Email == "") {
        Errors["Email"] = "Please enter email";
      }

      if (IsExist == "AllDone") {
        Errors["Email"] = null
      }
    }

    if(NameEmail=="NameDetails"){
      if (Name == "") {
        Errors["Name"] = "Please enter first name";
      }else{
        Errors["Name"] = null
      }
    }
    
   
    this.setState({ Errors: Errors });
  }

  // handle change
  HandleChange(Field, e) {
    let Fields = this.state.Fields;
    Fields[Field] = e.target.value;
    this.setState({ Fields });
    var Email = document.getElementById("Email").value.trim();
    if (Email != "") {
      this.state.Errors.Email = null;
      this.state.MeetingEmailAvailable = null;
      this.MeetingOwnersCheckExist(Email);
    } else {
      this.state.MeetingEmailAvailable = null;
      this.state.Errors.Email = null;
    }
  }

  // find exits meeting owner Email
  async MeetingOwnersCheckExist(Email) {
    let regexp =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let Errors = {};
    if (Email != "") {
      if (!regexp.test(Email)) {
        return "InvalidEmail";
      } else {
        var MeetingSource = {
          ClientID: this.state.ClientID,
          Email: Email,
          Role: this.state.Role,
        };
        var resdata = await Axios({
          url: CommonConstants.MOL_APIURL + "/meetingowner/MeetingOwnerExists",
          method: "POST",
          data: MeetingSource,
        });
        if (resdata.data.StatusMessage == "SUCCESS") {
          if (resdata.data.Data.length > 0) {
            this.setState({ MeetingEmailAvailable: resdata.data.Data[0].Name });
            return "ExistEmail";
          } else {
            return "AllDone";
          }
        }
      }
    } else {
      return "BlankData";
    }
  }

  // back to button
  BackBtn() {
    history.push("/CMeetingowner");
  }

  // meeting owner add
  async SaveBtn(e) {
    this.setState({ IsButtonDisabled: true });
    var Final_flag = await this.FromValidation();

    if (Final_flag == true) {
      var Name = document.getElementById("Name").value;
      var lastName = document.getElementById("LastName").value;
      var email = document.getElementById("Email").value;
      var Conference = document.getElementById("Conference").value;
      var MeetingPlatformName = document.getElementById("MeetingPlatformName").value;

      var MeetingOwnerAdd = {
        ClientID: this.state.ClientID,
        Name: Name,
        IsDeleted: false,
        CreatedDt: new Date(),
        CreatedBy: this.state.CUserID,
        Email: email,
        ConferenceLineDialIn: Conference,
        IsOmitMeetingOwnerSentence: false,
        FirstName: Name,
        LastName: lastName,
        UserID: this.state.UserID,
        Role: this.state.Role,
        MeetingPlatformName:MeetingPlatformName
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/meetingowner/MeetingOwnerAdd",
        method: "POST",
        data: MeetingOwnerAdd,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div>Meeting Owner added successfully.</div>);
          history.push("/CMeetingowner");
        } else {
          toast.error(res.data.Message);
          this.setState({ IsButtonDisabled: false });
        }
      });
    } else {
      this.setState({ IsButtonDisabled: false });
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 

          <div className="bodyhome">

            <div className="row">
              <div className="col">
                <h4 className="headertitlepad float-left">
                  Add Meeting Owner
                </h4>
              </div>
            </div>

            <div class="paddcols">
              <div className="row py-3">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">First Name</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="Name"
                        onBlur={() =>
                          this.HandleChangeBlur("NameDetails")}
                        name="Name"
                        placeholder="Enter first name"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Last Name</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="LastName"
                        name="LastName"
                        placeholder="Enter last name"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Email</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first Email"
                        id="Email"
                        onBlur={() =>
                          this.HandleChangeBlur("EmailDetails")}
                        name="Email"
                        placeholder="Enter email"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Email"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Conference Line Dial In
                    </label>
                    <div class="col-lg-8">
                      <textarea
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first Conference"
                        id="Conference"
                        name="Conference"
                        placeholder="Enter conference line dial in"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                    Meeting Platform Name 
                    </label>
                    <div class="col-lg-8">
                    <input
                        class="form-control m-input"
                        data-val="true"
                        // data-val-required="Please enter first MeetingPlatformName"
                        id="MeetingPlatformName"
                        onBlur={() =>
                          this.HandleChangeBlur("MeetingPlatformNameDetails")}
                        name="MeetingPlatformName"
                        placeholder="Enter Meeting Platform Name"
                        type="text"
                      />
                    
                      <span style={{ color: "red" }}>
                        {this.state.Errors["MeetingPlatformName"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-3 mt-3">
              <div class="col-lg-12 pull-left">
                <button
                  id="submit"
                  onClick={this.SaveBtn}
                  disabled={this.state.IsButtonDisabled}
                  class="btn btn-primary btn-lightgreen mr-1"
                >
                  <i class="la la-save"></i> Save
                </button>
                <button
                  id="backtolist"
                  onClick={this.BackBtn}
                  class="btn btn-secondary"
                >
                  <i class="la la-arrow-circle-left"></i> Back
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCAddMeetingOwnerPage = connect(
  mapState,
  actionCreators
)(CAddMeetingOwnerPage);
export { connectedCAddMeetingOwnerPage as CAddMeetingOwnerPage };
