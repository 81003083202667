import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from "../../_components/user/footer/footer";
import MeetingSlackNotification from '../../_components/user/MeetingSlackNotification/MeetingSlackNotificationTabing';


class MeetingSlackNotificationPage extends React.Component {
    render() {
        return (
            <>
                <div className="adminmain mheight">
                    <Sidebar className="" />
                    <div className="bodyhome">
                        <div className="row">
                            <div className="col padd-0 ">
                                <h4 className="headertitlebd">Slack Notification</h4>
                            </div>
                        </div>
                        <span className="devider"></span>
                        <div className="col padd-0">
                            <MeetingSlackNotification />
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedMeetingSlackNotificationPage = connect(mapState, actionCreators)(MeetingSlackNotificationPage);
export { connectedMeetingSlackNotificationPage as MeetingSlackNotificationPage };