import React, { useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
const moment = require("moment");

import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Popup from "reactjs-popup";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable(props) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortValue, SetSortValue] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsEdit, SetIsEdit] = React.useState(false);
  const [EditData, SetEditData] = React.useState("");
  const [trigger, setTrigger] = React.useState(false);

  const [SmartVariableDetails, SetSmartVariableDetails] = React.useState([]);
  const [IDs, SetIDs] = React.useState([]);
  const [ IsLoading,SetIsLoading] = React.useState(false)
  const [CUserID,SetCUserID] = React.useState(0)
  const [sortedColumn, setSortedColumn] = React.useState(null);

  useEffect(() => {
    var ResearchPageUserInfo = GetUserDetails();
    if (ResearchPageUserInfo != null) {
      SetClientID(ResearchPageUserInfo.ClientID);
      SetUserID(ResearchPageUserInfo.ParentUserID);
      SetCUserID(ResearchPageUserInfo.ChildUserID);
      SetRole(ResearchPageUserInfo.Role);
    }
    GetSmartVariable(ResearchPageUserInfo.ClientID,ResearchPageUserInfo.ParentUserID,ResearchPageUserInfo.Role);
    // GetSmartVariableDetails();
  }, [ClientID, Role, Search, Page, RowsPerPage,trigger]);

  //get smart variable  list
  const GetSmartVariable = (CID,UID,Roles) => {
    SetIsLoading(true)
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortValue,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: Roles,
      CommanField: 1,
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableGet2",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)

    });
  };

  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true)

    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var Sort = SortValue;
      if (Sort == 1) {
        Sort = -1;
        SetSortValue(-1);
      } else {
        Sort = 1;
        SetSortValue(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortValue,
      Search: Search,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
      CommanField: 1,
      };
      const ResearchGet = Axios({
        url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableGet2",
        method: "POST",
        data: InputParameter,
      });
      ResearchGet.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.totalcount);
        SetFlen(Result.data.totalcount);
        SetCountPage(Result.data.Pagecount);
            SetIsLoading(false)

      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var Sorted;
      if (Sorted == 1) {
        Sorted = -1;
        SetSortValue(-1);
      } else {
        Sorted = 1;
        SetSortValue(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortValue,
      Search: Search,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
      CommanField: 1,
      };
      const ResearchGet = Axios({
        url: CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableGet2",
        method: "POST",
        data: InputParameter,
      });
      ResearchGet.then((Result) => {
        SetRows(Result.data.Pagedata);
        SetRlen(Result.data.totalcount);
        SetFlen(Result.data.totalcount);
        SetCountPage(Result.data.Pagecount);
            SetIsLoading(false)

      });
    }
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {

      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };
  const UpdataChildComponent = (value) => {
    document.getElementById("hideloding").style.display = "block";
    if(value == true){
      setTrigger(value)

    }
  }
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  const EditBtn = (ID) => {
    props.EditID(ID);
  };

  //delete
  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a BiggerThan - State .",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonColor: "#34bfa3",
      cancelButtonText: "No, cancel!",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var InputData = {
          ID: ID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL + "/smart_variable/SmartVariableDelete",
          method: "POST",
          data: InputData,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "BiggerThan - State deleted successfully.",
                "success"
              );
              // document.getElementById("hideloding").style.display = "block";

              GetSmartVariable(ClientID,UserID,Role);
              props.updata(true)
              // UpdataChildComponent(true)
              // document.getElementById("hideloding").style.display = "none";

            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  return (
    <>
    {
      IsLoading ? <div id="hideloding" className="loding-display">
      <img src={loadingicon} />
    </div> : <></>
    }
      
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <TableContainer className="my-4" component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell onClick={() => {
                  SortData("FeatureValue");
                  setSortedColumn("FeatureValue"); // Set the sorted column
                }}>
                {/* <a
                  onClick={() => {
                    SortData("FeatureValue");
                  }}
                > */}
                  BiggerThan - State
                  <span className="shorting">
                         <ArrowUpward className={SortValue === 1 && sortedColumn === "FeatureValue" ? "active" : null} />
                          <ArrowDownward  className={SortValue === -1 && sortedColumn === "FeatureValue"  ? "active" : null} />
                          </span>
                {/* </a> */}
              </TableCell>
              <TableCell onClick={() => {
                  SortData("City");
                  setSortedColumn("City"); // Set the sorted column
                }}>
                {/* <a
                  onClick={() => {
                    SortData("City");
                  }}
                > */}
                  City
                  <span className="shorting">
                         <ArrowUpward className={SortValue === 1 && sortedColumn === "City" ? "active" : null} />
                          <ArrowDownward  className={SortValue === -1 && sortedColumn === "City"  ? "active" : null} />
                          </span>
                {/* </a> */}
              </TableCell>
              <TableCell onClick={() => {
                  SortData("State");
                  setSortedColumn("State"); // Set the sorted column
                }}>
                {/* <a
                  onClick={() => {
                    SortData("State");
                  }}
                > */}
                  State
                  <span className="shorting">
                         <ArrowUpward className={SortValue === 1 && sortedColumn === "State" ? "active" : null} />
                          <ArrowDownward  className={SortValue === -1 && sortedColumn === "State"  ? "active" : null} />
                          </span>
                {/* </a> */}
              </TableCell>
              <TableCell>Sent</TableCell>
              <TableCell>Tracked</TableCell>
              <TableCell>Replied</TableCell>
              <TableCell>Interested</TableCell>
              <TableCell>Open %</TableCell>
              <TableCell>Reply %</TableCell>
              <TableCell>Interested %</TableCell>
              <TableCell onClick={() => {
                  SortData("CreatedDate");
                  setSortedColumn("CreatedDate"); // Set the sorted column
                }}>
                {/* <a
                  onClick={() => {
                    SortData("CreatedDt");
                  }}
                > */}
                  Created Date
                  <span className="shorting">
                         <ArrowUpward className={SortValue === 1 && sortedColumn === "CreatedDate" ? "active" : null} />
                          <ArrowDownward  className={SortValue === -1 && sortedColumn === "CreatedDate"  ? "active" : null} />
                          </span>
                {/* </a> */}
              </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Rows?.length == 0 ? (
              <p className="text-center">No data available in table</p>
            ) : (
              Rows?.map((row) => (
                <TableRow>
                  <TableCell>{row.FeatureValue}</TableCell>
                  <TableCell>{row.City}</TableCell>
                  <TableCell>{row.State}</TableCell>
                  <TableCell>{row.Sent == undefined ? 0 : row.Sent}</TableCell>
                  <TableCell>
                    {row.Tracked == undefined ? 0 : row.Tracked}
                  </TableCell>
                  <TableCell>
                    {row.Replied == undefined ? 0 : row.Replied}
                  </TableCell>
                  <TableCell>
                    {row.TotalInterested == undefined ? 0 : row.TotalInterested}
                  </TableCell>
                  <TableCell>{row.OpenPercent == undefined  ? 0 : row.OpenPercent}%</TableCell>
                  <TableCell>{row.ReplyPercent == undefined  ? 0 : row.ReplyPercent}%</TableCell>
                  <TableCell>{row.InterestedPercent  == undefined  ? 0 : row.InterestedPercent}%</TableCell>
                  <TableCell>
                    {moment(new Date(row.CreatedDate).toDateString()).format(
                      "MM/DD/YYYY"
                    )}
                  </TableCell>

                  <TableCell>
                    <a title="Edit"
                      onClick={() => {
                        EditBtn(row._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i className="la flaticon-edit-1 edit-icon"></i>
                    </a>
                    <a title="Delete"
                      onClick={() => {
                        DeleteBtn(row._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i className="la flaticon-delete-1 delete-icon"></i>
                    </a>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {Sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
