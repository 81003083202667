import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import {
    IconButton,
    Collapse,
    Box,
    Paper,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    
  } from '@material-ui/core';
  import moment from 'moment';
  import Pagination from "@material-ui/lab/Pagination";
  import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { toast } from 'react-toastify';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

const SortingIcons = ({SortedBy,sortedColumn,ColumnName}) => {
  return (
    <span className="shorting">
           <ArrowUpward className={SortedBy === 1 && sortedColumn === ColumnName? "active": null}/>
           <ArrowDownward className={SortedBy === -1 && sortedColumn === ColumnName? "active": null}/>
    </span>
  )
}

function CallHistory({UserID,ClientID,Role,AccountID,Domain}) {

    const [Data, setData] = useState([]);
    const [Rows, setRows] = useState([]);
    const [RLen, setRLen] = useState(0);
    const [FLen, setFLen] = useState(0);
    const [CountPage, setCountPage] = useState(0);
    const [Page, setPage] = useState(1);
    const [RowsPerPage, setRowsPerPage] = useState(100);
    const [Search, setSearch] = useState("");
    const [SortField, setSortField] = useState("RecordingFileCreatedDate");
    const [SortedBy, setSortedBy] = useState(-1);
    const [Sflag, setSflag] = useState(false);
    const [sortedColumn, setSortedColumn] = React.useState("RecordingFileCreatedDate");

    useEffect(() => {
        FetchingMethod(Domain,Search, Page, RowsPerPage);
      }, [Search, Page, RowsPerPage, SortField, SortedBy,Domain,AccountID,ClientID]);

      // Lists get all
 const FetchingMethod = (DomainList, Search, Page, RowPage) => {
    if(Domain?.trim() != "" && AccountID?.trim() != "" && ClientID?.trim() != "" && UserID?.trim() !== ""){
        const RequestData = {
        Page: parseInt(Page),
        ClientID: ClientID,
        Domain: DomainList,
        RowsPerPage: parseInt(RowPage),
        Sort: true,
        Field: SortField,
        Role: Role,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
        SalesReplyAccountID:AccountID
        };
        Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/CallHistoryGet",
        method: "POST",
        data: RequestData,
        }).then((Response) => {
          if(Response.data.StatusMessage === "SUCCESS"){
            setData(Response.data?.PageData);
            setRows(Response.data?.PageData);
            setRLen(Response.data?.TotalCount);
            setFLen(Response.data?.TotalCount);
            setCountPage(Response.data?.PageCount);
          }else{
              toast.error(Response.data.Message)
          } 
            
        });
    }
  }

  //Sorting Contact list
 const SortingMethod = (FieldName) => {
  setSortField(FieldName)
  setSortedColumn(FieldName);
  const isNewSortField = FieldName !== SortField;
  let SortBy = SortedBy === 1 ? -1 : 1;

  if (isNewSortField) {
    setSortedBy(1);
    SortBy = 1;
  } else {
    setSortedBy(SortBy);
  }

  setSortField(FieldName);
 }

  // search for record
 const RequestSearch = (Event) => {
    console.log(Event)
    if (Event.key === "Enter") {
        var SearchedVal = document.getElementById("CallHistorySearch").value;
        console.log(SearchedVal)
        setSearch(SearchedVal)
      }
  };

  // change display rows
 const ChangeRowSelected = (Event) => {
    setRowsPerPage(Event.target.value)
    setPage(1)
  };

  // change page
const HandleChangePage = (Event, NewPage) => {
    if (NewPage != Page) {
    setPage(NewPage)
    }
  };



  return (
    <>
         <div className="row pt-3 pb-0">
              <div className="col d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">
                  Call History
                </h3>
              </div>
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={(event) => ChangeRowSelected(event)}
                    value={RowsPerPage}
                  >
                    {CommonConstants.show_rows.map((value) => (<option value={value} key={value}>{value}</option>))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="CallHistorySearch"
                    onKeyPress={(event) => RequestSearch(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell onClick={()=>{SortingMethod("Name")}}>Name <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Name"} /></TableCell>
                      <TableCell onClick={()=>{SortingMethod("Title")}}>Title <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Title"} /></TableCell>
                      <TableCell onClick={()=>{SortingMethod("Company")}}>Company <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Company"} /></TableCell>
                      <TableCell onClick={()=>{SortingMethod("Duration")}}>Duration <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Duration"} /></TableCell>
                      <TableCell onClick={()=>{SortingMethod("Status")}}>Result <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Status"} /></TableCell>
                      <TableCell onClick={()=>{SortingMethod("RecordingFileCreatedDate")}}>Call Time/Date <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"RecordingFileCreatedDate"} /></TableCell>
                      <TableCell onClick={()=>{SortingMethod("RecordingFileName")}}>Recording <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"RecordingFileName"} /></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Rows?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      Rows?.map((row) => (
                        <TableRow key={row?._id}>
                          <TableCell>{row.Name}</TableCell>
                          <TableCell>{row.Title}</TableCell>
                          <TableCell>{row.Company}</TableCell>
                          <TableCell>{row.Duration}</TableCell>
                          <TableCell>{row.Status}</TableCell>
                          <TableCell>{row.RecordingFileCreatedDate == null ? "" : moment( new Date(row.RecordingFileCreatedDate).toDateString()).format("MM/DD/YYYY HH:mm A")}</TableCell>
                          {/* <TableCell>{row.RecordingFileName}</TableCell> */}
                          {console.log(CommonConstants.CallRecoingURL + row.RecordingFileName+".mp3")}
                          <TableCell><audio controls style={{ outline: 'none' }} type="audio/mp3">
                            <source
                              src={CommonConstants.CallRecoingURL + row.RecordingFileName+".mp3"}
                              id="src"
                            />
                          </audio></TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
           
              <div class="row">
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {RLen == 0
                      ? 0
                      : (Page - 1) * RowsPerPage +
                        1}{" "}
                    to{" "}
                    {Page * RowsPerPage >
                    RLen
                      ? RLen
                      : Page * RowsPerPage}{" "}
                    of {RLen} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                    count={CountPage}
                    page={Page}
                    onChange={HandleChangePage}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            
    </>
  )
}

export default CallHistory