import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import CampaignsTabing from '../../_components/clients/campaigns/campaignsTabing';
//import CollapsibleTable from '../_components/usertable';



class CCampaignsPage extends React.Component {
   

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                        <div className="row">
                            <div className="col">
                                {/* <h4 className="headertitle float-left pl-0 mb-0">Campaigns</h4>  */}
                            </div>
                        </div> 
                        <div className="row"> 
                            <div className="col">
                                    <CampaignsTabing />
                            </div>
                        </div>     
                </div> 
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCCampaignsPage = connect(mapState, actionCreators)(CCampaignsPage);
export { connectedCCampaignsPage as CCampaignsPage };