import { clientConstants } from "../_constants";

const initialState = {
  clientDropdown: [],
  clientID: null,
  selectedClientDropdown: null,
  searchValue: "",
  isClients: false,
  filteredOptions: [],
};

export function client(state = initialState, action) {
  switch (action.type) {
    case clientConstants.SET_CLIENT_DROPDOWN:
      return {
        ...state,
        clientDropdown: action.data,
      };
    case clientConstants.SET_CLIENT_ID:
      return {
        ...state,
        clientID: action.data,
      };
    case clientConstants.SET_SELECTED_CLIENT_DROPDOWN:
      return {
        ...state,
        selectedClientDropdown: action.data,
      };
    case clientConstants.SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.data,
      };
    case clientConstants.SET_IS_CLIENTS:
      return {
        ...state,
        isClients: action.data,
      };
    case clientConstants.SET_FILTERED_OPTIONS:
      return {
        ...state,
        filteredOptions: action.data,
      };
    default:
      return state;
  }
}
