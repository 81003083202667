import React, { useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { CommonConstants } from "../../../../_constants/common.constants";
import { GetUserDetails } from "../../../../_helpers/Utility";
import { history } from "../../../../_helpers";
import loadingicon from "../../../../images/loading.gif";

export default function AccountClientStatus() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("OrderBy");
  const [sortedColumn, setSortedColumn] = React.useState("OrderBy");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [CUserID,SetCUserID] = React.useState(0)

  useEffect(() => {
    document.title = "Account Client Status | SalesHive";
    let Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID)
      SetRole(Details.Role);
    }
    AccountStatuslistGet(Details.ClientID, Details.ParentUserID);
  }, [Search, Page, RowsPerPage]);

  //Get Account Status List
  const AccountStatuslistGet = (CID, UID) => {
    SetIsLoading(true)
    let InputParameter = {
      ClientID: CID,
      UserID: UID,
      Role: Role,
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
    };
    const Rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/account_client_status/AccountClientStatusGet",
      method: "POST",
      data: InputParameter,
    });
    Rows1.then((Result) => {
      SetData(Result.data.PageData);
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)
    });
  };

  //Delete Account Client Status
  const DeleteBtn = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a account client status",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      cancelButtonText: "No, cancel!",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let StatusData = {
          _id: id,
          IsDeleted: true,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/account_client_status/AccountClientStatusDelete",
          method: "POST",
          data: StatusData,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Account client status deleted successfully.",
                "success"
              );
              history.push("/accountclientstatus");
              SetPage(1);
              SetSearch("");
              document.getElementById("Search").value = "";
              AccountStatuslistGet(ClientID, UserID);
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //Search meeting list
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      let SearchedVal = document.getElementById("Search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  //Handle change page
  const HandleChangePage = (event, newPage) => {
    if (newPage == Page) {
      SetPage(newPage);
    } else {
      SetPage(newPage);
    }
  };

  //Change row seleted
  const ChangeRowSelected = (event) => {
    SetRowsPerPage(Number(event.target.value));
    SetPage(1);
  };

  //render on edit page
  const EditPage = (id) => {
    history.push("/editaccountclientstatus", id);
  };

  //get sort field data
  // const SortData = (Field) => {
  //   SetIsLoading(true)
  //   let SearchedVal = document.getElementById("Search").value;
  //   let SerchBox;
  //   if (SearchedVal == "") {
  //     SerchBox = false;
  //     SetSflag(false);
  //     let SortField = Field;
  //     let SortBy;
  //     if (SortedBy == 1) {
  //       SortBy = -1;
  //       SetSortedBy(-1);
  //     } else {
  //       SortBy = 1;
  //       SetSortedBy(1);
  //     }
  //     SetSortField(Field);
  //     let InputParameter = {
  //       Page: Page,
  //       RowsPerPage: RowsPerPage,
  //       SerchBox: false,
  //       Archive: false,
  //       Sort: true,
  //       Field: SortField,
  //       SortBy: SortBy,
  //       Type: "User",
  //       ClientID: ClientID,
  //       UserID: UserID,
  //       Role: Role,
  //     };
  //     const AccountCategoriesList = Axios({
  //       url:
  //         CommonConstants.MOL_APIURL +
  //         "/account_client_status/AccountClientStatusGet",
  //       method: "POST",
  //       data: InputParameter,
  //     });
  //     AccountCategoriesList.then((Result) => {
  //       SetRows(Result.data.PageData);
  //       SetRlen(Result.data.TotalCount);
  //       SetFlen(Result.data.TotalCount);
  //       SetCountPage(Result.data.PageCount);
  //       SetIsLoading(false)
  //     });
  //   } else {
  //     SerchBox = true;
  //     SetSflag(true);
  //     let SortField = Field;
  //     let SortBy;
  //     if (SortedBy == 1) {
  //       SortBy = -1;
  //       SetSortedBy(-1);
  //     } else {
  //       SortBy = 1;
  //       SetSortedBy(1);
  //     }
  //     SetSortField(Field);
  //     let InputParameter = {
  //       Page: Page,
  //       RowsPerPage: RowsPerPage,
  //       search: SearchedVal,
  //       SerchBox: SerchBox,
  //       Archive: false,
  //       Sort: true,
  //       Field: SortField,
  //       SortBy: SortBy,
  //       Type: "User",
  //       ClientID: ClientID,
  //       UserID: UserID,
  //       Role: Role,
  //     };
  //     const AccountCategoriesList = Axios({
  //       url:
  //         CommonConstants.MOL_APIURL +
  //         "/account_client_status/AccountClientStatusGet",
  //       method: "POST",
  //       data: InputParameter,
  //     });
  //     AccountCategoriesList.then((Result) => {
  //       SetRows(Result.data.PageData);
  //       SetRlen(Result.data.TotalCount);
  //       SetFlen(Result.data.TotalCount);
  //       SetCountPage(Result.data.PageCount);
  //       SetIsLoading(false)
  //     });
  //   }
  // };

  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("Search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
  
    SetSortField(Field);
    SetSflag(SearchedVal !== "");
  
    const InputParameter = {
      Page,
      RowsPerPage,
      SerchBox: SearchedVal !== "",
      Sort: true,
      Search: SearchedVal,
      Field,
      SortBy,
      Type: "User",
      ClientID,
      UserID,
      Role,
    };
  
    Axios({
      url: `${CommonConstants.MOL_APIURL}/account_client_status/AccountClientStatusGet`,
      method: "POST",
      data: InputParameter,
    }).then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetPage(1);
      SetIsLoading(false);
    });
  };

  return (
    <div>
      {
        IsLoading ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : <></>
      }
      
      <div className="row px-0 mt-4">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="Search"
              id="Search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell  onClick={() => {SortData("Name");}}>
                    Name
                    <span className="shorting">
                         <ArrowUpward className={SortedBy === 1 && sortedColumn === "Name" ? "active" : null} />
                         <ArrowDownward className={SortedBy === -1 && sortedColumn === "Name" ? "active" : null} />
                    </span>
                  
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((Row) => (
                  <TableRow key={Row?._id}>
                    <TableCell
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {Row?.Name}
                    </TableCell>
                    <TableCell>
                      <a
                        onClick={() => {
                          EditPage(Row._id);
                        }}
                        className="btn-eyesicon"
                        title="Edit"
                      >
                        <i class="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      <a
                        onClick={() => {
                          DeleteBtn(Row._id);
                        }}
                        className="btn-eyesicon"
                        title="Delete"
                      >
                        <i class="la flaticon-delete-1 delete-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
    
        <div className="row pb-4">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      
    </div>
  );
}
