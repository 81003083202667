import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Axios from "axios";
import { history } from "../../../_helpers";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import Footer from "../../../_components/user/footer/footer";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class EditDocumentsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      FieldID: 0,
      IsExist: false,
      TitleNotNull: true,
      UrlNotNull: true,
      Type: true,
      IsUrl: true,
      reset: false,
      fields: {},
      errors: {},
      ClientID: null,
      UserID: null,
      CUserID: null,
      TypeList: [],
      Role: null,
      Title: null,
      formData: {},
      TypeStatus:null,
      IsSubmitting: false,
    };
    this.GetTypeDropDown();
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.HandleChangedropdownBlur = this.HandleChangedropdownBlur.bind(this);
    this.HandleChangeotherFieldBlur =
      this.HandleChangeotherFieldBlur.bind(this);
    this.handleChangeWebsitecheck = this.handleChangeWebsitecheck.bind(this);
  }
  componentDidMount() {
    document.title = "Edit Document | SalesHive";

    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.setState({ Role: Details.Role });
    }
    this.GetDocumentOldData();
  }

  // Get Document details from database
  GetDocumentOldData() {
    const ID = this.props.location.state;
    var InputParameter = {
      ID: ID,
      Role: this.state.Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/documents/DocumentsGetByID",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        this.setState({
          formData: {
            Title: res.data.Data.Title,
            Url: res.data.Data.Url,
            Type: res.data.Data.TypeID,
          },
        });
        this.setState({ Title: res.data.Data.Title });
        document.getElementById("Title").value = res.data.Data.Title;
        document.getElementById("Url").value = res.data.Data.Url;
        this.setState({TypeStatus:res.data.Data.TypeID})
        //document.getElementById("Type").value = res.data.Data.TypeID;
        document.getElementById("hideloding").style.display = "none";
      } else {
        toast.error(res.data.Message);
      }
    });
  }

  // Get Type Drop Down
  GetTypeDropDown() {
    const InputParameter = {
      Role: this.state.Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/documents/TypeGet",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        const TypeData = res.data.Data;
        const option = TypeData.map((Type) => ({
          FieldID: Type.FieldID,
          FieldName: Type.FieldName,
        }));
        this.setState({ TypeList: option });
      } else {
        toast.error(res.data.Message);
      }
    });
  }
  //check exist Title
  async CheckExistDocumentTitle(Title) {
    if (Title != "") {
      var InputParameters = {
        ClientID: this.state.ClientID,
        Title: Title,
        Role: this.state.Role,
      };
      var CheckDocument = await Axios({
        url: CommonConstants.MOL_APIURL + "/documents/DocumentsTitleExists",
        method: "POST",
        data: InputParameters,
      });
      if (CheckDocument.data.StatusMessage == "SUCCESS") {
        if (CheckDocument.data.Data.length > 0) {
          if (this.state.Title.toLowerCase() === Title.toLowerCase()) {
            return "AllDone";
          } else {
            return "ExistTitle";
          }
        } else {
          return "AllDone";
        }
      }
    } else {
      return "BlankData";
    }
  }

  //New changes for validation
  async CheckWebSite(URLlink) {
    var regexp = new RegExp(
      "^(https?:\\/\\/)" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    if (URLlink != "") {
      if (!regexp.test(URLlink)) {
        return "InvalidURL";
      } else {
        return "AllDoneURL";
      }
    } else {
      return "BlankDataURL";
    }
  }

  async handleChangeWebsitecheck() {
    let errors = { ...this.state.errors };
    var UrlLink = document.getElementById("Url").value.trim();
    if (UrlLink == "") {
      errors["Url"] = "Please enter url";
    } else {
      let IsExist = await this.CheckWebSite(UrlLink);
      if (IsExist == "InvalidURL") {
        errors["Url"] = "Please enter valid url";
      }
      if (IsExist == "AllDoneURL") {
        errors["Url"] = null;
      }
    }
    this.setState({ errors: errors });
  }

  async HandleChangedropdownBlur(event) {
    let errors = { ...this.state.errors };
    this.setState({TypeStatus:event.target.value})
    var TypeID = document.getElementById("Type").value.trim();

    if (TypeID == "") {
      errors["Type"] = "Please select type";
    } else {
      errors["Type"] = null;
    }

    this.setState({ errors: errors });
  }

  async HandleChangeotherFieldBlur() {
    
    let errors = { ...this.state.errors };
    var Title = document.getElementById("Title").value.trim();
    var IsExistTitle = await this.CheckExistDocumentTitle(Title);

    //title
    if (IsExistTitle == "ExistTitle") {
      errors["Title"] = "Title already exist.";
    }

    if (IsExistTitle == "BlankData" && Title == "") {
      errors["Title"] = "Please enter title";
    }

    if (IsExistTitle == "AllDone") {
      errors["Title"] = null;
    }

    this.setState({ errors: errors });
  }

  BackBtn() {
    history.push("/documents");
  }

  HandleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  async FromValidation() {
    let formIsValid = true;
    // this.setState({ IsValid: false });
    let fields = this.state.fields;
    var Title = document.getElementById("Title").value.trim();
    var UrlLink = document.getElementById("Url").value.trim();
    var TypeID = document.getElementById("Type").value.trim();

    var IsExistTitle = await this.CheckExistDocumentTitle(Title);
    let IsExist = await this.CheckWebSite(UrlLink);
    let errors = {};

    //title
    if (IsExistTitle == "ExistTitle") {
      formIsValid = false;
      errors["Title"] = "Title already exist.";
    }

    if (IsExistTitle == "BlankData" && Title == "") {
      formIsValid = false;
      errors["Title"] = "Please enter title";
    }

    //URL
    if (IsExist == "InvalidURL") {
      formIsValid = false;
      errors["Url"] = "Please enter valid url";
    }
    if (IsExist == "BlankDataURL" && UrlLink == "") {
      formIsValid = false;
      errors["Url"] = "Please enter url";
    }

    if (TypeID == "") {
      formIsValid = false;
      errors["Type"] = "Please select type";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  // HandleValidation = () => {
  //   let formIsValid = true;
  //   const errors = {};
  //   this.CheckExistDocumentTitle(this.state.formData.Title);

  //   // Validate Title field
  //   if (!this.state.formData.Title) {
  //     formIsValid = false;
  //     errors.Title = "Please enter title";
  //   } else if (this.state.IsExist) {
  //     formIsValid = false;
  //     errors.Title = "Title already exist.";
  //   } else {
  //     errors.Title = "";
  //   }
  //   // Validate Url field
  //   if (!this.state.formData.Url) {
  //     formIsValid = false;
  //     errors.Url = "Please enter url";
  //   } else if (!this.UrlPatternValidation(this.state.formData.Url)) {
  //     formIsValid = false;
  //     errors.Url = "Please enter valid url";
  //   } else {
  //     errors.Spend = "";
  //   }
  //   // Validate Type field
  //   if (!this.state.formData.Type || this.state.formData.Type == "--Select--") {
  //     formIsValid = false;
  //     errors.Type = "Please select type";
  //   } else {
  //     errors.Type = "";
  //   }
  //   this.setState({ errors });

  //   return formIsValid;
  // };

  // HandleBlur = (event) => {
  //   const { name, value } = event.target;
  //   const { errors } = this.state;
  //   if (!value) {
  //     errors[name] = `Please enter ${name.toLowerCase()}`;
  //   } else {
  //     if (name === "Title") {
  //       this.CheckExistDocumentTitle(value);
  //       if (this.state.IsExist) {
  //         errors[name] = `${name} already exist.`;
  //       } else {
  //         delete errors[name];
  //       }
  //     } else if (name == "Url") {
  //       if (!this.UrlPatternValidation(value)) {
  //         errors[name] = `Please enter valid url`;
  //       } else {
  //         delete errors[name];
  //       }
  //     } else {
  //       delete errors[name];
  //     }
  //   }
  //   this.setState({ errors });
  // };

  async SaveBtn(e) {
    this.setState({ IsSubmitting: true });
    //document.getElementById("hideloding").style.display = "block";
    var Final_flag = await this.FromValidation();
    if (Final_flag == true) {
      document.getElementById("hideloding").style.display = "block";
      var Title = document.getElementById("Title").value.trim();
      var Url = document.getElementById("Url").value.trim();
      var TypeID = document.getElementById("Type").value.trim();
      var Data = {
        ID: this.props.location.state,
        Title: Title,
        Url: Url,
        TypeID: TypeID,
        LastUpdatedBy: this.state.CUserID,
        LastUpdatedDate: new Date(),
        Role: this.state.Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/documents/DocumentsUpdate",
        method: "POST",
        data: Data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div>
              Document Edit
              <br />
              Document updated successfully.
            </div>
          );
          history.push("/documents");
        } else {
          toast.error(res.data.Message);
          this.setState({ IsSubmitting: false });
        }
      });
    } else {
      document.getElementById("hideloding").style.display = "none";
      this.setState({ IsSubmitting: false });
    }
    document.getElementById("hideloding").style.display = "none";
  }
  render() {
    return (
      <>
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitlepad float-left pt-4">
                  Edit Document
                </h4>
              </div>
            </div>

            <div class="bg-white">
              <div className="row pt-1">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet px-5">Title</label>
                    <div class="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="Title"
                        name="Title"
                        placeholder="Enter title"
                        type="text"
                        onBlur={this.HandleChangeotherFieldBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Title"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group ">
                  <div class="row">
                    <label class="col-lg-4 view-lable-meet">Url</label>
                    <div class="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="Url"
                        name="Url"
                        placeholder="Enter url"
                        type="text"
                        onBlur={this.handleChangeWebsitecheck}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Url"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row ">
                    <label class="col-lg-4 view-lable-meet px-5">Type</label>
                    <div class="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field  must be a number."
                        value={this.state.TypeStatus}
                        id="Type"
                        name="Type"
                        onChange={this.HandleChangedropdownBlur}
                      >
                        <option value="">--Select--</option>
                        {this.state.TypeList.map((val) => (
                          <option value={val.FieldID}>{val.FieldName}</option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["Type"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-3 mt-4">
              <div class="col-lg-12 pull-left mb-4">
                <button
                  id="submit"
                  onClick={this.SaveBtn}
                  disabled={this.state.IsSubmitting}
                  class="btn btn-primary btn-lightgreen mr-1"
                >
                  <i class="la la-save"></i> Save
                </button>
                <a
                  id="backtolist"
                  onClick={this.BackBtn}
                  class="btn btn-secondary"
                >
                  <i class="la la-arrow-circle-left"></i> Back
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditDocumentsPage = connect(
  mapState,
  actionCreators
)(EditDocumentsPage);
export { connectedEditDocumentsPage as EditDocumentsPage };
