import React from "react";
import Axios from "axios";

import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { userActions } from "../../../_actions";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

class CAddContactsCustomFieldpage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();

    super(props);

    this.state = {
      // Fields: {},
      Errors: {},
      FieldNameAvailble: null,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      Role: Details.Role,
      UserIDby:Details.ChildUserID,
      IsExist: false,
      BtnDisabled:false
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = 'Add Custom Contact Field | SalesHive'
  }

  // form validation
  async FromValidation() {
    let FormIsValid = true;
    let Errors = {};
    var Name = document.getElementById("Name").value.trim();
    var IsExist = await this.ContactCustomFieldNameCheckExits(Name)
    if (Name == "" && Name.indexOf(" ") != "") {
      FormIsValid = false;
      Errors["Name"] = "Please enter field name";

    }

    if(IsExist == true){
      FormIsValid = false;
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

  // handle change
  HandleChange() {
    var Name = document.getElementById("Name").value.trim();
    if (Name != "" && Name.indexOf(" ") != "") {
      this.state.Errors.Name = null;
      this.state.FieldNameAvailble = null;
      this.ContactCustomFieldNameCheckExits(Name);
    } else {
      this.state.FieldNameAvailble = null;
      this.state.Errors.Name = null;
    }
  }

  // Contact Custom Field Name Check Exits
  async ContactCustomFieldNameCheckExits(Name) {
    var str_in = {
      ClientID: this.state.ClientID,
      FieldName: Name,
      Role:this.state.Role
    };
    var resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/contactcustom/ContactCustomFieldExists",
      method: "POST",
      data: str_in,
    })
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          this.setState({ FieldNameAvailble: resdata.data.Data[0]?.FieldName });
          this.setState({ IsExist: true });
          return true;
        }else{
          this.setState({ IsExist: false });
          return false;
        }
      }
  }

  // back button
  BackBtn() {
    history.push("/CContactsCustomField");
  }

  // save button
  async SaveBtn(e) {
    this.setState({BtnDisabled:true})
    var Final_flag =  await this.FromValidation()
    if (Final_flag == true) {
      var Name = document.getElementById("Name").value.trim();
      var ContactCustomField = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
        FieldName: Name,
        CreatedBy: this.state.UserIDby,
        FieldFor: "prospect",
        FieldValue: null,
        CreatedDate: new Date(),
        IsFieldRequired: false
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/contactcustom/ContactCustomFieldAdd",
        method: "POST",
        data: ContactCustomField,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div className="toastsize" >Custom  contact field added<br/> Custom contact field added successfully.</div>);
          history.push("/CContactsCustomField");
        } else {
          toast.error(res.data.Message);
          this.setState({BtnDisabled:false})
        }
      });
    }else{
      this.setState({BtnDisabled:false})
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad pl-0 mb-0">
                    Add Custom Contact Field
                  </h4>
                </div>
              </div>

            <div class="paddcols px-3">
              <div className="row py-2">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Field Name</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter field name"
                        id="Name"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        name="Name"
                        placeholder="Enter field name"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                      {this.state.FieldNameAvailble && (
                        <span style={{ color: "red" }}   id="validation_error">
                          Field name already exist.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row my-3">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.BtnDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <button
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </button>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCAddContactsCustomFieldpage = connect(
  mapState,
  actionCreators
)(CAddContactsCustomFieldpage);
export { connectedCAddContactsCustomFieldpage as CAddContactsCustomFieldpage };
