import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import KPIsclientsTabing from "../../_components/clients/kpis/KPIsTabing";
const moment = require("moment");
import { CommonConstants } from "../../_constants/common.constants";
import $ from "jquery";
import {
  getFirstDayOfQuarter,
  getCurrentDayOfQuarter,
} from "../../_helpers/Utility";

class CkpisPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      FromDate: "",
      ToDate: "",
      IsAnalyze:false
    };
    this.SaveBtn = this.SaveBtn.bind(this);
    this.UpdateFromKPIPage = this.UpdateFromKPIPage.bind(this);
    this.daterefect = React.createRef();
    this.daterefect2 = React.createRef();
  }

  componentDidMount() {
    var fromdate = getFirstDayOfQuarter();

    const previousMonthDate = new Date(fromdate);

    // Convert to UTC date components
    const utcYear = previousMonthDate.getUTCFullYear();
    const utcMonth = String(previousMonthDate.getUTCMonth() + 1).padStart(
      2,
      "0"
    );
    const utcDay = String(previousMonthDate.getUTCDate()).padStart(2, "0");

    // Format the date
    const formattedDate = `${utcMonth}/${utcDay}/${utcYear}`;

    // const formattedDate = previousMonthDate.toLocaleDateString('en-US', {
    //     month: '2-digit',
    //     day: '2-digit',
    //     year: 'numeric'
    // });

    // Set the value of the "from-date" input field
    document.getElementById("from-date").value = formattedDate;
    const $datepickerElement = $(this.daterefect.current);
    $datepickerElement.datetimepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      container: "#app",
      todayBtn: true,
      todayHighlight: true,
      minView: "2",
    });
    document.getElementById("to-date").value = new Date().toLocaleDateString(
      "en-US",
      { month: "2-digit", day: "2-digit", year: "numeric" }
    );
    const $datepickerElement2 = $(this.daterefect2.current);
    $datepickerElement2.datetimepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      container: "#app",
      todayBtn: true,
      todayHighlight: true,
      minView: "2",
    });

    var FromDate = moment
      .utc(document.getElementById("from-date").value)
      .format("YYYY-MM-DD");
    var ToDate = moment
      .utc(document.getElementById("to-date").value)
      .format("YYYY-MM-DD");
    this.setState({ FromDate: FromDate, ToDate: ToDate,IsAnalyze:true });
  }

  SaveBtn() {
    var FromDate = moment
      .utc(document.getElementById("from-date").value)
      .format("YYYY-MM-DD");
    var ToDate = moment
      .utc(document.getElementById("to-date").value)
      .format("YYYY-MM-DD");

    this.setState({ FromDate: FromDate, ToDate: ToDate,IsAnalyze:true });
  }
  UpdateFromKPIPage(value) {
    if (value == true) {
      window.location = "/kpis";
    }
  }

  render() {
    const mainTtile = CommonConstants.Title;
    document.title = "KPIs" + mainTtile;

    return (
      <>
        <div className="adminmain mheight">
          <Sidebar
            menupage="/kpis"
            updatefromchild={this.UpdateFromKPIPage}
            className=""
          />
          <div className="bodyhome">
            <div className="row">
              <div className="col-md-5 padd-0">
                <h4 className="headertitlebd pl-2 py-4 mt-0">KPIs</h4>
              </div>
              <div className="col padright">
                <div className="btn-group datepicker-hed">
                  <div className="col-lg-6 timedatepikker date-input">
                    <input
                      className="form-control m-input"
                      type="text"
                      id="from-date"
                      ref={this.daterefect}
                    />
                  </div>
                  <div className="col-lg-6 timedatepikker date-input">
                    <input
                      className="form-control m-input"
                      type="text"
                      id="to-date"
                      ref={this.daterefect2}
                    />
                  </div>
                  <button
                    onClick={this.SaveBtn}
                    id="aCompanyState"
                    class="btn btn-primary btnColor m-btn brrb72"
                    autocomplete="off"
                  >
                    <i class="la flaticon-diagram"></i>
                    <span> Analyze</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col padd-0">
                <KPIsclientsTabing
                  FromDates={this.state.FromDate}
                  ToDates={this.state.ToDate}
                  IsAnalyze ={this.state.IsAnalyze}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCkpisPage = connect(mapState, actionCreators)(CkpisPage);
export { connectedCkpisPage as CkpisPage };
