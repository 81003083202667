import React, { useEffect, useRef } from "react";
import Axios from "axios";
import PropTypes from "prop-types";

import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Avatar } from "@material-ui/core";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import Userimg from "../../../images/avatar/1.jpg";
import loadingiconDespam from "../../../images/loading.gif";
// import TableRow from '@material-ui/core/TableRow';
import HighlightWithinTextarea from 'react-highlight-within-textarea'; 
import { useState } from "react";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable() {
  const [KeywordArray, SetKeywordArray] = React.useState([]);
  const [Rows, SetRows] = React.useState([]);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID, SetCUserID] = React.useState(0);
  const [IsLoadingDespam, SetIsLoadingDespam] = React.useState(false);
  // despammer errors table details
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [CSVEPage, SetCSVEPage] = React.useState(1);
  const [CSVERowsPerPage, SetCSVERowsPerPage] = React.useState(100);
  const [Page, SetPage] = React.useState(1);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [text, setText] = useState('');
  const [KeywordText, setKeywordText] = useState([]);
  const textareaRef = useRef(null);


  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
  }, []);
  //
  function extractSubstr(str, regexp) {
    return (
      str
        .replace(/[^\w\s]|_/g, "")
        .replace(/\s+/g, " ")
        .toLowerCase()
        .match(regexp) || []
    );
  }
  // Find words by searching for sequences of non-whitespace characters.
  function getWordsByNonWhiteSpace(str) {
    return extractSubstr(str, /\S+/g);
  }
  // //search for record
  // const RequestSearch = (Event) => {
  //   if (Event.key === "Enter") {
  //     var SearchedVal = document.getElementById("search").value;
  //     if (SearchedVal == Search) {
  //       SetSearch(SearchedVal);
  //       SetPage(1);
  //     } else {
  //       SetSearch(SearchedVal);
  //       SetPage(1);
  //     }
  //   }
  // };

  // //change Page
  // const HandleChangePage = (Event, NewPage) => {
  //   if (NewPage == Page) {
  //     SetPage(NewPage);
  //   } else {
  //     SetPage(NewPage);
  //   }
  // };

  // //change display rows
  // const ChangeRowSelected = (Event) => {
  //   SetRowsPerPage(Event.target.value);
  //   Rows.slice(
  //     (Page - 1) * RowsPerPage,
  //     (Page - 1) * RowsPerPage + RowsPerPage
  //   );
  //   SetPage(1);
  //   SetRows(
  //     Rows.slice(
  //       (Page - 1) * RowsPerPage,
  //       (Page - 1) * RowsPerPage + RowsPerPage
  //     )
  //   );
  //   SetRows(
  //     Rows.slice(
  //       (Page - 1) * RowsPerPage,
  //       (Page - 1) * RowsPerPage + RowsPerPage
  //     )
  //   );
  //   SetRlen(
  //     Rows.slice(
  //       (Page - 1) * RowsPerPage,
  //       (Page - 1) * RowsPerPage + RowsPerPage
  //     ).length
  //   );
  //   SetFlen(
  //     Rows.slice(
  //       (Page - 1) * RowsPerPage,
  //       (Page - 1) * RowsPerPage + RowsPerPage
  //     ).length
  //   );
  //   SetCountPage(
  //     parseInt(
  //       (Rows.slice(
  //         (Page - 1) * RowsPerPage,
  //         (Page - 1) * RowsPerPage + RowsPerPage
  //       ).length +
  //         (RowsPerPage - 1)) /
  //         RowsPerPage
  //     )
  //   );
  // };
  const ParagrapghCount = async () => {
    // var Paragrapgh = document.getElementById("CopyMail").value;
    console.log(text); 
    SetIsLoadingDespam(true);
    //  var word1 = getWordsByNonWhiteSpace(Paragrapgh)
    var InputParameter = {
      KeywordCheck: text,
      Role: Role,
    };
    var res = await Axios({
      url: CommonConstants.MOL_APIURL + "/despammer/CheckParagraphKeyword",
      method: "POST",
      data: InputParameter,
    });
    if (res.data.StatusMessage == "SUCCESS") {
      SetData(res.data.Data);
      SetRows(res.data.Data);

      SetRlen(res.data.Data.length);
      SetFlen(res.data.Data.length);
      const totalPages = Math.ceil(res.data.Data.length / CSVERowsPerPage);
      SetCountPage(totalPages);
      // SetRlen(res.data.Data.length);
      // SetFlen(res.data.Data.length);
      // SetCountPage(
      //   parseInt((res.data.Data.length + (RowsPerPage - 1)) / RowsPerPage)
      // );
      let keywordArray = [];
      res.data.Data.forEach(item => {
        keywordArray.push(item.Keyword);
    });
    console.log(keywordArray);
      setKeywordText(keywordArray)

      SetIsLoadingDespam(false);
    } else {
      toast.error(res.data?.Message);
      SetIsLoadingDespam(false);
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetCSVERowsPerPage(Number(Event.target.value));
    SetCSVEPage(1);
  };

  // Search details

  const handleSearch = (e) => {
    SetSearch(e.target.value);
  };

  let filteredData = Data.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key].toString().toLowerCase().includes(Search.toLowerCase())
    );
  });

  const handleSort = (key) => {
    SetSortField(key);
  };

  if (SortField !== "") {
    filteredData.sort((a, b) => {
      if (a[SortField] < b[SortField]) {
        return -1;
      }
      if (a[SortField] > b[SortField]) {
        return 1;
      }
      return 0;
    });
  }

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = CSVEPage * CSVERowsPerPage;
  const indexOfFirstItem = indexOfLastItem - CSVERowsPerPage;

  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      {IsLoadingDespam === true ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingiconDespam} />
        </div>
      ) : (
        <></>
      )}
      <div className="row bg-white pt-4">
        <div className="col-xl-12">
          <form className="border-bottom pb-3">
            <div class="col-lg-12  px-1">
              <label>Email Copy </label>
              {/* <textarea
                id="CopyMail"
                class="form-control m-input hwt-content hwt-input"
                rows="15"
                autocomplete="off"
                spellcheck="false"
              ></textarea> */}
              <div className="m-area-despam">
                <HighlightWithinTextarea id="CopyMail" style={{ height: '100%', resize: 'none' }}
                  ref={textareaRef}
                  value={text}
                  onChange={setText}
                  //highlight={['Be', 'Life']} 
                  highlight={KeywordText} 
                  rows="15"
                />
              </div>

            </div>
            <div class="col-lg-12 alignright px-0 pb-4 pt-4">
              <a
                id="lnkDespam"
                onClick={ParagrapghCount}
                class="btn btn-primary btnColor"
              >
                {" "}
                Despam
              </a>
            </div>
          </form>
        </div>
      </div>

      <div className="row bg-white px-4 mt-3 pb-3">
        <div className="col">
          <div className="row border-bottom my-3 py-3 pt-4 ">
            <div className="col ">
              <h4 className="headertitlenop float-left">
                Total Issues ({Rows.length})
              </h4>
            </div>
          </div>

          <div className="row my-3 pt-3 px-1">
            <div className="col-sm-12 col-md-6">
              <label className="textlabelte">
                Show
                <select
                  name="tbl_meeting_length"
                  onChange={ChangeRowSelected}
                  aria-controls="tbl_meeting"
                  className="form-control form-control-sm"
                  value={CSVERowsPerPage}
                >
                  {CommonConstants.show_rows.map((value) => (
                    <option value={value}>{value}</option>
                  ))}
                </select>
                entries
              </label>
            </div>
            <div className="col-sm-12 col-md-6 full-right">
              <label className="textlabelte">
                Search:{" "}
                <input
                  type="search"
                  id="search"
                  onChange={(e) => {
                    handleSearch(e);
                  }}
                  className="form-control form-control-sm ml-2"
                  placeholder=""
                  aria-controls="tbl_meeting"
                />
              </label>
            </div>
          </div>

          <div className="table-bordered mx-1">
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {" "}
                      <a
                        onClick={() => {
                          handleSort("Keyword");
                        }}
                      >
                        Terms
                      </a>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <a
                        onClick={() => {
                          handleSort("Counts");
                        }}
                      >
                        Terms
                      </a>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentItems.length === 0 ? (
                    <p className="text-center">No data available in table</p>
                  ) : (
                    currentItems?.map((Row) => {
                      return (
                        <TableRow>
                          <TableCell>{Row.Keyword}</TableCell>
                          <TableCell>{Row.Counts}</TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {Sflag ? (
            <div className="row">
              <Pagination
                component="div"
                count={CountPage}
                page={CSVEPage}
                onChange={handlePagination}
                showFirstButton
                showLastButton
              />
              <div className="col dataTables_info">
                <p>
                  Showing {Rlen == 0 ? 0 : (CSVEPage - 1) * CSVERowsPerPage + 1}{" "}
                  to{" "}
                  {CSVEPage * CSVERowsPerPage > Rlen
                    ? Rlen
                    : CSVEPage * CSVERowsPerPage}{" "}
                  of {Rlen} entries (filtered from {Flen} total entries)
                </p>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col dataTables_info">
                <p>
                  Showing {Rlen == 0 ? 0 : (CSVEPage - 1) * CSVERowsPerPage + 1}{" "}
                  to{" "}
                  {CSVEPage * CSVERowsPerPage > Rlen
                    ? Rlen
                    : CSVEPage * CSVERowsPerPage}{" "}
                  of {Rlen} entries
                </p>
              </div>
              <div className="col pageright">
                <Pagination
                  component="div"
                  count={CountPage}
                  page={CSVEPage}
                  onChange={handlePagination}
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
