import React, { useEffect } from 'react';
const moment = require("moment");
import Axios from "axios";

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from "@material-ui/lab/Pagination";

import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export default function AccountAllTable() {
  const [Open, SetOpen] = React.useState(false);
  const [Data, SetData] = React.useState([]);
  const [Rows, SetRows] = React.useState([]);
  const [Rlen, SetRlen] = React.useState(0);
  const [Flen, SetFlen] = React.useState(0);
  const [SFlag, SetSFlag] = React.useState(false);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Checks, SetChecks] = React.useState(false);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [SortField, SetSortField] = React.useState("Name");
  const [SortBy, SetSortBy] = React.useState(1);
  const [Search, SetSearch] = React.useState("");
  const [HeaderList, SetHeaderList] = React.useState([]);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [UserIDby, SetUserIDby] = React.useState(0);
  const [Role,SetRole] = React.useState(null);
  useEffect(() => {
    var GetAccountcateList = GetUserDetails();
    if (GetAccountcateList != null) {
      SetClientID(GetAccountcateList.ClientID);
      SetUserID(GetAccountcateList.ParentUserID);
      SetUserIDby(GetAccountcateList.ChildUserID);
      SetRole(GetAccountcateList.Role);
    }
    GetProspectsTableList()
  }, [ClientID, UserID])

  // Get Prospects Table List
  const GetProspectsTableList = () => {
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortBy,
      Search: Search,
      Type: "User",
      ClientID: ClientID,
      Role:Role,
      UserID: UserID,
      AccountTypeID: "Prospect"
    };
    const GetProspectList = Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/GetAccountlist",
      method: "POST",
      data: InputParameter,
    });
    GetProspectList.then((Result) => {
      SetData(Result.data.PageData);
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetHeaderList(Result.data.AccountHeader);
    });
  }

  // Pagination document
  const HandleChangePage = (NewPage) => {
    SetPage(NewPage);
    if (Checks == true) {
      var InputParameter = {
        Page: NewPage,
        RowsPerPage: RowsPerPage,
        SearchBox: false,
        Archive: true,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        Role:Role,
        UserID: UserID,
        AccountType: "Prospect"
      };
      const GetProspectList = Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/GetAccountlist",
        method: "POST",
        data: InputParameter,
      });
      GetProspectList.then((Result) => {
        SetData(Result.data.PageData);
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
      });
    } else {
      var InputParameter = {
        Page: NewPage,
        RowsPerPage: RowsPerPage,
        SearchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        Role:Role,
        UserID: UserID,
        AccountType: "Prospect"
      };
      const GetProspectList = Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/GetAccountlist",
        method: "POST",
        data: InputParameter,
      });
      GetProspectList.then((Result) => {
        SetData(Result.data.PageData);
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
      });
    }
  };

  // Change Rows Selected
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  // Search document
  const RequestSearch = () => {
    var SearchVal = document.getElementById("search").value;
    SetPage(1);
    SetRowsPerPage(10);
    var SearchBox;
    if (SearchVal == "") {
      SearchBox = false;
      SetSFlag(false);
    } else {
      SearchBox = true;
    }
    var InputParameter = {
      Page: 1,
      ClientID: ClientID,
      UserID: UserID,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortBy,
      Search: SearchVal,
      Type: "User",
      Role:Role,
      SearchBox: SearchBox,
      AccountType: "Prospect"
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/GetAccountlist",
      method: "POST",
      data: InputParameter,
    });
    rows1.then((Result) => {
      SetData(Result.data.PageData);
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
    });
  };

  // View Page
  const ViewPage = (ID) => {
    history.push("/cviewaccounts", ID);
  };

  return (
    <>
      <div className="row mt-4">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onChange={() => RequestSearch()}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>

      <div className="table-bordered">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <input type="checkbox" />
              </TableCell>
              <TableCell></TableCell>
              {HeaderList?.map((row) => (
                <TableCell key={row._id}>{row.ColumnName}</TableCell>
              ))}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Rows?.map((value1) => (
              <>
                <TableRow>
                  <TableCell>
                    <input type="checkbox" />
                  </TableCell>
                  <TableCell>
                    {" "}
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => SetOpen(!Open)}
                    >
                      {Open ? (
                        <i
                          class="fa fa-minus-circle minusl"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          class="fa fa-plus-circle plusbl"
                          aria-hidden="true"
                        ></i>
                      )}
                    </IconButton>
                  </TableCell>
                  {HeaderList.map((row) => {
                    if (row.ColumnName == "Account Name") {
                      return <TableCell>{value1.AccountName}</TableCell>;
                    } else if (row.ColumnName == "Email Domains") {
                      return <TableCell>{value1.EmailDomins}</TableCell>;
                    } else if (row.ColumnName == "Website") {
                      return <TableCell>{value1.Website}</TableCell>;
                    } else if (row.ColumnName == "Address 1") {
                      return <TableCell>{value1.Address1}</TableCell>;
                    } else if (row.ColumnName == "Address 2") {
                      return <TableCell>{value1.Address2}</TableCell>;
                    } else if (row.ColumnName == "City") {
                      return <TableCell>{value1.City}</TableCell>;
                    } else if (row.ColumnName == "State") {
                      return <TableCell>{value1.State}</TableCell>;
                    } else if (row.ColumnName == "Zip") {
                      return <TableCell>{value1.Zip}</TableCell>;
                    } else if (row.ColumnName == "Country") {
                      return <TableCell>{value1.Country}</TableCell>;
                    } else if (row.ColumnName == "Account Category") {
                      return <TableCell>{value1.p1[0]?.Name}</TableCell>;
                    } else if (row.ColumnName == "Industry") {
                      return <TableCell>{value1.Industry}</TableCell>;
                    } else if (row.ColumnName == "Account Owner") {
                      return <TableCell>{value1.p3[0]?.Name}</TableCell>;
                    } else if (row.ColumnName == "Reason Unqualified") {
                      return <TableCell>{value1.p4[0]?.Reason}</TableCell>;
                    } else if (row.ColumnName == "Company Phone") {
                      return <TableCell>{value1.CompanyPhone}</TableCell>;
                    } else if (row.ColumnName == "Account Client Status") {
                      return <TableCell>{value1.p5[0]?.Name}</TableCell>;
                    } else if (row.ColumnName == "AccountType") {
                      return <TableCell>{value1.p2[0]?.FieldName}</TableCell>;
                    } else if (row.ColumnName == "Created Date") {
                      return (
                        <TableCell>
                          {moment(
                            new Date(row.CreatedDate).toDateString()
                          ).format("MM/DD/YYYY")}
                        </TableCell>
                      );
                    } else if (row.ColumnName == "Company Revenue") {
                      return <TableCell>{value1.CompanyRevenue}</TableCell>;
                    } else if (row.ColumnName == "Employee Count") {
                      return (
                        <TableCell>{value1.CompanyEmployeeCount}</TableCell>
                      );
                    } else if (row.ColumnName == "Total Call Count") {
                      return <TableCell>{value1.p1[0]?.Name}</TableCell>;
                    } else if (row.ColumnName == "Custom Account 1") {
                      return <TableCell>{0}</TableCell>;
                    } else if (row.ColumnName == "custom 1") {
                      return <TableCell>{0}</TableCell>;
                    } else {
                      return <TableCell></TableCell>;
                    }
                  })}

                  <TableCell>
                    <a
                      onClick={() => {
                        ViewPage(value1._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i className="la flaticon-eye edit-icon"></i>
                    </a>
                    <a
                      onClick={() => {
                        DeleteClientName(value1._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i className="la flaticon-delete-1 delete-icon"></i>
                    </a>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                  >
                    <Collapse in={Open} timeout="auto" unmountOnExit>
                      <Box margin={1} className="innertables">
                        <Table size="small" aria-label="purchases">
                          <TableHead></TableHead>
                          <TableRow>
                            <TableCell align="center"></TableCell>
                            <TableCell scope="row"></TableCell>
                            <TableCell
                              className="labeltds"
                              scope="row"
                            ></TableCell>
                            <TableCell
                              className="labeltds"
                              scope="row"
                            ></TableCell>
                            <TableCell>8 June 2021</TableCell>
                            <TableCell>12/15/2020</TableCell>
                          </TableRow>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      {SFlag ? (
        <div className="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}