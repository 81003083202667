import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Avatar } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Userimg from '../../../images/avatar/1.jpg';
import userdefault from '../../../images/default.png';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Popup from "reactjs-popup";

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      { date: '2020-01-05', customerId: '11091700', amount: 3 },
      { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
    ],
  };
}


function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popupval,setpopup] = React.useState(false);
  const [cancelpopupval,setcancelpopup] = React.useState(false);
  const [ArchivedPopup,setarchivedpopup] = React.useState(false);

  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
 



  const openEditPopup = () => { 
    setpopup(true);
  };

  const closeTermsConditionModal = () => { 
    setpopup(false);
  };

  const SubmitTermsConditionModal = () => {
    setpopup(false);
  };

  const openCencelPopup = () => { 
    setcancelpopup(true);
  };

  const openArchivedPopup = () => { 
    setarchivedpopup(true);
  };



  

  document.addEventListener('mouseup', function(e) { 
      //console.log(popupval);
      if(popupval){
        setpopup(false);
        setcancelpopup(false);
        setarchivedpopup(false);
      }
  });

 

    //const openTermsConditionPopup = false;
  


  return (
<>
 
    <React.Fragment>
      <TableRow className={classes.root}> 
         
        <TableCell>
        <div class="toggleswich">
          <input type="checkbox" class="checktogle" checked />
          <b class="switch"> 
            <span class="checkion"></span>
            <span class="uncheckion"></span>
          </b>
          <b class="track"></b>
        </div>
        </TableCell>
        <TableCell> ***Meeting Request*** </TableCell>
        <TableCell>0</TableCell>
        <TableCell>0</TableCell>
        <TableCell>0</TableCell> 
        <TableCell>0%</TableCell>
        <TableCell>0%</TableCell>
        <TableCell>0%</TableCell>
        <TableCell>05/26/2021	</TableCell>  
        <TableCell>
          <a href="/editresponder" className="btn-eyesicon"><i class="la flaticon-edit-1 edit-icon"></i></a>
          <a href="#" class="btn-eyesicon"><i class="la flaticon-delete-1 delete-icon"></i></a>  
        </TableCell> 

      </TableRow>
      
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} className="innertables">
              <Table size="small" aria-label="purchases">
                 <TableHead>
                 
                </TableHead> 
                <TableRow> 
                <TableCell>8 June 2021</TableCell>
                <TableCell>  </TableCell>
                <TableCell> </TableCell>  
                </TableRow>
              </Table>

            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>

  </>  
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    Userimg: PropTypes.number.isRequired,
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
  createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
  createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
  createData('Eclair', 262, 16.0, 24, 6.0, 3.79), 
];

export default function AccountAllTable() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow> 
          <TableCell>Active</TableCell> 
          <TableCell>Day</TableCell> 
          <TableCell>Sent</TableCell> 
          <TableCell>Replies</TableCell> 
          <TableCell>Interested</TableCell> 
          <TableCell>Open %</TableCell> 
          <TableCell>Reply %</TableCell> 
          <TableCell>Interested %</TableCell>
          <TableCell>Created Date</TableCell>
          <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
