import React from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";
import Axios from "axios";
const moment = require("moment");
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Table from "@material-ui/core/Table";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";


toast.configure();

class EditClientPotentialMeetingsPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      fields: {},
      errors: {},
      // Note
      Page: 1,
      RowsPerPage: 10,
      Search: "",
      SortField: "Title",
      SortedBy: 1,
      Sflag: false,
      Data: [],
      Rows: [],
      Rlen: 0,
      Flen: 0,
      CountPage: 0,
      SearchData: [],
      AllRes: [],
      PotentialMeetingID: "",
      DefaultMeetingOwner: "",
      DefaultEnggamentStatus: "",
      DefaultSource: "",
      DefaultReminderEmail: "",
      NameAvailable: null,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      AllClientList: [],
      EngagementList: [],
      PotentialOwnerList: [],
      PotentialSourceList: [],
      PotentialAccountList: [],
    };
    this.AccountNoteSave = this.AccountNoteSave.bind(this);
    this.AccountNoteEditBtn = this.AccountNoteEditBtn.bind(this);
    this.NoteDelete = this.NoteDelete.bind(this);
    this.EditBtn = this.EditBtn.bind(this);
    this.AccountNoteUpdate = this.AccountNoteUpdate.bind(this);
    this.HandleChangeOwner = this.HandleChangeOwner.bind(this);
    this.HandleChangeAccountStatus = this.HandleChangeAccountStatus.bind(this);
    this.HandleChangeSource = this.HandleChangeSource.bind(this);
    this.HandleChangeReminderEmail = this.HandleChangeReminderEmail.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);

    this.NotesDetails();
    this.EditPotentialMeetingstatus();
    this.GetAllClientList(Details.ParentUserID);
    this.HandleClientID(Details.ClientID);
  }

  EditPotentialMeetingstatus() {
    const id = this.props.location.state;
    var str_in = {
      PotentialMeetingID: id,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/potentialmeeting/ClientPotentialGetById",
      method: "POST",
      data: str_in,
    }).then((res) => {
      this.setState({
        AllRes: res.data.ViewPotentialData,
        DefaultMeetingOwner:
          res.data.ViewPotentialData[0]?.MeetingOwnerID,
        DefaultEnggamentStatus:
          res.data.ViewPotentialData[0]?.EngagamentStatusID,
        DefaultSource:
          res.data.ViewPotentialData[0]?.MeetingSourceID,
        DefaultReminderEmail:
          res.data.ViewPotentialData[0]?.ReminderEmailAccountID,
      });
    });
  }

  //All Client
  GetAllClientList(UserId) {
    var str_in = {
      UserID: UserId,
    };
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/potentialmeeting/AllClientList",
      method: "POST",
      data: str_in,
    });
    rows.then((result) => {
      this.setState({
        AllClientList: result.data.Data,
      });
    });
  }

  //Client ID
  HandleClientID = (Cid) => {
    var str_in = {
      ClientID: Cid,
    };
    const rows = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/potentialmeeting/ClientPotentialAllStatusGet",
      method: "POST",
      data: str_in,
    });
    rows.then((Result) => {
      this.setState({
        EngagementList: Result.data.Enggementstatus,
        PotentialOwnerList: Result.data.PotentialOwner,
        PotentialSourceList: Result.data.MeetingSource,
        PotentialAccountList: Result.data.MeetingAccount,
      });
    });
  };

  FromValidation() {
    let formIsValid = true;
    let fields = this.state.fields;
    let errors = {};
    if (!fields["FirstName"]) {
      formIsValid = false;
      errors["FirstName"] = "Please enter first name";
    }
    if (!fields["LastName"]) {
      formIsValid = false;
      errors["LastName"] = "Please enter last name";
    }
    if (!fields["Title"]) {
      formIsValid = false;
      errors["Title"] = "Please enter Title";
    }
    if (!fields["Company"]) {
      formIsValid = false;
      errors["Company"] = "Please enter Company";
    }
    if (!fields["Email"]) {
      formIsValid = false;
      errors["Email"] = "Please enter Email";
    }
    if (!fields["MeetingSourceID"]) {
      formIsValid = false;
      errors["MeetingSourceID"] = "Please select Meeting Source";
    }
    if (!fields["ClientPOCID"]) {
      formIsValid = false;
      errors["ClientPOCID"] = "Please select reminder email account";
    }
    if (!fields["MeetingOwnerID"]) {
      formIsValid = false;
      errors["MeetingOwnerID"] = "Please select owner";
    }
    if (!fields["EngagementStatusID"]) {
      formIsValid = false;
      errors["EngagementStatusID"] = "Please select status";
    }
    // if (!fields["ClientPotentialID"]) {
    //   formIsValid = false;
    //   errors["ClientPotentialID"] = "Please select Client";
    // }
    // if (this.state.UserExist != null) {
    //     formIsValid = false;
    // }
    this.setState({ errors: errors });
    return formIsValid;
  }

  //handle change
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.Name != "") {
      this.state.errors.Name = null;
      this.state.ClientPotentialMeetingAvailble = null;
      // this.CheckExistContactSourceName(e.target.value);
    } else {
      this.state.ClientPotentialMeetingAvailble = null;
      this.state.errors.Name = null;
    }
  }

  //exist potential meeting
  CheckExistPotentialMeetingName(FirstName) {
    var str_in = {
      ClientID: this.state.ClientID,
      FirstName: FirstName,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/potentialmeeting/PotentialMeetingStatusExists",
      method: "POST",
      data: str_in,
    }).then((resdata) => {
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.data.length > 0) {
          this.setState({ statusAvailable: resdata.data.data });
        }
      }
    });
  }

  //edit page
  EditBtn = () => {
    history.push("/editaccount", this.props.location.state);
  };

  //Notes
  NotesDetails() {
    var Details = GetUserDetails();
    const id = this.props.location.state;
    this.setState({ PotentialMeetingID: id });
    var NoteData = {
      Page: this.state.Page,
      ClientID: parseInt(Details.ClientID),
      MeetingID: id,
      RowsPerPage: this.state.RowsPerPage,
      Sort: true,
      Field: this.state.SortField,
      SortBy: this.state.SortedBy,
      Search: this.state.Search,
      Type: "User",
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/potentialmeeting/GetClientPotentialMeetingNotes",
      method: "POST",
      data: NoteData,
    }).then((responsenote) => {
      this.setState({ Data: responsenote.data?.PageData });
      this.setState({ Rows: responsenote.data?.PageData });
      this.setState({ Rlen: responsenote.data?.TotalCount });
      this.setState({ Flen: responsenote.data?.TotalCount });
      this.setState({ CountPage: responsenote.data?.PageCount });
    });
  }

  //change display rows
  ChangeRowSelected = (event) => {
    this.setState({ Page: 1 });
    this.setState({ RowsPerPage: event.target.value });
    this.NotesDetails();
  };

  // search for record  note
  RequestSearch(event) {
    if (event.key === "Enter") {
      var SearchedVal = document.getElementById("searchnote").value;
      this.setState({ Search: SearchedVal });
      this.NotesDetails();
    }
  }

  // change page
  HandleChangePage = (NewPage) => {
    this.setState({ Page: NewPage });
    this.NotesDetails();
  };

  //  handle change  notes
  HandleChangePNotes(Field, e) {
    let Fields = this.state.Fields;
    Fields[Field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Title != "") {
      this.state.Errors.Title = null;
      this.state.TitleAvailable = null;
      this.NoteTitleCheckExist(e.target.value);
    } else {
      this.state.TitleAvailable = null;
      this.state.Errors.Title = null;
    }
  }

  //delete  note id
  NoteDelete(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a Client potential note.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        var PNoteDelete = {
          _id: id,
          LastUpdatedDt: new Date(),
          LastUpdatedBy: this.state.CUserID,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/potentialmeeting/DeletePotentialNotesMeeting",
          method: "POST",
          data: PNoteDelete,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Account note deleted successfully.",
                "success"
              );
              history.push("/viewclientpotentialmeetings");
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  }

  // find check exits title
  NoteTitleCheckExist(Title) {
    var ProspectNote = {
      ClientID: this.state.ClientID,
      Title: Title,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/potentialmeeting/NotesMeetingStatusExists",
      method: "POST",
      data: ProspectNote,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        if (res.data.Data.length > 0) {
          this.setState({ TitleAvailable: res.data.Data[0].Title });
        }
      }
    });
  }

  // save  note
  AccountNoteSave() {
    var Title = document.getElementById("TitlePNote").value;
    var Notes = document.getElementById("NotesPNote").value;

    // if(this.FromValidation()){
    var NoteData = {
      ClientID: this.state.ClientID,
      UserID: this.state.UserID,
      MeetingID: this.props.location.state,
      Title: Title,
      Note: Notes,
      CreatedBy: this.state.CUserID,
      CreatedDate: new Date(),
      LastUpdatedBy: null,
      LastUpdatedDate: null,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/potentialmeeting/AddPotentialNotesMeeting",
      method: "POST",
      data: NoteData,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        toast.success("Account note add successfully.", "Add Note ");
      } else {
        toast.error(res.data.Message);
      }
    });
    // }
  }

  // find id by record  edit
  AccountNoteEditBtn(id) {
    this.setState({ ids: id });
    var AccountNoteEdit = {
      id: id,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/potentialmeeting/PotentialMeetingNotesByID",
      method: "POST",
      data: AccountNoteEdit,
    }).then((res) => {
      document.getElementById("TitleEditNote").value = res.data[0].Title;
      document.getElementById("NotesEditPNotes").value = res.data[0].Note;
    });
  }

  // note update
  AccountNoteUpdate() {
    var Title = document.getElementById("TitleEditNote").value;
    var Notes = document.getElementById("NotesEditPNotes").value;

    var NoteUpdateData = {
      _id: this.state.ids,
      Title: Title,
      Note: Notes,
      LastUpdatedBy: this.state.CUserID,
      LastUpdatedDate: new Date(),
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/potentialmeeting/PotenialNotesMeetingUpdate",
      method: "POST",
      data: NoteUpdateData,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        toast.success(
          "Note Status update successfully.",
          "Update Account Status"
        );
      } else {
        toast.error(res.data.Message);
      }
    });
  }

  // To set default
  HandleChangeOwner(e) {
    this.setState({ DefaultMeetingOwner: e.target.DefaultMeetingOwner });
  }
  HandleChangeAccountStatus(e) {
    this.setState({
      DefaultEnggamentStatus: e.target.DefaultEnggamentStatus,
    });
  }
  HandleChangeSource(e) {
    this.setState({
      DefaultSource: e.target.DefaultSource,
    });
  }

  HandleChangeReminderEmail(e) {
    console.log(e.target.value);
    this.setState({
      DefaultReminderEmail: e.target.DefaultReminderEmail,
    });
  }

  SaveBtn() {
    var FirstName = document.getElementById("FirstName").value;
    var LastName = document.getElementById("LastName").value;
    var Title = document.getElementById("Title").value;
    var Company = document.getElementById("Company").value;
    var Email = document.getElementById("Email").value;
    var LinkedInURL = document.getElementById("LinkedInURL").value;
    var DirectPhone = document.getElementById("DirectPhone").value;
    var CompanyPhone = document.getElementById("CompanyPhone").value;
    var MeetingSourceID = document.getElementById("MeetingSourceID").value;
    var MeetingOwnerID = document.getElementById("MeetingOwnerID").value;
    var EngagementStatusID =
      document.getElementById("EngagementStatusID").value;
    var ReminderEmailAccount = document.getElementById("ClientPOCID").value;

    if (FirstName == "") {
      if (FirstName == "") {
        document.getElementById("FirstName_error").style.display = "block";
      }
      if (LastName == "") {
        document.getElementById("LastName_error").style.display = "block";
      }
      if (Title == "") {
        document.getElementById("Title_error").style.display = "block";
      }
      if (Company == "") {
        document.getElementById("Company_error").style.display = "block";
      }
      if (Email == "") {
        document.getElementById("Email_error").style.display = "block";
      }
    } else {
      var data = {
        _id: this.props.location.state,
        FirstName: FirstName,
        LastName: LastName,
        Email: Email,
        Title: Title,
        Company: Company,
        LinkedInUrl: LinkedInURL,
        DirectPhone: DirectPhone,
        CompanyPhone: CompanyPhone,
        EngagamentStatusID: EngagementStatusID,
        MeetingSourceID: MeetingSourceID,
        MeetingOwnerID: MeetingOwnerID,
        ReminderEmailAccountID: ReminderEmailAccount,
        Domain: Email.substring(Email.lastIndexOf("@") + 1),
        SalesReply_MessageID: null,
        ConversionCampaignID: null,
        ResponderColdCallersType: null,
        ResponderColdCallersID: null,
        ProspectLastReply: null,
        IsDeleted: false,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
        LastUpdatedBy: this.state.CUserI,
        LastUpdatedDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/potentialmeeting/PotentialMeetingUpdate",
        method: "POST",
        data: data,
      }).then((res) => {
        console.log(res);
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            "Potential meeting Status update successfully.",
            "Update Potential meeting Status"
          );
          history.push("/clientpotentialmeetings");
        } else {
          toast.error(res.data.Message);
        }
      });
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
        <Sidebar className="" /> 
          <div className="bodyhome"> 
              <div className="row">
                <div className="col-md-4 col-sm-4 padd-0 d-flex align-items-center">
                  <h3 className=" float-left addpotential_header py-3">
                  Client Edit Potential Meeting	
                  </h3>
                </div>
                <div className="col-md-8 col-sm-8 text-right py-3">
                  <a className="btn btngroup m-btn m-btn--custom mr-1">
                    <i className="la la-exchange"></i>
                    <span className="Mobile_button"> Convert</span>
                  </a>

                  <a
                    onClick={() => {
                      this.SaveBtn();
                    }}
                    className="btn btngroup m-btn m-btn--custom btn-fill mr-1"
                  >
                    <i className="la flaticon-edit-1 "></i>
                    <span className="Mobile_button"> Save</span>
                  </a>
                  <a
                    className="btn btngroup2 m-btn m-btn--custom"
                    onClick={this.backbtn}
                  >
                    <i className="la la-arrow-circle-left"></i>
                    <span className="Mobile_button"> Back</span>
                  </a>
                </div>
              </div>
            <div class="paddcols">
              {this.state.AllRes?.map((value) => (
                <div className="row pt-4">
                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">Client</label>
                      <div class="col-lg-8">
                        <select
                          className="form-control "
                          name="Client"
                          defaultValue={this.state?.ClientID}
                          id="ClientPotentialID"
                          disabled
                        >
                          {this.state.AllClientList.map((value) => (
                            <option value={value.ClientID}>{value.Name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6"></div>
                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">
                        First Name{" "}
                      </label>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="FirstName"
                          name="FirstName"
                          placeholder="Enter first name"
                          defaultValue={value?.FirstName}
                          onChange={this.handleChange.bind(this, "FirstName")}
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["FirstName"]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">Last Name</label>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter first name"
                          id="LastName"
                          name="LastName"
                          defaultValue={value?.LastName}
                          onChange={this.handleChange.bind(this, "LastName")}
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["LastName"]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">Title</label>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Title"
                          id="Title"
                          name="Title"
                          defaultValue={value?.Title}
                          onChange={this.handleChange.bind(this, "Title")}
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["Title"]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">Company</label>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Company"
                          id="Company"
                          name="Company"
                          defaultValue={value?.Company}
                          onChange={this.handleChange.bind(this, "Company")}
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["Company"]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">Email</label>
                      <div class="col-lg-8">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-regex="Invalid email."
                          data-val-remote="Email already exist."
                          data-val-remote-additionalfields="*.Email,*.PageName,*.CampaignID,*.ClientID,*.UserID"
                          data-val-remote-url="/Utility/IsProspectEmailExistsAndBlacklist"
                          data-val-required="Please enter email."
                          id="Email"
                          name="Email"
                          type="text"
                          defaultValue={value?.Email}
                          onChange={this.handleChange.bind(this, "Email")}
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["Email"]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">
                        LinkedIn Url
                      </label>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="LinkedIn Url"
                          id="LinkedInURL"
                          name="LinkedInURL"
                          defaultValue={value?.LinkedInUrl}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">
                        Meeting Source{" "}
                      </label>
                      <div class="col-lg-8">
                        <select
                          className="form-control "
                          id="MeetingSourceID"
                          name="MeetingSourceID"
                          value={this.state.DefaultSource}
                          onChange={this.HandleChangeSource}
                        >
                          {this.state.PotentialSourceList.map((val) => (
                            <option value={val.MeetingSourceID}>
                              {val.Name}
                            </option>
                          ))}
                        </select>
                        {/* <span style={{ color: "red" }}>
                          {this.state.errors["MeetingSourceID"]}
                        </span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">Owner</label>
                      <div class="col-lg-8">
                        <select
                          className="form-control "
                          id="MeetingOwnerID"
                          name="MeetingOwnerID"
                          value={this.state.DefaultMeetingOwner}
                          onChange={this.HandleChangeOwner}
                        >
                          {this.state.PotentialOwnerList.map((val) => (
                            <option value={val.MeetingOwnerID}>
                              {val.Name}
                            </option>
                          ))}
                        </select>
                        {/* <span style={{ color: "red" }}>
                          {this.state.errors["MeetingOwnerID"]}
                        </span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">
                        Reminder Email Account
                      </label>
                      <div class="col-lg-8">
                        <select
                          className="form-control "
                          id="ClientPOCID"
                          name="ClientPOCID"
                          value={this.state.DefaultReminderEmail}
                          onChange={this.HandleChangeReminderEmail}
                        >
                          {this.state.PotentialAccountList.map((val) => (
                            <option value={val.AccountID}>
                              {val.SMTPEmail}
                            </option>
                          ))}
                        </select>
                        {/* <span style={{ color: "red" }}>
                          {this.state.errors["ClientPOCID"]}
                        </span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">
                        Engagement Status
                      </label>
                      <div class="col-lg-8">
                        <select
                          className="form-control "
                          id="EngagementStatusID"
                          name="EngagementStatusID"
                          value={this.state.DefaultEnggamentStatus}
                          onChange={this.HandleChangeAccountStatus}
                        >
                          {this.state.EngagementList.map((val) => (
                            <option value={val.PotentialMeetingStatusID}>
                              {val.Status}
                            </option>
                          ))}
                        </select>
                        {/* <span style={{ color: "red" }}>
                          {this.state.errors["EngagementStatusID"]}
                        </span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">
                        Direct Phone
                      </label>
                      <div class="col-lg-8">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-regex="Invalid direct phone"
                          data-val-regex-pattern="^[0-9]*$"
                          id="DirectPhone"
                          name="DirectPhone"
                          type="text"
                          defaultValue={value?.DirectPhone}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">
                        Company Phone
                      </label>
                      <div class="col-lg-8">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-regex="Invalid company phone"
                          data-val-regex-pattern="^[0-9]*$"
                          id="CompanyPhone"
                          name="CompanyPhone"
                          type="number"
                          defaultValue={value?.CompanyPhone}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Notes */}
        <div className="bodyhome1">
          <div className="paddcols px-4">
            <div className="row p-3 pb-0">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">Notes</h3>
              </div>
              <div className="col text-right">
                <Popup
                  trigger={
                    <button className="btn btngroup m-btn ">
                      <i class="la la-plus la-icon-font-size-13"></i>
                      <span className="Mobile_button">Add</span>
                    </button>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div>
                      <div className="modal-black"></div>
                      <div className="filterPopup largerPopup">
                        <div className="paddingboxTerms">
                          <div className="modal-header py-3 px-3">
                            <h4 className="mb-0">Note</h4>
                          </div>
                          <div className="modal-body p-5">
                            <div class="row mb-3">
                              <label class="col-lg-2 col-form-label text-left">
                                Title
                              </label>
                              <div class="col-lg-10">
                                <input
                                  class="form-control m-input"
                                  data-val="true"
                                  data-val-remote="Title already exist."
                                  data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                  data-val-required="Please enter title"
                                  id="TitlePNote"
                                  name="TitleNote"
                                  placeholder="Enter title"
                                  onBlur={this.HandleChangePNotes.bind(
                                    this,
                                    "TitlePNote"
                                  )}
                                  type="text"
                                />
                                <span style={{ color: "red" }}>
                                  {this.state.Errors["TitlePNote"]}
                                </span>
                                {this.state.TitleAvailable && (
                                  <span style={{ color: "red" }}>
                                    Title already exist.
                                  </span>
                                )}
                              </div>
                            </div>

                            <div class="row">
                              <label class="col-lg-2 col-form-label text-left">
                                Note
                              </label>
                              <div class="col-lg-10">
                                <textarea
                                  class="form-control m-input minheight390"
                                  data-val="true"
                                  data-val-remote="Title already exist."
                                  data-val-required="Please enter title"
                                  id="NotesPNote"
                                  name="NotesPNote"
                                  placeholder="Enter note"
                                  onBlur={this.HandleChangePNotes.bind(
                                    this,
                                    "NotesPNote"
                                  )}
                                  type="text"
                                >
                                  {" "}
                                </textarea>
                                <span style={{ color: "red" }}>
                                  {this.state.Errors["NotesPNote"]}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="modal-content bordernone text-right">
                            <div class="row">
                              <div class="col-lg-12 pull-right">
                                <a
                                  id="backtolist"
                                  class="btn btn-secondary mr-2"
                                  onClick={() => {
                                    close();
                                  }}
                                >
                                  cancel
                                </a>
                                <a
                                  id="submit"
                                  class="btn btn-primary btn-lightgreen mr-1"
                                  href="javascript:void(0);"
                                  value="Save"
                                  onClick={this.AccountNoteSave}
                                >
                                  <i class="la la-save"></i> Save
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Popup>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    onChange={this.ChangeRowSelected}
                    className="form-control form-control-sm"
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="searchnote"
                    onKeyPress={(event) => this.RequestSearch(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Notes</TableCell>
                      <TableCell>Created Date</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.Rows?.map((row) =>
                      row.PotentialMeetingID != null ? (
                        <TableRow>
                          <TableCell>{row.Title}</TableCell>
                          <TableCell>{row.Note}</TableCell>
                          <TableCell>
                            {moment(
                              new Date(row.CreatedDate).toDateString()
                            ).format("MM/DD/YYYY")}
                          </TableCell>
                          <TableCell>
                            <a
                              onClick={() => {
                                this.AccountNoteEditBtn(
                                  row._id
                                );
                              }}
                            >
                              <Popup
                                trigger={
                                  <i class="la flaticon-edit-1 edit-icon"></i>
                                }
                                modal
                                nested
                              >
                                {(close) => (
                                  <div>
                                    <div className="modal-black"></div>
                                    <div className="filterPopup largerPopup">
                                      <div className="paddingboxTerms">
                                        <div className="modal-header py-3 px-3">
                                          <h4 className="mb-0">Note</h4>
                                        </div>
                                        <div className="modal-body p-5">
                                          <div class="row mb-3">
                                            <label class="col-lg-2 col-form-label text-left">
                                              Title
                                            </label>
                                            <div class="col-lg-10">
                                              <input
                                                class="form-control m-input"
                                                data-val="true"
                                                data-val-remote="Title already exist."
                                                data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                                data-val-required="Please enter title"
                                                id="TitleEditNote"
                                                name="TitleEditNote"
                                                placeholder="Enter title"
                                                type="text"
                                                onChange={this.HandleChangePNotes.bind(
                                                  this,
                                                  "TitleEditNote"
                                                )}
                                                value={
                                                  this.state.Fields[
                                                    "TitleEditNote"
                                                  ]
                                                }
                                              />
                                              <span style={{ color: "red" }}>
                                                {
                                                  this.state.Errors[
                                                    "TitleEditNote"
                                                  ]
                                                }
                                              </span>
                                              {this.state.TitleAvailable && (
                                                <span style={{ color: "red" }}>
                                                  Title already exist.
                                                </span>
                                              )}
                                            </div>
                                          </div>

                                          <div class="row">
                                            <label class="col-lg-2 col-form-label text-left">
                                              Note
                                            </label>
                                            <div class="col-lg-10">
                                              <textarea
                                                class="form-control m-input minheight390"
                                                data-val="true"
                                                data-val-remote="Title already exist."
                                                data-val-required="Please enter title"
                                                id="NotesEditPNotes"
                                                name="NotesEditPNotes"
                                                placeholder="Enter note"
                                                type="text"
                                                onChange={this.HandleChangePNotes.bind(
                                                  this,
                                                  "NotesEditPNotes"
                                                )}
                                                value={
                                                  this.state.Fields[
                                                    "NotesEditPNotes"
                                                  ]
                                                }
                                              >
                                                {" "}
                                              </textarea>
                                              <span style={{ color: "red" }}>
                                                {
                                                  this.state.Errors[
                                                    "NotesEditPNotes"
                                                  ]
                                                }
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="modal-content bordernone text-right">
                                          <div class="row">
                                            <div class="col-lg-12 pull-right">
                                              <a
                                                id="backtolist"
                                                class="btn btn-secondary mr-2"
                                                onClick={() => {
                                                  close();
                                                }}
                                              >
                                                cancel
                                              </a>
                                              <a
                                                id="submit"
                                                class="btn btn-primary btn-lightgreen mr-1"
                                                href="javascript:void(0);"
                                                value="Save"
                                                onClick={this.AccountNoteUpdate}
                                              >
                                                <i class="la la-save"></i> Save
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Popup>
                            </a>
                            <a
                              className="btn-eyesicon"
                              onClick={() => {
                                this.NoteDelete(row.PotentialMeetingNoteID);
                              }}
                            >
                              <i class="la flaticon-delete-1 delete-icon"></i>
                            </a>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <></>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.Sflag ? (
              <div class="row">
                <Pagination
                  component="div"
                  count={this.state.CountPage}
                  onChange={this.HandleChangePage}
                  showFirstButton
                  showLastButton
                />
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.Rlen == 0
                      ? 0
                      : (this.state.Page - 1) * this.state.RowsPerPage + 1}{" "}
                    to{" "}
                    {this.state.Page * this.state.RowsPerPage > this.state.Rlen
                      ? this.state.Rlen
                      : this.state.Page * this.state.RowsPerPage}{" "}
                    of {this.state.Rlen} entries (filtered from{" "}
                    {this.state.Flen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.Rlen == 0
                      ? 0
                      : (this.state.Page - 1) * this.state.RowsPerPage + 1}{" "}
                    to{" "}
                    {this.state.Page * this.state.RowsPerPage > this.state.Rlen
                      ? this.state.Rlen
                      : this.state.Page * this.state.RowsPerPage}{" "}
                    of {this.state.Rlen} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                    count={this.state.CountPage}
                    onChange={this.HandleChangePage}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditClientPotentialMeetingsPage = connect(
  mapState,
  actionCreators
)(EditClientPotentialMeetingsPage);
export { connectedEditClientPotentialMeetingsPage as EditClientPotentialMeetingsPage };
