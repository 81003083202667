import React, { useEffect, useState } from "react";
import Axios from "axios";
import Popupone from "reactjs-popup";
import Popup from "reactjs-popup";
var CryptoJS = require("crypto-js");
import Swal from "sweetalert2";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Alert } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";

import { CommonConstants } from "../../../_constants/common.constants";
const EmailTemplete = require("../../../_helpers/email-templete");
import loadingicon from "../../../images/loading.gif";
import{GetUserDetails} from '../../../_helpers/Utility';
import Pagenations from "../../Pagenations";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';


const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AdminInviteTable({listupdate}) {
  const Details= GetUserDetails();
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDt");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Reload, SetReload] = React.useState(false)
  const [InvitePopup, SetInvitePopup] = React.useState(false)
  const [RID, SetRID] = React.useState(null)
  const [Email, SetEmail] = React.useState(null);
  const [AlertBox, SetAlertBox] = useState(false);
  const [IsLoading,SetIsLoading] = React.useState(false);
  const [CUserID,SetCUserID] = React.useState(0)
  const [sortedColumn, setSortedColumn] = React.useState(null);
  
  useEffect( () => {
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
      
    }
    AdminGetMethod(GetUserData.ClientID,GetUserData.ParentUserID,GetUserData.Role);
  }, [Search, Page, RowsPerPage,Reload,listupdate]);


  //get admin list
  const AdminGetMethod = (CID,UID,URole) => {
    SetIsLoading(true)
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const AdminListAPI = Axios({
      url:
        CommonConstants.MOL_APIURL + "/AdminUser/AdminInviteListGet",
      method: "POST",
      data: InputParameter,
    });
    AdminListAPI.then((Result) => {
      
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)
    });
  };

 
  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value?.trim();
      if(SearchedVal==Search){
        SetSearch(SearchedVal);
        SetPage(1);
      }else{
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if(NewPage==Page){
      SetPage(NewPage);
    }else{
        SetPage(NewPage);
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true)

    var SearchedVal = document.getElementById("search").value?.trim();
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        Search:SearchedVal
      };
      const AdminListAPI = Axios({
        url:CommonConstants.MOL_APIURL +"/AdminUser/AdminInviteListGet",
        method: "POST",
        data: InputParameter,
      });
      AdminListAPI.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
      SetIsLoading(false)


      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search:SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const AdminListAPI = Axios({
        url:CommonConstants.MOL_APIURL +"/AdminUser/AdminInviteListGet",
        method: "POST",
        data: InputParameter,
      });
      AdminListAPI.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
      SetIsLoading(false)


      });
    }
  };

  const OpenReInvitePopup = (Emaildata, ID) => {
    SetEmail(Emaildata);
    SetRID(ID);
    SetInvitePopup(true);
  };

  const CloseReInvitePopup = () => {
    SetInvitePopup(false);
    SetEmail("");
  };

  //resend invite
  const SubmitReInvitePopup = () => {
    var Email = document.getElementById("change_mail").value;

    let VerificationCodeChar = (Math.random() + 1)
    .toString(36)
    .substring(7);

var re =
/(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
var EmailFlg = re.test(Email);
if (EmailFlg == true) {
  var FinalEmail = CryptoJS.AES.encrypt(
    JSON.stringify(Email),
    "my-secret-key@123"
  ).toString();
  var UID = CryptoJS.AES.encrypt(
    JSON.stringify(UserID),
    "my-secret-key@123"
  ).toString();
  var AUVID = CryptoJS.AES.encrypt(
    JSON.stringify(RID),
    "my-secret-key@123"
  ).toString();
  var VC = CryptoJS.AES.encrypt(
    JSON.stringify(VerificationCodeChar),
    "my-secret-key@123"
  ).toString();
  
  var EmailData = EmailTemplete.AdminUserEmailInvite(
    FinalEmail,
    UID,
    AUVID,
    VC,
    CommonConstants.admin_user
  );

  var str_mail = {
    Email: Email,
    html: EmailData,
    subject: "SalesHive User Account",
  };
  Axios({
    url: CommonConstants.MOL_APIURL + "/user/profileemail",
    method: "POST",
    data: str_mail,
  }).then((res) => {
    if (res) {
      var str_mail = {
        ID: RID,
        VC: VerificationCodeChar,
        
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/AdminUser/ResendInvitation",
        method: "POST",
        data: str_mail,
      }).then((res) => {
        
        SetInvitePopup(false);
      SetRID("");
      SetEmail("");
      toast.success(<div>Admins <br/>
                               Invitation mail sent successfully.</div>)
      // SetAlertBox(true);
      })
      
    }
  });
   
        SetInvitePopup(false);
        SetRID("");
        SetEmail("");
        // SetAlertBox(true);
     
  }
}



  const AlertBoxhandleClose = () => {
    SetAlertBox(false);
  };

 //delete new code
 const DeleteBtn = (ID) => {
  Swal.fire({
    title: "Are you sure?",
    text: "you want to delete an admin invitation.",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#34bfa3",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, cancel!",
    reverseButtons: true,
  }).then((Result) => {
    if (Result.isConfirmed) {
      var data = {
        AdminUserInvitationID: ID,
        LastUpdatedDate: new Date(),
        LastUpdatedBy: CUserID,
        Role: Role,
      };
      Axios({
        url:CommonConstants.MOL_APIURL +"/AdminUser/InviteDelete",
        method: "POST",
        data: data,
      }).then((Res) => {
        if (Res) {
          if ((Res.statusText = "Ok")) {
            Swal.fire(
              "Deleted!",
              "Admins invitation deleted successfully.",
              "success"
            );
            AdminGetMethod(ClientID,UserID,Role);
            SetPage(1)
            SetSearch("")
            document.getElementById("search").value = ""
         
           
          } else {
          }
        }
      });
    } else if (Result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled", "Your data is safe :)", "error");
    }
  });
};



  return (
    <div>
      {
        IsLoading ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : <></>
      }
      
      <Snackbar
        className="erorrheader"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={AlertBox}
        autoHideDuration={6000}
        onClose={AlertBoxhandleClose}
      >
        <Alert variant="filled" className="erorrheader">
          Admin 
          <br /> Invitation mail send successfully.
        </Alert>
      </Snackbar>
    
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              Type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell onClick={() => {SortData("Email"); setSortedColumn("Email");}}>
                    Email
                    <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "Email" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "Email" ? "active" : null} />
                  </span>
                  
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            { Rows.length == 0 ? <p className="text-center">No data available in table</p> :
              Rows?.map((row) => (
                <TableRow key={row?._id}>
                  <TableCell>{row.Email}</TableCell>
                  <TableCell>
                    <a
                      class="btn btn-sm btn-primary btnColor m-btn whitetext mr-3"
                      onClick={() => {
                        OpenReInvitePopup(row.Email, row._id);
                      }}
                      title="Reinvite"
                    >
                      Reinvite
                    </a>
                    <a
                      onClick={() => {
                        DeleteBtn(row._id);
                      }}
                      className="btn-eyesicon"
                      title="Delete"
                    >
                      <i class="la flaticon-delete-1 delete-icon"></i>
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Popupone open={InvitePopup} modal>
        <div className="modal-black"></div>
        <div className="filterPopup bigpopupcontent">
          <div className="paddingboxTerms">
            <div className="modal-header">
              <h5>Send Mail</h5>
              <a className="close-ion" onClick={CloseReInvitePopup}>
                <span aria-hidden="true" class="la la-remove"></span>
              </a>
            </div>
            <div className="modal-content bordernone">
              <div class="row max-cols">
                <label class="col-lg-4 view-lable-meet">Email</label>
                <div class="col-lg-8">
                  <input
                    class="form-control m-input"
                    data-val="true"
                    data-val-required="Please enter first name"
                    id="change_mail"
                    name="FirstName"
                    placeholder="First name"
                    Type="text"
                    value={Email}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                Type="button"
                class="btn btn-secondary"
                onClick={CloseReInvitePopup}
                data-dismiss="modal"
              >
                Cancel
              </button>
              <a
                id="m_EmailInvite_submit"
                onClick={SubmitReInvitePopup}
                href="javascript:void(0);"
                class="btn btn-primary btnColor"
              >
                Send{" "}
              </a>
            </div>
          </div>
        </div>
      </Popupone>
      <Pagenations
         Sflag={Sflag}
         CountPage={CountPage}
         HandleChangePage={HandleChangePage}
         Rlen={Rlen}
         Page={Page}
         RowsPerPage={RowsPerPage}
         Flen={Flen}
      />
    </div>
  );
}
