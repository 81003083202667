import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LastdaysTabing from "../../../_components/user/Kpis/LastdaysTabing";
import Axios from "axios";
import SubjectTable from "./SubjectTable";
import GreetingTable from "./GreetingTable";
import OpenerTable from "./OpenerTable";
import FUOpenerTable from "./FUOpenerTable";
import CTATable from "./CTATable";
import CloserTable from "./CloserTable";
import OptOutTable from "./OptOutTable";
import ProblemTable from "./ProblemTable";
import ValuePropTable from "./ValuePropTable";
import StoryTable from "./StoryTable";
import SenderTitleTable from "./SenderTitleTable";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs({daterange,updateFromChild}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  useEffect(()=>{
    document.title = `Variable Sets | SalesHive`;
    //console.log(daterange,"--1--")
  },[daterange]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="tabdes">
      <div>
        <AppBar className="col-tabs" position="relative">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example" 
          >
            <Tab label="Subject" {...a11yProps(0)}>
              Overviewasdasd
            </Tab>
            <Tab label="Greeting" {...a11yProps(1)} />
            <Tab label="Opener" {...a11yProps(2)} />
            <Tab label="FUOpener" {...a11yProps(3)} />
            <Tab label="CTA" {...a11yProps(4)} />
            <Tab label="Closer" {...a11yProps(5)} />
            <Tab label="OptOut" {...a11yProps(6)} />
            <Tab label="Problem" {...a11yProps(7)} />
            <Tab label="ValueProp" {...a11yProps(8)} />
            <Tab label="Story" {...a11yProps(9)} />
            <Tab label="Sender Title" {...a11yProps(10)} />
          </Tabs>
        </AppBar>
        
        <TabPanel value={value} className="tagpaddnone bg-white" index={0}>
          <div className="px-3">
            <div className="row">
              <div className="col"> 
                  <SubjectTable FeatureName={"Subject"} FeatureFieldID={"7"} dater={daterange} udapate={updateFromChild}/> 
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="bg-white" index={1}>
          <div className="px-3">
            <div className="row">
              <div className="col">
                  <SubjectTable FeatureName={"Greeting"} FeatureFieldID={"3"} dater={daterange} udapate={updateFromChild}/>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="bg-white" index={2}>
          <div className="px-3">
            <div className="row">
              <div className="col"> 
                  <SubjectTable FeatureName={"Opener"} FeatureFieldID={"5"} dater={daterange} udapate={updateFromChild}/> 
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="bg-white" index={3}>
          <div className="px-3">
            <div className="row">
              <div className="col">
                  <SubjectTable FeatureName={"FUOpener"} FeatureFieldID={"6"} dater={daterange} udapate={updateFromChild}/>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="bg-white" index={4}>
          <div className="px-3">
            <div className="row">
              <div className="col">
                  <SubjectTable FeatureName={"CTA"} FeatureFieldID={"1"} dater={daterange} udapate={updateFromChild}/>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="bg-white" index={5}>
          <div className="px-3">
            <div className="row">
              <div className="col">
                  <SubjectTable FeatureName={"Closer"} FeatureFieldID={"2"} dater={daterange} udapate={updateFromChild}/>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="bg-white" index={6}>
          <div className="px-3">
            <div className="row">
              <div className="col">
                  <SubjectTable FeatureName={"OptOut"} FeatureFieldID={"8"} dater={daterange} udapate={updateFromChild}/>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="bg-white" index={7}>
          <div className="px-3">
            <div className="row">
              <div className="col">
                  <SubjectTable FeatureName={"Problem"} FeatureFieldID={"9"} dater={daterange} udapate={updateFromChild}/>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="bg-white" index={8}>
          <div className="px-3">
            <div className="row">
              <div className="col">
                  <SubjectTable
                    FeatureName={"ValueProp"}
                    FeatureFieldID={"10"}
                    dater={daterange}
                  />
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="bg-white" index={9}>
          <div className="px-3">
            <div className="row">
              <div className="col">
                  <SubjectTable FeatureName={"Story"} FeatureFieldID={"11"} dater={daterange} udapate={updateFromChild}/>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} className="bg-white" index={10}>
          <div className="px-3">
            <div className="row">
              <div className="col">
                  <SubjectTable
                    FeatureName={"Sender Title"}
                    FeatureFieldID={"4"}
                    dater={daterange}
                    udapate={updateFromChild}
                  />
              </div>
            </div>
          </div>
        </TabPanel>
      </div>
    </div>
  );
}
