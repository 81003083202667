import React, { useEffect } from "react";
import Axios from "axios";
import Popup from "reactjs-popup";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";


import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { makeStyles } from "@material-ui/core/styles";

import { history } from "../../_helpers";
import { CommonConstants } from "../../_constants/common.constants";
import loadingicon from "../../images/loading.gif";
import { GetUserDetails } from '../../_helpers/Utility';
import { Tooltip } from "@mui/material";
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const imgstyle = {
  height: "40px",
  width: "40px",
  borderradius: "50%",
};

export default function AccountAllTable() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("ClientName");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [ChildUserID, SetChildUserID] = React.useState(0);
  const [ParentUserID, SetParentUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [AddBurnerModel, SetAddBurnerModel] = React.useState(false);
  const [ClientList, SetClientList] = React.useState([])
  const [ColdCallerID, SetColdCallerID] = React.useState("");
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);
  const [isLoadingPB, setisLoadingPB] = React.useState(false);
  const [sortedColumn, setSortedColumn] = React.useState(null);

  useEffect(() => {

    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetChildUserID(GetUserData.ChildUserID);
      SetParentUserID(GetUserData.ParentUserID);
      SetRole(GetUserData.Role);
    }
    GetClientList(GetUserData.Role, GetUserData.ChildUserID, GetUserData.ParentUserID)
    FetchDetailMethod(GetUserData.ChildUserID, GetUserData.ParentUserID, GetUserData.Role)
    GetColdCallerID(GetUserData.Username);
  }, []);

  useEffect(() => {

    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetChildUserID(GetUserData.ChildUserID);
      SetParentUserID(GetUserData.ParentUserID);
      SetRole(GetUserData.Role);
    }
    FetchDetailMethod(GetUserData.ChildUserID, GetUserData.ParentUserID, GetUserData.Role)
  }, [RowsPerPage,Search,Page]);


  const GetColdCallerID = async (Email) => {
    
    var InputParameter = {
      Email: Email
    }
    const result = await Axios({
      url: CommonConstants.MOL_APIURL + "/cold_callers_profile/GetColdCallerByEmail",
      method: "POST",
      data: InputParameter
    });
    
    if (result.data.StatusMessage == "SUCCESS") {
      if (result.data.Data != undefined) {
        SetColdCallerID(result.data?.Data?.ColdCallerID)
      }
    }

  }
  const GetClientList = async (Role, CUID, PUID) => {
    var ObjData = {
      "Role": Role,
      "ChildUserID": CUID,
      "ParentUserID": PUID,
    }
    const result = await Axios({
      url: CommonConstants.MOL_APIURL + "/client/GetClientListForTopDropDown",
      method: "POST",
      data: ObjData
    });
    if (result.data.StatusMessage == "SUCCESS") {
      if (result.data.Data.length > 0) {
        SetClientList(result.data.Data)
      }
    }

  }

  // Get Clients list
  const FetchDetailMethod = (CUID, PUID, URole) => {
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ColdCallerUserID: CUID,
      UserID: PUID,
      Role: URole,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/Utility/GetPhoneBurnerUserList",
      method: "POST",
      data: InputParameter,
    });
    rows1.then((result) => {
      if (result.data.StatusMessage == "SUCCESS") {
        SetData(result.data.PageData);
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
      }

      setisLoadingPB(false)
    });
  };
  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("Search").value;
      SetSearch(SearchedVal);
      SetPage(1);
      setisLoadingPB(true)
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    setisLoadingPB(true)
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      setisLoadingPB(true)
    }
  };

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("Search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {

        Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ColdCallerUserID: ChildUserID,
      UserID: ParentUserID,
      Role: Role,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/Utility/GetPhoneBurnerUserList",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {

        Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ColdCallerUserID: ChildUserID,
      UserID: ParentUserID,
      Role: Role,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/Utility/GetPhoneBurnerUserList",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
      });
    }
  };

  //Delete record of phone burner
  //delete action item
  const DeleteAuths = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a cold caller phoneburner login.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then((Result) => {
      if (Result.isConfirmed) {
        var InputData = {
          ID: ID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: ChildUserID,
          Role: Role

        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/login/DeletePhoneBurner",
          method: "POST",
          data: InputData,
        }).then((Res) => {
          if (Res) {
            if ((Res.data.StatusMessage = "SUCCESS")) {
              Swal.fire("Deleted!","Cold caller phoneburner login deleted successfully.","success");
              // setisLoadingPB(true)
              SetSearch("")
              SetPage(1)
              FetchDetailMethod(ChildUserID, ParentUserID, Role);
             // document.getElementById("search").value = ""

            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };
  const ColdCallerListExists = async (cID) =>{
    
    var InputParameter = {
      ClientsID:cID,
      ColdCallerID:ColdCallerID,
      Role:Role
    }
    var Result  = await Axios({url: CommonConstants.MOL_APIURL + "/login/CheckPhoneBurnerExists",method:"POST",data:InputParameter})
    if(Result.data?.StatusMessage === "SUCCESS"){
      return Result.data?.data?.CheckDataFlage;
    }else{
      return false
    }
  }
  const Authenticatebtn = async () => {
    
    setIsButtonDisabled(true);
    let Clientid = document.getElementById("clientIddropdown").value;
    var IsExists = await ColdCallerListExists(Clientid);
    if(IsExists=== true){

      toast.error(
        <div className="toastsize">
          Sales Development Reps Phone Burner Login
          <br />
          Pair already exist.
        </div>
      );
      setIsButtonDisabled(false);
      }else{
        if (Clientid != "") {
          var Details = GetUserDetails();
          var InputParameter = {
            UserID: Details.ParentUserID,
            ClientID: Clientid,
            ColdCallerID: ColdCallerID,
            IsAuthFromOldPlatform:false,
            IsAuthenticated:false,
            redirectPage: "Account",
          };

          Axios({
            url:
              CommonConstants.MOL_APIURL + "/Utility/GetPhoneBurnerAuthCode",
            method: "POST",
            data: InputParameter,
            withCredentials: true
          }).then((res) => {
            if (res.data.StatusCode == "Valid") {
              window.location.href = res.data.Url
            }
          });
        } else {
          alert("First select the client")
        }
      }
  }

  const ReAuthenticatebtn = (objClientID,IsAuthFromOldPlatform,IsAuthenticated) => {
      var Details = GetUserDetails();
      
      var InputParameter = {
        UserID: Details.ParentUserID,
        ClientID: objClientID,
        ColdCallerID: ColdCallerID,
        IsAuthFromOldPlatform:IsAuthFromOldPlatform,
        IsAuthenticated:IsAuthenticated,
        redirectPage: "Account",
      };

      Axios({
        url:
          CommonConstants.MOL_APIURL + "/Utility/GetPhoneBurnerAuthCode",
        method: "POST",
        data: InputParameter,
        withCredentials: true
      }).then((res) => {
        if (res.data.StatusCode == "Valid") {
          window.location.href = res.data.Url
        }
      });
   
  }

  return (
    <>
   {isLoadingPB ? (
          <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div>
        ) : (
          <></>
        )}

      <div className="bodyhome1 ">
        <div className="paddcols">
          <div className="row pt-2">
            <div className="col d-flex align-items-center">
              <h3 className=" float-left pb-4 addpotential_header">Phone burner</h3>
            </div>
            <div className="col text-right">
              <Tooltip title="Add Pairs"><button
                onClick={() => SetAddBurnerModel(true)}
                className="btn btngroup m-btn"
              >
                <i class="la la-plus la-icon-font-size-13"></i>
              </button></Tooltip>
              <Popup open={AddBurnerModel}>
                <div>
                  <div className="modal-black"></div>
                  <div className="filterPopup largerPopup-767">
                    <div className="paddingboxTerms">
                      <div className="modal-header py-4 px-4">
                        <h5 className="mb-0">Authentication</h5>
                      </div>
                      <div className="modal-body p-5">
                        <div class="row mb-3 col-lg-8">
                          <label class="col-lg-2 col-form-label text-left">
                            Client
                          </label>
                          <div class="col-lg-10">
                            <select class="form-control m-input" id='clientIddropdown'>
                              {ClientList?.map((row) => (
                                <option value={row.ClientID}>{row.Name}</option>
                              ))}
                            </select>

                          </div>
                        </div>

                      </div>
                      <div className="modal-content bordernone text-right border-top pb-4">
                        <div class="row">
                          <div class="col-lg-12 pull-right">
                            <a
                              id="backtolist"
                              class="btn btn-secondary mr-2"
                              onClick={() => SetAddBurnerModel(false)}
                            >
                              cancel
                            </a>
                            <button
                              id="submitSave"
                              class="btn btn-primary btn-lightgreen mr-1"
                              href="javascript:void(0);"
                              value="Save"
                              disabled={isButtonDisabled}
                              onClick={Authenticatebtn}
                            >
                              <i class="la la-save"></i> Authenticate
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Popup>
            </div>
          </div>
          <div className="row padt-25">
            <div className="col-sm-12 col-md-6">
              <label className="textlabelte">
                Show
                <select
                  name="tbl_meeting_length"
                  onChange={ChangeRowSelected}
                  aria-controls="tbl_meeting"
                  className="form-control form-control-sm"
                  value={RowsPerPage}
                >
                  {CommonConstants.show_rows.map((value) => (
                    <option key={value} value={value}>{value}</option>
                  ))}
                </select>
                entries
              </label>
            </div>
            <div className="col-sm-12 col-md-6 full-right">
              <label className="textlabelte">
                Search:{" "}
                <input
                  type="Search"
                  id="Search"
                  onKeyPress={RequestSearch}
                  className="form-control form-control-sm ml-2"
                  placeholder=""
                  aria-controls="tbl_meeting"
                />
              </label>
            </div>
          </div>
          <div className="table-bordered">
            <TableContainer component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>

                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell onClick={()=>{SortData("UserName"); setSortedColumn("UserName");}}>Client<span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "UserName" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "UserName" ? "active" : null} />
                    </span></TableCell>
                    <TableCell onClick={()=>{SortData("UserEmail"); setSortedColumn("UserEmail");}}>User Email<span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "UserEmail" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "UserEmail" ? "active" : null} />
                    </span></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Rows.length == 0 ? <p className="text-center">No data in Available</p> :
                    Rows?.map((row) => (
                      
                      <TableRow key={row?._id}>
                        <TableCell>  {row.coldcallerphoneburnerlogins.IsAuthenticated == true ? (
                          
                          <>
                            <a id="submit"
                              className="btn btn-success btn-secondary  mr-1">
                              <i className=""></i> Working
                            </a>
                          </>) : (<><a id="submit"
                             onClick={() => ReAuthenticatebtn(row.clients._id,row.coldcallerphoneburnerlogins.IsAuthFromOldPlatform,row.coldcallerphoneburnerlogins.IsAuthenticated)}
                            className="btn btn-primary btn-lightgreen mr-1">
                            <i className=""></i> Authenticate
                          </a></>)}</TableCell>
                        <TableCell>{row.clients.Name} </TableCell>
                        <TableCell>{row.coldcallerphoneburnerlogins.UserEmail}</TableCell>
                        <TableCell><a
                          onClick={() => {
                            DeleteAuths(row.coldcallerphoneburnerlogins._id);
                          }}
                          className="btn-eyesicon"
                        >
                          <i class="la flaticon-delete-1 delete-icon"></i>
                        </a></TableCell>

                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
{/* 
          {Sflag ? (
            <div className="row">
              <Pagination
                component="div"
                count={CountPage}
                onChange={HandleChangePage}
                page={Page}
                showFirstButton
                showLastButton
              />
              <div className="col dataTables_info">
                <p>
                  Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                  {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
                  entries (filtered from {Flen} total entries)
                </p>
              </div>
            </div>
          ) : ( */}
            <div className="row">
              <div className="col dataTables_info">
                <p>
                  Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                  {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
                  entries
                </p>
              </div>
              <div className="col pageright">
                <Pagination
                  component="div"
                  count={CountPage}
                  onChange={HandleChangePage}
                  page={Page}
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          {/* )} */}


        </div>
      </div>

    </>

  );
}
