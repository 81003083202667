import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar'; 
import Footer from '../../_components/user/footer/footer'; 
import ClientVariableTabing from '../../_components/clients/variable/VariableTabing.js';
import Popupone from "reactjs-popup";
const moment = require("moment");
import { GetUserDetails } from "../../_helpers/Utility";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import $ from "jquery";
toast.configure();

class CVariablePage extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          ClientID: null,
          UserID: null,
          CUserID: null,
          StartDate: null,
          EndDate: null,
          exportdata: {
            Start: moment(new Date().setMonth(new Date().getMonth() - 3)).format(
              "YYYY/MM/DD"
            ),
            End: moment(new Date()).format("YYYY/MM/DD"),
          },
          pageData: null,
        };
        this.daterefect = React.createRef()
        this.daterefect2 = React.createRef()
        this.DateAnalize = this.DateAnalize.bind(this);
      }
      componentDidMount() {
        var Details = GetUserDetails();
        if (Details != null) {
          this.state.ClientID = Details.ClientID;
          this.state.UserID = Details.ParentUserID;
          this.state.CUserID = Details.ChildUserID;
        }
        var d = new Date();
        d.setMonth(d.getMonth() - 1);
        var start = moment(d).format("YYYY-MM-DD");
        var d1 = new Date();
        var end = moment(d1).format("YYYY-MM-DD");
        this.setState({ StartDate: start });
        this.setState({ EndDate: end });
        const currentDate = new Date();

    const previousMonthDate = new Date(currentDate.setMonth(currentDate.getMonth() - 3));
    
    const formattedDate = previousMonthDate.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    });
    
    // Set the value of the "from-date" input field
    document.getElementById("from-date").value = formattedDate;
        const $datepickerElement = $(this.daterefect.current); 
    $datepickerElement.datetimepicker({
        format: 'mm/dd/yyyy',
        autoclose: true,
        container: '#app',
        todayBtn: true,
        todayHighlight: true,
        minView: '2',
    });
    document.getElementById("to-date").value = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
    const $datepickerElement2 = $(this.daterefect2.current); 
    $datepickerElement2.datetimepicker({
        format: 'mm/dd/yyyy',
        autoclose: true,
        container: '#app',
        todayBtn: true,
        todayHighlight: true,
        minView: '2',
    });
    var d = new Date();
    d.setMonth(d.getMonth() - 3);
    var start = moment(d).format("YYYY/MM/DD");
    var d1 = new Date();
    var end = moment(d1).format("YYYY/MM/DD");
        this.setState({
          exportdata: {
            Start: start,
            End: end,
          },
        });
      }
      DateAnalize() {
        var tempfromdate = document.getElementById("from-date").value;
        var temptodate = document.getElementById("to-date").value;
        var fromdate = moment(tempfromdate).format("YYYY/MM/DD");
        var todate = moment(temptodate).format("YYYY/MM/DD");
        if(fromdate > todate){
          toast.error("Please enter valid date");
          return
        }
        this.setState({
          exportdata: {
            Start: fromdate,
            End: todate,
          },
        });
      }
    
      //Reload page while delete perform
      UpdateFromChild = (value) => {
        if (value == true) {
          this.componentDidMount();
        }
      };
      render() {
        return (
          <> 
            <Popupone open={this.state.InvitePopup} modal>
              <div className="modal-black"></div>
              <div className="filterPopup bigpopupcontent">
                <div className="paddingboxTerms">
                  <div className="modal-header">
                    <h5>Send Mail</h5>
                    <a className="close-ion" onClick={this.closeInvitePopup}>
                      <span aria-hidden="true" class="la la-remove"></span>
                    </a>
                  </div>
                  <div className="modal-content bordernone">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">First Name</label>
                      <div class="col-lg-8">
                        <input
                          class="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter first name"
                          id="FirstName"
                          name="FirstName"
                          placeholder="First name"
                          type="text"
                          value=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <a
                      id="m_emailInvite_submit"
                      href="javascript:void(0);"
                      class="btn btn-primary btnColor"
                    >
                      Send{" "}
                    </a>
                  </div>
                </div>
              </div>
            </Popupone>
    
            <div className="adminmain mheight"> 
              <Sidebar className="" /> 
              <div className="bodyhome">
                <div className="row">
                  <div className="col">
                    <h4 className="headertitlebd px-1">Variable Sets</h4>
                  </div>
                  <div className="col padright">
                    <div className="listing-li float-right padb-15">
                    <div class="btn-group datepicker-hed">
                  <div className="col-lg-6 timedatepikker date-input">
                                <input className="form-control m-input"
                              type='text'
                              id='from-date'
                              ref={this.daterefect}
                            /> 
                             
                            </div>
                            <div className="col-lg-6 timedatepikker date-input">
                            <input className="form-control m-input"
                              type='text'
                              id='to-date'
                              ref={this.daterefect2}
                            /> 
                              </div>
                    <button
                      onClick={this.DateAnalize}
                      id="aCompanyState"
                      class="btn btn-primary btnColor btn-radius"
                      autocomplete="off"
                    >
                      <i class="la flaticon-diagram"></i>
                      <span>Analyze</span>
                    </button>
                  </div>
                    </div>
                  </div>
                </div>  
    
              <div className="listblock">
                <ClientVariableTabing
                  daterange={this.state.exportdata}
                  updateFromChild={this.UpdateFromChild}
                />
              </div>
              </div>
            </div>
                <Footer />
            </>
        );
    }

}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCVariablePage = connect(mapState, actionCreators)(CVariablePage);
export { connectedCVariablePage as CVariablePage };