import { CommonConstants } from '../_constants/common.constants';
const dotenv = require('dotenv');
const SiteName = process.env.SITENAME;
const SiteUrl = process.env.SITEURL;
const SiteFront = process.env.SITEFRONT;

// Email for Forgot password
export function userForgotpassword(forgot_token,email){
    const resetLink = CommonConstants.resetpass + "?code=" + forgot_token + "&email=" + email;
    return (`<table style="width: 100%;background: #1f1f1f;" align="center">
    <tr>
        <td style="text-align: center;" align="center">
            <table style="width: 95% !important;margin: 0 auto;text-align: inherit;" align="center">
                <tr>
                    <td>
                        <table align="center" style="padding: 0px;position: relative;">
                            <tr>
                                <td style="padding: 10px 20px 0px 0px;position: relative;padding-top: 3px;padding-bottom: 0;vertical-align: middle;">
                                    <table style="width: 280px;margin: 0 auto;">
                                        <tr>
                                            <td style="padding-top: 0;padding-bottom: 0;vertical-align: middle;">
                                                <a href="https://appv2.saleshive.com">
                                                    <img src="https://apiv2.saleshive.com/Images/SalesHiveIconsmall.png" border="0" alt="" />
                                                </a>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td style="padding: 10px 20px 0px 0px;position: relative;padding-top: 0;padding-bottom: 0;vertical-align: middle;padding-right: 0px;">
                                    <table style="width: 280px;margin: 0 auto;">
                                        <tr>
                                            <td>
                                                <table style="padding: 10px 20px 0px 0px;position: relative;float: right;" align="right">
                                                    <tr>
                                                        <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                            <a href="https://facebook.com/saleshivehq">
                                                                <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_facebook.png" alt="" />
                                                            </a>
                                                        </td>
                                                        <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                            <a href="https://twitter.com/saleshivehq">
                                                                <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_twitter.png" alt="" />
                                                            </a>
                                                        </td>
                                                        <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                            <a href="https://www.linkedin.com/company/Saleshive/">
                                                                <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_linkedin.png" alt="" />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>  `+
        "<p style='text-align:center;'><b>Here we are to help you</b></p>\n" +
        "<p style='text-align:center;'>Please click the following URL to reset your password:</p>\n" +
        "<a href='" + resetLink + "' style='text-align:center;'>" + resetLink + "</a>\n" +
        "<p style='text-align:center;'>If clicking the URL above does not work, copy and paste the URL into a browser window.</p>\n" 
        + `  <table class="page-footer" align="center">
        <tr>
            <td style="text-align: center;" align="center">
                <table class="container" align="center">
                    <tr>
                        <td>
                            <table style="padding: 0px;width: 100%;position: relative;display: block;">
                                <tr>
                                    <td class="wrapper last">
                                        <span style="font-size:12px;">
                                            <i>This is a system generated email and reply is not required.</i>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                            <table style="padding: 0px;width: 100%;position: relative;display: block;">
                                <tr>
                                    <td class="wrapper">
                                        <table class="four columns">
                                            <tr>
                                                <td style="padding-top: 0;padding-bottom: 0;vertical-align: middle;">
                                                    &copy; SalesHive 2024.
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td class="wrapper last">
                                        <table class="eight columns">
                                            <tr>
                                                <td class="vertical-middle align-reverse">
                                                    <a href="https://saleshive.com/about/">
                                                        About Us
                                                    </a>
                                                    &nbsp; <a href="https://saleshive.com/privacy/">
                                                        Privacy Policy
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>`

    );
}

// Email For password Updated
export function userPasswordUpdate() {
    return (
        "<table style='width:100%;color:#222222;font-family:Helvetica, Arial, sans-serif;font-weight:normal;padding:0;margin:0;text-align:left;line-height:1.3;font-size:14px;line-height:19px;'>" +
        "<tr>" +
        "<td style='text-align:center;' align='center' valign='top'>" +
        "<table style='width:100%;background:#1f1f1f;' align='center'>" +
        "<tr>" +
        "<td style='text-align:center;' align='center'>" +
        "<table style='width:95% !important;margin:0 auto;text-align:inherit;' align='center'>" +
        "<tr>" +
        "<td>" +
        "<table align='center' style='padding:0px;position:relative;'>" +
        "<tr>" +
        "<td style='padding:10px 20px 0px 0px;position:relative;padding-top:3px;padding-bottom:0;vertical-align:middle;'>" +
        "<table style='width:280px;margin:0 auto;'>" +
        "<tr>" +
        "<td style='padding-top:0;padding-bottom:0;vertical-align:middle;'>" +
        "<a href='https://dev.saleshive.com/'>" +
        "<img src='https://dev.saleshive.com/Content/images/SalesHiveIconsmall.png' border='0' alt='' />" +
        "</a>" +
        "</td>" +
        "</tr>" +
        "</table>" +
        "</td>" +
        "<td style='padding:10px 20px 0px 0px;position:relative;padding-top:0;padding-bottom:0;vertical-align:middle;padding-right:0px;'>" +
        "<table style='width:280px;margin:0 auto;'>" +
        "<tr>" +
        "<td>" +
        "<table style='padding:10px 20px 0px 0px;position:relative;float:right;' align='right'>" +
        "<tr>" +
        "<td style='vertical-align:middle;padding:0 2px !important;width:auto !important;'>" +
        "<a href='https://facebook.com/saleshivehq' target='_blank'>" +
        "<img src='https://dev.saleshive.com/EmailTemplate/images/social_facebook.png' />" +
        "</a>" +
        "</td>" +
        "<td style='vertical-align:middle;padding:0 2px !important;width:auto !important;'>" +
        "<a href='https://twitter.com/saleshivehq' target='_blank'>" +
        "<img src='https://dev.saleshive.com/EmailTemplate/images/social_twitter.png' />" +
        "</a>" +
        "</td>" +
        "<td style='vertical-align:middle;padding:0 2px !important;width:auto !important;'>" +
        "<a href='https://www.linkedin.com/company/Saleshive/' target='_blank'>" +
        "<img src='https://dev.saleshive.com/EmailTemplate/images/social_linkedin.png' />" +
        "</a>" +
        "</td>" +
        "</tr>" +
        "</table>" +
        "</td>" +
        "</tr>" +
        "</table>" +
        "</td>" +
        "</tr>" +
        "</table>" +
        "</td>" +
        "</tr>" +
        "</table>" +
        "</tr>" +
        "</table>" +
        "<table style='margin:0 auto;text-align:inherit;' align='center'>" +
        "<tr>" +
        "<td>" +
        "<table align='center'>" +
        "<tr>" +
        "<td>" +
        "<p> Hi there, We're confirming that</p>" +
        "<h4>Your password is updated in SalesHive</h4>" +
        "</td>" +
        "</tr>" +
        "</table>" +
        "<span class='devider'>" +
        "</span>" +
        "</td>" +
        "</tr>" +
        "</table>" +
        "<table class='page-footer' align='center'>" +
        "<tr>" +
        "<td style='text-align:center;' align='center'>" +
        "<table class='container' align='center'>" +
        "<tr>" +
        "<td>" +
        "<table style='padding:0px;width:100%;position:relative;display:block;'>" +
        "<tr>" +
        "<td class='wrapper last'>" +
        "<span style='font-size:12px;'>" +
        "<i>This is a system generated email, and a reply is not required.</i>" +
        "</span>" +
        "</td>" +
        "</tr>" +
        "</table>" +
        "<table style='padding:0px;width:100%;position:relative;display:block;'>" +
        "<tr>" +
        "<td class='wrapper'>" +
        "<table class='four columns'>" +
        "<tr>" +
        "<td style='padding-top:0;padding-bottom:0;vertical-align:middle;'>&#169; SalesHive 2023.</td>" +
        "</tr>" +
        "</table>" +
        "</td>" +
        "<td class 'wrapper last'>" +
        "<table class='eight columns'>" +
        "<tr>" +
        "<td class='vertical-middle align-reverse'>" +
        "<a href='https://saleshive.com/about/' target='_blank'>About Us</a> &#160; " +
        "<a href='https://saleshive.com/privacy/' target='_blank'>Privacy Policy</a>" +
        "</td>" +
        "</tr>" +
        "</table>" +
        "</td>" +
        "</tr>" +
        "</table>" +
        "</td>" +
        "</tr>" +
        "</table>" +
        "</td>" +
        "</tr>" +
        "</table>" +
        "</td>" +
        "</tr>" +
        "</table>"
    );
}


//Email for Responder invite
export function responderInvitation(invite_code){
    const invitationLink = CommonConstants.responder_Invitation + "?code=" + invite_code;
    return (
        `<table style="width: 100%;background: #1f1f1f;" align="center">
            <tr>
                <td style="text-align: center;" align="center">
                    <table style="width: 95% !important;margin: 0 auto;text-align: inherit;" align="center">
                        <tr>
                            <td>
                                <table align="center" style="padding: 0px;position: relative;">
                                    <tr>
                                        <td style="padding: 10px 20px 0px 0px;position: relative;padding-top: 3px;padding-bottom: 0;vertical-align: middle;">
                                            <table style="width: 280px;margin: 0 auto;">
                                                <tr>
                                                    <td style="padding-top: 0;padding-bottom: 0;vertical-align: middle;">
                                                        <a href="https://appv2.saleshive.com">
                                                            <img src="https://apiv2.saleshive.com/Images/SalesHiveIconsmall.png" border="0" alt="" />
                                                        </a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td style="padding: 10px 20px 0px 0px;position: relative;padding-top: 0;padding-bottom: 0;vertical-align: middle;padding-right: 0px;">
                                            <table style="width: 280px;margin: 0 auto;">
                                                <tr>
                                                    <td>
                                                        <table style="padding: 10px 20px 0px 0px;position: relative;float: right;" align="right">
                                                            <tr>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://facebook.com/saleshivehq">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_facebook.png" alt="" />
                                                                    </a>
                                                                </td>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://twitter.com/saleshivehq">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_twitter.png" alt="" />
                                                                    </a>
                                                                </td>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://www.linkedin.com/company/Saleshive/">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_linkedin.png" alt="" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table> 
        <p style='text-align:center;'><b>Please click the following URL to set your credentials:</b></p>
        <p style='text-align:center;'><a href='${invitationLink}'>${invitationLink}</a></p>
        <p style='text-align:center;'>If clicking the URL above does not work, copy and paste the URL into a browser window.</p>
        
        <table class="page-footer" align="center">
        <tr>
            <td style="text-align: center;" align="center">
                <table class="container" align="center">
                    <tr>
                        <td>
                            <table style="padding: 0px;width: 100%;position: relative;display: block;">
                                <tr>
                                    <td class="wrapper last">
                                        <span style="font-size:12px;">
                                            <i>This is a system generated email and reply is not required.</i>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                            <table style="padding: 0px;width: 100%;position: relative;display: block;">
                                <tr>
                                    <td class="wrapper">
                                        <table class="four columns">
                                            <tr>
                                                <td style="padding-top: 0;padding-bottom: 0;vertical-align: middle;">
                                                    &copy; SalesHive 2024.
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td class="wrapper last">
                                        <table class="eight columns">
                                            <tr>
                                                <td class="vertical-middle align-reverse">
                                                    <a href="https://saleshive.com/about/">
                                                        About Us
                                                    </a>
                                                    &nbsp; <a href="https://saleshive.com/privacy/">
                                                        Privacy Policy
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>`
    );
}
//Email for coldcallers invite
export function coldcallersInvitation(invite_code){
    const invitationLink = CommonConstants.coldcallers_Invitation + "?code=" + invite_code;
    return (
        `<table style="width: 100%;background: #1f1f1f;" align="center">
            <tr>
                <td style="text-align: center;" align="center">
                    <table style="width: 95% !important;margin: 0 auto;text-align: inherit;" align="center">
                        <tr>
                            <td>
                                <table align="center" style="padding: 0px;position: relative;">
                                    <tr>
                                        <td style="padding: 10px 20px 0px 0px;position: relative;padding-top: 3px;padding-bottom: 0;vertical-align: middle;">
                                            <table style="width: 280px;margin: 0 auto;">
                                                <tr>
                                                    <td style="padding-top: 0;padding-bottom: 0;vertical-align: middle;">
                                                        <a href="https://appv2.saleshive.com">
                                                            <img src="https://apiv2.saleshive.com/Images/SalesHiveIconsmall.png" border="0" alt="" />
                                                        </a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td style="padding: 10px 20px 0px 0px;position: relative;padding-top: 0;padding-bottom: 0;vertical-align: middle;padding-right: 0px;">
                                            <table style="width: 280px;margin: 0 auto;">
                                                <tr>
                                                    <td>
                                                        <table style="padding: 10px 20px 0px 0px;position: relative;float: right;" align="right">
                                                            <tr>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://facebook.com/saleshivehq">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_facebook.png" alt="" />
                                                                    </a>
                                                                </td>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://twitter.com/saleshivehq">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_twitter.png" alt="" />
                                                                    </a>
                                                                </td>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://www.linkedin.com/company/Saleshive/">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_linkedin.png" alt="" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table> 
        <p style='text-align:center;'><b>Please click the following URL to set your credentials:</b></p>
        <p style='text-align:center;'><a href='${invitationLink}'>${invitationLink}</a></p>
        <p style='text-align:center;'>If clicking the URL above does not work, copy and paste the URL into a browser window.</p>
        
        <table class="page-footer" align="center">
        <tr>
            <td style="text-align: center;" align="center">
                <table class="container" align="center">
                    <tr>
                        <td>
                            <table style="padding: 0px;width: 100%;position: relative;display: block;">
                                <tr>
                                    <td class="wrapper last">
                                        <span style="font-size:12px;">
                                            <i>This is a system generated email and reply is not required.</i>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                            <table style="padding: 0px;width: 100%;position: relative;display: block;">
                                <tr>
                                    <td class="wrapper">
                                        <table class="four columns">
                                            <tr>
                                                <td style="padding-top: 0;padding-bottom: 0;vertical-align: middle;">
                                                    &copy; SalesHive 2024.
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td class="wrapper last">
                                        <table class="eight columns">
                                            <tr>
                                                <td class="vertical-middle align-reverse">
                                                    <a href="https://saleshive.com/about/">
                                                        About Us
                                                    </a>
                                                    &nbsp; <a href="https://saleshive.com/privacy/">
                                                        Privacy Policy
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>`
    );
}
//Email for salesStrategists invite
export function salesStrategistsInvitation(email,UserID,invite_code){
    const invitationLink = CommonConstants.salesstrategists_invitation + "?email=" + email + "&UID=" + UserID + "&vc=" + invite_code;
    return (
        `<table style="width: 100%;background: #1f1f1f;" align="center">
            <tr>
                <td style="text-align: center;" align="center">
                    <table style="width: 95% !important;margin: 0 auto;text-align: inherit;" align="center">
                        <tr>
                            <td>
                                <table align="center" style="padding: 0px;position: relative;">
                                    <tr>
                                        <td style="padding: 10px 20px 0px 0px;position: relative;padding-top: 3px;padding-bottom: 0;vertical-align: middle;">
                                            <table style="width: 280px;margin: 0 auto;">
                                                <tr>
                                                    <td style="padding-top: 0;padding-bottom: 0;vertical-align: middle;">
                                                        <a href="https://appv2.saleshive.com">
                                                            <img src="https://apiv2.saleshive.com/Images/SalesHiveIconsmall.png" border="0" alt="" />
                                                        </a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td style="padding: 10px 20px 0px 0px;position: relative;padding-top: 0;padding-bottom: 0;vertical-align: middle;padding-right: 0px;">
                                            <table style="width: 280px;margin: 0 auto;">
                                                <tr>
                                                    <td>
                                                        <table style="padding: 10px 20px 0px 0px;position: relative;float: right;" align="right">
                                                            <tr>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://facebook.com/saleshivehq">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_facebook.png" alt="" />
                                                                    </a>
                                                                </td>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://twitter.com/saleshivehq">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_twitter.png" alt="" />
                                                                    </a>
                                                                </td>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://www.linkedin.com/company/Saleshive/">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_linkedin.png" alt="" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table> 
        <p style='text-align:center;'><b>Please click the following URL to set your credentials:</b></p>
        <p style='text-align:center;'><a href='${invitationLink}'>${invitationLink}</a></p>
        <p style='text-align:center;'>If clicking the URL above does not work, copy and paste the URL into a browser window.</p>
        
        <table class="page-footer" align="center">
        <tr>
            <td style="text-align: center;" align="center">
                <table class="container" align="center">
                    <tr>
                        <td>
                            <table style="padding: 0px;width: 100%;position: relative;display: block;">
                                <tr>
                                    <td class="wrapper last">
                                        <span style="font-size:12px;">
                                            <i>This is a system generated email and reply is not required.</i>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                            <table style="padding: 0px;width: 100%;position: relative;display: block;">
                                <tr>
                                    <td class="wrapper">
                                        <table class="four columns">
                                            <tr>
                                                <td style="padding-top: 0;padding-bottom: 0;vertical-align: middle;">
                                                    &copy; SalesHive 2024.
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td class="wrapper last">
                                        <table class="eight columns">
                                            <tr>
                                                <td class="vertical-middle align-reverse">
                                                    <a href="https://saleshive.com/about/">
                                                        About Us
                                                    </a>
                                                    &nbsp; <a href="https://saleshive.com/privacy/">
                                                        Privacy Policy
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>`
    );
}
//Update Email for SalesStrategists 
export function salesStrategistsEmailUpdate(email,UserUID,lastUpdatedBy,UID,Email_Token){
    const emailChangeLink = CommonConstants.change_Email_salesstrategists + "?email=" + email + "&UserUID=" + UserUID + "&lastUpdatedBy=" + lastUpdatedBy + "&UID=" + UID + "&code=" + Email_Token;
    return (
        `<table style="width: 100%;background: #1f1f1f;" align="center">
            <tr>
                <td style="text-align: center;" align="center">
                    <table style="width: 95% !important;margin: 0 auto;text-align: inherit;" align="center">
                        <tr>
                            <td>
                                <table align="center" style="padding: 0px;position: relative;">
                                    <tr>
                                        <td style="padding: 10px 20px 0px 0px;position: relative;padding-top: 3px;padding-bottom: 0;vertical-align: middle;">
                                            <table style="width: 280px;margin: 0 auto;">
                                                <tr>
                                                    <td style="padding-top: 0;padding-bottom: 0;vertical-align: middle;">
                                                        <a href="https://appv2.saleshive.com">
                                                            <img src="https://apiv2.saleshive.com/Images/SalesHiveIconsmall.png" border="0" alt="" />
                                                        </a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td style="padding: 10px 20px 0px 0px;position: relative;padding-top: 0;padding-bottom: 0;vertical-align: middle;padding-right: 0px;">
                                            <table style="width: 280px;margin: 0 auto;">
                                                <tr>
                                                    <td>
                                                        <table style="padding: 10px 20px 0px 0px;position: relative;float: right;" align="right">
                                                            <tr>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://facebook.com/saleshivehq">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_facebook.png" alt="" />
                                                                    </a>
                                                                </td>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://twitter.com/saleshivehq">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_twitter.png" alt="" />
                                                                    </a>
                                                                </td>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://www.linkedin.com/company/Saleshive/">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_linkedin.png" alt="" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table> 
        <p style='text-align:center;'><b>Please click the following URL to set your credentials:</b></p>
        <p style='text-align:center;'><a href='${emailChangeLink}'>${emailChangeLink}</a></p>
        <p style='text-align:center;'>If clicking the URL above does not work, copy and paste the URL into a browser window.</p>
        
        <table class="page-footer" align="center">
        <tr>
            <td style="text-align: center;" align="center">
                <table class="container" align="center">
                    <tr>
                        <td>
                            <table style="padding: 0px;width: 100%;position: relative;display: block;">
                                <tr>
                                    <td class="wrapper last">
                                        <span style="font-size:12px;">
                                            <i>This is a system generated email and reply is not required.</i>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                            <table style="padding: 0px;width: 100%;position: relative;display: block;">
                                <tr>
                                    <td class="wrapper">
                                        <table class="four columns">
                                            <tr>
                                                <td style="padding-top: 0;padding-bottom: 0;vertical-align: middle;">
                                                    &copy; SalesHive 2024.
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td class="wrapper last">
                                        <table class="eight columns">
                                            <tr>
                                                <td class="vertical-middle align-reverse">
                                                    <a href="https://saleshive.com/about/">
                                                        About Us
                                                    </a>
                                                    &nbsp; <a href="https://saleshive.com/privacy/">
                                                        Privacy Policy
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>`
    );
}

//Email for responder invitation for create new responder profile
export function ResponderUserEmailInvite(email,UID,cby,VC,url){
    const invitationLink = url + "?email=" + email + "&cby=" + cby + "&UID=" + UID + "&VC=" + VC;
    return (
        `<table style="width: 100%;background: #1f1f1f;" align="center">
            <tr>
                <td style="text-align: center;" align="center">
                    <table style="width: 95% !important;margin: 0 auto;text-align: inherit;" align="center">
                        <tr>
                            <td>
                                <table align="center" style="padding: 0px;position: relative;">
                                    <tr>
                                        <td style="padding: 10px 20px 0px 0px;position: relative;padding-top: 3px;padding-bottom: 0;vertical-align: middle;">
                                            <table style="width: 280px;margin: 0 auto;">
                                                <tr>
                                                    <td style="padding-top: 0;padding-bottom: 0;vertical-align: middle;">
                                                        <a href="https://appv2.saleshive.com">
                                                            <img src="https://apiv2.saleshive.com/Images/SalesHiveIconsmall.png" border="0" alt="" />
                                                        </a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td style="padding: 10px 20px 0px 0px;position: relative;padding-top: 0;padding-bottom: 0;vertical-align: middle;padding-right: 0px;">
                                            <table style="width: 280px;margin: 0 auto;">
                                                <tr>
                                                    <td>
                                                        <table style="padding: 10px 20px 0px 0px;position: relative;float: right;" align="right">
                                                            <tr>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://facebook.com/saleshivehq">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_facebook.png" alt="" />
                                                                    </a>
                                                                </td>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://twitter.com/saleshivehq">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_twitter.png" alt="" />
                                                                    </a>
                                                                </td>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://www.linkedin.com/company/Saleshive/">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_linkedin.png" alt="" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table> 
        <p style='text-align:center;'><b>Please click the following URL to set your credentials:</b></p>
        <p style='text-align:center;'><a href='${invitationLink}'>${invitationLink}</a></p>
        <p style='text-align:center;'>If clicking the URL above does not work, copy and paste the URL into a browser window.</p>
        
        <table class="page-footer" align="center">
        <tr>
            <td style="text-align: center;" align="center">
                <table class="container" align="center">
                    <tr>
                        <td>
                            <table style="padding: 0px;width: 100%;position: relative;display: block;">
                                <tr>
                                    <td class="wrapper last">
                                        <span style="font-size:12px;">
                                            <i>This is a system generated email and reply is not required.</i>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                            <table style="padding: 0px;width: 100%;position: relative;display: block;">
                                <tr>
                                    <td class="wrapper">
                                        <table class="four columns">
                                            <tr>
                                                <td style="padding-top: 0;padding-bottom: 0;vertical-align: middle;">
                                                    &copy; SalesHive 2024.
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td class="wrapper last">
                                        <table class="eight columns">
                                            <tr>
                                                <td class="vertical-middle align-reverse">
                                                    <a href="https://saleshive.com/about/">
                                                        About Us
                                                    </a>
                                                    &nbsp; <a href="https://saleshive.com/privacy/">
                                                        Privacy Policy
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>`
    );
}

export function SeoUserEmailInvite(email,UID,cby,VC,url){
    const invitationLink = url + "?email=" + email + "&cby=" + cby + "&UID=" + UID + "&VC=" + VC;
    return (
        `<table style="width: 100%;background: #1f1f1f;" align="center">
            <tr>
                <td style="text-align: center;" align="center">
                    <table style="width: 95% !important;margin: 0 auto;text-align: inherit;" align="center">
                        <tr>
                            <td>
                                <table align="center" style="padding: 0px;position: relative;">
                                    <tr>
                                        <td style="padding: 10px 20px 0px 0px;position: relative;padding-top: 3px;padding-bottom: 0;vertical-align: middle;">
                                            <table style="width: 280px;margin: 0 auto;">
                                                <tr>
                                                    <td style="padding-top: 0;padding-bottom: 0;vertical-align: middle;">
                                                        <a href="https://appv2.saleshive.com">
                                                            <img src="https://apiv2.saleshive.com/Images/SalesHiveIconsmall.png" border="0" alt="" />
                                                        </a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td style="padding: 10px 20px 0px 0px;position: relative;padding-top: 0;padding-bottom: 0;vertical-align: middle;padding-right: 0px;">
                                            <table style="width: 280px;margin: 0 auto;">
                                                <tr>
                                                    <td>
                                                        <table style="padding: 10px 20px 0px 0px;position: relative;float: right;" align="right">
                                                            <tr>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://facebook.com/saleshivehq">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_facebook.png" alt="" />
                                                                    </a>
                                                                </td>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://twitter.com/saleshivehq">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_twitter.png" alt="" />
                                                                    </a>
                                                                </td>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://www.linkedin.com/company/Saleshive/">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_linkedin.png" alt="" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table> 
        <p style='text-align:center;'><b>Please click the following URL to set your credentials:</b></p>
        <p style='text-align:center;'><a href='${invitationLink}'>${invitationLink}</a></p>
        <p style='text-align:center;'>If clicking the URL above does not work, copy and paste the URL into a browser window.</p>
        
        <table class="page-footer" align="center">
        <tr>
            <td style="text-align: center;" align="center">
                <table class="container" align="center">
                    <tr>
                        <td>
                            <table style="padding: 0px;width: 100%;position: relative;display: block;">
                                <tr>
                                    <td class="wrapper last">
                                        <span style="font-size:12px;">
                                            <i>This is a system generated email and reply is not required.</i>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                            <table style="padding: 0px;width: 100%;position: relative;display: block;">
                                <tr>
                                    <td class="wrapper">
                                        <table class="four columns">
                                            <tr>
                                                <td style="padding-top: 0;padding-bottom: 0;vertical-align: middle;">
                                                    &copy; SalesHive 2024.
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td class="wrapper last">
                                        <table class="eight columns">
                                            <tr>
                                                <td class="vertical-middle align-reverse">
                                                    <a href="https://saleshive.com/about/">
                                                        About Us
                                                    </a>
                                                    &nbsp; <a href="https://saleshive.com/privacy/">
                                                        Privacy Policy
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>`
    );
}


// Email for Email change
export function userEmailChange(email_token,email,url){
    const updateEmailLink = url + "?code=" + email_token + "&email=" + email;
    return (
        `<table style="width: 100%;background: #1f1f1f;" align="center">
        <tr>
            <td style="text-align: center;" align="center">
                <table style="width: 95% !important;margin: 0 auto;text-align: inherit;" align="center">
                    <tr>
                        <td>
                            <table align="center" style="padding: 0px;position: relative;">
                                <tr>
                                    <td style="padding: 10px 20px 0px 0px;position: relative;padding-top: 3px;padding-bottom: 0;vertical-align: middle;">
                                        <table style="width: 280px;margin: 0 auto;">
                                            <tr>
                                                <td style="padding-top: 0;padding-bottom: 0;vertical-align: middle;">
                                                    <a href="https://appv2.saleshive.com">
                                                        <img src="https://apiv2.saleshive.com/Images/SalesHiveIconsmall.png" border="0" alt="" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td style="padding: 10px 20px 0px 0px;position: relative;padding-top: 0;padding-bottom: 0;vertical-align: middle;padding-right: 0px;">
                                        <table style="width: 280px;margin: 0 auto;">
                                            <tr>
                                                <td>
                                                    <table style="padding: 10px 20px 0px 0px;position: relative;float: right;" align="right">
                                                        <tr>
                                                            <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                <a href="https://facebook.com/saleshivehq">
                                                                    <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_facebook.png" alt="" />
                                                                </a>
                                                            </td>
                                                            <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                <a href="https://twitter.com/saleshivehq">
                                                                    <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_twitter.png" alt="" />
                                                                </a>
                                                            </td>
                                                            <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                <a href="https://www.linkedin.com/company/Saleshive/">
                                                                    <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_linkedin.png" alt="" />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table> `+
        "<p style='text-align:center;'><b>Please click the following URL to update your Email:</b></p>\n" +
        "<p style='text-align:center;'><a href='" + updateEmailLink + "'>" + updateEmailLink + "</a></p>\n" +
        "<p style='text-align:center;'>If clicking the URL above does not work, copy and paste the URL into a browser window.</p>\n" + `<table class="page-footer" align="center">
        <tr>
            <td style="text-align: center;" align="center">
                <table class="container" align="center">
                    <tr>
                        <td>
                            <table style="padding: 0px;width: 100%;position: relative;display: block;">
                                <tr>
                                    <td class="wrapper last">
                                        <span style="font-size:12px;">
                                            <i>This is a system generated email and reply is not required.</i>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                            <table style="padding: 0px;width: 100%;position: relative;display: block;">
                                <tr>
                                    <td class="wrapper">
                                        <table class="four columns">
                                            <tr>
                                                <td style="padding-top: 0;padding-bottom: 0;vertical-align: middle;">
                                                    &copy; SalesHive 2024.
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td class="wrapper last">
                                        <table class="eight columns">
                                            <tr>
                                                <td class="vertical-middle align-reverse">
                                                    <a href="https://saleshive.com/about/">
                                                        About Us
                                                    </a>
                                                    &nbsp; <a href="https://saleshive.com/privacy/">
                                                        Privacy Policy
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>`
    );
}

//Email for responder invitation for create new responder profile
export function AdminUserEmailInvite(email,UID,AUVID,VC,url){
    const credentialsLink = url + "?email=" + email + "&AUVID=" + AUVID + "&UID=" + UID + "&VC=" + VC;
    return (
        `<table style="width: 100%;background: #1f1f1f;" align="center">
            <tr>
                <td style="text-align: center;" align="center">
                    <table style="width: 95% !important;margin: 0 auto;text-align: inherit;" align="center">
                        <tr>
                            <td>
                                <table align="center" style="padding: 0px;position: relative;">
                                    <tr>
                                        <td style="padding: 10px 20px 0px 0px;position: relative;padding-top: 3px;padding-bottom: 0;vertical-align: middle;">
                                            <table style="width: 280px;margin: 0 auto;">
                                                <tr>
                                                    <td style="padding-top: 0;padding-bottom: 0;vertical-align: middle;">
                                                        <a href="https://appv2.saleshive.com">
                                                            <img src="https://apiv2.saleshive.com/Images/SalesHiveIconsmall.png" border="0" alt="" />
                                                        </a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td style="padding: 10px 20px 0px 0px;position: relative;padding-top: 0;padding-bottom: 0;vertical-align: middle;padding-right: 0px;">
                                            <table style="width: 280px;margin: 0 auto;">
                                                <tr>
                                                    <td>
                                                        <table style="padding: 10px 20px 0px 0px;position: relative;float: right;" align="right">
                                                            <tr>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://facebook.com/saleshivehq">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_facebook.png" alt="" />
                                                                    </a>
                                                                </td>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://twitter.com/saleshivehq">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_twitter.png" alt="" />
                                                                    </a>
                                                                </td>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://www.linkedin.com/company/Saleshive/">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_linkedin.png" alt="" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table> 
        <p style='text-align:center;'><b>Please click the following URL to set your credentials:</b></p>
        <p style='text-align:center;'><a href='${credentialsLink}'>${credentialsLink}</a></p>
        <p style='text-align:center;'>If clicking the URL above does not work, copy and paste the URL into a browser window.</p>
        
        <table class="page-footer" align="center">
        <tr>
            <td style="text-align: center;" align="center">
                <table class="container" align="center">
                    <tr>
                        <td>
                            <table style="padding: 0px;width: 100%;position: relative;display: block;">
                                <tr>
                                    <td class="wrapper last">
                                        <span style="font-size:12px;">
                                            <i>This is a system generated email and reply is not required.</i>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                            <table style="padding: 0px;width: 100%;position: relative;display: block;">
                                <tr>
                                    <td class="wrapper">
                                        <table class="four columns">
                                            <tr>
                                                <td style="padding-top: 0;padding-bottom: 0;vertical-align: middle;">
                                                    &copy; SalesHive 2024.
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td class="wrapper last">
                                        <table class="eight columns">
                                            <tr>
                                                <td class="vertical-middle align-reverse">
                                                    <a href="https://saleshive.com/about/">
                                                        About Us
                                                    </a>
                                                    &nbsp; <a href="https://saleshive.com/privacy/">
                                                        Privacy Policy
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>`
    );
} 

//Email for admin user for create new admin profile
export function userEmailInvite(email,CID,UID,url){
    const credentialsLink = url + "?email=" + email + "&CID=" + CID + "&UID=" + UID;
    return (
        `<table style="width: 100%;background: #1f1f1f;" align="center">
            <tr>
                <td style="text-align: center;" align="center">
                    <table style="width: 95% !important;margin: 0 auto;text-align: inherit;" align="center">
                        <tr>
                            <td>
                                <table align="center" style="padding: 0px;position: relative;">
                                    <tr>
                                        <td style="padding: 10px 20px 0px 0px;position: relative;padding-top: 3px;padding-bottom: 0;vertical-align: middle;">
                                            <table style="width: 280px;margin: 0 auto;">
                                                <tr>
                                                    <td style="padding-top: 0;padding-bottom: 0;vertical-align: middle;">
                                                        <a href="https://appv2.saleshive.com">
                                                            <img src="https://apiv2.saleshive.com/Images/SalesHiveIconsmall.png" border="0" alt="" />
                                                        </a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td style="padding: 10px 20px 0px 0px;position: relative;padding-top: 0;padding-bottom: 0;vertical-align: middle;padding-right: 0px;">
                                            <table style="width: 280px;margin: 0 auto;">
                                                <tr>
                                                    <td>
                                                        <table style="padding: 10px 20px 0px 0px;position: relative;float: right;" align="right">
                                                            <tr>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://facebook.com/saleshivehq">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_facebook.png" alt="" />
                                                                    </a>
                                                                </td>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://twitter.com/saleshivehq">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_twitter.png" alt="" />
                                                                    </a>
                                                                </td>
                                                                <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                    <a href="https://www.linkedin.com/company/Saleshive/">
                                                                        <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_linkedin.png" alt="" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table> 
        <p style='text-align:center;'><b>Please click the following URL to set your credentials:</b></p>
        <p style='text-align:center;'><a href='${credentialsLink}'>${credentialsLink}</a></p>
        <p style='text-align:center;'>If clicking the URL above does not work, copy and paste the URL into a browser window.</p>
        
        <table class="page-footer" align="center">
        <tr>
            <td style="text-align: center;" align="center">
                <table class="container" align="center">
                    <tr>
                        <td>
                            <table style="padding: 0px;width: 100%;position: relative;display: block;">
                                <tr>
                                    <td class="wrapper last">
                                        <span style="font-size:12px;">
                                            <i>This is a system generated email and reply is not required.</i>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                            <table style="padding: 0px;width: 100%;position: relative;display: block;">
                                <tr>
                                    <td class="wrapper">
                                        <table class="four columns">
                                            <tr>
                                                <td style="padding-top: 0;padding-bottom: 0;vertical-align: middle;">
                                                    &copy; SalesHive 2024.
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td class="wrapper last">
                                        <table class="eight columns">
                                            <tr>
                                                <td class="vertical-middle align-reverse">
                                                    <a href="https://saleshive.com/about/">
                                                        About Us
                                                    </a>
                                                    &nbsp; <a href="https://saleshive.com/privacy/">
                                                        Privacy Policy
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>`
    );
}

//Email for potential meeting details
export function userEmail(){
    const credentialsLink = url + "?email=" + email;
    return (
        `<table style="width: 100%;background: #1f1f1f;" align="center">
        <tr>
            <td style="text-align: center;" align="center">
                <table style="width: 95% !important;margin: 0 auto;text-align: inherit;" align="center">
                    <tr>
                        <td>
                            <table align="center" style="padding: 0px;position: relative;">
                                <tr>
                                    <td style="padding: 10px 20px 0px 0px;position: relative;padding-top: 3px;padding-bottom: 0;vertical-align: middle;">
                                        <table style="width: 280px;margin: 0 auto;">
                                            <tr>
                                                <td style="padding-top: 0;padding-bottom: 0;vertical-align: middle;">
                                                    <a href="https://appv2.saleshive.com">
                                                        <img src="https://apiv2.saleshive.com/Images/SalesHiveIconsmall.png" border="0" alt="" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td style="padding: 10px 20px 0px 0px;position: relative;padding-top: 0;padding-bottom: 0;vertical-align: middle;padding-right: 0px;">
                                        <table style="width: 280px;margin: 0 auto;">
                                            <tr>
                                                <td>
                                                    <table style="padding: 10px 20px 0px 0px;position: relative;float: right;" align="right">
                                                        <tr>
                                                            <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                <a href="https://facebook.com/saleshivehq">
                                                                    <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_facebook.png" alt="" />
                                                                </a>
                                                            </td>
                                                            <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                <a href="https://twitter.com/saleshivehq">
                                                                    <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_twitter.png" alt="" />
                                                                </a>
                                                            </td>
                                                            <td style="vertical-align: middle;padding: 0 2px !important;width: auto !important;">
                                                                <a href="https://www.linkedin.com/company/Saleshive/">
                                                                    <img src="https://apiv2.saleshive.com/EmailTemplate/images/social_linkedin.png" alt="" />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table> `+
        "<p style='text-align:center;'><b>Please click the following URL to set your credentials:</b></p>\n" +
        "<p style='text-align:center;'><a href='" + credentialsLink + "'>" + credentialsLink + "</a></p>\n" +
        "<p style='text-align:center;'>If clicking the URL above does not work, copy and paste the URL into a browser window.</p>\n" +
        "<p style='text-align:center;'>This is a system-generated email, and no reply is required.</p>"+ `
        <table class="page-footer" align="center">
        <tr>
            <td style="text-align: center;" align="center">
                <table class="container" align="center">
                    <tr>
                        <td>
                            <table style="padding: 0px;width: 100%;position: relative;display: block;">
                                <tr>
                                    <td class="wrapper last">
                                        <span style="font-size:12px;">
                                            <i>This is a system generated email and reply is not required.</i>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                            <table style="padding: 0px;width: 100%;position: relative;display: block;">
                                <tr>
                                    <td class="wrapper">
                                        <table class="four columns">
                                            <tr>
                                                <td style="padding-top: 0;padding-bottom: 0;vertical-align: middle;">
                                                    &copy; SalesHive 2024.
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td class="wrapper last">
                                        <table class="eight columns">
                                            <tr>
                                                <td class="vertical-middle align-reverse">
                                                    <a href="https://saleshive.com/about/">
                                                        About Us
                                                    </a>
                                                    &nbsp; <a href="https://saleshive.com/privacy/">
                                                        Privacy Policy
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>`
    );
} 

//Email for Two Step Verification 
export function twostepverificationEmail(Code){
    return(

"<table style='width: 100%; margin: 0 auto;text-align: inherit;' align='center'>"+
  "<tr>\n"+
      "<td>"+
          "<table style='margin: 15px auto 15px;display: table;' align='center'>"+
              "<tr>"+
                 "<td>"+
                      "<h1 style='margin-bottom:0; '>Email Verification</h1>"+
                  "</td>"+
              "</tr>"+
              "<tr>"+
                  "<td>"+
                      "<p style='text-align: center;'>Please use below verification code to login.</p>"+
                  "</td>"+
              "</tr>"+
          "</table>"+
      "</td>"+
  "</tr>"+
  "<tr>"+
      "<td style='background: #e8f0fe; padding: 70px 20px; width: 100%;'>"+
          "<table align='center'>"+
              "<tr>"+
                  "<td>"+
                      "<table style='margin-bottom: 10px' align='center'>"+
                         
                          "<tr>"+
                              "<td>"+
                                  "<h1 style='margin: 0 auto;'>Your OTP : <strong style='font-size: 46px;'>"+Code+ "</strong> </h1>"+
                              "</td>"+
                          "</tr>"+
                      "</table>"+
                  "</td>"+
              "</tr>"+
          "</table>"+
      "</td>"+
  "</tr>" +
  "<tr>"+
      "<td>"+
          "<table style='padding: 15px;' align='center'>"+
              "<tr>"+
                  "<td><p>Thank You</p></td>"+
              "</tr>"+
          "</table>"+
      "</td>"+
  "</tr>"+
"</table>"

    )
}