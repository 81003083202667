import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

import { history } from "../../../_helpers";
import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

class EditResearchTeamsPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      Field: {},
      Errors: {},
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      StatusAvailable: null,
      IsExist: false,
      OldName: "",
      IsButtonDisabled: false,
    };
    this.ResearchTeamGet();
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
  }
  componentDidMount() {
    document.title = `Edit Research Team | SalesHive`;
  }

  ResearchTeamGet() {
    const InputParameter = {
      ID: this.props.location.state,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/researchteams/ResearchTeamsByID",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        this.setState({ OldName: res.data.Data.Name });
        document.getElementById("Name").value = res.data.Data.Name;
        document.getElementById("CostPerHour").value =
          res.data.Data.CostPerHour;
      }
      document.getElementById("hideloding").style.display = "none";
    });
  }

  async FormValidation() {
    let FormIsValid = true;
    let Errors = {};
    var Name = document.getElementById("Name").value.trim();
    var CostPerHour = document.getElementById("CostPerHour").value;
    var IsExist = await this.CheckResearchTeamsNameExist(Name);
    if (Name.length <= 0) {
      FormIsValid = false;
      Errors["Name"] = "Please enter name";
    }
    if (CostPerHour.length <= 0) {
      FormIsValid = false;
      Errors["CostPerHour"] = " Please enter cost per hour.";
    }
    if (CostPerHour <= 0) {
      FormIsValid = false;
      Errors["CostPerHour"] =
        "Please enter a value greater than or equal to 1.";
    }
    if (IsExist == "BlankData" && Name == "") {
      FormIsValid = false;
      Errors["Name"] = "Please enter name";
    }
    if (IsExist == "ExistResearch") {
      FormIsValid = false;
      Errors["Name"] = "Name already exist.";
    }
    // if (this.state.StatusAvailable != null) {
    //   FormIsValid = false;
    // }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

  // handle change
  HandleChange(field, e) {
    let Fields = this.state.Field;
    Fields[field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Name != "") {
      this.state.Errors.Name = null;
      this.state.StatusAvailable = null;
      this.CheckResearchTeamsNameExist(e.target.value);
    } else {
      this.state.StatusAvailable = null;
      this.state.Errors.Name = null;
    }
  }

  async HandleChangeBlur() {
    let Errors = { ...this.state.Errors };
    var Name = document.getElementById("Name").value.trim();

    var IsExist = await this.CheckResearchTeamsNameExist(Name);

    if (IsExist == "BlankData" && Name == "") {
      Errors["Name"] = "Please enter name";
    }
    if (IsExist == "ExistResearch") {
      Errors["Name"] = "Name already exist.";
    }

    if (IsExist == "AllDone") {
      Errors["Name"] = null;
    }

    this.setState({ Errors: Errors });
  }

  //check exist Status
  async CheckResearchTeamsNameExist(Name) {
    if (Name != "") {
      var Inputparameter = {
        ClientID: this.state.ClientID,
        Name: Name,
      };
      var resdata = await Axios({
        url: CommonConstants.MOL_APIURL + "/researchteams/ResearchTeamsExist",
        method: "POST",
        data: Inputparameter,
      });
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          if (this.state.OldName == Name) {
            return "AllDone";
          } else {
            this.setState({ StatusAvailable: resdata.data.Data[0]?.Name });
            return "ExistResearch";
          }
        } else {
          return "AllDone";
        }
      }
    } else {
      return "BlankData";
    }
  }

  BackBtn() {
    history.push("/ResearchTeam");
  }

  async SaveBtn(e) {
    e.preventDefault();
    // this.state.Errors.Name = null;
    // this.state.StatusAvailable = null;
    this.setState({ IsButtonDisabled: true });
    // e.preventDefault();
    // e.currentTarget.disabled = true;
    var Final_flag = await this.FormValidation();
    if (Final_flag == true) {
      var Name = document.getElementById("Name").value;
      var CostPerHour = document.getElementById("CostPerHour").value;
      var InputParameter = {
        ID: this.props.location.state,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Name: Name,
        CostPerHour: CostPerHour,
        LastUpdatedBy: this.state.CUserID,
        LastUpdatedDate: new Date(),
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/researchteams/ResearchTeamsUpdate",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">Research team updated successfully.</div>
          );
          history.push("/ResearchTeam");
        } else {
          toast.error(res.data?.Message);
          this.setState({ IsButtonDisabled: false });
        }
      });
    } else {
      this.setState({ IsButtonDisabled: false });
      //document.getElementById("submit").disabled = false;
    }
  }

  render() {
    return (
      <>
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain mheight"> 
          <Sidebar className="" />  
          <div className="bodyhome">
            <div class="">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left mb-0">
                    Edit Research Team{" "}
                  </h4>
                </div>
              </div>
            </div>

            <div class="paddcols">
              <div className="row">  
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Name</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                       
                        placeholder="Enter Name"
                        id="Name"
                        onBlur={this.HandleChangeBlur}
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                      {/* {this.state.StatusAvailable && (
                        <span style={{ color: "red" }}>
                          Name already exist.
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Cost per hour
                    </label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        id="CostPerHour"
                        placeholder="Enter Cost per hour"
                        type="number"
                        min="0"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["CostPerHour"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div class="row my-3">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.IsButtonDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
              
            </div> 
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditResearchTeamsPage = connect(
  mapState,
  actionCreators
)(EditResearchTeamsPage);
export { connectedEditResearchTeamsPage as EditResearchTeamsPage };
