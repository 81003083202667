import React from "react";
const moment = require("moment");
import Axios from "axios";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class EditClientDirectMailPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      fields: {},
      errors: {},
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      Role: Details.Role,
      Typelist: [],
      IsExist: false,
      MailTypeList: [],
      ClientList: [],
      MailTypeID: null,
      formData: {
        Client: "",
        MailType: "",
        Spend: "",
        Quantity: "",
        Description: "",
        MeetingDate: new Date().toISOString().split('T')[0],
      },
      BtnDisabled: false,
      IsLoading: false,
    };
    this.ClientDropDown(
      Details.ChildUserID,
      Details.ParentUserID,
      Details.Role
    );
    this.ClientDirectMailData();
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
    this.GetMailTypeDropDown();
    //   document.getElementById('OrderDate').valueAsDate  = new Date();
  }
  componentDidMount() {
    document.title = "Edit Client Direct Mail | SalesHive";
  }

  //Get Client Direct mail Data
  ClientDirectMailData() {
    this.setState({ IsLoading: true });
    const ID = this.props.location.state;
    var InputParameter = {
      ID: ID,
      Role: this.state.Role,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/client_direct_mail/ClientDirectMailByID",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        this.setState({ IsLoading: true });
        this.setState({ MailTypeID: res.data.Data.MailType.toString() });
        let PrevData = {};
        PrevData["Client"] = res.data.Data.ClientID;
        PrevData["MailType"] = res.data.Data.MailType;
        PrevData["Spend"] = res.data.Data.Spend;
        PrevData["Quantity"] = res.data.Data.Quantity;
        PrevData["Description"] = res.data.Data.Description;
        var todayDate = moment(res.data.Data.OrderDate).format("YYYY-MM-DD");
        document.getElementById("OrderDate").value = todayDate;
        this.setState({ formData: PrevData });
        this.setState({ IsLoading: false });

      } else {
        toast.error(res.data.Message);
        this.setState({ IsLoading: false });
      }
    });
  }

  // Client Drop Down
  ClientDropDown(CUID, PUID, ROLE) {
  
    this.setState({ IsLoading: true });
    var ObjData = {
      Role: ROLE,
      ChildUserID: CUID,
      ParentUserID: PUID,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/client/GetClientListForTopDropDown",
      method: "POST",
      data: ObjData,
    }).then((result) => {
      if (result.data.StatusMessage == "SUCCESS") {
        this.setState({ IsLoading: true });
        const ClientData = result.data.Data;
        const option = ClientData.map((d) => ({
          _id: d.ClientID,
          Name: d.Name,
        }));
        this.setState({ ClientList: option });
        this.setState({ IsLoading: false });
      }
      this.setState({ IsLoading: false });
    });
  }

  // Get Mail Type Drop Down
  GetMailTypeDropDown() {
    this.setState({ IsLoading: true });

    Axios({
      url: CommonConstants.MOL_APIURL + "/client_direct_mail/MailTypeGet",
      method: "POST",
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        const data = res.data.Data;
        const option = data.map((d) => ({
          FieldID: d.FieldID,
          FieldName: d.FieldName,
        }));
        this.setState({ MailTypeList: option });
        this.setState({ IsLoading: false });
      } else {
        toast.error(res.data.Message);
        this.setState({ IsLoading: false });
      }
    });
  }

  FormValidation = () => {
    let formIsValid = true;
    const errors = {};

    //Client validation
    if (!this.state.formData.Client) {
      formIsValid = false;
      errors.Client = "Please select client";
    } else {
      errors.Client = "";
    }

    // Validate Type field
    if (!this.state.formData.MailType) {
      formIsValid = false;
      errors.MailType = "Please select type";
    } else {
      errors.MailType = "";
    }
    
    var Spend = parseFloat(document.getElementById("Spend").value);
    var Quantity =parseFloat(document.getElementById("Quantity").value);
    // Validate Spend field
    if(!isNaN(Spend)){
      Spend = document.getElementById("Spend").value
    }
       // Validate Spend field
       if(!isNaN(Quantity)){
        Quantity = document.getElementById("Quantity").value
      }
    if (Spend === "") {
      formIsValid = false;
      errors.Spend = "Please enter spend";
    } else if (/e/i.test(Spend)) {
      formIsValid = false;
      errors.Spend = "The field Spend must be a number.";
    } else if (Spend <= 0) {
      formIsValid = false;
      errors.Spend = "Please enter a value greater than or equal to 1.";
    }else if(isNaN(Spend)){
      formIsValid = false;
      errors.Spend = "The field Spend must be a number.";

    }else {
      errors.Spend = "";
    }

    // Validate Quantity field
    if (Quantity === "") {
      formIsValid = false;
      errors.Quantity = "Please enter spend";
    } else if (/e/i.test(Quantity)) {
      formIsValid = false;
      errors.Quantity = "The field Quantity must be a number.";
    } else if (Quantity <= 0) {
      formIsValid = false;
      errors.Quantity = "Please enter a value greater than or equal to 1.";
    }else if(isNaN(Quantity)){
      formIsValid = false;
      errors.Quantity = "The field Quantity must be a number.";

    }
    else {
      errors.Quantity = "";
    }

    var OrderDate = document.getElementById("OrderDate").value;

    if (OrderDate == null || OrderDate == undefined || OrderDate == "") {
      errors["MeetingDate"] = "Please select order date";
    } else {
      errors["MeetingDate"] = "";
    }
    // Validate Description field
    if (!this.state.formData.Description) {
      formIsValid = false;
      errors.Description = "Please enter description";
    } else {
      errors.Description = "";
    }

    this.setState({ errors });

    return formIsValid;
  };

  HandleBlur = (event) => {
    const { name, value } = event.target;
    const { errors } = this.state;

    if (!value) {
      errors[name] = `Please enter ${name.toLowerCase()}`;
    } else {
      if (name === "Spend" || name === "Quantity") {
        if (isNaN(value)) {
          errors[name] = `${name} field must be a number`;
        }else if (/e/i.test(value)) { // Check for the presence of 'e' (case insensitive)
          errors[name] = `The field ${name} must be a number.`;
        } else if (isNaN(value)) {
          errors[name] = `The field ${name} must be a number.`;
        }
         else if (value <= 0) {
          errors[name] = "Please enter a value greater than or equal to 1.";
        } else {
          delete errors[name];
        }
      } else {
        delete errors[name];
      }
    }
    this.setState({ errors });
  };
  

  HandleChange = (event) => {
    let errors = { ...this.state.errors };
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;

    this.setState({ formData });

    var MailType = document.getElementById("MailType").value;
    var ClientName = document.getElementById("Clients").value;

    if (event.target.name == "Client") {
      if (ClientName == undefined || ClientName == null || ClientName == "" || ClientName == "--Select--") {
        errors["Client"] = "Please select client";
      } else {
        errors["Client"] = null;
      }
    }
    if (event.target.name == "MailType") {
      if (MailType == undefined || MailType == null || MailType == "" || MailType == "--Select--") {
        errors["MailType"] = "Please select type";
      } else {
        errors["MailType"] = null;
      }
    }

    this.setState({ errors: errors });
  };

  backbtn() {
    history.push("/clientdirectmail");
  }
  savebtn() {
    this.setState({ IsLoading: true });

    this.setState({ BtnDisabled: true });
    var OrderDate = document.getElementById("OrderDate").value;
    if (this.FormValidation() && OrderDate.trim() !== "") {
      var Client = document.getElementById("Clients").value;
      var MailType = document.getElementById("MailType").value;
      var Spend = document.getElementById("Spend").value;
      var Quantity = document.getElementById("Quantity").value;
      var Description = document.getElementById("Description").value;
      var InputData = {
        ID: this.props.location.state,
        ClientID: Client,
        UserID: this.state.UserID,
        MailType: MailType,
        Quantity: Quantity,
        Spend: Spend,
        Description: Description,
        OrderDate: OrderDate,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
        Role: this.state.Role,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/client_direct_mail/ClientDirectMailUpdate",
        method: "POST",
        data: InputData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Client Direct Mail Edit
              <br />
              Client Direct Mail updated successfully.
            </div>
          );
          this.setState({ IsLoading: false });

          history.push("/clientdirectmail");
        } else {
          toast.error(res.data.Message);
          this.setState({ BtnDisabled: false });
        }
      });
    } else {
      this.FormValidation()
      this.setState({ BtnDisabled: false });
      this.setState({ IsLoading: false });
    }
  }

  render() {
    return (
      <>
        {this.state.IsLoading === true ? (
          <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div>
        ) : (
          <></>
        )}

        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left pl-0">
                    Edit Client Direct Mail{" "}
                  </h4>
                </div>
              </div>

            <div className="paddcols">
              <div className="row pt-4">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Client</label>
                    <div className="col-lg-8">
                      <select
                        className="form-control"
                        name="Client"
                        id="Clients"
                        value={this.state.formData.Client}
                        onChange={this.HandleChange}
                       // onBlur={this.HandleBlur}
                      >
                        <option value="">--Select--</option>
                        {this.state.ClientList.map((Clients) => (
                          <option value={Clients._id}>{Clients.Name}</option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["Client"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Type</label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field Status must be a number."
                        id="MailType"
                        name="MailType"
                        value={this.state.formData.MailType}
                        onChange={this.HandleChange}
                        //onBlur={this.HandleBlur}
                      >
                        <option value="">Select</option>
                        {this.state.MailTypeList.map((val) => (
                          <option value={val.FieldID}>{val.FieldName}</option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["MailType"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Spend</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="Spend"
                        name="Spend"
                        placeholder="Enter Spend"
                        type="number"
                        min="0"
                        value={this.state.formData.Spend}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Spend"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Order Date</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="OrderDate"
                        name="MeetingDate"
                        placeholder="Enter Order date"
                        type="date"
                        // value={this.state.formData.MeetingDate}
                        // onChange={this.HandleChange}
                        // onBlur={this.HandleBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["MeetingDate"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Quantity</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        id="Quantity"
                        name="Quantity"
                        placeholder="Enter Quantity"
                        type="number"
                        min="0"
                        value={this.state.formData.Quantity}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Quantity"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Description</label>
                    <div className="col-lg-8">
                      <textarea
                        className="form-control m-input"
                        type="text"
                        id="Description"
                        name="Description"
                        value={this.state.formData.Description}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      ></textarea>
                      <span style={{ color: "red" }}>
                        {this.state.errors["Description"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          

              <div className="row py-4">
                <div className="col-lg-12 pull-left">
                  <button
                    id="submit"
                    disabled={this.state.BtnDisabled}
                    onClick={this.savebtn}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <a
                    id="usersperclient"
                    onClick={this.backbtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
            </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditClientDirectMailPage = connect(
  mapState,
  actionCreators
)(EditClientDirectMailPage);
export { connectedEditClientDirectMailPage as EditClientDirectMailPage };
