import React from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import DeliverabilityTesting from '../../_components/user/DeliverabilityTesting/DeliverabilityTesting';
//import CollapsibleTable from '../_components/usertable';
import Popup from "reactjs-popup";


toast.configure()
import { CommonConstants } from '../../_constants/common.constants';
import{GetUserDetails} from '../../_helpers/Utility'
import { history } from "../../_helpers";

class DeliverabilityTrackingPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ClientID:"",
            UserID:"",
            DeliverabilityTrackingID :""
        };

        this.SaveBcc = this.SaveBcc.bind(this);

    }
    componentDidMount(){
        document.title="Deliverability Tracking | SalesHive"
        var Details=GetUserDetails();
        
        if(Details != null)
        {
            this.state.ClientID= (Details.ClientID);
            this.state.UserID=(Details.ChildUserID);
          
        }
        this.GetBCCMail(Details.ParentUserID)
      }

      GetBCCMail(Userid){
        var data={
            UserID:Userid,
        };
        Axios({
            url: CommonConstants.MOL_APIURL + "/deliverAbilitytracking/DeliverAbilityTrackingGet",
            method: "POST",
            data: data
        }).then(res => {
            
          if (res.data.StatusMessage == "SUCCESS") {
                this.state.DeliverabilityTrackingID=res.data.Data?._id;
                document.getElementById('BCCEmail').value=res.data.Data.BCCEmail;
          } else {
            
          }
        });
      }

      SaveBcc(){
        let BCCEmail=document.getElementById('BCCEmail').value.trim();
        let EmailsArr = BCCEmail.split(',');
        let EmailStatus = true;
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        if(EmailsArr.length != 0 && BCCEmail.length != 0){
            EmailsArr.forEach((elem)=>{
                if(!emailRegex.test(elem)){
                   return EmailStatus = false;
                }
            })
        }
        
  if(EmailStatus == false){
    toast.error(<div className="toastsize">Please enter valid email!</div>);
  }else{
    var Data={
        ID:this.state.DeliverabilityTrackingID,
        BCCEmail:BCCEmail,
        LastUpdatedDate:new Date(),
        LastUpdatedBy:this.state.UserID
    };
    Axios({
        url: CommonConstants.MOL_APIURL + "/deliverAbilitytracking/DeliverAbilityTrackingUpdate",
        method: "POST",
        data: Data
    }).then(res => {
        
      if (res.data.StatusMessage == "SUCCESS") {
            document.getElementById('BCCEmail').value=res.data.Data.BCCEmail;
            toast.success(<div className="toastsize">DeliverAbility Tracking <br/>Deliverability tracking updated successfully.</div>)
      } else {
        
      }
    });
  }
        
      }

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitlenop float-left px-1 py-4">Deliverability Tracking</h4>
                            <div className="listing-li float-right padb-15">
                               
                            </div>
                        </div>
                    </div>
 
                <div className="whiterow pt-4">
                    <div class="col-xs-12">  
                        <div class="row pt-2 pb-4 px-4 d-flex align-items-center">
                            <label class="col-lg-2 col-form-labe px-4">BCC Email</label>
                            <div class="col-lg-8">
                                <input class="form-control" id="BCCEmail" name="BCCEmail" placeholder="BCC Email" type="text"  />
                            </div>
                        </div>  
                    </div>
                </div>
                <div className="py-4 mt-1"> 
                    <div class="row px-1">
                        <div class="col-lg-12 pull-left">    
                            
                        <a id="submit" class="btn btn-primary btn-lightgreen mr-1" onClick={this.SaveBcc}>
                            <i class="la la-save"></i> Save
                        </a>
                        </div>
                    </div>
                </div> 
    
                      
                   

                </div>

                    

                     

                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedDeliverabilityTrackingPage = connect(mapState, actionCreators)(DeliverabilityTrackingPage);
export { connectedDeliverabilityTrackingPage as DeliverabilityTrackingPage };