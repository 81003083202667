import React, { useEffect } from "react";


const ChatbotComponent = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "module";
    script.src = "https://saleshive.nyc3.cdn.digitaloceanspaces.com/embed.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.Chatbot.init({
        chatflowConfig: {
          pineconeNamespace: "8ed2f192-529a-43ac-af0c-b0c1044bba3f"
        }
      });
    };

    // Clean up after component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  
  return <div id="chatbot-container"></div>;
  
};

export default ChatbotComponent;