import React, { useEffect } from "react";
import FusionCharts from 'fusioncharts';
import ReactFC from 'react-fusioncharts';
import Widgets from 'fusioncharts/fusioncharts.widgets';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import Axios from "axios";
import { toast } from "react-toastify";
import ComputerIcon from '@material-ui/icons/Computer';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import loadingicon from "../../../images/loading.gif";
import ReactApexChart from 'react-apexcharts';

// Resolves charts dependancy
ReactFC.fcRoot(FusionCharts, Widgets, FusionTheme);

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

const red = "#BA2D0B";
const orange = "#F8BF58";
const purplegreen = "#0075A2";
const green = "#20BF55";

export default function CoreWebVitals({ IsRerunCoreWebVitals, SeoReporting }) {


    const [ClientID, setClientID] = React.useState("000000000000000000000000");
    const [IsReload, setIsReload] = React.useState(false);
    const [Domain, setDomain] = React.useState(null);

    const [WebPerformanceScore, setWebPerformanceScore] = React.useState(0);
    const [WebFirstContentfulPaint, setWebFirstContentfulPaint] = React.useState(0);
    const [WebLargestContentfulPaint, setWebLargestContentfulPaint] = React.useState(0);
    const [WebTotalBlockingTime, setWebTotalBlockingTime] = React.useState(0);
    const [WebCumulativeLayoutShift, setWebCumulativeLayoutShift] = React.useState(0);
    const [WebSpeedIndex, setWebSpeedIndex] = React.useState(0);

    const [MobilePerformanceScore, setMobilePerformanceScore] = React.useState(0);
    const [MobileFirstContentfulPaint, setMobileFirstContentfulPaint] = React.useState(0);
    const [MobileLargestContentfulPaint, setMobileLargestContentfulPaint] = React.useState(0);
    const [MobileTotalBlockingTime, setMobileTotalBlockingTime] = React.useState(0);
    const [MobileCumulativeLayoutShift, setMobileCumulativeLayoutShift] = React.useState(0);
    const [MobileSpeedIndex, setMobileSpeedIndex] = React.useState(0);
    const [IsLoading, SetIsLoading] = React.useState(false)
    const [WebVitalsWeek, SetWebVitalsWeek] = React.useState([]);
    const [WebVitalPerformanceScore, SetWebVitalPerformanceScore] = React.useState([]);
    const [MobileWeek, SetMobileWeek] = React.useState([]);
    const [MobilevitalPerformanceScore, SetMobilevitalPerformanceScore] = React.useState([]);

    const [WebPerformanceScoreColor, SetWebPerformanceScoreColor] = React.useState("");
    const [WebFirstContentfulPaintColor, SetWebFirstContentfulPaintColor] = React.useState("");
    const [WebLargestContentfulPaintColor, SetWebLargestContentfulPaint] = React.useState("");
    const [WebTotalBlockingTimeColor, SetWebTotalBlockingTimeColor] = React.useState("");
    const [WebCumulativeLayoutShiftColor, SetWebCumulativeLayoutShiftColor] = React.useState("");
    const [WebSpeedIndexColor, SetWebSpeedIndexColor] = React.useState("");

    const [MobilePerformanceScoreColor, SetMobilePerformanceScoreColor] = React.useState("");
    const [MobileFirstContentfulPaintColor, SetMobileFirstContentfulPaintColor] = React.useState("");
    const [MobileLargestContentfulPaintColor, SetMobileLargestContentfulPaintColor] = React.useState("");
    const [MobileTotalBlockingTimeColor, SetMobileTotalBlockingTimeColor] = React.useState("");
    const [MobileCumulativeLayoutShiftColor, SetMobileCumulativeLayoutShiftColor] = React.useState("");
    const [MobileSpeedIndexColor, SetMobileSpeedIndexColor] = React.useState("");



    useEffect(() => {
        var UserDetails = GetUserDetails();
        if (UserDetails != null) {
            if (IsRerunCoreWebVitals) {
                RerunCoreWebVitals(UserDetails.ClientID, Domain)
            }
        }
    }, [IsRerunCoreWebVitals])

    useEffect(() => {
        document.title = `Core Web Vitals | SalesHive`;
        var UserDetails = GetUserDetails();
        if (UserDetails != null) {
            setClientID(UserDetails.ClientID);
            GetCoreWebVitals(UserDetails.ClientID)
            GetMobileWebVitals(UserDetails.ClientID)
            GetWebVitalsPerformancegraph(UserDetails.ClientID)
            GetMobilePerformancegraph(UserDetails.ClientID)
        }

    }, [IsReload]);

    const GetCoreWebVitals = (ClientID) => {
        SetIsLoading(true)
        var requestData = {
            ClientID: ClientID,
            VitalsType: "WebVitals"
        };
        Axios({
            url: CommonConstants.MOL_APIURL + "/seoreporting/corewebvitalsget",
            method: "POST",
            data: requestData,
        }).then(response => {
            if (response.data.StatusMessage == "SUCCESS") {
                if (response.data.PageData.length > 0) {
                    var objCoreWebVital = response.data.PageData[0];
                    setDomain(response.data?.ClientDetails?.Website);
                    SeoReporting(response.data?.ClientDetails?.IsSeoReporting);

                    var WebPerformanceScoredata = (Math.round(objCoreWebVital?.PerformanceScore * 100))
                    setWebPerformanceScore(WebPerformanceScoredata)
                    WebColorSet("WebPerformanceScore", WebPerformanceScoredata)

                    var WebFirstContentfulPaintdata = (Math.round((objCoreWebVital.LighthouseMetrics.find(c => c.auditName === "first contentful-paint")?.Score || 0) * 100));
                    setWebFirstContentfulPaint(WebFirstContentfulPaintdata)
                    WebColorSet("WebFirstContentfulPaint", WebFirstContentfulPaintdata)

                    var WebLargestContentfulPaintdata = (Math.round((objCoreWebVital.LighthouseMetrics.find(c => c.auditName === "largest contentful-paint")?.Score || 0) * 100));
                    setWebLargestContentfulPaint(WebLargestContentfulPaintdata)
                    WebColorSet("WebLargestContentfulPaint", WebLargestContentfulPaintdata)

                    var WebTotalBlockingTimedata = (Math.round((objCoreWebVital.LighthouseMetrics.find(c => c.auditName === "total blocking-time")?.Score || 0) * 100));
                    setWebTotalBlockingTime(WebTotalBlockingTimedata)
                    WebColorSet("WebTotalBlockingTime", WebTotalBlockingTimedata)

                    var WebCumulativeLayoutShiftdata = (Math.round((objCoreWebVital.LighthouseMetrics.find(c => c.auditName === "cumulative layout-shift")?.Score || 0) * 100));
                    setWebCumulativeLayoutShift(WebCumulativeLayoutShiftdata)
                    WebColorSet("WebCumulativeLayoutShift", WebCumulativeLayoutShiftdata)

                    var WebSpeedIndexdata = (Math.round((objCoreWebVital.LighthouseMetrics.find(c => c.auditName === "speed index")?.Score || 0) * 100));
                    setWebSpeedIndex(WebSpeedIndexdata)
                    WebColorSet("WebSpeedIndex", WebSpeedIndexdata)

                } else {
                    setDomain(response.data?.Website);
                    SeoReporting(response.data?.ClientDetails?.IsSeoReporting);
                    setWebPerformanceScore(0)
                    setWebFirstContentfulPaint(0)
                    setWebLargestContentfulPaint(0)
                    setWebTotalBlockingTime(0)
                    setWebCumulativeLayoutShift(0)
                    setWebSpeedIndex(0)
                    SetWebPerformanceScoreColor(red)
                    SetWebFirstContentfulPaintColor(red)
                    SetWebLargestContentfulPaint(red)
                    SetWebTotalBlockingTimeColor(red)
                    SetWebCumulativeLayoutShiftColor(red)
                    SetWebSpeedIndexColor(red)

                }
            } else {
                setWebPerformanceScore(0)
                setWebFirstContentfulPaint(0)
                setWebLargestContentfulPaint(0)
                setWebTotalBlockingTime(0)
                setWebCumulativeLayoutShift(0)
                setWebSpeedIndex(0)
                SetWebPerformanceScoreColor(red)
                SetWebFirstContentfulPaintColor(red)
                SetWebLargestContentfulPaint(red)
                SetWebTotalBlockingTimeColor(red)
                SetWebCumulativeLayoutShiftColor(red)
                SetWebSpeedIndexColor(red)
            }
            SetIsLoading(false)


        }).catch(error => {
            SetIsLoading(false)
            console.error('Error fetching core web vitals:', error);
        });
    };

    const GetMobileWebVitals = (ClientID) => {
        SetIsLoading(true)
        var requestData = {
            ClientID: ClientID,
            VitalsType: "MobileVitals"
        };
        Axios({
            url: CommonConstants.MOL_APIURL + "/seoreporting/corewebvitalsget",
            method: "POST",
            data: requestData,
        }).then(response => {
            if (response.data.StatusMessage == "SUCCESS") {
                if (response.data.PageData.length > 0) {
                    var objCoreWebVital = response.data.PageData[0];
                    setDomain(response.data?.Website);


                    var MobilePerformanceScoredata = (Math.round(objCoreWebVital?.PerformanceScore * 100))
                    setMobilePerformanceScore(MobilePerformanceScoredata)
                    MobileColorSet("MobilePerformanceScore", MobilePerformanceScoredata)


                    var MobileFirstContentfulPaintdata = (Math.round((objCoreWebVital.LighthouseMetrics.find(c => c.auditName === "first contentful-paint")?.Score || 0) * 100));
                    setMobileFirstContentfulPaint(MobileFirstContentfulPaintdata)
                    MobileColorSet("MobileFirstContentfulPaint", MobileFirstContentfulPaintdata)

                    var MobileLargestContentfulPaintdata = (Math.round((objCoreWebVital.LighthouseMetrics.find(c => c.auditName === "largest contentful-paint")?.Score || 0) * 100));
                    setMobileLargestContentfulPaint(MobileLargestContentfulPaintdata)
                    MobileColorSet("MobileLargestContentfulPaint", MobileLargestContentfulPaintdata)


                    var MobileTotalBlockingTimedata = (Math.round((objCoreWebVital.LighthouseMetrics.find(c => c.auditName === "total blocking-time")?.Score || 0) * 100));
                    setMobileTotalBlockingTime(MobileTotalBlockingTimedata)
                    MobileColorSet("MobileTotalBlockingTime", MobileTotalBlockingTimedata)


                    var MobileCumulativeLayoutShiftdata = (Math.round((objCoreWebVital.LighthouseMetrics.find(c => c.auditName === "cumulative layout-shift")?.Score || 0) * 100));
                    setMobileCumulativeLayoutShift(MobileCumulativeLayoutShiftdata)
                    MobileColorSet("MobileCumulativeLayoutShift", MobileCumulativeLayoutShiftdata)


                    var MobileSpeedIndexdata = (Math.round((objCoreWebVital.LighthouseMetrics.find(c => c.auditName === "speed index")?.Score || 0) * 100));
                    setMobileSpeedIndex(MobileSpeedIndexdata)
                    MobileColorSet("MobileSpeedIndex", MobileSpeedIndexdata)

                } else {
                    setDomain(response.data?.Website);
                    setMobilePerformanceScore(0)
                    setMobileFirstContentfulPaint(0)
                    setMobileLargestContentfulPaint(0)
                    setMobileTotalBlockingTime(0)
                    setMobileCumulativeLayoutShift(0)
                    setMobileSpeedIndex(0)
                    SetMobilePerformanceScoreColor(red)
                    SetMobileFirstContentfulPaintColor(red)
                    SetMobileLargestContentfulPaintColor(red)
                    SetMobileTotalBlockingTimeColor(red)
                    SetMobileCumulativeLayoutShiftColor(red)
                    SetMobileSpeedIndexColor(red)

                }
            } else {
                setMobilePerformanceScore(0)
                setMobileFirstContentfulPaint(0)
                setMobileLargestContentfulPaint(0)
                setMobileTotalBlockingTime(0)
                setMobileCumulativeLayoutShift(0)
                setMobileSpeedIndex(0)
                SetMobilePerformanceScoreColor(red)
                SetMobileFirstContentfulPaintColor(red)
                SetMobileLargestContentfulPaintColor(red)
                SetMobileTotalBlockingTimeColor(red)
                SetMobileCumulativeLayoutShiftColor(red)
                SetMobileSpeedIndexColor(red)
            }
            SetIsLoading(false)


        }).catch(error => {
            console.error('Error fetching core mobile vitals:', error);
            SetIsLoading(false)
        });
    };
    const RerunCoreWebVitals = (ClientID, Domain) => {
        SetIsLoading(true)
        var requestData = {
            ClientID: ClientID,
            Domain: Domain
        };
        Axios({
            url: CommonConstants.MOL_APIURL + "/seoreporting/reruncorewebvitals",
            method: "POST",
            data: requestData,
        }).then(response => {
            if (response.data.StatusMessage == "SUCCESS") {
                toast.success(<div className="toastsize">Re-run successfully.</div>);
                SetIsLoading(false)
                setIsReload(true)

            } else {
                toast.error(<div className="toastsize">Internal server error</div>);
                SetIsLoading(false)
                setIsReload(true)
            }



        }).catch(error => {
            console.error('Error fetching core mobile vitals:', error);
        });
    }

    const GetWebVitalsPerformancegraph = (ClientID) => {
        var requestData = {
            ClientID: ClientID,
            VitalsType: "WebVitals"

        };

        Axios({
            url: CommonConstants.MOL_APIURL + "/seoreporting/webvitalgraphget",
            method: "POST",
            data: requestData,
        }).then(response => {
            if (response.data.StatusMessage == "SUCCESS") {
                if (response.data.PageData.length > 0) {
                    var lstWebVitalsWeek = [], lstWebVitalPerformanceScore = []
                    for (var i = 0; i < response.data.PageData.length; i++) {
                        var item = response.data.PageData[i]
                        lstWebVitalsWeek.push(item.WeekDay)
                        lstWebVitalPerformanceScore.push(Math.round(item.PerformanceScore))
                    }
                    SetWebVitalsWeek(lstWebVitalsWeek)
                    SetWebVitalPerformanceScore(lstWebVitalPerformanceScore);


                } else {
                    SetWebVitalsWeek([])
                    SetWebVitalPerformanceScore([])
                }
            } else {
                SetWebVitalsWeek([])
                SetWebVitalPerformanceScore([])
            }
        }).catch(error => {
            console.error('Error fetching Get Web Vitals Performancegraph:', error);
        });
    };

    const GetMobilePerformancegraph = (ClientID) => {
        var requestData = {
            ClientID: ClientID,
            VitalsType: "MobileVitals"

        };
        Axios({
            url: CommonConstants.MOL_APIURL + "/seoreporting/webvitalgraphget",
            method: "POST",
            data: requestData,
        }).then(response => {
            if (response.data.StatusMessage == "SUCCESS") {
                if (response.data.PageData.length > 0) {
                    var lstMobileWeek = [], lstMobilePerformanceScore = []
                    for (var i = 0; i < response.data.PageData.length; i++) {
                        var item = response.data.PageData[i]
                        lstMobileWeek.push(item.WeekDay)
                        lstMobilePerformanceScore.push(Math.round(item.PerformanceScore))
                    }
                    SetMobileWeek(lstMobileWeek)
                    SetMobilevitalPerformanceScore(lstMobilePerformanceScore);


                } else {
                    SetMobileWeek([])
                    SetMobilevitalPerformanceScore([])
                }
            } else {
                SetMobileWeek([])
                SetMobilevitalPerformanceScore([])
            }
        }).catch(error => {
            console.error('Error fetching Get Web Vitals Performancegraph:', error);
        });
    };
    const WebColorSet = (Type, Score) => {
        if (Type == "WebPerformanceScore") {
            if (Score <= 25) {
                SetWebPerformanceScoreColor(red)
            }
            else if (Score >= 26 && Score <= 50) {
                SetWebPerformanceScoreColor(orange)
            } else if (Score >= 51 && Score <= 75) {
                SetWebPerformanceScoreColor(purplegreen)
            }
            else if (Score >= 76) {
                SetWebPerformanceScoreColor(green)
            }

        } else if (Type == "WebFirstContentfulPaint") {
            if (Score <= 25) {
                SetWebFirstContentfulPaintColor(red)
            }
            else if (Score >= 26 && Score <= 50) {
                SetWebFirstContentfulPaintColor(orange)
            } else if (Score >= 51 && Score <= 75) {
                SetWebFirstContentfulPaintColor(purplegreen)
            }
            else if (Score >= 76) {
                SetWebFirstContentfulPaintColor(green)
            }
        }
        else if (Type == "WebLargestContentfulPaint") {
            if (Score <= 25) {
                SetWebLargestContentfulPaint(red)
            }
            else if (Score >= 26 && Score <= 50) {
                SetWebLargestContentfulPaint(orange)
            } else if (Score >= 51 && Score <= 75) {
                SetWebLargestContentfulPaint(purplegreen)
            }
            else if (Score >= 76) {
                SetWebLargestContentfulPaint(green)
            }
        }
        else if (Type == "WebTotalBlockingTime") {
            if (Score <= 25) {
                SetWebTotalBlockingTimeColor(red)
            }
            else if (Score >= 26 && Score <= 50) {
                SetWebTotalBlockingTimeColor(orange)
            } else if (Score >= 51 && Score <= 75) {
                SetWebTotalBlockingTimeColor(purplegreen)
            }
            else if (Score >= 76) {
                SetWebTotalBlockingTimeColor(green)
            }
        }
        else if (Type == "WebCumulativeLayoutShift") {
            if (Score <= 25) {
                SetWebCumulativeLayoutShiftColor(red)
            }
            else if (Score >= 26 && Score <= 50) {
                SetWebCumulativeLayoutShiftColor(orange)
            } else if (Score >= 51 && Score <= 75) {
                SetWebCumulativeLayoutShiftColor(purplegreen)
            }
            else if (Score >= 76) {
                SetWebCumulativeLayoutShiftColor(green)
            }
        }
        else if (Type == "WebSpeedIndex") {
            if (Score <= 25) {
                SetWebSpeedIndexColor(red)
            }
            else if (Score >= 26 && Score <= 50) {
                SetWebSpeedIndexColor(orange)
            } else if (Score >= 51 && Score <= 75) {
                SetWebSpeedIndexColor(purplegreen)
            }
            else if (Score >= 76) {
                SetWebSpeedIndexColor(green)
            }
        }


    }
    const MobileColorSet = (Type, Score) => {

        if (Type == "MobilePerformanceScore") {
            if (Score <= 25) {
                SetMobilePerformanceScoreColor(red)
            }
            else if (Score >= 26 && Score <= 50) {
                SetMobilePerformanceScoreColor(orange)
            } else if (Score >= 51 && Score <= 75) {
                SetMobilePerformanceScoreColor(purplegreen)
            }
            else if (Score >= 76) {
                SetMobilePerformanceScoreColor(green)
            }
        } else if (Type == "MobileFirstContentfulPaint") {
            if (Score <= 25) {
                SetMobileFirstContentfulPaintColor(red)
            }
            else if (Score >= 26 && Score <= 50) {
                SetMobileFirstContentfulPaintColor(orange)
            } else if (Score >= 51 && Score <= 75) {
                SetMobileFirstContentfulPaintColor(purplegreen)
            }
            else if (Score >= 76) {
                SetMobileFirstContentfulPaintColor(green)
            }
        }
        else if (Type == "MobileLargestContentfulPaint") {
            if (Score <= 25) {
                SetMobileLargestContentfulPaintColor(red)
            }
            else if (Score >= 26 && Score <= 50) {
                SetMobileLargestContentfulPaintColor(orange)
            } else if (Score >= 51 && Score <= 75) {
                SetMobileLargestContentfulPaintColor(purplegreen)
            }
            else if (Score >= 76) {
                SetMobileLargestContentfulPaintColor(green)
            }
        }
        else if (Type == "MobileTotalBlockingTime") {
            if (Score <= 25) {
                SetMobileTotalBlockingTimeColor(red)
            }
            else if (Score >= 26 && Score <= 50) {
                SetMobileTotalBlockingTimeColor(orange)
            } else if (Score >= 51 && Score <= 75) {
                SetMobileTotalBlockingTimeColor(purplegreen)
            }
            else if (Score >= 76) {
                SetMobileTotalBlockingTimeColor(green)
            }
        }
        else if (Type == "MobileCumulativeLayoutShift") {
            if (Score <= 25) {
                SetMobileCumulativeLayoutShiftColor(red)
            }
            else if (Score >= 26 && Score <= 50) {
                SetMobileCumulativeLayoutShiftColor(orange)
            } else if (Score >= 51 && Score <= 75) {
                SetMobileCumulativeLayoutShiftColor(purplegreen)
            }
            else if (Score >= 76) {
                SetMobileCumulativeLayoutShiftColor(green)
            }
        }
        else if (Type == "MobileSpeedIndex") {
            if (Score <= 25) {
                SetMobileSpeedIndexColor(red)
            }
            else if (Score >= 26 && Score <= 50) {
                SetMobileSpeedIndexColor(orange)
            } else if (Score >= 51 && Score <= 75) {
                SetMobileSpeedIndexColor(purplegreen)
            }
            else if (Score >= 76) {
                SetMobileSpeedIndexColor(green)
            }
        }

    }

    const WebVitalsgraph = {
        chart: {
            //caption: "Performance Score",
            lowerlimit: "0",
            upperlimit: "100",
            showvalue: "1",
            //numbersuffix: " Score",
            theme: "fusion",
            showtooltip: "0",
            captionFontColor: "#000",
            captionFontWeight: 700,
            captionFontname: 'Montserrant',
            toolbar: {
              show: false,  
            } 
        },
        colorrange: {
            color: [
                {
                    minvalue: "0",
                    maxvalue: "25",
                    code: "#BA2D0B",
                },
                {
                    minvalue: "26",
                    maxvalue: "50",
                    code: "#F8BF58",
                },
                {
                    minvalue: "51",
                    maxvalue: "75",
                    code: "#0075A2",

                },
                {
                    minvalue: "76",
                    maxvalue: "100",
                    code: "#20BF55",
                },
            ],
        },
        dials: {
            dial: [
                {
                    value: WebPerformanceScore,
                },
            ],
        },
    };

    const WebVitalchartConfigs = {
        type: 'angulargauge', // The chart type
        width: '100%', // Width of the chart
        height: 270, // Height of the chart
        dataFormat: 'json', // Data type
        dataSource: WebVitalsgraph, // Chart data
    };

    const MobileVitalsgraph = {
        chart: {
            //caption: "Performance Score",
            lowerlimit: "0",
            upperlimit: "100",
            showvalue: "1",
            //numbersuffix: " Score",
            theme: "fusion",
            showtooltip: "0",
            captionFontColor: "#000",
            captionFontWeight: 700,
            captionFontname: 'Montserrant',
            toolbar: {
              show: false,  
            } 
        },
        colorrange: {
            color: [
                {
                    minvalue: "0",
                    maxvalue: "25",
                    code: "#BA2D0B",
                },
                {
                    minvalue: "26",
                    maxvalue: "50",
                    code: "#F8BF58",
                },
                {
                    minvalue: "51",
                    maxvalue: "75",
                    code: "#0075A2",

                },
                {
                    minvalue: "76",
                    maxvalue: "100",
                    code: "#20BF55",
                },
            ],
        },
        dials: {
            dial: [
                {
                    value: MobilePerformanceScore,
                },
            ],
        },
    };

    const MobileVitalchartConfigs = {
        type: 'angulargauge', // The chart type
        width: '100%', // Width of the chart
        height: 270, // Height of the chart
        dataFormat: 'json', // Data type
        dataSource: MobileVitalsgraph, // Chart data
    };

    var graphwebvitals =
        [

            {
                name: 'Others',
                color: '#404968',
                data: WebVitalPerformanceScore
            },

        ]
    var graphwebvitalsopation = {
        chart: {
            type: 'line',
            height: 380,
            stacked: true,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: 0,
                    offsetY: 0
                }
            }
        }],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 0,
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            },
        },
        xaxis: {
            //type: 'datetime',
            categories: WebVitalsWeek,
        },
        legend: {
            position: "top",
            offsetY: 0,
            offsetX: 0,
        },
        fill: {
            opacity: 1
        },
        stroke: { 
            width: [2, 2, 2, 2],
            curve: 'smooth'
        },
        markers: {
          size: 4,
          strokeWidth: 1.5,
          hover: {
            size: 6 // Size of the marker when hovered
          }
        }
    }
    var graphmobilevitals =
        [

            {
                name: 'Others',
                color: '#404968',
                data: MobilevitalPerformanceScore
            },

        ]
    var graphmobilevitalsopation = {
        chart: {
            type: 'line',
            height: 380,
            stacked: true,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: 0,
                    offsetY: 0
                }
            }
        }],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 0,
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            },
        },
        xaxis: {
            //type: 'datetime',
            categories: MobileWeek,
        },
        legend: {
            position: "top",
            offsetY: 0,
            offsetX: 0,
        },
        fill: {
            opacity: 1
        },
        stroke: { 
            width: [2, 2, 2, 2],
            curve: 'smooth'
        },
        markers: {
          size: 4,
          strokeWidth: 1.5,
          hover: {
            size: 6 // Size of the marker when hovered
          }
        }
    }

    return (
        <>

            {
                IsLoading ? <div id="hideloding" className="loding-display">
                    <img src={loadingicon} />
                </div> : <></>
            }
            <div className="row">
                <div className="col mb-3">
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="bg-white h-100">
                                    <h6 className="chartheading mb-5 pl-3 pt-3">Desktop Vitals: Performance Score </h6>
                                <div className="chartboxsmet px-0">
                                    <ReactApexChart options={graphwebvitalsopation} series={graphwebvitals} type="line" height={350} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="bg-white h-100">
                                    <h6 className="chartheading mb-5 pl-3 pt-3">Mobile Vitals: Performance Score</h6>
                                <div className="chartboxsmet px-0">
                                    <ReactApexChart options={graphmobilevitalsopation} series={graphmobilevitals} type="line" height={350} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col'>
                    <h4 className="headertitle float-left">
                        <ComputerIcon className='mr-2' /> Desktop Vitals {/* : {Domain} */}
                    </h4>
                </div>
            </div>

            <div className='coreweb'>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="bg-white boxcardcounter h-100 text-center">
                            <h4 className='text-center hero-title'>Performance Score</h4>
                            <div className='px-2 mt-3 max-250-w'>
                                <ReactFC {...WebVitalchartConfigs} />
                            </div>
                            <div className='boxcodenum-main '>
                                <span>{WebPerformanceScore}</span>
                            </div>
                            <p className='px-2 max-250-w'>A comprehensive metric that aggregates the following performance indicators to
                                give an overall assessment of a page’s speed and user experience quality.</p>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className='bg-white pt-3 px-3'>
                            <div className='row'>
                                <div className="col-md-6 col-lg-4 mb-4">
                                    <div style={{ "backgroundColor": WebPerformanceScoreColor }} className="boxcardcounter h-100">
                                        <div className='boxcodenum'>
                                            <span>{WebPerformanceScore}</span>
                                        </div>
                                        <h6>Performance Score <ComputerIcon className='mr-2' /></h6>
                                        <p>A comprehensive metric that aggregates the following performance indicators to give an overall assessment of a page’s speed and user experience quality.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 mb-4">
                                    <div style={{ "backgroundColor": WebFirstContentfulPaintColor }} className="boxcardcounter h-100">
                                        <div className='boxcodenum'>
                                            <span>{WebFirstContentfulPaint}</span>
                                        </div>
                                        <h6>First Contentful Paint <ComputerIcon className='mr-2' /></h6>
                                        <p>First Contentful Paint (FCP): Measures the time from navigating to a page until the first text or image
                                            is painted on the screen, providing an early indicator of page load speed.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 mb-4">
                                    <div style={{ "backgroundColor": WebLargestContentfulPaintColor }} className="boxcardcounter h-100">
                                        <div className='boxcodenum'>
                                            <span>{WebLargestContentfulPaint}</span>
                                        </div>
                                        <h6>Largest Contentful Paint <ComputerIcon className='mr-2' /></h6>
                                        <p>Largest Contentful Paint (LCP): Tracks the time at which the largest text or image element is
                                            rendered on the screen, offering a sense of when the main content of the page has become visible.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 mb-4">
                                    <div style={{ "backgroundColor": WebTotalBlockingTimeColor }} className="boxcardcounter h-100">
                                        <div className='boxcodenum'>
                                            <span>{WebTotalBlockingTime}</span>
                                        </div>
                                        <h6>Total Blocking Time <ComputerIcon className='mr-2' /></h6>
                                        <p>Total Blocking Time (TBT): Quantifies the total amount of time between First Contentful Paint and
                                            Time to Interactive during which input responsiveness is hindered by the main thread being blocked.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 mb-4">
                                    <div style={{ "backgroundColor": WebCumulativeLayoutShiftColor }} className="boxcardcounter h-100">
                                        <div className='boxcodenum'>
                                            <span>{WebCumulativeLayoutShift}</span>
                                        </div>
                                        <h6>Cumulative Layout Shift <ComputerIcon className='mr-2' /></h6>
                                        <p>Cumulative Layout Shift (CLS): Assesses the stability of a page by measuring the sum of all
                                            unexpected layout shifts that occur during the lifespan of the page view.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 mb-4">
                                    <div style={{ "backgroundColor": WebSpeedIndexColor }} className="boxcardcounter h-100">
                                        <div className='boxcodenum'>
                                            <span>{WebSpeedIndex}</span>
                                        </div>
                                        <h6>Speed Index <ComputerIcon className='mr-2' /></h6>
                                        <p>Page Speed Index: Provides a score based on how quickly the contents of a page are visibly
                                            populated, reflecting the user’s experience of how rapidly a page contentfully appears.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-2">
                <div className='col'>
                    <h4 className="headertitle float-left">
                        <PhoneIphoneIcon className='mr-2' /> Mobile Vitals {/* : {Domain} */}
                    </h4>
                </div>
            </div>

            <div className='coreweb'>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="bg-white boxcardcounter h-100">
                            {/* <h6 className='text-center'>Performance Score</h6> */}
                            <h4 className='text-center hero-title'>Performance Score</h4>
                            <div className='px-2'>
                                <ReactFC {...MobileVitalchartConfigs} />
                            </div>
                            <div className='boxcodenum-main '>
                                <span>{MobilePerformanceScore}</span>
                            </div>
                            <p className='px-2 max-250-w'>A comprehensive metric that aggregates the following performance indicators to
                                give an overall assessment of a page’s speed and user experience quality.</p>
                            {/* <p className='px-4'>A comprehensive metric that aggregates the following performance indicators to
                                give an overall assessment of a page’s speed and user experience quality.</p> */}
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className='bg-white pt-3 px-3'>
                            <div className='row'>
                                <div className="col-md-6 col-lg-4 mb-4">
                                    <div style={{ "background-color": MobilePerformanceScoreColor }} className="boxcardcounter h-100">
                                        <div className='boxcodenum'>
                                            <span>{MobilePerformanceScore}</span>
                                        </div>
                                        <h6>Performance Score <PhoneIphoneIcon className='mr-2' /></h6>
                                        <p>A comprehensive metric that aggregates the following performance indicators to
                                            give an overall assessment of a page’s speed and user experience quality.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 mb-4">
                                    <div style={{ "backgroundColor": MobileFirstContentfulPaintColor.toString() }} className="boxcardcounter h-100">
                                        <div className='boxcodenum'>
                                            <span>{MobileFirstContentfulPaint}</span>
                                        </div>
                                        <h6>First Contentful Paint <PhoneIphoneIcon className='mr-2' /></h6>
                                        <p>First Contentful Paint (FCP): Measures the time from navigating to a page until the first text or image
                                            is painted on the screen, providing an early indicator of page load speed.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 mb-4">
                                    <div style={{ "backgroundColor": MobileLargestContentfulPaintColor }} className="boxcardcounter h-100">
                                        <div className='boxcodenum'>
                                            <span>{MobileLargestContentfulPaint}</span>
                                        </div>
                                        <h6>Largest Contentful Paint <PhoneIphoneIcon className='mr-2' /></h6>
                                        <p>Largest Contentful Paint (LCP): Tracks the time at which the largest text or image element is
                                            rendered on the screen, offering a sense of when the main content of the page has become visible.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 mb-4">
                                    <div style={{ "backgroundColor": MobileTotalBlockingTimeColor }} className="boxcardcounter h-100">
                                        <div className='boxcodenum'>
                                            <span>{MobileTotalBlockingTime}</span>
                                        </div>
                                        <h6>Total Blocking Time <PhoneIphoneIcon className='mr-2' /></h6>
                                        <p>Total Blocking Time (TBT): Quantifies the total amount of time between First Contentful Paint and
                                            Time to Interactive during which input responsiveness is hindered by the main thread being blocked.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 mb-4">
                                    <div style={{ "backgroundColor": MobileCumulativeLayoutShiftColor }} className="boxcardcounter h-100">
                                        <div className='boxcodenum'>
                                            <span>{MobileCumulativeLayoutShift}</span>
                                        </div>
                                        <h6>Cumulative Layout Shift <PhoneIphoneIcon className='mr-2' /></h6>
                                        <p>Cumulative Layout Shift (CLS): Assesses the stability of a page by measuring the sum of all
                                            unexpected layout shifts that occur during the lifespan of the page view.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 mb-4">
                                    <div style={{ "backgroundColor": MobileSpeedIndexColor }} className="boxcardcounter h-100">
                                        <div className='boxcodenum'>
                                            <span>{MobileSpeedIndex}</span>
                                        </div>
                                        <h6>Speed Index <PhoneIphoneIcon className='mr-2' /></h6>
                                        <p>Page Speed Index: Provides a score based on how quickly the contents of a page are visibly
                                            populated, reflecting the user’s experience of how rapidly a page contentfully appears.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    );

}


