import React from "react";
import Axios from "axios";
var CryptoJS = require("crypto-js");
import { connect } from "react-redux";

import logo from "../../../images/SalesHive.png";
import logobgs from "../../../images/Loginvector.svg";

import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";


class UpdateEmailAdminUserPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Email: "",
      id: "",
      OldEmail: "",
      //_Lang: 'en',
    };

    this.SubmittedData = this.SubmittedData.bind(this);
    this.BackButton = this.BackButton.bind(this);
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    const tmp = queryParams.get("email");
    var finaltmp = tmp.split(" ").join("+");
    var FinalCode = code.split(" ").join("+");
    var bytes = CryptoJS.AES.decrypt(finaltmp, "my-secret-key@123");
    var email = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    document.getElementById("Email").value = email;
    this.setState({ Email: email });
    var InputParameters = {
      EmailToken: FinalCode,
      // Email:email
    };
    
    Axios({
      url: CommonConstants.MOL_APIURL + "/user/finduserEmailcode",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      
      this.setState({ id: res.data[0]._id });
      this.setState({ OldEmail: res.data[0].Username });
    });
  }
  BackButton() {
    window.location = CommonConstants.home;
  }

//SaveEmail
  SubmittedData() {
    var Email = document.getElementById("Email").value;
    if (this.state.Email == Email) {
      var InputParameters = {
        Email: Email,
        uID: this.state.id,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/user/updateEmail",
        method: "POST",
        data: InputParameters,
      }).then((res) => {
       
        if (res.data != "") {
          
          var InputParameters = {
            Email: Email,
            OldEmail: this.state.OldEmail,
          };
          Axios({
            url: CommonConstants.MOL_APIURL + "/AdminUser/EmailUpdate",
            method: "POST",
            data: InputParameters,
          }).then((res) => {
            history.push("/AdminUserPage");
          });
          //resetpass_u.style.display = "block";
          // this.setState({
          //     open: true
          // });
          //window.location = CommonConstants.home;
        }
      });
    }
  }

  render() {
    return (
      <div class="login-main">
        <div className="row h-100">
          <div className="col-xs-12 col-md-6 logo-bgbod padding-btn">
            <img src={logobgs} class="login-vector" />

            <div class="m-login__logo">
              <a href="/">
                <img src={logo} class="main-logo" />
              </a>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 d-flex">
            {/* Login Form in sign in page*/}
            <div className="full-hegiht">
              <div id="login" className="centerheigh ">
                <div className="heading">
                  <h1 class="lefter_title">Update Email</h1>
                </div>
                <form name="login" className="form">
                  <div class="input-control">
                    <label for="Email" className="input-label" hidden>
                      Email Address
                    </label>
                    <input
                      type="Email"
                      id="Email"
                      name="Email"
                      className="input-field"
                      placeholder="Email Address"
                      readOnly
                    />
                    {/* <p id="Email_e" className="errorinput"> Please enter Email </p> */}
                  </div>

                  <div class="input-control btn-center">
                    <a
                      type="button"
                      name="submit"
                      className="input-submit"
                      value="Save"
                      onClick={() => this.SubmittedData()}
                    >
                      <i class="la la-save"></i> Save
                    </a>
                    <a
                      type="button"
                      name="submit"
                      className="input-submit"
                      value="Save"
                      onClick={() => this.BackButton()}
                    >
                      <i class="la la-arrow-circle-left"></i> Back
                    </a>
                    {/* <p id="err_user"> Please enter Correct Email or password </p> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  return state;
}

const actionCreators = {};

const connectedUpdateEmailResponderPage = connect(
  mapState,
  actionCreators
)(UpdateEmailAdminUserPage);
export { connectedUpdateEmailResponderPage as UpdateEmailAdminUserPage };
