import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { history } from "../../../_helpers";
import { userActions } from "../../../_actions";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import loadingicon from "../../../images/loading.gif";

toast.configure();

class EditEmodProfilePage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);
    this.state = {
      Fields: {},
      Errors: {},
      StatusAvailable: null,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      Role: Details.Role,
      IsExist: false,
      IsButtonDisabled: false,
      IsLoading: false,
      OldStatus: "",
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
  }
  componentDidMount() {
    document.title = `Edit eMod Profile | SalesHive`;
    this.EditeModByID();
  }

  // Edit Function Name
  EditeModByID() {
    const id = this.props.location.state;
    this.setState({ IsLoading: true });
    this.setState({ id: id });
    var str_in = {
      id: id,
      Role: this.state.Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/eMod/eModByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
      if (res.data?.StatusMessage == "SUCCESS") {
        document.getElementById("ProfileName").value =
          res.data?.Data[0]?.Profile;
        document.getElementById("Prompt").value = res.data?.Data[0]?.Prompt;
        document.getElementById("SubjectPrompt").value =
          res.data?.Data[0]?.SubjectPrompt;
        this.setState({ OldStatus: res.data?.Data[0]?.Profile });
        this.setState({ IsLoading: false });
      } else {
        toast.error(res.data?.Message);
        this.setState({ IsLoading: false });
      }
    });
  }

  // form validation
  async FromValidations() {
    let FormIsValid = true;
    let Fields = this.state.Fields;
    let Errors = {};
    var ProfileNames = document.getElementById("ProfileName")?.value.trim();
    var Prompts = document.getElementById("Prompt")?.value.trim();

    var IsExist = await this.eModCheckExist(ProfileNames);
    // if (ProfileName == "") {
    //   FormIsValid = false;
    //   Errors["ProfileName"] = "Please select profile name";
    // }

    if (Prompts == "") {
      FormIsValid = false;
      Errors["Prompt"] = "Please enter prompt";
    }

    if (IsExist == "ExistStatus") {
      FormIsValid = false;
      Errors["ProfileName"] = "Name already exist.";
    }

    if (IsExist == "BlankData" && ProfileNames == "") {
      FormIsValid = false;
      Errors["ProfileName"] = "Please select profile name";
    }

    if (IsExist == "AllDone") {
      Errors["ProfileName"] = null;
    }

    this.setState({ Errors: Errors });
    return FormIsValid;
  }

  // form validation
  async HandleChangeBlur(Name) {
    let Errors = { ...this.state.Errors };
    var ProfileNames = document.getElementById("ProfileName")?.value.trim();
    var Prompts = document.getElementById("Prompt")?.value.trim();
    var IsExist = await this.eModCheckExist(ProfileNames);

    if (Name == "ProfileNameBlur") {
      if (ProfileNames == "" && IsExist == "BlankData") {
        Errors["ProfileName"] = "Please select profile name";
      } else {
        Errors["ProfileName"] = null;
      }
    }

    if (Name == "PromptBlur") {
      if (Prompts == "") {
        Errors["Prompt"] = "Please enter prompt";
      } else {
        Errors["Prompt"] = null;
      }
    }

    if (IsExist == "ExistStatus") {
      Errors["ProfileName"] = "Name already exist.";
    }

    if (IsExist == "AllDone") {
      Errors["ProfileName"] = null;
    }
    this.setState({ Errors: Errors });
  }

  async eModCheckExist(Name) {
    if (Name != "") {
      var MeetingSource = {
        UserID: this.state.UserID,
        Profile: Name,
        Role: this.state.Role,
      };
      var resdata = await Axios({
        url: CommonConstants.MOL_APIURL + "/eMod/eModExists",
        method: "POST",
        data: MeetingSource,
      });
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          if (this.state.OldStatus == Name) {
            return "AllDone";
          } else {
            return "ExistStatus";
          }
        } else {
          return "AllDone";
        }
      }
    } else {
      return "BlankData";
    }
  }

  // back button
  BackBtn() {
    history.push("/emodprofile");
  }

  // save meeting source
  async SaveBtn(e) {
    e.preventDefault();
    this.setState({ IsButtonDisabled: true });
    var Final_flag = await this.FromValidations();
    if (Final_flag == true) {
      this.setState({ IsLoading: true });
      var ProfileName = document.getElementById("ProfileName")?.value.trim();
      var SubjectPrompts = document
        .getElementById("SubjectPrompt")
        ?.value.trim();
      var Prompts = document.getElementById("Prompt")?.value.trim();

      var Data = {
        id: this.props.location.state,
        Profile: ProfileName,
        Prompt: Prompts,
        SubjectPrompt: SubjectPrompts,
        LastUpdatedDate: new Date(),
        LastUpdatedBy: this.state.CUserID,
        Role: this.state.Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/eMod/eModUpdate",
        method: "POST",
        data: Data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          this.setState({ IsLoading: false });
          toast.success(
            <div className="toastsize">
              eMod Profile Edit
              <br />
              eMod Profile Updated successfully.
            </div>
          );
          history.push("/emodprofile");
        } else {
          this.setState({ IsButtonDisabled: false });
          this.setState({ IsLoading: false });
          toast.error(res.data.Message);
        }
      });
    } else {
      this.setState({ IsButtonDisabled: false });
      this.setState({ IsLoading: false });
    }
  }

  render() {
    return (
      <>
        {this.state.IsLoading ? (
          <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div>
        ) : (
          <></>
        )}
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome px-3">
            <div className="row">
              <div className="col">
                <h4 className="headertitlepad float-left mb-0 py-4">
                  Add eMod Profile
                </h4>
              </div>
            </div>
            <div className="bg-white px-4">
              <div className="row pt-4 pb-4">
                <div class="col-lg-12 boxsinput_group">
                  <div class="row px-2">
                    <label class="col-lg-3 view-lable-meet">Profile</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter Profile"
                        onBlur={() => this.HandleChangeBlur("ProfileNameBlur")}
                        id="ProfileName"
                        name="Name"
                        placeholder="Enter Profile"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["ProfileName"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 boxsinput_group">
                  <div class="row px-2">
                    <label class="col-lg-3 view-lable-meet">
                      Subject Prompt
                    </label>
                    <div className="col-lg-8">
                      <textarea
                        className="form-control m-input height-89"
                        data-val="true"
                        id="SubjectPrompt"
                        name="Name"
                        placeholder=""
                        type="text"
                      ></textarea>
                      {/* <span style={{ color: "red" }}>
                        {this.state.Errors["SubjectPrompt"]}
                      </span>  */}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 boxsinput_group">
                  <div class="row px-2">
                    <label class="col-lg-3 view-lable-meet">Prompt</label>
                    <div className="col-lg-8">
                      <textarea
                        className="form-control m-input height-180"
                        data-val="true"
                        id="Prompt"
                        name="Prompt"
                        placeholder=""
                        type="text"
                        onBlur={() => this.HandleChangeBlur("PromptBlur")}
                      ></textarea>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Prompt"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row py-4">
              <div class="col-lg-12 pull-left">
                <button
                  id="submit"
                  onClick={this.SaveBtn}
                  disabled={this.state.IsButtonDisabled}
                  class="btn btn-primary btn-lightgreen mr-1"
                >
                  <i class="la la-save"></i> Save
                </button>
                <button
                  id="backtolist"
                  onClick={this.BackBtn}
                  class="btn btn-secondary"
                >
                  <i class="la la-arrow-circle-left"></i> Back
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedPotentialMeetingStatusPage = connect(
  mapState,
  actionCreators
)(EditEmodProfilePage);
export { connectedPotentialMeetingStatusPage as EditEmodProfilePage };
