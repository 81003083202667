import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
//import CallHistory from '../_components/coldcalling/callhistory';
//import CollapsibleTable from '../_components/usertable';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import ReactApexchart from "react-apexcharts";
import { CommonConstants } from "../../../_constants/common.constants";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";

const moment = require("moment");
class CViewOpportunitiesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
      statusAvailable: null,
      showing: true,
      meetingsource: [],
      potentialowner: [],
      potentialstatus: [],
      allres: [],
      oldstatus1: [],
      newstatus1: [],
      sourcemeeting: [],
      statusmeeting: [],
      timezone: [],
      data: [],
      rows: [],
      rlen: 0,
      flen: 0,
      countpage: 0,
      data1: [],
      rows1: [],
      rlen1: 0,
      flen1: 0,
      countpage1: 0,
      ClientID: null,
      UserID: null,
      CUserID: null,
      page: 1,
      rowsPerPage: 10,
      search: "",
      sortField: "Name",
      sortedBy: 1,
      sflag: false,
      page1: 1,
      rowsPerPage1: 10,
      search1: "",
      sortField1: "Name",
      sortedBy1: 1,
      sflag1: false,
    };
    this.backbtn = this.backbtn.bind(this);
    this.editbtn = this.editbtn.bind(this);
    this.editspage = this.editspage.bind(this);
    this.saveupdatebtn = this.saveupdatebtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
    this.DeleteClientName = this.DeleteClientName.bind(this);
    this.EditOpportunitiesMeetingstatus();
    // this.MeetingHistoryStatus();
  }
  componentDidMount() {
    // this.GetPotentialmeeting();
    var Details = GetUserDetails();

    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
    }
  }

  // GetPotentialmeeting() {
  //   var Details = GetUserDetails();
  //   
  //   var str_in = {
  //     ClientID: Details.ClientID,
  //     UserID: Details.ChildUserID,
  //   };
  //   
  //   const rows = Axios({
  //     url:
  //       CommonConstants.MOL_APIURL + "/potentialmeeting/findPotentialAllStatus",
  //     method: "POST",
  //     data: str_in,
  //   });
  //   rows.then((result) => {
  //     
  //     this.setState({
  //       meetingsource: result.data.meetingsource,
  //       potentialowner: result.data.potentialowner,
  //       potentialstatus: result.data.potentialstatus,
  //     });

  //     //  return result.data
  //   });
  // }

  fromValidation() {
    let formIsValid = true;
    let fields = this.state.fields;
    let errors = {};
    if (!fields["FirstName"]) {
      formIsValid = false;
      errors["FirstName"] = "Please Enter FirstName";
    }

    if (this.state.statusAvailable != null) {
      formIsValid = false;
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.FirstName != "") {
      // this.state.errors.FirstName = null;
      // this.state.statusAvailable = null;
      this.CheckExistPotentialMeetingName(e.target.value);
    } else {
      // this.state.statusAvailable = null;
      // this.state.errors.FirstName = null;
    }
  }

  CheckExistPotentialMeetingName(FirstName) {
    var str_in = {
      ClientID: this.state.ClientID,
      FirstName: FirstName,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/opportunities/OpportunitiesMeetingStatusExists",
      method: "POST",
      data: str_in,
    }).then((resdata) => {
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.data.length > 0) {
          this.setState({ statusAvailable: resdata.data.data });
        }
      }
    });
  }

  EditOpportunitiesMeetingstatus() {
    const id = this.props.location.state;
    this.setState({ id: id });
    // 
    var str_in = {
      id: id,
    };
    
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/opportunities/OpportunitiesMeetingByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
      this.setState({ allres: [res.data] });
      

      //meetingSource
      var str_in1 = {
        MeetingSourceID: res.data.MeetingSourceID,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/opportunities/findOpportunitiesMeetingSource",
        method: "POST",
        data: str_in1,
      }).then((resmeeting) => {
        this.setState({ sourcemeeting: resmeeting.data });
        // 
      });

      //MeetingTimezone
      var str_in4 = {
        TimeZoneID: res.data.TimeZoneID,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/opportunities/GetTimezoneMeeting",
        method: "POST",
        data: str_in4,
      }).then((timezoneres) => {
        this.setState({ timezone: timezoneres.data.timezone });
        // 
      });

      // notes
      // 
      this.setState({ meetingid: res.data.EventID });

      var str_in2 = {
        ClientID: this.state.ClientID,
        EventID: res.data.EventID,
        page: this.state.page,
        rowsPerPage: this.state.rowsPerPage,
        sort: true,
        field: this.state.sortField,
        sortby: this.state.sortedBy,
        search: this.state.search,
        type: "User",
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/opportunities/GetOpportunitiesMeetingNotes",
        method: "POST",
        data: str_in2,
      }).then((resnotes) => {
        // 

        this.setState({ data: resnotes.data.pagedata });
        this.setState({ rows: resnotes.data.pagedata });
        this.setState({ rlen: resnotes.data.totalcount });
        this.setState({ flen: resnotes.data.totalcount });
        this.setState({ countpage: resnotes.data.pagecount });
        // 
      });

      // Meeting status history
      var str_in3 = {
        ClientID: this.state.ClientID,
        EventID: res.data.EventID,
        page: this.state.page1,
        rowsPerPage: this.state.rowsPerPage1,
        sort: true,
        field: this.state.sortField1,
        sortby: this.state.sortedBy1,
        search: this.state.search1,
        type: "User",
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/opportunities/GetOpportunitiesMeetingStatusHistory",
        method: "POST",
        data: str_in3,
      }).then((reshistory) => {
        

        this.setState({ data1: reshistory.data.pagedata });
        this.setState({ rows1: reshistory.data.pagedata });
        this.setState({ rlen1: reshistory.data.totalcount });
        this.setState({ flen1: reshistory.data.totalcount });
        this.setState({ countpage1: reshistory.data.pagecount });
        // this.setState({ statusmeeting: reshistory.data.meetingstatus });
      });
    });
  }

  backbtn() {
    history.push("/opportunities");
  }

  editbtn = (id) => {
    history.push("/editoppotunities", id);
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    var str_in = {
      ClientID: this.state.ClientID,
      EventID: this.state.meetingid,
      page: newPage,
      rowsPerPage: this.state.rowsPerPage,
      serchbox: false,
      sort: true,
      field: this.state.sortField,
      sortby: this.state.sortedBy,
      type: "User",
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/opportunities/GetOpportunitiesMeetingNotes",
      method: "POST",
      data: str_in,
    });
    rows1.then((resnotes) => {
      // 
      this.setState({ data: resnotes.data.pagedata });
      this.setState({ rows: resnotes.data.pagedata });
      this.setState({ rlen: resnotes.data.totalcount });
      this.setState({ flen: resnotes.data.totalcount });
      this.setState({ countpage: resnotes.data.pagecount });
      
    });
  };

  //Search
  requestSearch = () => {
    var searchedVal = document.getElementById("search").value;
    this.setState({ page: 1 });
    this.setState({ rowsPerPage: 10 });
    var searchbox;
    if (searchedVal == "") {
      searchbox = false;
      this.setState({ sflag: false });
    } else {
      searchbox = true;
    }
    var str_in = {
      ClientID: this.state.ClientID,
      EventID: this.state.meetingid,
      page: 1,
      rowsPerPage: this.state.rowsPerPage,
      sort: true,
      field: this.state.sortField,
      sortby: this.state.sortedBy,
      search: searchedVal,
      type: "User",
      searchbox: searchbox,
    };
    
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/opportunities/GetOpportunitiesMeetingNotes",
      method: "POST",
      data: str_in,
    });
    rows1.then((resnotes) => {
      this.setState({ data: resnotes.data.pagedata });
      this.setState({ rows: resnotes.data.pagedata });
      this.setState({ rlen: resnotes.data.totalcount });
      this.setState({ flen: resnotes.data.totalcount });
      this.setState({ countpage: resnotes.data.pagecount });
      
    });
  };

  //Delete notes details
  DeleteClientName = (id) => {
    
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete opportunities Notes Meeting.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          _id: id,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: this.state.CUserID,
        };
        
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/opportunities/DeleteOpportunitiesNotesMeeting",
          method: "POST",
          data: data,
        }).then((res) => {
          
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Delete opportunities Notes Meeting successfully.",
                "success"
              );
              history.push("/opportunities");
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };
  chengeRowSelected = (event) => {
    

    this.setState({ rowsPerPage: event.target.value });
    this.setState({ page: 1 });
  

    // startpage("search", 1, event.target.value);
  };

  //notes

  fromValidationNotes() {
    let formIsValid = true;
    let fileds = this.state.fields;
    let errors = {};
    if (!fileds["Title"]) {
      formIsValid = false;
      errors["Title"] = "Please Enter Title";
    }

    if (this.state.statusAvailable != null) {
      formIsValid = false;
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChangeNotes(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.Title != "") {
      this.state.errors.Title = null;
      this.state.statusAvailable = null;
      this.CheckExistPotentialMeetingTitle(e.target.value);
    } else {
      this.state.statusAvailable = null;
      this.state.errors.Title = null;
    }
  }

  CheckExistPotentialMeetingTitle(Title) {
    var str_in = {
      ClientID: this.state.ClientID,
      Title: Title,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/opportunities/OpportunitiesNotesStatusExists",
      method: "POST",
      data: str_in,
    }).then((resdata) => {
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.data.length > 0) {
          this.setState({ statusAvailable: resdata.data.data });
        }
      }
    });
  }

  savebtn() {
    var Title = document.getElementById("Title1").value;
    var Notes = document.getElementById("Notes").value;

    if (
      Title == undefined ||
      Notes == undefined ||
      this.state.meetingid == null
    ) {
      
    } else {
      // var emailData = EmailTemplete.userEmail();

      var data = {
        Title: Title,
        Note: Notes,
        IsDeleted: false,
        MeetingID: this.state.meetingid,
        ClientID: this.state.ClientID,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
        LastUpdatedBy: null,
        LastUpdatedDate: null,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/opportunities/AddOpportunitiesNotesMeeting",
        method: "POST",
        data: data,
      }).then((res) => {
        
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            "opportunities meeting Status add successfully.",
            "Add opportunities meeting Status"
          );
          history.push("/opportunities");
          
        } else {
          toast.error(res.data.Message);
        }
      });
    }
  }

  editspage(id) {
    this.setState({ id: id });
    
    var str_in = {
      id: id,
    };
    
    Axios({
      url: CommonConstants.MOL_APIURL + "/opportunities/OpportunitiesNotesByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
      
      // if (res.data.StatusMessage == "SUCCESS") {
      document.getElementById("Title1").value = res.data.Title;
      document.getElementById("Notes").value = res.data.Note;
    });
  }

  saveupdatebtn() {
    var Title = document.getElementById("Title1").value;
    var Notes = document.getElementById("Notes").value;

    if (Title == undefined || Notes == undefined) {
      
    } else {
      // var emailData = EmailTemplete.userEmail();

      var data = {
        _id: this.state.id,
        Title: Title,
        Note: Notes,
        LastUpdatedBy: this.state.CUserID,
        LastUpdatedDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/opportunities/OpportunitiesNotesMeetingUpdate",
        method: "POST",
        data: data,
      }).then((res) => {
        
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            "Potential meeting Status add successfully.",
            "Add Potential meeting Status"
          );
          history.push("/potentialmeeting");
          
        } else {
          toast.error(res.data.Message);
        }
      });
    }
  }
  //meeting history

  requestSearch1() {
    var searchedVal1 = document.getElementById("search1").value;
    this.setState({ page1: 1 });
    this.setState({ rowsPerPage1: 10 });
    var searchbox;
    if (searchedVal1 == "") {
      searchbox = false;
      this.setState({ sflag1: false });
    } else {
      searchbox = true;
    }
    var str_in = {
      ClientID: this.state.ClientID,
      EventID: this.state.meetingid,
      page: 1,
      rowsPerPage: this.state.rowsPerPage1,
      sort: true,
      field: this.state.sortField1,
      sortby: this.state.sortedBy1,
      search: searchedVal1,
      type: "User",
      searchbox: searchbox,
    };
    
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/opportunities/GetOpportunitiesMeetingStatusHistory",
      method: "POST",
      data: str_in,
    });
    rows1.then((resnotes) => {
      this.setState({ data1: resnotes.data.pagedata });
      this.setState({ rows1: resnotes.data.pagedata });
      this.setState({ rlen1: resnotes.data.totalcount });
      this.setState({ flen1: resnotes.data.totalcount });
      this.setState({ countpage1: resnotes.data.pagecount });
      // 
    });
  }

  chengeRowSelected1 = (event) => {
    

    this.setState({ rowsPerPage1: event.target.value });
    this.setState({ page1: 1 });
    
    

    // startpage("search", 1, event.target.value);
  };

  render() {
    return (
      <>
        <div className="adminmain"> 
          <Sidebar className="" /> 
          <div>
          <div className="bodyhome">
              <div className="row">
                <div className="col-md-4 col-sm-4 padd-0 d-flex align-items-center">
                  <h3 className=" float-left addpotential_header mb-0">
                    Edit Opportunities Meeting
                  </h3>
                </div>
                <div className="col-md-8 col-sm-8 pt-4 pb-4 text-right">
                  {this.state.allres.map((value) => (
                    <a
                      className="btn btngroup m-btn mr-1"
                      onClick={() => {
                        this.editbtn(value._id);
                      }}
                    >
                      <i className="la flaticon-edit-1 "></i>
                      <span className="Mobile_button">Edit</span>
                    </a>
                  ))}
                  <a
                    className="btn btngroup2 m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                    onClick={this.backbtn}
                  >
                    <i className="la la-arrow-circle-left"></i>
                    <span className="Mobile_button">Back</span>
                  </a>
                </div>
              </div>
              <div className="paddcols">
                <div className="col-md-4 col-sm-4 pt-4 padd-0 d-flex align-items-center">
                  <h3 className=" float-left addpotential_header">
                    Contact Info
                  </h3>
                </div>
              {this.state.allres.map((value) => (
                <div className="row pt-4">
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        First Name
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.FirstName}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Last Name
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.LastName}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Title
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.Title}</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Company
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.Company}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Email
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.Email}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        LinkedIn URL
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.LinkedInUrl}</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Direct Phone
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.DirectPhone}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Direct Phone Ext
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.DirectPhoneExt}</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Company Phone
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.CompanyPhone}</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Website
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.Website}</label>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-md-4 pt-4 col-sm-4 padd-0 d-flex align-items-center">
                <h3 className=" float-left addpotential_header">
                  Meeting Info
                </h3>
              </div>
              {this.state.allres.map((value) => (
                <div className="row pt-4">
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Meeting Status:
                      </label>

                      <div className="col-lg-7 View_text">
                        <label>{value.Status}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Meeting Source:
                      </label>
                      {this.state.sourcemeeting.map((value1) => (
                        <div className="col-lg-7 View_text">
                          <label>{value1.Name}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Meeting Set Date :
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.DirectPhone}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Meeting Set By
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.MeetingOwnerID}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Meeting Date :
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.DirectPhone}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Meeting Time Zone
                      </label>
                      <div className="col-lg-7 View_text">
                        {this.state.timezone.map((value2) => (
                          <div className="col-lg-7 View_text">
                            <label>{value2.FieldName}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Owner
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.MeetingOwnerID}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Reminder Email Account
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>main</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Contract Value :
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.ContractValue}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Industry :
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.Industry}</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        External Account ID :
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.DirectPhone}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        External Contact ID :
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.DirectPhoneExt}</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        External Field 1 :
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.DirectPhone}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        External Field 2 :
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.DirectPhoneExt}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        External Field 3 :
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.DirectPhoneExt}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group"></div>

                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Seniority :
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.DirectPhoneExt}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Yes
                      </label>
                      <div className="col-lg-7 View_text">
                        <label></label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Call Recording
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>{value.CompanyPhone}</label>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div className="col-md-4 pt-4 col-sm-4 padd-0 d-flex align-items-center">
                <h3 className=" float-left addpotential_header">
                  Status Dates
                </h3>
              </div>
              {this.state.allres.map((value) => (
                <div className="row pt-4">
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Opportunity Date
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>
                          {moment(
                            new Date(value.CreatedDt).toDateString()
                          ).format("MM/DD/YYYY")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Closed Won Date
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>
                          {moment(
                            new Date(value.EventStartDt).toDateString()
                          ).format("MM/DD/YYYY")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Closed Lost Date
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>
                          {moment(
                            new Date(value.EventCreatedDt).toDateString()
                          ).format("MM/DD/YYYY")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 viewboxsinput_group">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Unqualified Date
                      </label>
                      <div className="col-lg-7 View_text">
                        <label>
                          {moment(
                            new Date(value.CreatedDt).toDateString()
                          ).format("MM/DD/YYYY")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div className="col-md-4 pt-4 col-sm-4 padd-0 d-flex align-items-center">
                <h3 className=" float-left addpotential_header">
                  Custom Meeting Field
                </h3>
              </div>

              <div className="row pt-4">
                <div className="col-lg-6 viewboxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      cust1
                    </label>
                    <div className="col-lg-7 View_text">
                      {/* <label>{value.FirstName}</label> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          {/* notes */}
          <div className="bodyhome mt-4">
            <div className="paddcols">
              <div className="row">
                <div className="col padd-0 d-flex align-items-center">
                  <h3 className=" float-left pb-4 addpotential_header">Notes</h3>
                </div>
                <div className="col text-right">
                  {/* <a className="btn btngroup m-btn ">
                    <i className="la la-plus la-icon-font-size-13"></i>
                    <span className="Mobile_button">Add</span>
                  </a> */}
                  <Popup
                    trigger={
                      <button className="btn btngroup m-btn ">
                        <i class="la la-plus la-icon-font-size-13"></i>
                        <span className="Mobile_button">Add</span>
                      </button>
                    }
                    modal
                    nested
                  >
                    {(close) => (
                      <div>
                        <div className="modal-black"></div>
                        <div className="filterPopup largerPopup">
                          <div className="paddingboxTerms">
                            <div className="modal-header py-3 px-3">
                              <h4 className="mb-0">Note</h4>
                            </div>
                            <div className="modal-body p-5">
                              <div class="row mb-3">
                                <label class="col-lg-2 col-form-label text-left">
                                  Title
                                </label>
                                <div class="col-lg-10">
                                  <input
                                    class="form-control m-input"
                                    data-val="true"
                                    data-val-remote="Title already exist."
                                    data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                    data-val-required="Please enter title"
                                    id="Title1"
                                    name="Title1"
                                    placeholder="Enter title"
                                    type="text"
                                  />
                                </div>
                              </div>

                              <div class="row">
                                <label class="col-lg-2 col-form-label text-left">
                                  Note
                                </label>
                                <div class="col-lg-10">
                                  <textarea
                                    class="form-control m-input minheight390"
                                    data-val="true"
                                    data-val-remote="Title already exist."
                                    data-val-required="Please enter title"
                                    id="Notes"
                                    name="Notes"
                                    placeholder="Enter note"
                                    type="text"
                                  >
                                    {" "}
                                  </textarea>
                                </div>
                              </div>
                            </div>
                            <div className="modal-content bordernone text-right">
                              <div class="row">
                                <div class="col-lg-12 pull-right">
                                  <a
                                    id="backtolist"
                                    class="btn btn-secondary mr-2"
                                    onClick={() => {
                                      
                                      close();
                                    }}
                                  >
                                    cancel
                                  </a>
                                  <a
                                    id="submit"
                                    class="btn btn-primary btn-lightgreen mr-1"
                                    href="javascript:void(0);"
                                    value="Save"
                                    onClick={this.savebtn}
                                  >
                                    <i class="la la-save"></i> Save
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>
                </div>
              </div>
              <div className="row padt-25">
                <div className="col-sm-12 col-md-6">
                  <label className="textlabelte">
                    Show
                    <select
                      name="tbl_meeting_length"
                      aria-controls="tbl_meeting"
                      className="form-control form-control-sm"
                      onChange={this.chengeRowSelected}
                    >
                      {CommonConstants.show_rows.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                      {/* <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option> */}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                  <label className="textlabelte">
                    Search:{" "}
                    <input
                      type="search"
                      id="search"
                      onChange={() => this.requestSearch()}
                      className="form-control form-control-sm ml-2"
                      placeholder=""
                      aria-controls="tbl_meeting"
                    />
                  </label>
                </div>
              </div>
              <div className="table-bordered">
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Title</TableCell>
                        <TableCell>Notes</TableCell>
                        <TableCell>Created Date</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.rows.map((row) =>
                        row.MeetingID != null ? (
                          <TableRow>
                            <TableCell>{row.Title}</TableCell>
                            <TableCell>{row.Note}</TableCell>
                            <TableCell>
                              {moment(
                                new Date(row.CreatedDate).toDateString()
                              ).format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell>
                              <a
                                onClick={() => {
                                  this.editspage(row._id);
                                }}
                              >
                                <Popup
                                  trigger={
                                    <i class="la flaticon-edit-1 edit-icon"></i>
                                  }
                                  modal
                                  nested
                                >
                                  {(close) => (
                                    <div>
                                      <div className="modal-black"></div>
                                      <div className="filterPopup largerPopup">
                                        <div className="paddingboxTerms">
                                          <div className="modal-header py-3 px-3">
                                            <h4 className="mb-0">Note</h4>
                                          </div>
                                          <div className="modal-body p-5">
                                            <div class="row mb-3">
                                              <label class="col-lg-2 col-form-label text-left">
                                                Title
                                              </label>
                                              <div class="col-lg-10">
                                                <input
                                                  class="form-control m-input"
                                                  data-val="true"
                                                  data-val-remote="Title already exist."
                                                  data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                                  data-val-required="Please enter title"
                                                  id="Title1"
                                                  name="Title1"
                                                  placeholder="Enter title"
                                                  type="text"
                                                  onChange={this.handleChange.bind(
                                                    this,
                                                    "Title1"
                                                  )}
                                                  value={
                                                    this.state.fields["Title1"]
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div class="row">
                                              <label class="col-lg-2 col-form-label text-left">
                                                Note
                                              </label>
                                              <div class="col-lg-10">
                                                <textarea
                                                  class="form-control m-input minheight390"
                                                  data-val="true"
                                                  data-val-remote="Title already exist."
                                                  data-val-required="Please enter title"
                                                  id="Notes"
                                                  name="Notes"
                                                  placeholder="Enter note"
                                                  type="text"
                                                  onChange={this.handleChange.bind(
                                                    this,
                                                    "Notes"
                                                  )}
                                                  value={
                                                    this.state.fields["Notes"]
                                                  }
                                                >
                                                  {" "}
                                                </textarea>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="modal-content bordernone text-right">
                                            <div class="row">
                                              <div class="col-lg-12 pull-right">
                                                <a
                                                  id="backtolist"
                                                  class="btn btn-secondary mr-2"
                                                  onClick={() => {
                                                    
                                                    close();
                                                  }}
                                                >
                                                  cancel
                                                </a>
                                                <a
                                                  id="submit"
                                                  class="btn btn-primary btn-lightgreen mr-1"
                                                  href="javascript:void(0);"
                                                  value="Save"
                                                  onClick={this.saveupdatebtn}
                                                >
                                                  <i class="la la-save"></i> Save
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Popup>
                              </a>
                              <a
                                href="#"
                                className="btn-eyesicon"
                                onClick={() => {
                                  this.DeleteClientName(row._id);
                                }}
                              >
                                <i class="la flaticon-delete-1 delete-icon"></i>
                              </a>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <></>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {this.state.sflag ? (
                <div class="row">
                  <Pagination
                    component="div"
                    count={this.state.countpage}
                    onChange={this.handleChangePage}
                    showFirstButton
                    showLastButton
                  />
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.rlen == 0
                        ? 0
                        : (this.state.page - 1) * this.state.rowsPerPage + 1}{" "}
                      to{" "}
                      {this.state.page * this.state.rowsPerPage > this.state.rlen
                        ? this.state.rlen
                        : this.state.page * this.state.rowsPerPage}{" "}
                      of {this.state.rlen} entries (filtered from{" "}
                      {this.state.flen} total entries)
                    </p>
                  </div>
                </div>
              ) : (
                <div class="row">
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.rlen == 0
                        ? 0
                        : (this.state.page - 1) * this.state.rowsPerPage + 1}{" "}
                      to{" "}
                      {this.state.page * this.state.rowsPerPage > this.state.rlen
                        ? this.state.rlen
                        : this.state.page * this.state.rowsPerPage}{" "}
                      of {this.state.rlen} entries
                    </p>
                  </div>
                  <div class="col pageright">
                    <Pagination
                      component="div"
                      count={this.state.countpage}
                      onChange={this.handleChangePage}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="bodyhome mt-4">
            <div className="paddcols">
              <div className="row">
                <div className="col padd-0 d-flex align-items-center">
                  <h3 className=" float-left pb-4 addpotential_header">
                    Meeting Status History
                  </h3>
                </div>
                <div className="col text-right"></div>
              </div>
              <div className="row padt-25">
                <div className="col-sm-12 col-md-6">
                  <label className="textlabelte">
                    Show
                    <select
                      name="tbl_meeting_length"
                      aria-controls="tbl_meeting"
                      className="form-control form-control-sm"
                      onChange={this.chengeRowSelected1}
                    >
                      {CommonConstants.show_rows.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                  <label className="textlabelte">
                    Search:{" "}
                    <input
                      type="search"
                      id="search1"
                      onChange={() => this.requestSearch1()}
                      className="form-control form-control-sm ml-2"
                      placeholder=""
                      aria-controls="tbl_meeting"
                    />
                  </label>
                </div>
              </div>
              <div className="table-bordered">
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Old Status</TableCell>
                        <TableCell>New Status</TableCell>
                        <TableCell>Change Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.rows1.map((row) => (
                        <TableRow>
                          <TableCell>{row.p1[0].Status}</TableCell>
                          <TableCell>
                            {row.p2.length == 0 ? " " : row.p2[0].Status}
                          </TableCell>
                          <TableCell>
                            {moment(
                              new Date(row.ChangeDate).toDateString()
                            ).format("MM/DD/YYYY")}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {this.state.sflag1 ? (
                <div class="row">
                  <Pagination
                    component="div"
                    count={this.state.countpage1}
                    onChange={this.handleChangePage1}
                    showFirstButton
                    showLastButton
                  />
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.rlen1 == 0
                        ? 0
                        : (this.state.page1 - 1) * this.state.rowsPerPage1 +
                          1}{" "}
                      to{" "}
                      {this.state.page1 * this.state.rowsPerPage1 >
                      this.state.rlen1
                        ? this.state.rlen1
                        : this.state.page1 * this.state.rowsPerPage1}{" "}
                      of {this.state.rlen1} entries (filtered from{" "}
                      {this.state.flen1} total entries)
                    </p>
                  </div>
                </div>
              ) : (
                <div class="row">
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.rlen1 == 0
                        ? 0
                        : (this.state.page1 - 1) * this.state.rowsPerPage1 +
                          1}{" "}
                      to{" "}
                      {this.state.page1 * this.state.rowsPerPage1 >
                      this.state.rlen1
                        ? this.state.rlen1
                        : this.state.page1 * this.state.rowsPerPage1}{" "}
                      of {this.state.rlen1} entries
                    </p>
                  </div>
                  <div class="col pageright">
                    <Pagination
                      component="div"
                      count={this.state.countpage1}
                      onChange={this.handleChangePage1}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddSalesHiveContactPage = connect(
  mapState,
  actionCreators
)(CViewOpportunitiesPage);
export { connectedAddSalesHiveContactPage as CViewOpportunitiesPage };
