import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";
import { CSVReader, CSVDownloader } from "react-papaparse";


import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});



export default function AccountAllTable({ToDate,FromDate,dater}) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [FromDates,SetFromDates ] = React.useState("");
  const [ToDates, SetToDates] = React.useState("");
  const [ExportData,SetExportData] = React.useState([]);
  const [ClientName, SetClientName] = React.useState("");
  const [CUserID,SetCUserID] = React.useState(0)

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    SetFromDates(FromDate);
    SetToDates(ToDate);
    ExportDataMethod(Details.ClientID,Details.ParentUserID,dater);
    VariableReporting(Details.ClientID,Details.ParentUserID,Details.Role,dater);
  }, [Search, Page, RowsPerPage,ToDate,FromDate,dater]);

  //Export Data
  const ExportDataMethod=(CID,UID,DateRange)=>{
    const InputParameters={
      UserID:UID,
      ClientID:CID,
      ToDate:ToDates,
      FromDate:FromDates,
      FieldName:"Greeting",
      DateRange:DateRange
  }
  Axios({
  url: CommonConstants.MOL_APIURL + "/variable_reporting/VariableReprotingExport",
  method: "POST",
  data:InputParameters
  }).then((Res) => {
    SetClientName(Res.data.ClientName[0].Name);
      SetExportData( Res.data.Data );
  });
  }
 
 const ExportCsv=()=> {}


  // Variable Reporting get list
  const VariableReporting = (CID,UID,URole,DateRange) => {
    var InputParameters = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      ToDate:ToDates,
      FromDate:FromDates,
      FieldName:"Greeting",
      DateRange:DateRange

    };

    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/variable_reporting/VariableReportingGet",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((Result) => {
      console.log(Result)
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      document.getElementById("hideloding").style.display = "none";
    });
  };

 //search for record
 const RequestSearch = (Event) => {
  if (Event.key === "Enter") {
    var SearchedVal = document.getElementById("search").value;
    SetSearch(SearchedVal);
    SetPage(1);
    document.getElementById("hideloding").style.display = "block";
  }
};


//change Page
const HandleChangePage = (Event, NewPage) => {
  if(NewPage==Page){
    SetPage(NewPage);
  }else{
    SetPage(NewPage);
     document.getElementById("hideloding").style.display = "block";
  }
};

//change display rows
const ChangeRowSelected = (Event) => {
  SetRowsPerPage(Number(Event.target.value));
  SetPage(1);
  document.getElementById("hideloding").style.display = "block";
};

//get sort field data
const SortData = (Field) => {
  var SearchedVal = document.getElementById("search").value;
  var SerchBox;
  if (SearchedVal == "") {
    SerchBox = false;
    SetSflag(false);
    var SortField = Field;
    var SortBy;
    if (SortedBy == 1) {
      SortBy = -1;
      SetSortedBy(-1);
    } else {
      SortBy = 1;
      SetSortedBy(1);
    }
    SetSortField(Field);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      SerchBox: false,
      Archive: false,
      Sort: true,
      Field: SortField,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
      ToDate:ToDates,
      FromDate:FromDates,
      FieldName:"Greeting",
      DateRange:dater

    };
    console.log(InputParameter);
    const AccountCategoriesList = Axios({
      url:CommonConstants.MOL_APIURL +"/variable_reporting/VariableReportingGet",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      console.log(Result.data);
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
    });
  } else {
    SerchBox = true;
    SetSflag(true);
    var SortField = Field;
    var SortBy;
    if (SortedBy == 1) {
      SortBy = -1;
      SetSortedBy(-1);
    } else {
      SortBy = 1;
      SetSortedBy(1);
    }
    SetSortField(Field);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      search: SearchedVal,
      SerchBox: SerchBox,
      Archive: false,
      Sort: true,
      Field: SortField,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
      ToDate:ToDates,
      FromDate:FromDates,
      FieldName:"Greeting",
      DateRange:dater

    };
    const AccountCategoriesList = Axios({
      url:CommonConstants.MOL_APIURL +"/variable_reporting/VariableReportingGet",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
    });
  }
};
  return (
    <>
    <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
       <div className="row">
                        <div className="col border-bottom "> 
                            <div className="listing-li float-right padb-15 px-4">
                                <ul>
                                    <li>
                    <a
                                        onClick={ExportCsv}
                                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                                    >
                        <span>
                          <CSVDownloader
                            data={ExportData}
                            filename={`${ClientName}-Greeting`}

                            bom={true}
                          >
                        <i class="la la-icon-font-size-13 la-download"></i>
                           
                          </CSVDownloader>
                        </span>
                      </a>
                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
    <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow> 
            <TableCell><a
                    onClick={() => {
                      SortData("_id");
                    }}
                  >Greeting</a></TableCell> 
            <TableCell><a
                    onClick={() => {
                      SortData("ClientName");
                    }}
                  >Sent</a></TableCell>
            <TableCell><a
                    onClick={() => {
                      SortData("ClientName");
                    }}
                  >Tracked</a></TableCell>
            <TableCell><a
                    onClick={() => {
                      SortData("ClientName");
                    }}
                  >Replies</a></TableCell>
            <TableCell><a
                    onClick={() => {
                      SortData("ClientName");
                    }}
                  >Interested</a></TableCell>
            <TableCell><a
                    onClick={() => {
                      SortData("ClientName");
                    }}
                  >Open %</a></TableCell>
            <TableCell><a
                    onClick={() => {
                      SortData("ClientName");
                    }}
                  >Reply %</a></TableCell>  
            <TableCell><a
                    onClick={() => {
                      SortData("ClientName");
                    }}
                  >Intrested %</a></TableCell>  
          </TableRow>
        </TableHead>
        <TableBody>
          {Rows.map((row) => (
            <TableRow key={row.name} row={row} >
               
              <TableCell>{row._id}</TableCell>
              <TableCell>{0}</TableCell>
              <TableCell>{0}</TableCell>
              <TableCell>{0}</TableCell>
              <TableCell>{0}</TableCell>
              <TableCell>{0}</TableCell>
              <TableCell>{0}</TableCell>
              <TableCell>{0}</TableCell>
              
              </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>

  );
}
