import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../../_actions';
import Sidebar from '../../../_components/clients/nav-sidebar/Sidebar';
import Footer from '../../../_components/user/footer/footer';
import Axios from 'axios';
import { history } from '../../../_helpers';
import { CommonConstants } from '../../../_constants/common.constants';
import { GetUserDetails } from "../../../_helpers/Utility";
//import CallHistory from '../_components/coldcalling/callhistory';

import CEditCampaignsTabing from '../../../_components/clients/EditCampaigns/EditCampaignsTabing';
import { toast } from "react-toastify";
toast.configure();
import 'react-toastify/dist/ReactToastify.css'; 
class CEditCampaignsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        pager: {},
        pageOfItems: [],
        files: [],
        list:[],
        columns:[],
        reset:false,
        csvData:[],
        code:"",
        fields: {},
        errors: {},
        NameAvailable: null,
        ClientID:null ,
        UserID:null,
        CUserID:null,
        Role:null,
        Name:"",
        Active:false,
        CampaignID:null,
        Page:"",
        Email:"",
        ProspectID:null,
        AccountID:null
    }; 

} 
async componentDidMount(){
  window.addEventListener('storage', (event) => {
    if (event.key === 'clientChanged') {
      // Reload the page and redirect to /campaigns
      window.location.href = '/campaigns';
    }
  });
    var Details = GetUserDetails();
    if (Details != null) {
      this.setState({ClientID:Details.ClientID});
      this.setState({UserID:Details.ParentUserID});
      this.setState({CUserID:Details.ChildUserID});
      this.setState({Role:Details.Role});
    }
    console.log(this.props.location.state?.data,this.props.location.state?.PageName,"wsw")
    var CampaignID = ""
    if(this.props.location.state?.data != undefined &&  this.props.location.state?.PageName === "Campaign" ){
        CampaignID = this.props.location.state?.data;
        this.setState({CampaignID:this.props.location.state?.data})
        this.setState({Page:this.props.location.state?.PageName})
    }
    if(this.props.location.state?.data != undefined  &&  this.props.location.state?.PageName === "ViewContact" ){
        CampaignID = this.props.location.state?.data;
        this.setState({CampaignID:this.props.location.state?.data})
        this.setState({Email:this.props.location.state?.EmailData})
        this.setState({ProspectID:this.props.location.state?.dataprospect})
        this.setState({Page:this.props.location.state?.PageName})
    }

    


    var StrIn = {
        CampaignID:CampaignID
      }
      var Result = await Axios({
        url: CommonConstants.MOL_APIURL + "/campaign/GetCampaignByID",
        method: "POST",
        data: StrIn,
      });
      
      if(Result.data.StatusMessage === "SUCCESS"){
        this.setState({Name:Result.data.Data[0].Name})
        this.setState({Active:Result.data.Data[0].IsActive})
      }

}

  //Reload page while delete perform
  UpdateFromChild = (value) => {
    
    if (typeof value !== 'boolean') {
      this.setState({CampaignID:value})
      this.GetCampaignDetails(value)
    }
    if (value == true) {
      this.setState({Active:false});
    }
  };

  async GetCampaignDetails(CampaignID){

    var StrIn = {
      CampaignID:CampaignID
    }
    var Result = await Axios({
      url: CommonConstants.MOL_APIURL + "/campaign/GetCampaignByID",
      method: "POST",
      data: StrIn,
    });
    
    if(Result.data.StatusMessage === "SUCCESS"){
      this.setState({Name:Result.data.Data[0].Name})
      this.setState({Active:Result.data.Data[0].IsActive})
    }
  }

async CampaignActiveUpdate(CAID){
  
  this.setState({Active:!this.state.Active});
    var Str_in = {
        IsActive:!this.state.Active,
        CampaignID:CAID,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
      };
      const rows1 = await Axios({
        url:
          CommonConstants.MOL_APIURL + "/campaign/CampaignActiveUpdate",
        method: "POST",
        data: Str_in,
      });
      if(rows1.data.StatusMessage == "SUCCESS"){
        if(rows1.data?.Data?.IsActive === true){
          this.setState({Active:true});
          toast.success(<div>Campaign<br/>Campaign status Activated successfully.</div>);
        }else{
          this.setState({Active:false});
          toast.success(<div>Campaign<br/>Campaign status Deactivated successfully.</div>);
        }
        
        //GetCampaignsList(ClientID,UserID,Role)
      }else if(rows1.data.StatusMessage == "EmailSignatureError"){
        toast.error(<div>Campaign<br />Email signature is required</div>);
        this.setState({Active:false});
      }
      else{
        toast.error(<div>Campaign<br />Please Add Account, Prospect and Step at least one.</div>);
        this.setState({Active:false});
      }
}

    render() {
        return (
            <>
                
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitlebd py-4 pl-0 m-0">Edit Campaign: {this.state.Name}	</h4>
                        </div>
                        <div className="col d-flex justify-content-end pt-4">
                        <div class="toggleswich">
                            <input type="checkbox"  class="checktogle" onChange={()=>{this.CampaignActiveUpdate(this.state.CampaignID)}} checked={this.state.Active}/>
                            <b class="switch">
                              <span class="checkion"></span>
                              <span class="uncheckion"></span>
                            </b>
                            <b class="track"></b>
                          </div>
                        </div>
                    </div>
                    <span className="devider"></span>
                    <div className="col padd-0">
                        
                        <CEditCampaignsTabing campaignID={this.props.location.state?.data} CPage={this.state.Page} email={this.state.Email} pid={this.state.ProspectID} AccountID={this.props.location.state?.AccountData} PageName={this.props.location.state?.PageName} updateFromChild={this.UpdateFromChild}/>
                    </div>
                </div>
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCEditCampaignsPage = connect(mapState, actionCreators)(CEditCampaignsPage);
export { connectedCEditCampaignsPage as CEditCampaignsPage };