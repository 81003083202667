import React from 'react';
import { connect } from 'react-redux';
import Axios from "axios";
import { toast } from "react-toastify";

import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Accountcategory from '../../_components/clients/accountclientstatus/accountcategory/accountcategory';
//import CollapsibleTable from '../_components/usertable';
import Box from "@material-ui/core/Box";
const Fileupload = require("../../_helpers/fileupload");
import Footer from "../../_components/user/footer/footer";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { CommonConstants } from "../../_constants/common.constants";
import { history } from "../../_helpers";
import { GetUserDetails } from "../../_helpers/Utility";
import { GetClientDetails } from "../../_helpers/Utility";
import loadingicon from "../../images/loading.gif";



class CAccountCategoryPage extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          Pager: {},
          PageOfItems: [],
          Files: [],
          List: [],
          Columns: [],
          Reset: false,
          CsvData: [],
          DropBoxData: [],
          FileName: "",
          ExportData: [],
          CName: "",
          TotalDisplayCount: false,
          TotalResult: 0,
          TotalDuplicate: 0,
          TotalError: 0,
          TotalSuccess: 0,
          ParentUpadate: false,
          Role:null
        };
        this.HandleOnDrop = this.HandleOnDrop.bind(this);
        this.HandleOnError = this.HandleOnError.bind(this);
        this.HandleOnRemoveFile = this.HandleOnRemoveFile.bind(this);
        this.ImportCsv = this.ImportCsv.bind(this);
        this.MapCsv = this.MapCsv.bind(this);
        this.CancleCsv = this.CancleCsv.bind(this);
        this.SaveCsv = this.SaveCsv.bind(this);
        this.AddAccountCategory = this.AddAccountCategory.bind(this);
        this.ExportCsv = this.ExportCsv.bind(this);
      }
    
      componentDidMount() {
        document.getElementById("hidelod").style.display = "none";
        document.getElementById("import_csv").style.display = "none";
        document.getElementById("map_attribute").style.display = "none";
        var Details = GetUserDetails();
        if (Details != null) {
          this.state.ClientID = Details.ClientID;
          this.state.UserID = Details.ParentUserID;
          this.state.CUserID = Details.ChildUserID;
          this.state.Role=Details.Role;
        }
    
        var InputParameter = {
          ClientID: Details.ClientID,
          UserID:Details.ParentUserID,
          Role:Details.Role
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/account_categories/AccountCategoriesExport",
          method: "POST",
          data: InputParameter,
        }).then((Res) => {
          this.setState({ ExportData: Res.data });
        });
      }
    
      componentDidUpdate() {
        //this.loadPage();
      }
    
      //Reload page while delete perform
      UpdateFromChild = (value) => {
        if(value == true){
          this.componentDidMount()
        }
      }

      //reset csv
      ResetCsv() {
        this.setState({ Reset: true });
      }
    
      //change dropdown
      HandleOnDrop(Data, FileInfo) {
        var FileName = FileInfo.name;
        this.setState({ Files: FileInfo });
        var sep = FileName.split(".");
        if (sep[sep.length-1]!= "csv") {
          console.log("wrong file");
          this.setState({ Reset: true });
          this.ResetCsv();
        } else {
          var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
          this.setState({ FileName: csvfile });
          this.setState({ CsvData: Data });
          this.setState({ DropBoxData: Data[0].data });
        }
      }
    
      HandleOnError(Err, File, InputElem, Reason) {}
    
      HandleOnRemoveFile(Data) {}
    
      //import csv
      ImportCsv() {
        document.getElementById("import_csv").style.display = "block";
      }
    
      //map csv
      MapCsv() {
        document.getElementById("hidelod").style.display = "block";
        if (this.state.CsvData.length != 0) {
          console.log(this.state.CsvData);
          document.getElementById("map_attribute").style.display = "block";
          // console.log(this.state.CsvData[0].data);
          this.state.DropBoxData.map((dddd, index) => {
            this.ResetCsv();
            document.getElementById("hidelod").style.display = "none";
          });
        } else {
          this.ResetCsv();
          toast.error(<div className="toastsize">Please select file.</div>)
          document.getElementById("hidelod").style.display = "none";
        }
      }
    
      //cancle Csv
      CancleCsv() {
        this.ResetCsv();
        this.setState({ TotalDisplayCount: false });
        document.getElementById("map_attribute").style.display = "none";
        document.getElementById("import_csv").style.display = "none";
      }
    
      //add account category
      SaveCsv() {
        document.getElementById("hidelod").style.display = "block";
        var AccountCategory = document.getElementById("AccountCategory").value;
        var EmailDomain = document.getElementById("EmailDomain").value;
        Fileupload.getBase64(this.state.Files, (Result) => {
          var RreglarString = Result;
          var Splirs = RreglarString.split("base64,");
          var Base64 = Splirs[1];
          var InputParameter = {
            Fields: "Coverimg",
            Path: "./Content/UploadedFiles/UserAccountCategories",
            File: Base64,
            ImageName: this.state.FileName,
            FileData: this.state.CsvData,
            Name: AccountCategory,
            Email: EmailDomain,
            ClientID: this.state.ClientID,
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL + "/account_categories/ImportCSVListData",
            method: "POST",
            data: InputParameter,
          }).then((Res) => {
            if (Res.data.StatusMessage == "SUCCESS") {
              toast.success(
                <div className="toastsize">List import successfully.</div>
              );
              // history.push("/list");
              document.getElementById("hidelod").style.display = "none";
              this.setState({ TotalDisplayCount: true });
              this.setState({ TotalResult: Res.data.Total });
              this.setState({ TotalDuplicate: Res.data.TotalDuplication });
              this.setState({ TotalError: Res.data.TotalError });
              this.setState({ TotalSuccess: Res.data.TotalSuccess });
              document.getElementById("map_attribute").style.display = "none";
              document.getElementById("import_csv").style.display = "none";
            } else {
              toast.error(Res.data.Message);
            }
          });
        });
      }
    
      //Add function
      AddAccountCategory() {
        history.push("/CAddAccountCategory");
      }
      ExportCsv() {}
    
      render() {
        return (
          <>
            <div id="hidelod" className="loding-display">
              <img src={loadingicon} />
            </div>
            <div className="adminmain mheight"> 
              <Sidebar className="" /> 
              <div className="bodyhome">
                <div className="row ">
                  <div className="col">
                    <h4 className="headertitlebd float-left px-1 py-4">
                      Account Categories
                    </h4>
                  </div> 
                  <div className="col">
                    <div className="listing-li float-right padb-15">
                      <ul>
                        <li>
                          <a
                            onClick={this.ImportCsv}
                            className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          >
                            <i className="la la-icon-font-size-13 la-upload"></i>
                            <span>Import</span>
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={this.ExportCsv}
                            className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          >
                            <i className="la la-icon-font-size-13 la-download"></i>
                            <span>
                              <CSVDownloader className="px-0"
                                data={this.state.ExportData}
                                filename={this.state.CName + "-AccountCategory"}
                                bom={true}
                              >
                                Export
                              </CSVDownloader>
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={this.AddAccountCategory}
                            className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          >
                            <i className="la la-icon-font-size-13 la-plus"></i>
                            <span>Add</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
    
                <div className="portletbody px-4" id="import_csv">
                  <div className="row">
                    <div className="col-xl-8 offset-xl-2">
                      <h3 className="uploadheadingtitle">
                        Import Account Category
                      </h3>
                      <div className="uplodfilesbox">
                        <CSVReader
                          accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                          onDrop={this.HandleOnDrop}
                          onError={this.HandleOnError}
                          addRemoveButton
                          removeButtonColor="#659cef"
                          onRemoveFile={this.HandleOnRemoveFile}
                          isReset={this.state.Reset}
                        >
                          <span>Select .CSV file</span>
                          <p>Add account category data from csv file to import.</p>
                        </CSVReader>
                      </div>
                    </div>
                    <div className="col-xl-8 offset-xl-2">
                      <div className="alignright">
                        <a
                          onClick={this.MapCsv}
                          className="btn btn-primary btnColor"
                        >
                          <i className="la la-upload"></i> Upload
                        </a>
                        <a onClick={this.CancleCsv} className="btn btn-secondary">
                          <i className="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
    
                <div className="portletbody px-4 portletshadow" id="map_attribute">
                  <div className="row col-xs-12">
                    <h4 className="headertitle float-left">Map attributes</h4>
                  </div>
                  <div className="row">
                    <div className="form-group m-form__group row col-lg-6 mx-0">
                      <div className="col-lg-5 nopadding">
                        <div>
                          <select
                            className="dbcontent form-control  m-input"
                            disabled="disabled"
                          >
                            <option selected="" value="AccountCategory">
                              Account Category
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-2 text-center">
                        <span className="fa fa-arrows-h fa-2x "></span>
                      </div>
                      <div className="col-lg-5 nopadding">
                        <div>
                          <select
                            id="AccountCategory"
                            className="csvcontent form-control  m-input"
                            autocomplete="off"
                          >
                            <option value="not mapped">Not Mapped</option>
                            {this.state.DropBoxData.map((dbdata, index) => (
                              <option value={index}>{dbdata}</option>
                            ))}
                          </select>
                        </div>
                      </div> 
                    </div>
                    <div className="form-group m-form__group row col-lg-6 mx-0">
                      <div className="col-lg-5 nopadding">
                        <div>
                          <select
                            className="dbcontent form-control  m-input"
                            disabled="disabled"
                          >
                            <option selected="" value="EmailDomain">
                              Email Domain
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-2 text-center">
                        <span className="fa fa-arrows-h fa-2x"></span>
                      </div>
                      <div className="col-lg-5 nopadding">
                        <div>
                          <select
                            id="EmailDomain"
                            className="csvcontent form-control  m-input"
                            autocomplete="off"
                          >
                            <option value="not mapped">Not Mapped</option>
                            {this.state.DropBoxData.map((dbdata, index) => (
                              <option value={index}>{dbdata}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 border-top pt-4 my-2 px-4">
                      <a
                        onClick={this.SaveCsv}
                        className="btn btn-primary btn-lightgreen"
                      >
                        <i className="la la-save"></i>Save
                      </a>
                    </div>
                  </div>
                </div>
    
                {this.state.TotalDisplayCount == true ? (
                  <div className="portletbody px-4" id=" ">
                    <div className="row col-xs-12">
                      <h4 className="headertitle float-left">Results</h4>
                    </div>
    
                    <div className="col-xs-12">
                      <div class="row colfive">
                        <div class="col-md-3">
                          <div class="shadowcard boxcardcounter">
                            <div class="d-flex align-center">
                              <div class="contectboxes w-100">
                                <div class="line-title mb-2">
                                  <span class="box-main-title font-primary">
                                    {this.state.TotalResult}
                                  </span>
                                </div>
                                <div class="box-inner-title">
                                  <string class="clr-sky">Total</string>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
    
                        <div class="col-md-3">
                          <div class="shadowcard boxcardcounter">
                            <div class="d-flex align-center">
                              <div class="contectboxes w-100">
                                <div class="line-title mb-2">
                                  <span class="box-main-title font-success">
                                    {this.state.TotalSuccess}
                                  </span>
                                </div>
                                <div class="box-inner-title">
                                  <string class="clr-sky">Total success</string>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
    
                        <div class="col-md-3">
                          <div class="shadowcard boxcardcounter">
                            <div class="d-flex align-center">
                              <div class="contectboxes w-100">
                                <div class="line-title mb-2">
                                  <span class="box-main-title font-danger">
                                    {this.state.TotalError}
                                  </span>
                                </div>
                                <div class="box-inner-title">
                                  <string class="clr-sky">Total error</string>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
    
                        <div class="col-md-3">
                          <div class="shadowcard boxcardcounter">
                            <div class="d-flex align-center">
                              <div class="contectboxes w-100">
                                <div class="line-title mb-2">
                                  <span class="box-main-title font-warning">
                                    {this.state.TotalDuplicate}
                                  </span>
                                </div>
                                <div class="box-inner-title">
                                  <string class="clr-sky">Total Duplicate</string>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
    
                <div className="paddcols px-4">
                  <Box>
                    <div className="row">
                      <div className="col">
                        <Accountcategory updateFromChild={this.UpdateFromChild}/>
                      </div>
                    </div>
                  </Box>
                </div>
              </div>
            </div>
            <Footer />
          </>
        );
      }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCAccountCategoryPage = connect(mapState, actionCreators)(CAccountCategoryPage);
export { connectedCAccountCategoryPage as CAccountCategoryPage };