import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Ads from '../customvariableset/ads'; 
import Axios from 'axios';
import { CommonConstants } from '../../../_constants/common.constants';
import{GetUserDetails} from '../../../_helpers/Utility'
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function SimpleTabs({daterange,updateFromChild}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [CUserID, setCUserID] = React.useState(0);
  const [Role, setRole] = React.useState('');
  const [TabsView, setTabsView] = React.useState([]);
  const [AnyDates, setAnyDates] = React.useState({});
  const [pageData1, setpageData1] = React.useState(null);

  useEffect(() => {
document.title = "Custom Variable Sets | SalesHive"
    var Details = GetUserDetails();
    if(Details != null)
    {
      setClientID(Details.ClientID);
      setUserID(Details.ParentUserID);
      setCUserID(Details.ChildUserID);
      setRole(Details.Role);
    }

    GetCustomVariableList(Details.ClientID,Details.ParentUserID,Details.Role);
  }, [daterange])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

    //const openTermsConditionPopup = false;
  const GetCustomVariableList = (CID,UID,Role) =>{
    var str_in ={
      ClientID: CID,
      UserID:UID,
      Role:Role
    }
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/customvariable/GetVariableList",
      method: "POST",
      data: str_in
    
    });
    rows1.then(result => {
      
      setTabsView(result.data.data);
    })
  }

  return (
    <div className="tabdes">   
      <div> 
        <AppBar  className='mb-4 col-tabs' position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            {TabsView.map((row,index)=>(<Tab label={row.Name} {...a11yProps(index)}>Overviewasdasd</Tab>))}
          </Tabs>
        </AppBar>
        {TabsView.length !== 0 ? (
        TabsView.map((row, index) => (
          <TabPanel value={value} className="whiterow inpt-0" index={index}>
            <div className="row">
              <div className="col">
                <Ads
                  customtabdata={row}
                  dater={daterange}
                  updateData={updateFromChild}
                />
              </div>
            </div>
          </TabPanel>
        ))
      ) : (
        <div className="text-center whitebg py-3">Custom variable not available</div>
      )}
      </div>
    </div>
  );
}