import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Avatar } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Userimg from '../../../images/avatar/1.jpg';
import userdefault from '../../../images/default.png';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Popup from "reactjs-popup";
import loadingicon from "../../../images/loading.gif";


const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      { date: '2020-01-05', customerId: '11091700', amount: 3 },
      { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
    ],
  };
}


function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popupval,setpopup] = React.useState(false);
  const [cancelpopupval,setcancelpopup] = React.useState(false);
  const [ArchivedPopup,setarchivedpopup] = React.useState(false);

  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
 



  const openEditPopup = () => { 
    setpopup(true);
  };

  const closeTermsConditionModal = () => { 
    setpopup(false);
  };

  const SubmitTermsConditionModal = () => {
    setpopup(false);
  };

  const openCencelPopup = () => { 
    setcancelpopup(true);
  };

  const openArchivedPopup = () => { 
    setarchivedpopup(true);
  };



  

  document.addEventListener('mouseup', function(e) { 
      //console.log(popupval);
      if(popupval){
        setpopup(false);
        setcancelpopup(false);
        setarchivedpopup(false);
      }
  });

 

    //const openTermsConditionPopup = false;
  


  return (
<>

        {/* <a onClick={openEditPopup} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
          <i class="fa la-icon-font-size-13 fa-filter"></i>
        </a>  */}

        <Popup open={popupval} modal>
            <div className="modal-black"></div>
            <div className="filterPopup maxpopup">
                <div className="paddingboxTerms"> 
                    <div className="modal-content bordernone aligncentertext">
                    <div class="swal2-icon swal2-warning swal2-animate-warning-icon">
                      <span class="swal2-icon-text">!</span>
                      </div>
                    <h3>Are you sure?</h3>
                    <p>you want to Archive a Responder.</p>
                    <div class="swal2-actions">
                      <button type="button" class="swal2-cancel btn btn-secondary m-btn m-btn--custom mr-2">No, cancel!</button>
                      <button type="button" class="swal2-confirm btn btn-success m-btn m-btn--custom">Yes, archive it!</button>
                    </div>
                    </div>

                </div> 
            </div> 
        </Popup>

        {/* <a onClick={openCencelPopup} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
           <i class="fa la-icon-font-size-13 fa-filter"></i>
        </a>  */}

        <Popup open={cancelpopupval} modal>
            <div className="modal-black"></div>
            <div className="filterPopup maxpopup">
                <div className="paddingboxTerms"> 
                    <div className="modal-content bordernone aligncentertext">
                    <div class="swal2-icon swal2-error swal2-animate-error-icon d-flex">
                      <span class="swal2-x-mark">
                        <span class="swal2-x-mark-line-left"></span>
                        <span class="swal2-x-mark-line-right"></span>
                      </span>
                    </div>
                    <h3>Cancelled</h3>
                    <p>Your data is safe :)</p>
                    <div class="swal2-actions">
                      {/* <button type="button" class="swal2-cancel btn btn-secondary m-btn m-btn--custom mr-2">No, cancel!</button> */}
                      <button type="button" class="swal2-confirm btn btn-success m-btn m-btn--custom">Ok</button>
                    </div>
                    </div>

                </div> 
            </div> 
        </Popup>


        {/* <a onClick={openArchivedPopup} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
           <i class="fa la-icon-font-size-13 fa-filter"></i>
        </a>  */}

        <Popup open={ArchivedPopup} modal>
            <div className="modal-black"></div>
            <div className="filterPopup maxpopup">
                <div className="paddingboxTerms"> 
                    <div className="modal-content bordernone aligncentertext">
                    <div class="swal2-icon swal2-success swal2-animate-success-icon">
                      <div class="swal2-success-circular-line-left"></div>
                          <span class="swal2-success-line-tip"></span> 
                          <span class="swal2-success-line-long"></span>
                          <div class="swal2-success-ring"></div> 
                          <div class="swal2-success-fix"></div>
                          <div class="swal2-success-circular-line-right"></div>
                        </div>
                    <h3>Archived!</h3>
                    <p>Responder Archived successfully.</p>
                    <div class="swal2-actions">
                      {/* <button type="button" class="swal2-cancel btn btn-secondary m-btn m-btn--custom mr-2">No, cancel!</button> */}
                      <button type="button" class="swal2-confirm btn btn-success m-btn m-btn--custom">Ok</button>
                    </div>
                    </div>

                </div> 
            </div>              
        </Popup>

    <React.Fragment>
      <TableRow className={classes.root}> 
{/*          
        <TableCell>
        <div class="toggleswich">
          <input type="checkbox" class="checktogle" />
          <b class="switch"> 
            <span class="checkion"></span>
            <span class="uncheckion"></span>
          </b>
          <b class="track"></b>
        </div>
        </TableCell> */}
        <TableCell>Client.Company Intro </TableCell>
        <TableCell>0</TableCell>
        <TableCell>0</TableCell>
        <TableCell>0</TableCell>
        <TableCell>0</TableCell>
        <TableCell>0%</TableCell>
        <TableCell>0%</TableCell>
        <TableCell>0%</TableCell>   
        <TableCell>0%</TableCell>   

      </TableRow>
      
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} className="innertables">
              <Table size="small" aria-label="purchases">
                 <TableHead>
                 
                </TableHead> 
              
                <TableRow> 
                <TableCell>8 June 2021</TableCell>
                <TableCell>  </TableCell>
                <TableCell> </TableCell>  
                </TableRow>
              </Table>

            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>

  </>  
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    Userimg: PropTypes.number.isRequired,
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
  createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
  createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
  createData('Eclair', 262, 16.0, 24, 6.0, 3.79), 
];

export default function AccountAllTable({ToDate,FromDate,dater}) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [FromDates,SetFromDates ] = React.useState("");
  const [ToDates, SetToDates] = React.useState("");
  const [ExportData,SetExportData] = React.useState([]);
  const [ClientName, SetClientName] = React.useState("");

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    SetFromDates(FromDate);
    SetToDates(ToDate);
    ExportDataMethod(Details.ClientID,Details.ChildUserID,dater);
    VariableReporting(Details.ClientID,Details.ChildUserID,Details.Role,dater);
  }, [Search, Page, RowsPerPage,ToDate,FromDate,dater]);

  //Export Data
  const ExportDataMethod=(CID,UID,DateRange)=>{
    const InputParameters={
      UserID:UID,
      ClientID:CID,
      ToDate:ToDates,
      FromDate:FromDates,
      FieldName:'Day',
      DateRange:DateRange
  }
  console.log(InputParameters,"Export input")
  Axios({
  url: CommonConstants.MOL_APIURL + "/variable_reporting/VariableReprotingExport",
  method: "POST",
  data:InputParameters
  }).then((Res) => {
    SetClientName(Res.data.ClientName[0].Name);
      SetExportData( Res.data.Data );
  });
  }
 
 const ExportCsv=()=> {}


  // Variable Reporting get list
  const VariableReporting = (CID,UID,URole,DateRange) => {
    var InputParameters = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      ToDate:ToDates,
      FromDate:FromDates,
      FieldName:"Day",
      DateRange:DateRange
    };
console.log(InputParameters)
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/variable_reporting/VariableReportingGet",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((Result) => {
      console.log(Result)
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      document.getElementById("hideloding").style.display = "none";
    });
  };

 //search for record
 const RequestSearch = (Event) => {
  if (Event.key === "Enter") {
    var SearchedVal = document.getElementById("search").value;
    SetSearch(SearchedVal);
    SetPage(1);
    document.getElementById("hideloding").style.display = "block";
  }
};

//change Page
const HandleChangePage = (Event, NewPage) => {
  if(NewPage==Page){
    SetPage(NewPage);
  }else{
    SetPage(NewPage);
     document.getElementById("hideloding").style.display = "block";
  }
};

//change display rows
const ChangeRowSelected = (Event) => {
  SetRowsPerPage(Number(Event.target.value));
  SetPage(1);
  document.getElementById("hideloding").style.display = "block";
};

//get sort field data
const SortData = (Field) => {
  var SearchedVal = document.getElementById("search").value;
  var SerchBox;
  if (SearchedVal == "") {
    SerchBox = false;
    SetSflag(false);
    var SortField = Field;
    var SortBy;
    if (SortedBy == 1) {
      SortBy = -1;
      SetSortedBy(-1);
    } else {
      SortBy = 1;
      SetSortedBy(1);
    }
    SetSortField(Field);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      SerchBox: false,
      Archive: false,
      Sort: true,
      Field: SortField,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
      ToDate:ToDates,
      FromDate:FromDates,
      FieldName:"Day",
      DateRange:dater
    };
    console.log(InputParameter);
    const AccountCategoriesList = Axios({
      url:CommonConstants.MOL_APIURL +"/variable_reporting/VariableReportingGet",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      console.log(Result.data);
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
    });
  } else {
    SerchBox = true;
    SetSflag(true);
    var SortField = Field;
    var SortBy;
    if (SortedBy == 1) {
      SortBy = -1;
      SetSortedBy(-1);
    } else {
      SortBy = 1;
      SetSortedBy(1);
    }
    SetSortField(Field);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      search: SearchedVal,
      SerchBox: SerchBox,
      Archive: false,
      Sort: true,
      Field: SortField,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
      ToDate:ToDates,
      FromDate:FromDates,
      FieldName:"Day",
      DateRange:dater
    };
    console.log(InputParameter);
    const AccountCategoriesList = Axios({
      url:CommonConstants.MOL_APIURL +"/variable_reporting/VariableReportingGet",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
    });
  }
};
  return (
    <>
      <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
       <div className="row">
                        <div className="col border-bottom "> 
                            <div className="listing-li float-right padb-15 px-4">
                                <ul>
                                    <li>
                    <a
                                        onClick={ExportCsv}
                                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                                    >
                        
                        <span>
                          <CSVDownloader
                            data={ExportData}
                            filename={`${ClientName}-Day`}
                            bom={true}
                          >
                           <i class="la la-icon-font-size-13 la-download"></i>
                          </CSVDownloader>
                        </span>
                      </a>
                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
    <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow> 
            <TableCell><a
                    onClick={() => {
                      SortData("_id");
                    }}
                  >Day</a></TableCell> 
            <TableCell><a
                    onClick={() => {
                      SortData("ClientName");
                    }}
                  >Sent</a></TableCell>
            <TableCell><a
                    onClick={() => {
                      SortData("ClientName");
                    }}
                  >Tracked</a></TableCell>
            <TableCell><a
                    onClick={() => {
                      SortData("ClientName");
                    }}
                  >Replies</a></TableCell>
            <TableCell><a
                    onClick={() => {
                      SortData("ClientName");
                    }}
                  >Interested</a></TableCell>
            <TableCell><a
                    onClick={() => {
                      SortData("ClientName");
                    }}
                  >Open %</a></TableCell>
            <TableCell><a
                    onClick={() => {
                      SortData("ClientName");
                    }}
                  >Reply %</a></TableCell>  
            <TableCell><a
                    onClick={() => {
                      SortData("ClientName");
                    }}
                  >Intrested %</a></TableCell>  
          </TableRow>
        </TableHead>
        <TableBody>
          {Rows.map((row) => (
            <TableRow key={row.name} row={row} >
              <TableCell>{row._id}</TableCell>
              <TableCell>{0}</TableCell>
              <TableCell>{0}</TableCell>
              <TableCell>{0}</TableCell>
              <TableCell>{0}</TableCell>
              <TableCell>{0}</TableCell>
              <TableCell>{0}</TableCell>
              <TableCell>{0}</TableCell>
              </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>

  );
}