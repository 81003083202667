import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

toast.configure();

class EditGenericEmailDomainPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Code: "",
      Fields: {},
      Errors: {},
      NameAvailable: null,
      ClientID: null,
      UserID: null,
      CUserID: null,
      OldName: "",
      IsButtonDisabled:false
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = 'Edit Generic Email Domain | SalesHive';
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
    this.EditGenricEmailDomain();
    
  }

  //form validation
  async FromValidation() {
    let formIsValid = true;
    let Fields = this.state.Fields;
    let Errors = {};
    var Name = document.getElementById("Name").value.trim();
    var IsExist = await this.CheckExistGenaricEmailDomain(Name);
    var IsValidate = await this.DomainCheck(Name)
    if (Name == "") {
      formIsValid = false;
      Errors["Name"] = "Please enter domain";
    }else if(IsExist == true){
      formIsValid = false;
      Errors['Name'] = "Generic email domains already exist."
    }else if(IsValidate != true){
      formIsValid = false;
      Errors["Name"] = "Invalid generic email domain"
    }
    
    this.setState({ Errors: Errors });
    return formIsValid;
  }

// Validate domain
async DomainCheck(Domain){
    
  let regexp = /^[a-zA-Z0-9]+[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]?(?:\.[a-zA-Z]{2,}){1,}$/i;
 
   if (regexp.test(Domain))
     {
       return true;
     }
   else
     {
       return false;
     }
 }

 async BlurHandler(){
  let formIsValid = true;
  let Fields = this.state.Fields;
  let Errors = {};
  var Name = document.getElementById("Name").value.trim();
  var IsExist = await this.CheckExistGenaricEmailDomain(Name);
  
  var IsValidate = await this.DomainCheck(Name)
  if (Name == "") {
    formIsValid = false;
    Errors["Name"] = "Please enter domain";
  }else if(IsExist == true){
    formIsValid = false;
    Errors["Name"] = "Generic email domains already exist.";

  }else if(IsValidate != true){
    formIsValid = false;
    Errors["Name"] = "Invalid generic email domain."
  }
  
  this.setState({ Errors: Errors });
  return formIsValid;
 }
  //change to check error
  HandleChange(Field, e) {
    // document.getElementById("firstname_e").style.display = "none";
    let Fields = this.state.Fields;
    Fields[Field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Name != "") {
      this.state.Errors.Name = null;
      this.state.StatusAvailable = null;
      this.CheckExistGenaricEmailDomain(e.target.value);
    } else {
      this.state.StatusAvailable = null;
      this.state.Errors.Name = null;
    }
  }

  //check exist name
  async CheckExistGenaricEmailDomain(Name) {
    let Errors = {};
    var str_in = {
      ClientID: this.state.ClientID,
      GenericEmailDomain: Name,
    };
    var resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/genericemaildomain/GenericEmailDomainExists",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (this.state.OldName == Name) {
          this.setState({ IsExist: false });
          Errors["Name"] = "";

          return false;
        } else {
          Errors["Name"] = "Generic email domains already exist.";

          this.setState({
            StatusAvailable: resdata.data.Data[0]?.GenericEmailDomain,
          });
          Errors["Name"] = "";

          this.setState({ IsExist: false });
          return true;
        }
      } else {
        this.setState({ IsExist: false });
        return false;
      }
    }
    this.setState({ Errors: Errors });
  }

  //update account category
  EditGenricEmailDomain() {
    const id = this.props.location.state;
    this.setState({ id: id });
    var EditFields = {
      ID: id,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/genericemaildomain/GenericEmailDomainGetByID",
      method: "POST",
      data: EditFields,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById("Name").value =
          res.data.Data[0]?.GenericEmailDomain;
        this.setState({ OldName: res.data.Data[0]?.GenericEmailDomain });
        document.getElementById("hideloding").style.display = "none";
      } else {
        toast.error(res.data?.Message);
        document.getElementById("hideloding").style.display = "none";
      }
    });
  }

  //back to account category
  BackBtn() {
    history.push("/genericemaildomain");
  }

  //update account category
  async SaveBtn(e) {
    // e.preventDefault();
    // e.currentTarget.disabled = true;
    this.setState({IsButtonDisabled:true});
    //document.getElementById("submit").disabled = true;
    this.state.Errors.Name = null;
    this.state.StatusAvailable = null;
    var FinalValidation = await this.FromValidation();
    if (FinalValidation == true) {
      var Name = document.getElementById("Name").value;
      var data = {
        ID: this.state.id,
        GenericEmailDomain: Name,
        LastUpdatedBy: this.state.CUserID,
        LastUpdatedDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/genericemaildomain/GenericEmailDomainUpdate",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsizeighty">
              Generic Email Domain Edit
              <br />
              Generic email domain updated successfully.
            </div>
          );
          history.push("/genericemaildomain");
        } else {
          toast.error(res.data.Message);
          this.setState({IsButtonDisabled:false});
          //document.getElementById("submit").disabled = false;

        }
      });
    } else {
      this.setState({IsButtonDisabled:false});
      //document.getElementById("submit").disabled = false;
    }
  }

  render() {
    return (
      <>
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left pl-0">
                    Edit Generic Email Domain
                  </h4>
                </div>
              </div>

            <div className="paddcols">
              <div className="row">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Domain</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter name"
                        onBlur={()=>{this.BlurHandler()}}
                        id="Name"
                        name="Name"
                        placeholder="Enter Domain"
                        type="text"
                      />
                      {/* <label id="firstname_e" className="errorinput">
                        {" "}
                        Please enter Domain{" "}
                      </label> */}
                      
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div className="row mb-4 pt-4">
                <div className="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.IsButtonDisabled}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <a
                    id="backtoList"
                    onClick={this.BackBtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditGenericEmailDomainPage = connect(
  mapState,
  actionCreators
)(EditGenericEmailDomainPage);
export { connectedEditGenericEmailDomainPage as EditGenericEmailDomainPage };
