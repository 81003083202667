import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import Footer from "../../../_components/user/footer/footer";

toast.configure();

class CEditReasonUnqualifiedPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Code: "",
      Fields: {},
      Errors: {},
      TitleNotNull: false,
      IsExist: false,
      NameAvailable: null,
      ClientID: null,
      UserID: null,
      UserIDby: null,
      OldResaon: null,
      IsButtonDisabled:false
    };
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title="Edit Account Reason Unqualified | SalesHive"
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.UserIDby=Details.ChildUserID
      this.state.Role=Details.Role
    }
    this.EditContactReason();
  }

  // form validation
  async FromValidation() {
    let FormIsValid = true;
    var Name = document.getElementById("Name").value.trim();
    var IsExist = await this.CheckExistAccountReason(Name);
    let Errors = {};

    if (Name == "" && Name.indexOf(" ") != "") {
      FormIsValid = false;
      Errors["Name"] = "Please Enter Name";
    }
    if (IsExist == true) {
      FormIsValid = false;
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

  // handle change
  HandleChange(field, e) {
    let Fields = this.state.Fields;
    Fields[field] = e.target.value;
    console.log(e.target.value);
    this.setState({ Fields });
    if (Fields.Name != "" && Fields.Name.indexOf(" ") != 0) {
      this.state.Errors.Name = null;
      this.state.NameAvailable = null;
      this.CheckExistAccountReason(e.target.value);
    } else {
      this.state.NameAvailable = null;
      this.state.Errors.Name = null;
    }
  }

  //check exist Status
  async CheckExistAccountReason(Name) {
    var str_in = {
      ClientID: this.state.ClientID,
      Reason: Name,
      Role:this.state.Role
    };
    let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/account_reason_unqualified/AccountReasonUnqualifiedExists",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (this.state.OldResaon == Name) {
          this.setState({ IsExist: false });
        } else {
          this.setState({ NameAvailable: resdata.data.Data[0]?.Reason });
          this.setState({ IsExist: true });
          return true;
        }
      } else {
        this.setState({ IsExist: false });
        return false;
      }
    }
  }

  // Edit function reason
  EditContactReason() {
    const id = this.props.location.state;
    this.setState({ id: id });
    var str_in = {
      id: id,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/account_reason_unqualified/AccountReasonunqualifiedGetById",
      method: "POST",
      data: str_in,
    }).then((res) => {
      if (res.data?.StatusMessage == "SUCCESS") {
        document.getElementById("Name").value = res.data.Data[0]?.Reason;
        document.getElementById("hideloding").style.display = "none";
        this.setState({ OldResaon: res.data?.Data[0].Reason });
      } else {
        toast.error(res.data?.Message);
        document.getElementById("hideloding").style.display = "none";
      }
    });
  }

  //back
  BackBtn() {
    history.push("/CAccountReasonUnqualified");
  }

  //Update client status
  async SaveBtn(e) {
    // this.state.Errors.Name = null;
    // this.state.NameAvailable = null;
    e.preventDefault();
    // e.currentTarget.disabled = true;
    this.setState({IsButtonDisabled:true});
    var Final_flag = await this.FromValidation();
    if (Final_flag == true) {
      var Name = document.getElementById("Name").value.trim();
      var data = {
        id: this.state.id,
        Reason: Name,
        LastUpdatedBy: this.state.UserIDby,
        LastUpdatedDate: new Date(),
        Role:this.state.Role
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/account_reason_unqualified/AccountReasonUnqualifiedUpdate",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Account Reason Unqualified
              <br />
              Account Reason Unqualified updated Successfully.
            </div>
          );
          history.push("/CAccountReasonUnqualified");
        } else {
          toast.error(res.data.Message);
          this.setState({IsButtonDisabled:false});
        }
      });
    } else {
      this.setState({IsButtonDisabled:false});
      //document.getElementById("submit").disabled = false;
    }
  }

  render() {
    return (
      <>
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain mheight">
            <Sidebar className="" />
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left pl-0">
                    Edit 
                  </h4>
                </div>
              </div>

            <div class="paddcols">
              <div className="row py-2 ">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Reason</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter Name"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        id="Name"
                        name="Name"
                        placeholder="Enter Reason"
                        type="text"
                      />
                      {this.state.NameAvailable && (
                        <span style={{ color: "red" }}>
                          Name already exist.
                        </span>
                      )}
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row my-3">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.IsButtonDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <button
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </button>
                </div>
              </div>
          </div>
        </div>
      <Footer />
      </>
    );
  }
}
function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCEditReasonUnqualifiedPage = connect(
  mapState,
  actionCreators
)(CEditReasonUnqualifiedPage);
export { connectedCEditReasonUnqualifiedPage as CEditReasonUnqualifiedPage };
