import React, { useEffect } from "react"; 
import semrushLogo from "../../../images/semrush-logo.png";

export default function Partnership() {
 
  return ( 
    <div className="partner-box">
        <h6>In Partnership With:</h6> <img src={semrushLogo} width={100} />
    </div>
  );
}
