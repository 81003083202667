import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import {
    IconButton,
    Collapse,
    Box,
    Paper,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    
  } from '@material-ui/core';
  import moment from 'moment';
  import Pagination from "@material-ui/lab/Pagination";
  import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

const SortingIcons = ({SortedBy,sortedColumn,ColumnName}) => {
  return (
    <span className="shorting">
           <ArrowUpward className={SortedBy === 1 && sortedColumn === ColumnName? "active": null}/>
           <ArrowDownward className={SortedBy === -1 && sortedColumn === ColumnName? "active": null}/>
    </span>
  )
}

function Meeting({UserID,ClientID,Role,AccountID,Domain}) {

  const [listData, setListData] = useState([]);
  const [Rows, setRows] = useState([]);
  const [Rlen, setRlen] = useState(0);
  const [Flen, setFlen] = useState(0);
  const [CountPage, setCountPage] = useState(0);
  const [Page, setPage] = useState(1);
  const [RowsPerPage, setRowsPerPage] = useState(100);
  const [Search, setSearch] = useState("");
  const [SortField, setSortField] = useState("CreatedDate");
  const [SortedBy, setSortedBy] = useState(-1);
  const [SflagM, setSflagM] = useState(false);
  const [MeetingNotes, setMeetingNotes] = useState([]);
  const [Open, setOpen] = useState(false);
  const [sortedColumn, setSortedColumn] = React.useState('CreatedDate');


  useEffect(() => {
    MeetingListsGet(Domain,Search, Page, RowsPerPage);
  }, [Search, Page, RowsPerPage, SortField, SortedBy,Domain,AccountID,ClientID]);


    // meeting Details
   const MeetingListsGet = (DomainList, Search, Page, RowPage) => {
    if(Domain?.trim() != "" && AccountID?.trim() != "" && ClientID?.trim() != "" && UserID?.trim() !== ""){
        var PListData = {
          Page: parseInt(Page),
          ClientID: ClientID,
          Domain: DomainList,
          RowsPerPage: parseInt(RowPage),
          Sort: true,
          Field: SortField,
          SortBy: SortedBy,
          Role: Role,
          Search: Search,
          Type: "User",
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/accounts/MeetingAccountGet",
          method: "POST",
          data: PListData,
        }).then((PListResult) => {
          if(PListResult.data.StatusMessage === "SUCCESS"){
            setListData(PListResult.data?.PageData);
            setRows(PListResult.data?.PageData);
            setRlen(PListResult.data?.TotalCount);
            setFlen(PListResult.data?.TotalCount);
            setCountPage(PListResult.data?.PageCount);
            setMeetingNotes(PListResult.data?.MeetingNotes);
          }else{
            toast.error(PListResult.data.Message)
          }
            
        });
      }
      }
    
      //Sorting Contact list
     const BookedMeetingSorting = (Field) => {
      setSortField(Field)
      setSortedColumn(Field);
      const isNewSortField = Field !== SortField;
      let SortBy = SortedBy === 1 ? -1 : 1;
    
      if (isNewSortField) {
        setSortedBy(1);
        SortBy = 1;
      } else {
        setSortedBy(SortBy);
      }
    
      setSortField(Field);
    
      }
    
      // search for record
     const RequestSearchListM = (Event) => {
        
        if (Event.key === "Enter") {
          var SearchedVal = document.getElementById("MeetingSearch").value;
          setSearch(SearchedVal);
        
        }
      };
    
      // change display rows
     const ChangeRowSelectedListM = (Event) => {
        setRowsPerPage(Number(Event.target.value));
        setPage(1)
        var SearchedVal = document.getElementById("MeetingSearch").value;
        setSearch(SearchedVal);
        var PListData = {
          Page: 1,
          ClientID: ClientID,
          Domain: Domain,
          RowsPerPage: Number(Event.target.value),
          Sort: true,
          Field: SortField,
          SortBy: SortedBy,
          Role: Role,
          Search: SearchedVal,
          Type: "User",
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/accounts/MeetingAccountGet",
          method: "POST",
          data: PListData,
        }).then((PListResult) => {
          
          if(PListResult.data.StatusMessage === "SUCCESS"){
            setListData(PListResult.data?.PageData);
            setRows(PListResult.data?.PageData);
            setRlen(PListResult.data?.TotalCount);
            setFlen(PListResult.data?.TotalCount);
            setCountPage(PListResult.data?.PageCount);
            setMeetingNotes(PListResult.data?.MeetingNotes);
          }else{
            toast.error(PListResult.data.Message)
          }
        });
      };
    
      // change page
     const HandleChangePageListM = (Event, NewPage) => {
        
        if (NewPage == Page) {
            setPage(NewPage)
        } else {
          var SearchedVal = document.getElementById("MeetingSearch").value;
          setSearch(SearchedVal)
          var PListData = {
            Page: NewPage,
            ClientID: ClientID,
            Domain: Domain,
            RowsPerPage: RowsPerPage,
            Sort: true,
            Field: SortField,
            SortBy: SortedBy,
            Search: SearchedVal,
            Role: Role,
            Type: "User",
          };
          Axios({
            url: CommonConstants.MOL_APIURL + "/accounts/MeetingAccountGet",
            method: "POST",
            data: PListData,
          }).then((PListResult) => {
            if(PListResult.data.StatusMessage === "SUCCESS"){
              setListData(PListResult.data?.PageData);
              setRows(PListResult.data?.PageData);
              setRlen(PListResult.data?.TotalCount);
              setFlen(PListResult.data?.TotalCount);
              setCountPage(PListResult.data?.PageCount);
              setMeetingNotes(PListResult.data?.MeetingNotes);
            }else{
              toast.error(PListResult.data.Message)
            }
          });
        }
      };
    
      // edit Meeting page
    const  MeetingEditPage = (id) => {
        // history.push("/editbookedmeeting", id);
        history.push({
          pathname: "/editbookedmeeting",
          state: {
            data: id,
            Pagename: "AccountContact",
            AccountData: AccountID,
          },
        });
      };
    
      // add Metting page
    const  MeetingAdd = () => {
        // history.push("/addbookedmeeting");
        history.push({
          pathname: "/addbookedmeeting",
          state: { data: AccountID, Pagename: "AccountContact" },
        });
      };
    
      //View Meeting 
      const ViewMeeting = (id) => {
        history.push({
            pathname: "/viewbookedmeeting",
            state: {
                data: id,
                Pagename: 'AccountContact',
                AccountData: AccountID,
              },
          });
      }


  return (
    <>
            <div className="row pt-3"> 

                <div className="col">                
                  <h3 className=" float-left pb-4 addpotential_header">
                    Meeting
                  </h3>
                </div>

                <div className="col text-right">
                  <button
                    onClick={MeetingAdd}
                    className="btn btngroup m-btn m-btn--custom "
                  >
                    <i className="la la-plus la-icon-font-size-13"></i>
                    <span className="Mobile_button">Add</span>
                  </button>
                </div> 
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={ChangeRowSelectedListM}
                    value={RowsPerPage}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value} key={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="MeetingSearch"
                    onKeyPress={(event) => RequestSearchListM(event)}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell  onClick={() => {BookedMeetingSorting("ContactName");}}>
                          Contact Name
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"ContactName"} />
                      </TableCell>
                      <TableCell onClick={() => {BookedMeetingSorting("Email");}}>
                          Email
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Email"} />
                      </TableCell>

                      <TableCell onClick={() => {BookedMeetingSorting("Company");}}>
                          Company
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Company"} />
                      </TableCell>
                      <TableCell onClick={() => {BookedMeetingSorting("Title");}}>
                          Title
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Title"} />
                      </TableCell>
                      <TableCell onClick={() => {BookedMeetingSorting("Status");}}>
                          Meeting Status
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Status"} />
                      </TableCell>
                      <TableCell onClick={() => {BookedMeetingSorting("MeetingSource");}}>
                          Meeting Source
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"MeetingSource"} />
                      </TableCell>
                      <TableCell onClick={() => {BookedMeetingSorting("MeetingOwner");}}>
                          Owner
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"MeetingOwner"} />
                      </TableCell>
                      <TableCell  onClick={() => {BookedMeetingSorting("CreatedDate");}}>
                          Meeting Date
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"CreatedDate"} />
                      </TableCell>
                      <TableCell onClick={() => {BookedMeetingSorting("EventCreatedDt");}}>
                          Meeting Set Date
                          <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"EventCreatedDt"} />
                      </TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Rows?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      Rows?.map((row) => (
                        <React.Fragment key={row._id}>
                          <TableRow>
                            <TableCell align="center">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() =>
                                    setOpen((prev) => ({
                                        ...prev,
                                        [row._id]: !prev[row._id],
                                      }))
                                }
                              >
                                {Open[row._id] ? (
                                  <i
                                    className="fa fa-minus-circle minusl"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-plus-circle plusbl"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell><a onClick={()=>{ViewMeeting(row._id)}}><u>{row?.ContactName}</u></a> </TableCell>
                            <TableCell>{row?.Email}</TableCell>
                            <TableCell>{row?.Company}</TableCell>
                            <TableCell>{row?.JobTitle}</TableCell>
                            <TableCell>{row?.Status}</TableCell>
                            <TableCell>{row?.MeetingSource}</TableCell>
                            <TableCell>{row?.MeetingOwner}</TableCell>
                            <TableCell>
                              {row?.EventStartDt}
                            </TableCell>
                            <TableCell>
                              {row?.EventCreatedDt}
                            </TableCell>
                            <TableCell>
                              <a
                                onClick={() => {
                                  MeetingEditPage(row._id);
                                }}
                                className="btn-eyesicon"
                              >
                                <i className="la flaticon-edit-1 edit-icon"></i>
                              </a>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={6}
                            >
                              <Collapse
                                in={Open[row._id]}
                                timeout="auto"
                                unmountOnExit
                              >
                                {Open[row._id] && (
                                  <Box margin={1} className="innertables">
                                    <Table size="small" aria-label="purchases">
                                      <TableHead></TableHead>
                                      {MeetingNotes.map((note) =>
                                        note.MeetingID.toString() ==
                                        row?._id.toString() ? (
                                          <TableRow>
                                            <TableCell><b>{note?.Title}</b></TableCell>
                                            <TableCell><b> {note.CreatedDate? moment(new Date(note.CreatedDate).toDateString()).format("MM/DD/YYYY"): ""}</b></TableCell>
                                            <TableCell scope="row">
                                              {note.Note}
                                            </TableCell>
                                           
                                          </TableRow>
                                        ) : null
                                      )}

                                      {!MeetingNotes?.some(
                                        (note) =>
                                          note?.MeetingID.toString() ==
                                          row?._id.toString()
                                      ) && (
                                        <TableRow>
                                          <TableCell colSpan={4}>
                                            No data available
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </Table>
                                  </Box>
                                )}
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
           
              <div className="row">
                <div className="col dataTables_info">
                  <p>
                    Showing{" "}
                    {Rlen == 0
                      ? 0
                      : (Page - 1) * RowsPerPage +
                        1}{" "}
                    to{" "}
                    {Page * RowsPerPage >
                    Rlen
                      ? Rlen
                      : Page * RowsPerPage}{" "}
                    of {Rlen} entries
                  </p>
                </div>
                <div className="col pageright">
                  <Pagination
                    component="div"
                    count={CountPage}
                    page={Page}
                    onChange={HandleChangePageListM}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
           
    </>
  )
}

export default Meeting