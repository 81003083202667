import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { userActions } from "../../../_actions";
import loadingicon from "../../../images/loading.gif";
import Footer from "../../../_components/user/footer/footer";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

class CEditContactsCustomFieldpage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      FieldNameAvailble: null,
      ClientID: null,
      UserID: null,
      OldName:null,
      IsExist: false,
      Role: "",
      UserIDby:null,
      BtnDisabled:false,
      IsLoading:true
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }

  componentDidMount() {
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.Role = Details.Role;
      this.state.UserIDby = Details.ChildUserID;
    }
    this.ContactCustomFieldEdit();
  }

  // back button
  BackBtn() {
    history.push("/CContactsCustomField");
  }

  // form validation
  async FromValidation() {
    let formIsValid = true;
    var Name = document.getElementById("Name").value.trim();
    var IsExist = await this.ContactCustomFieldNameCheckExits(Name);
      let Errors = {};

      if (Name == "" && Name.indexOf(" ") != "") {
        formIsValid = false;
        Errors["Name"] = "Please enter field name";
      }
      if(IsExist == true){
        formIsValid = false;
      }
      this.setState({ Errors: Errors });
    return formIsValid;
  }

  // handle change
  HandleChange() {
    var Name = document.getElementById("Name").value.trim();

    if (Name != "" && Name.indexOf(" ") != "") {
      this.state.Errors.Name = null;
      this.state.FieldNameAvailble = null;
      this.ContactCustomFieldNameCheckExits(Name);
    } else {
      this.state.FieldNameAvailble = null;
      this.state.Errors.Name = null;
    }
  }

  // Contact Custom Field Name Check Exits
  async ContactCustomFieldNameCheckExits(Name) {
    var str_in = {
      ClientID: this.state.ClientID,
      FieldName: Name,
      Role:this.state.Role
    };
    var resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/contactcustom/ContactCustomFieldExists",
      method: "POST",
      data: str_in,
    })
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          if(this.state.OldName == Name){
            this.setState({ IsExist: true });
            return false;
          }else{
          this.setState({ FieldNameAvailble: resdata.data.Data[0]?.FieldName });
            this.setState({ IsExist: true });
            return true;
        }
        }else{
          this.setState({ IsExist: false });
          return false;
        }
      }
      
  }

  // contact custom field edit data
  ContactCustomFieldEdit() {
    const id = this.props.location.state;
    this.setState({ id: id });
    var ContactCustomFieldData = {
      id: id,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contactcustom/ContactCustomFieldByID",
      method: "POST",
      data: ContactCustomFieldData,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById("Name").value = res.data.Data[0].FieldName;
        this.setState({OldName:res.data.Data[0].FieldName})
       
        this.setState({IsLoading:false})
      } else {
        toast.error(res.data.Message);
        this.setState({IsLoading:false})
      }
    });
  }

  // save button
  async SaveBtn() {
    this.setState({BtnDisabled:true})

    var FinalValidation = await this.FromValidation()
    if (FinalValidation == true) {
      this.setState({IsLoading:true})
      var Name = document.getElementById("Name").value.trim();
      var data = {
        _id: this.state.id,
        FieldName: Name,
        LastUpdatedBy: this.state.UserIDby,
        LastUpdatedDate: new Date(),
        Role:this.state.Role
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/contactcustom/ContactCustomFieldUpdate",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div className="toastsize" >Custom  contact field edit<br/> Custom contact field updated successfully.</div>);
          history.push("/CContactsCustomField");
        } else {
          toast.error(res.data.Message);
          this.setState({BtnDisabled:false})
          this.setState({IsLoading:false})
        }
      });
    } else {
   
      this.setState({BtnDisabled:false})
      this.setState({IsLoading:false})
    }
  }

  render() {
    return (
      <>
      {
        this.state.IsLoading == true ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : null

      }

        <div className="adminmain mheight"> 
            <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad pl-0 mb-0">
                  Edit Custom Contact Field
                  </h4>
                </div>
              </div>

            <div class="paddcols">
              <div className="row py-2">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Field Name</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter  field name"
                        id="Name"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        name="Name"
                        placeholder="Enter field name"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                      {this.state.FieldNameAvailble && (
                        <span style={{ color: "red" }}>
                            Flield name already exist.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row my-3">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.BtnDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <button
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </button>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCEditContactsCustomFieldpage = connect(
  mapState,
  actionCreators
)(CEditContactsCustomFieldpage);
export { connectedCEditContactsCustomFieldpage as CEditContactsCustomFieldpage };
