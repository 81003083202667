import React from "react";
import { connect } from "react-redux";
import Axios from "axios";

import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { CommonConstants } from "../../../_constants/common.constants";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../../_helpers/Utility";
import { userActions } from "../../../_actions";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";
toast.configure();
class CEditMeetingStatusPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      StatusAvailable: null,
      ClientID: null,
      UserID: null,
      VariableType: [],
      SimpleMeeting: [],
      DefaultSelectEng: null,
      DefaultSelectSimpleMeeting: null,
      OldName: null,
      UserIDby: null,
      Role: null,
      BtnDisabled: false,
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.HandleEngagement = this.HandleEngagement.bind(this);
  }

  componentDidMount() {
    document.title = "Edit Meeting Statuses | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.Role = Details.Role;
      this.state.UserIDby = Details.ChildUserID;
    }
    this.EditMeetingstatus();
  }

  // form validation
  async FromValidations() {
    let FormIsValid = true;
    let Fields = this.state.Fields;
    let Errors = {};
    var status = document.getElementById("status").value.trim();
    var VariableType = document.getElementById("VariableType").value;
    var SimpleMeeting = document.getElementById("SimpleMeeting").value;
    var SimpleProbability = document.getElementById("Probability").value;
    var IsExist = await this.CheckExistStatus(status);
    if (VariableType == "") {
      FormIsValid = false;
      Errors["VariableType"] = "Please select status type";
    }
    if (parseInt(SimpleProbability) > 100) {
      FormIsValid = false;
      Errors["Probability"] = "Please enter a value less than or equal to 100.";
    }
    if (SimpleMeeting == "") {
      FormIsValid = false;
      Errors["SimpleMeeting"] = "Please select simple meeting status";
    }
    if (IsExist == "ExistStatus") {
      FormIsValid = false;
      Errors["status"] = "Status Already Exist";
    }

    if (IsExist == "BlankData" && status == "") {
      FormIsValid = false;
      Errors["status"] = "Please enter status";
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

  // back button
  BackBtn() {
    history.push("/CMeetingStatus");
  }

  // form validation
  async HandleChangeBlur(Name) {
    let Errors = { ...this.state.Errors };
    var status = document.getElementById("status").value.trim();
    var SimpleProbability = document.getElementById("Probability").value;
    var IsExist = await this.CheckExistStatus(status);
    if (status == "") {
      Errors["status"] = "Please enter status";
    }

    if (IsExist == "ExistStatus") {
      Errors["status"] = "Status Already Exist";
    }

    if (Name == "ProbabilityName") {
      if (parseInt(SimpleProbability) > 100) {
        Errors["Probability"] =
          "Please enter a value less than or equal to 100.";
      } else {
        Errors["Probability"] = null;
      }
    }

    if (IsExist == "AllDone") {
      Errors["status"] = null;
    }

    this.setState({ Errors: Errors });
  }

  // handle change
  HandleChange(Field, e) {
    let Fields = this.state.Fields;
    Fields[Field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Status != "") {
      this.state.Errors.Status = null;
      this.state.StatusAvailable = null;
      this.CheckExistStatus(e.target.value);
    } else {
      this.state.StatusAvailable = null;
      this.state.Errors.Status = null;
    }
  }

  // find exits status
  async CheckExistStatus(Status) {
    if (Status != "") {
      var CheckMeetingStatus = {
        ClientID: this.state.ClientID,
        Status: Status,
      };
      var res = await Axios({
        url: CommonConstants.MOL_APIURL + "/meetingstatus/MeetingStatusExists",
        method: "POST",
        data: CheckMeetingStatus,
      });
      if (res.data.StatusMessage == "SUCCESS") {
        if (res.data.Data.length > 0) {
          if (this.state.OldName == Status) {
            //
            this.setState({ IsExist: false });
            return "AllDone";
          } else {
            return "ExistStatus";
          }
        } else {
          return "AllDone";
        }
      }
    } else {
      return "BlankData";
    }
  }

  // find id to record
  EditMeetingstatus() {
    const id = this.props.location.state;
    this.setState({ id: id });
    var MeetingStatusByID = {
      id: id,
      Role: this.state.Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/meetingstatus/MeetingStatusFindById",
      method: "POST",
      data: MeetingStatusByID,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById("status").value = res.data.Data[0]?.Status;
        document.getElementById("Probability").value =
          res.data.Data[0]?.Probability;
        this.setState({ DefaultSelectEng: res.data.Data[0]?.StatusTypeID });
        this.setState({
          DefaultSelectSimpleMeeting: res.data.Data[0]?.SimpleMeetingStatusID,
        });
        this.setState({ OldName: res.data.Data[0].Status });
        Axios({
          url: CommonConstants.MOL_APIURL + "/meetingstatus/StatusTypeListGet",
          method: "POST",
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            this.setState({ VariableType: res.data?.Data });
            this.setState({ SimpleMeeting: res.data?.SimpleMeetingT });
            document.getElementById("hideloding").style.display = "none";
          } else {
            toast.error(res.data.Message);
          }
        });
      } else {
        toast.error(res.data.Message);
      }
    });
  }

  // save to update meeting status
  async SaveBtn(e) {
    this.setState({ BtnDisabled: true });
    e.preventDefault();
    // this.state.Errors.Name = null;
    // this.state.StatusAvailable = null;
    // e.currentTarget.disabled = true;
    var Final_flag = await this.FromValidations();
    if (Final_flag == true) {
      var status = document.getElementById("status").value;
      var Probability = document.getElementById("Probability").value;
      var VariableType = document.getElementById("VariableType").value;
      var SimpleMeeting = document.getElementById("SimpleMeeting").value;

      var MeetingStatusUpdate = {
        _id: this.state.id,
        Status: status,
        StatusTypeID: VariableType,
        Probability: Probability,
        LastUpdatedBy: this.state.UserIDby,
        LastUpdatedDate: new Date(),
        SimpleMeetingID: SimpleMeeting,
        Role: this.state.Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/meetingstatus/MeetingStatusUpdate",
        method: "POST",
        data: MeetingStatusUpdate,
      }).then((res) => {
        if (res.statusText == "OK") {
          toast.success(
            <div className="toastsize">
              Meeting Status edit
              <br />
              Meeting Status updated successfully.
            </div>
          );
          history.push("/CMeetingStatus");
        } else {
          toast.error(res.data.Message);
          this.setState({ BtnDisabled: false });
          //document.getElementById("submit").disabled = false;
        }
      });
    } else {
      this.setState({ BtnDisabled: false });
      //document.getElementById("submit").disabled = false;
    }
  }

  // handle change dropdown
  HandleEngagement(e) {
    this.setState({ DefaultSelectEng: e.target.DefaultSelectEng });
  }

  // handle change dropdown
  HandleEngagementSimple(e) {
    console.log(e);
    this.setState({
      DefaultSelectSimpleMeeting: e.target.DefaultSelectSimpleMeeting,
    });
  }

  render() {
    return (
      <>
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="row">
            <div className="col">
              <h4 className="headertitlepad float-left mb-0 pl-0">
                  Edit Meeting Statuses
                </h4>
              </div>
            </div>

            <div class="paddcols mb-3">
              <div className="row py-4 px-3 ">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Status</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter Status"
                        //onBlur={this.HandleChange.bind(this, "status")}
                        //value={this.state.Fields["Status"]}
                        id="status"
                        name="Name"
                        placeholder="Enter Status"
                        type="text"
                        onBlur={() => this.HandleChangeBlur("StatusName")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["status"]}
                      </span>
                      {/* {this.state.StatusAvailable && (
                        <span style={{ color: "red" }}>
                          Status already exist.
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Status Type</label>
                    <div class="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="VariableType"
                        name="VariableType"
                        autoComplete="off"
                        value={this.state.DefaultSelectEng}
                        onChange={this.HandleEngagement}
                        // onBlur={this.HandleChangeBlur}
                      >
                        {this.state.VariableType.map((value) => (
                          <option value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Probability</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter Probability"
                        // onChange={this.HandleChange.bind(this, "Probability")}
                        // value={this.state.Fields["Probability"]}
                        id="Probability"
                        name="Probability"
                        placeholder="Enter Probability"
                        type="number"
                        onBlur={() => this.HandleChangeBlur("ProbabilityName")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Probability"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Simple Meeting Status
                    </label>
                    <div class="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="SimpleMeeting"
                        name="SimpleMeeting"
                        autoComplete="off"
                        value={this.state.DefaultSelectSimpleMeeting}
                        onChange={(e) => {
                          this.HandleEngagementSimple(e);
                        }}
                        //onBlur={this.HandleChangeBlur}
                      >
                        {/* {<option value="">--Select--</option>} */}
                        {this.state.SimpleMeeting.map((value) => (
                          <option value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["SimpleMeeting"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-lg-12 pull-left">
                <button
                  id="submit"
                  onClick={this.SaveBtn}
                  disabled={this.state.BtnDisabled}
                  class="btn btn-primary btn-lightgreen mr-1"
                >
                  <i class="la la-save"></i> Save
                </button>
                <a
                  id="backtolist"
                  onClick={this.BackBtn}
                  class="btn btn-secondary"
                >
                  <i class="la la-arrow-circle-left"></i> Back
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCEditMeetingStatusPage = connect(
  mapState,
  actionCreators
)(CEditMeetingStatusPage);
export { connectedCEditMeetingStatusPage as CEditMeetingStatusPage };
