import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

toast.configure();

class AddClientPotentialMeetingsPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      fields: {},
      errors: {},
      statusAvailable: null,
      AllClientList: [],
      EngagementList: [],
      PotentialOwnerList: [],
      PotentialSourceList: [],
      PotentialAccountList: [],
      UserID:Details.ParentUserID,
      CUserID:Details.ChildUserID,
      ClientID:Details.ClientID,
    };
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveClientPotentialbtn = this.SaveClientPotentialbtn.bind(this);
    this.GetAllClientList(Details.ParentUserID);
  }

  //All Client
  GetAllClientList(UserId) {
    var str_in = {
      UserID: UserId,
    };
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/potentialmeeting/AllClientList",
      method: "POST",
      data: str_in,
    });
    rows.then((result) => {
      this.setState({
        AllClientList: result.data.Data,
      });
    });
  }

  //Client ID
  HandleClientID = (e) => {
    var str_in = {
      ClientID: e.target.value,
    };
    const rows = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/potentialmeeting/ClientPotentialAllStatusGet",
      method: "POST",
      data: str_in,
    });
    rows.then((Result) => {
      this.setState({
        EngagementList: Result.data.Enggementstatus,
        PotentialOwnerList: Result.data.MeetingSource,
        PotentialSourceList: Result.data.PotentialOwner,
        PotentialAccountList: Result.data.MeetingAccount,
      });
    });
  };

  //Form validation
  FromValidation() {
    let formIsValid = true;
    let fields = this.state.fields;
    let errors = {};
    if (!fields["FirstName"]) {
      formIsValid = false;
      errors["FirstName"] = "Please enter first name";
    }
    if (!fields["LastName"]) {
      formIsValid = false;
      errors["LastName"] = "Please enter last name";
    }
    if (!fields["Title"]) {
      formIsValid = false;
      errors["Title"] = "Please enter Title";
    }
    if (!fields["Company"]) {
      formIsValid = false;
      errors["Company"] = "Please enter Company";
    }
    if (!fields["Email"]) {
      formIsValid = false;
      errors["Email"] = "Please enter Email";
    }
    if (!fields["MeetingSourceID"]) {
      formIsValid = false;
      errors["MeetingSourceID"] = "Please select Meeting Source";
    }
    if (!fields["ClientPOCID"]) {
      formIsValid = false;
      errors["ClientPOCID"] = "Please select reminder email account";
    }
    if (!fields["MeetingOwnerID"]) {
      formIsValid = false;
      errors["MeetingOwnerID"] = "Please select owner";
    }
    if (!fields["EngagementStatusID"]) {
      formIsValid = false;
      errors["EngagementStatusID"] = "Please select status";
    }
    // if (!fields["ClientPotentialID"]) {
    //   formIsValid = false;
    //   errors["ClientPotentialID"] = "Please select Client";
    // }
    // if (this.state.UserExist != null) {
    //     formIsValid = false;
    // }
    this.setState({ errors: errors });
    return formIsValid;
  }

  //handle change
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.Name != "") {
      this.state.errors.Name = null;
      this.state.ClientPotentialMeetingAvailble = null;
      // this.CheckExistContactSourceName(e.target.value);
    } else {
      this.state.ClientPotentialMeetingAvailble = null;
      this.state.errors.Name = null;
    }
  }

  //exist potential meeting
  CheckExistPotentialMeetingName(FirstName) {
    var str_in = {
      ClientID: this.state.ClientID,
      FirstName: FirstName,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/potentialmeeting/PotentialMeetingStatusExists",
      method: "POST",
      data: str_in,
    }).then((resdata) => {
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.data.length > 0) {
          this.setState({ statusAvailable: resdata.data.data });
        }
      }
    });
  }

  SaveClientPotentialbtn() {
    var FirstName = document.getElementById("FirstName").value;
    var LastName = document.getElementById("LastName").value;
    var Title = document.getElementById("Title").value;
    var Company = document.getElementById("Company").value;
    var Email = document.getElementById("Email").value;
    var LinkedInURL = document.getElementById("LinkedInURL").value;
    var DirectPhone = document.getElementById("DirectPhone").value;
    var CompanyPhone = document.getElementById("CompanyPhone").value;
    var MeetingSourceID = document.getElementById("MeetingSourceID").value;
    var MeetingOwnerID = document.getElementById("MeetingOwnerID").value;
    var EngagementStatusID =
      document.getElementById("EngagementStatusID").value;
    var ReminderEmailAccount = document.getElementById("ClientPOCID").value;

    if (this.FromValidation()) {
      var data = {
        FirstName: FirstName,
        LastName: LastName,
        Email: Email,
        Title: Title,
        Company: Company,
        LinkedInUrl: LinkedInURL,
        DirectPhone: DirectPhone,
        CompanyPhone: CompanyPhone,
        EngagamentStatusID: EngagementStatusID,
        MeetingSourceID: MeetingSourceID,
        MeetingOwnerID: MeetingOwnerID,
        ReminderEmailAccountID: ReminderEmailAccount,
        Domain: Email.substring(Email.lastIndexOf("@") + 1),
        SalesReply_MessageID: null,
        ConversionCampaignID: null,
        ProspectLastReply: null,
        IsDeleted: false,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
        LastUpdatedBy: null,
        LastUpdatedDate: null,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/potentialmeeting/PotentialMeetingAdd",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            "Client Potential meeting Status add successfully.",
            "Add Potential meeting Status"
          );
          // if(this.props.location.state=="ClientPotential"){
          history.push("/clientpotentialmeetings");
          // }else{
          //   history.push("/potentialmeeting");
          // }
          console.log("successfull Potential meeting", res.data);
        } else {
          toast.error(res.data.Message);
        }
      });
    }
  }

  //back btn
  BackBtn() {
    // if(this.props.location.state=="ClientPotential"){
    history.push("/clientpotentialmeetings");
    // }else{
    //   history.push("/potentialmeeting");
    // }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            
            <div className="row">
              <div className="col">
                <h4 className="headertitlepad float-left pl-0">
                  Client Add Potential Meeting{" "}
                </h4>
              </div>
            </div>

            <div class="paddcols">
              <div className="row pt-4">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Client</label>
                    <div class="col-lg-8">
                      <select
                        className="form-control "
                        name="Client"
                        onChange={this.HandleClientID}
                        id="ClientPotentialID"
                      >
                        <option value="">--Select--</option>
                        {this.state.AllClientList.map((value) => (
                          <option value={value.ClientID}>{value.Name}</option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClientPotentialID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6"></div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">First Name </label>
                    <div class="col-lg-8">
                      <input
                        type="text"
                        class="form-control"
                        id="FirstName"
                        name="FirstName"
                        placeholder="Enter first name"
                        onChange={this.handleChange.bind(this, "FirstName")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["FirstName"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Last Name</label>
                    <div class="col-lg-8">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter first name"
                        id="LastName"
                        name="LastName"
                        onChange={this.handleChange.bind(this, "LastName")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["LastName"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Title</label>
                    <div class="col-lg-8">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Title"
                        id="Title"
                        name="Title"
                        onChange={this.handleChange.bind(this, "Title")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Title"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Company</label>
                    <div class="col-lg-8">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Company"
                        id="Company"
                        name="Company"
                        onChange={this.handleChange.bind(this, "Company")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Company"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Email</label>
                    <div class="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid email."
                        data-val-remote="Email already exist."
                        data-val-remote-additionalfields="*.Email,*.PageName,*.CampaignID,*.ClientID,*.UserID"
                        data-val-remote-url="/Utility/IsProspectEmailExistsAndBlacklist"
                        data-val-required="Please enter email."
                        id="Email"
                        name="Email"
                        type="text"
                        onChange={this.handleChange.bind(this, "Email")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Email"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">LinkedIn Url</label>
                    <div class="col-lg-8">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="LinkedIn Url"
                        id="LinkedInURL"
                        name="LinkedInURL"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Meeting Source{" "}
                    </label>
                    <div class="col-lg-8">
                      <select
                        className="form-control "
                        id="MeetingSourceID"
                        name="MeetingSourceID"
                        onChange={this.handleChange.bind(
                          this,
                          "MeetingSourceID"
                        )}
                      >
                        <option value="">--Select--</option>
                        {this.state.PotentialSourceList.map((value) => (
                          <option value={value.MeetingSourceID}>
                            {value.Name}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["MeetingSourceID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Owner</label>
                    <div class="col-lg-8">
                      <select
                        className="form-control "
                        id="MeetingOwnerID"
                        name="MeetingOwnerID"
                        onChange={this.handleChange.bind(
                          this,
                          "MeetingOwnerID"
                        )}
                      >
                        <option value="">--Select--</option>
                        {this.state.PotentialOwnerList.map((value) => (
                          <option value={value.MeetingOwnerID}>
                            {value.Name}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["MeetingOwnerID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Reminder Email Account
                    </label>
                    <div class="col-lg-8">
                      <select
                        className="form-control "
                        id="ClientPOCID"
                        name="ClientPOCID"
                        onChange={this.handleChange.bind(this, "ClientPOCID")}
                      >
                        <option value="">--Select--</option>
                        {this.state.PotentialAccountList.map((value) => (
                          <option value={value.AccountID}>
                            {value.SMTPEmail}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClientPOCID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Engagement Status
                    </label>
                    <div class="col-lg-8">
                      <select
                        className="form-control "
                        id="EngagementStatusID"
                        name="EngagementStatusID"
                        onChange={this.handleChange.bind(
                          this,
                          "EngagementStatusID"
                        )}
                      >
                        <option value="">--Select--</option>
                        {this.state.EngagementList.map((value) => (
                          <option value={value.PotentialMeetingStatusID}>
                            {value.Status}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["EngagementStatusID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Direct Phone</label>
                    <div class="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid direct phone"
                        data-val-regex-pattern="^[0-9]*$"
                        id="DirectPhone"
                        name="DirectPhone"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Company Phone
                    </label>
                    <div class="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid company phone"
                        data-val-regex-pattern="^[0-9]*$"
                        id="CompanyPhone"
                        name="CompanyPhone"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row border-top py-4 mt-3">
                <div class="col-lg-12 pull-left">
                  <a
                    id="submit"
                    onClick={this.SaveClientPotentialbtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </a>
                  <a
                    id="usersperclient"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddClientPotentialMeetingsPage = connect(
  mapState,
  actionCreators
)(AddClientPotentialMeetingsPage);
export { connectedAddClientPotentialMeetingsPage as AddClientPotentialMeetingsPage };
