import React, { useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Swal from "sweetalert2";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import Pagenations from "../../Pagenations";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

const imgstyle = {
  height: "40px",
  width: "40px",
  borderradius: "50%",
};

export default function AccountAllTable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("ColdCallersName");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Reload, SetReload] = React.useState(false);
  const [IsArchive, SetIsArchive] = React.useState(false);
  const [AnchorEl, SetAnchorEl] = React.useState(null);
  const [AnchorElf, SetFanchorEl] = React.useState(null);
  const [selectedRowId, setSelectedRowId] = React.useState(null);
  const [AID, SetAID] = React.useState("");
  const [ColdCallerTeams, SetColdCallerTeams] = React.useState([]);
  const [ColdCallerTeamID, SetColdCallerTeamID] = React.useState(0);
  const [CUserID, SetCUserID] = React.useState(0);
  const [sortedColumn, setSortedColumn] = React.useState("ColdCallersName");

  useEffect(() => {
    document.getElementById("hideloding").style.display = "block";
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    ColdcallerGet(GetUserData.ParentUserID, GetUserData.Role);
    ColdCallerTeam(GetUserData.ParentUserID);
    OriginalDataStore();
  }, [Search, Page, RowsPerPage, IsArchive, ColdCallerTeamID]);

  //cold caller Get list
  const ColdcallerGet = (UID, URole) => {
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      UserID: UID,
      Role: URole,
      IsArchive: IsArchive,
      ColdCallerTeamID: ColdCallerTeamID,
    };

    Axios({
      url:
        CommonConstants.MOL_APIURL + "/cold_callers_profile/GetColdCallerList",
      method: "POST",
      data: InputParameter,
    }).then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      document.getElementById("hideloding").style.display = "none";
    });
  };

  //Get Cold caller team Dropdown
  const ColdCallerTeam = (UID) => {
    var InputParameter = {
      UserID: UID,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/cold_callers_profile/ColdCallersTeamDropDown",
      method: "POST",
      data: InputParameter,
    }).then((Result) => {
      SetColdCallerTeams(Result.data.Data);
    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value?.trim();
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
        document.getElementById("hideloding").style.display = "block";
      }
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      document.getElementById("hideloding").style.display = "block";
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    document.getElementById("hideloding").style.display = "block";
  };

  //get sort field data
  const SortData = (Field) => {
    if(Field == '2FA' || Field == 'Action'){
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);

    }else{
      document.getElementById("hideloding").style.display = "block";

      var SearchedVal = document.getElementById("search").value?.trim();
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        UserID: UserID,
        Role: Role,
        IsArchive: IsArchive,
        Search: SearchedVal,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/cold_callers_profile/GetColdCallerList",
        method: "POST",
        data: InputParameter,
      }).then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        document.getElementById("hideloding").style.display = "none";
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        UserID: UserID,
        Role: Role,
        IsArchive: IsArchive,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/cold_callers_profile/GetColdCallerList",
        method: "POST",
        data: InputParameter,
      }).then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        document.getElementById("hideloding").style.display = "none";
      });
    }
    }
    
  };

  //Search filter
  const SearchFilter = () => {
    var Check = document.getElementById("include_Archive").checked;
    var ColdCallerTeamID = document.getElementById("ColdCallerTeamID").value;
    SetIsArchive(Check);
    SetColdCallerTeamID(ColdCallerTeamID);
  };

  //Reset Filter table
  const ResetFilter = () => {
    document.getElementById("include_Archive").checked = false;
    document.getElementById("ColdCallerTeamID").value = 0;
    SetIsArchive(false);
    SetColdCallerTeamID(0);
    ColdcallerGet(UserID, Role);
  };

  //Handle Click
  const HandleClick = (event, id) => {
    SetAID(id);
    SetAnchorEl(event.currentTarget);
    setSelectedRowId(id);
  };

  //Close AnchorEl
  const HandleClose = () => {
    SetAID(null);
    SetAnchorEl(null);
    setSelectedRowId(null);
  };

  //Handle AnchorEl
  const HandleClickOne = (event, id) => {
    SetAID(id);
    SetFanchorEl(event.currentTarget);
    setSelectedRowId(id);
  };

  //Close handle
  const HandleCloseOne = () => {
    SetAID(null);
    SetFanchorEl(null);
    setSelectedRowId(null);
  };

  //Find Archive
  const OriginalDataStore = () => {
    const Rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/cold_callers_profile/findnoArchive",
      method: "GET",
    });
    Rows1.then((Result) => {
      SetOrignalData(Result.data);
    });
  };

  //Edit page
  const EditPage = (id) => {
    history.push("/editcoldcallers", id);
  };

  //Update Status
  const UpdateStatus = (ID, Flag) => {
    const InputParameters = {
      ID: ID,
      Flag: Flag,
      LastUpdatedBy: CUserID,
      LastUpdatedDate: new Date(),
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/cold_callers_profile/UpdateIsTwoStepVerification",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      if (res) {
        if ((res.statusText = "Ok")) {
          if (res.data.Data.IsTwoStepVerification == true) {
            toast.success(
              <div className="toastsize">
                Cold caller <br /> Two Step Verification Activated successfully.{" "}
              </div>
            );
          } else {
            toast.success(
              <div className="toastsize">
                Cold caller <br /> Two Step Verification Deactivated
                successfully.{" "}
              </div>
            );
          }
          ColdcallerGet(UserID, Role);
        } else {
        }
      }
    });
  };

    //cold caller Get list
    const ColdcallerGetRender = (UID, URole,IsArchive) => {
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
        UserID: UID,
        Role: URole,
        IsArchive: IsArchive,
        ColdCallerTeamID: ColdCallerTeamID,
      };
  
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/cold_callers_profile/GetColdCallerList",
        method: "POST",
        data: InputParameter,
      }).then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        document.getElementById("hideloding").style.display = "none";
      });
    };

  //Archive User
  const ArchivedUser = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to Archive a Sales development reps.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var InputParameters = {
          uID: AID,
          Type: "archive",
          LastUpdatedDate: new Date(),
          LastUpdatedBy: UserID,
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL + "/cold_callers_profile/ArchiveProfile",
          method: "POST",
          data: InputParameters,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Archived!",
                "Sales development reps Archived successfully.",
                "success"
              );
              SetIsArchive(false);
              HandleClose();
              HandleCloseOne();
              ColdcallerGetRender(UserID, Role,false);
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
        HandleClose();
        HandleCloseOne();
      }
    });
  };

  //Unarchive User
  const UnArchivedUser = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to unarchive a Sales development reps.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, unarchive it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var InputParameters = {
          uID: AID,
          Type: "unarchive",
          LastUpdatedDate: new Date(),
          LastUpdatedBy: UserID,
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL + "/cold_callers_profile/ArchiveProfile",
          method: "POST",
          data: InputParameters,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Unarchived!",
                "Sales development reps unarchived successfully.",
                "success"
              );
              SetIsArchive(false);
              HandleClose();
              HandleCloseOne();
              ColdcallerGetRender(UserID, Role,false);
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
        HandleClose();
        HandleCloseOne();
      }
    });
  };

  //Delete user
  const DeleteUser = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a Sales development reps.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var InputParameters = {
          ID: AID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/cold_callers_profile/DeleteColdCaller",
          method: "POST",
          data: InputParameters,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Sales development reps deleted successfully.",
                "success"
              );
              HandleClose();
              HandleCloseOne();
              ColdcallerGet(UserID, Role);
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
        HandleClose();
        HandleCloseOne();
      }
    });
  };

  return (
    <div>
      <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
      <div className="row borderbtnm">
        <div className="col py-3">
          <div className="row">
            <label className="col-lg-3 view-lable-meet pt-2">
              Sales Development Team
            </label>
            <div className="col-lg-7 pt-3">
              <select
                className="form-control  m-input"
                id="ColdCallerTeamID"
                name="ColdCallerTeamID"
                autocomplete="off"
                // value={RowsPerPage}
              >
                <option value="0">--Select--</option>
                {ColdCallerTeams.map((value) => (
                  <option key={value._id} value={value._id}>
                    {value.ColdCallerTeamName}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="col padright flexdisplayer py-3">
          <label className="check_bx floatrighter mr-3 mt-3">
            <input id="include_Archive" Type="checkbox" />
            <span className="checkmark"></span>
            Include archived sales development reps
          </label>
          <div className="fulleriger mt-2">
            <button
              Type="submit"
              onClick={SearchFilter}
              className="btn btn-primary btnColor"
            >
              <span>
                <i className="la la-search mr-2"></i>
                <span>Search</span>
              </span>
            </button>
            <a onClick={ResetFilter} className="btn btn-secondary">
              <i className="la la-close"></i> Reset
            </a>
          </div>
        </div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              Type="Search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell onClick={() => { SortData("2FA"); setSortedColumn("2FA");}}>Is Two Step Verification</TableCell>
                <TableCell onClick={() => { SortData("ColdCallersName"); setSortedColumn("ColdCallersName");}}>
                    Name
                    <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "ColdCallersName" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "ColdCallersName" ? "active" : null} />
                  </span>
                </TableCell>
                <TableCell onClick={() => { SortData("Email"); setSortedColumn("Email");}}>
                    Email
                    <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "Email" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "Email" ? "active" : null} />
                  </span>
                </TableCell>
                <TableCell onClick={() => { SortData("ColdCallerTeamName"); setSortedColumn("ColdCallerTeamName");}}>
                    Cold Caller Team Name
                    <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "ColdCallerTeamName" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "ColdCallerTeamName" ? "active" : null} />
                  </span>
                </TableCell>
                <TableCell onClick={() => { SortData("IsTeamLead"); setSortedColumn("IsTeamLead");}}>
                See All Clients
                    <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "IsTeamLead" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "IsTeamLead" ? "active" : null} />
                  </span>
                </TableCell>
                <TableCell onClick={() => { SortData("SlackAccount"); setSortedColumn("SlackAccount");}}>
                    Slack Account
                    <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "SlackAccount" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "SlackAccount" ? "active" : null} />
                  </span>
                </TableCell>
                <TableCell onClick={() => { SortData("Action"); setSortedColumn("Action");}}>Action  <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "Action" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "Action" ? "active" : null} />
                  </span></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row, index) => (
                  <TableRow key={row._id}>
                    <TableCell>
                      <div className="toggleswich">
                        <input
                          type="checkbox"
                          onChange={() => {
                            UpdateStatus(row._id, !row.IsTwoStepVerification);
                          }}
                          className="checktogle"
                          checked={row.IsTwoStepVerification}
                        />
                        <b className="switch">
                          <span className="checkion"></span>
                          <span className="uncheckion"></span>
                        </b>
                        <b className="track"></b>
                      </div>
                    </TableCell>
                    <TableCell><div className="flexcontentpro">
                      <span class="carduser_pic mr-2">
                      <img
                        style={imgstyle}
                        src={
                          row.ProfileImage == ""
                            ? CommonConstants.Image_url + "default.png"
                            : CommonConstants.Image_url + row.ProfileImage
                        }
                      ></img>
                      </span>
                      <span>{row?.FullName}</span>
                      </div>
                    </TableCell>
                    <TableCell>{row?.Email}</TableCell>
                    <TableCell>{row?.ColdCallerTeamName}</TableCell>
                    <TableCell>
                      {row.IsTeamLead == true ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>{row.SlackAccount}</TableCell>
                    <TableCell>
                      {row.IsArchive ? (
                               <div>
                        <a className="btn-eyesicon">
                          <Button
                            // aria-controls="simple-menu"
                            // aria-haspopup="true"
                            // onClick={(e) => {
                            //   HandleClick(e, row._id);
                            // }}
                            
                            aria-controls={`simple-menu-${index}`}
                            aria-haspopup="true"
                            onClick={(e) => HandleClick(e, row._id)}
                          >
                            <i className="la la-ellipsis-h add-icon"></i>
                          </Button>
                          <Menu
                            className="droptable dopdownwe"
                            // id="simple-menu"
                            // anchorEl={AnchorEl}
                            // keepMounted
                            // open={Boolean(AnchorEl)}
                            // onClose={HandleClose}  
                            anchorEl={AnchorEl}
                            open={Boolean(AnchorEl && row._id === selectedRowId)}
                            onClose={HandleClose}
                            id={`simple-menu-${index}`}
                          >
                            <MenuItem onClick={DeleteUser}>
                              <i className="la flaticon-delete-1 mr-3"></i>{" "}
                              Delete
                            </MenuItem>
                            <MenuItem onClick={UnArchivedUser}>
                            <i className="la flaticon-download mr-3"></i>{" "}
                              Unarchive
                            </MenuItem>
                          </Menu>
                        </a>
                        </div>
                      ) : (
                        <div>
                          <a
                            onClick={() => {
                              EditPage(row._id);
                            }}
                            className="btn-eyesicon"
                            title="Edit"
                          >
                            <i className="la flaticon-edit-1 edit-icon"></i>
                          </a>
                          <a className="btn-eyesicon">
                            <Button
                              // aria-controls="simple-menu"
                              // aria-haspopup="true"
                              // onClick={(e) => {
                              //   HandleClickOne(e, row._id);
                              // }}
                              aria-controls={`simple-menu-${index}`}
                              aria-haspopup="true"
                              onClick={(e) => HandleClickOne(e, row._id)}
                            >
                              <i className="la la-ellipsis-h add-icon"></i>
                            </Button>
                            <Menu
                              className="droptable"
                              // id="simple-menu1"
                              // anchorEl={AnchorElf}
                              // keepMounted
                              // open={Boolean(AnchorElf)}
                              // onClose={HandleCloseOne}

                              anchorEl={AnchorElf}
                              open={Boolean(AnchorElf && row._id === selectedRowId)}
                              onClose={HandleCloseOne}
                              id={`simple-menu-${index}`}
                            >
                              <MenuItem onClick={ArchivedUser}>
                                <i className="la flaticon-download mr-3"></i>{" "}
                                Archive
                              </MenuItem>
                            </Menu>
                          </a>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
      <Pagenations
        Sflag={Sflag}
        CountPage={CountPage}
        HandleChangePage={HandleChangePage}
        Rlen={Rlen}
        Page={Page}
        RowsPerPage={RowsPerPage}
        Flen={Flen}
      />
    </div>
  );
}
