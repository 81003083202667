import React from "react";
import Axios from "axios";

import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

class CEditDefaultCountryBlacklistPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      CountryNameAvailable: null,
      ClientID: null,
      UserID: null,
      IsExist: false,
      OldCountryName:null,
      Role:null,
      UserIDby:null
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserIDby = Details.ChildUserID;
      this.state.UserID = Details.ParentUserID;
      this.state.Role = Details.Role;
    }

    this.DefaultCountryBlackListEdit();
  }

  // get id by data
  DefaultCountryBlackListEdit() {
    const id = this.props.location.state;
    this.setState({ id: id });

    var str_in = {
      id: id,
      Role:this.state.Role
    };

    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/defaultcountryblacklist/DefaultCountryBlacklistGetByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        this.setState({OldCountryName:res.data.Data[0].Country});
        document.getElementById("Country").value = res.data.Data[0].Country;
        document.getElementById("Abbreviation").value = res.data.Data[0].Abbreviation;
      document.getElementById("hideloding").style.display = "none";

      } else {
        toast.error(res.data.Message);
      }
    });
  }
    // form validation
   async FromValidation() {
      let FormIsValid = true;
      let Fields = this.state.Fields;
      var Country = document.getElementById("Country").value.trim();
      let IsExists = await this.CoutryNameCheckExist(Country);
      let Errors = {};
      if (Country == "") {
        FormIsValid = false;
        Errors["Country"] = "Please Enter Country";
      }

      if (IsExists == true) {
        FormIsValid = false;
      }
  
      if (this.state.CountryNameAvailable != null) {
        FormIsValid = false;
      }
      this.setState({ Errors: Errors });
      return FormIsValid;
    }

    // handle change
    HandleChange(field, e) {
      let Fields = this.state.Fields;
      Fields[field] = e.target.value;
      this.setState({ Fields });
      if (Fields.Name != "") {
        this.state.Errors.Country = null;
        // this.state.Errors.Abbreviation = null;
        this.state.CountryNameAvailable = null;
        this.CoutryNameCheckExist(e.target.value);
      } else {
        this.state.CountryNameAvailable = null;
        this.state.Errors.Country = null;
        // this.state.Errors.Abbreviation = null;
      }
    }

  


  // check exit name
 async CoutryNameCheckExist(Country) {
    var CheckExitsData = {
      ClientID: this.state.ClientID,
      UserID: this.state.UserID,
      Country: Country,
      Role:this.state.Role
    };

  let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/defaultcountryblacklist/DefaultCountryBlacklistExists",
      method: "POST",
      data: CheckExitsData,
    })
      if (resdata.data.Data.length > 0) {
        if(resdata.data.Data[0].Country.toLowerCase() != this.state.OldCountryName.toLowerCase()){
          this.setState({ CountryNameAvailable: resdata.data.Data[0].Country });
          this.setState({ IsExist: true });
        }
      }else{
        this.setState({ IsExist: false });
      }
    
  }



  // back button
  BackBtn() {
    history.push("/CDefaultCountryBlackList");
  }

  // save button
 async SaveBtn() {
  document.getElementById("hideloding").style.display = "block";
  document.getElementById("submit").disabled = true;

  this.state.Errors.Country = null;
  this.state.CountryNameAvailable = null;
  var Final_flag =  await this.FromValidation()
    if(Final_flag == true){
      var Country = document.getElementById("Country").value;
      var Abbreviation = document.getElementById("Abbreviation").value;
      var DefualtCoutryBlackListUpdate = {
        _id: this.state.id,
        UserID: this.state.UserID,
        Country: Country,
        Abbreviation: Abbreviation,
        LastUpdatedDate: new Date(),
        LastUpdatedBy: this.state.UserIDby,
        Role:this.state.Role
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/defaultcountryblacklist/DefaultCountryBlacklistUpdate",
        method: "POST",
        data: DefualtCoutryBlackListUpdate,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
       toast.success(<div className="toastsize" >Default Country Blacklist<br/>Default country blacklist updated successfully.</div>);
          history.push("/CDefaultCountryBlackList");
        } else {
          toast.error(res.data.Message);
          document.getElementById("submit").disabled = false;

        }
      });
    }else{
      document.getElementById("submit").disabled = false;
    }
    document.getElementById("hideloding").style.display = "none";

  }

  render() {
    return (
      <>
        <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad pl-0 mb-0">
                    Edit 
                  </h4>
                </div>
              </div>

            <div class="paddcols">
              <div className="row">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Country </label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter Country"
                        // onChange={(e)=>{this.CoutryNameCheckExist1(e.target.value)}}
                        onBlur={this.HandleChange.bind(this, "Country")}
                        // value={this.state.Fields["Country"]}
                        id="Country"
                        name="Country"
                        placeholder="Enter country"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Country"]}
                      </span>
                      {this.state.CountryNameAvailable && (
                        <span style={{ color: "red" }}>
                          Country name already exist.
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Abbreviation{" "}
                    </label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter  Abbreviation"
                        onChange={this.HandleChange.bind(this, "Abbreviation")}
                        value={this.state.Fields["Abbreviation"]}
                        id="Abbreviation"
                        name="Abbreviation"
                        placeholder="Enter abbreviation"
                        type="text"
                      />
                                     <span style={{ color: "red" }}>
                        {this.state.Errors["Abbreviation"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row my-3">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCEditDefaultCountryBlacklistPage = connect(
  mapState,
  actionCreators
)(CEditDefaultCountryBlacklistPage);
export { connectedCEditDefaultCountryBlacklistPage as CEditDefaultCountryBlacklistPage };
