import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from "../../_components/user/footer/footer";
import ClientlinkedInfollowup from '../../_components/user/ClientLinkedInFollowup/ClientLinkedInFollowup';
//import CollapsibleTable from '../_components/usertable';



class ClientLinkedInFollowUpPage extends React.Component {
   

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row"> 
                        <div className="col">
                            <h4 className="headertitlepad float-left pl-0 mb-0"> All LinkedIn Follow Ups </h4>
                        </div>
                        <div className="col padright pr-0"> 
                        </div>
                    </div>
 
 

                    <div class="paddcols pb-4 px-4">  
                        <div className="row">
                            <div className="col">
                                <ClientlinkedInfollowup /> 
                            </div>
                        </div>      
                    </div>

                </div> 
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedClientLinkedInFollowUpPage = connect(mapState, actionCreators)(ClientLinkedInFollowUpPage);
export { connectedClientLinkedInFollowUpPage as ClientLinkedInFollowUpPage };