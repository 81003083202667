import React from "react";
import { connect } from "react-redux";
import { CSVDownloader } from "react-papaparse";
import Axios from "axios";
import { toast } from "react-toastify";
import Box from "@material-ui/core/Box";
import "react-toastify/dist/ReactToastify.css";

import { userActions } from "../../_actions";
import Sidebar from "../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import Documents from "../../_components/clients/documents/DocumentsTable";
//import CollapsibleTable from '../_components/usertable';
import { history } from "../../_helpers";
import { GetUserDetails } from "../../_helpers/Utility";
import { CommonConstants } from "../../_constants/common.constants";

toast.configure();
class CDocumentsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ExportData: [],
      ClientName: "",
    };
    this.ExportCsv = this.ExportCsv.bind(this);
    this.AddPage = this.AddPage.bind(this);
  }
  AddPage() {
    history.push("/cadddocuments");
  }
  componentDidMount() {
    document.title = "Documents | SalesHive";

    var Details = GetUserDetails();
    const InputParameter = {
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/documents/DocumentsExport",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      this.setState({ ExportData: res.data.Data });
      this.setState({ ClientName: res.data.ClientName });
    });
  }

  ExportCsv() {
    toast.success(<div>Data exported successfully.</div>);
  }

  //Reload page while delete perform
  UpdateFromChild = (value) => {
    if (value == true) {
      this.componentDidMount();
    }
  };

  render() {
    return (
      <>
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitle float-left px-0">Documents</h4>
              </div>
              <div className="col">
                <div className="align-items-center d-flex justify-content-end">
                  <div className="pr-15 listing-li float-right pt-1">
                    <ul>
                      <li className="px-0">
                        <CSVDownloader
                          className="px-0"
                          data={this.state.ExportData}
                          filename={`${this.state.ClientName}-Document`}
                          bom={true}
                        >
                          <a
                            onClick={this.ExportCsv}
                            className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          >
                            <i className="la la-icon-font-size-13 la-download"></i>
                            <span>Export</span>
                          </a>
                        </CSVDownloader>
                      </li>
                      <li className="px-1">
                        <a
                          onClick={() => this.AddPage()}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i className="la la-icon-font-size-13 la-plus"></i>
                          <span>Add</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="paddcols px-4">
              <Box>
                <div className="row">
                  <div className="col">
                    <Documents updateFromChild={this.UpdateFromChild} />
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCDocumentsPage = connect(
  mapState,
  actionCreators
)(CDocumentsPage);
export { connectedCDocumentsPage as CDocumentsPage };
