import React, { useEffect } from "react";
const moment = require("moment");
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { CommonConstants } from "../../../../_constants/common.constants";
import { GetUserDetails } from "../../../../_helpers/Utility";
import { history } from "../../../../_helpers";
import loadingicon from "../../../../images/loading.gif";

toast.configure();


export default function AddAccountCategoriesTable(props) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("isselected");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [PUserID, SetPUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [AddArray, SetAddArray] = React.useState([]);
  const [CategoryName,SetCategoryName] = React.useState("");
  const [AccountID,SetAccountID] = React.useState("");
  const [SalesReplyAccountCategoryID,SetSalesReplyAccountCategoryID] = React.useState("");
  const [IsChecked,SetIsChecked] = React.useState(true);
  const [IsButtonDisabled,SetIsButtonDisabled]=React.useState(false);
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [sortedColumn, setSortedColumn] = React.useState("isselected");

  useEffect( () => {
    document.title = `Account | SalesHive`;
    let GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ChildUserID);
      SetPUserID(GetUserData.ParentUserID);
      SetRole(GetUserData.Role);
    }
    SetCategoryName(props.account.Name)
    SetAccountID(props.account.ID)
    SetSalesReplyAccountCategoryID(props.account.SalesReplyAccountCategoryIDs)
    AccountCategoryListGet(GetUserData.ClientID,GetUserData.ParentUserID,GetUserData.Role,props.account.SalesReplyAccountCategoryIDs);
  }, [Search, Page, RowsPerPage]);


  //get account category list
  const AccountCategoryListGet = (CID,UID,URole,SalesReplyAccountCategoryIDs) => {
    SetIsLoading(true)
    let InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      AccountCategoryID:SalesReplyAccountCategoryIDs
    };
    const AccountCategoriesList = Axios({
      url:
        CommonConstants.MOL_APIURL + "/account_categories/AccountsGetByCategory",
      method: "POST",
      data: InputParameter,
    });
    AccountCategoriesList.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);

      let temArrayA = []
      const Nearrray = Result.data.PageData.filter((item)=> item.AccountCategoryID === props.account.ID)
      Nearrray.map((item) => {
        temArrayA.push({ID: item._id, Value: props.account.ID });
      })
      SetAddArray(temArrayA)
      SetIsLoading(false)
    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      let SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if(NewPage==Page){
      SetPage(NewPage);
    }else{
      SetPage(NewPage);
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

    //Sorting method 
    const SortData = (Field) => {
      setSortedColumn(Field);
      SetIsLoading(true);
      const SearchedVal = document.getElementById("search").value?.trim();
      const isNewSortField = Field !== SortField;
      let SortBy = SortedBy === 1 ? -1 : 1;
    
      if (isNewSortField) {
        SetSortedBy(1);
        SortBy = 1;
      } else {
        SetSortedBy(SortBy);
      }
    
      SetSortField(Field);
      SetSflag(SearchedVal !== "");
    
      const InputParameter = {
        Page,
        RowsPerPage,
        search: SearchedVal,
        SerchBox: SearchedVal !== "",
        Archive: false,
        Sort: true,
        Field,
        SortBy,
        Type: "User",
        ClientID,
        UserID: PUserID,
        Role,
        AccountCategoryID: SalesReplyAccountCategoryID
      };
    
      Axios({
        url: `${CommonConstants.MOL_APIURL}/account_categories/AccountsGetByCategory`,
        method: "POST",
        data: InputParameter,
      }).then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetPage(1);
        SetIsLoading(false);
      });
    };
    

//Back Btn
  const BackBtn = () => {
    history.push("/CAccountCategory");
  };

//   // all check handler
// const CheckHandler = (e) => {
//   {
//     Rows?.map((val) => {
//       if (e.target.checked) {
//         let temArray = []
//         document.getElementById(val._id).checked = true;
//         // let Obj={ID:val._id,Value:AccountID}
//         temArray.push({ID:val._id,Value:AccountID});
//         SetAddArray(temArray);
//       } else if (!e.target.checked) {
//         document.getElementById(val._id).checked = false;
//         let temArray = []
//         let Obj={ID:val._id,Value:null}
//         temArray.push(Obj);
//         AddArray.splice(Obj, 1);
//       }
//     });
//   }
// };
// Check handler function
const CheckHandler = (e) => {
  if (e.target.checked) {
    let temArray = [];

    // Loop through each item in the Rows array
    Rows?.map((val) => {
      document.getElementById(val._id).checked = true;

      // Push an object containing the ID and a value (possibly AccountID) to temArray
      temArray.push({ ID: val._id, Value: AccountID });
    });

    // Set the temArray as the new array using SetAddArray function
    SetAddArray(temArray);
  } else if (!e.target.checked) {
    let temArray = [];

    // Loop through each item in the Rows array
    Rows?.map((val) => {
      document.getElementById(val._id).checked = false;


      // Remove the object from AddArray using splice
      temArray.push({ ID: val._id, Value: null });
  
    });
    SetAddArray(temArray);
  }
};


//Add list contact
// const CheckBoxAdd = (e, SalesReplyAccountID) => {
//   console.log(SalesReplyAccountID,"SalesReplyAccountID")
//   console.log(AccountID,"AccountID")
//   if (e.target.checked) {
//     let temArray = AddArray;
//     let Obj={ID:SalesReplyAccountID,Value:AccountID}
//     temArray.push(Obj);
//     SetAddArray(temArray);
//     SetIsChecked(true)
    
//   } else if (!e.target.checked) {
//     let temArray = AddArray;
//     let Obj={ID:SalesReplyAccountID,Value:null}
//     temArray.push(Obj);
//     SetAddArray(temArray);

//     SetIsChecked(false)
//   }
// };

// Checkbox handler function for individual rows
const CheckBoxAdd = (e, SalesReplyAccountID) => {

  // Clone the existing AddArray to a new array
  let temArray = [...AddArray];

  if (e.target.checked) {
    // Create an object with SalesReplyAccountID and AccountID and push it to the array
   
    let Obj = { ID: SalesReplyAccountID, Value: AccountID };
    temArray.push(Obj);

    // SetIsChecked to true, assuming this is used to manage a parent checkbox
    SetIsChecked(true);
  } else {
    // Find the index of the object with the specified ID in the temArray
    const index = temArray.findIndex(item => item.ID === SalesReplyAccountID);

    if (index !== -1) {
      // Replace the object at the found index with a new object having Value as null
      temArray[index] = { ID: SalesReplyAccountID, Value: null };
    }

    // SetIsChecked to false, assuming this is used to manage a parent checkbox
    SetIsChecked(false);
  }

  // Update the AddArray state with the new array
  SetAddArray(temArray);
};



 //Save Btn
 const SaveBtn=()=>{
  SetIsLoading(true)
 // SetIsButtonDisabled(true)
  let InputParameter = {
    ClientID: ClientID,
    UserID: PUserID,
    SalesReplyAccountIDs: AddArray,
    AccountCategoryID: AccountID,
    LastUpdatedBy: UserID,
    LastUpdatedDate: new Date(),
  };

  const AccountCategoriesList = Axios({
    url:
      CommonConstants.MOL_APIURL + "/account_categories/AccountsCategoryAdd",
    method: "POST",
    data: InputParameter,
  });
  AccountCategoriesList.then((Result) => {
    if (Result.data.StatusMessage == "SUCCESS") {
      toast.success(
        <div className="toastsize">
          Sales hive account
          <br />
          Selected sales hive account added successfully.
        </div>
      );
      // document.getElementById("hideloding").style.display = "block";
      AccountCategoryListGet(ClientID,PUserID,Role,SalesReplyAccountCategoryID)
    } else {
      toast.error(Result.data.Message);
    }
  SetIsLoading(false)

  });
  
 }

  return (
    <div>
      {
        IsLoading ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> :<></>
      }
       
      <div className="row borderbtnm px-4 d-flex align-items-center pt-2 pb-2">
                <div className="col px-3">
                  <h4 className="headertitlenop float-left px-1">
                  Accounts  {`( ${CategoryName} )`}
                  </h4>
                </div>


                <div class="col my-2 float-right">
                  <div class="pull-right px-0">
                    <a
                      id="submit"
                      onClick={()=>{SaveBtn()}}
                     // disabled={IsButtonDisabled}
                      class="btn btn-primary btn-lightgreen mr-1"
                    >
                      <i class="la la-save"></i> Save
                    </a>
                    <a
                      id="backtolist"
                      onClick={()=>{BackBtn()}}
                      class="btn btn-secondary"
                    >
                      <i class="la la-arrow-circle-left"></i> Back
                    </a>
                  </div>
                </div>

              </div>
      <div className="row padt-25 px-4">
        <div className="col-sm-12 col-md-6 px-3">
          <label className="textlabelte px-1">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              value={RowsPerPage}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm">
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              Type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"/>
          </label>
        </div>
      </div>
 
      <div className="table-bordered mx-4">
        <TableContainer component={Paper} className="">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell width={20}>
                <input name="select_all_contacts" type="checkbox" onChange={(e) =>
                        CheckHandler(e)
                      } />
                </TableCell>
                <TableCell onClick={() => { SortData("AccountName"); }}>
                  Account Name
                  <span className="shorting">
                         <ArrowUpward className={SortedBy === 1 && sortedColumn === "AccountName" ? "active" : null} />
                         <ArrowDownward className={SortedBy === -1 && sortedColumn === "AccountName" ? "active" : null} />
                    </span>
                </TableCell>
                <TableCell  onClick={() => {SortData("AccountNameLong");}}>
                  Account Name Long
                  <span className="shorting">
                         <ArrowUpward className={SortedBy === 1 && sortedColumn === "AccountNameLong" ? "active" : null} />
                         <ArrowDownward className={SortedBy === -1 && sortedColumn === "AccountNameLong" ? "active" : null} />
                    </span>
                </TableCell>
                <TableCell onClick={() => { SortData("Domain");}}>
                  Email Domains
                  <span className="shorting">
                         <ArrowUpward className={SortedBy === 1 && sortedColumn === "Domain" ? "active" : null} />
                         <ArrowDownward className={SortedBy === -1 && sortedColumn === "Domain" ? "active" : null} />
                    </span>
                </TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
            {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow key={row._id}>
                    {
                        row.AccountCategoryID == AccountID?
                    <TableCell>
                      
                      
                      <input
                      type="checkbox"
                      id={row._id}
                      onChange={(e) =>
                        CheckBoxAdd(e,row._id)
                      }
                      defaultChecked={IsChecked}
                      
                    /></TableCell> :
                    <TableCell>
                      
                    <input type="checkbox" id={row._id}
                      defaultChecked={!IsChecked}
                      onChange={(e) =>
                        CheckBoxAdd(e,row._id)
                      }/>
                      </TableCell>
                  }
                    <TableCell>{row.AccountName}</TableCell>
                    <TableCell>{row.AccountNameLong}</TableCell>
                    <TableCell>{row.Domain}</TableCell>
                    
                  </TableRow>
                ))
              )
            }
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
   
        <div className="row px-4">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
    </div>
  );
}
