import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import Axios from "axios";
import { history } from "../../../_helpers";
//import CallHistory from '../_components/coldcalling/callhistory';
//import CollapsibleTable from '../_components/usertable';

import ReactApexchart from "react-apexcharts";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class EditCustomMeetingPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      pager: {},
      pageOfItems: [],
      files: [],
      list: [],
      columns: [],
      reset: false,
      csvData: [],
      code: "",
      fields: {},
      errors: {},
      NameAvailable: null,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      Typelist: [],
      VariableTypeID: null,
      CustomVariable: {},
      IsExist: false,
    };
    this.GetDocmeeting();
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {}

  GetDocmeeting() {
    Axios({
      url: CommonConstants.MOL_APIURL + "/documents/GetMeetingDocuments",
      method: "POST",
    }).then((res) => {
      // console.log(res.data.DocummentsStatus,"type");
      this.setState({ Typelist: res.data.DocummentsStatus });
    });
  }

  FromValidation() {
    let formIsValid = true;
    if (Object.entries(this.state.fields).length > 0) {
      let fields = this.state.fields;
      let errors = {};

      if (!fields["Title"]) {
        formIsValid = false;
        errors["Title"] = "Please enter Name";
      }
      if (this.state.NameAvailable != null) {
        formIsValid = false;
      }
      this.setState({ errors: errors });
    }
    return formIsValid;
  }

  // handlechange
  handleChange(field, e) {
    // document.getElementById("blank_error").style.display = "none";
    // console.log("textbox");
    console.log(e.target.value);
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.Title != "") {
      this.state.errors.Title = null;
      this.state.NameAvailable = null;
      this.CheckExistDocumentsTitle(e.target.value);
    } else {
      this.state.NameAvailable = null;
      this.state.errors.Title = null;
    }
  }

  // check exit name
  CheckExistDocumentsTitle(Title) {
    var str_in = {
      id: this.state.ClientID,
      Title: Title,
    };
    console.log(str_in);

    Axios({
      url: CommonConstants.MOL_APIURL + "/documents/DocumentsStatusExists",
      method: "POST",
      data: str_in,
    }).then((resdata) => {
      console.log(resdata);
      if (resdata.data.length > 0) {
        this.setState({ NameAvailable: resdata.data[0].Title });
        console.log(this.state.NameAvailable);
        // this.setState({ IsExist: true });
      }
    });
  }

  backbtn() {
    history.push("/documents");
  }
  savebtn() {
    var name = document.getElementById("Title").value;
    var Urllink = document.getElementById("Url").value;
    var TypeID = document.getElementById("TypeID").value;
    // console.log(name);
    // console.log(Urllink);
    // console.log(TypeID);

    if (name == undefined || Urllink == undefined || TypeID == undefined) {
      // document.getElementById("blank_error").style.display = "block";
      console.log("Please enter name");
    } else {
      var data = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Title: name,
        Url: Urllink,
        TypeID: TypeID,
        IsDeleted: false,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
        LastUpdatedBy: null,
        LastUpdatedDate: null,
      };
      console.log(data);
      Axios({
        url: CommonConstants.MOL_APIURL + "/documents/AddDocument",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success("Add Documents successfully.", "Add Documents Status");
          history.push("/documents");
          console.log("successfull data status", res.data);
        } else {
          toast.error(res.data.Message);
        }
      });
    }
  }

  render() {
    return (
      <>
        <div className="adminmain"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row borderbtnm px-4">
                <div className="col">
                  <h4 className="headertitlepad float-left">Edit Custom Meeting Field </h4>
                </div>
              </div>

            <div class="paddcols">
              <div className="row py-4 px-3 borderbtnm mb-3">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Field Name </label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input" placeholder="Enter Field Name" type="text"
                      />
                    </div>
                  </div>
                </div>  
              </div>
            </div>

              <div class="row mb-3 px-3">
                <div class="col-lg-12 pull-left">
                  <a
                    id="submit"
                    onClick={this.savebtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </a>
                  <a
                    id="backtolist"
                    onClick={this.backbtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditCustomMeetingPage = connect(
  mapState,
  actionCreators
)(EditCustomMeetingPage);
export { connectedEditCustomMeetingPage as EditCustomMeetingPage };
