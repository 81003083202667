import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import CampaignsTab from '../../_components/user/Campaigns/CampaignsTabing';
//import CollapsibleTable from '../_components/usertable'; 
import Footer from '../../_components/user/footer/footer';


class CampaignsPage extends React.Component {
   

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitlebd float-left py-4 pl-0">Campaigns</h4> 
                        </div>
                        </div> 
                        <div className="row">
                            <div className="col"> 
                                <CampaignsTab /> 
                            </div>
                        </div>      
 
                    </div>  
                </div>
                
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCampaignsPage = connect(mapState, actionCreators)(CampaignsPage);
export { connectedCampaignsPage as CampaignsPage };