import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

toast.configure();

class EditGlobalBounceListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Code: "",
      Fields: {},
      Errors: {},
      EmailAvailable: null,
      ClientID: null,
      UserID: null,
      CUserID: null,
      IsExist: false,
      OldEmail: null,
      IsButtonDisabled: false,
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
  }
  componentDidMount() {
    document.title = "Edit Global Bounce List | SalesHive";

    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
    this.EditGlobalBounce();
  }

  // form validation
  async HandleChangeBlur() {
    let Errors = { ...this.state.Errors };
    var Email = document.getElementById("Email").value.trim();

    let IsExist = await this.CheckExistGolbalBounce(Email);
    if (IsExist == "InvalidEmail") {
      Errors["Email"] = "invalid email.";
    }
    if (IsExist == "ExistEmail") {
      Errors["Email"] = "Email Already Exist";
    }

    if (IsExist == "BlankData" && Email == "") {
      Errors["Email"] = "Please enter email";
    }

    if (IsExist == "AllDone") {
      Errors["Email"] = null;
    }

    this.setState({ Errors: Errors });
  }

  //form validation
  async FromValidation() {
    let formIsValid = true;
    let errors = {};

    var FieldName = document.getElementById("Email").value.trim();
    var IsExist = await this.CheckExistGolbalBounce(FieldName);
    // var IsValid = await this.CheckEmailValidation();
    if (IsExist == "InvalidEmail") {
      formIsValid = false;
      errors["Email"] = "invalid email.";
    }
    if (IsExist == "ExistEmail") {
      formIsValid = false;
      errors["Email"] = "Email Already Exist";
    }

    if (IsExist == "BlankData" && FieldName == "") {
      formIsValid = false;
      errors["Email"] = "Please enter email";
    }

    this.setState({ Errors: errors });

    return formIsValid;
  }

  //change to check error
  async HandleChange(Field, e) {
    let Fields = this.state.Fields;
    Fields[Field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Email != "" && Fields.Email.indexOf(" ") != 0) {
      this.state.Errors.Email = null;
      this.state.EmailAvailable = null;
      await this.CheckExistGolbalBounce(e.target.value);
      await this.CheckEmailValidation();
    } else {
      this.state.EmailAvailable = null;
      this.state.Errors.Email = null;
    }
  }

  //check exist name
  async CheckExistGolbalBounce(Email) {
    let regexp =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let Errors = {};
    if (Email != "") {
      if (!regexp.test(Email)) {
        //Errors["Email"] = "invalid email";
        // this.setState({ Errors: Errors });
        // return true;
        return "InvalidEmail";
      } else {
        var InputParameter = {
          UserID: this.state.UserID,
          Email: Email,
        };
        var resdata = await Axios({
          url: CommonConstants.MOL_APIURL + "/global_bounce/GlobalBounceExists",
          method: "POST",
          data: InputParameter,
        });
        if (resdata.data.StatusMessage == "SUCCESS") {
          if (resdata.data.Data.length > 0) {
            if (this.state.OldEmail == Email) {
              this.setState({ IsExist: false });
              return "AllDone";
            } else {
              this.setState({ EmailAvailable: resdata.data.Data[0]?.Email });
              return "ExistEmail";
            }
          } else {
            return "AllDone";
          }
        }
      }
    } else {
      return "BlankData";
    }
  }

  //update account category
  EditGlobalBounce() {
    const id = this.props.location.state;
    this.setState({ id: id });
    var InputParameter = {
      ID: id,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/global_bounce/GlobalBounceGetByID",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById("Email").value = res.data.Data[0].Email;
        this.setState({ OldEmail: res.data.Data[0].Email });
      } else {
        toast.error(res.data?.Message);
      }
    });
  }

  //back to account category
  BackBtn() {
    history.push("/globalbouncelist");
  }

  //update account category
  async SaveBtn(e) {
    // this.state.Errors.Email = null;
    // this.state.EmailAvailable = null;
    // e.preventDefault();
    // e.currentTarget.disabled = true;
    e.preventDefault();
    this.setState({ IsButtonDisabled: true });
    var FinalValidation = await this.FromValidation();
    var Details = GetUserDetails();
    if (FinalValidation == true) {
      var FieldName = document.getElementById("Email").value.trim();
      var data = {
        ID: this.state.id,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        Email: FieldName,
        OldEmail: this.state.OldEmail,
        LastUpdatedBy: this.state.CUserID,
        LastUpdatedDate: new Date(),
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/global_bounce/GlobalBounceUpdate",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Global Bounce
              <br />
              Global Bounce updated successfully.
            </div>
          );
          history.push("/globalbouncelist");
        } else {
          toast.error(res.data.Message);
          this.setState({ IsButtonDisabled: false });
        }
      });
    } else {
      this.setState({ IsButtonDisabled: false });
      //document.getElementById("submit").disabled = false;
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col ">
                  <h4 className="headertitlepad float-left">
                    Edit Global Bounce List
                  </h4>
                </div>
              </div>

            <div className="paddcols">
              <div className="row pt-4">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Email</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter email"
                        onBlur={this.HandleChangeBlur}
                        value={this.state.Fields["Email"]}
                        id="Email"
                        name="Email"
                        placeholder="Enter Email"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Email"]}
                      </span>
                      {/* {this.state.EmailAvailable && (
                        <span style={{ color: "red" }}>
                          Email already exist.
                        </span>
                      )} */}
                    </div>
                  </div>
                </div> 
              </div>
            </div>

            <div className="row border-top pt-4 my-4">
              <div className="col-lg-12 pull-left">
                <button
                  id="submit"
                  onClick={this.SaveBtn}
                  disabled={this.state.IsButtonDisabled}
                  className="btn btn-primary btn-lightgreen mr-1 ml-0"
                >
                  <i className="la la-save"></i> Save
                </button>
                <button
                  id="backtoList"
                  onClick={this.BackBtn}
                  className="btn btn-secondary"
                >
                  <i className="la la-arrow-circle-left"></i> Back
                </button>
              </div>
            </div>

          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditGlobalBounceListPage = connect(
  mapState,
  actionCreators
)(EditGlobalBounceListPage);
export { connectedEditGlobalBounceListPage as EditGlobalBounceListPage };
