import React from 'react';
import { connect } from 'react-redux';

import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from "../../_components/user/footer/footer";
import CustomMeetingTable from '../../_components/user/CustomMeeting/CustomMeeting';
import CollapsibleTable from '../../_components/usertable';
import { history } from '../../_helpers';
import { GetUserDetails } from "../../_helpers/Utility";
import { GetClientDetails } from "../../_helpers/Utility";



class CustomMeetingPage extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          CName:"",
        };
        this.AddBtn = this.AddBtn.bind(this);
      }
    
      componentDidMount() {
        var Details = GetUserDetails();
        if (Details != null) {
          this.state.ClientID = Details.ClientID;
          this.state.UserID = Details.ChildUserID;
        }
        
       GetClientDetails(Details.ClientID).then((result)=>{
        this.setState({CName:result[0].Name})
       })
        
      }

      AddBtn(){
        history.push("/addcustommeeting")
      }



    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitle float-left pl-0">Custom Meeting Field</h4> 
                        </div> 
                        <div className="col">
                            <div className="listing-li float-right padb-15">
                                <ul>
                                    <li>
                                    <a href="" className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                        <i class="la la-icon-font-size-13 la-download"></i>
                                        <span>Export</span>
                                    </a>
                                    </li>
                                    <li>
                                    <a onClick={()=>{this.AddBtn()}}className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                        <i class="la la-icon-font-size-13 la-plus"></i>
                                        <span>Add</span>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="paddcols"> 
                        <div className="row px-3 mb-3">
                            <div className="col"> 
                                    <CustomMeetingTable /> 
                            </div>
                        </div>      

                        {/* <div className="row">
                            <div className="col dataTables_info">
                            <p>Showing 1 to 71 of 71 entries</p>
                            </div>
                        </div> */}
                    </div>

                </div>

                </div>
                
        <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCustomMeetingPage = connect(mapState, actionCreators)(CustomMeetingPage);
export { connectedCustomMeetingPage as CustomMeetingPage };