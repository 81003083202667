import React from "react";
import { connect } from "react-redux";
import logo from "../images/SalesHive.png";
import logobgs from "../images/Loginvector.svg";
import { history } from "../_helpers/history";
import "react-toastify/dist/ReactToastify.css";
import loadingicon from "../images/loading.gif";
import OtpInput from 'react-otp-input';
import Axios from "axios";
import { CommonConstants } from "../_constants/common.constants";

const style={
  width:"40px",
  height:"60px",
  padding:"10px",
  "textAlign":"center",
  margin:"5px",
  "input::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: "0"
  },
  "input[type=number]": { MozAppearance: "textfield" }
}

class TwoStepVerificationPage extends React.Component {
  constructor(props) {
    if(props.location.state?.ID === undefined || props.location.state?.ID === null || props.location.state?.ID == ""){
      history.push('/login')
    }
    
    super(props);
    this.state = {
      ID:this.props.location.state?.ID,
      otp:"",
      StorageObject:this.props.location.state?.StorageObject,
      Redirection:this.props.location.state?.Redirection,
      ErrorMessage:"",
      DisplayError:false,
      value: "",
      
    }; 
    this.VerifyOTP = this.VerifyOTP.bind(this);

  }


  handleChange = (value1, event) => {
    this.setState({ [value1]: event.target.value.trim() });
  }

  inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      

      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  //Verify OTP
  VerifyOTP = async(event) => {
    event.preventDefault();
    let {otp,ID} = this.state;
    
    //  if(otp.length < 6){
    //   this.setState({DisplayError:true,ErrorMessage:"Please enter OTP to login"})
    //  }else if(this.state.ID !== otp){
    //   this.setState({DisplayError:true,ErrorMessage:"OTP is invalid!"})
    //  }else if(this.state.OTP === otp.toString()){
    //   this.setState({DisplayError:false,ErrorMessage:""})
    //   localStorage.setItem("LoginData", JSON.stringify(this.state.StorageObject));
    //   // Store the current date as the login timestamp
    //   const loginDate = new Date().toISOString();
    //   localStorage.setItem('loginTimestamp', loginDate);
    //   history.push(this.state.Redirection);
    //  }
 if(otp.length < 6){
      this.setState({DisplayError:true,ErrorMessage:"Please enter OTP to login"})
     }else{
      const InputParameter = {
        UserID:ID,
        OTP:otp,
        UserType:this.state.StorageObject?.Role
      };
    
      try {
        const Result = await Axios.post(CommonConstants.MOL_APIURL + "/login/VerifyOTP", InputParameter);
        if (Result.data.StatusMessage === "SUCCESS") {
              if(Result.data.IsCorrect){
                this.setState({DisplayError:false,ErrorMessage:""})
                  localStorage.setItem("LoginData", JSON.stringify(this.state.StorageObject));
                  // Store the current date as the login timestamp
                  const loginDate = new Date().toISOString();
                  localStorage.setItem('loginTimestamp', loginDate);
                  history.push(this.state.Redirection);
              }else{
                this.setState({DisplayError:true,ErrorMessage:"OTP is invalid!"})
              }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    
  }

  //Back to Login
  Back = () =>{
    history.push('/login');
  }
 


  handleOtpChange = (otp) => {
      this.setState({ otp });
    
  };
  

  render() {
    document.title = "Two-Factor-Authentication | SalesHive"
    
    return (
      <div className="login-main">
        <div
          id="hideloding"
          className="loding-display"
          style={{ display: "none" }}
        >
          <img src={loadingicon} />
        </div>
        <div className="row h-100">
          <div className="col-xs-12 col-md-7 logo-bgbod padding-btn">
            <img src={logobgs} className="login-vector" />

            <div className="m-login__logo">
              <a href="/">
                <img src={logo} className="main-logo" />
              </a>
            </div>
          </div>
          <div className="col-xs-12 col-md-5 d-flex">
            {/* Login Form in sign in page*/}
            <div className="full-hegiht">
              <div id="login" className="centerheigh ">
               
                <div className="heading">
                  <h5>Two-Factor-Authentication.</h5>
                </div>
                {
                  this.state.DisplayError ? <div style={{border:"1px solid red",color:"red",padding:"10px"}}><h5>{this.state.ErrorMessage}</h5></div>:null
                }
                <div>
                  <p>Please check your <b>e-mail</b> account for the verification code which we have just sent you and enter that <b>code
                    </b>  on the box below to login.</p>
                </div>
               
                <div id="forgot_message" className="text-success"></div>
            
                <form className="form">
                <label className="input-label" >
                      OTP
                    </label>
                <div className="otpContainer" style={{display:"flex",direction:'row'}}>
                      <OtpInput
                            value={this.state.otp}
                            onChange={this.handleOtpChange}
                            numInputs={6}
                            renderSeparator={<span> </span>}
                            renderInput={(props) => <input {...props} style={style} />}
                          />
                 </div>
                 <button className="input-submit" onClick={this.VerifyOTP}>Verify</button>
                 <button className="input-submit-back" onClick={this.Back}>Back</button>

                </form>
              </div>

              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  return state;
}

const actionCreators = {};

const connectedTwoStepVerificationPage = connect(mapState, actionCreators)(TwoStepVerificationPage);
export { connectedTwoStepVerificationPage  as TwoStepVerificationPage };
