import React, { useEffect } from "react";
import Axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';


import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import loadingicon from "../../../images/loading.gif";
//import Userimg from '../images/avatar/1.jpg';

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});


export default function CollapsibleTable() {
  const [open, setOpen] = React.useState(false);
  const [data, setdata] = React.useState([]);
  const [flen, setflen] = React.useState(0);
  const [sflag, setsflag] = React.useState(false);
  const [rlen, setrlen] = React.useState(0);
  const [countpage, setCountPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [search, setSearch] = React.useState("");
  const [aID, setAID] = React.useState("");
  const [checkS, setcheckS] = React.useState(false);
  const [orignaldata, setorignaldata] = React.useState([]);
  const [sortField, setsortField] = React.useState("Month");
  const [sortedColumn, setSortedColumn] = React.useState("Month");
  const [sortedBy, setSortedBy] = React.useState(1);
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [Role, setRole] = React.useState("");
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [CUserID,SetCUserID] = React.useState(0)
  useEffect(() => {
    var getaccountcatelist = GetUserDetails();
    if (getaccountcatelist != null) {
      setClientID(getaccountcatelist.ClientID);
      setUserID(getaccountcatelist.ParentUserID);
      SetCUserID(getaccountcatelist.ChildUserID);
      setRole(getaccountcatelist.Role);
    }
    GetMeetingByMonthList(getaccountcatelist.ClientID,getaccountcatelist.ParentUserID,getaccountcatelist.Role);
  }, [search, page, rowsPerPage]);

  //get seniority meeting
  const GetMeetingByMonthList = (CID,UID,URole) => {
    SetIsLoading(true)
    var str_in = {
      page: page,
      rowsPerPage: rowsPerPage,
      sort: true,
      field: sortField,
      sortby: sortedBy,
      search: search,
      type: "User",
      ClientID: CID,
      Role:URole
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetMeetingByMonths",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      setdata(result.data.pagedata);
      setRows(result.data.pagedata);
      setrlen(result.data.totalcount);
      setflen(result.data.totalcount);
      setCountPage(result.data.pagecount);
      SetIsLoading(false)
      //document.getElementById("hideloding").style.display = "none";
    });
  };

  const chengeRowSelected = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
    //document.getElementById("hideloding").style.display = "block";
  };

  //search
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      if (SearchedVal == search) {
        setSearch(SearchedVal);
        setPage(1);
      } else {
        setSearch(SearchedVal);
        setPage(1);
        // document.getElementById("hideloding").style.display = "block";
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    if(newPage==page){
      setPage(newPage);
    }else{
      setPage(newPage);
     // document.getElementById("hideloding").style.display = "block";
    }
  };

  const toggleSort = (column) => {
    SetIsLoading(true);
    if (sortedColumn === column) {
      setSortedBy(sortedBy === 1 ? -1 : 1);
      SetIsLoading(false);
    } else {
      setSortedColumn(column);
      setSortedBy(1);
      SetIsLoading(false);
    }
  };

  const sortRows = (column) => {
    const sortedRows = [...rows];

    sortedRows.sort((a, b) => {
      if (a[column] < b[column]) return -1;
      if (a[column] > b[column]) return 1;
      return 0;
    });

    if (sortedColumn === column && sortedBy === -1) {
      return sortedRows.reverse();
    }

    return sortedRows;
  };


  return (
    <div>
  {
      IsLoading ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : <></>
    }
      <div className="row padt-25 border-top">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={chengeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={rowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
        <label className="textlabelte">
            Search:{" "}
            <input
              Type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"/>
          </label>
        </div>
      </div>
      <div  className="table-bordered">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
            <TableCell onClick={() => toggleSort("Month")}>
                Month
                <span className="shorting">
                  <ArrowUpward className={sortedBy === 1 && sortedColumn === "Month" ? "active" : null} />
                  <ArrowDownward className={sortedBy === -1 && sortedColumn === "Month" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell >
              Total Meeting
              </TableCell>
              <TableCell >
                Meeting %

              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                sortRows(sortedColumn).map((row) => (
                  <TableRow key={row?._id}>
                    <TableCell>{row?.month}</TableCell>
                    <TableCell>{row?.count}</TableCell>
                    <TableCell>{Math.round(row?.Percentage)}%</TableCell>
                  </TableRow>
                ))
              )}
          </TableBody>
        </Table>
      </TableContainer></div>
      {sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={countpage}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {rlen == 0 ? 0 : (page - 1) * rowsPerPage + 1} to{" "}
              {page * rowsPerPage > rlen ? rlen : page * rowsPerPage} of {rlen}{" "}
              entries (filtered from {flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {rlen == 0 ? 0 : (page - 1) * rowsPerPage + 1} to{" "}
              {page * rowsPerPage > rlen ? rlen : page * rowsPerPage} of {rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={countpage}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
  );
}
