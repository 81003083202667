import React, { useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";
const moment = require("moment");
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";

import { CommonConstants } from "../../../_constants/common.constants";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export default function AccountAllTable() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Name");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [AccountTypeD1, SetAccountTypeD1] = React.useState([]);
  const [HideInactiveAccount, SetHideInactiveAccount] = React.useState(true);
  const [ExportData, SetExportData] = React.useState([]);
  const [CountData, SetCountData] = React.useState([]);
  const [CUserID,SetCUserID] = React.useState(0)
  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    ClientDomainGet(Details?.ParentUserID);
  }, [Search, Page, RowsPerPage]);

  // Domain account get
  const ClientDomainGet = (Uid) => {
    document.getElementById("hideloding").style.display = "block";

    var EmailAccountData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      UserID: Uid,
      // HideInactiveAccountParam:HideInactiveAccount,
    };
    
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/emailaccount/DomainsAccountGet",
      method: "POST",
      data: EmailAccountData,
    });
    rows1.then((result) => {
      
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      document.getElementById("hideloding").style.display = "none";
    });
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      document.getElementById("hideloding").style.display = "block";
    }
  };

  // hide inactive accounts
  const HideInActiveAccount = (e) => {
    var HideInActiveP = document.getElementById(
      "include_inactive_account"
    ).checked;
    
    var EmailAccountData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      UserID: UserID,
      HideInactiveAccountParam: HideInActiveP,
    };
    
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/emailaccount/DomainsAccountGet",
      method: "POST",
      data: EmailAccountData,
    });
    rows1.then((result) => {
      
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetCountData(result.data.TotalCountData);
    });
  };

  //get sort field data
  const SortData = (Field) => {
    document.getElementById("hideloding").style.display = "block";

    var SearchedVal = document.getElementById("search").value;

    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var EmailAccountData = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
        UserID: UserID,
      };
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/emailaccount/DomainsAccountGet",
        method: "POST",
        data: EmailAccountData,
      });
      rows1.then((result) => {
        
        SetData(result.data.PageData);
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
        document.getElementById("hideloding").style.display = "none";
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var EmailAccountData = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
        UserID: UserID,
      };
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/emailaccount/DomainsAccountGet",
        method: "POST",
        data: EmailAccountData,
      });
      rows1.then((result) => {
        
        SetData(result.data.PageData);
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
        document.getElementById("hideloding").style.display = "none";
      });
    }
  };
  return (
    <>
      <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
      <div className="row pb-3 px-4 borderbtnm">
        <div className="col"></div>
        <div className="col padright pr-4">
          <div className="float-right">
            <label class="check_bx floatrighter mr-3">
              <input id="include_archive" type="checkbox" />
              <span className="checkmark"></span>
              Include Old Clients
            </label>
            <label class="check_bx floatrighter mr-3">
              <input
                id="include_inactive_account"
                defaultChecked={HideInactiveAccount}
                type="checkbox"
              />
              <span className="checkmark"></span>
              Hide Inactive Accounts
            </label>
            <button
              type="submit"
              onClick={(e) => HideInActiveAccount(e)}
              class="btn btn-primary btnColor m-btn float-left"
            >
              <span>
                <i class="la flaticon-search"></i>
                <span>Search</span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      
      <div className="table-bordered">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Domain");
                  }}
                >
                  Domain
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("LastWeekEmailsSent");
                  }}
                >
                  Last Week Emails Sent
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("AvgEmailsPerDay");
                  }}
                >
                  Avg Emails Per Day
                </a>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Rows.map((row) => (
              <TableRow>
                <TableCell>{row?.Domain}</TableCell>
                <TableCell>{row?.LastWeekEmailsSent == null ? 0 : 0}</TableCell>
                <TableCell>{row?.AvgEmailsPerDay == null ? 0 : 0}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
