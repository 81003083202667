import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import {
    IconButton,
    Collapse,
    Box,
    Paper,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    
  } from '@material-ui/core';
  import moment from 'moment';
  import Pagination from "@material-ui/lab/Pagination";
  import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

const SortingIcons = ({SortedBy,sortedColumn,ColumnName}) => {
  return (
    <span className="shorting">
           <ArrowUpward className={SortedBy === 1 && sortedColumn === ColumnName? "active": null}/>
           <ArrowDownward className={SortedBy === -1 && sortedColumn === ColumnName? "active": null}/>
    </span>
  )
}

function Campaigns({UserID,ClientID,Role,AccountID,Domain}) {
    const [Data, setData] = useState([]);
    const [Rows, setRows] = useState([]);
    const [RLen, setRLen] = useState(0);
    const [FLen, setFLen] = useState(0);
    const [CountPage, setCountPage] = useState(0);
    const [Page, setPage] = useState(1);
    const [RowsPerPage, setRowsPerPage] = useState(100);
    const [Search, setSearch] = useState("");
    const [SortField, setSortField] = useState("CreatedDate");
    const [SortedBy, setSortedBy] = useState(-1);
    const [Sflag, setSflag] = useState(false);
    const [sortedColumn, setSortedColumn] = React.useState("CreatedDate");

    useEffect(() => {
        CampaignsAccountGet(Domain,Search, Page, RowsPerPage);
      }, [Search, Page, RowsPerPage, SortField, SortedBy,Domain,AccountID,ClientID]);

      // Cmapaign Details
 const CampaignsAccountGet = (DomainCampaigns, Search, Page, RowPage) => {
    if(Domain?.trim() != "" && AccountID?.trim() != "" && ClientID?.trim() != "" && UserID?.trim() !== ""){
    var CHListData = {
      Page: parseInt(Page) !== null ? parseInt(Page) : 1,
      UserID: UserID,
      ClientID: ClientID,
      RowsPerPage: parseInt(RowPage),
      Domain: DomainCampaigns,
      Sort: true,
      Field: SortField,
      Role: Role,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      SalesReplyAccountID:AccountID
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/CampaignAccountHistoryGet",
      method: "POST",
      data: CHListData,
    }).then((CHListResult) => {
      if(CHListResult.data.StatusMessage === "SUCCESS"){
        setData(CHListResult.data?.PageData);
        setRows(CHListResult.data?.PageData);
        setRLen(CHListResult.data?.TotalCount);
        setFLen(CHListResult.data?.TotalCount);
        setCountPage(CHListResult.data?.PageCount);
      }else{
        toast.error(CHListResult.data.Message)
      }
       
    });
    }
  }

  // search for record
 const RequestSearchCampaigns = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("CampaignsSearch").value;
      setSearch(SearchedVal)
    }
  }

  // change display rows
 const ChangeRowSelectedCampaigns = (Event) => {
    setRowsPerPage(Event.target.value)
    setPage(1)
  }

  // change page
const  HandleChangePage = (NewPage) => {
    if (NewPage != Page) {
        setPage(NewPage)
    }
  }


  //Sorting Method
  const SortingMethod = (FieldName) => {
    setSortField(FieldName)
    setSortedColumn(FieldName);
    const isNewSortField = FieldName !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;

    if (isNewSortField) {
      setSortedBy(1);
      SortBy = 1;
    } else {
      setSortedBy(SortBy);
    }

    setSortField(FieldName);
  }



  // add Campaigns page
 const CampaignsAdd = () => {
    history.push("/addcampaigns");
  };

  //View Campaign Page
const ViewCampaignPage = (id) => {
    history.push({
        pathname: "/editcampaigns",
        state: {
          data: id,
          PageName: 'AccountContact',
          AccountData: AccountID
        }
      });
}

  return (
    <>
        <div className="row pt-3 pb-0">
              <div className="col">
                <h3 className=" float-left pb-4 addpotential_header">
                  Campaigns
                </h3>
              </div>
              <div className="col text-right">
                <button
                  onClick={ CampaignsAdd}
                  className="btn btngroup m-btn m-btn--custom"
                >
                  <i className="la la-plus la-icon-font-size-13"></i>
                  <span className="Mobile_button">Add</span>
                </button>
              </div>
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={ChangeRowSelectedCampaigns}
                    value={RowsPerPage}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value} key={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="CampaignsSearch"
                    onKeyPress={(event) =>
                        RequestSearchCampaigns(event)
                    }
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell onClick={()=>{SortingMethod("Name")}}>Name <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"Name"} /></TableCell>
                      <TableCell onClick={()=>{SortingMethod("CreatedDate")}}>Created Date <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"CreatedDate"} /></TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Rows?.length === 0 ? (
                      <p className="text-center">No data available in table</p>
                    ) : (
                      Rows?.map((row) => (
                        <TableRow key={row?._id}>
                          <TableCell><a onClick={()=>{ViewCampaignPage(row._id);}}><u>{row?.Name}</u></a></TableCell>
                          <TableCell>
                            {moment(
                              new Date(row?.CreatedDate).toDateString()
                            ).format("MM/DD/YYYY")}
                          </TableCell>
                          <TableCell>
                            <a
                              onClick={() => {
                                ViewCampaignPage(row._id);
                              }}
                            >
                              <i className="la flaticon-edit-1 edit-icon"></i>
                            </a>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            
              <div className="row">
                <div className="col dataTables_info">
                  <p>
                    Showing{" "}
                    {RLen == 0
                      ? 0
                      : (Page - 1) * RowsPerPage +
                        1}{" "}
                    to{" "}
                    {Page * RowsPerPage >
                    RLen
                      ? RLen
                      : Page * RowsPerPage}{" "}
                    of {RLen} entries
                  </p>
                </div>
                <div className="col pageright">
                  <Pagination
                    component="div"
                    count={CountPage}
                    page={Page}
                    onChange={HandleChangePage}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
           
    </>
  )
}

export default Campaigns