import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { GetUserDetails } from "../../../_helpers/Utility";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import SidebarClient from "../../../_components/clients/nav-sidebar/Sidebar";

const MainSidebar = () => {
  const [role, setRole] = useState("");

  useEffect(() => {
    const fetchUserDetails = async () => {
      const userDetails = await GetUserDetails();
      if (userDetails != null) {
        setRole(userDetails.Role);
      }
    };

    fetchUserDetails();
  }, []); // Empty dependency array ensures this effect runs only once after mount

  return (
    <>
      {role === "Client" ? (
        <SidebarClient className="" /> 
  
      ) : (
        <Sidebar className="" />
     
      )}
    </>
  );
};

export default MainSidebar;
