import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import CallHistory from '../../_components/user/ColdCalling/CallHistory';
import { getFirstDayOfQuarter } from "../../_helpers/Utility";
import $ from "jquery";

class CallHistoryPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            FromDate:  moment(getFirstDayOfQuarter().toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
            })).format("YYYY-MM-DD"),
            ToDate: moment().format("YYYY-MM-DD")
        };
        
        this.SaveBtn = this.SaveBtn.bind(this);
        this.daterefect = React.createRef();
        this.daterefect2 = React.createRef();
    }
    
    componentDidMount() {
        this.initializeDatePickers();
        this.setInitialDates();
    }

    componentDidUpdate(prevProps) {
        // Check if the relevant props have changed and act accordingly
        if (this.props.someProp !== prevProps.someProp) {
            // Perform any actions needed when props change
            this.handleNewProps(this.props.someProp);
        }
    }

    initializeDatePickers() {
        const $datepickerElement = $(this.daterefect.current); 
        $datepickerElement.datetimepicker({
            format: 'mm/dd/yyyy',
            autoclose: true,
            container: '#app',
            todayBtn: true,
            todayHighlight: true,
            minView: '2',
        });

        const $datepickerElement2 = $(this.daterefect2.current); 
        $datepickerElement2.datetimepicker({
            format: 'mm/dd/yyyy',
            autoclose: true,
            container: '#app',
            todayBtn: true,
            todayHighlight: true,
            minView: '2',
        });
    }

    setInitialDates() {
        const fromdate = getFirstDayOfQuarter().toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
        const todate = new Date().toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });

        document.getElementById("from-date").value = fromdate;
        document.getElementById("to-date").value = todate;

        this.setState({
            FromDate: moment(fromdate, "MM-DD-YYYY").format("YYYY-MM-DD"),
            ToDate: moment(todate, "MM-DD-YYYY").format("YYYY-MM-DD")
        });
    }

    SaveBtn() {
        const FromDate = moment(document.getElementById('from-date').value, "MM/DD/YYYY").format("YYYY-MM-DD");
        const ToDate = moment(document.getElementById('to-date').value, "MM/DD/YYYY").format("YYYY-MM-DD");
        this.setState({ FromDate, ToDate });
    }

    render() {
        return (
            <>
                <div className="adminmain mheight">
                    <Sidebar className="" />
                    <div className="bodyhome">
                        <div className="row">
                            <div className="col">
                                <h4 className="headertitlebd float-left py-4 mt-0">Call History</h4>
                            </div>
                            <div className="col pt-3">
                                <div className="btn-group datepicker-hed">
                                    <input id="fromtab" name="fromtab" type="hidden" value="" />
                                    <div className="col-lg-6 timedatepikker date-input">
                                        <input className="form-control datepicker" id="from-date" name="from-date" type="text" ref={this.daterefect} autoComplete="off" />
                                    </div>
                                    <div className="col-lg-6 timedatepikker date-input">
                                        <input className="form-control datepicker" id="to-date" name="to-date" type="text" ref={this.daterefect2} autoComplete="off" />
                                    </div>
                                    <button id="aCompanyState" className="btn btn-primary btnColor btn-radius" onClick={this.SaveBtn} autoComplete="off">
                                        <i className="la flaticon-diagram"></i>
                                        <span>Analyze</span>
                                    </button>
                                </div>
                            </div>
                        </div> 
                            <div className="row pt-2">
                                <div className="col"> 
                                    <CallHistory ToDate={this.state.ToDate} FromDate={this.state.FromDate} /> 
                                </div> 
                            </div>    
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCallHistoryPage = connect(mapState, actionCreators)(CallHistoryPage);
export { connectedCallHistoryPage as CallHistoryPage };
