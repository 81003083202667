import React, { useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

export default function EngagementStatusTable() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("OrderBy");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [CUserID,SetCUserID] = React.useState(0)
  const [sortedColumn, setSortedColumn] = React.useState(null);

  // start Document ready Fuction
  useEffect(() => {
    document.title = `Engagement Status | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

    GetEngagementStatus(Details.ClientID, Details.ParentUserID, Details.Role);
  }, [Search, Page, RowsPerPage]);

  //Get engagement status
  const GetEngagementStatus = (CID, UID, URole) => {
    SetIsLoading(true)
    var FieldParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      Sortby: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const Rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/potentialmeetingstatus/PotentialMeetingStatusGet",
      method: "POST",
      data: FieldParameter,
    });
    Rows1.then((Result) => {
      SetData(Result.data.PageData);
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)
    });
  };

  //edit page
  const EditEngagementStatus = (id) => {
    history.push("/ceditpotentialmeetingstatus", id);
  };

  //delete engagagement status
  const DeleteBtn = async(id,Name) => {
    var InputParameters = {
      ID: id,
      Type: "User",
      ClientID: ClientID,
      Field: SortField,
      SortBy: SortedBy,
    };
    const MeetingStatus = await Axios({
      url: CommonConstants.MOL_APIURL + "/potentialmeetingstatus/MeetingStatusDeleteDropDown",
      method: "POST",
      data: InputParameters,
    });
    
    let Datas = MeetingStatus.data.Data.filter(obj => obj._id !== id);
    var MeetingStatusDropDown = [];
    Datas?.map((val) => {
      MeetingStatusDropDown[val._id] = `${val.Status}`;
    });

    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a engagement status.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Replace Status",
          text: "Which value replace with '" + Name + "' engagement status?",
          confirmButtonColor: "#360947",
          cancelButtonColor: "#34bfa3",
          confirmButtonText: "Save",
          cancelButtonText: "Cancel",
          position: 'top',
          reverseButtons: true,
          input: "select",
          inputOptions: MeetingStatusDropDown,
          inputPlaceholder: MeetingStatusDropDown[0],
          showCancelButton: true,
          showCloseButton: true,
          customClass: {
            title: 'titelleft',
            popup: 'container-500',
          },
        }).then((Success) => {
        if (Success.isConfirmed){
          var UpdateId = Success.value;
          var data = {
            id: id,
            NewMeetingStatusID: UpdateId,
            LastUpdatedBy: CUserID,
            Role: Role,
            ClientID:ClientID
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/potentialmeetingstatus/PotentialMeetingStatusDelete",
            method: "POST",
            data: data,
          }).then((res) => {
            if (res) {
              if ((res.statusText = "Ok")) {
                Swal.fire(
                  "Deleted!",
                  "Engagement status deleted successfully.",
                  "success"
                );
                SetPage(1);
                SetSearch("");
                document.getElementById("Search").value = "";
                GetEngagementStatus(ClientID, UserID, Role);
              } else {
              }
            }
          });
        }else if (Success.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //page change
  const PageChange = (event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //Search table list
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      var SearchedVal = document.getElementById("Search").value;
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };

  //change row
  const ChangeRowSelected = (event) => {
    SetRowsPerPage(Number(event.target.value));
    SetPage(1);
  };

  //sort data with title
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("Search").value;
    SetIsLoading(true)
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        Sortby: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/potentialmeetingstatus/PotentialMeetingStatusGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false)
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        Sortby: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/potentialmeetingstatus/PotentialMeetingStatusGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false)
      });
    }
  };

  return (
    <div>
     {
      IsLoading ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : <></>
    }
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="Search"
              id="Search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
              <TableCell className="w-250" onClick={() => { SortData("Status"); setSortedColumn("Status"); }}>
              Status
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Status" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Status" ? "active" : null} />
                </span>
              </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow>
                    <TableCell
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {row?.Status}
                    </TableCell>
                    <TableCell>
                      <a title="Edit"
                        onClick={() => {
                          EditEngagementStatus(row?._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i class="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      <a title="Delete"
                        onClick={() => {
                          DeleteBtn(row?._id, row?.Status);

                        }}
                        className="btn-eyesicon"
                      >
                        <i class="la flaticon-delete-1 delete-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            page={Page}
            onChange={PageChange}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={PageChange}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
  );
}
