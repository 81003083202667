import React, { useEffect } from 'react';
import Pagination from "@material-ui/lab/Pagination";
import Axios from "axios";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Paper from '@material-ui/core/Paper';
import loadingicon from "../../../images/loading.gif";

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';


export default function CallRecordingsTable({ ToDate, FromDate }) {
  const [Flen, SetFlen] = React.useState(0);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [SalesRepList, SetSalesRepList] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("PSTCreatedDate");
  const [SortValue, SetSortValue] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsIncludeAllStatus, SetIsIncludeAllStatus] = React.useState(false);
  const [LessThan30Sec, SetLessThan30Sec] = React.useState(false);
  const [ColdCallersID, SetColdCallersID] = React.useState(null);
  const [IsLoading,SetIsLoading]= React.useState(true);
  const [CUserID,SetCUserID] = React.useState(0);
  const [sortedColumn, setSortedColumn] = React.useState("PSTCreatedDate");

   useEffect(() => {
    document.title = "Call Recordings | SalesHive";
    const details = GetUserDetails();
    if (details != null) {
      SetClientID(details.ClientID);
      SetUserID(details.ParentUserID);
      SetCUserID(details.ChildUserID);
      SetRole(details.Role);
      GetColdCallingRecordings(details.ClientID, details.ParentUserID, details.Role,FromDate,ToDate);

    }
  }, [FromDate,ToDate,SortValue, SortField, Search, RowsPerPage, Page, ColdCallersID, LessThan30Sec, IsIncludeAllStatus]);

  useEffect(() => {
    const details = GetUserDetails();
    GetSalesRep(details.ParentUserID);
  }, []);


  const GetSalesRep = (UID) => {
    const InputParameter = {
      UserID: UID
    }
    Axios({
      url: CommonConstants.MOL_APIURL + "/cold_caller_recording/SalesDevelopmentRepsGet",
      method: "POST",
      data: InputParameter,
    }).then((Res) => {
      if (Res.data.StatusMessage = "SUCCESS") {
      SetSalesRepList(Res.data.Data);
      }
    });
  }
  //get Cold calling recording  list
  const GetColdCallingRecordings = (CID, UID, role,FromDate,ToDate) => {
    if(CID != "" && UID != "" && role != "" && FromDate != "" && ToDate != ""){
      SetIsLoading(true)
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortValue,
        Search: Search?.trim(),
        Type: "User",
        ClientID: CID == "" ? ClientID : CID,
        UserID: UID == "" ? UserID : UID,
        Role: role == "" ? Role : role,
        IncludeAllStatus: IsIncludeAllStatus,
        LessThan30Sec: LessThan30Sec,
        ToDate: ToDate,
        FromDate: FromDate,
        ColdCallersID: ColdCallersID
      };
      
      const Row = Axios({
        url: CommonConstants.MOL_APIURL + "/cold_caller_recording/CallRecordingGet",
        method: "POST",
        data: InputParameter,
      });
      Row.then((Result) => {
        if (Result.data.StatusMessage = "SUCCESS") {
          SetRows(Result.data.PageData);
          SetRlen(Result.data.TotalCount);
          SetFlen(Result.data.TotalCount);
          SetCountPage(Result.data.PageCount);
          SetIsLoading(false)
        }else{
          SetIsLoading(false)
  
        }
      });
    }
  };

  //get sort field data

  const SortData = (Field) => {
    SetIsLoading(true);
    const isNewSortField = Field !== SortField;
    let SortBy = SortValue === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortValue(1);
    } else {
      SetSortValue(SortBy);
    }
  
    SetSortField(Field);
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal?.trim());
      SetPage(1)

    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  //Search Box
  const SearchData = () => {
    let IncludeAllStatus = document.getElementById('includeAllStatus').checked;
    let lessthan30s = document.getElementById('lessthan30s').checked;
    let ColdCallersID = document.getElementById('ColdCallersID').value;
    SetIsIncludeAllStatus(IncludeAllStatus);
    SetLessThan30Sec(lessthan30s);
    SetColdCallersID(ColdCallersID);
  }

  const Reset=()=>{
    SetIsIncludeAllStatus(false);
    SetLessThan30Sec(false);
    SetColdCallersID(null);
    SetPage(1);
    SetSearch(""); 
    document.getElementById('includeAllStatus').checked =false;
    document.getElementById('lessthan30s').checked = false;
    document.getElementById('ColdCallersID').value = "";
  }
  return (
    <>
    {
      IsLoading === true ?
      <div id="hideloding" className="loding-display">
      <img src={loadingicon} />
    </div> :<></>
    }
      <div className="row my-4 borderbtnm pb-4 px-3">
        <div className="mr-4 mb-2">
          <div className="boxborders">
            <label class="check_bx nowrap">
              <input type="checkbox" id="includeAllStatus" /><span className="checkmark"></span>
              Include all status
            </label>
          </div>
        </div>
        <div className="mb-2">
          <div className="boxborders">
            <label class="check_bx nowrap">
              <input type="checkbox" id="lessthan30s" /><span className="checkmark"></span>
              Include less than 30secs
            </label>
          </div>
        </div>
        <div class="col d-flex">
          <label class="mt-2 mr-2 nowrap"> Sales Development Reps</label>
          <select class="form-control  m-input mr-4" data-val="true" data-val-number="The field ColdCallersID must be a number."
            id="ColdCallersID" name="ColdCallersID">
            <option value="">--Select--</option>
            {SalesRepList.map((row) => (
              <option value={row._id} key={row._id}> {row.ColdCallersName} {row.LastName}</option>

            ))};

          </select> 
          <div className='d-flex align-items-start'>
          <a href="javascript:void(0);" class="btn btn-primary btnColor" onClick={SearchData}><i class="la la-search"></i>Search</a>
          <a href="javascript:void(0);" class="ml-3 btn btn-secondary" onClick={Reset}><i class="la la-refresh"></i> Reset</a>
          </div>
        </div>
      </div>
      <div className="row px-3 pt-5">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>

      <div className="table-bordered mx-3">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell onClick={()=>{SortData("Name"); setSortedColumn("Name");}}>Name<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "Name" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "Name" ? "active" : null} />
                    </span></TableCell>
                <TableCell onClick={()=>{SortData("Email"); setSortedColumn("Email");}}>
                  Email<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "Email" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "Email" ? "active" : null} />
                    </span></TableCell>
                <TableCell onClick={()=>{SortData("CompanyPhone"); setSortedColumn("CompanyPhone");}}>Company Phone<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "CompanyPhone" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "CompanyPhone" ? "active" : null} />
                    </span></TableCell>
                <TableCell onClick={()=>{SortData("DirectPhone"); setSortedColumn("DirectPhone");}}>Direct Phone<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "DirectPhone" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "DirectPhone" ? "active" : null} />
                    </span></TableCell>
                <TableCell onClick={()=>{SortData("Mobile"); setSortedColumn("Mobile");}}>Mobile Phone<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "Mobile" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "Mobile" ? "active" : null} />
                    </span></TableCell>
                <TableCell onClick={()=>{SortData("Title"); setSortedColumn("Title");}}>Title<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "Title" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "Title" ? "active" : null} />
                    </span></TableCell>
                <TableCell onClick={()=>{SortData("Company"); setSortedColumn("Company");}}>Company<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "Company" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "Company" ? "active" : null} />
                    </span></TableCell>
                <TableCell onClick={()=>{SortData("Duration"); setSortedColumn("Duration");}}>Duration<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "Duration" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "Duration" ? "active" : null} />
                    </span></TableCell>
                <TableCell onClick={()=>{SortData("Status"); setSortedColumn("Status");}}>Result<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "Status" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "Status" ? "active" : null} />
                    </span></TableCell>
                <TableCell onClick={()=>{SortData("PSTCreatedDate"); setSortedColumn("PSTCreatedDate");}}>Call Date/ Time<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "PSTCreatedDate" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "PSTCreatedDate" ? "active" : null} />
                    </span></TableCell>
                <TableCell onClick={()=>{SortData("ColdCallersName"); setSortedColumn("ColdCallersName");}}>Sales Development Reps<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "ColdCallersName" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "ColdCallersName" ? "active" : null} />
                    </span></TableCell>
                <TableCell onClick={()=>{SortData("PlabackUrl"); setSortedColumn("PlabackUrl");}}>Recording<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "PlabackUrl" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "PlabackUrl" ? "active" : null} />
                    </span></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (Rows.map((row) => (
                <TableRow key={row?._id}>
                  <TableCell>{row.Name}</TableCell>
                  <TableCell>{row.Email}</TableCell>
                  <TableCell>{row.CompanyPhone}</TableCell>
                  <TableCell>{row.DirectPhone}</TableCell>
                  <TableCell>{row.Mobile}</TableCell>
                  <TableCell>{row.Title}</TableCell>
                  <TableCell>{row.Company}</TableCell>
                  <TableCell>{row.Duration1}</TableCell>
                  <TableCell>{row.Status}</TableCell>
                  <TableCell>{row.PSTCreatedDate + " PT"}</TableCell>
                  <TableCell>{row.ColdCallersName}</TableCell>
                  <TableCell>
                  <audio controls style={{ outline: 'none', width: '300px' }} type='audio/mp3'>
                      <source src={`${CommonConstants.CallRecoingURL}${row.RecordingFileName}.mp3`} />
                    </audio>
                  </TableCell>
                </TableRow>
              )))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

        <div className="row px-3 pb-4 mb-2">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
    </>
  );
}
