import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Avatar } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Userimg from '../../../images/avatar/1.jpg';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popup from "reactjs-popup";
import Axios from 'axios';
import { CommonConstants } from '../../../_constants/common.constants';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export default function AccountAllTable() {
  const [data, setdata] = React.useState([]);
  const [flen, setflen] = React.useState(0);
  const [sflag, setsflag] = React.useState(false);
  const [rlen, setrlen] = React.useState(0);
  const [countpage, setCountPage] = React.useState(0);
  const [rows, setRows] = React.useState([])
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, SetrowsPerPage] = React.useState(15);
  const [searched, setSearched] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElf, setfAnchorEl] = React.useState(null);
  const [aID, setAID] = React.useState("");
  const [checkS, setcheckS] = React.useState(false);
  const [orignaldata, setorignaldata] = React.useState([]);
  const [sortField, setsortField] = React.useState("Name");
  const [sortedBy, setsortedBy] = React.useState(1);
  const [archivepopup, setarchivepopup] = React.useState(false);
  const [cancelarchivepopupval, setcancelarchivepopupval] = React.useState(false);
  const [ArchivedPopup, setArchivedPopup] = React.useState(false);
  const [question, setquestion] = React.useState("");
  const [msg, setmsg] = React.useState("");
  const [type, settype] = React.useState("");
  const [popupbutton, setpopupbutton] = React.useState("");
  useEffect(() => {
    startpage("normal", page, rowsPerPage);
    originaldatastore();
  }, [])
  const imgstyle = {
    height: '40px',
    width: '40px',
    borderradius: '50%'
  }
  const startpage = (strdata, pages, rowcount) => {
    if (strdata == "normal") {
      
      var str_in = {
        "page": page,
        "rowsPerPage": rowsPerPage,
        "serchbox": false,
        "archive": checkS,
        "sort": true,
        "field": sortField,
        "sortby": sortedBy,
        "type": "User"
      }
    } else if (strdata == "search") {
      
      var str_in = {
        "page": pages,
        "rowsPerPage": rowcount,
        "serchbox": false,
        "archive": checkS,
        "sort": true,
        "field": sortField,
        "sortby": sortedBy,
        "type": "User"
      }
    }
    
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/account_client_status/AccountClientStatusList",
      method: "POST",
      data: str_in
    });
    rows1.then(result => {
      
      setdata(result.data.pagedata)
      setRows(result.data.pagedata)
      setrlen(result.data.totalcount)
      setflen(result.data.totalcount)
      setCountPage(result.data.pagecount)
    })
  }
  const originaldatastore = () => {
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/responder_profile/findnoarchive",
      method: "GET"
    });
    rows1.then(result => {
      setorignaldata(result.data)
    })
  }
  const requestSearch = () => {
    var searchedVal = document.getElementById('search').value
    setPage(1);
    setRowsPerPage(10);
    var serchbox;
    var archived;
    if (checkS == true) {
      archived = true
    } else {
      archived = false
    }
    if (searchedVal == "") {
      serchbox = false;
      setsflag(false);
    } else {
      serchbox = true;
      setsflag(true);
    }
    var str_in = {
      "page": 1,
      "rowsPerPage": rowsPerPage,
      "search": searchedVal,
      "serchbox": serchbox,
      "archive": archived,
      "sort": true,
      "field": sortField,
      "sortby": sortedBy,
      "type": "User"
    }
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/account_client_status/AccountClientStatusList",
      method: "POST",
      data: str_in
    });
    rows1.then(result => {
      
      setdata(result.data.pagedata)
      setRows(result.data.pagedata)
      setrlen(result.data.totalcount)
      setCountPage(result.data.pagecount)
    })
    // if(searchedVal == ""){
    //   setSearched("");
    //   setrlen(flen);
    //   setsflag(false);
    //   setPage(0)
    //   setRows(data)
    //   setCountPage(parseInt(((flen + (rowsPerPage - 1)) / rowsPerPage)))

    // }else{
    //   const filteredRows = orignaldata.filter((row) => {
    //     return row.Firstname.toLowerCase().includes(searchedVal.toLowerCase());
    //   });
    //   setRows(filteredRows);
    //   setrlen(filteredRows.length);
    //   setsflag(true);
    //   setPage(0)
    //   setCountPage(parseInt(((filteredRows.length + (rowsPerPage - 1)) / rowsPerPage)))
    // }

  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (checkS == true) {
      var str_in = {
        "page": newPage,
        "rowsPerPage": rowsPerPage,
        "serchbox": false,
        "archive": true,
        "sort": true,
        "field": sortField,
        "sortby": sortedBy,
        "type": "User"
      }
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/account_client_status/AccountClientStatusList",
        method: "POST",
        data: str_in
      });
      rows1.then(result => {
        
        setdata(result.data.pagedata)
        setRows(result.data.pagedata)
        setrlen(result.data.totalcount)
        setflen(result.data.totalcount)
        setCountPage(result.data.pagecount)
      })

    } else {
      var str_in = {
        "page": newPage,
        "rowsPerPage": rowsPerPage,
        "serchbox": false,
        "archive": false,
        "sort": true,
        "field": sortField,
        "sortby": sortedBy,
        "type": "User"
      }
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/account_client_status/AccountClientStatusList",
        method: "POST",
        data: str_in
      });
      rows1.then(result => {
        
        setdata(result.data.pagedata)
        setRows(result.data.pagedata)
        setrlen(result.data.totalcount)
        setflen(result.data.totalcount)
        setCountPage(result.data.pagecount)
      })
    }
  }


  const chengeRowSelected = (event) => {
    
    setRowsPerPage(event.target.value);
    setPage(1);
    
    //setCountPage(parseInt(((rlen + (event.target.value - 1)) / event.target.value)))
    startpage("search", 1, event.target.value);
  }

  const handleClick = (event, id) => {
    
    setAID(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick1 = (event, id) => {
    
    setAID(id);
    setfAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setfAnchorEl(null);
  };
  const clickOnSearch = () => {
    
    var check = document.getElementById('include_archive')
    
    if (check.checked == true) {
      setcheckS(true);
      var str_in = {
        "page": page,
        "rowsPerPage": rowsPerPage,
        "serchbox": false,
        "archive": true,
        "sort": true,
        "field": sortField,
        "sortby": sortedBy,
        "type": "User"

      }
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/account_client_status/AccountClientStatusList",
        method: "POST",
        data: str_in
      });
      rows1.then(result => {
        
        setdata(result.data.pagedata)
        setRows(result.data.pagedata)
        setrlen(result.data.totalcount)
        setflen(result.data.totalcount)
        setCountPage(result.data.pagecount)
      })
    }
    else {
      setcheckS(false);
      var str_in = {
        "page": page,
        "rowsPerPage": rowsPerPage,
        "serchbox": false,
        "archive": false,
        "sort": true,
        "field": sortField,
        "sortby": sortedBy,
        "type": "User"
      }
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/account_client_status/AccountClientStatusList",
        method: "POST",
        data: str_in
      });
      rows1.then(result => {
        
        setdata(result.data.pagedata)
        setRows(result.data.pagedata)
        setrlen(result.data.totalcount)
        setflen(result.data.totalcount)
        setCountPage(result.data.pagecount)
      })
    }
  }

  const archiveUser = () => {
    
    var str_in = {
      "uID": aID,
      "flag": "archive"
    }
    
    Axios({
      url: CommonConstants.MOL_APIURL + "/responder_profile/archiveprofile",
      method: "POST",
      data: str_in
    }).then(res => {
      
      handleClose1();
      setAID("");
      clickOnSearch();
      originaldatastore();
    })
  }
  const unarchiveUser = () => {
    
    var str_in = {
      "uID": aID,
      "flag": "unarchive"
    }
    
    Axios({
      url: CommonConstants.MOL_APIURL + "/responder_profile/archiveprofile",
      method: "POST",
      data: str_in
    }).then(res => {
      
      handleClose();
      clickOnSearch();
    })
  }
  const deleteUser = () => {
    
    var str_in = {
      "_id": aID,
      "IsDelete":true,
      "LastUpdatedBy":null,
      "LastUpdatedDate":new Date(),
      "flag": "delete",
      "Role":""
    }
    
    Axios({
      url: CommonConstants.MOL_APIURL + "/account_client_status/AccountClientStatusDelete",
      method: "POST",
      data: str_in
    }).then(res => {
      
      handleClose();
      startpage("normal", page, rowsPerPage);
    })
  }

  const editpage = (id) => {
    window.location = CommonConstants.home + "editaccountclientstatus?code=" + id;
  }

  const sortdata = (field) => {
    
    var searchedVal = document.getElementById('search').value
    // setPage(1);
    // setRowsPerPage(10);
    var serchbox;
    if (searchedVal == "") {
      serchbox = false;
      setsflag(false);
      var sortfield = field;
      var sortedby;
      if (sortedBy == 1) {
        sortedby = -1;
        setsortedBy(-1);

      } else {
        sortedby = 1;
        setsortedBy(1);
      }
      setsortField(field);
      var str_in = {
        "page": page,
        "rowsPerPage": rowsPerPage,
        "serchbox": false,
        "archive": false,
        "sort": true,
        "field": sortfield,
        "sortby": sortedby,
        "type": "User"
      }
      
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/account_client_status/AccountClientStatusList",
        method: "POST",
        data: str_in
      });
      rows1.then(result => {
        
        setdata(result.data.pagedata)
        setRows(result.data.pagedata)
        setrlen(result.data.totalcount)
        setflen(result.data.totalcount)
        setCountPage(result.data.pagecount)
      })
    } else {
      serchbox = true;
      setsflag(true);
      var sortfield = field;
      var sortedby;
      if (sortedBy == 1) {
        sortedby = -1;
        setsortedBy(-1);

      } else {
        sortedby = 1;
        setsortedBy(1);
      }
      setsortField(field);
      var str_in = {
        "page": page,
        "rowsPerPage": rowsPerPage,
        "search": searchedVal,
        "serchbox": serchbox,
        "archive": false,
        "sort": true,
        "field": sortfield,
        "sortby": sortedby,
        "type": "User"
      }
      
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/account_client_status/AccountClientStatusList",
        method: "POST",
        data: str_in
      });
      rows1.then(result => {
        
        setdata(result.data.pagedata)
        setRows(result.data.pagedata)
        setrlen(result.data.totalcount)
        setflen(result.data.totalcount)
        setCountPage(result.data.pagecount)
      })
    }

  }

  const archiveopen = (popuptype,id) => {
    settype(popuptype)
    setAID(id);
    if (popuptype == "archive") {
      handleClose1();
      setmsg("you want to Archive a Responder.");
      setquestion("Are you sure?");
      setpopupbutton("Yes, archive it!");
    } else if (popuptype == "unarchive") {
      handleClose();
      setmsg("you want to Unarchive a Responder.");
      setquestion("Are you sure?");
      setpopupbutton("Yes, unarchive it!");
    } else {
      handleClose();
      setmsg("you want to Delete a Responder.");
      setquestion("Are you sure?");
      setpopupbutton("Yes, delete it!");
    }
    setarchivepopup(true);
  }

  const archivesubmit = () => {
    setarchivepopup(false);

    if (type == "archive") {
      setmsg("Responder Archived successfully.");
      setquestion("Archived!");
      setArchivedPopup(true);
      archiveUser();
    } else if (type == "unarchive") {
      setmsg("Responder Unarchived successfully.");
      setquestion("Unarchived!");
      setArchivedPopup(true);
      unarchiveUser();
    } else {
      setmsg("Responder Delete successfully.");
      setquestion("Delete");
      setArchivedPopup(true);
      deleteUser();
    }
  }

  const archivecancle = () => {
    
    setarchivepopup(false);
    openarchivepopup();
  }

  const openarchivepopup = () => {
    setcancelarchivepopupval(true);
  }

  const canclearchivepopup = () => {
    setcancelarchivepopupval(false);
  }

  const responderarchive = () => {
    setArchivedPopup(false);
  }

  return (
    <div>
    <Popup open={archivepopup} modal>
    <div className="modal-black"></div>
    <div className="filterPopup maxpopup">
        <div className="paddingboxTerms"> 
            <div className="modal-content bordernone aligncentertext">
            <div class="swal2-icon swal2-warning swal2-animate-warning-icon">
              <span class="swal2-icon-text">!</span>
              </div>
            <h3>{question}</h3>
            <p>{msg}</p>
            <div class="swal2-actions">
              <button type="button" onClick={archivecancle} class="swal2-cancel btn btn-secondary m-btn m-btn--custom mr-2">No, cancel!</button>
              <button type="button" onClick={archivesubmit} class="swal2-confirm btn btn-success m-btn m-btn--custom">{popupbutton}</button>
            </div>
            </div>

        </div> 
    </div> 
</Popup>
<Popup open={cancelarchivepopupval} modal>
    <div className="modal-black"></div>
    <div className="filterPopup maxpopup">
        <div className="paddingboxTerms"> 
            <div className="modal-content bordernone aligncentertext">
            <div class="swal2-icon swal2-error swal2-animate-error-icon d-flex">
              <span class="swal2-x-mark">
                <span class="swal2-x-mark-line-left"></span>
                <span class="swal2-x-mark-line-right"></span>
              </span>
            </div>
            <h3>Cancelled</h3>
            <p>Your data is safe :)</p>
            <div class="swal2-actions">
              {/* <button type="button" class="swal2-cancel btn btn-secondary m-btn m-btn--custom mr-2">No, cancel!</button> */}
              <button onClick={canclearchivepopup} type="button" class="swal2-confirm btn btn-success m-btn m-btn--custom">Ok</button>
            </div>
            </div>

        </div> 
    </div> 
</Popup>
<Popup open={ArchivedPopup} modal>
    <div className="modal-black"></div>
    <div className="filterPopup maxpopup">
        <div className="paddingboxTerms"> 
            <div className="modal-content bordernone aligncentertext">
            <div class="swal2-icon swal2-success swal2-animate-success-icon">
              <div class="swal2-success-circular-line-left"></div>
                  <span class="swal2-success-line-tip"></span> 
                  <span class="swal2-success-line-long"></span>
                  <div class="swal2-success-ring"></div> 
                  <div class="swal2-success-fix"></div>
                  <div class="swal2-success-circular-line-right"></div>
                </div>
            <h3>{question}</h3>
            <p>{msg}</p>
            <div class="swal2-actions">
              {/* <button type="button" class="swal2-cancel btn btn-secondary m-btn m-btn--custom mr-2">No, cancel!</button> */}
              <button type="button" onClick={responderarchive} class="swal2-confirm btn btn-success m-btn m-btn--custom">Ok</button>
            </div>
            </div>

        </div> 
    </div>              
</Popup>
<div className="row">
        <div className="col padd-0"> 
        </div>
    </div>
<div className="row padt-25">
        <div className="col-sm-12 col-md-6">
            <label className="textlabelte">Show 
            <select name="tbl_meeting_length" onChange={chengeRowSelected} aria-controls="tbl_meeting" class="form-control form-control-sm">
            {CommonConstants.show_rows.map((value)=>
              (<option value={value}>{value}</option>)
            )}
            {/* <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option> */}
            </select> 
            entries</label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
        <label class="textlabelte">Search: <input type="search" id="search" onChange={() => requestSearch()} class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting"/></label>
        </div>
</div>
<div className="table-bordered">
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Field Names</TableCell>
            {/* <TableCell>Meeting Channel</TableCell>
            <TableCell>Meeting Channel Type</TableCell>
            <TableCell>Count In Reporting?</TableCell>  */}
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {rows.map((row) => (
            <TableRow>
              <TableCell>{row.Name}</TableCell>
              <TableCell>{ }</TableCell>
              <TableCell>{ }</TableCell>
              <TableCell>{ }</TableCell>
              <TableCell>
                <a href="#" onClick={()=>{editpage(row._id)}} className="btn-eyesicon"><i class="la flaticon-edit-1 edit-icon"></i></a>
                <a href="#" onClick={()=>{archiveopen("delete",row._id)}} className="btn-eyesicon"><i class="la flaticon-delete-1 delete-icon"></i></a>  
              </TableCell> 
            </TableRow>
          ))}
        </TableBody>

            <div className="row">
        
         </div>

      </Table>
    </TableContainer>
    </div>
    {
    sflag ? (<div class="row">
      <Pagination
        component="div"
        count={countpage}
        onChange={handleChangePage} showFirstButton showLastButton
      />
      <div class="col dataTables_info">
        <p>Showing {rlen == 0 ? 0 : ((page - 1) * rowsPerPage + 1)} to {((page * rowsPerPage)) > rlen ? rlen : ((page * rowsPerPage))} of {rlen} entries (filtered from {flen} total entries)</p>
      </div>
    </div>) : <div class="row">

      <div class="col dataTables_info">
        <p>Showing {rlen == 0 ? 0 : ((page - 1) * rowsPerPage + 1)} to {((page * rowsPerPage)) > rlen ? rlen : ((page * rowsPerPage))} of {rlen} entries</p>
      </div>
      <div class="col pageright">
        <Pagination
          component="div"
          count={countpage}
          onChange={handleChangePage} showFirstButton showLastButton
        />
      </div>
    </div>
  }
    </div >
  );
}
