import React from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import Footer from "../../../_components/user/footer/footer";

toast.configure();

class CAddReasonUnqualifiedPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);
    this.state = {
      Fields: {},
      Errors: {},
      TitleNotNull: false,
      IsExist: false,
      NameAvailable: null,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      Role: Details.Role,
      UserIDby: Details.ChildUserID,
      IsButtonDisabled:false
    };
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title="Add Account Reason Unqualified | SalesHive"
  }

  // form validation
  async FromValidation() {
    let FormIsValid = true;
    var Name = document.getElementById("Name").value.trim();
    var IsExist = await this.CheckExistAccountReason(Name);
    let Errors = {};
    if (Name == "" && Name.indexOf(" ") != "") {
      FormIsValid = false;
      Errors["Name"] = "Please Enter Name";
    }
    if (IsExist == true) {
      FormIsValid = false;
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

  // handle change
  HandleChange(field, e) {
    let Fields = this.state.Fields;
    Fields[field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Name != "" && Fields.Name.indexOf(" ") != "") {
      this.state.Errors.Name = null;
      this.state.NameAvailable = null;
      this.CheckExistAccountReason(e.target.value);
    } else {
      this.state.NameAvailable = null;
      this.state.Errors.Name = null;
    }
  }

  //check exist Status
  async CheckExistAccountReason(Name) {
    var str_in = {
      ClientID: this.state.ClientID,
      Reason: Name,
      Role:this.state.Role
    };
    let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/account_reason_unqualified/AccountReasonUnqualifiedExists",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        this.setState({ NameAvailable: resdata.data.Data[0]?.Reason });
        this.setState({ IsExist: true });
        return true;
      } else {
        this.setState({ IsExist: false });
        return false;
      }
    }
  }

  async SaveBtn(e) {
    // this.state.Errors.Name = null;
    // this.state.NameAvailable = null;
    e.preventDefault();
    // e.currentTarget.disabled = true;
    this.setState({IsButtonDisabled:true});
    var Final_flag = await this.FromValidation();

    if (Final_flag == true) {
      var Name = document.getElementById("Name").value.trim();
      var AddField = {
        Reason: Name,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
        CreatedBy: this.state.UserIDby,
        CreatedDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/account_reason_unqualified/AccountReasonUnqualifiedAdd",
        method: "POST",
        data: AddField,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Account Reason Unqualified
              <br />
              Account Reason Unqualified add Successfully.
            </div>
          );
          history.push("/CAccountReasonUnqualified");
        } else {
          toast.error(res.data.Message);
          this.setState({IsButtonDisabled:false});
        }
      });
    } else {
      this.setState({IsButtonDisabled:false});
      //document.getElementById("submit").disabled = false;
    }
  }

  //Back
  BackBtn() {
    history.push("/CAccountReasonUnqualified");
  }

  render() {
    return (
      <>
      <div className="adminmain mheight"> 
        <Sidebar className="" /> 
        <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitlepad float-left">
                  Add 
                </h4>
              </div>
            </div>

          <div class="paddcols">
            <div className="row py-2 px-4">
              <div class="col-lg-6 boxsinput_group">
                <div class="row max-cols">
                  <label class="col-lg-4 view-lable-meet">Name</label>
                  <div className="col-lg-8">
                    <input
                      className="form-control m-input"
                      data-val="true"
                      data-val-required="Please enter first Name"
                      onBlur={this.HandleChange.bind(this, "Name")}
                      id="Name"
                      name="Name"
                      placeholder="Enter name"
                      type="text"
                    />
                    <span style={{ color: "red" }}>
                      {this.state.Errors["Name"]}
                    </span>
                    {this.state.NameAvailable && (
                      <span style={{ color: "red" }}>Name already exist.</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

            <div class="row my-3">
              <div class="col-lg-12 pull-left">
                <button
                  id="submit"
                  onClick={this.SaveBtn}
                  class="btn btn-primary btn-lightgreen mr-1"
                >
                  <i class="la la-save"></i> Save
                </button>
                <button
                  id="backtolist"
                  onClick={this.BackBtn}
                  class="btn btn-secondary"
                >
                  <i class="la la-arrow-circle-left"></i> Back
                </button>
              </div>
            </div>
        </div>
      </div>
      <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCAddReasonUnqualifiedPage = connect(
  mapState,
  actionCreators
)(CAddReasonUnqualifiedPage);
export { connectedCAddReasonUnqualifiedPage as CAddReasonUnqualifiedPage };
