import React, { useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";
const moment = require("moment");

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function BlackListDomain(props) {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [PUserID, SetPUserID] = React.useState(0);
  const [Role,SetRole] = React.useState("");
  const [EditData, SetEditData] = React.useState([]);
  const [OldDomainData, SetOldDomainData] = React.useState("");
  const [Fields, SetFields] = React.useState({});
  const [Errors, SetErrors] = React.useState({});
  // Domain tab
  const [IsExist, SetIsExist] = React.useState(false);
  const [IsValid, SetIsValid] = React.useState(false);
  const [IsLoading, SetIsLoading] = React.useState(false);
  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetPUserID(Details.ChildUserID);
      SetRole(Details.Role)
    }
   // console.log("props3232",props)
    BlackListedDomainGet(Details.ClientID, Details.ParentUserID,Details.Role);
  }, [Search, Page, RowsPerPage, ClientID, UserID, props.addDomainlistupdate]);

  const BlackListedDomainGet = (CID, UID,Roles) => {
    var BlackListedDomainData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role:Roles
    };

    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL + "/blacklisteddomain/BlacklistedDomainGet",
      method: "POST",
      data: BlackListedDomainData,
    });
    rows1.then((result) => {
      SetData(result.data?.PageData);
      SetRows(result.data?.PageData);
      SetRlen(result.data?.TotalCount);
      SetFlen(result.data?.TotalCount);
      SetCountPage(result.data?.PageCount);
document.getElementById("hideloding").style.display = "none"
    
    });
  };

  // Delete for domain function
  const DeleteBtn = (id, Domain) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a Domain .",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        SetIsLoading(true)
        var data = {
          SalesReplyBlackListedDomainID: id,
          IsDeleted: true,
          DomainName: Domain,
          Role:Role,
          UserID: UserID,
          ClientID:ClientID
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/blacklisteddomain/BlacklistedDomainDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
             
              toast.success(<div className="toastsize">Domain <br/>Saleshive blacklisted domain will be deleted soon.</div>)
              history.push("/blacklists");
              SetPage(1);
              SetSearch("");
              BlackListedDomainGet(ClientID,UserID,Role)
              document.getElementById("search").value = "";
              props.updateFromChild(true);
              SetIsLoading(false)
            } else {
              SetIsLoading(false)

            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
        SetIsLoading(false)

      }
    });
  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      BlackListedDomainGet(ClientID, UserID);
      SetIsLoading(true)
    }
  };

  // change page
  const ChangeRowSelected = (event) => {
    SetRowsPerPage(event.target.value);
    SetPage(1);
    BlackListedDomainGet(ClientID, UserID);
    SetIsLoading(true)
  };

  // handle change page
  const HandleChangePage = (event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      SetIsLoading(true)
    }
  };

  // Edit desing change
  const EditDomain = (SalesReplyBlackListedDomainID) => {
    SetIsLoading(true)
    document.getElementById(
      SalesReplyBlackListedDomainID + "labelbox"
    ).style.display = "none";
    document.getElementById(
      SalesReplyBlackListedDomainID + "text"
    ).style.display = "block";
    document.getElementById(
      SalesReplyBlackListedDomainID + "editicon1"
    ).style.display = "none";
    document.getElementById(
      SalesReplyBlackListedDomainID + "saveicon1"
    ).style.display = "block";
    document.getElementById(
      SalesReplyBlackListedDomainID + "editicon2"
    ).style.display = "none";
    document.getElementById(
      SalesReplyBlackListedDomainID + "saveicon2"
    ).style.display = "block";

    var str_in = {
      SalesReplyBlackListedDomainID: SalesReplyBlackListedDomainID,
      Role:Role
    };

    Axios({
      url:
        CommonConstants.MOL_APIURL + "/blacklisteddomain/DomainBlacklistByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById(SalesReplyBlackListedDomainID + "text").value =
          res.data.Data?.Domain;
        SetOldDomainData(res.data.Data?.Domain);
        SetEditData(res.data?.Data);
    SetIsLoading(false)

      } else {
        toast.error(res.data.Message);
        SetIsLoading(false)
      }
    });
  };

  const HideEditDomain = (SalesReplyBlackListedDomainID) => {
    document.getElementById(
      SalesReplyBlackListedDomainID + "labelbox"
    ).style.display = "block";
    document.getElementById(
      SalesReplyBlackListedDomainID + "text"
    ).style.display = "none";
    document.getElementById(
      SalesReplyBlackListedDomainID + "editicon1"
    ).style.display = "block";
    document.getElementById(
      SalesReplyBlackListedDomainID + "saveicon1"
    ).style.display = "none";
    document.getElementById(
      SalesReplyBlackListedDomainID + "editicon2"
    ).style.display = "block";
    document.getElementById(
      SalesReplyBlackListedDomainID + "saveicon2"
    ).style.display = "none";
  };

  // Update domain data
  const UpdateBlackListDomainBtn = async (id) => {
    SetIsLoading(true)
    var Final_flag = await FormValidation(id);
    if (Final_flag == true) {
      var Domain = document.getElementById(id + "text").value.trim();
      var BlackListDomainUpdate = {
        SalesReplyBlackListedDomainID: id,
        Domain: Domain,
        LastUpdatedDate: new Date(),
        UserID: UserID,
        OldDomainName: OldDomainData,
        Role:Role,
        ClientID:ClientID
      };

      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/blacklisteddomain/BlacklistedDomainUpdate",
        method: "POST",
        data: BlackListDomainUpdate,
      }).then((result) => {
        if (result.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div>
              Domain<br/>
              Domain updated successfully.
            </div>
          );
          HideEditDomain(id);
          // document.getElementById("hideloding").style.display = "block";
          BlackListedDomainGet(ClientID, UserID);
          SetIsLoading(false)
        } else {
          toast.error(result.data.Message);
          SetIsLoading(false)
        }
      });
    }
  };

  // formvalidation for domain tab
  const FormValidation = async (id) => {
    
    let formIsValid = true;
    let errors = {};
    var Domain = document.getElementById(id + "text").value.trim();

    let IsExist = await BlacklistDomainNameCheckExist(Domain);

    let FLAGISVALID = await DomainCheck(Domain);

    if (Domain == "" && Domain.indexOf(" ") != "") {
      formIsValid = false;
      toast.error(<div className="toastsize">Domain<br/>Please Enter Value</div>);
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    if (FLAGISVALID == true && Domain.length > 0) {
      formIsValid = false;
      toast.error("Invalid domain");
    }
    SetErrors(errors);
    return formIsValid;
  };

  //Domain Check for domain
  const DomainCheck = async (Domain) => {
    let regexp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;

    if (!regexp.test(Domain)) {
      SetIsValid(true);
      return true;
    } else {
      SetIsValid(false);
      return false;
    }
  };

  // cheack exists for domain tab
  const BlacklistDomainNameCheckExist = async (Domain) => {
    var str_in = {
      ClientID: ClientID,
      Domain: Domain,
      Role:Role
    };
    let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/blacklisteddomain/BlackListDomainCheckExits",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (OldDomainData == Domain) {
          SetIsExist(false);
          return false;
        } else {
          toast.error("Domain is already exists!");
          SetIsExist(true);
          SetIsLoading(false)
          return true;
        }
      } else {
        SetIsExist(false);
        SetIsLoading(false)
        return false;
      }
    }
  };

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: SearchedVal,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role:Role
      };
      const BlacklistedDomainGet = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/blacklisteddomain/BlacklistedDomainGet",
        method: "POST",
        data: InputParameter,
      });
      BlacklistedDomainGet.then((Result) => {
        SetData(Result.data?.PageData);
        SetRows(Result.data?.PageData);
        SetRlen(Result.data?.TotalCount);
        SetFlen(Result.data?.TotalCount);
        SetCountPage(Result.data?.PageCount);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: SearchedVal,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role:Role
      };
      const BlacklistedDomainGet = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/blacklisteddomain/BlacklistedDomainGet",
        method: "POST",
        data: InputParameter,
      });
      BlacklistedDomainGet.then((Result) => {
        SetRows(Result.data?.PageData);
        SetRlen(Result.data?.TotalCount);
        SetFlen(Result.data?.TotalCount);
        SetCountPage(Result.data?.PageCount);;
      });
    }
  };
  return (
    <div>
          { IsLoading == true ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>: <></>}
      <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>

      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {" "}
                  <a
                    onClick={() => {
                      SortData("Domain");
                    }}
                  >
                    Domain{" "}
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("CreatedDate");
                    }}
                  >
                   	Created Date
                  </a>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length == 0 ? (
                <p className="text-center">No data in Available</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>
                      <label
                        id={row?._id + "labelbox"}
                      >
                        {row.Domain}{" "}
                      </label>{" "}
                      <input
                        type="textbox"
                        id={row?._id + "text"}
                        class="form-control m-input"
                        style={{ display: "none" }}
                        value={Fields["Domain"]}
                        autocomplete="off"
                      />
                    </TableCell>
                    <TableCell>
                      {moment(new Date(row?.CreatedDate).toDateString()).format(
                        "MM/DD/YYYY"
                      )}
                    </TableCell>
                    <TableCell className="px-0 d-flex ">
                      <a
                        onClick={() => {
                          EditDomain(row?._id);
                        }}
                        id={row?._id + "editicon1"}
                        className="btn-eyesicon" title="Edit"
                      >
                        <i class="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      <a
                        onClick={() => {
                          DeleteBtn(
                            row?._id,
                            row.Domain
                          );
                        }}
                        id={row?._id + "editicon2"}
                        className="btn-eyesicon" title="Delete"
                      >
                        <i class="la flaticon-delete-1 delete-icon"></i>
                      </a>
                      <a
                        onClick={() => {
                          UpdateBlackListDomainBtn(
                            row?._id
                          )
                        }}
                        style={{ display: "none" }}
                        id={row?._id + "saveicon1"}
                        className="btn-eyesicon delete-icon" title="Save"
                      >
                        <i className="la la-save"></i>
                      </a>
                      <a
                        onClick={() => {
                          HideEditDomain(row?._id);
                        }}
                        style={{ display: "none" }}
                        id={row?._id + "saveicon2"}
                        className="btn-eyesicon delete-icon" title="Cancel"
                      >
                        <i className="la flaticon-cancel"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            page={Page}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
  );
}
