import React, { useEffect } from 'react';
import Pagination from "@material-ui/lab/Pagination";
import Axios from "axios";
import { CommonConstants } from "../../../_constants/common.constants";
import { getFirstDayOfQuarter, GetUserDetails } from "../../../_helpers/Utility";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import loadingicon from "../../../images/loading.gif";
import StyleHeader from "../StickyHeader/StickyHeader";


export default function CallDisposition({ ObjDisposition }) {
  
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);

  const [DispositionData, SetDispositionData] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [ColdCallersID, SetColdCallersID] = React.useState(null);
  const [IsLoading, SetIsLoading] = React.useState(true);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);



  useEffect(() => {
    document.title = "Call Reporting | SalesHive"
    var fromdate = getFirstDayOfQuarter()
    fromdate.setDate(fromdate.getDate())
    const formattedDate = fromdate.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    });
    var Todate = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })

    const Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);

      GetDisposition(Details.ClientID, Details.ParentUserID, formattedDate, Todate);
    }
  }, [Page, RowsPerPage, SortedBy]);

  useEffect(() => {
    var UserDetails = GetUserDetails();
    if (UserDetails != null) {
      if (ObjDisposition.IsAnalyze) {
        GetDisposition(UserDetails.ClientID, UserDetails.ParentUserID, null, null);
      }
    }
  }, [ObjDisposition.FromDate,ObjDisposition.ToDate,ObjDisposition.ColdCallerID,ObjDisposition.TagID,ObjDisposition.IsAnalyze])

  //get Cold calling recording  list
  const GetDisposition = (CID, UID, FormDate, Todate) => {
    SetIsLoading(true)
    var requestData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Field: SortField,
      SortBy: SortedBy,
      ClientID: CID == "" ? ClientID : CID,
      UserID: UID == "" ? UserID : UID,
      FromDate: ObjDisposition.IsAnalyze == true ? ObjDisposition.FromDate : FormDate,
      ToDate: ObjDisposition.IsAnalyze == true ? ObjDisposition.ToDate : Todate,
      ColdCallerID: ObjDisposition.IsAnalyze == true ? ObjDisposition.ColdCallerID : null,
      TagID:ObjDisposition.IsAnalyze == true ? ObjDisposition.TagID : null
    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/cold_calling_reporting/DispositionGet",
      method: "POST",
      data: requestData,
    });
    Row.then((response) => {
      if (response.data.StatusMessage = "SUCCESS") {
        if (response.data.PageData.length > 0) {
          SetDispositionData(response.data.PageData);
          SetRlen(response.data.TotalCount);
          SetFlen(response.data.TotalCount);
          SetCountPage(response.data.PageCount);
          SetIsLoading(false)
          setIsDataLoaded(true);

        }
        else {
          SetDispositionData([]);
          SetRlen(0);
          SetFlen(0);
          SetCountPage(0);
          SetIsLoading(false)
          setIsDataLoaded(true);

        }
      } else {
        SetDispositionData([]);
        SetRlen(0);
        SetFlen(0);
        SetCountPage(0);
        SetIsLoading(false)
        setIsDataLoaded(true);

      }
    });
  };



  //change Page
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };



  return (
    <>
      
      <div className="row px-3">
      </div>

      <div className="row px-3 ">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>

      </div>
      <div className="table-bordered mx-3">
        <TableContainer component={Paper} className='position-relative'>
        <StyleHeader isDataLoaded={isDataLoaded} />

        {
        IsLoading === true ?
          <div id="hideloding" className="loadingchart">
            <img src={loadingicon} />
          </div> : <></>
      }
          <Table className="table-ref" aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Disposition</TableCell>
                <TableCell>Count</TableCell>
                <TableCell>Percentage </TableCell>  
                </TableRow>
            </TableHead>
            <TableBody>

              {DispositionData?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (DispositionData.map((row, i) => (
                <React.Fragment key={i}>
                  <TableRow row={row}>
                  <TableCell>{row.Disposition}</TableCell>
                    <TableCell>{row.DispositionCount}</TableCell>
                    <TableCell>{row.DispositionCountRate}</TableCell>
                  </TableRow>
                </React.Fragment>
              )))}

            </TableBody>
          </Table>
        </TableContainer>
      </div>

     
      {Sflag ? (
            <div className="row px-3 pb-3">
              <Pagination
                component="div"
                count={CountPage}
                page={Page}
                onChange={PageChange}
                showFirstButton
                showLastButton
              />
              <div class="col dataTables_info">
                <p>
                  Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                  {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
                  entries (filtered from {Flen} total entries)
                </p>
              </div>
            </div>
          ) : (
            <div className="row px-3 pb-3">
              <div class="col dataTables_info">
                <p>
                  Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                  {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
                  entries
                </p>
              </div>
              <div class="col pageright">
                <Pagination
                  component="div"
                  count={CountPage}
                  page={Page}
                  onChange={HandleChangePage}
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          )}

      
    
    </>
  );
}
