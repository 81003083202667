import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Avatar } from '@material-ui/core';
import Pagination from "@material-ui/lab/Pagination";
const moment = require("moment");
import { history } from "../../../_helpers";

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Userimg from '../../../images/avatar/1.jpg';

import { GetUserDetails } from "../../../_helpers/Utility";
import Axios from "axios";
import { CommonConstants } from "../../../_constants/common.constants";

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export default function AccountAllTable() {
  const [open, setOpen] = React.useState(false);
  const [data, setdata] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [rlen, setrlen] = React.useState(0);
  const [flen, setflen] = React.useState(0);
  const [sflag, setsflag] = React.useState(false);
  const [countpage, setCountPage] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [sortField, setsortField] = React.useState("Name");
  const [sortedBy, setsortedBy] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const [headerlist, setHeaderlist] = React.useState([]);
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [Role,SetRole] = React.useState(null);
  useEffect(() => {
    var getaccountcatelist = GetUserDetails();
    if (getaccountcatelist != null) {
      setClientID(getaccountcatelist.ClientID);
      setUserID(getaccountcatelist.ParentUserID);
      SetRole(getaccountcatelist.Role)
    }
    getPartnersTableList()
  }, [ClientID, UserID])

  const getPartnersTableList = async () => {
    var str_in = {
      page: page,
      rowsPerPage: RowsPerPage,
      sort: true,
      field: sortField,
      sortby: sortedBy,
      search: search,
      type: "User",
      ClientID: ClientID,
      Role:Role,
      UserID: UserID,
      AccountType: "Partner"
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/GetAccountlist",
      method: "POST",
      data: str_in,
    });
    await rows1.then((result) => {
      setdata(result.data.pagedata);
      setRows(result.data.pagedata);
      setrlen(result.data.totalcount);
      setflen(result.data.totalcount);
      setCountPage(result.data.pagecount);
      setHeaderlist(result.data.accountheader);
    });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (checkS == true) {
      var str_in = {
        page: newPage,
        rowsPerPage: RowsPerPage,
        serchbox: false,
        archive: true,
        sort: true,
        field: sortField,
        sortby: sortedBy,
        type: "User",
        ClientID: ClientID,
        Role:Role,
        UserID: UserID,
        AccountType: "Partner"
      };
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/GetAccountlist",
        method: "POST",
        data: str_in,
      });
      rows1.then((result) => {
        setdata(result.data.pagedata);
        setRows(result.data.pagedata);
        setrlen(result.data.totalcount);
        setflen(result.data.totalcount);
        setCountPage(result.data.pagecount);
      });
    } else {
      var str_in = {
        page: newPage,
        rowsPerPage: RowsPerPage,
        serchbox: false,
        archive: false,
        sort: true,
        field: sortField,
        sortby: sortedBy,
        type: "User",
        ClientID: ClientID,
        Role:Role,
        UserID: UserID,
        AccountType: "Partner"
      };
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/accounts/GetAccountlist",
        method: "POST",
        data: str_in,
      });
      rows1.then((result) => {
        setdata(result.data.pagedata);
        setRows(result.data.pagedata);
        setrlen(result.data.totalcount);
        setflen(result.data.totalcount);
        setCountPage(result.data.pagecount);
      });
    }
  };

  const changeRowSelected = (event) => {
    SetRowsPerPage(event.target.value);
    setPage(1);
  };

  const requestSearch = () => {
    var searchedVal = document.getElementById("search").value;
    setPage(1);
    SetRowsPerPage(10);
    var searchbox;
    if (searchedVal == "") {
      searchbox = false;
      setsflag(false);
    } else {
      searchbox = true;
    }
    var str_in = {
      page: 1,
      ClientID: ClientID,
      UserID: UserID,
      rowsPerPage: RowsPerPage,
      sort: true,
      field: sortField,
      sortby: sortedBy,
      search: searchedVal,
      Role:Role,
      type: "User",
      searchbox: searchbox,
      AccountType: "Partner"
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/accounts/GetAccountlist",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      setdata(result.data.pagedata);
      setRows(result.data.pagedata);
      setrlen(result.data.totalcount);
      setflen(result.data.totalcount);
      setCountPage(result.data.pagecount);
    });
  };

  const viewpage = (id) => {
    history.push("/viewaccount", id);
  };

  return (
    <>
      <div className="row mt-4">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={changeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onChange={() => requestSearch()}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <input type="checkbox" />
              </TableCell>
              <TableCell></TableCell>
              {headerlist?.map((row) => (
                <TableCell key={row._id}>{row.ColumnName}</TableCell>
              ))}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((value1) => (
              <>
                <TableRow>
                  <TableCell>
                    <input type="checkbox" />
                  </TableCell>
                  <TableCell>
                    {" "}
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpen(!open)}
                    >
                      {open ? (
                        <i
                          class="fa fa-minus-circle minusl"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          class="fa fa-plus-circle plusbl"
                          aria-hidden="true"
                        ></i>
                      )}
                    </IconButton>
                  </TableCell>
                  {headerlist.map((row) => {
                    if (row.ColumnName == "Account Name") {
                      return <TableCell>{value1.AccountName}</TableCell>;
                    } else if (row.ColumnName == "Email Domains") {
                      return <TableCell>{value1.EmailDomins}</TableCell>;
                    } else if (row.ColumnName == "Website") {
                      return <TableCell>{value1.Website}</TableCell>;
                    } else if (row.ColumnName == "Address 1") {
                      return <TableCell>{value1.Address1}</TableCell>;
                    } else if (row.ColumnName == "Address 2") {
                      return <TableCell>{value1.Address2}</TableCell>;
                    } else if (row.ColumnName == "City") {
                      return <TableCell>{value1.City}</TableCell>;
                    } else if (row.ColumnName == "State") {
                      return <TableCell>{value1.State}</TableCell>;
                    } else if (row.ColumnName == "Zip") {
                      return <TableCell>{value1.Zip}</TableCell>;
                    } else if (row.ColumnName == "Country") {
                      return <TableCell>{value1.Country}</TableCell>;
                    } else if (row.ColumnName == "Account Category") {
                      return <TableCell>{value1.p1[0]?.Name}</TableCell>;
                    } else if (row.ColumnName == "Industry") {
                      return <TableCell>{value1.Industry}</TableCell>;
                    } else if (row.ColumnName == "Account Owner") {
                      return <TableCell>{value1.p3[0]?.Name}</TableCell>;
                    } else if (row.ColumnName == "Reason Unqualified") {
                      return <TableCell>{value1.p4[0]?.Reason}</TableCell>;
                    } else if (row.ColumnName == "Company Phone") {
                      return <TableCell>{value1.CompanyPhone}</TableCell>;
                    } else if (row.ColumnName == "Account Client Status") {
                      return <TableCell>{value1.p5[0]?.Name}</TableCell>;
                    } else if (row.ColumnName == "AccountType") {
                      return <TableCell>{value1.p2[0]?.FieldName}</TableCell>;
                    } else if (row.ColumnName == "Created Date") {
                      return (
                        <TableCell>
                          {moment(
                            new Date(row.CreatedDate).toDateString()
                          ).format("MM/DD/YYYY")}
                        </TableCell>
                      );
                    } else if (row.ColumnName == "Company Revenue") {
                      return <TableCell>{value1.CompanyRevenue}</TableCell>;
                    } else if (row.ColumnName == "Employee Count") {
                      return (
                        <TableCell>{value1.CompanyEmployeeCount}</TableCell>
                      );
                    } else if (row.ColumnName == "Total Call Count") {
                      return <TableCell>{value1.p1[0]?.Name}</TableCell>;
                    } else if (row.ColumnName == "Custom Account 1") {
                      return <TableCell>{0}</TableCell>;
                    } else if (row.ColumnName == "custom 1") {
                      return <TableCell>{0}</TableCell>;
                    } else {
                      return <TableCell></TableCell>;
                    }
                  })}

                  <TableCell>
                    <a
                      onClick={() => {
                        viewpage(value1._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i className="la flaticon-eye edit-icon"></i>
                    </a>
                    <a
                      onClick={() => {
                        DeleteClientName(value1._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i className="la flaticon-delete-1 delete-icon"></i>
                    </a>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                  >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      <Box margin={1} className="innertables">
                        <Table size="small" aria-label="purchases">
                          <TableHead></TableHead>
                          <TableRow>
                            <TableCell align="center"></TableCell>
                            <TableCell scope="row"></TableCell>
                            <TableCell
                              className="labeltds"
                              scope="row"
                            ></TableCell>
                            <TableCell
                              className="labeltds"
                              scope="row"
                            ></TableCell>
                            <TableCell>8 June 2021</TableCell>
                            <TableCell>12/15/2020</TableCell>
                          </TableRow>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      {sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={countpage}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {rlen == 0 ? 0 : (page - 1) * rowsPerPage + 1} to{" "}
              {page * rowsPerPage > rlen ? rlen : page * rowsPerPage} of {rlen}{" "}
              entries (filtered from {flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {rlen == 0 ? 0 : (page - 1) * rowsPerPage + 1} to{" "}
              {page * rowsPerPage > rlen ? rlen : page * rowsPerPage} of {rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={countpage}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
