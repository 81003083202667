import React from "react";
import Axios from "axios";
const moment = require("moment");
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import ClientLogin from "../../_components/user/ClientLogin/ClientLoginTable";
//import CollapsibleTable from '../_components/usertable';

import { CSVReader, CSVDownloader } from "react-papaparse";
import { CommonConstants } from "../../_constants/common.constants";
import { GetUserDetails } from "../../_helpers/Utility";
import { history } from "../../_helpers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class ClientLoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UserID: null,
      ClientID: null,
      ExportData: [],
      OldClient:false
    };
    this.ExportCsv = this.ExportCsv.bind(this);
  }

  componentDidMount() {
    var Details = GetUserDetails();
    if (Details != null) {
      this.setState({ ClientID: Details.ClientID });
      this.setState({ UserID: Details.ParentUserID });
    }
    this.ExportMethod(Details.ParentUserID);
  }

  ExportMethod(UID) {
    const InputParameters = {
      UserID: UID,
      OldClient:this.state.OldClient
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/client_login/ClientLoginExport",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      this.setState({ ExportData: Res.data?.Data });
    });
  }

  ExportCsv() {
    toast.success(<div className="toastsize">Data exported successfully.</div>);
  }

  //Reload page while delete perform
  UpdateFromChild = (value,OldClient) => {
    
    
    this.setState({OldClient:OldClient})
    if (value == true) {
      this.componentDidMount();
    }
  };
  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitle float-left px-0">Client Logins</h4>
                <div className="listing-li float-right padb-15">
                  <ul>
                  <CSVDownloader
                            className="px-0"
                            data={this.state.ExportData}
                            filename={"AllClients-Logins"}
                            bom={true}
                          >
                    <li>
                    
                      <a
                        onClick={this.ExportCsv}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-download"></i>
                        <span>
                         
                            Export
                        </span>
                      </a>
                    </li>
                          </CSVDownloader>
                  </ul>
                </div>
              </div>
            </div>

            <div class="paddcols px-4">
              <div className="row px-2">
                <div className="col">
                  <ClientLogin updateFromChild={this.UpdateFromChild}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedClientLoginPage = connect(
  mapState,
  actionCreators
)(ClientLoginPage);
export { connectedClientLoginPage as ClientLoginPage };
