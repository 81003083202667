import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { arrayMoveImmutable } from "array-move";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { event } from "jquery";
import Swal from "sweetalert2";

import { history } from "../../_helpers";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../_helpers/Utility";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import { CommonConstants } from "../../_constants/common.constants";
import loadingicon from "../../images/loading.gif";

toast.configure();

const SortableItem = SortableElement(({ value }) => (
  <li className="boxcorder">{value}</li>
));


const SortableList = SortableContainer(({ newItems }) => {
  return (
    <ul>
      {newItems.map((value, Index) =>
        value.IsStatic == true ? (
          <SortableItem
            id={Index}
            key={`item-${Index}`}
            index={Index}
            value={value.ColumnName}
          />
        ) : (
          <SortableItem
            id={Index}
            key={`item-${Index}`}
            index={Index}
            value={value.ColumnName}
          />
        )
      )}
    </ul>
  );
});
class CAccountCustomColumnPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      EditItems: [],
      newItems: [],
      Items: [],
      Page: {},
      IsChecked: true,
      ClientID: null,
      UserID: null,
      CUserID: null,
      Role:null,
      IsLoading:false,
      BtnDisabled:false,
    };
  }

  componentDidMount() {
    document.title = `Account Custom Column | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role
    }

    this.GetAccountCustomColumnList();
  }

//Get account custom data
  GetAccountCustomColumnList() {
    this.setState({IsLoading:true})
    var str_in = {
      Page: 1,
      RowsPerPage: 100,
      TableName: "AccountPage",
      Field: "OrderBy",
      Sortby: 1,
      Type: "User",
      ClientID: this.state.ClientID,
      Role:this.state.Role,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/accountcuatomcolumn/AccountCustomColumnList",
      method: "POST",
      data: str_in,
    });
    rows1.then((Result) => {
      const includeTrueArray = [];
      const includeFalseArray = [];
  
      Result.data.PageData.forEach((value) => {
        if (value.IsInclude) {
          includeTrueArray.push(value);
        } else {
          includeFalseArray.push(value);
        }
      });
  
      const sortedIncludeTrueArray = includeTrueArray
        .slice()
        .sort((a, b) => a.OrderBy - b.OrderBy);
  
      this.setState({
        Items: sortedIncludeTrueArray.concat(includeFalseArray),
        EditItems: Result.data.PageData,
        newItems: sortedIncludeTrueArray,
      });
      this.setState({IsLoading:false})

    });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      newItems: arrayMoveImmutable(this.state.newItems, oldIndex, newIndex),
    });
  };

  SaveOrder() {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to save custom columns.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
    let OrderBy = 0;
    for (let i = 0; i < this.state.newItems.length; i++) {
      this.state.newItems[i].OrderBy = OrderBy + 1;
      OrderBy++;
    }
    this.SaveData();
  } else if (result.dismiss === Swal.DismissReason.cancel) {
    Swal.fire("Cancelled", "Your data is safe :)", "error");
  }
    });
  }


//add item
  SaveData() {
    this.setState({IsLoading:true,BtnDisabled:true})

    this.state.newItems.map((changeitem) => {
      this.state.EditItems.map((EditItems) => {
        if (changeitem._id == EditItems._id) {
          EditItems.OrderBy = changeitem.OrderBy;
        }
      });
    });
    let str_in = {
      Role:this.state.Role,
      AccountcustomcolumnList: this.state.EditItems,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/accountcuatomcolumn/UpdateOrderAccountCustomcolumn",
      method: "POST",
      data: str_in,
    });
    rows1.then((Result) => {
      if (Result.data.StatusMessage == "SUCCESS") {
        toast.success(
          <div className="toastsize">
            Saleshive Account<br/>
            Column column order updated successfully.
          </div>
        );
        this.setState({IsLoading:false})
        history.push("/caccounts");

      } else {
    this.setState({IsLoading:false,BtnDisabled:false})

        toast.error(Result.data.Message);
      }
      //this.GetAccountCustomColumnList();
    });
  }

//update custom account
  UpdateAccountCustomList(e, value) {
    const isChecked = e.target.checked;
  
    this.setState((prevState) => {
      const updatedEditItems = prevState.EditItems.map((updateItems) => {
        if (value._id === updateItems._id) {
          return { ...updateItems, IsInclude: isChecked };
        }
        return updateItems;
      });
  
      let newItemsWithChange = prevState.newItems.slice(); 
  
      if (isChecked) {
        if (!newItemsWithChange.includes(value)) {
          newItemsWithChange.push(value);
        }
      } else {
        newItemsWithChange = newItemsWithChange.filter(
          (item) => item._id !== value._id
        );
      }
  
      const sortedIncludeTrueArray = newItemsWithChange
        .filter((newfilterarray) => newfilterarray.IsInclude === true)
        .sort((a, b) => a.OrderBy - b.OrderBy);
        
        const includeFalseArray = newItemsWithChange.filter(
          (newfilterarray) => newfilterarray.IsInclude === false
        );
        
        const originalarray = this.state.Items;
        
        const reordereddata = originalarray.filter((ele) =>
          includeFalseArray.some((ele2) => ele._id === ele2._id)
        );
          
  
      const finalSortedItems = sortedIncludeTrueArray.concat(reordereddata);
  
      return {
        newItems: finalSortedItems,
        EditItems: updatedEditItems,
      };
    });
  }

//back
  BackBtn() {
    history.push("/caccounts");
  }

//check toggle
  toggleChange = () => {
    this.setState({
      IsChecked: !this.state.IsChecked,
    });
  };

  render() {
    
    return (
      <>
      {
        this.state.IsLoading ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : <></>
      }
      
        <div className="adminmain">
          <Sidebar className="" />
          <div className="bodyhome">
            <div class="bg-white my-3">
              <div className="row borderbtnm px-4 d-flex align-items-center">
                <div className="col padd-0 px-3">
                  <h4 className="headertitlepad float-left px-1 ">
                    Account Custom Column
                  </h4>
                </div>
                <div class="pull-left  px-4">
                  <button
                    id="submit"
                    onClick={() => {
                      this.SaveOrder();
                    }}
                    disabled={this.state.BtnDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    href="javascript:void(0);"
                    class="btn btn-secondary"
                    onClick={() =>{this.BackBtn()}}
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>

              <div className="row px-5 mt-5">
                <div className="col col-lg-6 padd-0">
                  <div className="cardcolum">
                    <h4 className="headertitlepad float-left">All Column</h4>

                    <div class="boxsinput_group">
                      <div class="m-section">
                        <h3 class="m-section__heading">Fixed Column</h3>
                        <ul class="ddlist">
                      {this.state.Items
                        .filter(
                          (accountcolumn) => accountcolumn.IsStatic == true
                        )
                        .map((value, Index) => (
                          <li>
                            <div class="col-lg-6 row">
                              <label class="">
                                <input
                                  type="checkbox"
                                  id={Index}
                                  checked
                                  key={`item-${Index}`}
                                  index={Index}
                                />
                                {value.ColumnName}
                              </label>
                            </div>
                          </li>
                        ))}
                    </ul>
                      </div>

                      <div class="m-section">
                        <h3 class="m-section__heading">Custom Column</h3>
                        <ul class="ddlist">
                        {this.state.Items
                        .filter((value) => value.IsStatic !== true) // Exclude fixed columns
                        .map((value, Index) => (
                          <li key={`item-${Index}`}>
                            <div class="col-lg-6 row">
                              <label class="">
                                <input
                                  type="checkbox"
                                  id={Index}
                                  defaultChecked={value.IsInclude}
                                  onChange={(e) =>
                                    this.UpdateAccountCustomList(e, value)
                                  }
                                />
                                {value.ColumnName}
                              </label>
                            </div>
                          </li>
                        ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col col-lg-6 padd-0">
                  <div className="cardcolum">
                    <h4 className="headertitlepad float-left">
                      Column Ordering
                    </h4>

                    <div class="boxsinput_group">
                      <SortableList
                        newItems={this.state.newItems}
                        onSortEnd={this.onSortEnd}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCAccountCustomColumnPage = connect(
  mapState,
  actionCreators
)(CAccountCustomColumnPage);
export { connectedCAccountCustomColumnPage as CAccountCustomColumnPage };
