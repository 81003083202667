import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from "../../_components/user/footer/footer";
import Pendinginvites from '../../_components/user/PendingInvites/PendingInvitesTable';
//import CollapsibleTable from '../_components/usertable';



class PendingInvitesPage extends React.Component {
   

    render() {
        return (
            <>
                <div className="adminmain"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitle float-left pl-0">Pending Invites</h4>
                        </div>
                    </div>

                   

                    <div class="paddcols px-4 pt-4"> 
                        <div className="row px-2">
                            <div className="col">
                                    <Pendinginvites /> 
                            </div>
                        </div>    
                    </div> 
                </div> 
                </div>
            <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedPendingInvitesPage = connect(mapState, actionCreators)(PendingInvitesPage);
export { connectedPendingInvitesPage as PendingInvitesPage };