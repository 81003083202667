import React from "react";
import { connect } from "react-redux";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import Reasonunqualified from "../../../_components/user/AccountClientStatus/ReasonUnqualified/ReasonUnqualified";
import { history } from "../../../_helpers/history";

class AccountReasonUnqualifiedPage extends React.Component {
  AddUnqualifiedReason() {
    history.push("/addaccountreasonunqualified");
  }

  SetResasonOrderreason() {
    history.push("/setorderaccount");
  }
  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" />  
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitlenop py-4 float-left px-1 mt-1 mb-0">
                  Account Reason Unqualified
                </h4>
              </div> 
              <div className="col">
                <div className="listing-li float-right pb-3 pt-2">
                  <ul>
                    <li>
                      <a onClick={this.SetResasonOrderreason.bind(this)}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 flaticon-settings"></i>
                        <span>Set Order</span>
                      </a>
                    </li>
                    <li>
                      <a
                      
                        onClick={this.AddUnqualifiedReason.bind(this)}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> 
          
            <div class="paddcols px-0">
              <div className="row px-4">
                <div className="col px-4"> 
                    <Reasonunqualified /> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer /> 
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAccountReasonUnqualifiedPage = connect(
  mapState,
  actionCreators
)(AccountReasonUnqualifiedPage);
export { connectedAccountReasonUnqualifiedPage as AccountReasonUnqualifiedPage };
