import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../../_actions';
import Sidebar from '../../../_components/user/NavigationSidebar/Sidebar';
import Axios from 'axios';
import { CommonConstants } from '../../../_constants/common.constants';
import {arrayMoveImmutable} from 'array-move';
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
import { history } from '../../../_helpers';
import { toast } from 'react-toastify';
import{GetUserDetails} from '../../../_helpers/Utility'
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const SortableItem = SortableElement(({value}) =>
  <li className="boxcorder">{value}</li>
);
const SortableList = SortableContainer(({items}) => {
  return (
    <ul>
      {items.map((value, Index) => (
        
        <SortableItem id={Index} key={`item-${Index}`} index={Index} value={value.Status} />
      ))}
    </ul>
  );
}); 

class PotentialMeetingStatusOrderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items:[],
      pager: {},
      pageOfItems: [],
      files: [],
      ClientID:null ,
      UserID:null,
  };
  this.saveOrder = this.saveOrder.bind(this);
  this.backbtn = this.backbtn.bind(this);
  }

    componentDidMount(){
      var Details=GetUserDetails();
      if(Details != null)
      {
          this.state.ClientID= (Details.ClientID);
          this.state.UserID=(Details.ChildUserID);
        
      }
        this.GetEngagementStatusOrder();
      }
      GetEngagementStatusOrder(){
        var str_in = {
          "page": 1,
          "rowsPerPage": 100,
          "field": "OrderBy",
          "sortby": 1,
          "type": "User",
          "ClientID":this.state.ClientID
        }
        console.log(str_in);
        const rows1 = Axios({
          url: CommonConstants.MOL_APIURL + "/potentialmeetingstatus/PotentialMeetingStatusList",
          method: "POST",
          data: str_in
        });
        rows1.then(result => {
          console.log(result)
          this.setState({items:result.data.pagedata})
      });
      }
        
      onSortEnd = ({oldIndex, newIndex}) => {
        this.setState({
          items: arrayMoveImmutable(this.state.items, oldIndex, newIndex),
        });
    
      };

      saveOrder(){
        let OrderBy =0;
        for(let i=0;i<this.state.items.length;i++){
          this.state.items[i].OrderBy = OrderBy+1;
          OrderBy++;
        }
        this.savedata();
    
      }

      savedata(){
        let str_in = {
          "PotentialMeetingStatusList": this.state.items
        }
        console.log(str_in);
        const rows1 = Axios({
          url: CommonConstants.MOL_APIURL + "/potentialmeetingstatus/PotentialMeetingStatusSetOrder",
          method: "POST",
          data: str_in
        });
        rows1.then(result => {
          if (result.data.StatusMessage == "SUCCESS") {
            toast.success("Potential meeting status order updated successfully.", "Engagement status order");
            history.push('/potentialmeetingstatus')
        }
        else {
            toast.error(res.data.Message)
        }
          this.GetEngagementStatusOrder();
        });
    
      }

     
  backbtn(){
        history.push('/potentialmeetingstatus')
    }

    render() {
        return (
            <>
                <div className="adminmain"> 
                <Sidebar className=""/> 
                <div className="bodyhome"> 
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitlepad float-left">Potential Meeting Status Order</h4>
                        </div> 
                    </div> 
                    <div class="paddcols">   
                      <div className="row pt-4">  
                          <div className="col-xl-12 offset-xl-12 p-0">
                            <span className="alertinfo">
                                &nbsp;<b>NOTE</b> : Please drag &amp; drop item to change order of client status and press save button.
                            </span>

                            <div class="spacupgroup">
                                <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />
                            </div>
                          </div>   
                        </div> 
                    </div>

                    <div class="row py-4 px-3">
                          <div class="pull-left">
                              <a id="submit" onClick={()=>{this.saveOrder()}} class="btn btn-primary btn-lightgreen mr-1"><i class="la la-save"></i> Save order</a>
                              <a id="backtolist" href="javascript:void(0);" class="btn btn-secondary"><i class="la la-arrow-circle-left"></i> Back</a>
                          </div>
                      </div> 
                </div> 
              </div>
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedPotentialMeetingStatusOrderPage = connect(mapState, actionCreators)(PotentialMeetingStatusOrderPage);
export { connectedPotentialMeetingStatusOrderPage as PotentialMeetingStatusOrderPage };