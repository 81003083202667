import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
//import CallHistory from '../_components/coldcalling/callhistory';
//import CollapsibleTable from '../_components/usertable';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import ReactApexchart from "react-apexcharts";
import { CommonConstants } from "../../../_constants/common.constants";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";

const moment = require("moment");
const AccountState = {
  series: [
    {
      name: "Sent",
      color: "#360947",
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 56, 45, 65],
    },
    {
      name: "Responses",
      color: "#F8BF58",
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 40, 115, 110],
    },
    {
      name: "Meetings",
      color: "#ffb963",
      data: [0, 85, 0, 98, 87, 105, 10, 114, 5, 40, 50, 110],
    },
  ],
  options: {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
          show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "04/01 - 04/04",
        "04/05 - 04/11",
        "04/12 - 04/18",
        "04/19 - 04/25",
        "04/26 - 05/02",
        "05/03",
        "05/10",
        "05/17",
        "05/24",
        "06/07",
        "06/14",
        "06/21",
      ],
    },
    yaxis: {
      title: {
        text: "$ (thousands)",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " thousands";
        },
      },
    },
  },
};

class EditOpportunitiesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
      statusAvailable: null,
      showing: true,
      meetingsource: [],
      Statusfield: [],
      potentialowner: [],
      potentialstatus: [],
      allres: [],
      meetingsource1: [],
      meetingstatus1: [],
      timezone1: [],
      updateid: [],
      meetingseniority1: [],
      sourcemeeting: [],
      statusmeeting: [],
      defaultselect: [],
      defaultselectsource: [],
      timezone: [],
      data: [],
      rows: [],
      rlen: 0,
      flen: 0,
      countpage: 0,
      data1: [],
      rows1: [],
      rlen1: 0,
      flen1: 0,
      countpage1: 0,
      ClientID: null,
      UserID: null,
      CUserID: null,
      page: 1,
      rowsPerPage: 10,
      search: "",
      sortField: "Name",
      sortedBy: 1,
      sflag: false,
      page1: 1,
      rowsPerPage1: 10,
      search1: "",
      sortField1: "Name",
      sortedBy1: 1,
      sflag1: false,
    };
    this.backbtn = this.backbtn.bind(this);
    // this.editbtn = this.editbtn.bind(this);
    this.editspage = this.editspage.bind(this);
    this.saveupdatebtn = this.saveupdatebtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
    this.DeleteClientName = this.DeleteClientName.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangesource = this.handleChangesource.bind(this);
    this.handleChangetimezone = this.handleChangetimezone.bind(this);
    this.handleChangeseniority = this.handleChangeseniority.bind(this);
    this.handleChangestatus = this.handleChangestatus.bind(this);

    this.EditOpportunitiesMeetingstatus();
    // this.MeetingHistoryStatus();
  }
  componentDidMount() {
    this.GetOpportunitiesmeeting();
    var Details = GetUserDetails();

    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
    }
  }

  fromValidation() {
    let formIsValid = true;
    let fields = this.state.fields;
    let errors = {};
    if (!fields["FirstName"]) {
      formIsValid = false;
      errors["FirstName"] = "Please Enter FirstName";
    }

    if (this.state.statusAvailable != null) {
      formIsValid = false;
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.FirstName != "") {
      // this.state.errors.FirstName = null;
      // this.state.statusAvailable = null;
      this.CheckExistPotentialMeetingName(e.target.value);
    } else {
      // this.state.statusAvailable = null;
      // this.state.errors.FirstName = null;
    }
  }

  CheckExistPotentialMeetingName(FirstName) {
    var str_in = {
      ClientID: this.state.ClientID,
      FirstName: FirstName,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/opportunities/OpportunitiesMeetingStatusExists",
      method: "POST",
      data: str_in,
    }).then((resdata) => {
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.data.length > 0) {
          this.setState({ statusAvailable: resdata.data.data });
        }
      }
    });
  }

  EditOpportunitiesMeetingstatus() {
    const id = this.props.location.state;
    this.setState({ id: id });

    // 
    var str_in = {
      id: id,
    };

    Axios({
      url:
        CommonConstants.MOL_APIURL + "/opportunities/OpportunitiesMeetingByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
      this.setState({ allres: [res.data] });
      this.setState({ updateid: res.data._id });
      document.getElementById("FirstName").value = res.data.FirstName;
      document.getElementById("LastName").value = res.data.LastName;
      document.getElementById("Title").value = res.data.Title;
      document.getElementById("Company").value = res.data.Company;
      document.getElementById("Email").value = res.data.Email;
      document.getElementById("LinkedInUrl").value = res.data.LinkedInUrl;
      document.getElementById("MeetingSourceID").value =
        res.data.MeetingSourceID;
      document.getElementById("MeetingOwnerID").value = res.data.MeetingOwnerID;
      document.getElementById("DirectPhone").value = res.data.DirectPhone;
      document.getElementById("DirectPhoneExt").value = res.data.DirectPhoneExt;
      document.getElementById("CompanyPhone").value = res.data.CompanyPhone;

      //   document.getElementById("Website").value=res.data.Website;
      document.getElementById("MeetingStatusID").value = res.data.StatusID;
      document.getElementById("MeetingTimeZoneID").value = res.data.TimeZoneID;
      //   document.getElementById("EmailAccountID").value=res.data.MeetingOwnerID;
      document.getElementById("SeniorityMeetingID").value =
        res.data.SeniorityID;
      document.getElementById("Industry").value = res.data.Industry;
      document.getElementById("ContractValue").value = res.data.ContractValue;
      this.setState({ defaultselectsource: res.data.MeetingSourceID });
      this.setState({ defaultselecttimezone: res.data.TimeZoneID });
      this.setState({ defaultselectseniority: res.data.SeniorityID });
      this.setState({ defaultselectstatus: res.data.StatusID });
      // 
      var data7 = {
        ClientID: this.state.ClientID,
        MeetingStatusID: res.data.StatusID,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/opportunities/findOpportunitiesMeetingStatus",
        method: "POST",
        data: data7,
      }).then((resstatus) => {
        //  
        //  
        this.setState({ Statusfield: resstatus.data.BookedStatus[0].Status });
      });

      //meetingSource
      var str_in1 = {
        MeetingSourceID: res.data.MeetingSourceID,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/opportunities/findOpportunitiesMeetingSource",
        method: "POST",
        data: str_in1,
      }).then((resmeeting) => {
        this.setState({ sourcemeeting: resmeeting.data });
        // 
      });

      //MeetingTimezone
      var str_in4 = {
        TimeZoneID: res.data.TimeZoneID,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/opportunities/GetTimezoneMeeting",
        method: "POST",
        data: str_in4,
      }).then((timezoneres) => {
        this.setState({ timezone: timezoneres.data.timezone });
        // 
      });

      // notes
      // 
      this.setState({ meetingid: res.data.EventID });

      var str_in2 = {
        ClientID: this.state.ClientID,
        EventID: res.data.EventID,
        page: this.state.page,
        rowsPerPage: this.state.rowsPerPage,
        sort: true,
        field: this.state.sortField,
        sortby: this.state.sortedBy,
        search: this.state.search,
        type: "User",
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/opportunities/GetOpportunitiesMeetingNotes",
        method: "POST",
        data: str_in2,
      }).then((resnotes) => {
        // 

        this.setState({ data: resnotes.data.pagedata });
        this.setState({ rows: resnotes.data.pagedata });
        this.setState({ rlen: resnotes.data.totalcount });
        this.setState({ flen: resnotes.data.totalcount });
        this.setState({ countpage: resnotes.data.pagecount });
        //
      });

      // Meeting status history
      var str_in3 = {
        ClientID: this.state.ClientID,
        EventID: res.data.EventID,
        page: this.state.page,
        rowsPerPage: this.state.rowsPerPage,
        sort: true,
        field: this.state.sortField,
        sortby: this.state.sortedBy,
        search: this.state.search,
        type: "User",
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/opportunities/GetOpportunitiesMeetingStatusHistory",
        method: "POST",
        data: str_in3,
      }).then((reshistory) => {
        

        this.setState({ data1: reshistory.data.pagedata });
        this.setState({ rows1: reshistory.data.pagedata });
        this.setState({ rlen1: reshistory.data.totalcount });
        this.setState({ flen1: reshistory.data.totalcount });
        this.setState({ countpage1: reshistory.data.pagecount });
        this.setState({ statusmeeting: reshistory.data.meetingstatus });
        //
      });
    });
  }

  GetOpportunitiesmeeting() {
    var Details = GetUserDetails();
    
    var str_in = {
      ClientID: Details.ClientID,
    };
    
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/opportunities/FindMeetingAllStatus",
      method: "POST",
      data: str_in,
    });
    rows.then((result) => {
      
      this.setState({
        // potentialowner: result.data.potentialowner,
        meetingsource1: result.data.meetingsource,
        meetingstatus1: result.data.meetingstatus,
        timezone1: result.data.meetingtimezone,
        meetingseniority1: result.data.meetingseniority,
      });

      //  return result.data
    });
  }

  backbtn() {
    history.push("/opportunities");
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    var str_in = {
      ClientID: this.state.ClientID,
      EventID: this.state.meetingid,
      page: newPage,
      rowsPerPage: this.state.rowsPerPage,
      serchbox: false,
      sort: true,
      field: this.state.sortField,
      sortby: this.state.sortedBy,
      type: "User",
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/opportunities/GetOpportunitiesMeetingNotes",
      method: "POST",
      data: str_in,
    });
    rows1.then((resnotes) => {
      // 
      this.setState({ data: resnotes.data.pagedata });
      this.setState({ rows: resnotes.data.pagedata });
      this.setState({ rlen: resnotes.data.totalcount });
      this.setState({ flen: resnotes.data.totalcount });
      this.setState({ countpage: resnotes.data.pagecount });
      
    });
  };

  //Search
  requestSearch = () => {
    var searchedVal = document.getElementById("search").value;
    this.setState({ page: 1 });
    this.setState({ rowsPerPage: 10 });
    var searchbox;
    if (searchedVal == "") {
      searchbox = false;
      this.setState({ sflag: false });
    } else {
      searchbox = true;
    }
    var str_in = {
      ClientID: this.state.ClientID,
      EventID: this.state.meetingid,
      page: 1,
      rowsPerPage: this.state.rowsPerPage,
      sort: true,
      field: this.state.sortField,
      sortby: this.state.sortedBy,
      search: searchedVal,
      type: "User",
      searchbox: searchbox,
    };
    
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/opportunities/GetOpportunitiesMeetingNotes",
      method: "POST",
      data: str_in,
    });
    rows1.then((resnotes) => {
      this.setState({ data: resnotes.data.pagedata });
      this.setState({ rows: resnotes.data.pagedata });
      this.setState({ rlen: resnotes.data.totalcount });
      this.setState({ flen: resnotes.data.totalcount });
      this.setState({ countpage: resnotes.data.pagecount });
      
    });
  };

  //Delete notes details
  DeleteClientName = (id) => {
    
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete opportunities Notes Meeting.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          _id: id,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: this.state.CUserID,
        };
        
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/opportunities/DeleteOpportunitiesNotesMeeting",
          method: "POST",
          data: data,
        }).then((res) => {
          
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Delete opportunities Notes Meeting successfully.",
                "success"
              );
              history.push("/opportunities");
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };
  chengeRowSelected = (event) => {
    

    this.setState({ rowsPerPage: event.target.value });
    this.setState({ page: 1 });
 

    // startpage("search", 1, event.target.value);
  };

  //notes

  fromValidationNotes() {
    let formIsValid = true;
    let fileds = this.state.fields;
    let errors = {};
    if (!fileds["Title"]) {
      formIsValid = false;
      errors["Title"] = "Please Enter Title";
    }

    if (this.state.statusAvailable != null) {
      formIsValid = false;
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChangeNotes(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.Title != "") {
      this.state.errors.Title = null;
      this.state.statusAvailable = null;
      this.CheckExistPotentialMeetingTitle(e.target.value);
    } else {
      this.state.statusAvailable = null;
      this.state.errors.Title = null;
    }
  }

  CheckExistPotentialMeetingTitle(Title) {
    var str_in = {
      ClientID: this.state.ClientID,
      Title: Title,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/opportunities/OpportunitiesNotesStatusExists",
      method: "POST",
      data: str_in,
    }).then((resdata) => {
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.data.length > 0) {
          this.setState({ statusAvailable: resdata.data.data });
        }
      }
    });
  }

  savebtn() {
    var Title = document.getElementById("Title1").value;
    var Notes = document.getElementById("Notes").value;

    if (
      Title == undefined ||
      Notes == undefined ||
      this.state.meetingid == null
    ) {
      
    } else {
      // var emailData = EmailTemplete.userEmail();

      var data = {
        Title: Title,
        Note: Notes,
        IsDeleted: false,
        MeetingID: this.state.meetingid,
        ClientID: this.state.ClientID,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
        LastUpdatedBy: null,
        LastUpdatedDate: null,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/opportunities/AddOpportunitiesNotesMeeting",
        method: "POST",
        data: data,
      }).then((res) => {
        
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            "opportunities meeting Status add successfully.",
            "Add opportunities meeting Status"
          );
          history.push("/opportunities");
          
        } else {
          toast.error(res.data.Message);
        }
      });
    }
  }

  editspage(id) {
    this.setState({ id: id });
    

    var str_in = {
      id: id,
    };
    
    Axios({
      url: CommonConstants.MOL_APIURL + "/opportunities/OpportunitiesNotesByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
      
      // if (res.data.StatusMessage == "SUCCESS") {
      document.getElementById("Title1").value = res.data.Title;
      document.getElementById("Notes").value = res.data.Note;
    });
  }

  saveupdatebtn() {
    var Title = document.getElementById("Title1").value;
    var Notes = document.getElementById("Notes").value;

    if (Title == undefined || Notes == undefined) {
      
    } else {
      // var emailData = EmailTemplete.userEmail();

      var data = {
        _id: this.state.id,
        Title: Title,
        Note: Notes,
        LastUpdatedBy: this.state.CUserID,
        LastUpdatedDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/opportunities/OpportunitiesNotesMeetingUpdate",
        method: "POST",
        data: data,
      }).then((res) => {
        
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            "Potential meeting Status add successfully.",
            "Add Potential meeting Status"
          );
          history.push("/potentialmeeting");
          
        } else {
          toast.error(res.data.Message);
        }
      });
    }
  }
  //meeting history

  requestSearch1() {
    var searchedVal1 = document.getElementById("search1").value;
    this.setState({ page1: 1 });
    this.setState({ rowsPerPage1: 10 });
    var searchbox;
    if (searchedVal1 == "") {
      searchbox = false;
      this.setState({ sflag1: false });
    } else {
      searchbox = true;
    }
    var str_in = {
      ClientID: this.state.ClientID,
      EventID: this.state.meetingid,
      page: 1,
      rowsPerPage: this.state.rowsPerPage1,
      sort: true,
      field: this.state.sortField1,
      sortby: this.state.sortedBy1,
      search: searchedVal1,
      type: "User",
      searchbox: searchbox,
    };
    
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/opportunities/GetOpportunitiesMeetingStatusHistory",
      method: "POST",
      data: str_in,
    });
    rows1.then((resnotes) => {
      this.setState({ data1: resnotes.data.pagedata });
      this.setState({ rows1: resnotes.data.pagedata });
      this.setState({ rlen1: resnotes.data.totalcount });
      this.setState({ flen1: resnotes.data.totalcount });
      this.setState({ countpage1: resnotes.data.pagecount });
      
    });
  }

  chengeRowSelected1 = (event) => {
    

    this.setState({ rowsPerPage1: event.target.value });
    this.setState({ page1: 1 });

    // startpage("search", 1, event.target.value);
  };

  savebtn() {
    var FirstName = document.getElementById("FirstName").value;
    var LastName = document.getElementById("LastName").value;
    var Title = document.getElementById("Title").value;
    var Company = document.getElementById("Company").value;
    var Email = document.getElementById("Email").value;
    var LinkedInUrl = document.getElementById("LinkedInUrl").value;
    var MeetingSourceID = document.getElementById("MeetingSourceID").value;
    var MeetingOwnerID = document.getElementById("MeetingOwnerID").value;
    var DirectPhone = document.getElementById("DirectPhone").value;
    var DirectPhoneExt = document.getElementById("DirectPhoneExt").value;
    var CompanyPhone = document.getElementById("CompanyPhone").value;
    var Website = document.getElementById("Website").value;
    var MeetingStatusID = document.getElementById("MeetingStatusID").value;
    var MeetingTimeZoneID = document.getElementById("MeetingTimeZoneID").value;
    // var EmailAccountID = document.getElementById("EmailAccountID").value;
    var SeniorityMeetingID =
      document.getElementById("SeniorityMeetingID").value;
    var Industry = document.getElementById("Industry").value;
    var ContractValue = document.getElementById("ContractValue").value;

    if (
      FirstName == undefined ||
      LastName == undefined ||
      Title == undefined ||
      Company == undefined ||
      Email == undefined ||
      LinkedInUrl == undefined ||
      MeetingSourceID == undefined ||
      MeetingOwnerID == undefined ||
      DirectPhone == undefined ||
      DirectPhoneExt == undefined ||
      CompanyPhone == undefined ||
      Website == undefined ||
      MeetingStatusID == undefined ||
      MeetingTimeZoneID == undefined ||
      SeniorityMeetingID == undefined ||
      Industry == undefined ||
      ContractValue == undefined
    ) {
      
    } else {
      // 
      var data = {
        _id: this.state.updateid,
        FirstName: FirstName,
        LastName: LastName,
        Status: this.state.Statusfield,
        Email: Email,
        Title: Title,
        Company: Company,
        LinkedInUrl: LinkedInUrl,
        MeetingOwnerID: MeetingOwnerID,
        MeetingSourceID: MeetingSourceID,
        DirectPhone: DirectPhone,
        DirectPhoneExt: DirectPhoneExt,
        CompanyPhone: CompanyPhone,
        Website: Website,
        MeetingStatusID: MeetingStatusID,
        MeetingTimeZoneID: MeetingTimeZoneID,
        SeniorityMeetingID: SeniorityMeetingID,
        Industry: Industry,
        ContractValue: ContractValue,
        LastUpdatedBy: this.state.CUserID,
        LastUpdatedDate: new Date(),
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/opportunities/OpportunitiesUpdate",
        method: "POST",
        data: data,
      }).then((res) => {
        
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            "opportunities meeting Status update successfully.",
            "Update opportunities meeting Status"
          );
          history.push("/opportunities");
          
        } else {
          toast.error(res.data.Message);
        }
      });
    }
  }
  // To set default
  handleChangesource(e) {
    this.setState({ defaultselectsource: e.target.defaultselect });
  }
  handleChangetimezone(e) {
    this.setState({ defaultselecttimezone: e.target.defaultselecttimezone });
  }
  handleChangeseniority(e) {
    this.setState({ defaultselectseniority: e.target.defaultselectseniority });
  }
  handleChangestatus(e) {
    this.setState({ defaultselectstatus: e.target.defaultselectstatus });
  }

  render() {
    return (
      <>
        {/* EDIT AND NOTES PAGE  */}
        <div className="adminmain"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row ">
                <div className="col-md-4 col-sm-4 padd-0 d-flex align-items-center">
                  <h3 className=" float-left addpotential_header mb-0">
                    Edit Potential Meeting
                  </h3>
                </div>
                <div className="col-md-8 col-sm-8 pb-4 text-right pt-3">
                  <a
                    className="btn btngroup1 m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air mr-1"
                    onClick={this.savebtn}
                  >
                    <i class="la la-save"></i>
                    <span className="Mobile_button">Save</span>
                  </a>

                  <a
                    className="btn btngroup2 m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                    onClick={this.backbtn}
                  >
                    <i class="la la-arrow-circle-left"></i>
                    <span className="Mobile_button">Back</span>
                  </a>
                </div>
              </div>
              
            <div className="paddcols">
              <div className="col-md-4 col-sm-4 pt-4 padd-0 d-flex align-items-center">
                <h3 className=" float-left addpotential_header">
                  Contact Info
                </h3>
              </div>
              <div className="row pt-4">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      First Name
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="FirstName"
                        name="FirstName"
                        type="text"
                        onChange={this.handleChange.bind(this, "FirstName")}
                        value={this.state.fields["FirstName"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Last Name
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="LastName"
                        name="LastName"
                        type="text"
                        onChange={this.handleChange.bind(this, "LastName")}
                        value={this.state.fields["LastName"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Title
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Title"
                        name="Title"
                        type="text"
                        onChange={this.handleChange.bind(this, "Title")}
                        value={this.state.fields["Title"]}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Company"
                        name="Company"
                        type="text"
                        onChange={this.handleChange.bind(this, "Company")}
                        value={this.state.fields["Company"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Email
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid email."
                        data-val-remote="Email already exist."
                        data-val-remote-additionalfields="*.Email,*.PageName,*.CampaignID,*.ClientID,*.UserID"
                        data-val-remote-url="/Utility/IsProspectEmailExistsAndBlacklist"
                        data-val-required="Please enter email."
                        id="Email"
                        name="Email"
                        type="text"
                        onChange={this.handleChange.bind(this, "Email")}
                        value={this.state.fields["Email"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      LinkedIn URL
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="LinkedInUrl"
                        name="LinkedInUrl"
                        type="text"
                        onChange={this.handleChange.bind(this, "LinkedInUrl")}
                        value={this.state.fields["LinkedInUrl"]}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Direct Phone
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid direct phone"
                        data-val-regex-pattern="^[0-9]*$"
                        id="DirectPhone"
                        name="DirectPhone"
                        type="text"
                        onChange={this.handleChange.bind(this, "DirectPhone")}
                        value={this.state.fields["DirectPhone"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Direct Phone Ext
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid direct phone ext"
                        data-val-regex-pattern="^[0-9]*$"
                        id="DirectPhoneExt"
                        name="DirectPhoneExt"
                        type="text"
                        onChange={this.handleChange.bind(
                          this,
                          "DirectPhoneExt"
                        )}
                        value={this.state.fields["DirectPhoneExt"]}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company Phone
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid company phone"
                        data-val-regex-pattern="^[0-9]*$"
                        id="CompanyPhone"
                        name="CompanyPhone"
                        type="text"
                        onChange={this.handleChange.bind(this, "CompanyPhone")}
                        value={this.state.fields["CompanyPhone"]}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Website
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        id="Website"
                        name="Website"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Account Name
                    </label>
                    <div className="col-lg-7">
                      <label></label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 pt-4 padd-0 d-flex align-items-center">
                <h3 className=" float-left addpotential_header">
                  Meeting Info
                </h3>
              </div>
              <div className="row  ">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Meeting Status:
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field ContactSourceID must be a number."
                        id="MeetingStatusID"
                        name="MeetingStatusID"
                        value={this.state.defaultselectstatus}
                        onChange={this.handleChangestatus}
                      >
                        <option value="">--Select--</option>
                        {this.state.meetingstatus1.map((value) => (
                          <option value={value.MeetingStatusID}>
                            {value.Status}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Meeting Source:
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field ContactSourceID must be a number."
                        id="MeetingSourceID"
                        name="MeetingSourceID"
                        value={this.state.defaultselectsource}
                        onChange={this.handleChangesource}
                      >
                        <option value="">--Select--</option>
                        {this.state.meetingsource1.map((value) => (
                          <option value={value.MeetingSourceID}>
                            {value.Name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Meeting Set Date :
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="date2"
                        name="date2"
                        placeholder="Goal"
                        type="date"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Meeting Set By:
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field ContactOwnerID must be a number."
                        id="MeetingOwnerID"
                        name="MeetingOwnerID"
                      >
                        <option value="">--Select--</option>
                        {this.state.potentialowner.map((value) => (
                          <option value={value.MeetingOwnerID}>
                            {value.Name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Meeting Date:
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="date2"
                        name="date2"
                        placeholder="Goal"
                        type="date"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Meeting Time Zone
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field ContactOwnerID must be a number."
                        id="MeetingTimeZoneID"
                        name="MeetingTimeZoneID"
                        value={this.state.defaultselecttimezone}
                        onChange={this.handleChangetimezone}
                      >
                        <option value="">--Select--</option>
                        {this.state.timezone1.map((value) => (
                          <option value={value.CommonFieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Owner:
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field ContactOwnerID must be a number."
                        id="MeetingOwnerID"
                        name="MeetingOwnerID"
                      >
                        <option value="">--Select--</option>
                        {this.state.potentialowner.map((value) => (
                          <option value={value.MeetingOwnerID}>
                            {value.Name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Reminder Email Account:
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field ClientPOCID must be a number."
                        id="ClientPOCID"
                        name="ClientPOCID"
                      >
                        <option value="">--Select--</option>
                        <option value="1760">Billing POC</option>
                        <option value="1761">General POC</option>
                        <option value="1762">Main POC</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Contract Value :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="ContractValue"
                        name="ContractValue"
                        type="text"
                        onChange={this.handleChange.bind(this, "ContractValue")}
                        value={this.state.fields["ContractValue"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Industry :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Industry"
                        name="Industry"
                        type="text"
                        onChange={this.handleChange.bind(this, "Industry")}
                        value={this.state.fields["Industry"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Account ID :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="ExternalAccountID"
                        name="ExternalAccountID"
                        type="text"
                        onChange={this.handleChange.bind(
                          this,
                          "ExternalAccountID"
                        )}
                        value={this.state.fields["ExternalAccountID"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Contact ID :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="ExternalContactID"
                        name="ExternalContactID"
                        type="text"
                        onChange={this.handleChange.bind(
                          this,
                          "ExternalContactID"
                        )}
                        value={this.state.fields["ExternalContactID"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Field 1 :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="ExternalField1"
                        name="ExternalField1"
                        type="text"
                        onChange={this.handleChange.bind(
                          this,
                          "ExternalField1"
                        )}
                        value={this.state.fields["ExternalField1"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Field 2 :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="ExternalField2"
                        name="ExternalField2"
                        type="text"
                        onChange={this.handleChange.bind(
                          this,
                          "ExternalField2"
                        )}
                        value={this.state.fields["ExternalField2"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Field 3 :
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="ExternalField3"
                        name="ExternalField3"
                        type="text"
                        onChange={this.handleChange.bind(
                          this,
                          "ExternalField3"
                        )}
                        value={this.state.fields["ExternalField3"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center"></div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Seniority :
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field SeniorityMeetingID must be a number."
                        id="SeniorityMeetingID"
                        name="SeniorityMeetingID"
                        value={this.state.defaultselectseniority}
                        onChange={this.handleChangeseniority}
                      >
                        <option value="">--Select--</option>
                        {this.state.meetingseniority1.map((value) => (
                          <option value={value.CommonFieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 viewboxsinput_group">
                  <div className="col-lg-6 boxsinput_group">
                    <label class="">
                      <input type="checkbox" className="" />
                      <span className="pl-2">Send Reminder</span>
                    </label>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Call Recording
                    </label>
                    <div className="col-lg-7">
                      <input
                        type="file"
                        id="uploadRecording"
                        accept=".mp3"
                        style={{ outline: "none" }}
                        //     onChange={this.handleUpload.bind(this, "coverimage")}
                        // className="{{ $errors->has('email') ? 'alert alert-danger' : '' }}"
                        autocomplete="off"
                      />
                      <label
                        id="recFileName"
                        class="m--hide break-content"
                      ></label>
                      <input
                        id="RecordingFilePath"
                        name="RecordingFilePath"
                        type="hidden"
                        value=""
                      ></input>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-4 pt-4 padd-0 d-flex align-items-center">
                <h3 className=" float-left addpotential_header">
                  Status Dates
                </h3>
              </div>
              <div className="row ">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Opportunity Date
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="OpportunityDate"
                        name="OpportunityDate"
                        type="text"
                        onChange={this.handleChange.bind(
                          this,
                          "OpportunityDate"
                        )}
                        value={this.state.fields["OpportunityDate"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Closed Won Date
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="ClosedWonDate"
                        name="ClosedWonDate"
                        type="text"
                        onChange={this.handleChange.bind(this, "ClosedWonDate")}
                        value={this.state.fields["ClosedWonDate"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Closed Lost Date
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="ClosedLostDate"
                        name="ClosedLostDate"
                        type="text"
                        onChange={this.handleChange.bind(
                          this,
                          "ClosedLostDate"
                        )}
                        value={this.state.fields["ClosedLostDate"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Unqualified Date
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="UnqualifiedDate"
                        name="UnqualifiedDate"
                        type="text"
                        onChange={this.handleChange.bind(
                          this,
                          "UnqualifiedDate"
                        )}
                        value={this.state.fields["UnqualifiedDate"]}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-4 pt-4 padd-0 d-flex align-items-center">
                <h3 className=" float-left addpotential_header">
                  Custom Meeting Field
                </h3>
              </div>
              <div className="row ">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      cust1
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="cust1"
                        name="cust1"
                        type="text"
                        onChange={this.handleChange.bind(this, "cust1")}
                        value={this.state.fields["cust1"]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          

        {/* notes */} 
          <div className="paddcols mt-3 pt-4">
            <div className="row">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">Notes</h3>
              </div>
              <div className="col text-right">
                {/* <a className="btn btngroup m-btn ">
                  <i className="la la-plus la-icon-font-size-13"></i>
                  <span className="Mobile_button">Add</span>
                </a> */}
                <Popup
                  trigger={
                    <button className="btn btngroup m-btn ">
                      <i class="la la-plus la-icon-font-size-13"></i>
                      <span className="Mobile_button">Add</span>
                    </button>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div>
                      <div className="modal-black"></div>
                      <div className="filterPopup largerPopup">
                        <div className="paddingboxTerms">
                          <div className="modal-header py-3 px-3">
                            <h4 className="mb-0">Note</h4>
                          </div>
                          <div className="modal-body p-5">
                            <div class="row mb-3">
                              <label class="col-lg-2 col-form-label text-left">
                                Title
                              </label>
                              <div class="col-lg-10">
                                <input
                                  class="form-control m-input"
                                  data-val="true"
                                  data-val-remote="Title already exist."
                                  data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                  data-val-required="Please enter title"
                                  id="Title1"
                                  name="Title1"
                                  placeholder="Enter title"
                                  type="text"
                                />
                              </div>
                            </div>

                            <div class="row">
                              <label class="col-lg-2 col-form-label text-left">
                                Note
                              </label>
                              <div class="col-lg-10">
                                <textarea
                                  class="form-control m-input minheight390"
                                  data-val="true"
                                  data-val-remote="Title already exist."
                                  data-val-required="Please enter title"
                                  id="Notes"
                                  name="Notes"
                                  placeholder="Enter note"
                                  type="text"
                                >
                                  {" "}
                                </textarea>
                              </div>
                            </div>
                          </div>
                          <div className="modal-content bordernone text-right">
                            <div class="row">
                              <div class="col-lg-12 pull-right">
                                <a
                                  id="backtolist"
                                  class="btn btn-secondary mr-2"
                                  onClick={() => {
                                    
                                    close();
                                  }}
                                >
                                  cancel
                                </a>
                                <a
                                  id="submit"
                                  class="btn btn-primary btn-lightgreen mr-1"
                                  href="javascript:void(0);"
                                  value="Save"
                                  onClick={this.savebtn}
                                >
                                  <i class="la la-save"></i> Save
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Popup>
              </div>
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={this.chengeRowSelected}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="search"
                    onChange={() => this.requestSearch()}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Notes</TableCell>
                      <TableCell>Created Date</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.rows.map((row) =>
                      row.MeetingID != null ? (
                        <TableRow>
                          <TableCell>{row.Title}</TableCell>
                          <TableCell>{row.Note}</TableCell>
                          <TableCell>
                            {moment(
                              new Date(row.CreatedDate).toDateString()
                            ).format("MM/DD/YYYY")}
                          </TableCell>
                          <TableCell>
                            <a
                              onClick={() => {
                                this.editspage(row._id);
                              }}
                            >
                              <Popup
                                trigger={
                                  <i class="la flaticon-edit-1 edit-icon"></i>
                                }
                                modal
                                nested
                              >
                                {(close) => (
                                  <div>
                                    <div className="modal-black"></div>
                                    <div className="filterPopup largerPopup">
                                      <div className="paddingboxTerms">
                                        <div className="modal-header py-3 px-3">
                                          <h4 className="mb-0">Note</h4>
                                        </div>
                                        <div className="modal-body p-5">
                                          <div class="row mb-3">
                                            <label class="col-lg-2 col-form-label text-left">
                                              Title
                                            </label>
                                            <div class="col-lg-10">
                                              <input
                                                class="form-control m-input"
                                                data-val="true"
                                                data-val-remote="Title already exist."
                                                data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                                data-val-required="Please enter title"
                                                id="Title1"
                                                name="Title1"
                                                placeholder="Enter title"
                                                type="text"
                                                onChange={this.handleChange.bind(
                                                  this,
                                                  "Title1"
                                                )}
                                                value={
                                                  this.state.fields["Title1"]
                                                }
                                              />
                                            </div>
                                          </div>

                                          <div class="row">
                                            <label class="col-lg-2 col-form-label text-left">
                                              Note
                                            </label>
                                            <div class="col-lg-10">
                                              <textarea
                                                class="form-control m-input minheight390"
                                                data-val="true"
                                                data-val-remote="Title already exist."
                                                data-val-required="Please enter title"
                                                id="Notes"
                                                name="Notes"
                                                placeholder="Enter note"
                                                type="text"
                                                onChange={this.handleChange.bind(
                                                  this,
                                                  "Notes"
                                                )}
                                                value={
                                                  this.state.fields["Notes"]
                                                }
                                              >
                                                {" "}
                                              </textarea>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="modal-content bordernone text-right">
                                          <div class="row">
                                            <div class="col-lg-12 pull-right">
                                              <a
                                                id="backtolist"
                                                class="btn btn-secondary mr-2"
                                                onClick={() => {
                                                  
                                                  close();
                                                }}
                                              >
                                                cancel
                                              </a>
                                              <a
                                                id="submit"
                                                class="btn btn-primary btn-lightgreen mr-1"
                                                href="javascript:void(0);"
                                                value="Save"
                                                onClick={this.saveupdatebtn}
                                              >
                                                <i class="la la-save"></i> Save
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Popup>
                            </a>
                            <a
                              href="#"
                              className="btn-eyesicon"
                              onClick={() => {
                                this.DeleteClientName(row._id);
                              }}
                            >
                              <i class="la flaticon-delete-1 delete-icon"></i>
                            </a>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <></>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.sflag ? (
              <div class="row">
                <Pagination
                  component="div"
                  count={this.state.countpage}
                  onChange={this.handleChangePage}
                  showFirstButton
                  showLastButton
                />
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.rlen == 0
                      ? 0
                      : (this.state.page - 1) * this.state.rowsPerPage + 1}{" "}
                    to{" "}
                    {this.state.page * this.state.rowsPerPage > this.state.rlen
                      ? this.state.rlen
                      : this.state.page * this.state.rowsPerPage}{" "}
                    of {this.state.rlen} entries (filtered from{" "}
                    {this.state.flen} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.rlen == 0
                      ? 0
                      : (this.state.page - 1) * this.state.rowsPerPage + 1}{" "}
                    to{" "}
                    {this.state.page * this.state.rowsPerPage > this.state.rlen
                      ? this.state.rlen
                      : this.state.page * this.state.rowsPerPage}{" "}
                    of {this.state.rlen} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                    count={this.state.countpage}
                    onChange={this.handleChangePage}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div> 
          <div className="paddcols my-3 pt-4">
            <div className="row">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">
                  Meeting Status History
                </h3>
              </div>
              <div className="col text-right"></div>
            </div>
            <div className="row padt-25">
              <div className="col-sm-12 col-md-6">
                <label className="textlabelte">
                  Show
                  <select
                    name="tbl_meeting_length"
                    aria-controls="tbl_meeting"
                    className="form-control form-control-sm"
                    onChange={this.chengeRowSelected1}
                  >
                    {CommonConstants.show_rows.map((value) => (
                      <option value={value}>{value}</option>
                    ))}
                  </select>
                  entries
                </label>
              </div>
              <div className="col-sm-12 col-md-6 full-right">
                <label className="textlabelte">
                  Search:{" "}
                  <input
                    type="search"
                    id="search1"
                    onChange={() => this.requestSearch1()}
                    className="form-control form-control-sm ml-2"
                    placeholder=""
                    aria-controls="tbl_meeting"
                  />
                </label>
              </div>
            </div>
            <div className="table-bordered">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Old Status</TableCell>
                      <TableCell>New Status</TableCell>
                      <TableCell>Change Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.rows1.map((row) => (
                      <TableRow>
                        <TableCell>{row.p1[0].Status}</TableCell>
                        <TableCell>
                          {row.p2.length == 0 ? " " : row.p2[0].Status}
                        </TableCell>
                        <TableCell>
                          {moment(
                            new Date(row.ChangeDate).toDateString()
                          ).format("MM/DD/YYYY")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {this.state.sflag1 ? (
              <div class="row">
                <Pagination
                  component="div"
                  count={this.state.countpage1}
                  onChange={this.handleChangePage1}
                  showFirstButton
                  showLastButton
                />
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.rlen1 == 0
                      ? 0
                      : (this.state.page1 - 1) * this.state.rowsPerPage1 +
                        1}{" "}
                    to{" "}
                    {this.state.page1 * this.state.rowsPerPage1 >
                    this.state.rlen1
                      ? this.state.rlen1
                      : this.state.page1 * this.state.rowsPerPage1}{" "}
                    of {this.state.rlen1} entries (filtered from{" "}
                    {this.state.flen1} total entries)
                  </p>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col dataTables_info">
                  <p>
                    Showing{" "}
                    {this.state.rlen1 == 0
                      ? 0
                      : (this.state.page1 - 1) * this.state.rowsPerPage1 +
                        1}{" "}
                    to{" "}
                    {this.state.page1 * this.state.rowsPerPage1 >
                    this.state.rlen1
                      ? this.state.rlen1
                      : this.state.page1 * this.state.rowsPerPage1}{" "}
                    of {this.state.rlen1} entries
                  </p>
                </div>
                <div class="col pageright">
                  <Pagination
                    component="div"
                    count={this.state.countpage1}
                    onChange={this.handleChangePage1}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            )}
          </div> 

        {/* EDIT PAGE AND NOTES END */}

        </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddSalesHiveContactPage = connect(
  mapState,
  actionCreators
)(EditOpportunitiesPage);
export { connectedAddSalesHiveContactPage as EditOpportunitiesPage };
