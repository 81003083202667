import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Pagination from "@material-ui/lab/Pagination";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import loadingicon from "../../../images/loading.gif";
import { CommonConstants, COLORS } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import ReactApexchart from "react-apexcharts";

import { GetClientDetails } from "../../../_helpers/Utility";
// import { IgrLegendModule, IgrCategoryChartModule } from "@infragistics/igniteui-react-charts";
// import { IgrLegend, IgrCategoryChart } from "@infragistics/igniteui-react-charts";

// const mods = [
//     IgrLegendModule,
//     IgrCategoryChartModule
// ];
// mods.forEach((m) => m.register());
export default function AccountAllTable({
  CampaignIDforEmail,
  FromDates,
  ToDates,
}) {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CampaignName");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);
  const [FromDate, SetFromDate] = React.useState("");
  const [ToDate, SetToDate] = React.useState("");
  const [CampaignID, SetCampaignID] = React.useState("");
  const [ExportData, SetExportData] = React.useState([]);
  const [CountGraph, SetCountGraph] = React.useState([]);
  const [SendCountCard, SetSendCountCard] = React.useState(0);
  const [ReplyCountCard, SetReplyCountCard] = React.useState(0);
  const [OpenPerCountCard, SetOpenPerCountCard] = React.useState(0);
  const [ReplyPerCountCard, SetReplyPerCountCard] = React.useState(0);
  const [BouncePerCountCard, SetBouncePerCountCard] = React.useState(0);
  const [CountOpenGraph, SetCountOpenGraph] = React.useState([]);
  const [Cname, SetCname] = React.useState("");

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

    GetClientDetails(Details.ClientID).then((result) => {
      SetCname(result[0].Name);
    });
    if(FromDates!=""&& ToDates!=""){
      SetCampaignID(CampaignIDforEmail);
      SetFromDate(FromDates);
      SetToDate(ToDates)
      EmailReportingGet(
        Details.ClientID,
        Details.ParentUserID,
        Details.Role,
        FromDates,
        ToDates
      );
      EmailReportingExportGet(
        Details.ClientID,
        Details.ParentUserID,
        Details.Role,
        FromDates,
        ToDates
      );
      ReportingEmailMatrixGraphGet(
        Details.ClientID,
        Details.ParentUserID,
        Details.Role,
        FromDates,
        ToDates
      );
      ReportingEmailMatrixOpenGraphGet(
        Details.ClientID,
        Details.ParentUserID,
        Details.Role,
        FromDates,
        ToDates
      );
    }
  }, [Search, Page, RowsPerPage, FromDates, ToDates]);

  // email reporting list
  const EmailReportingGet = (CID, UID, URole, Fromdate, Todate) => {
    SetIsLoading(true);
    var InputParameters = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      Sortby: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      CampaignID: CampaignIDforEmail,
      FromDate: Fromdate,
      ToDate: Todate,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/campaign/ReportingEmailGet",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      // SetCounts(result.data.Counts);
      SetSendCountCard(result.data.PageData[0]?.TotalSentMail ?? 0);
      SetReplyCountCard(result.data.PageData[0]?.TotalReply ?? 0);
      SetOpenPerCountCard(result.data.PageData[0]?.TotalOpenPercentage ?? 0);
      SetReplyPerCountCard(result.data.PageData[0]?.TotalReplyPercentage ?? 0);
      SetBouncePerCountCard(
        result.data.PageData[0]?.TotalBouncePercentage ?? 0
      );

      SetIsLoading(false);
    });
  };

  // email reporting list
  const EmailReportingExportGet = (CID, UID, URole, Fromdate, Todate) => {
    var InputParameters = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
      CampaignID: CampaignIDforEmail,
      FromDate: Fromdate,
      ToDate: Todate,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/campaign/ReportingExportEmailGet",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((result) => {
      SetExportData(result.data);
    });
  };

  // email reporting list
  const ReportingEmailMatrixGraphGet = (CID, UID, URole, Fromdate, Todate) => {
    var InputParameters = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
      CampaignID: CampaignIDforEmail,
      FromDate: Fromdate,
      ToDate: Todate,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL + "/campaign/ReportingEmailMatrixGraphGet",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((result) => {
      SetCountGraph(result.data);
    });
  };

  // email reporting list
  const ReportingEmailMatrixOpenGraphGet = (
    CID,
    UID,
    URole,
    Fromdate,
    Todate
  ) => {
    var InputParameters = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
      CampaignID: CampaignIDforEmail,
      FromDate: Fromdate,
      ToDate: Todate,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/campaign/ReportingEmailMatrixOpenPercentageGraphGet",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((result) => {
      SetCountOpenGraph(result.data);
    });
  };


  // Define the AccountStateOpen in the required format
  const AccountStateOpen = {
    series: [
      {
        name: "Open %",
        color: "#F8BF58",
        data: CountOpenGraph.map((item) => item.OpenPercentage),
      },
      {
        name: "",
        color: "#ffffff00", // Transparent color
        data: [],
      },
      
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
        toolbar: {
          show: false,  
        } 
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2, 2, 2],
        curve: 'smooth'
      },
      xaxis: {
        categories: CountOpenGraph.map((item) => item.dateRange),
        title: {
          text: "Days",
        },
      },
      legend: {
        position: 'right',
        onItemClick: {
          toggleDataSeries: false
      },
    },
      yaxis: {
        title: {
          text: "Count", // Update the y-axis title
        },
      },
      // xaxis: {
      //   title: {
      //     text: "Days", // Update the x-axis title
      //   },
      // },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "%"; // Update the tooltip format
          },
        },
      },
      markers: {
        size: 4,
        strokeWidth: 1.5,
        hover: {
          size: 6 // Size of the marker when hovered
        }
      }
    },
  };

  const AccountState = {
    series: [
      {
        name: "Open",
        color: COLORS.secondary,
        data: CountGraph.map((item) => item.Open),
      },
      {
          name: "Sent",
          color: COLORS.primary,
          data: CountGraph.map((item) => item.Sent),
      },
      {
          name: "Replies",
          color: COLORS.brown,
          data: CountGraph.map((item) => item.Reply),
      },
      {
          name: "Bounces",
          color: COLORS.emeraldGreen,
          data: CountGraph.map((item) => item.Bounce),
      },
    ],
    options: {
        chart: {
            type: "line",
            height: 350,
            toolbar: {
              show: false,  
            } 
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "55%",
                endingShape: "rounded",
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
          width: [2, 2, 2, 2],
          curve: 'smooth'
        },
        xaxis: {
            categories: CountGraph.map((item) => item.dateRange),
            title: {
                text: "Weeks",
            },
        },
        legend: {
          position: 'right',
          onItemClick: {
            toggleDataSeries: false
        },
      },
        // legend: {
        //     position: 'right',
        // },
        yaxis: {
            title: {
                text: "Count",
            },
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
          enabled: true,
          intersect: false,
          x: {
              show: true,
          },
          y: {
              formatter: function(val) {
                  return val;
              },
          },
          custom: function({ series, seriesIndex, dataPointIndex, w }) {
          
            const data = CountGraph[dataPointIndex];
               
            return (
              `<div style="background-color: #fff; border: 1px solid #ccc; border-radius: 8px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); max-width: 300px;">
              <div style="font-weight: bold;background-color:#e1e1e1;padding: 8px; margin-bottom: 8px;"><small>${data.dateRange}</small></div>
              <div style="display: flex; align-items: center;padding-inline: 8px; margin-bottom: 4px;">
                  <div style="width: 12px; height: 12px; border-radius: 50%; margin-right: 8px; background-color: ${AccountState.series[0].color};"></div>
                  <div><small>${AccountState.series[0].name}: ${data.Open}</small></div>
              </div>
              <div style="display: flex; align-items: center;padding-inline: 8px; margin-bottom: 4px;">
                  <div style="width: 12px; height: 12px; border-radius: 50%; margin-right: 8px; background-color: ${AccountState.series[1].color};"></div>
                  <div><small>${AccountState.series[1].name}: ${data.Sent}</small></div>
              </div>
              <div style="display: flex; align-items: center;padding-inline: 8px; margin-bottom: 4px;">
                  <div style="width: 12px; height: 12px; border-radius: 50%; margin-right: 8px; background-color: ${AccountState.series[2].color};"></div>
                  <div><small>${AccountState.series[2].name}: ${data.Reply}</small></div>
              </div>
              <div style="display: flex; align-items: center;padding-inline: 8px;">
                  <div style="width: 12px; height: 12px; border-radius: 50%; margin-right: 8px; background-color: ${AccountState.series[3].color};"></div>
                  <div><small>${AccountState.series[3].name}: ${data.Bounce}</small></div>
              </div>
          </div>
      `
            );
        },
    
      },
      markers: {
        size: 4,
        strokeWidth: 1.5,
        hover: {
          size: 6 // Size of the marker when hovered
        }
      }
    },
};
  // const AccountState = {
  //   series: [
  //     {
  //       name: "Open",
  //       color: "#F8BF58",
  //       data: CountGraph.map((item) => item.Open),
  //     },
  //     {
  //       name: "Sent",
  //       color: "#360947",
  //       data: CountGraph.map((item) => item.Sent),
  //     },
     
  //     {
  //       name: "Replies",
  //       color: "#FF6347",
  //       data: CountGraph.map((item) => item.Reply),
  //     },
  //     {
  //       name: "Bounces",
  //       color: "#CD853F",
  //       data: CountGraph.map((item) => item.Bounce),
  //     },
  //   ],
  //   options: {
  //     chart: {
  //       type: "bar",
  //       height: 350,
  //     },
  //     plotOptions: {
  //       bar: {
  //         horizontal: false,
  //         columnWidth: "55%",
  //         endingShape: "rounded",
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     stroke: {
  //       show: true,
  //       width: 2,
  //       colors: ["transparent"],
  //     },
  //     xaxis: {
  //       categories: CountGraph.map((item) => item.dateRange),
  //       title: {
  //         text: "Weeks",
  //       },
  //     },
  //     legend: {
  //       position: 'right', // Places the legend on the right side
  //     },
  //     yaxis: {
  //       title: {
  //         text: "Count", // Update the y-axis title
  //       },
  //     },
  //     // xaxis: {
  //     //   title: {
  //     //     text: "Weeks", // Update the x-axis title
  //     //   },
  //     // },
  //     fill: {
  //       opacity: 1,
  //     },
  //     tooltip: {
      
     
  //       y: {
  //         formatter: function (val) {
  //           return val
  //         },
  //       },
  //     },
  //   },
  // };
 
  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //get sort field data
  const SortData = (Field) => {
    debugger
    var SearchedVal = document.getElementById("search").value;
    SetIsLoading(true);
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Search: SearchedVal,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        CampaignID: CampaignID,
        FromDate: FromDate,
        ToDate: ToDate,
      };
      const ApiResponse = Axios({
        url: CommonConstants.MOL_APIURL + "/campaign/GetReportingAccount",
        method: "POST",
        data: InputParameter,
      });
      ApiResponse.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);

        SetIsLoading(false);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        CampaignID: CampaignID,
        FromDate: FromDate,
        ToDate: ToDate,
      };
      const ApiResponse = Axios({
        url: CommonConstants.MOL_APIURL + "/campaign/GetReportingAccount",
        method: "POST",
        data: InputParameter,
      });
      ApiResponse.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);

        SetIsLoading(false);
      });
    }
  };
  return (
    <>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}

      <div className="row colfive">
        <div className="col-xl-2">
          <div class="bg-light-sky boxcardcounter align-center">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{isNaN(SendCountCard) ? 0 : SendCountCard}</span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Sent</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          <div class="bg-light-sky boxcardcounter align-center">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{isNaN(ReplyCountCard) ? 0 : ReplyCountCard}</span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Reply</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          <div class="bg-light-sky boxcardcounter align-center">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">
                    {isNaN(OpenPerCountCard) ? 0 : parseFloat(OpenPerCountCard.toFixed(0))}%
                  </span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Open %</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          <div class="bg-light-sky boxcardcounter align-center">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">
                    {isNaN(ReplyPerCountCard) ? 0 : parseFloat(ReplyPerCountCard.toFixed(0))}%
                  </span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Reply %</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          <div class="bg-light-sky boxcardcounter align-center">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">
                    {isNaN(BouncePerCountCard) ? 0 : parseFloat(BouncePerCountCard.toFixed(0))}%
                  </span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Bounce %</string>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row padtop-50">
            <div className="col-xl-1"></div>
            <div className="col-xl-10">
                <div id="chart">
                    <IgrCategoryChart
                        options={AccountState.options}
                        series={AccountState.series}
                        type="bar"
                        height={450}
                    />
                </div>
            </div>
            <div className="col-xl-1"></div>
        </div> */}
      <div className="row padtop-50"> 
        <div class="col-xl-12">
          <div id="chart">
            <ReactApexchart
              options={AccountState.options}
              series={AccountState.series}
              type="line"
              height={450}
            />
      
          </div>
        </div> 
      </div>

      <div className="row padtop-50"> 
        <div class="col-xl-12">
          <div id="chart">
            <ReactApexchart
              options={AccountStateOpen.options}
              series={AccountStateOpen.series}
              type="line"
              height={450}
            />
          </div>
        </div> 
      </div>

      <div className="row">
        <div className="col">
          <h4 className="headertitle float-left pl-0">Email Details</h4>
        </div>
        <div className="col full-right pt-2 border-bottom">
        <div className="listing-li float-right py-3">
          <ul className="mt-0">
            <li>
              <CSVDownloader
                className="px-0"
                data={ExportData}
                filename={Cname + "-Email"}
                bom={true}
              >
                <a
                  className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                  //onClick={this.exportcsv}
                >
                  <i class="la la-icon-font-size-13 la-download"></i>
                  <span>Export</span>
                </a>
              </CSVDownloader>
            </li>
          </ul>
        </div>
        </div>
      </div>

      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("CampaignName");
                    }}
                  >
                    Campaign Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("TotalSentMail");
                    }}
                  >
                    Sent
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("TotalReply");
                    }}
                  >
                    Reply
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("TotalOpen");
                    }}
                  >
                    Open
                  </a>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell>{row.CampaignName}</TableCell>
                    <TableCell>{row.TotalSentMail}</TableCell>
                    <TableCell>{row.TotalReply}</TableCell>
                    <TableCell>{row.TotalOpen}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
