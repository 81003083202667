import React,{ useEffect }  from 'react';
const moment = require("moment");
import Swal from "sweetalert2";
import Axios from "axios";
import Popup from "reactjs-popup";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from "@material-ui/lab/Pagination";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Avatar } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Userimg from '../../../images/avatar/1.jpg';

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

export default function AccountAllTable(props) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortValue, SetSortValue] = React.useState(1);
  const [sortedColumn, setSortedColumn] = React.useState(null);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [ParentUserID, SetParentUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false);

  const [Title, SetTitle] = React.useState("");
  const [FrameURL, SetFrameURL] = React.useState("");
  const [OpenFrame, SetOpenFrame] = React.useState(false);

  useEffect(() => {
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ChildUserID);
      SetParentUserID(GetUserData.ParentUserID);
      SetRole(GetUserData.Role);
    }
    DocumentsGet(
      GetUserData.ClientID,
      GetUserData.ParentUserID,
      GetUserData.Role
    );
  }, [Search, Page, RowsPerPage,SortValue,SortField]);

  //Get Document List
  const DocumentsGet = (CID, UID, Role) => {
    SetIsLoading(true);

    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortValue,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: Role,
    };
    const ActionItmeList = Axios({
      url: CommonConstants.MOL_APIURL + "/documents/DocumentsGet",
      method: "POST",
      data: InputParameter,
    });
    ActionItmeList.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false);
    });
  };

  //get sort field data
  const SortData = (Field) => {
    SetSortField(Field)
    SetSortValue(-SortValue)
  }

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value?.trim();
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  //edit page
  const EditBtn = (ID) => {
    history.push("/ceditdocuments", ID);
  };

  //delete action item
  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a document.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var InputData = {
          ID: ID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: UserID,
          Role: Role,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/documents/DocumentsDelete",
          method: "POST",
          data: InputData,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Document deleted successfully.",
                "success"
              );
              SetSearch("");
              SetPage(1);
              DocumentsGet(ClientID, ParentUserID, Role);
              history.push("/CDocuments");
              document.getElementById("search").value = "";
              props.updateFromChild(true);
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //open iframe
  const OpenIframe = (title, url) => {
    SetTitle(title);
    SetFrameURL(url);
    SetOpenFrame(true);
  };

  //close iframe
  const CloseIframe = () => {
    SetTitle("");
    SetFrameURL("");
    SetOpenFrame(false);
  };

  return (
    <>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <Popup open={OpenFrame} modal>
        <div className="modal-black"></div>
        <div className="filterPopup largerPopup1400">
          <div className="paddingboxTerms">
            <div className="modal-header py-4">
              <h5>{Title}</h5>
              <a className="close-ion text-dark" onClick={CloseIframe}>
                <span aria-hidden="true" className="la la-remove"></span>
              </a>
            </div>
            <div className="modal-content bordernone p-3">
              <iframe
                src={FrameURL}
                title="iFrame"
                style={{ border: "none" }}
                width="100%"
                height="500px"
              />
            </div>
          </div>
        </div>
      </Popup>

      <div className="px-2">
        <div className="row pt-3">
          <div className="col-sm-12 col-md-6">
            <label className="textlabelte">
              Show
              <select
                name="tbl_meeting_length"
                onChange={ChangeRowSelected}
                aria-controls="tbl_meeting"
                className="form-control form-control-sm"
              >
                {CommonConstants.show_rows.map((value) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
              entries
            </label>
          </div>
          <div className="col-sm-12 col-md-6 full-right">
            <label className="textlabelte">
              Search:{" "}
              <input
                type="search"
                id="search"
                onKeyPress={RequestSearch}
                className="form-control form-control-sm ml-2"
                placeholder=""
                aria-controls="tbl_meeting"
              />
            </label>
          </div>
        </div>

        <div className="table-bordered">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
              <TableRow>
                  <TableCell onClick={() => { SortData("Title"); setSortedColumn("Title"); }}>
                    Title
                    <span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "Title" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "Title" ? "active" : null} />
                    </span>
                  </TableCell>
                  
                  <TableCell onClick={() => { SortData("FieldName"); setSortedColumn("FieldName"); }}>
                    Type
                    <span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "FieldName" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "FieldName" ? "active" : null} />
                    </span>
                  </TableCell>
                  
                  <TableCell onClick={() => { SortData("CreatedDate"); setSortedColumn("CreatedDate"); }}>
                    Created Date
                    <span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "CreatedDate" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "CreatedDate" ? "active" : null} />
                    </span>
                  </TableCell>
                  
                  <TableCell>Action</TableCell>
                </TableRow>

              </TableHead>
              <TableBody>
                {Rows.length === 0 ? (
                  <p className="text-center">No data available in table</p>
                ) : (
                  Rows.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell>
                        <u
                          onClick={() => {
                            OpenIframe(row.Title, row.Url);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {row.Title}
                        </u>
                      </TableCell>
                      <TableCell>{row.FieldName}</TableCell>
                      <TableCell>
                        {moment(
                          new Date(row.CreatedDate).toDateString()
                        ).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            EditBtn(row._id);
                          }}
                          className="btn-eyesicon"
                          title="Edit"
                        >
                          <i className="la flaticon-edit-1 edit-icon"></i>
                        </a>
                        <a
                          onClick={() => {
                            DeleteBtn(row._id);
                          }}
                          className="btn-eyesicon"
                          title="Delete"
                        >
                          <i className="la flaticon-delete-1 delete-icon"></i>
                        </a>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      </div>
    </>
  );
}

