import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import { history } from "../../../_helpers";
import { userActions } from "../../../_actions";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import CampaignInCategories from "../../../_components/clients/campaigncategories/CampaignInCategories";
import Sidebar from '../../../_components/clients/nav-sidebar/Sidebar';
import Footer from "../../../_components/user/footer/footer";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

class CAddCampaignInCategoriesPage extends React.Component {
   

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome"> 
            <div className="row">
              <div className="col">
                <h4 className="headertitlepad pl-0 mb-0">
                  Add Campaign Category
                </h4>
              </div>
            </div> 
            <div class="paddcols">  
                <div className="row">
                  <div className="col">
                    <CampaignInCategories />
                  </div> 
                </div> 
            </div> 
          </div>


        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedcampaigncategories = connect(
  mapState,
  actionCreators
)(CAddCampaignInCategoriesPage);
export { connectedcampaigncategories as CAddCampaignInCategoriesPage };
