import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../../_actions';
import Sidebar from '../../../_components/user/NavigationSidebar/Sidebar';
import Axios from 'axios';
import { CommonConstants } from '../../../_constants/common.constants';
import { history } from '../../../_helpers';
import{GetUserDetails} from '../../../_helpers/Utility'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

class AddPotentialMeetingStatusPage extends React.Component {
    constructor(props) {
        super(props);

    
        this.state = {
            pager: {},
            pageOfItems: [],
            files: [],
            list:[],
            columns:[],
            reset:false,
            csvData:[],

            fileds: {},
            errors: {},
            AddPotentialmeetingstatusname: null,
            ClientID:Details.ClientID ,
            UserID:Details.ParentUserID,
            CUserID:Details.ChildUserID,
            Role:Details.Role
        };

    var Details=GetUserDetails();
        this.backbtn = this.backbtn.bind(this);
        this.savebtn = this.savebtn.bind(this);
    }
    componentDidMount(){

    }

        // form validation
 fromValidation(){
      
    let formIsValid = true;
    let fileds = this.state.fileds;
    let errors = {};
    if(!fileds["Status"]){
        formIsValid = false;
        errors["Status"] = "Please Enter Status";
    }
  
    if (this.state.AddPotentialmeetingstatusname != null ){
        formIsValid = false;
    }
    this.setState({errors : errors});
    return formIsValid;
  }
  handleChange (field, e){
    let fields = this.state.fileds;
    fields[field] = e.target.value;
    this.setState({fields});
    if(fields.Status != "") {
        this.state.errors.Status = null;
        this.state.AddPotentialmeetingstatusname = null;
        this.CheckExistMeetingStatusName(e.target.value)
  
    }
    else {
        this.state.AddPotentialmeetingstatusname = null;
        this.state.errors.Status = null;
    }
  }
  
  CheckExistMeetingStatusName(Status) {
  
    var str_in = {
      
        "ClientID": this.state.ClientID,
        "Status": Status
    };
    Axios({
        url: CommonConstants.MOL_APIURL + "/potentialmeetingstatus/PotentialMeetingStatusExists",
        method: "POST",
        data: str_in
    }).then(resdata => {
        if (resdata.data.StatusMessage == "SUCCESS") {
            if (resdata.data.data.length > 0) {
                this.setState({ AddPotentialmeetingstatusname: resdata.data.data });
            }
        }
    })
  }



    backbtn(){
      window.location = CommonConstants.home+"accountclientstatus"
    }
    savebtn(e){
        e.preventDefault();
      if(this.fromValidation())  {
            var Status = document.getElementById("Status").value
            var data = {
          
                "Status": Status,
                "ClientID": this.state.ClientID,
                "Role":this.state.Role,
                "CreatedBy":this.state.CUserID,
                "CreatedDt":new Date().toISOString(),
                "LastUpdatedBy":this.state.CUserID,
                "LastUpdatedDt":new Date().toISOString(),
                "type": "User",
       
            };
            console.log(data, "wwwwwrwrwwwr")
            Axios({
                url: CommonConstants.MOL_APIURL + "/potentialmeetingstatus/PotentialMeetingStatusAdd",
                method: "POST",
                data: data
                
            }).then(res => {
    
                if(res.data.StatusMessage  == "SUCCESS"){
                toast.success("Meeting Source Add Successfully.", "Add Meeting Source")
                history.push('/potentialmeetingstatus')
      
                console.log("fwfsfsfs", res.data)
            }
            else {
                toast.error(res.data.Message)
            }
      
        });
      }
      
      }
    // savebtn(){
    //   var name = document.getElementById("Name").value;
    //   if(name == undefined){
    //     console.log("Please enter name");
    //   }else{
    //       var data={
    //         "Name":name,
    //         "filename":null,
    //         "IsDeleted":false,
    //         "ClientID":null,
    //         "OrderBy":10,
    //         "UserID":null,
    //         "CreatedBy":"17",
    //         "CreatedDate": new Date(),
    //         "LastUpdatedBy":null,
    //         "LastUpdatedDate":null
    
    //     };
    //     Axios({
    //         url: CommonConstants.MOL_APIURL + "/account_client_status/AccountClientStatusAdd",
    //         method: "POST",
    //         data: data
    //     }).then(res => {
    //         console.log(res);
    //         if(res){
    //             //register_u.style.display = "block";
    //             //window.location = CommonConstants.home+"accountcategory";
    //         }
    //     });
    //   }
    // }

    render() {
        return (
            <>
                <div className="adminmain"> 
                <Sidebar className=""/> 
                <div className="bodyhome">

                <div className="row">
                    <div className="col">
                        <h4 className="headertitlepad float-left">Add Potential Meeting Status</h4>
                    </div> 
                </div> 

                <div class="paddcols"> 
                      <div className="row pt-4">  
                        <div class="col-lg-6 boxsinput_group">
                            <div class="row max-cols">
                                <label class="col-lg-4 view-lable-meet">Status</label>
                                <div class="col-lg-8">
                                <input class="form-control m-input" data-val="true" data-val-required="Please enter status" id="Status" onBlur={this.handleChange.bind(this, "Status")} name="Status"  placeholder="Status" type="text" />
                                    <span style={{ color: "red" }}>{this.state.errors["Status"]}</span>
                                            {this.state.AddPotentialmeetingstatusname && <span style={{ color: "red" }}>Status already exist.</span>}
                                </div>
                            </div>
                        </div>
                      </div>    
                </div>

                <div class="row py-4 px-4">
                    <div class="col-lg-12 pull-left">
                        <a id="submit" onClick={this.savebtn} class="btn btn-primary btn-lightgreen mr-1"><i class="la la-save"></i> Save</a>
                        <a id="backtolist" onClick={this.backbtn} class="btn btn-secondary"><i class="la la-arrow-circle-left"></i> Back</a>
                    </div>
                </div> 
                </div> 
            </div>
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedPotentialMeetingStatusPage = connect(mapState, actionCreators)(AddPotentialMeetingStatusPage);
export { connectedPotentialMeetingStatusPage as AddPotentialMeetingStatusPage };