import React, { useEffect } from "react";
import Axios from "axios";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import AccountsTable from "../../../_components/user/ClientMailAccounts/AccountsTable";
import DomainsTable from "../../../_components/user/ClientMailAccounts/DomainsTable";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { GetUserDetails } from "../../../_helpers/Utility";
import { GetClientDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import loadingicons from "../../../images/loading.gif";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [popupval, setpopup] = React.useState(false);
  const [ExportData, SetExportData] = React.useState([]);
  const [CName, SetCName] = React.useState("");
  const [ClientID, SetClientID] = React.useState("");
  const [UserID, SetUserID] = React.useState("");
  const [Role, SetRole] = React.useState("");
  const [Childupdate, SetChildUpdate] = React.useState(false);
  const [CUserID,SetCUserID] = React.useState(0)
  const [IsHideInactiveAccount, SetIsHideInactiveAccount] = React.useState(true);
  const [IsOldIncludeState, SetIsOldIncludeState] = React.useState(false);
  const [IsLoading, SetIsLoading] = React.useState(false);

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    GetClientDetails(Details.ClientID).then((result) => {
      SetCName(result[0].Name);
    });
    // ExportCsvData(Details.ParentUserID, Details.Role,IsHideInactiveAccount,IsOldIncludeState);
  }, [UserID, ClientID]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ExportCsv = () => {
    toast.success(<div className="toastsize">Data exported successfully.</div>);
  };

  document.addEventListener("mouseup", function (e) {
    if (popupval) {
      setpopup(false);
    }
  });

  const ExportCsvData = async(UID, ROLE,IsHideInactiveAcc,IsOldInc) => {
   
    const InputParameter = {
      UserID: UID,
      Role: ROLE,
      HideInactiveAccountParam: IsHideInactiveAcc,
      IsOldInclude:IsOldInc
    };
    const rows1 = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/emailaccount/ClientEmailAccountExport",
      method: "POST",
      data: InputParameter,
    })
    if(rows1.data.StatusMessage === "SUCCESS"){
      return rows1.data;
    }
  };


//  //Export details
//  const Exportdetails = async(CID, URole) => {
    

//   var str_in = {
//     ClientID: CID,
//     Role: URole,
//     IsApply: FilterParameters?.length ===0?false:true,
//     BookMeetingFilterArray: FilterParameters,
//   };

// const rows1 = await Axios({
//   url: CommonConstants.MOL_APIURL + "/bookedmeeting/ExportBookedMeeting",
//   method: "POST",
//   data: str_in,
// });
// if(rows1.data.StatusMessage === "SUCCESS"){
//   return rows1.data;
// };
// };


const exportcsv = async() => {
  try {
      SetIsLoading(true)
      const result = await ExportCsvData(UserID,Role,IsHideInactiveAccount,IsOldIncludeState);
     
      if (result.StatusMessage === "SUCCESS") {
        const data = result.Data;
        if (data.length > 0) {
          // setexportdta(data);
          const downloader = document.createElement("a");
          const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(convertToCSV(data));
          downloader.setAttribute("href", csvContent);
          downloader.setAttribute("download", CName+"-Master_Email_Accounts_Detail.csv"); 
          downloader.click();
          toast.success(<div>Data exported successfully.</div>);
          SetIsLoading(false)
      
        } else {
          toast.error("No data available for export.");
          SetIsLoading(false)
        
        }
      } else {
        toast.error("Failed to export data. Please try again.");
        SetIsLoading(false)
    
      }
  } catch (error) {
    SetIsLoading(false)
    toast.error("An error occurred while exporting data.");
  }
  };


function convertToCSV(data) {
  const headers = Object.keys(data[0]);

  const csvRows = [];
  csvRows.push(headers.join(','));

  data.forEach(item => {
      const values = headers.map(header => {
          // Check if the value is a string before attempting to replace characters
          const value = typeof item[header] === 'string' ? item[header] : String(item[header]);
          const escapedValue = value.replace(/"/g, '""'); // Escape double quotes
          return `"${escapedValue}"`; // Enclose value in double quotes
      });
      csvRows.push(values.join(','));
  });

  return csvRows.join('\n');
  }


  const HandleExportwithSearchChild=(ValOne,ValTwo)=>{
   // SetIsLoading(true)
   SetIsHideInactiveAccount(ValOne)
   SetIsOldIncludeState(ValTwo)
    // ExportCsvData(UserID,Role,ValOne,ValTwo)
  }


  const UpdateChildComponent = (value) => {
    SetChildUpdate(!Childupdate);
  };

  return (
    <>
    {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicons} />
        </div>
      ) : (
        <></>
      )}
      <div className="row">
        <div className="col">
          <h4 className="headertitlebd pl-0"> Master Email Accounts </h4>
        </div>
        <div className="col px-5">
          <div className="listing-li float-right padb-15 pt-1">
            <ul>
              {value === 0 ? (
                <li>
                 <a 
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          onClick={exportcsv}
                        >
                          <i className="la la-icon-font-size-13 la-download"></i>
                          <span>
                              Export
                          </span>
                        </a>
                </li>
              ) : (
                <></>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="tabdes">
        <div>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange} variant="scrollable"
              aria-label="simple tabs example"
            >
              <Tab label="Accounts" {...a11yProps(0)}>
                Overviewasdasd
              </Tab>
              <Tab label="Domains" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} className="tagpaddnone whitebg" index={0}>
            <div className="row px-2">
              <div className="col">
                <AccountsTable updatechild={UpdateChildComponent} HandleExportwithSearch={HandleExportwithSearchChild}/> 
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} className="tagpaddnone whitebg" index={1}>
            <div className="row px-2">
              <div className="col">
                  <DomainsTable /> 
              </div>
            </div>
          </TabPanel>
        </div>
      </div>
    </>
  );
}
