import React from "react";
import Axios from "axios";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

class AddContactSourcePage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      ContactSourceNameAvailble: null,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      Role: Details.Role,
      BtnDisabled: false
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = 'Add | SalesHive'
  }

  // form validation
  async FromValidation() {
    let formIsValid = true;
    let Fields = this.state.Fields;
    let Errors = {};
    var Name = document.getElementById("Name").value.trim();
    let IsExists = await this.ContactSourceNameCheckExist(Name)
    if (Name == "") {
      formIsValid = false;
      Errors["Name"] = "Please enter name";
    }
    if (IsExists == true) {
      formIsValid = false;
    }
    if (this.state.ContactSourceNameAvailble != null) {
      formIsValid = false;
    }
    this.setState({ Errors: Errors });
    return formIsValid;
  }

  // handle change
  HandleChange(field, e) {
    let Fields = this.state.Fields;
    Fields[field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Name != "") {
      this.state.Errors.Name = null;
      this.state.ContactSourceNameAvailble = null;
      this.ContactSourceNameCheckExist(e.target.value);
    } else {
      this.state.ContactSourceNameAvailble = null;
      this.state.Errors.Name = null;
    }
  }

  // contact source check exits
  async ContactSourceNameCheckExist(Name) {
    var str_in = {
      ClientID: this.state.ClientID,
      Name: Name,
      Role: this.state.Role
    };
    let resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/contactsource/ContactSourceExists",
      method: "POST",
      data: str_in,
    })
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        this.setState({
          ContactSourceNameAvailble: resdata.data.Data[0].Name
        });
        return true;
      } else {
        return false;

      }
    }

  }

  // back button
  BackBtn() {
    history.push("/contactsource");
  }

  // save button
  async SaveBtn() {
    this.setState({ BtnDisabled: true })
    var Final_flag = await this.FromValidation()
    if (Final_flag == true) {
      var Name = document.getElementById("Name").value.trim();
      var data = {
        Name: Name,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
        CreatedBy: this.state.CUserID,
        CreatedDt: new Date().toISOString(),
        ContactSourceID: 1234,
        type: "User",
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contactsource/ContactSourceAdd",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div className="toastsize" >Contact source<br />Contact Source added successfully.</div>);
          history.push("/contactsource");
        } else {
          toast.error(res.data.Message);
          this.setState({ BtnDisabled: false })
        }
      });
    } else {
      this.setState({ BtnDisabled: false })
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="row pt-4 pb-3">
              <div className="col px-3">
                <h4 className="headertitlenop float-left">
                  Add
                </h4>
              </div>
            </div>

            <div class="whiterow">
              <div className="row px-4 pt-3 pb-3">
                <div class="col-lg-6 boxsinput_group px-3">
                  <div class="row max-cols px-1">
                    <label class="col-lg-3 view-lable-meet">Name</label>
                    <div class="col-lg-7 pl-4">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter field name"
                        id="Name"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        name="Name"
                        placeholder="Enter name"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                      {this.state.ContactSourceNameAvailble && (
                        <span style={{ color: "red" }}>
                          Name already exist.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row py-3">
              <div class="col-lg-12 pull-left pt-2 pb-2 mx-1">
                <button
                  id="submit"
                  disabled={this.state.BtnDisabled}
                  onClick={this.SaveBtn}
                  class="btn btn-primary btn-lightgreen mr-1"
                >
                  <i class="la la-save"></i> Save
                </button>
                <a
                  id="backtolist"
                  onClick={this.BackBtn}
                  class="btn btn-secondary"
                >
                  <i class="la la-arrow-circle-left"></i> Back
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddContactSourcePage = connect(
  mapState,
  actionCreators
)(AddContactSourcePage);
export { connectedAddContactSourcePage as AddContactSourcePage };
