import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import ApikeyTable from "../../../_components/user/ApiKey/ApikeyTable";

class ApikeyPage extends React.Component {
  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col padd-0">
                <h4 className="headertitlebd">API Keys</h4>
              </div>
              <div className="col padright"></div>
            </div> 
            <div class="paddcols">
              <div className="row">
                <div className="col">
                  <ApikeyTable />
                </div>
              </div>
            </div>
          
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedApikeyPage = connect(mapState, actionCreators)(ApikeyPage);
export { connectedApikeyPage as ApikeyPage };
