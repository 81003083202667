import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Axios from "axios";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loadingicon from "../../../images/loading.gif";

toast.configure();

class AddGlobalContactBlacklistPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      fields: {},
      errors: {},
      ClientID: Details.ClientID,
      UserID: Details.ChildUserID,
      PUserID: Details.ParentUserID,
      IsExist: false,
      IsValid:true
    };
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {}

  //on blur form validation
  async FormBlur(){
    let formIsValid = true;
    let errors = {};
  var Email = document.getElementById("Email").value.trim();
  var ISExist = await this.CheckExistEmail();
  console.log(ISExist)
  var ISValid = await this.ValidateEmail(Email);
    if (Email.length==0) {
      formIsValid = false;
      errors["Email"] = "Please enter email";
    }else if(!ISValid){
      formIsValid = false;
      errors["Email"] = "Invalid email!";
    }else if (ISExist) {
      formIsValid = false;
      errors["Email"] = "Email is already exists!";
    }
    
    
    this.setState({ errors: errors });
  }

  async FormValidation() {
    let formIsValid = true;
    let errors = {};
  var Email = document.getElementById("Email").value.trim();
  var ISExist = await this.CheckExistEmail();
  var ISValid = await this.ValidateEmail(Email);
    if (Email.length==0) {
      formIsValid = false;
      errors["Email"] = "Please enter email";
    }else if(!ISValid){
      formIsValid = false;
      errors["Email"] = "Invalid email.";
    }else if (ISExist) {
      formIsValid = false;
      errors["Email"] = "Email is already exists!";
    }
    
    
    this.setState({ errors: errors });
    
    return formIsValid;
  }

  //Domain Check 
  DomainCheck(Domain){
   let regexp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
  
    if (regexp.test(Domain))
      {
        return true;
      }
    else
      {
        return false;
      }
  }

  //Email validation
  async ValidateEmail(input) {
    
  var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (input.match(validRegex)) {
    return true;
  } else {
    return false;
  }
}

  // check exit Email
  async CheckExistEmail() {
    var Email = document.getElementById("Email").value;
    var InputParameter = {
      Email: Email,
      UserID:this.state.PUserID
    };
    let resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/globalcontactblacklist/GlobalContactBlacklistExists",
      method: "POST",
      data: InputParameter,
    })
    let errors = {};
    if (resdata.data.Data > 0) {
      let fields = this.state.fields;
      if (!fields["Email"]) {
              errors["Email"] = "Email is already exists!";
      }
      this.setState({ IsExist: true });
      this.setState({ errors: errors }); 
      return true;
    }else{
      errors["Email"] = "";
      this.setState({ errors: errors }); 
      return false;

    }
  }

  backbtn() {
    history.push({pathname:"/globalblacklist",state:{page:"contact"}});
  }
  async savebtn(e) {
    document.getElementById('hideloding').style.display="block";
    e.preventDefault();
    e.currentTarget.disabled = true;
    this.state.errors.Email = null;
    var Final_flag =  await this.FormValidation()
    if(Final_flag == true){
      var Email = document.getElementById("Email").value;

      var InputData = {
        Email: Email,
        UserID: this.state.UserID,
        CreatedBy: this.state.UserID,
        CreatedDate: new Date(),
      };
      
      Axios({
        url: CommonConstants.MOL_APIURL + "/globalcontactblacklist/GlobalContactBlacklistAdd",
        method: "POST",
        data: InputData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div className="toastsize" >Global Contact Blacklist<br/>Global Contact Blacklist contact added successfully.</div>);
          document.getElementById('hideloding').style.display="none";
          history.push({pathname:"/globalblacklist",state:{page:"contact"}});
        } else {
          toast.error(res.data.Message);
          document.getElementById("submit").disabled = false;
          document.getElementById('hideloding').style.display="none";
        }
      });
    
    }else{
      document.getElementById("submit").disabled = false;
      document.getElementById('hideloding').style.display="none";
    }
  }

  render() {
    return (
      <>
       <div id="hideloding" className="loding-display" style={{display:"none"}}>
          <img src={loadingicon} />
        </div>
        <div className="adminmain"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row borderbtnm">
                <div className="col">
                  <h4 className="headertitlepad float-left">Add </h4>
                </div>
              </div>

            <div className="paddcols">
              <div className="row pt-4 pb-2">  
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Email</label>
                    <div className="col-lg-8">
                      <input className="form-control m-input" onBlur={()=>{this.FormBlur()}} id="Email" placeholder="Enter email" type="text" />
                    <span style={{ color: "red" }}>{this.state.errors["Email"]}</span>

                    </div>
                  </div>
                </div>  
              </div> 
            </div>
            
              <div className="row mb-5 mt-3">
                <div className="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.savebtn}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.backbtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>

          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddGlobalContactBlacklistPage = connect(
  mapState,
  actionCreators
)(AddGlobalContactBlacklistPage);
export { connectedAddGlobalContactBlacklistPage as AddGlobalContactBlacklistPage };
