import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';

// Note that Froala Editor has to be required separately
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.css';
import 'froala-editor/js/plugins.pkgd.min.js';


import MeetingReminder from "../../../src/_components/user/MeetingReminder/MeetingReminderTabing";

class MeetingReminderPage extends React.Component {

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                    <Sidebar className="" /> 
                    <div className="bodyhome">
                        <div className='row'>
                            <div className="col">
                                <h4 className="headertitle float-left pl-0">Meeting Reminder</h4>
                            </div>
                        </div>
                        <MeetingReminder />
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedMeetingReminderPage = connect(mapState, actionCreators)(MeetingReminderPage);
export { connectedMeetingReminderPage as MeetingReminderPage };