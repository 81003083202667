import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";
import "froala-editor/js/plugins.pkgd.min.js";
import FroalaEditor from "react-froala-wysiwyg";
import Froala from "froala-editor";
//import CallHistory from '../_components/coldcalling/callhistory';
//import CollapsibleTable from '../_components/usertable';

import ReactApexchart from "react-apexcharts";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";

toast.configure();

var DeliverabilityVariable = [
  { name: "FullName" },
  { name: "Title" },
  { name: "Email" },
];
var Vari = {};
if (DeliverabilityVariable.length > 0) {
  for (var i = 0; i < DeliverabilityVariable.length; i++) {
    Vari["{" + DeliverabilityVariable[i].name + "}"] =
      DeliverabilityVariable[i].name;
  }
}

class EditDeliverabilityTestingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pager: {},
      pageOfItems: [],
      files: [],
      list: [],
      columns: [],
      reset: false,
      csvData: [],
      code: "",
      fields: {},
      errors: {},
      NameAvailable: null,
      ClientID: null,
      UserID: null,
      CUserID: null,
      VariableType: [],
      VariableTypeID: null,
      CustomVariable: {},
      IsExist: false,
      signature: {},
      BtnDisabled:false
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = "Edit | SalesHive";

    Froala.RegisterCommand("Variable", {
      title: "Advanced options",
      type: "dropdown",
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      options: Vari,
      callback: function (cmd, val) {
        var editorInstance = this;
        editorInstance.html.insert(val);
      },
    });

    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
    }
    this.GetDeliverability();
  }

  // fetch Direct Mail data
  GetDeliverability() {
    var InputParameter = {
      ID: this.props.location.state,
    };
    
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/DeliverAbilityTesting/DeliverAbilityTestingGetByID",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        
        document.getElementById("Title").value = res.data.Data.Subject;
        // document.getElementById('signature').value=res.data.Data.Message;
        let datas = {};
        datas.data = res.data.Data.Message;
        this.setState({
          signature: datas,
        });
        // 
      } else {
        toast.error(res.data.Message);
      }
    });
  }

  HandleModelChange = (model) => {
    let datas = {};
    datas.data = model;
    this.setState({
      signature: datas,
    });
  };

  config = {
    placeholderText: "Edit Your Content Here!",
    charCounterCount: true,
    // pluginsEnabled: ['bold','italic','underline','createLink'],
    toolbarButtons: [
      "bold",
      "italic",
      "underline",
      "insertLink",
      "insertImage",
      "html",
      "Variable",
    ],
    //shortcutsEnabled: ["insertTemplateButton"],
    key:CommonConstants.EditorValidationKey,
    pastePlain: true,
    attribution: false
  };

  FromValidation() {
    let formIsValid = true;
    var Subject = document.getElementById("Title").value.trim();
    // 
    var Message = this.state.signature?.data;
    console.log(Message)
    let errors = {};

    if (Subject.length <= 0) {
      formIsValid = false;
      errors["Subject"] = "Please enter subject";
    }else if (Message == undefined || Message == "") {
      formIsValid = false;
      toast.error("Please enter message");
      // errors["Message"] = "Please enter Message";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  // handlechange
  handleChange(field, e) {
    document.getElementById("blank_error").style.display = "none";
    // 
    // 
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.Name != "") {
      this.state.errors.Name = null;
      this.state.NameAvailable = null;
      this.CheckExistvariableName(e.target.value);
    } else {
      this.state.NameAvailable = null;
      this.state.errors.Name = null;
    }
  }

  BackBtn() {
    history.push("/deliverabilitytesting");
  }
  SaveBtn() {
    this.setState({BtnDisabled:true})
    if (this.FromValidation()) {
      var Subject = document.getElementById("Title").value.trim();
      var data = {
        ID: this.props.location.state,
        Subject: Subject,
        Message: this.state.signature.data,
        LastUpdatedBy: this.state.CUserID,
        LastUpdateDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/DeliverAbilityTesting/DeliverAbilityTestingUpdate",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div className="toastsize">Deliverability Testing <br/>Deliverability Testing updated successfully.</div>)
          history.push("/deliverabilitytesting");
        } else {
          this.setState({BtnDisabled:false})
          toast.error(res.data.Message);
        }
      });
    }else{
      this.setState({BtnDisabled:false})
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
            <Sidebar className="" /> 
          <div className="bodyhome">
           
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left">
                    Edit DeliverabilityTesting
                  </h4>
                </div>
              </div>
              <div class="paddcols pb-4">
              <div className="row pt-4">
                <div class="col-lg-12">
                  <div class="row">
                    <label class="col-lg-2 view-lable-meet">Subject</label>
                    <div class="col-lg-10">
                      <input
                        class="form-control m-input"
                        id="Title"
                        name="Title"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Subject"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row pt-4">
                <div class="col-lg-12">
                  <div class="row">
                    <label class="col-lg-2 view-lable-meet">Message</label>
                    <div class="col-lg-10">
                      <FroalaEditor
                        id="signature"
                        config={this.config}
                        model={this.state.signature?.data}
                        onModelChange={this.HandleModelChange}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Message"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div class="row py-4">
                <div class="col-lg-12 pull-left">
                  <button
                    disabled={this.state.BtnDisabled}
                    id="submit"
                    onClick={this.SaveBtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
           
          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditDeliverabilityTestingPage = connect(
  mapState,
  actionCreators
)(EditDeliverabilityTestingPage);
export { connectedEditDeliverabilityTestingPage as EditDeliverabilityTestingPage };
