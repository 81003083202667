import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import ContactsTabing from "../../_components/user/Contacts/ContactsTabing";
import CollapsibleTable from "../../_components/usertable";

class ContactsPage extends React.Component {
  render() {
    return (
      <>
        <div className="adminmain mheight"> 
            <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitlebd lg-headertitle">Contacts</h4>
              </div>
              <div className="col padright"></div>
            </div>
          <div className="row">
            <div className="col">
              <ContactsTabing />
            </div>
          </div>
          </div> 
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedContactsPage = connect(mapState, actionCreators)(ContactsPage);
export { connectedContactsPage as ContactsPage };
