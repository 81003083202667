import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

import { history } from "../../../_helpers";
import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

class EditDefaultObjectionsPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      fields: {},
      errors: {},
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      StatusAvailable: null,
      IsExist: false,
      OldObjection: "",
      IsButtonDisabled: false,
    };
    this.GetDefaultObjections();
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = "Edit | SalesHive";
  }

  GetDefaultObjections() {
    const InputParameter = {
      ID: this.props.location.state,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultobjections/DefaultObjectionsByID",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        this.setState({ OldObjection: res.data.Data.Objection });
        document.getElementById("Objection").value = res.data.Data.Objection;
        document.getElementById("Response").value = res.data.Data.Response;
        document.getElementById("hideloding").style.display = "none";
      } else {
        toast.error(res.data?.Message);
        document.getElementById("hideloding").style.display = "none";
      }
    });
  }

  async FormValidation() {
    let formIsValid = true;
    let errors = {};
    let Objection = document.getElementById("Objection").value.trim();
    var IsExist = await this.CheckObjectionExist(Objection);
    if (Objection.length <= 0) {
      formIsValid = false;
      errors["Objection"] = " Please enter objection";
    }
    if (IsExist == true) {
      formIsValid = false;
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  // handle change
  HandleChange(field, e) {
    let Fields = this.state.fields;
    Fields[field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Objection != "") {
      this.state.errors.Objection = null;
      this.state.StatusAvailable = null;
      this.CheckObjectionExist(e.target.value);
    } else {
      this.state.StatusAvailable = null;
      this.state.errors.Name = null;
    }
  }

  //check exist Status
  async CheckObjectionExist(Name) {
    var Inputparameter = {
      ClientID: this.state.ClientID,
      Objection: Name,
    };
    var resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultobjections/DefaultObjectionExist",
      method: "POST",
      data: Inputparameter,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (this.state.OldObjection === Name) {
          this.setState({ StatusAvailable: null });
          this.setState({ IsExist: false });
          return false;
        } else {
          this.setState({ StatusAvailable: resdata.data.Data[0]?.Objection });
          this.setState({ IsExist: true });
          return true;
        }
      } else {
        this.setState({ IsExist: false });
        return false;
      }
    }
  }

  BackBtn() {
    history.push("/defaultobjections");
  }

  async SaveBtn(e) {
    this.state.errors.Objection = null;
    this.state.StatusAvailable = null;
    this.setState({ IsSubmitting: true });
    var FinalValidation = await this.FormValidation();
    if (FinalValidation == true) {
      var Objection = document.getElementById("Objection").value;
      var Response = document.getElementById("Response").value;
      var InputParameter = {
        ID: this.props.location.state,
        ClientID: null, // No need ClientID ,it will work only on UserID (for Globle page)
        UserID: this.state.UserID,
        Objection: Objection,
        Response: Response,
        LastUpdatedBy: this.state.CUserID,
        LastUpdatedDate: new Date(),
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/defaultobjections/DefaultObjectionsUpdate",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Default Objections
              <br />
              Default objection updated successfully.
            </div>
          );
          history.push("/defaultobjections");
        } else {
          toast.error(res.data.Message);
        }
        this.setState({ IsSubmitting: false });
      });
    } else {
      // document.getElementById("submit").disabled = false;
      this.setState({ IsSubmitting: false });
    }
  }

  render() {
    return (
      <>
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left">Edit </h4>
                </div>
              </div>

            <div class="paddcols">
              <div className="row pt-2 pb-2">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Objection</label>
                    <div class="col-lg-8">
                      <textarea
                        class="form-control m-input"
                        onBlur={this.HandleChange.bind(this, "Objection")}
                        placeholder="Please enter objection"
                        id="Objection"
                      ></textarea>
                      <span style={{ color: "red" }}>
                        {this.state.errors["Objection"]}
                      </span>
                      {this.state.StatusAvailable && (
                        <span style={{ color: "red" }}>
                          Objection already exist.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row pt-2 pb-2">
                <div class="col-lg-6 boxsinput_group py-0">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Response</label>
                    <div class="col-lg-8">
                      <textarea
                        class="form-control m-input"
                        placeholder="Please enter objection"
                        id="Response"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row mb-4 pt-4">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.IsSubmitting}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditDefaultObjectionsPage = connect(
  mapState,
  actionCreators
)(EditDefaultObjectionsPage);
export { connectedEditDefaultObjectionsPage as EditDefaultObjectionsPage };
