import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { CSVReader, CSVDownloader } from "react-papaparse";

import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import ReactApexchart from "react-apexcharts";
//chart
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import ReactApexChart from "react-apexcharts";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable({ FromDateMeeting, ToDateMeeting }) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("MeetingSetDate");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [sortedColumn, setSortedColumn] = React.useState("Meeting Set Date");
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [Notes, SetNotes] = React.useState([]);
  const [ResponderData, SetResponderData] = React.useState([]);
  const [OldClients, SetOldClients] = React.useState(false);
  const [ResponderID, SetResponderID] = React.useState("");
  const [SourceData, SetSourceData] = React.useState({});
  const [StatusData, SetStatusData] = React.useState({});
  const [TotalMeeting, SetTotalMeeting] = React.useState(0);
  const [ExportData, SetExportData] = React.useState([]);
  const [ExportDataBlanck, SetExportDataBlanck] = React.useState([]);
  const [TotalGoal, SetTotalGoal] = React.useState([]);
  const [TotalEvent, SetTotalEvent] = React.useState([]);
  const [TotalGoalSum, SetTotalGoalSum] = React.useState(0);
  const [TotalEventSum, SetTotalEventSum] = React.useState(0);
  const [TotalWeekDay, SetTotalWeekDay] = React.useState([]);
  const [TotalCrush, SetTotalCrush] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);
  const [MeetingStatus, setMeetingStatus] = React.useState([]);
  const [HeaderList, SetHeaderlist] = React.useState([]);

  const classes = useRowStyles();

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

    OwnerDropDown(
      Details.ClientID,
      FromDateMeeting,
      ToDateMeeting,
      Details.Role
    );

    GraphSourceGet(
      Details.ClientID,
      FromDateMeeting,
      ToDateMeeting,
      Details.Role
    );
    GraphStatusGet(
      Details.ClientID,
      FromDateMeeting,
      ToDateMeeting,
      Details.Role
    );
    MeetingKeyMatricGet(
      Details.ClientID,
      Details.ParentUserID,

      FromDateMeeting,
      ToDateMeeting,
      Details.Role
    );
    MeetingExportHandler(
      Details.ClientID,
      FromDateMeeting,
      ToDateMeeting,
      Details.Role
    );
    KpiMeetingGet(
      Details.ClientID,
      Details.ParentUserID,
      Details.Role,
      FromDateMeeting,
      ToDateMeeting
    );
  }, [
    Search,
    Page,
    RowsPerPage,
    ResponderID,
    OldClients,
    FromDateMeeting,
    ToDateMeeting,
  ]);

  charts(FusionCharts);

  //Source Status Chart
  const GraphSourceGet = (CID, FromDates, ToDates, role) => {
    const InputParameters = {
      ClientID: CID,
      ToDate: ToDates,
      FromDate: FromDates,
      Role: role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/GraphSourceGet",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      var dataSource = {
        chart: {
          //caption: "Meeting Source",
          //subcaption: "For a net-worth of $1M",
          theme: "fusion",
          subCaption: " ",
          formatnumberscale: "0",
          showLegend: "1",
          showLabels: "0",
          // showValues: "1",
          valuePosition: "inside",
          numbersuffix: "%",
          showPercentValues: "0",
          showPercentInToolTip: "0",
          enableSlicing: "1",
          legendPosition: "bottom",
          legendBgColor: "#fff",
          legendBorderColor: "#fff",
          legendShadow: 0,
          legendItemFontSize: "13",
          legendNumColumns: "2",
          plothighlighteffect: "fadeout|color=#111C43, alpha=100",
          legendScrollBgColor: "#fff",
          minimiseWrappingInLegend: "1",
          alignLegendWithCanvas: "1",
          legendNumRows: 3,
        },
        data: Res.data.data,
      };
      SetSourceData(dataSource);
    });
  };

  //Status Chart
  const GraphStatusGet = (CID, FromDates, ToDates, role) => {
    const InputParameters = {
      ClientID: CID,
      ToDate: ToDates,
      Role: role,
      FromDate: FromDates,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/GraphStatusGet",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      // this.setState({ GraphData: Res.data.data });
      // this.setState({TotalReschedules:Res.data.TotalmeetingCount})
      var dataStatus = {
        chart: {
          //caption: "Meeting Status",
          //subcaption: "For a net-worth of $1M",
          theme: "fusion",
          subCaption: " ",
          formatnumberscale: "0",
          showLegend: "1",
          showLabels: "0",
          // showValues: "1",
          valuePosition: "inside",
          numbersuffix: "%",
          showPercentValues: "0",
          showPercentInToolTip: "0",
          enableSlicing: "1",
          legendPosition: "bottom",
          legendBgColor: "#fff",
          legendBorderColor: "#fff",
          legendShadow: 0,
          legendItemFontSize: "13",
          legendNumColumns: "2",
          plothighlighteffect: "fadeout|color=#111C43, alpha=100",
          legendScrollBgColor: "#fff",
          minimiseWrappingInLegend: "1",
          alignLegendWithCanvas: "1",
          legendNumRows: 3,
        },
        data: Res.data.data,
      };
      SetStatusData(dataStatus);
    });
  };

  const MeetingKeyMatricGet = (CID, UID, FromDates, ToDates, role) => {
    // SetIsLoading(true)
    const InputParameters = {
      ClientID: CID,
      UserID: UID,
      Role: role,
      ToDate: ToDates,
      FromDate: FromDates,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiMeetingKeyMatric",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      if (Res.data.StatusMessage == "SUCCESS") {
        if (Res.data.Data.length > 0) {
          var lstTotalGoal = [];
          var lstTotalEvent = [];
          var lstTotalWeekDay = [];
          var objTotalGoalSum = 0;
          var objTotalEventSum = 0;
          for (var i = 0; i < Res.data.Data.length; i++) {
            var Goal = Res.data.Data[i].TotalGoal;
            var Event = Res.data.Data[i].TotalEvent;
            var WeekDay = Res.data.Data[i].WeekDay;
            var GraphGoal = Res.data.Data[i].GraphTotalGoal;
            var GraphEvent = Res.data.Data[i].GraphTotalMeeting;
            lstTotalGoal.push(GraphGoal);
            objTotalGoalSum = objTotalGoalSum + Goal;

            lstTotalEvent.push(GraphEvent);
            objTotalEventSum = objTotalEventSum + Event;

            lstTotalWeekDay.push(WeekDay);
          }
          SetTotalGoal(lstTotalGoal);
          SetTotalEvent(lstTotalEvent);
          SetTotalGoalSum(objTotalGoalSum);
          SetTotalEventSum(objTotalEventSum);
          SetTotalWeekDay(lstTotalWeekDay);
          SetTotalCrush(
            objTotalEventSum > 0 && objTotalGoalSum > 0
              ? Math.round((objTotalEventSum * 100) / objTotalGoalSum)
              : 0
          );
          // SetIsLoading(false)
        } else {
          SetTotalGoal([]);
          SetTotalEvent([]);
          SetTotalGoalSum(0);
          SetTotalEventSum(0);
          SetTotalWeekDay([]);
          //SetIsLoading(false)
        }
      } else {
        SetTotalGoal([]);
        SetTotalEvent([]);
        SetTotalGoalSum(0);
        SetTotalEventSum(0);
        SetTotalWeekDay([]);
        // SetIsLoading(false)
      }
    });
  };

  const state = {
    series: [
      {
        name: "Goal",
        color: "#360947",
        data: TotalGoal,
      },
      {
        name: "Meetings",
        color: "#F8BF58",
        data: TotalEvent,
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
        toolbar: {
            show: false
        }
      },
      // plotOptions: {
      //   bar: {
      //     horizontal: false,
      //     columnWidth: "55%",
      //     endingShape: "rounded",
      //   },
      // },
      // dataLabels: {
      //   enabled: false,
      // },
      stroke: {
        width: [2, 2, 2, 2],
        curve: 'smooth'
      },
      xaxis: {
        categories: TotalWeekDay,
      },
      yaxis: {
        title: {
          text: "Count",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      legend: {
        position: "right",
        horizontalAlign: "bottom",
        offsetX: -10,
      },
      markers: {
        size: 4,
        strokeWidth: 1.5,
        hover: {
          size: 6 // Size of the marker when hovered
        }
      }
    },
  };

  // kpiMeeting get list
  const KpiMeetingGet = (CID, UID, URole, FromDates, ToDates) => {
    SetIsLoading(true);

    var KipsMeetingData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      ToDate: ToDates,
      FromDate: FromDates,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiMeetingListGet",
      method: "POST",
      data: KipsMeetingData,
    })
      .then((result) => {
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
        SetTotalMeeting(result.data.TotalCount);
        SetIsLoading(false);
      })
      .catch((error) => {
        SetIsLoading(false);
      });
  };

  //OwnerData Drop down list
  const OwnerDropDown = (CId, FromDates, ToDates, URole) => {
    var InputParameters = {
      ClientID: CId,
      Role: URole,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/MeetingOwnerGetKpi",
      method: "POST",
      data: InputParameters,
    });
    rows1
      .then((Result) => {
        SetNotes(Result.data.KpisMeetingNotes);
        setMeetingStatus(Result.data.MeetingStatus);
        SetHeaderlist(Result.data?.MeetingHeader);
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  //Get sort field data
  const SortData = (FieldName) => {
    SetIsLoading(true);
    let Field;
    if (FieldName == "Title") {
      Field = "JobTitle";
    } 
    // else if (FieldName == "MeetingSetDate") {
    //   Field = "EventCreatedDt";
    // } 
    // else if (FieldName == "MeetingDate") {
    //   Field = "EventStartDt";
    // }
     else if (FieldName == "CreatedDate") {
      Field = "CreatedDt";
    } else if (FieldName == "MeetingStatus") {
      Field = "Status";
    } else if (FieldName == "Owner") {
      Field = "MeetingOwner";
    } else if (FieldName == "SendReminder") {
      Field = "IsSendMeetingReminder";
    } else if (FieldName == "MeetingSetBy") {
      Field = "ResponserName";
    } else {
      Field = FieldName;
    }
    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        ToDate: ToDateMeeting,
        FromDate: FromDateMeeting,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/kpis/KpiMeetingListGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((result) => {
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
        SetTotalMeeting(result.data.TotalCount);
        SetIsLoading(false);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        ToDate: ToDateMeeting,
        FromDate: FromDateMeeting,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/kpis/KpiMeetingListGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((result) => {
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
        SetTotalMeeting(result.data.TotalCount);
        SetIsLoading(false);
      });
    }
  };

  // //View Meeting
  // const ViewPage = (id) => {
  //   history.push("/viewbookedmeeting", id);
  // };

  const ViewPage = (id) => {
    // history.push("/viewbookedmeeting", id);
    history.push({
      pathname: "/viewbookedmeeting",
      state: { data: id, Pagename: "kpiMeeting" },
    });
  };

  //Add Meeting
  // const AddPage = () => {
  //   history.push("/addbookedmeeting");
  // };

  const AddPage = () => {
    history.push({
      pathname: "/addbookedmeeting",
      state: { Pagename: "kpiMeeting" },
    });
  };

  //Export Meeting
  const MeetingExportHandler = (CID, FromDates, ToDates, role) => {
    let InputParameter = {
      ClientID: CID,
      ToDate: ToDates,
      Role: role,
      FromDate: FromDates,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpisMeetingExport",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      SetExportData(res.data.Data);
      SetExportDataBlanck(res.data.Data1)
    });
  };

  const EditAccountHandle = (id) => {
    if (id !== undefined && id !== null && id !== "000000000000000000000000") {
      history.push({
        pathname: "/viewaccount",
        state: { data: id, Pagename: "kpiMeeting" },
      });
    }
  };

  const EditContactHandle = (id, Email) => {
    if (id !== undefined && id !== null && id !== "000000000000000000000000") {
      history.push({
        pathname: "/viewcontacts",
        state: { data: id, EmailData: Email, Pagename: "kpiMeeting" },
      });
    }
  };

  const ExportPopup = () => {
    if (ExportDataBlanck.length === 0) {
      toast.error("No data available");
    } else {
      toast.success("Data exported successfully.");
    }
  };

  const Bookedstatusselect = (event, EventID, UserID, StatusIDs, EmailID) => {
    SetIsLoading(true);
    let MeetingIDs = null;
    MeetingIDs = event.target.value;
    //find the meeting status name by id
    var data = {
      ClientID: ClientID,
      Role: Role,
      MeetingStatusID: event.target.value,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/bookedmeeting/findBookedMeetingStatus",
      method: "POST",
      data: data,
    }).then((res) => {
      var data = {
        // _id: id,
        EventID: EventID,
        ClientID: ClientID,
        UserID: UserID,
        MeetingStatusID: res.data.BookedStatus[0]?._id,
        Role: Role,
        OldMeetingStatusID: StatusIDs,
        Status: res.data.BookedStatus[0]?.Status,
        StatusName: document.getElementById(`MeetingStatusID${EventID}`)
          .options[
          document.getElementById(`MeetingStatusID${EventID}`).selectedIndex
        ].textContent,
        Email: EmailID,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/bookedmeeting/UpdateBookedMeetingStatusHistory",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          KpiMeetingGet(ClientID, UserID, Role, FromDateMeeting, ToDateMeeting);
          toast.success(
            "Booked meeting select option Status update successfully.",
            "Update Booked select option  meeting Status"
          );
        } else {
          toast.error(res.data.Message);
          SetIsLoading(false);
        }
      });
    });
  };

  return (
    <>
      {IsLoading ? (
        <>
          <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="row colfive">
        <div className="col-xl-4">
          <div class="bg-white boxcardcounter">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{TotalEventSum}</span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Total Meetings</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <div class="bg-white boxcardcounter">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{TotalGoalSum}</span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Total Meeting Goal</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <div class="bg-white boxcardcounter">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{TotalCrush}%</span>
                </div>
                <div class="box-inner-title">
                  <string class="clr-sky">Crush %</string>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div class="col-xl-12">
          <div className="bg-white w-100 p-3">
            <div id="chart chart-max450">
            <h6 className="chartheading py-0 pb-3 pl-0">Meeting Goals</h6>
              <ReactApexchart
                options={state.options}
                series={state.series}
                type="line"
                height={450}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-6 minheight"> 
        <div className="bg-white p-3">
          <div className="chartboxsmet h-100">
            <h6 className="chartheading">Meeting Source</h6>
            <ReactFusioncharts
              key={JSON.stringify(SourceData)}
              type="pie3d"
              width="100%"
              height="450"
              dataFormat="JSON"
              dataSource={SourceData}
            />
          </div>
          </div>
        </div>
        <div className="col-md-6 minheight">
          <div className="chartboxsmet h-100">
          <div className="bg-white p-3">
            <h6 className="chartheading">Meeting Status</h6>
            <ReactFusioncharts
              key={JSON.stringify(StatusData)}
              type="pie3d"
              width="100%"
              height="450"
              dataFormat="JSON"
              dataSource={StatusData}
            />
          </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col d-flex align-items-center justify-content-between">
          <h4 className="headertitlenop float-left pl-0">Meetings Details</h4>
          <div className="listing-li float-right padb-15">
            <ul>
              <li>
                {ExportDataBlanck.length === 0 ? (
                  <a
                    onClick={() => {
                      ExportPopup()
                    }}
                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                  >
                    <i class="la la-icon-font-size-13 la-download"></i>
                    <span>Export</span>
                  </a>
                ) : (
                  <CSVDownloader
                    className="px-0"
                    data={ExportData}
                    filename={"KpisMeeting"}
                    bom={true}
                  >
                    <a
                      onClick={() => {
                        ExportPopup()
                      }}
                      className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                    >
                      <i class="la la-icon-font-size-13 la-download"></i>
                      <span>Export</span>
                    </a>
                  </CSVDownloader>
                )}
              </li>
              <li>
                <a
                  onClick={() => {
                    AddPage();
                  }}
                  className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                >
                  <i class="la la-icon-font-size-13 la-plus"></i>
                  <span>Add</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="">
        <div className="row padt-25">
          <div className="col-sm-12 col-md-6">
            <label className="textlabelte">
              Show
              <select
                name="tbl_meeting_length"
                onChange={ChangeRowSelected}
                aria-controls="tbl_meeting"
                class="form-control form-control-sm"
                value={RowsPerPage}
              >
                {CommonConstants.show_rows.map((value) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
              entries
            </label>
          </div>
          <div className="col-sm-12 col-md-6 full-right">
            <label class="textlabelte">
              Search:
              <input
                type="Search"
                id="search"
                onKeyPress={RequestSearch}
                class="form-control form-control-sm ml-2"
                placeholder=""
                aria-controls="tbl_meeting"
              />
            </label>
          </div>
        </div>
        <div className="table-bordered">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>Notes</TableCell>
                  <TableCell>Action</TableCell>
                  {HeaderList?.map((row) => (
                    <TableCell
                      key={row._id}
                      onClick={() => {
                        SortData(row.ColumnName.replace(/\s+/g, ""));
                        setSortedColumn(row.ColumnName); // Set the sorted column
                      }}
                    >
                      {row.ColumnName}
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && sortedColumn === row.ColumnName
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && sortedColumn === row.ColumnName
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </TableCell>
                  ))}
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Rows?.length === 0 ? (
                  <p className="text-center">No data available in table</p>
                ) : (
                  Rows?.map((value1) => (
                    <>
                      <TableRow key={value1._id}>
                        <TableCell align="center">
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              setOpen((prev) => ({
                                //...prev,
                                [value1._id]: !prev[value1._id],
                              }));
                              // GetNotes(row.EventID);
                            }}
                          >
                            {open[value1._id] ? (
                              <i
                                className="fa fa-minus-circle minusl"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <i
                                className="fa fa-plus-circle plusbl"
                                aria-hidden="true"
                              ></i>
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <a
                            title="View"
                            className="btn-eyesicon"
                            onClick={() => {
                              ViewPage(value1._id);
                            }}
                          >
                            <i class="la flaticon-eye edit-icon"></i>
                          </a>
                        </TableCell>
                        {HeaderList.map((row) => {
                          if (row.ColumnName == "Title") {
                            return <TableCell>{value1?.JobTitle}</TableCell>;
                          } else if (row.ColumnName == "Meeting Date") {
                            return (
                              <TableCell>
                                {value1?.EventStartDt != null &&
                                value1?.EventStartDt !== "Invalid date"
                                  ? value1?.EventStartDt
                                  : ""}
                              </TableCell>
                            );
                          } else if (row?.ColumnName == "Domain") {
                            return (
                              <TableCell>
                                <a
                                  href={`http://${value1?.Domain}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    textDecoration: "underline",
                                    color: "inherit",
                                  }}
                                >
                                  <u> {value1?.Domain}</u>
                                </a>
                              </TableCell>
                            );
                          } else if (row.ColumnName == "Meeting Set Date") {
                            return (
                              <TableCell>
                                {value1?.EventCreatedDt != null &&
                                value1?.EventCreatedDt != "Invalid date"
                                  ? value1?.EventCreatedDt
                                  : ""}
                              </TableCell>
                            );
                          } else if (row.ColumnName == "LinkedIn Url") {
                            return <TableCell>{value1?.LinkedInUrl}</TableCell>;
                          } else if (row.ColumnName == "Industry") {
                            return <TableCell>{value1?.Industry}</TableCell>;
                          } else if (row.ColumnName == "Meeting Status") {
                            return (
                              <TableCell>
                                {
                                  <select
                                    onChange={(e) => {
                                      Bookedstatusselect(
                                        e,
                                        value1?._id,
                                        value1?.UserID,
                                        value1?.StatusID,
                                        value1?.Email
                                      );
                                    }}
                                    className="form-control  m-input w-auto"
                                    id={`MeetingStatusID${value1?._id}`}
                                    name="MeetingStatusID"
                                    autocomplete="off"
                                    value={value1?.StatusID}
                                  >
                                    {MeetingStatus?.map((value) => (
                                      <option value={value?._id}>
                                        {value?.Status}
                                      </option>
                                    ))}
                                  </select>
                                }
                              </TableCell>
                            );
                          } else if (row.ColumnName == "Company") {
                            return <TableCell>{value1?.Company}</TableCell>;
                          } else if (row.ColumnName == "Contact Name") {
                            return (
                              <TableCell>
                                <a
                                  onClick={() => {
                                    EditContactHandle(
                                      value1?.Prospectid,
                                      value1.Email
                                    );
                                  }}
                                >
                                  <u>{value1?.ContactName}</u>
                                </a>
                              </TableCell>
                            );
                          } else if (row.ColumnName == "Contract Value") {
                            return (
                              <TableCell>{value1?.ContractValue}</TableCell>
                            );
                          } else if (row.ColumnName == "Email") {
                            return <TableCell>{value1?.Email}</TableCell>;
                          } else if (row.ColumnName == "Send Reminder") {
                            return (
                              <TableCell>
                                {value1?.IsSendMeetingReminder === true
                                  ? "Yes"
                                  : "No"}
                              </TableCell>
                            );
                          } else if (row.ColumnName == "Meeting Source") {
                            return (
                              <TableCell>{value1?.MeetingSource}</TableCell>
                            );
                          } else if (row.ColumnName == "Account Name") {
                            return (
                              <TableCell>
                                <a
                                  onClick={() => {
                                    EditAccountHandle(value1?.AccountNameID);
                                  }}
                                >
                                  <u>
                                    {value1?.AccountName == undefined ||
                                    value1?.AccountName == ""
                                      ? ""
                                      : value1?.AccountName}
                                  </u>
                                </a>
                              </TableCell>
                            );
                          } else if (row.ColumnName == "Owner") {
                            return (
                              <TableCell>{value1?.MeetingOwner}</TableCell>
                            );
                          } else if (row.ColumnName == "Contact Source") {
                            return (
                              <TableCell>{value1?.ContactSource}</TableCell>
                            );
                          } else if (
                            row.CustomFieldID != null &&
                            row.CustomFieldID != ""
                          ) {
                            let valcust = value1.CustomField?.filter(
                              (item) => item.CustomFieldID == row?.CustomFieldID
                            );
                            if (valcust?.length > 0) {
                              return (
                                <TableCell>{valcust[0]?.FieldValue}</TableCell>
                              );
                            } else {
                              return <TableCell></TableCell>;
                            }
                          } else {
                            return <TableCell></TableCell>;
                          }
                        })}
                        <TableCell>
                          <a
                            title="View"
                            className="btn-eyesicon"
                            onClick={() => {
                              ViewPage(value1._id);
                            }}
                          >
                            <i class="la flaticon-eye edit-icon"></i>
                          </a>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse
                            in={open[value1._id]}
                            timeout="auto"
                            unmountOnExit
                          >
                            {open[value1._id] && (
                              <Box margin={1} className="innertables">
                                <Table size="small" aria-label="purchases">
                                  <TableHead></TableHead>

                                  {Notes?.map((note) =>
                                    note?.MeetingID == value1?._id ? (
                                      <TableRow key={note.MeetingID}>
                                        <TableCell>{note.Title}</TableCell>
                                        <TableCell className="labeltds">
                                          {" "}
                                          {moment(
                                            new Date(
                                              note?.CreatedDate
                                            ).toDateString()
                                          ).format("MM/DD/YYYY")}
                                        </TableCell>
                                        <TableCell scope="row">
                                          {note.Note}
                                        </TableCell>
                                        <TableCell className="labeltds"></TableCell>
                                        <TableCell className="labeltds"></TableCell>
                                      </TableRow>
                                    ) : null
                                  )}

                                  {!Notes.some(
                                    (note) => note.MeetingID == value1._id
                                  ) && (
                                    <TableRow>
                                      <TableCell colSpan={4}>
                                        No data available
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </Table>
                              </Box>
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {Sflag ? (
          <div class="row">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
            <div class="col dataTables_info">
              <p>
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                {Rlen} entries (filtered from {Flen} total entries)
              </p>
            </div>
          </div>
        ) : (
          <div class="row">
            <div class="col dataTables_info">
              <p>
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                {Rlen} entries
              </p>
            </div>
            <div class="col pageright">
              <Pagination
                component="div"
                count={CountPage}
                onChange={HandleChangePage}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
