import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Pagination from "@material-ui/lab/Pagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingiconLoad from "../../../images/loading.gif";

export default function AccountAllTable() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(true);
  const [CUserID, SetCUserID] = React.useState(0);
  const [sortedColumn, setSortedColumn] = React.useState("CreatedDate");


  useEffect(() => {
    let Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    ContactBulkExportRequestGet(
      Details.ClientID,
      Details.ParentUserID,
      Details.Role
    );
  }, [Search, Page, RowsPerPage]);

  // Hard Bounce list
  const ContactBulkExportRequestGet = (CID, UID, URole) => {
    SetIsLoading(true);
    let InpurtData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      Sortby: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };

    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/contact_bulk_export_request/ContactBulkExportRequestGet",
      method: "POST",
      data: InpurtData,
    });
    rows1.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetIsLoading(false);
    });
  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      let SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //get sort field data
  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let Sortby = SortedBy === 1 ? -1 : 1;
    
    if (isNewSortField) {
      SetSortedBy(1);
      Sortby = 1;
    } else {
      SetSortedBy(Sortby);
    }
  
    SetSortField(Field);
    SetSflag(SearchedVal !== "");
  
    const InputParameter = {
      Page,
      RowsPerPage,
      SerchBox: SearchedVal !== "",
      Sort: true,
      Search: SearchedVal,
      Field,
      Sortby,
      Type: "User",
      ClientID,
      UserID,
      Role,
    };
  
    Axios({
      url: `${CommonConstants.MOL_APIURL}/contact_bulk_export_request/ContactBulkExportRequestGet`,
      method: "POST",
      data: InputParameter,
    }).then((Result) => {
      SetPage(1)
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false);
    });
  };

  return (
    <>
      {IsLoading === true ? (
        <div
          id="hideloding"
          className="loding-display"
         // style={{ display: "none" }}
        >
          <img src={loadingiconLoad} />
        </div>
      ) : (
        <></>
      )}
      <div>
        <div className="row">
          <div className="col padd-0"></div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <label className="textlabelte">
              Show
              <select
                name="tbl_meeting_length"
                onChange={ChangeRowSelected}
                aria-controls="tbl_meeting"
                class="form-control form-control-sm"
                value={RowsPerPage}
              >
                {CommonConstants.show_rows.map((value) => (
                  <option value={value} key={value}>{value}</option>
                ))}
              </select>
              entries
            </label>
          </div>
          <div className="col-sm-12 col-md-6 full-right">
            <label class="textlabelte">
              Search:{" "}
              <input
                type="search"
                id="search"
                onKeyPress={RequestSearch}
                class="form-control form-control-sm ml-2"
                placeholder=""
                aria-controls="tbl_meeting"
              />
            </label>
          </div>
        </div>
        <div className="table-bordered">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                <TableCell onClick={() => {SortData("ClientName");setSortedColumn("ClientName");}}>
                  Client Name
                  <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "ClientName"? "active": null}/>
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "ClientName"? "active": null}/>
                  </span>
                </TableCell>
                <TableCell onClick={() => {SortData("Email");setSortedColumn("Email"); }}>Email
                  <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "Email"? "active" : null}/>
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "Email"? "active": null}/>
                  </span>
                </TableCell>
                <TableCell onClick={() => {SortData("InProgress");setSortedColumn("InProgress");}}>
                   In Progress
                  <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "InProgress"? "active": null}/>
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "InProgress"? "active": null}/>
                  </span>
                </TableCell>
                <TableCell onClick={() => {SortData("IsComplete");setSortedColumn("IsComplete"); }}
                >
                   Completed
                  <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "IsComplete"? "active": null}/>
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "IsComplete"? "active": null}/>
                  </span>
                </TableCell>
                <TableCell onClick={() => { SortData("CreatedDate"); setSortedColumn("CreatedDate");}}>
                   Created Date
                  <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "CreatedDate"? "active": null}/>
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "CreatedDate"? "active": null}/>
                  </span>
                </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Rows.length == 0 ? (
                  <p className="text-center">No data available in table</p>
                ) : (
                  Rows.map((row) => (
                    <TableRow key={row?._id} row={row}>
                      <TableCell>{row.ClientName}</TableCell>
                      <TableCell>{row.Email}</TableCell>
                      <TableCell>{row.InProcess ? "Yes" : "No"}</TableCell>
                      <TableCell>{row.IsComplete ? "Yes" : "No"}</TableCell>
                      <TableCell>{moment(new Date(row.CreatedDate).toDateString()).format("MM/DD/YYYY")}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        
          <div class="row">
            <div class="col dataTables_info">
              <p>
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                {Rlen} entries
              </p>
            </div>
            <div class="col pageright">
              <Pagination
                component="div"
                count={CountPage}
                page={Page}
                onChange={HandleChangePage}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
      </div>
    </>
  );
}
