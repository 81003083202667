import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import Axios from "axios";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class EditObjectionsPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      fields: {},
      Errors: {},
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      OldObjection: null,
      IsExist: false,
      ObjectionsAvailble: null,
      IsButtonDisabled: false,
      IsLoading:false
    };
    this.ObjectionGetByID();
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
  }
  componentDidMount() {
    document.title = "Edit | SalesHive";
  }

  ObjectionGetByID() {
    const Inputparameters = {
      ID: this.props.location.state,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/objection/ObjectionGetByID",
      method: "POST",
      data: Inputparameters,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById("Objection").value = res.data.Data.Objection;
        document.getElementById("Response").value = res.data.Data.Response;
        this.setState({ OldObjection: res.data.Data.Objection });
         this.setState({ IsLoading: false});;
      } else {
        toast.error(res.data.Message);
         this.setState({ IsLoading: false});;
      }
    });
  }

  // form validation
  async FormValidation() {
    let formIsValid = true;
    var Objection = document.getElementById("Objection").value.trim();
    var IsExists = await this.CheckExistsObjection(Objection);
    let Errors = {};
    if (IsExists == "BlankData" && Objection == "") {
      formIsValid = false;
      Errors["Objection"] = "Please enter objection";
    }
    if (IsExists == "ExistObjection") {
      formIsValid = false;
      Errors["Objection"] = "Objection already exist.";
    }
    this.setState({ Errors: Errors });

    return formIsValid;
  }

  async HandleChangeBlur(field,e) {
    let Errors = { ...this.state.Errors };
    const value = e.target.value.trim();
    let IsExists;
  
    if (field === "Name") { // Change "Country" to "Name"
      IsExists = await this.CheckExistsObjection(value); // Change "CoutryNameCheckExist" to "ClientPocNameCheckExist"
  
      if (IsExists === "BlankData" && value === "") {
        Errors.Name = "Please enter objection"; // Change "Country" to "Name"
      } else if (IsExists === "ExistObjection") {
        Errors.Name = "Objection already exist."; // Change "Country" to "Name"
      } else if (IsExists === "AllDone") {
        Errors.Name = null; // Change "Country" to "Name"
      }
    }
  
    this.setState({ Errors });
  }

  // handle change
  HandleChange() {
    var Objection = document.getElementById("Objection").value.trim();

    if (Objection != "" && Objection.indexOf(" ") != "") {
      this.state.Errors.Objection = null;
      this.state.ObjectionsAvailble = null;
      this.CheckExistsObjection(Objection);
    } else {
      this.state.ObjectionsAvailble = null;
      this.state.Errors.Objection = null;
    }
  }

  // Contact Custom Field Name Check Exits
  async CheckExistsObjection(Objection) {
     this.setState({ IsLoading: true});
    if (Objection != "") {
      var str_in = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Objection: Objection,
      };
      var resdata = await Axios({
        url: CommonConstants.MOL_APIURL + "/objection/objectionExists",
        method: "POST",
        data: str_in,
      });
      // if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          if (this.state.OldObjection == Objection) {
             this.setState({ IsLoading: false});;
            return "AllDone";
          } else {
             this.setState({ IsLoading: false});;
            return "ExistObjection";
          }
        } else {
           this.setState({ IsLoading: false});;
          return "AllDone";
        }
      // }
    } else {
       this.setState({ IsLoading: false});;
      return "BlankData";
    }
  }

  backbtn() {
    history.push("/objections");
  }
  async savebtn() {
    this.setState({ IsButtonDisabled: true });
    var Final_flag = await this.FormValidation();
    if (Final_flag == true) {
      var Objection = document.getElementById("Objection").value.trim();
      var Response = document.getElementById("Response").value.trim();
      var InputData = {
        ID: this.props.location.state,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Objection: Objection,
        Response: Response,
        LastUpdatedBy: this.state.CUserID,
        LastUpdatedDate: new Date(),
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/objection/ObjectionUpdate",
        method: "POST",
        data: InputData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div>
              Objections <br />
              Objection updated successfully.
            </div>
          );
          history.push("/objections");
        } else {
          toast.error(res.data.Message);
          this.setState({ IsButtonDisabled: false });
        }
      });
    } else {
      this.setState({ IsButtonDisabled: false });
    }
  }

  render() {
    return (
      <>
         {
        this.state.IsLoading === true ? <>
        <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
        </> : <></>
      }
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left py-4 mb-0">Edit</h4>
                </div>
              </div>

            <div class="paddcols px-3 pt-0">
              <div className="row pt-4 px-3">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Objection</label>
                    <div class="col-lg-7">
                      <textarea
                        className="form-control m-input"
                        id="Objection"
                        placeholder="Enter objection"
                        onBlur={this.HandleChangeBlur.bind(this, "Objection")}
                      ></textarea>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Objection"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6"></div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Response </label>
                    <div class="col-lg-7">
                      <textarea
                        className="form-control m-input"
                        id="Response"
                        placeholder="Enter response"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div> 
            </div>

              <div class="row mb-4 mt-4">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.savebtn}
                    disabled={this.state.IsButtonDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <button
                    id="backtolist"
                    onClick={this.backbtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </button>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditObjectionsPage = connect(
  mapState,
  actionCreators
)(EditObjectionsPage);
export { connectedEditObjectionsPage as EditObjectionsPage };
