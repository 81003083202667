import React, { useEffect } from 'react';
import Pagination from "@material-ui/lab/Pagination";
import Axios from "axios";
import { CommonConstants } from "../../../_constants/common.constants";
import { getFirstDayOfQuarter, GetUserDetails } from "../../../_helpers/Utility";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import loadingicon from "../../../images/loading.gif";


export default function KPIsReporting({ FromDate, ToDate, IsAnalyze }) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);

  const [KPIsReportingData, SetKPIsReportingData] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(true);
  const [activeButton, SetActiveButton] = React.useState('Week');

  useEffect(() => {

    const Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      
      if (FromDate != "" && ToDate != "") {
        if (IsAnalyze) {
      
          GetKPIsReporting(Details.ClientID, Details.ParentUserID, FromDate, ToDate, activeButton);
        } else {
          GetKPIsReporting(Details.ClientID, Details.ParentUserID, FromDate, ToDate, activeButton);
        }
      }
    }
  }, [Page, RowsPerPage,activeButton, FromDate, ToDate, IsAnalyze]);


  
  const GetKPIsReporting = (ClientID, UserID, FromDate, ToDate, DisplayBy) => {
    SetIsLoading(true)
    var requestData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Field: SortField,
      SortBy: SortedBy,
      ClientID: ClientID,
      UserID: UserID,
      FromDate: FromDate,
      ToDate: ToDate,
      DisplayBy: DisplayBy

    };
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/kpisreporting",
      method: "POST",
      data: requestData,
    });
    Row.then((response) => {

      if (response.data.StatusMessage = "SUCCESS") {
        if (response.data.PageData.length > 0) {
          SetKPIsReportingData(response.data.PageData);
          SetRlen(response.data.TotalCount);
          SetFlen(response.data.TotalCount);
          SetCountPage(response.data.PageCount);
          SetIsLoading(false)
        }
        else {
          SetKPIsReportingData([]);
          SetRlen(0);
          SetFlen(0);
          SetCountPage(0);
          SetIsLoading(false)
        }
      }
      else {
        SetKPIsReportingData([]);
        SetRlen(0);
        SetFlen(0);
        SetCountPage(0);
        SetIsLoading(false)
      }

    });
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };
  const handleGroupingDate = (name) => {
    SetActiveButton(name); 
    //console.log(name); 
  };



  return (
    <>

      <div className="row mb-2 px-3">
        <div class="col-md-6">
          <h4 class="smheadtext xs-headertitle pl-0 mt-0">KPIs Reporting </h4>
        </div>
     
        <div className="col-md-6">
          <div class="listing-li float-right dailyfilter mt-3">
            <ul class="mt-0">
              <li className='px-0'>
                <a className={`btn btngroup m-btn ${activeButton === 'Day' ? 'active' : ''}`}  onClick={() => handleGroupingDate('Day')}>
                  <span>Daily</span>
                </a>
              </li>
              <li className='px-0'>
                <a className={`btn btngroup m-btn ${activeButton === 'Week' ? 'active' : ''}`}  onClick={() => handleGroupingDate('Week')}>
                  <span>Weekly</span>
                </a>
              </li>
              <li className='px-0'>
                <a className={`btn btngroup m-btn ${activeButton === 'Month' ? 'active' : ''}`}  onClick={() => handleGroupingDate('Month')}>
                  <span>Monthly</span>
                </a>
              </li>

            </ul>
          </div>
        </div>
      </div>

      <div className="row px-3">
        <div className="col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>



      </div>



      <div className="table-bordered pt-0 mx-3">

        <TableContainer component={Paper} className='position-relative'>
          {
            IsLoading === true ?
              <div id="hideloding" className="loadingchart">
                <img src={loadingicon} />
              </div> : <></>
          }
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Emails Sent</TableCell>
                <TableCell>Email Responses</TableCell>
                <TableCell>Email Meetings </TableCell>
                <TableCell>Dials Made</TableCell>
                <TableCell>Connections Made</TableCell>
                <TableCell>Phone Meetings </TableCell>
                <TableCell>Total Meetings </TableCell>
              </TableRow>
            </TableHead>
            <TableBody >

              {KPIsReportingData?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (KPIsReportingData.map((row, i) => (
                <React.Fragment key={i}>
                  <TableRow row={row}>
                    <TableCell>{row.Date}</TableCell>
                    <TableCell>{row.EmailSent}</TableCell>
                    <TableCell>{row.ReplyCount}</TableCell>
                    <TableCell>{row.EmailMeeting}</TableCell>
                    <TableCell>{row.TotalDial}</TableCell>
                    <TableCell>{row.ConnectionsMade}</TableCell>
                    <TableCell>{row.PhoneMeeting}</TableCell>
                    <TableCell>{row?.EmailMeeting + row?.PhoneMeeting}</TableCell>
                  </TableRow>

                </React.Fragment>
              )))}



            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div className="row px-3 pb-3">
          <Pagination
            component="div"
            count={CountPage}
            page={Page}
            onChange={PageChange}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row px-3 pb-3">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}

    </>
  );
}
