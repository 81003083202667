const moment = require("moment");
import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import CampaignResponces from '../../_components/clients/campaigns/ResponsesTable';
import Footer from "../../_components/user/footer/footer";
import $ from "jquery";
import {
  getFirstDayOfQuarter,
  getCurrentDayOfQuarter,
} from "../../_helpers/Utility";
//import CollapsibleTable from '../_components/usertable';

class CEmailResponcesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      FromDate: "",
      ToDate: "",
    };
    // this.SaveBtn = this.SaveBtn.bind(this);
    // this.daterefect = React.createRef();
    // this.daterefect2 = React.createRef();
  }

  componentDidMount() {
    // var fromdate = getFirstDayOfQuarter();

    // const previousMonthDate = new Date(fromdate);

    // // Convert to UTC date components
    // const utcYear = previousMonthDate.getUTCFullYear();
    // const utcMonth = String(previousMonthDate.getUTCMonth() + 1).padStart(
    //   2,
    //   "0"
    // );
    // const utcDay = String(previousMonthDate.getUTCDate()).padStart(2, "0");

    // // Format the date
    // const formattedDate = `${utcMonth}/${utcDay}/${utcYear}`;

    // // Set the value of the "from-date" input field
    // document.getElementById("from-date").value = formattedDate;
    // const $datepickerElement = $(this.daterefect.current);
    // $datepickerElement.datetimepicker({
    //   format: "mm/dd/yyyy",
    //   autoclose: true,
    //   container: "#app",
    //   todayBtn: true,
    //   todayHighlight: true,
    //   minView: "2",
    // });
    // document.getElementById("to-date").value = new Date().toLocaleDateString(
    //   "en-US",
    //   { month: "2-digit", day: "2-digit", year: "numeric" }
    // );
    // const $datepickerElement2 = $(this.daterefect2.current);
    // $datepickerElement2.datetimepicker({
    //   format: "mm/dd/yyyy",
    //   autoclose: true,
    //   container: "#app",
    //   todayBtn: true,
    //   todayHighlight: true,
    //   minView: "2",
    // });

    // var FromDate = moment
    //   .utc(document.getElementById("from-date").value)
    //   .format("YYYY-MM-DD");
    // var ToDate = moment
    //   .utc(document.getElementById("to-date").value)
    //   .format("YYYY-MM-DD");
    // this.setState({
    //   FromDate: FromDate,
    //   ToDate: ToDate,
    // });
  }

  SaveBtn() {
    // var FromDate = moment
    //   .utc(document.getElementById("from-date").value)
    //   .format("YYYY-MM-DD");
    // var ToDate = moment
    //   .utc(document.getElementById("to-date").value)
    //   .format("YYYY-MM-DD");
    // this.setState({ FromDate: FromDate, ToDate: ToDate });
  }

  render() {
    return (
      <>
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome"> 
              {/* <div className="row"> */}
                {/* <div className="col"> */}
                  <CampaignResponces/>
                 {/* </div>
              </div> */} 
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEmailResponcesPage = connect(
  mapState,
  actionCreators
)(CEmailResponcesPage);
export { connectedEmailResponcesPage as CEmailResponcesPage };
