import React, { useEffect, useState } from "react";
import Axios from "axios";
import Popup from "reactjs-popup";
var CryptoJS = require("crypto-js");
import Popupone from "reactjs-popup";
import Swal from "sweetalert2";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import { Alert } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import loadingicon from "../../../images/loading.gif";
import { toast } from "react-toastify";

import { CommonConstants } from "../../../_constants/common.constants";
const EmailTemplete = require("../../../_helpers/email-templete");
import{GetUserDetails} from '../../../_helpers/Utility';

import Pagenations from "../../Pagenations";

const REACT_APP_ENCRYPTION_SALT =  process.env.REACT_APP_ENCRYPTION_SALT
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function ResponderInviteTable({listupdate}) {


  const [Search, SetSearch] = React.useState("");
  const [ClientID, SetClientID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Flen, SetFlen] = useState(0);
  const [Sflag, SetSflag] = useState(false);
  const [Rlen, SetRlen] = useState(0);
  const [CountPage, SetCountPage] = useState(0);
  const [Rows, SetRows] = useState([]);
  const [Page, SetPage] = useState(1);
  const [RowsPerPage, SetRowsPerPage] = useState(100);
  const [Email, SetEmail] = useState("");
  const [InvitePopup, SetInvitePopup] = useState(false);
  const [RID, SetRID] = useState("");
  const [UserID, SetUserID] = useState(null);
  const [SortField, SetSortField] = React.useState("Email");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [Alertbox, SetAlertbox] = useState(false);
  const [CUserID,SetCUserID] = React.useState(0)
  const [sortedColumn, setSortedColumn] = React.useState(null);


  useEffect(() => {
    var GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    ResponderInviteGet(GetUserData.ClientID,GetUserData.ParentUserID,GetUserData.Role);

  }, [Search, Page, RowsPerPage,listupdate]);

  //get Invite responder list
  const ResponderInviteGet = (CID,UID,URole) => {
    SetIsLoading(true)
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const AdminListAPI = Axios({
      url:CommonConstants.MOL_APIURL +  "/responder_profile/ResponderInviteListGet",
      method: "POST",
      data: InputParameter,
    });
    AdminListAPI.then((Result) => {
      
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)
    });
  };

 
  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("Search").value?.trim();
      if(SearchedVal==Search){
        SetSearch(SearchedVal);
        SetPage(1);
      }else{
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if(NewPage==Page){
      SetPage(NewPage);
    }else{
        SetPage(NewPage);
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
    
  };

  //get sort field data

  const SortData = (Field) => {
    if(Field == '2FA' || Field == 'Action'){
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);

    }else{
      SetIsLoading(true)
      var SearchedVal = document.getElementById("Search").value?.trim();
      var SerchBox;
      if (SearchedVal == "") {
        SerchBox = false;
        SetSflag(false);
        var SortField = Field;
        var SortBy;
        if (SortedBy == 1) {
          SortBy = -1;
          SetSortedBy(-1);
        } else {
          SortBy = 1;
          SetSortedBy(1);
        }
        SetSortField(Field);
        var InputParameter = {
          Page: Page,
          RowsPerPage: RowsPerPage,
          SerchBox: false,
          Archive: false,
          Sort: true,
          Field: SortField,
          SortBy: SortBy,
          Type: "User",
          ClientID: ClientID,
          UserID: UserID,
          Role: Role,
          Search:SearchedVal
        };
        const AdminListAPI = Axios({
          url:CommonConstants.MOL_APIURL +"/responder_profile/ResponderInviteListGet",
          method: "POST",
          data: InputParameter,
        });
        AdminListAPI.then((Result) => {
          SetRows(Result.data.PageData);
          SetRlen(Result.data.TotalCount);
          SetFlen(Result.data.TotalCount);
          SetCountPage(Result.data.PageCount);
          SetIsLoading(false)
        });
      } else {
        SerchBox = true;
        SetSflag(true);
        var SortField = Field;
        var SortBy;
        if (SortedBy == 1) {
          SortBy = -1;
          SetSortedBy(-1);
        } else {
          SortBy = 1;
          SetSortedBy(1);
        }
        SetSortField(Field);
        var InputParameter = {
          Page: Page,
          RowsPerPage: RowsPerPage,
          Search: SearchedVal,
          SerchBox: SerchBox,
          Archive: false,
          Sort: true,
          Field: SortField,
          SortBy: SortBy,
          Type: "User",
          ClientID: ClientID,
          UserID: UserID,
          Role: Role,
        };
        const AdminListAPI = Axios({
          url:CommonConstants.MOL_APIURL +"/responder_profile/ResponderInviteListGet",
          method: "POST",
          data: InputParameter,
        });
        AdminListAPI.then((Result) => {
          SetRows(Result.data.PageData);
          SetRlen(Result.data.TotalCount);
          SetFlen(Result.data.TotalCount);
          SetCountPage(Result.data.PageCount);
        SetIsLoading(false)
  
  
        });
      }
    }
   
  };

  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a responder invitation.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        var data = {
          uID: ID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
          Role: Role,
        };
        Axios({
          url:CommonConstants.MOL_APIURL + "/responder_profile/InviteDelete",
          method: "POST",
          data: data,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Responder invitation deleted successfully.",
                "success"
              );
              ResponderInviteGet(ClientID,UserID,Role);
              SetPage(1)
              SetSearch("")
              document.getElementById("Search").value = ""
           
             
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

//open invite popup
  const OpenReInvitePopup = (Emaildata, ID) => {
    console.log(ID)
    console.log(Emaildata)
    SetEmail(Emaildata);
    SetRID(ID);
    SetInvitePopup(true);
  };

//Close invite popup
  const CloseReInvitePopup = () => {
    SetInvitePopup(false);
    SetEmail("");
  };

//Email send
  const SubmitReInvitePopup = () => {

    
    var Email = document.getElementById("change_mail").value;
    var re =
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    var Emailflg = re.test(Email);
    if (Emailflg == true) {
      var FinalEmail = CryptoJS.AES.encrypt(
        JSON.stringify(Email),
        REACT_APP_ENCRYPTION_SALT
      ).toString();
      let VerificationCodeChar = (Math.random() + 1)
      .toString(36)
      .substring(7);
      var UID = CryptoJS.AES.encrypt(
        JSON.stringify(UserID),
        REACT_APP_ENCRYPTION_SALT
      ).toString();
      
      var VC = CryptoJS.AES.encrypt(
        JSON.stringify(VerificationCodeChar),
        REACT_APP_ENCRYPTION_SALT
      ).toString();
      var EmailData = EmailTemplete.ResponderUserEmailInvite(
        FinalEmail,
        UID,
        UID,
        VC,
        CommonConstants.responder_Invitation
      );
        var str_mail = {
          Email: Email,
          html: EmailData,
          subject: "SalesHive User Account",
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/user/profileEmail",
          method: "POST",
          data: str_mail,
        }).then((res) => {
          var InputParameters ={
            uID:RID,
            Invite_Token:VC
          }
          Axios({
            url: CommonConstants.MOL_APIURL + "/user/InviteResend",
            method: "POST",
            data: InputParameters,
          }).then((res)=>{
           
          })
        SetInvitePopup(false);
        SetRID("");
        SetEmail("");
        toast.success(<div className="toastsize">Responder <br/>
                               Invitation mail sent successfully.</div>)
        // SetAlertbox(true);
      });
    } else {
    }
  
  }

//Alert close
  const AlertboxhandleClose = () => {
    SetAlertbox(false);
  };


  return (
    <div>
       {
        IsLoading ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : <></>
      }
      <Snackbar
        className="erorrheader"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Alertbox}
        autoHideDuration={6000}
        onClose={AlertboxhandleClose}
      >
        <Alert variant="filled" className="erorrheader">
          Responder
          <br /> Invitation mail send successfully.
        </Alert>
      </Snackbar>
  
      <div className="row padt-25">
      
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option key={value} value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="Search"
              id="Search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell onClick={() => { SortData("Email"); setSortedColumn("Email");}}>
                    Email
                    <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "Email" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "Email" ? "active" : null} />
                  </span>
                </TableCell>
                <TableCell onClick={() => { SortData("Action"); setSortedColumn("Action");}}>Action <span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && sortedColumn === "Action" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && sortedColumn === "Action" ? "active" : null} />
                  </span></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length == 0 ? <p className="text-center">No data in Available</p> :
               Rows?.map((row) => (
                <TableRow key= {row?._id}>
                  <TableCell>{row.Email}</TableCell>
                  <TableCell>
                    <a
                      className="btn btn-sm btn-primary btnColor m-btn whitetext"
                      onClick={() => {
                        OpenReInvitePopup(row.Email, row._id);
                      }}
                      title="Reinvite"
                    >
                      Reinvite
                    </a>
                    <a
                      onClick={() => { DeleteBtn(row?._id)}}
                      className="btn-eyesicon"
                      title="Delete"
                    >
                      <i className="la flaticon-delete-1 delete-icon"></i>
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Popupone open={InvitePopup} modal>
        <div className="modal-black"></div>
        <div className="filterPopup bigpopupcontent">
          <div className="paddingboxTerms">
            <div className="modal-header">
              <h5>Send Mail</h5>
              <a className="close-ion" onClick={CloseReInvitePopup}>
                <span aria-hidden="true" className="la la-remove"></span>
              </a>
            </div>
            <div className="modal-content bordernone">
              <div className="row max-cols">
                <label className="col-lg-4 view-lable-meet">Email</label>
                <div className="col-lg-8">
                  <input
                    className="form-control m-input"
                    data-val="true"
                    data-val-required="Please enter first name"
                    id="change_mail"
                    name="FirstName"
                    placeholder="First name"
                    type="text"
                    value={Email}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={CloseReInvitePopup}
                data-dismiss="modal"
              >
                Cancel
              </button>
              <a
                id="m_EmailInvite_submit"
                onClick={SubmitReInvitePopup}
                href="javascript:void(0);"
                className="btn btn-primary btnColor"
              >
                Send{" "}
              </a>
            </div>
          </div>
        </div>
      </Popupone>
      <Pagenations
         Sflag={Sflag}
         CountPage={CountPage}
         HandleChangePage={HandleChangePage}
         Rlen={Rlen}
         Page={Page}
         RowsPerPage={RowsPerPage}
         Flen={Flen}
      />
    </div>
  );
}
