import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { Link } from "react-router-dom";
import { CSVReader, CSVDownloader } from "react-papaparse";

import { history } from "../../_helpers";
import { GetUserDetails } from "../../_helpers/Utility";
import { GetClientDetails } from "../../_helpers/Utility";
const Fileupload = require("../../_helpers/fileupload");
import { CommonConstants } from "../../_constants/common.constants";

import Footer from "../../_components/user/footer/footer";
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import CustomMeetingTable from '../../_components/clients/custommeeting/custommeeting';
class CCustomMeetingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Pager: {},
      PageOfItems: [],
      Files: [],
      List: [],
      CName: "",
      Columns: [],
      ReSet: false,
      CsvData: [],
      DropBoxData: [],
      FileName: "",
      ExportData: [],
      UserIDby: null,
      UserID:null
    };
    // this.HandleOnDrop = this.HandleOnDrop.bind(this);
    // this.HandleOnError = this.HandleOnError.bind(this);
    // this.HandleOnRemoveFile = this.HandleOnRemoveFile.bind(this);
    // this.Importcsv = this.Importcsv.bind(this);
    // this.MapCsv = this.MapCsv.bind(this);
    // this.CancleCsv = this.CancleCsv.bind(this);
    // this.SaveCsv = this.SaveCsv.bind(this);
    this.Addac = this.Addac.bind(this);
    this.ExportCsv = this.ExportCsv.bind(this);
  }

  componentDidMount() {
    var Details = GetUserDetails();
    if (Details != null) {
        this.state.ClientID = Details.ClientID;
        this.state.UserID = Details.ParentUserID;
        this.state.Role = Details.Role;
        this.state.UserIDby = Details.ChildUserID;
    }
    GetClientDetails(Details.ClientID).then((result) => {
      this.setState({ CName: result[0].Name });
    });
    var str_in = {
        ClientID: this.state.ClientID,
        Role:this.state.Role
      };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/meetingcustom/MeetingCustomExport",
      method: "POST",
      data: str_in,
    }).then((res) => {
      this.setState({ ExportData: res.data });
    });
  }

  componentDidUpdate() {
    //this.loadPage();
  }

    //Reload page while delete perform
    UpdateFromChild = (value) => {
      if(value == true){
        this.componentDidMount()
      }
    }

  //Add
  Addac() {
    history.push("/CAddCustomMeeting");
  }

  //Export
  ExportCsv() {}

  render() {
    const { Pager, PageOfItems } = this.state;
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitle float-left pl-0">Custom Meeting Field</h4>
                <div className="listing-li float-right pt-1">
                  <ul>
                    <li>
                    <CSVDownloader className="px-0"
                            data={this.state.ExportData}
                            filename={this.state.CName + " Meeting-Custom-Field"}
                            bom={true}
                          >
                      <a
                        onClick={this.ExportCsv}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-download"></i>
                        <span>
                          
                            Export
                          
                        </span>
                      </a>
                      </CSVDownloader>
                    </li>
                    <li>
                      <a
                        onClick={this.Addac}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="paddcols pb-4">
              <div className="row">
                <div className="col">
                  <CustomMeetingTable updateFromChild={this.UpdateFromChild}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};



const connectedCCustomMeetingPage = connect(mapState, actionCreators)(CCustomMeetingPage);
export { connectedCCustomMeetingPage as CCustomMeetingPage };