import React from "react";
import Axios from "axios";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../../_helpers/Utility";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

class EditContactTagPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      TagNameAvailable: null,
      ClientID: null,
      UserID: null,
      CUserID: null,
      IsExist: false,
      OldName:null,
      Role:null,
      BtnDisabled:false,
      IsLoading:true
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title='Edit Tag | SalesHive'
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role=Details.Role
    }
    this.ContactTagEdit();
  }

  // back button
  BackBtn() {
    history.push("/contacttag");
  }

   // form validation
   async FromValidation() {
    let FormIsValid = true;
    let Errors = {};
    var Name = document.getElementById("Tag").value.trim();
    var IsExists = await this.ContactTagExits(Name);
    if (IsExists == "BlankData" && Name === "") {
          FormIsValid = false;
          Errors["Name"] = "Please enter name";
    }

    if (IsExists === "ExistCountry") {
      FormIsValid = false;
      Errors["Name"] = "Contact tag already exist."; // Modify the error message
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

  async HandleChange(field, e) {
    let Errors = { ...this.state.Errors };
    const value = e.target.value.trim();
    let IsExists;

  
    if (field === "Name") { // Change "Country" to "Name"
      IsExists = await this.ContactTagExits(value); // Change "CoutryNameCheckExist" to "ClientPocNameCheckExist"
  
      if (IsExists === "BlankData" && value === "") {
        Errors.Name = "Please enter name"; // Change "Country" to "Name"
      } else if (IsExists === "ExistCountry") {
        Errors.Name = "Contact tag already exist."; // Change "Country" to "Name"
      } else if (IsExists === "AllDone") {
        Errors.Name = null; // Change "Country" to "Name"
      }
    }
  
    this.setState({ Errors });
  }

  // check contact tag exists
  async  ContactTagExits(Name) {
    if(Name !== ""){
      var ContactTagData = {
        ClientID: this.state.ClientID,
        Tag: Name,
        Role:this.state.Role
      };
      var resdata = await    Axios({
        url: CommonConstants.MOL_APIURL + "/contacttag/ContactTagExists",
        method: "POST",
        data: ContactTagData,
      })
        if (resdata.data.StatusMessage == "SUCCESS") {
          if (resdata.data.Data.length > 0) {
            if(this.state?.OldName?.toLowerCase() == Name?.toLowerCase()){

            }else{
              this.setState({ TagNameAvailable: resdata.data.Data[0].Tag });
              return "ExistCountry";
            }
  
          }
          else{
            return "AllDone";
          }
        }
    }else{
      return "BlankData";
    }


  }

  // contact tag edit
  ContactTagEdit() {
    const id = this.props.location.state;
    this.setState({ id: id });
    var ContactTagData = {
      id: id,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacttag/ContactTagGetByID",
      method: "POST",
      data: ContactTagData,
    }).then((res) => {
      
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById("Tag").value = res.data.Data[0].Tag;
        this.setState({OldName:res.data.Data[0].Tag})
        this.setState({IsLoading:false})
      } else {
        toast.error(res.data.Message);
        this.setState({IsLoading:false})
      }
    });
  }

  // save button for contact update
  async SaveBtn(e) {
    this.setState({BtnDisabled:true})
    var FinalValidation = await this.FromValidation()
      if (FinalValidation == true) {
        var Name = document.getElementById("Tag").value.trim();
        var ContactTagData = {
          _id: this.state.id,
          ClientID:this.state.ClientID,
          UserID:this.state.UserID,
          Tag: Name,
          LastUpdatedBy: this.state.CUserID,
          LastUpdatedDate: new Date(),
          OldTagName:this.state.OldName
        };

        Axios({
          url: CommonConstants.MOL_APIURL + "/contacttag/ContactTagUpdate",
          method: "POST",
          data: ContactTagData,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(<div className="toastsize">Tag Edit<br/>Tag updated successfully</div>);
            history.push("/contacttag");
          } else {

            this.setState({BtnDisabled:false})

            toast.error(res.data.Message);
          }
        });
      } else {

    this.setState({BtnDisabled:false})

      }
  }

  render() {
    return (
      <>
      {
        this.state.IsLoading === true ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : null
      }

        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            
            <div className="row pt-4 pb-3">
              <div className="col px-3">
                <h4 className="headertitlenop float-left px-1">Edit Tag</h4>
              </div>
            </div>

            <div class="whiterow">
              <div className="row pt-3 pb-3 borderbtnm px-4">
                <div class="col-lg-6 boxsinput_group px-3">
                  <div class="row max-cols px-1">
                    <label class="col-lg-4 view-lable-meet">Tag</label>
                    <div class="col-lg-7 mx-4">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter  field name"
                        id="Tag"
                        onChange={this.HandleChange.bind(this, "Name")}
                        value={this.state.Fields["Name"]}
                        name="Name"
                        placeholder="Enter Tag"
                        type="text"
                      />
                     
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                      {/* {this.state.TagNameAvailable && (
                        <span style={{ color: "red" }}>
                          Flield already exist.
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row pt-1 pb-3 ">
                <div class="col-lg-12 pull-left pt-3 pb-3 px-3 mx-1">
                  <button
                    id="submit"
                    disabled={this.state.BtnDisabled}
                    onClick={this.SaveBtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <button
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </button>
                </div>
              </div>
          </div>
        </div>
        <Footer/>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditContactTag = connect(
  mapState,
  actionCreators
)(EditContactTagPage);
export { connectedEditContactTag as EditContactTagPage };
