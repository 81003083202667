import { clientConstants } from '../_constants';
import Axios from "axios";
import { CommonConstants,IndependedClientRoute } from "../_constants/common.constants";
import { UpdateUserDetails,GetUserDetails } from "../_helpers/Utility";

export const clientActions = {
    getClientListForTopDropDown,
    setClientID,
    setSelectedClientDropdown,
    setSearchValue,
    setFilteredOptions,
};
function getClientListForTopDropDown(Role, CUID, PUID) {
    return async dispatch => {
      const path = location.pathname;
      var ObjData = {
        Role: Role,
        ChildUserID: CUID,
        ParentUserID: PUID,
      };
  
      try {
        const result = await Axios({
          url: CommonConstants.MOL_APIURL + "/client/GetClientListForTopDropDown",
          method: "POST",
          data: ObjData,
        });
  
        if (result.data.StatusMessage === "SUCCESS") {
          if (result.data.Data.length > 0) {
            dispatch(setClientDropdown(result.data.Data));
            dispatch(setFilteredOptions(result.data.Data));
            // Handle user details and client ID update
            var Details = await GetUserDetails();
  
            if (!Details.ClientID) {
              let UserData = JSON.parse(localStorage.getItem("LoginData"));
              if (!UserData.ClientID) {
                await UpdateUserDetails(result.data.Data[0].ClientID);
                dispatch(setClientID(result.data.Data[0].ClientID));
                dispatch(setSelectedClientDropdown(result.data.Data[0].ClientID));
                dispatch(setSearchValue(result.data.Data[0].Name));
                // props.updatefromchild(true);
              }
            } else {
              dispatch(setClientID(Details.ClientID));
              dispatch(setSelectedClientDropdown(Details.ClientID));
              var searchdata = result.data.Data;
              var filter = searchdata.filter(x => x.ClientID === Details.ClientID);
              if (filter.length > 0) {
                dispatch(setSearchValue(filter[0].Name));
              }
            }
            dispatch(setIsClients(true));
          } else {
            dispatch(setIsClients(false));
            if (!IndependedClientRoute.includes(path)) {
              toast.error(
                <div className="toastsize">
                  Client not available, please add client.
                </div>
              );
            }
          }
        }
      } catch (error) {
        console.error("Error fetching client list for top dropdown", error);
      }
    };
  
   
  }
  function setClientDropdown(data) { return { type: clientConstants.SET_CLIENT_DROPDOWN, data }; }
  function setClientID(data) { return { type: clientConstants.SET_CLIENT_ID, data }; }
  function setSelectedClientDropdown(data) { return { type: clientConstants.SET_SELECTED_CLIENT_DROPDOWN, data }; }
  function setSearchValue(data) { return { type: clientConstants.SET_SEARCH_VALUE, data }; }
  function setIsClients(data) { return { type: clientConstants.SET_IS_CLIENTS, data }; }
  function setFilteredOptions(data) { return { type: clientConstants.SET_FILTERED_OPTIONS, data }; }
 