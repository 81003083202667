import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { history } from "../../../_helpers";
import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { GetUserDetails } from "../../../_helpers/Utility";

toast.configure();

class CAddCustomMeetingPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();

    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      FieldNameAvailble: null,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      Role: Details.Role,
      UserIDby:Details.ChildUserID,
      IsButtonDisabled:false
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = `Add Custom Meeting Field | SalesHive`;
  }

  // form validation
  async FromValidation() {
    let FormIsValid = true;
    let Fields = this.state.Fields;
    let Errors = {};
    var FieldName = document.getElementById("FieldName").value.trim();
    var IsExist = await this.CheckExistMeetingCustomFieldName(FieldName)
    if (FieldName == "") {
      FormIsValid = false;
      Errors["FieldName"] = "Please enter fieldname";
    }

    if (IsExist === true) {
      FormIsValid = false;
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

//handle change
  HandleChange(field, e) {
    let Fields = this.state.Fields;
    Fields[field] = e.target.value;
    this.setState({ field });
    if (Fields.FieldName != "") {
      this.state.Errors.FieldName = null;
      this.state.FieldNameAvailble = null;
      this.CheckExistMeetingCustomFieldName(e.target.value);
    } else {
      this.state.FieldNameAvailble = null;
      this.state.Errors.FieldName = null;
    }
  }

//check exist field name
  async CheckExistMeetingCustomFieldName(FieldName) {
    var str_in = {
      ClientID: this.state.ClientID,
      FieldName: FieldName,
      Role:this.state.Role
    };
    var resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/meetingcustom/MeetingCustomExists",
      method: "POST",
      data: str_in,
    })
    console.log(resdata)
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.data.length > 0) {
        this.setState({ FieldNameAvailble: resdata.data.data });
        return true;
      }else{
        this.setState({ FieldNameAvailble: "" });
        return false;
      }
    }
  }

//back to page
  BackBtn() {
    history.push("/CCustomMeeting");
  }

//Add meeting custom field 
  async SaveBtn(e) {
    e.preventDefault();
    this.setState({IsButtonDisabled:true});
    // this.state.Errors.FieldName = null;
    // this.state.FieldNameAvailble = null;
    // e.currentTarget.disabled = true;
    var Final_flag =  await this.FromValidation()
    if (Final_flag == true) {
      var FieldName = document.getElementById("FieldName").value.trim();
      var data = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
        FieldName: FieldName,
        FieldTypeID:1,
        CreatedBy: this.state.UserIDby,
        FieldFor: "meeting",
        FieldValue: null,
        CreatedDate: new Date(),
        IsDeleted: false,
        IsFieldRequired: false,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/meetingcustom/MeetingCustomAdd",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div className="toastsize" >Custom Meeting Field Add<br/>Custom Meeting Field added successfully.</div>);
          history.push("/CCustomMeeting");
        } else {
          toast.error(res.data.Message);
          this.setState({IsButtonDisabled:false});
        }
      });
    }else{
      this.setState({IsButtonDisabled:false});
     // document.getElementById("submit").disabled = false;
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad pl-0 mb-0">
                    Add Custom Meeting Field
                  </h4>
                </div>
              </div>

            <div className="paddcols"> 
              <div className="row">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Name</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter field name"
                        id="FieldName"
                        onBlur={this.HandleChange.bind(this, "FieldName")}
                        name="FieldName"
                        placeholder="FieldName"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["FieldName"]}
                      </span>
                      {this.state.FieldNameAvailble && (
                        <span style={{ color: "red" }}>
                          Flield already exist.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div className="row my-4">
                <div className="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.IsButtonDisabled}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};


const connectedCAddCustomMeetingPage = connect(
  mapState,
  actionCreators
)(CAddCustomMeetingPage);
export { connectedCAddCustomMeetingPage as CAddCustomMeetingPage };
