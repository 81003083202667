import React from "react";
import Axios from "axios";
var CryptoJS = require("crypto-js");
import { connect } from "react-redux";

import logobgs from "../../../images/Loginvector.svg";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
const Uvalidation = require("../../../_helpers/user-validation");
const EmailTemplete = require("../../../_helpers/email-templete");

class UpdateUsersPerClientPage extends React.Component {
  constructor(props) {
    // var Details = GetUserDetails();
    super(props);
    this.state = {
      Email: "",
      id: "",
      OldEmail: "",
      // Role:Details.Role,
      Role:"Admin",
      BtnDisabled:false
    };

    this.SubmittedData = this.SubmittedData.bind(this);
    this.BackButton = this.BackButton.bind(this);
  }
  componentDidMount() {
      document.title="Update Email | SalesHive"
    const QueryParams = new URLSearchParams(window.location.search);
    const code = QueryParams.get("code");
    const Tmp = QueryParams.get("email");

    var FinalTmp = Tmp.split(" ").join("+");
    var bytes = CryptoJS.AES.decrypt(FinalTmp, "my-secret-key@123");

    var Email = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    document.getElementById("Email").value = Email;
    this.setState({ Email: Email });
    var InputParameters = {
      EmailToken: code.split(' ').join('+'),
      Email:Email
    };
    
    Axios({
      url: CommonConstants.MOL_APIURL + "/user/finduserEmailcode",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
        
      this.setState({ id: res.data[0]._id });
      this.setState({ OldEmail: res.data[0].Username });
    });
  }
 
//Update email
  SubmittedData() {
    this.setState({BtnDisabled:true})
    var Email = document.getElementById("Email").value;
    if (this.state.Email == Email) {
      var InputParameters = {
        Email: Email,
        uID: this.state.id,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/user/updateEmail",
        method: "POST",
        data: InputParameters,
      }).then((res) => {
        if (res.data != "") {
          var InputParameters = {
            Email: Email,
            OldEmail: this.state.OldEmail,
            Role:this.state.Role
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/users_per_client/UpdateEmail",
            method: "POST",
            data: InputParameters,
          }).then((res) => {
            
            history.push("/UsersPerClient");
          });
          //resetpass_u.style.display = "block";
          // this.setState({
          //     open: true
          // });
          //window.location = CommonConstants.home;
        }else{
          this.setState({BtnDisabled:false})
        }
      });
    }
  }

//back
  BackButton() {
    window.location = CommonConstants.home;
  }
  
  render() {
    return (
      <div class="login-main">
        <div className="row h-100">
          <div className="col-xs-12 col-md-6 logo-bgbod padding-btn">
            <img src={logobgs} class="login-vector" />

            <div class="m-login__logo">
              <a href="/">
                <img src={""} class="main-logo" />
              </a>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 d-flex">
            {/* Login Form in sign in page*/}
            <div className="full-hegiht">
              <div id="login" className="centerheigh ">
                {/* <Alert id="a_login" variant="filled" severity="success" className="erorrheader">login successfull.</Alert>
                                <Snackbar  className="erorrheader" anchorOrigin={{ vertical:'top' ,horizontal: 'center' }} open={this.state.open} autoHideDuration={6000} onClose={this.handleClose}>
                                    <Alert variant="filled" severity="error" className="erorrheader">Please enter Correct Email or password.</Alert>
                                </Snackbar> */}
                <div className="heading">
                  <h1 class="lefter_title">Update Email</h1>
                </div>
                <form name="login" className="form">
                  <div class="input-control">
                    <label for="Email" className="input-label" hidden>
                      Email Address
                    </label>
                    <input
                      type="Email"
                      id="Email"
                      name="Email"
                      className="input-field"
                      placeholder="Email Address"
                    />
                    {/* <p id="Email_e" className="errorinput"> Please enter Email </p> */}
                  </div>

                  <div class="input-control btn-center">
                    <button
                      type="button"
                      name="submit"
                      className="input-submit"
                      value="Save"
                      disabled={this.state.BtnDisabled}
                      onClick={() => this.SubmittedData()}
                    >
                      <i class="la la-save"></i> Save
                    </button>
                    <a
                      type="button"
                      name="submit"
                      className="input-submit"
                      value="Save"
                      onClick={() => this.BackButton()}
                    >
                      <i class="la la-arrow-circle-left"></i> Back
                    </a>
                    {/* <p id="err_user"> Please enter Correct Email or password </p> */}
                  </div>
                </form>
              </div>

              {/* Forgot password Form in sign in page*/}
              {/* <div id="forgot" className="centerheigh">
                                <Alert id="forgotpass_u" variant="filled" severity="success" className="erorrheader">Email send. Please check your Email.</Alert>
                                <Snackbar  className="erorrheader" anchorOrigin={{ vertical:'top' ,horizontal: 'center' }} open={this.state.forgotpassOpen} autoHideDuration={6000} onClose={this.handleforgotpassClose}>
                                    <Alert variant="filled" severity="error" className="erorrheader">No User found.</Alert>
                                </Snackbar>
                                <div className="heading">
                                    <h1 class="login_title">Forgotten Password ?</h1>
                                    <p className="login_pereg">Enter your Email to reset your password?</p>
                                </div>
                                <form name="login" className="form">
                                    <div class="input-control">
                                        <label for="Email" className="input-label" hidden>Email Address</label>
                                        <input type="Email" name="Email" id="f_Email" className="input-field" placeholder="Email Address" />
                                        <p id="f_Email_e" className="errorinput"> Please enter Email </p>
                                        <p id="e_user" className="errorinput">No User found</p>
                                        <p id="Email_user" className="errorinput">Email send. Please check your Email.</p>
                                    </div>
                                    <div class="input-control btn-center">
                                        <input type="button" name="Request" onClick={this.submitforgot} className="input-submit" value="Request" />
                                        <input type="button" name="Cancel" onClick={this.cancleforgot} className="input-submit" value="Cancel" />
                                    </div>
                                </form>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  return state;
}

const actionCreators = {};

const connectedUpdateUsersPerClientPage = connect(
  mapState,
  actionCreators
)(UpdateUsersPerClientPage);
export { connectedUpdateUsersPerClientPage as UpdateUsersPerClientPage };
