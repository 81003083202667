import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Axios from "axios";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import "react-toastify/dist/ReactToastify.css";



class CAddAccountCategoryPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      StatusAvailable: null,
      TitleNotNull: false,
      IsExist: false,
      ClientID: null,
      UserID: null,
      UserIDby:null,
      Role: null,
      IsButtonDisabled:false,
      IsLoading:false
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = `Add Account Category | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.Role=Details.Role;
      this.state.UserIDby=Details.ChildUserID
    }
    this.setState({IsLoading:false})
  }

   // form validation
   async FromValidation() {
    
    let FormIsValid = true;
    let Errors = {};
    var name = document.getElementById("Name").value.trim();
    var IsExist = await this.CheckExistAccountCategoryName(name)
    if (name== "") {
      FormIsValid = false;
      Errors["Name"] = "Please enter name";
    }
    if(IsExist == true){
      FormIsValid = false;
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

    // handle change
    HandleChange(field, e) {
      this.setState({IsLoading:false})
      let Fields = this.state.Fields;
      Fields[field] = e.target.value;
      this.setState({ Fields });
      if (Fields.Name != "") {
        this.state.Errors.Name = null;
        this.state.StatusAvailable = null;
        this.CheckExistAccountCategoryName(e.target.value);
        this.setState({IsLoading:false})
      } else {
        this.state.StatusAvailable = null;
        this.state.Errors.Name = null;
        this.setState({IsLoading:false})
      }
    }

//check exist name
async CheckExistAccountCategoryName(Name) {
  
    var str_in = {
      ClientID: this.state.ClientID,
      Name: Name,
      Role:this.state.Role
    };
    var resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/account_categories/AccountCategoriesStatusExists",
      method: "POST",
      data: str_in,
    })
      
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          this.setState({ StatusAvailable: resdata.data.Data[0]?.Name });
          this.setState({ IsExist: true });
          return true;
        } else {
          this.setState({ IsExist: false });
          return false;
        }
      }
   
  }

//back 
  BackBtn() {
    history.push("/CAccountCategory");
  }

//add account category
async SaveBtn(e) {
  // this.state.Errors.Name = null;
  // this.state.StatusAvailable = null;
  this.setState({IsButtonDisabled:true});
  // e.preventDefault();
  // e.currentTarget.disabled = true;
  var Final_flag =  await this.FromValidation()
  this.setState({IsLoading:true})
  if (Final_flag == true) {
    this.setState({IsButtonDisabled:true});
    var Name = document.getElementById("Name").value.trim();
      var AddField = {
        Name: Name,
        IsDeleted: false,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
        OrderBy: 10,
        CreatedBy: this.state.UserIDby,
        CreatedDate: new Date(),
        LastUpdatedBy: null,
        LastUpdatedDate: null,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/account_categories/AccountCategoriesAdd",
        method: "POST",
        data: AddField,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div className="toastsize" >Account Category<br/>Account category added successfully.</div>);
          history.push("/CAccountCategory");
          this.setState({IsLoading:false})
        } else {
          toast.error(res.data.Message);
          this.setState({IsButtonDisabled:false});
        }
      });
    }else{
      this.setState({IsButtonDisabled:false});
    }
    this.setState({IsLoading:false})

  }

  render() {
    return (
      <>
       {
        this.state.IsLoading == true ? <div id="hideloding1" className="loding-display">
          <img src={loadingicon} />
        </div> : null
      }
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left pl-0 mb-0">
                    Add Account Category
                  </h4>
                </div>
              </div>

            <div className="paddcols">
              <div className="row">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Name</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        id="Name"
                        name="Name"
                        placeholder="Name"
                        type="text"
                      />
                     
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                      {this.state.StatusAvailable && (
                        <span style={{ color: "red" }}>
                          Status already exist.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div> 
            </div>

              <div className="row py-3">
                <div className="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.IsButtonDisabled}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCAddAccountCategoryPage = connect(
  mapState,
  actionCreators
)(CAddAccountCategoryPage);
export { connectedCAddAccountCategoryPage as CAddAccountCategoryPage };
