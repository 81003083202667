import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import { history } from "../../../_helpers";
import { userActions } from "../../../_actions";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

class CAddPotentialMeetingStatusPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);
    this.state = {
      Fields: {},
      Errors: {},
      IsExist: false,
      StatusAvailable: null,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      Role: Details.Role,
      UserIDby: Details.ChildUserID,
      IsButtonDisabled:false
    };
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = `Add Engagement Status | SalesHive`;
  }

 // form validation
 async FromValidation() {

  let FormIsValid = true;
  var Status = document.getElementById("Status").value.trim();
  var IsExist = await this.CheckEngagementExistStatus(Status);
  let Errors = {};
  if (Status == "" && Status.indexOf(" ") != "") {
    FormIsValid = false;
    Errors["Status"] = "Please Enter Status";
  }

  if (IsExist == true) {
    FormIsValid = false;
  }
  this.setState({ Errors: Errors });
  return FormIsValid;
}

//change to check error
HandleChange() {
  var Status = document.getElementById("Status").value.trim();
  // let Fields = this.state.Fields;
  // Fields[field] = e.target.value;
  // this.setState({ Fields });
  if (Status != "" && Status.indexOf(" ") != "") {
    this.state.Errors.Status = null;
    this.state.StatusAvailable = null;
    this.CheckEngagementExistStatus(Status);
  } else {
    this.state.StatusAvailable = null;
    this.state.Errors.Status = null;
  }
}

async CheckEngagementExistStatus(Status) {
    var str_in = {
      ClientID: this.state.ClientID,
      Status: Status,
      Role:this.state.Role
    };
    var resdata = await  Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/potentialmeetingstatus/PotentialMeetingStatusExists",
      method: "POST",
      data: str_in,
    })
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          this.setState({ StatusAvailable: resdata.data.Data[0].Status });
          this.setState({ IsExist: true });
          return true;
        } else {
          this.setState({ IsExist: false });
          return false;
        }
      }
   
  }

  BackBtn() {
    history.push("/CPotentialMeetingStatus");
  }

  //add account status
  async SaveBtn(e) {
    // this.state.Errors.Status = null;
    // this.state.StatusAvailable = null;
    this.setState({IsButtonDisabled:true});
    // e.preventDefault();
    // e.currentTarget.disabled = true;
    var Final_flag = await this.FromValidation();
    if (Final_flag == true) {
      var Status = document.getElementById("Status").value.trim();

      var AddField = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
        Status: Status,
        type: "User",
        OrderBy: 10,
        IsDeleted: false,
        CreatedDate: new Date(),
        CreatedBy: this.state.UserIDby,
        LastUpdatedDate: null,
        LastUpdatedBy: null,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/potentialmeetingstatus/PotentialMeetingStatusAdd",
        method: "POST",
        data: AddField,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Add meeting status
              <br />
              Engagement status added successfully.
            </div>
          );
          history.push("/CPotentialMeetingStatus");
        } else {
          toast.error(res.data.Message);
          this.setState({IsButtonDisabled:false});
        }
      });
    }else {
      this.setState({IsButtonDisabled:false});
     // document.getElementById("submit").disabled = false;
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad pl-0 mb-0">
                    Add Engagement Status
                  </h4>
                </div>
              </div>

            <div class="paddcols px-4 mb-3">
              <div className="row py-4 px-3">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Status</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first Status"
                        onBlur={this.HandleChange.bind(this, "Status")}
                        id="Status"
                        name="Status"
                        placeholder="Status"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Status"]}
                      </span>
                      {this.state.StatusAvailable && (
                        <span style={{ color: "red" }}>
                          Status already exist.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div> 
              </div>
              <div class="row mb-3 px-3 pt-3">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.IsButtonDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <button
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </button>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCAddPotentialMeetingStatusPage = connect(
  mapState,
  actionCreators
)(CAddPotentialMeetingStatusPage);
export { connectedCAddPotentialMeetingStatusPage as CAddPotentialMeetingStatusPage };
