import React from "react";
import Axios from "axios";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

class EditCountryBlacklistPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      CountryNameAvailable: null,
      ClientID: null,
      UserID: null,
      CUserID: null,
      IsExist: false,
      OldCountryName: null,
      Role: null,
      BtnDisabled: false,
      OldName:null,
      IsLoading:true
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
  }
  componentDidMount() {
    document.title = "Edit Country Blacklist | SalesHive";

    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }

    this.DefaultCountryBlackListEdit();
  }

  // get id by data
  DefaultCountryBlackListEdit() {
    const id = this.props.location.state;
    this.setState({ id: id });

    var str_in = {
      id: id,
      Role: this.state.Role,
    };

    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/countryblacklist/CountryBlacklistByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        this.setState({ OldName: res.data.Data[0].Country });
        document.getElementById("Country").value = res.data.Data[0].Country;
        document.getElementById("Abbreviation").value =
          res.data.Data[0].Abbreviation;
         this.setState({ IsLoading: false });
      } else {
        toast.error(res.data.Message);
         this.setState({ IsLoading: false });
      }
    });
  }
  // form validation
  async FromValidation() {
    let FormIsValid = true;
    let Errors = {};
    var Country = document.getElementById("Country").value.trim();
    var IsExists = await this.CoutryNameCheckExist(Country);
    if (IsExists == "BlankData" && Country == "") {
      FormIsValid = false;
      Errors["Country"] = "Please enter country";
    }
    if (IsExists == "ExistCountry") {
      FormIsValid = false;
      Errors["Country"] = "Country already exist.";
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

  // handle change
  HandleChange(field, e) {
    let Fields = this.state.Fields;
    Fields[field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Name != "") {
      this.state.Errors.Country = null;
      // this.state.Errors.Abbreviation = null;
      this.state.CountryNameAvailable = null;
      this.CoutryNameCheckExist(e.target.value);
    } else {
      this.state.CountryNameAvailable = null;
      this.state.Errors.Country = null;
      // this.state.Errors.Abbreviation = null;
    }
  }

  async HandleChangeBlur() {
    let Errors = { ...this.state.Errors };
    var Country = document.getElementById("Country").value.trim();

    let IsExists = await this.CoutryNameCheckExist(Country);
    if (IsExists == "BlankData" && Country == "") {
      Errors["Country"] = "Please enter country";
    }
    if (IsExists == "ExistCountry") {
      Errors["Country"] = "Country already exist.";
    }

    if (IsExists == "AllDone") {
      Errors["Country"] = null;
    }

    this.setState({ Errors: Errors });
  }

  // check exit name
  async CoutryNameCheckExist(Country) {
    if (Country != "") {
      var CheckExitsData = {
        ClientID: this.state.ClientID,
        Country: Country,
        Role: this.state.Role,
      };

      let resdata = await Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/countryblacklist/CountryBlacklistExists",
        method: "POST",
        data: CheckExitsData,
      });
      if (resdata.data.Data.length > 0) {
        if (this.state?.OldName?.toLowerCase() == Country?.toLowerCase()) {
          return "AllDone";
        } else {
          return "ExistCountry";
        }
      } else {
        return "AllDone";
      }
    } else {
      return "BlankData";
    }
  }

  // back button
  BackBtn() {
    history.push("/countryblacklist");
  }

  // save button
  async SaveBtn() {
    this.setState({ BtnDisabled: true });
    var Final_flag = await this.FromValidation();
    if (Final_flag == true) {
      var Country = document.getElementById("Country").value;
      var Abbreviation = document.getElementById("Abbreviation").value;
      var CoutryBlackListUpdate = {
        CountryBlacklistID: this.state.id,
        UserID: this.state.UserID,
        Country: Country,
        Abbreviation: Abbreviation,
        LastUpdatedDate: new Date(),
        LastUpdatedBy: this.state.CUserID,
        Role: this.state.Role,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/countryblacklist/CountryBlacklistUpdate",
        method: "POST",
        data: CoutryBlackListUpdate,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              {" "}
              Country blacklist will be updated soon.
            </div>
          );
          history.push("/countryblacklist");
        } else {
          toast.error(res.data.Message);

          this.setState({ BtnDisabled: false });
        }
      });
    } else {
      this.setState({ BtnDisabled: false });
    }
  }

  render() {
    return (
      <>
      {
        this.state.IsLoading === true ?  <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : null
      }
  
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome"> 
            <div className="row">
              <div className="col">
                <h4 className="headertitlepad float-left">
                  Edit
                </h4>
              </div>
            </div>

            <div className="paddcols px-0">
              <div className="row py-3 px-4">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Country </label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter country"
                       
                        onBlur={this.HandleChangeBlur}
                      
                        id="Country"
                        name="Country"
                        placeholder="Enter country"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Country"]}
                      </span>
                    
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Abbreviation{" "}
                    </label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter  Abbreviation"
                        onChange={this.HandleChange.bind(this, "Abbreviation")}
                        value={this.state.Fields["Abbreviation"]}
                        id="Abbreviation"
                        name="Abbreviation"
                        placeholder="Enter abbreviation"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Abbreviation"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row my-4">
              <div class="col-lg-12 pull-left">
                <button
                  id="submit"
                  onClick={this.SaveBtn}
                  disabled={this.state.BtnDisabled}
                  class="btn btn-primary btn-lightgreen mr-1"
                >
                  <i class="la la-save"></i> Save
                </button>
                <a
                  id="backtolist"
                  onClick={this.BackBtn}
                  class="btn btn-secondary"
                >
                  <i class="la la-arrow-circle-left"></i> Back
                </a>
              </div>
            </div>

          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditCountryBlacklistPage = connect(
  mapState,
  actionCreators
)(EditCountryBlacklistPage);
export { connectedEditCountryBlacklistPage as EditCountryBlacklistPage };
