import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Axios from "axios";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";

import { GetUserDetails,URLValidation } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";



class CAddDocumentsPage extends React.Component {
  //Initialization of States
  constructor(props) {
    super(props);

    this.state = {
      IsExist: false,
      TitleNotNull: false,
      UrlNotNull: false,
      Type: false,
      IsUrl: true,
      reset: false,
      fields: {},
      errors: {},
      ClientID: null,
      UserID: null,
      ParentUserID: null,
      Role: null,
      TypeList: [],
      formData: {},
      IsSubmitting: false,
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);

    this.HandleChangedropdownBlur = this.HandleChangedropdownBlur.bind(this);
    this.HandleChangeotherFieldBlur =
      this.HandleChangeotherFieldBlur.bind(this);
    this.handleChangeWebsitecheck = this.handleChangeWebsitecheck.bind(this);
  }
  //State Life Cycle
  componentDidMount() {
    document.title = "Add Document | SalesHive";
    var Details = GetUserDetails();

    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ChildUserID;
      this.state.ParentUserID = Details.ParentUserID;
      this.setState({ Role: Details.Role });
    }
    this.GetTypeDropDown();
  }
  // Get Type Drop Down
  GetTypeDropDown() {
    const InputParameter = {
      Role: this.state.Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/documents/TypeGet",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        const TypeData = res.data.Data;
        const option = TypeData.map((Type) => ({
          FieldID: Type.FieldID,
          FieldName: Type.FieldName,
        }));
        this.setState({ TypeList: option });
        document.getElementById("hideloding").style.display = "none";
      } else {
        toast.error(res.data.Message);
      }
    });
  }

  //New changes for validation
  async CheckWebSite(URLlink) {
    var regexp = new RegExp(
      "^(https?:\\/\\/)" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    if (URLlink != "") {
      if (!URLValidation(URLlink)) {
        return "InvalidURL";
      } else {
        return "AllDoneURL";
      }
    } else {
      return "BlankDataURL";
    }
  }

  async handleChangeWebsitecheck() {
    let errors = { ...this.state.errors };
    var UrlLink = document.getElementById("Url").value.trim();
    if (UrlLink == "") {
      errors["Url"] = "Please enter url";
    } else {
      let IsExist = await this.CheckWebSite(UrlLink);
      if (IsExist == "InvalidURL") {
        errors["Url"] = "Please enter valid url";
      }
      if (IsExist == "AllDoneURL") {
        errors["Url"] = null;
      }
    }
    this.setState({ errors: errors });
  }

  async HandleChangedropdownBlur() {
    let errors = { ...this.state.errors };
    var TypeID = document.getElementById("Type").value.trim();

    if (TypeID == "") {
      errors["Type"] = "Please select type";
    } else {
      errors["Type"] = null;
    }

    this.setState({ errors: errors });
  }

  async HandleChangeotherFieldBlur() {
    
    let errors = { ...this.state.errors };
    var Title = document.getElementById("Title").value.trim();
    var IsExistTitle = await this.CheckExistDocumentTitle(Title);

    //title
    if (IsExistTitle == "ExistTitle") {
      errors["Title"] = "Title already exist.";
    }

    if (IsExistTitle == "BlankData" && Title == "") {
      errors["Title"] = "Please enter title";
    }

    if (IsExistTitle == "AllDone") {
      errors["Title"] = null;
    }

    this.setState({ errors: errors });
  }

  //check exist Title
  async CheckExistDocumentTitle(Title) {
    if (Title != "") {
      var InputParameters = {
        ClientID: this.state.ClientID,
        Title: Title,
        Role: this.state.Role,
      };
      var resdata = await Axios({
        url: CommonConstants.MOL_APIURL + "/documents/DocumentsTitleExists",
        method: "POST",
        data: InputParameters,
      });
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          return "ExistTitle";
        } else {
          return "AllDone";
        }
      }
    } else {
      return "BlankData";
    }
  }

  //Back Button
  BackBtn() {
    history.push("/CDocuments");
  }

  //form data validation on submit
  async FromValidation() {
    let formIsValid = true;
    // this.setState({ IsValid: false });
    let fields = this.state.fields;
    var Title = document.getElementById("Title").value.trim();
    var UrlLink = document.getElementById("Url").value.trim();
    var TypeID = document.getElementById("Type").value.trim();

    var IsExistTitle = await this.CheckExistDocumentTitle(Title);
    let IsExist = await this.CheckWebSite(UrlLink);
    let errors = {};

    //title
    if (IsExistTitle == "ExistTitle") {
      formIsValid = false;
      errors["Title"] = "Title already exist.";
    }

    if (IsExistTitle == "BlankData" && Title == "") {
      formIsValid = false;
      errors["Title"] = "Please enter title";
    }

    //URL
    if (IsExist == "InvalidURL") {
      formIsValid = false;
      errors["Url"] = "Please enter valid url";
    }
    if (IsExist == "BlankDataURL" && UrlLink == "") {
      formIsValid = false;
      errors["Url"] = "Please enter url";
    }

    if (TypeID == "") {
      formIsValid = false;
      errors["Type"] = "Please select type";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  //Save Button
  async SaveBtn(e) {
    this.setState({ IsSubmitting: true });
    var Final_flag = await this.FromValidation();
    if (Final_flag == true) {
      var Title = document.getElementById("Title").value.trim();
      var UrlLink = document.getElementById("Url").value.trim();
      var TypeID = document.getElementById("Type").value.trim();
      var Data = {
        ClientID: this.state.ClientID,
        UserID: this.state.ParentUserID,
        Title: Title,
        Url: UrlLink,
        TypeID: TypeID,
        CreatedBy: this.state.UserID,
        CreatedDate: new Date(),
        Role: this.state.Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/documents/DocumentAdd",
        method: "POST",
        data: Data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div>
              Document Add
              <br />
              Document added successfully.
            </div>
          );
          history.push("/CDocuments");
        } else {
          toast.error(res.data.Message);
          this.setState({ IsSubmitting: false });
        }
      });
    } else {
      this.setState({ IsSubmitting: false });
    }
  }

  render() {
    return (
      <>
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome px-3">
            <div className="row py-4">
              <div className="col">
                <h4 className="headertitlenop float-left pt-1">
                  Add Document{" "}
                </h4>
              </div>
            </div>

            <div class="bg-white">
              <div className="row pt-3 px-4">
                <div class="col-lg-6 boxsinput_group px-4">
                  <div class="row max-cols">
                    <label class="col-lg-3 view-lable-meet">Title</label>
                    <div class="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="Title"
                        name="Title"
                        placeholder="Enter title"
                        type="text"
                        onBlur={this.HandleChangeotherFieldBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Title"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols px-4">
                    <label class="col-lg-3 view-lable-meet">Url</label>
                    <div class="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="Url"
                        name="Url"
                        placeholder="Enter url"
                        type="text"
                        onBlur={this.handleChangeWebsitecheck}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Url"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group px-4">
                  <div class="row max-cols ">
                    <label class="col-lg-3 view-lable-meet">Type</label>
                    <div class="col-lg-7">
                      <select
                        className="form-control  m-input"
                        data-val="true"
                        data-val-number="The field Status must be a number."
                        id="Type"
                        name="Type"
                        onChange={this.HandleChangedropdownBlur}
                      >
                        <option value="">--Select--</option>
                        {this.state.TypeList.map((val) => (
                          <option value={val.FieldID}>{val.FieldName}</option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["Type"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-3 mt-4 pb-4">
              <div class="col-lg-12 pull-left">
                <button
                  id="submit"
                  onClick={this.SaveBtn}
                  disabled={this.state.IsSubmitting}
                  class="btn btn-primary btn-lightgreen mr-1"
                >
                  <i class="la la-save"></i> Save
                </button>
                <a
                  id="backtolist"
                  onClick={this.BackBtn}
                  class="btn btn-secondary"
                >
                  <i class="la la-arrow-circle-left"></i> Back
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCAddDocumentsPage = connect(
  mapState,
  actionCreators
)(CAddDocumentsPage);
export { connectedCAddDocumentsPage as CAddDocumentsPage };
