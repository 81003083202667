import React,{useEffect,useState} from "react";
import Axios from "axios";
import Popupone from "reactjs-popup";

import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";

import "froala-editor/js/plugins.pkgd.min.js";
import FroalaEditor from "react-froala-wysiwyg";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import Froala from "froala-editor";
import { toast } from "react-toastify";

export default function DefaultSlackEditor({HeadingName,IsRescheduling}) {
    const [ClientID,SetClientID] = useState("");
    const [UserID,SetUserID] = useState("");
    const [ChildUserID,SetChildUserID] = useState("");
    const [Role,SetRole] = useState("");
    const [DataExists,SetDataExists] = useState(true)
    const [IsLoading,SetIsLoading] = useState(false)
    const [FormData,SetFormData] = useState({
            _id: "",
            UserID:"",
            ClientID: "",
            SlackText:"",
            Subject: "",
            ChannelID: "",
            IsDeleted: false,
            CreatedDate: new Date(),
            LastUpdatedDate:new Date(),
            IsRescheduling: IsRescheduling,
        
    });
    const [PreviewData,SetPerviewData] = useState({
      SubjectPerview:"",
      BodyPerview:"",
    })
    const [OpenPopUp,SetOpenPopUp] = React.useState(false);
    const [FormDataPopUp,SetFormDataPopUp] = React.useState({
      ChannelName:""
    });
    const [ChannelID,SetChannelID] = React.useState("")
    const [IsChannelExists,SetIsChannelExists] = React.useState(false)
    const [Errors,SetErrors] = React.useState({})
    const Variables ={
        "<b>Firstname:</b> (Meeting.FIRSTNAME)":"Meeting.FIRSTNAME",
    "<b>Lastname:</b>(Meeting.LASTNAME)":"Meeting.LASTNAME",
    "<b>Title:</b>(Meeting.TITLE)":"Meeting.TITLE",
    "<b>Company:</b> (Meeting.COMPANY)":"Meeting.COMPANY",
    "<b>Email:</b> (Meeting.EMAIL)":"Meeting.EMAIL",
    "<b>Linkedinurl:</b> (Meeting.LINKEDINURL)":"Meeting.LINKEDINURL",
    "<b>Directphone:</b> (Meeting.DIRECTPHONE)":"Meeting.DIRECTPHONE",
    "<b>Directphoneext:</b> (Meeting.DIRECTPHONEEXT)":"Meeting.DIRECTPHONEEXT",
    "<b>Companyphone:</b> (Meeting.COMPANYPHONE)":"Meeting.COMPANYPHONE",
    "<b>Website:</b> (Meeting.WEBSITE)":"Meeting.WEBSITE",
    "<b>Meetingstatus:</b> (Meeting.MEETINGSTATUS)":"Meeting.MEETINGSTATUS",
    "<b>Meetingsource:</b> (Meeting.MEETINGSOURCE)":"Meeting.MEETINGSOURCE",
    "<b>Meetingsetdate:</b> (Meeting.MEETINGSETDATE)":"Meeting.MEETINGSETDATE",
    "<b>Meetingsetby:</b> (Meeting.MEETINGSETBY)":"Meeting.MEETINGSETBY",
    "<b>Meetingdate:</b> (Meeting.MEETINGDATE)":"Meeting.MEETINGDATE",
    "<b>Meetingtimezone:</b> (Meeting.MEETINGTIMEZONE)":"Meeting.MEETINGTIMEZONE",
    "<b>Owner:</b> (Meeting.OWNER)":"Meeting.OWNER",
    "<b>Reminderemailaccount:</b> (Meeting.REMINDEREMAILACCOUNT)":"Meeting.REMINDEREMAILACCOUNT",
    "<b>Contractvalue:</b> (Meeting.CONTRACTVALUE)":"Meeting.CONTRACTVALUE",
    "<b>Industry:</b> (Meeting.INDUSTRY)":"Meeting.INDUSTRY",
    "<b>Contactcategory:</b> (CONTACT.CONTACTCATEGORY)":"CONTACT.CONTACTCATEGORY",
    "<b>Accountcategory:</b> (CONTACT.ACCOUNTCATEGORY)":"CONTACT.ACCOUNTCATEGORY",
    "<b>Companynamelong:</b> (CONTACT.COMPANYNAMELONG)":"CONTACT.COMPANYNAMELONG",
    "<b>Address1:</b> (CONTACT.ADDRESS1)":"CONTACT.ADDRESS1",
    "<b>Address2:</b> (CONTACT.ADDRESS2)":"CONTACT.ADDRESS2",
    "<b>City:</b> (CONTACT.CITY)":"CONTACT.CITY",
    "<b>State:</b> (CONTACT.STATE)":"CONTACT.STATE",
    "<b>Country:</b> (CONTACT.COUNTRY)":"CONTACT.COUNTRY",
    "<b>Zip:</b> (CONTACT.ZIP)":"CONTACT.ZIP"
    }
    useEffect(() => {
        document.title = `Default Slack Notifications | SalesHive`;
        var Details = GetUserDetails();
        if (Details != null) {
          SetClientID(Details.ClientID);
          SetUserID(Details.ParentUserID);
          SetChildUserID(Details.ChildUserID);
          SetRole(Details.Role);
        }
    
        SlackNotificationsGet(Details.ClientID, Details.ParentUserID, Details.Role,IsRescheduling);
        Froala.RegisterCommand("Variable", {
            title: "Advanced options",
            type: "dropdown",
            focus: false,
            undo: false,
            refreshAfterCallback: true,
            options: Variables,
            callback: function (cmd, val) {
              var editorInstance = this;
              editorInstance.html.insert(val);
            },
            // Callback on refresh.
            refresh: function ($btn) {
              //
            },
            // Callback on dropdown show.
            refreshOnShow: function ($btn, $dropdown) {
              //
            },
          });
      }, [IsRescheduling]);

      let config = {
        placeholderText: "Type something!",
        charCounterCount: false,
        toolbarButtons: [
          "bold",
          "italic",
          "html",
          "Variable",
        ],
        //shortcutsEnabled: ["insertTemplateButton"],
        imageUploadURL:
          CommonConstants.MOL_APIURL + "/meetingremindersetting/upload_image",
        imageUploadRemoteUrls: false,
        enter: Froala.ENTER_BR,
        key:CommonConstants.EditorValidationKey,
        height: 300,
        pastePlain: true,
        attribution: false
      };

      // Getting Slack Notifications details
  const SlackNotificationsGet = (CID, UID, URole,IsRescheduling) => {
    SetIsLoading(true)
    var MeetingGoalData = {
      ClientID: CID,
      UserID: UID,
      Role: URole,
      IsRescheduling:IsRescheduling
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/defaultslacknotification/GetDefaultSlackNotificationDetails",
      method: "POST",
      data: MeetingGoalData,
    });
    rows1.then((Result) => {
      if(Result.data?.Data.length > 0){
        let ObjectData = Result.data?.Data[0];
        let StateData = {...FormData,
         _id:ObjectData?._id,
         UserID:ObjectData?.UserID,
         ClientID: ObjectData?.ClientID,
         SlackText:ObjectData?.SlackText,
         Subject: ObjectData?.Subject,
         ChannelID: ObjectData?.ChannelID,
     }
     PreviewSlacknBody(UID,CID,ObjectData?.Subject,ObjectData?.SlackText)
     SetFormData(StateData)
     SetDataExists(true)
    SetIsLoading(false)

      }else{
        SetDataExists(false)
    SetIsLoading(false)

      }
      
    });
  };

  //Changing Floral Editor Body method
  const SlackTextChange = (model) => {
    let StateData = {...FormData};
    let Obj = {...StateData,SlackText:model}
    SetFormData(Obj)
  };

    //Update OnChange Handler
  const HandleInputChange = (e) => {
        const { name, value } = e.target;
        SetFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
  };

  const SaveSlackNotfication = (e) => {
    e.preventDefault()
    SetIsLoading(true)

    let Data= {...FormData,DataExists};
    if(Data.SlackText.trim() === ""){
      toast.error((<div className="toastsize">Edit Default Slack Notifications <br/>
      Slack text is empty, fill it!</div>))
    }else{
      
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/defaultslacknotification/UpdateAddDefaultSlackNotifications",
        method: "POST",
        data: Data,
      });
      rows1.then((Result) => {
        if(Result.data.StatusMessage === "SUCCESS"){
          SlackNotificationsGet(ClientID, UserID, Role,IsRescheduling)
          PreviewSlacknBody(UserID,ClientID, Data.Subject,Data.SlackText)
    SetIsLoading(false)

          toast.success(<div className="toastsize">Edit Default Slack Notifications <br/>
            Slack notifications updated successfully!</div>)

        }
      })
    }
   
  }


  //Preview Slack body and text
  const PreviewSlacknBody = (UserID,ClientID,Subject,SlackBody) => {
    SetIsLoading(true)

    const InputParameters = {
      UserID,
      ClientID,
      Subject,
      SlackBody
    }
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/defaultslacknotification/DefaultSlackNotificationPerview",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((Result) => {
      if(Result.data.StatusMessage === "SUCCESS"){
        
        let {BodyPerview,SubjectPerview} = Result.data
          
          SetPerviewData({BodyPerview,SubjectPerview})
          
      }
    SetIsLoading(false)
    })
  }

      //Close Popup
      const ClosePopUp = () => {
        SetOpenPopUp(false);
        SetFormDataPopUp({ ChannelName:""});
        SetChannelID("")
        SetIsChannelExists(false)
      }

      //Open Popup
      const OpenPopUpMethod = () => {
        SetOpenPopUp(true)
      }
      
     // Getting Slack ChannelID and name
const SlackChannelMethod = async (value) => {
  SetIsLoading(true);

  try {
    const InputParameters = {
      ChannelName: value
    };

    const res = await Axios.post(
      CommonConstants.MOL_APIURL + "/Utility/CheckSlackChannelExists",
      InputParameters
    );

    SetChannelID(res.data.ChannelID);
    SetIsChannelExists(res.data.IsChannelExists);
    const IsExists = res.data.IsChannelExists;
    SetIsLoading(false);

    return IsExists;
  } catch (error) {
    console.error('Error in SlackChannelMethod:', error);
    SetIsLoading(false);
    throw error;
  }
};

const ValidateField = async (fieldName, value) => {
  const newErrors = { ...Errors };

  switch (fieldName) {
    case 'ChannelName':
      const trimmedValue = value.trim();

      try {
        if (trimmedValue === "") {
          newErrors.ChannelName = 'Please enter slack channel name';
        } else if (!(await SlackChannelMethod(trimmedValue))) {
          newErrors.ChannelName = 'Slack channel name does not exist.';
        } else {
          newErrors.ChannelName = "";
        }
      } catch (error) {
        console.error('Error in SlackChannelMethod:', error);
      }

      break;

    default:
      break;
  }

  SetErrors(newErrors);
};

// Handle Input Change
const HandleInputChangePopUp = (e) => {
  const { name, value } = e.target;
  SetFormDataPopUp((prevFormData) => ({
    ...prevFormData,
    [name]: value,
  }));
}

// Handle Blur
const HandleBlur = async (e) => {
  const { name, value } = e.target;

  try {
    await ValidateField(name, value);
    // Additional logic after validation if needed
  } catch (error) {
    console.error('Error in ValidateField:', error);
  }
};

      
      //Handle Submit
      const HandleSubmit = async (e) => {
        e.preventDefault();
      
        const fieldNames = Object.keys(FormDataPopUp);
      
        try {
          // Use Promise.all to wait for all asynchronous validations to complete
          const validationPromises = fieldNames.map(async (fieldName) => {
            await ValidateField(fieldName, FormDataPopUp[fieldName]);
          });
      
          await Promise.all(validationPromises);
      
          // Check if there are any errors
          const isValid = !Object.values(Errors).some((error) => error);
      
          
      
          if (isValid && FormDataPopUp.ChannelName?.trim() !== "" && IsChannelExists === true) {
            const InputParameters = {
              ChannelID: ChannelID,
              Subject: `<strong>${PreviewData.SubjectPerview}</strong>`,
              SlackText: PreviewData.BodyPerview,
            };
      
            const res = await Axios.post(
              CommonConstants.MOL_APIURL + "/Utility/SendMessageInSlack",
              InputParameters
            );
      
            if (res.data.Status === "SUCCESS") {
              toast.success(<div className="toastsize">Test Message sent!</div>);
              ClosePopUp()
            } else {
              toast.error(<div className="toastsize">Something went wrong!</div>);
            }
      
            SetErrors({});
          } else {
            // Handle the case where the form is not valid
          }
        } catch (error) {
          // Handle errors from ValidateField or Axios if needed
          console.error('Error in handleSubmit:', error);
          toast.error(<div>Unexpected error occurred!</div>);
        }
      };
      

  

  return (
    <div className="tabdes">
      {
        IsLoading ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : <></>
      }
          <Popupone open={OpenPopUp} modal>
          <div className="modal-black"></div>
          <div className="filterPopup bigpopupcontent">
            <div className="paddingboxTerms">
              <div className="modal-header">
                <h5>Slack Channel Name</h5>
                <a className="close-ion" onClick={ClosePopUp}>
                  <span aria-hidden="true" className="la la-remove"></span>
                </a>
              </div>
              <div className="modal-content bordernone p-4">
                <div className="row max-cols">
                  <label className="col-lg-4 view-lable-meet">Slack Channel Name</label>
                  <div className="col-lg-8">
                    <input
                      className="form-control m-input"
                      id="ChannelName"
                      name="ChannelName"
                      placeholder="Enter slack Channel name"
                      type="text"
                      value={FormData.ChannelName}
                        onChange={HandleInputChangePopUp}
                        onBlur={HandleBlur}
                      />
                      {Errors.ChannelName && (
                        <div className="errorinput">{Errors.ChannelName}</div>
                      )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={ClosePopUp}
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  id="m_emailInvite_submit"
                  onClick={HandleSubmit}
                  // disabled={this.state.IsLoading}
                  className="btn btn-primary btnColor"
                >
                  Sent{" "}
                </button>
              </div>
            </div>
          </div>
        </Popupone>
      <div className="row">
        <div className="col border-bottom">
          <h4 className="headertitle float-left pl-0">{HeadingName}</h4>
        </div>
        <div className="col border-bottom px-5">
          <div className="listing-li float-right padb-15"></div>
        </div>
      </div>

      <div className="row">
        <div className="col border-bottom"></div>
        <div className="col border-bottom px-5">
          <div className="listing-li float-right padb-15">
            <ul>
              <li>
                <button
                  onClick={OpenPopUpMethod}
                  className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                >
                  <i className="la la-send la-icon-font-size-13"></i>
                  <span>Send Test</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row pt-4 mb-3">
        <div className="col-lg-12 boxsinput_group">
          <div className="row">
            <label className="col-lg-2 view-lable-meet">Channel</label>
            <div className="col-lg-3">
              <select className="form-control m-input valid"
                value={FormData.ChannelID}
                onChange={HandleInputChange}
                name="ChannelID"
              >
                <option value="">----Select----</option>
                <option value="1">Slack Channel Internal</option>
                <option value="2">Slack Channel External</option>
              </select>
            </div>
          </div>
        </div>

        <div className="col-lg-12 boxsinput_group">
          <div className="row">
            <label className="col-lg-2 view-lable-meet">Subject</label>
            <div className="col-lg-10">
              <input
                className="form-control m-input"
                id="Name"
                name="Subject"
                value={FormData.Subject}
                onChange={HandleInputChange}

                type="text"
              />
            </div>
          </div>
        </div>

        <div className="col-lg-12 boxsinput_group">
          <div className="row">
            <label className="col-lg-2 view-lable-meet">Slack Text</label>
            <div className="col-lg-10">
              <FroalaEditor 
              id="signature"
                    config={config}
                    onModelChange={SlackTextChange}
                    model={FormData.SlackText}
               />
            </div>
          </div>
        </div>
      </div>

      <div className="row border-top pt-4 mt-3">
        <div className="col border-bottom pb-4 ">
          <button className="btn btn-primary btn-lightgreen mr-1" onClick={SaveSlackNotfication}>
            <i className="la la-save"></i> Save
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col border-bottom px-5">
          <h4 className="headertitle float-left pl-0">Preview</h4>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col px-5">
          <p>
            <b>Subject : {PreviewData.SubjectPerview}</b>
            <br/>
            <br/>
            <div dangerouslySetInnerHTML={{ __html: PreviewData.BodyPerview }} />
           
          </p>
        </div>
      </div>
    </div>
  );
}
