import React from 'react'
import { connect } from 'react-redux'
import Axios from 'axios'
var CryptoJS = require('crypto-js')
import { toast } from 'react-toastify'
import { CommonConstants } from '../../_constants/common.constants'
import { GetUserDetails } from '../../_helpers/Utility'
import { userActions } from '../../_actions'
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar'
import ClientsLists from '../../_components/clients/lists/lists'
import Footer from '../../_components/user/footer/footer'
import Popupone from 'reactjs-popup'
const EmailTemplete = require('../../_helpers/email-templete')

import ProfileImageUpload from '../../_components/ProfileUpload'

const Fileupload = require('../../_helpers/fileupload')
import { history } from '../../_helpers'
import loadingicon from '../../images/loading.gif'

const imgstyle = { width: '100px', height: '100px' }

class CEditMyProfilePage extends React.Component {
  constructor (props) {
    var Details = GetUserDetails()
    super(props)
    this.childRef = React.createRef()
    this.state = {
      UserData: [],
      InvitePopup: false,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      Role: Details.Role,
      Email: null,
      ShowExtraFields: true,
      CountryList: [],
      ImgFlag: false,
      FinalImg: '',
      FileImg: '',
      ImgFlag: false,
      FileImg: [],
      CountryID: null,
      Errors: {},
      ChildKey: 0
    }
    this.SaveProfile = this.SaveProfile.bind(this)
    this.OpenInvitePopup = this.OpenInvitePopup.bind(this)
    this.CloseInvitePopup = this.CloseInvitePopup.bind(this)
    this.SubmitInvitePopup = this.SubmitInvitePopup.bind(this)
  }
  componentDidMount () {
    var Details = GetUserDetails()
    if (Details != null) {
      this.setState({ ClientID: Details.ClientID })
      this.setState({ CUserID: Details.ChildUserID })
      this.setState({ UserID: Details.ParentUserID })
      this.setState({ Role: Details.Role })
    }
    this.GetUserData(Details.ChildUserID, Details.Role)

    if (
      Details.Role == 'Client' ||
      Details.Role == 'Responder' ||
      Details.Role == 'ColdCallers' ||
      Details.Role == 'SalesHacker'
    ) {
      this.setState({ ShowExtraFields: false })
    } else {
      this.setState({ ShowExtraFields: true })
    }
  }

  decryptPassword(encryptedPassword) {
    try {
      const decryptedPasswordBytes = CryptoJS.AES.decrypt(encryptedPassword.replace(/ /g, '+'), "my-secret-key@123");
      return decryptedPasswordBytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error('Error during password decryption:', error);
      return ''; // Return an empty string as a default value
    }
  }
  //Getting user data
  GetUserData (CUID, Role) {
    document.getElementById('hideloding').style.display = 'block'

    const InputParameters = {
      ChildUserID: CUID,
      AccountType: Role
    }
    Axios({
      url: CommonConstants.MOL_APIURL + '/login/GetLoginUserDetails',
      method: 'POST',
      data: InputParameters
    }).then(res => {
      const Pass = this.decryptPassword(res.data.Data.Password);
      this.setState({ UserData: res.data.Data })
      this.setState({ FinalImg: res.data.Data.ProfileImage })
      this.setState({ Email: res.data.Data.Username })
      document.getElementById('Password').value = Pass
      document.getElementById('FirstName').value = res.data.Data.FirstName
      document.getElementById('LastName').value = res.data.Data?.LastName
      document.getElementById('Is2FA').checked =res.data.Data?.IsTwoStepVerification
      document.getElementById('IsSentMeetingNotification').checked =res.data.Data?.IsSentMeetingNotification
      document.getElementById('IsSentPotentialMeetingNotification').checked =res.data.Data?.IsSentPotentialMeetingNotification
      document.getElementById('IsSentWeeklyEmailNotification').checked =res.data.Data?.IsSentWeeklyEmailNotification
      document.getElementById('hideloding').style.display = 'none'

      document.getElementById('hideloding').style.display = 'none'
    })
  }

  //upload
  HandleUpload (field, e) {
    e.preventDefault()
    if (
      Fileupload.checkMimeType(e.target.files[0]) &&
      Fileupload.checkFileSize(e.target.files[0])
    ) {
      var temp = URL.createObjectURL(e.target.files[0])
      this.setState({
        FinalImg: e.target.files[0]
      })
      this.setState({ ImgFlag: true })
      this.setState({ FileImg: temp })
    } else {
    }
  }

  //First name validation
  CheckFirstName () {
    let Errors = {}
    let FirstName = document.getElementById('FirstName').value.trim()
    if (FirstName == '') {
      Errors['FirstName'] = 'Please enter First Name'
      this.setState({ Errors: Errors })
      return false
    } else {
      Errors['FirstName'] = ''
      this.setState({ Errors: Errors })
      return true
    }
  }
  //Save Profile details
  async SaveProfile (e) {
    let Image = await this.childRef.current.SubmitData()
    let ImageName = Image.FileName

    this.setState({ FinalImg: ImageName })
    let Errors = {}
    document.getElementById('submit').disable = true
    let FirstName = document.getElementById('FirstName').value.trim()
    if (FirstName != '') {
      document.getElementById('hideloding').style.display = 'block'
      let FirstName = '',
        LastName = '',
        Is2FA = ''
      let Role = this.state.Role
      FirstName = document.getElementById('FirstName').value
      LastName = document.getElementById('LastName').value
      Is2FA = document.getElementById('Is2FA').checked
      let IsSentMeetingNotification = document.getElementById(
        'IsSentMeetingNotification'
      ).checked
      let IsSentPotentialMeetingNotification = document.getElementById(
        'IsSentPotentialMeetingNotification'
      ).checked
      let IsSentWeeklyEmailNotification = document.getElementById(
        'IsSentWeeklyEmailNotification'
      ).checked

      const InputParameters = {
        FirstName: FirstName,
        LastName: LastName,
        IsSentWeeklyEmailNotification,
        IsSentPotentialMeetingNotification,
        IsSentMeetingNotification,
        TwoFA: Is2FA,
        Role: this.state.Role,
        UserID: this.state.UserID,
        ProfileImage: this.state.FinalImg,
        LastUpdatedDate: new Date(),
        LastUpdatedBy: this.state.CUserID
      }

      // User profile updated successfully
      let res = await Axios({
        url: CommonConstants.MOL_APIURL + '/login/UpdateProfile',
        method: 'POST',
        data: InputParameters
      })

      if (res.data.StatusMessage == 'SUCCESS') {
        this.setState({ ChildKey: this.state.ChildKey + 1 })
        toast.success('User profile updated successfully.')
      } else {
        toast.error(res.data.Message)
      }

      // } else {
      //     var name = this.state.FinalImg.name;
      //     var filetype = name.split(".");

      //     var file_Name;
      //     if (/\s/g.test(filetype[0])) {
      //         file_Name =
      //             filetype[0].replaceAll(/\s/g, "") +
      //             new Date().getTime() +
      //             "." +
      //             filetype[1] +
      //             "";
      //     } else {
      //         file_Name = filetype[0] + new Date().getTime() + "." + filetype[1] + "";
      //     }
      //     await Fileupload.getBase64(this.state.FinalImg, (result) => {
      //         var rs = result;
      //         var splirs = rs.split("base64,");
      //         var _base64 = splirs[1];
      //         var myresult = {
      //             fields: "Coverimg",
      //             path: "./Content/UploadedFiles/UserProfilePic",
      //             file: _base64,
      //             imageName: file_Name,
      //         };
      //         Axios({
      //             url: CommonConstants.MOL_APIURL + "/AdminUser/ImageSave",
      //             method: "POST",
      //             data: myresult,
      //         }).then((res) => {

      //             const InputParameters = {
      //                 FirstName: FirstName,
      //                 LastName: LastName,
      //                 IsSentWeeklyEmailNotification,
      //                 IsSentPotentialMeetingNotification,
      //                 IsSentMeetingNotification,
      //                 TwoFA: Is2FA,
      //                 Role: this.state.Role,
      //                 UserID: this.state.UserID,
      //                 ProfileImage: file_Name
      //             }

      //             Axios({
      //                 url: CommonConstants.MOL_APIURL + "/login/UpdateProfile",
      //                 method: "POST",
      //                 data: InputParameters,
      //             }).then((res) => {

      //                 if (res.data.StatusMessage == "SUCCESS") {
      //                     toast.success("User profile updated successfully.")
      //                     this.setState({ ChildKey: this.state.ChildKey + 1 })
      //                 } else {
      //                     toast.error(res.data.Message)
      //                 }
      //             });
      //         });
      //     });

      // }

      document.getElementById('hideloding').style.display = 'none'
      document.getElementById('submit').disable = false
    } else {
      Errors['FirstName'] = 'Please enter First Name'
      document.getElementById('hideloding').style.display = 'none'
      document.getElementById('submit').disable = false
    }
    this.setState({ Errors: Errors })
  }
  //backBtn
  BackBtn () {
    history.push('/ckpis')
  }

  OpenInvitePopup () {
    this.setState({ InvitePopup: true })
  }
  CloseInvitePopup () {
    this.setState({ InvitePopup: false })
    this.setState({ Message: '' })
  }
  async CheckUpdateEmail (Email) {
    let Emails = document.getElementById('change_mail').value
    var InputParameters = {
      ParentUserID: '',
      // UserID: this.state.UserID,
      Email: Emails
    }
    let resdata = await Axios({
      url: CommonConstants.MOL_APIURL + '/AdminUser/UpdateEmailValidation',
      method: 'POST',
      data: InputParameters
    })
    if (resdata.data.StatusMessage == 'SUCCESS') {
      if (resdata.data.Flag) {
        this.setState({ Message: resdata.data.Message })
        return false
      } else {
        this.setState({ Message: '' })
        return true
      }
    }
  }
  // Whole form validation
  async FormValidation1 () {
    let formIsValid = true
    let Email = document.getElementById('change_mail').value
    let IsExists = await this.CheckUpdateEmail(Email)

    if (IsExists) {
      formIsValid = true
    } else {
      formIsValid = false
    }
    if (Email.trim().length <= 0) {
      formIsValid = false
      this.setState({ Message: 'Please enter email.' })
    }

    return formIsValid
  }

  //Email change
  async SubmitInvitePopup (e) {
    document.getElementById('hideloding').style.display = 'block'
    e.preventDefault()
    e.currentTarget.disabled = true
    var Final_flag = await this.FormValidation1()
    if (Final_flag == true) {
      var sendEmail = document.getElementById('change_mail').value
      var tmp_token =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
      // var email_token = CryptoJS.MD5(tmp_token).toString();
      var email_token = CryptoJS.AES.encrypt(
        JSON.stringify(tmp_token),
        'my-secret-key@123'
      ).toString()
      var uid = this.state.UserData.ClientUserID
      var str_in = {
        uID: uid,
        Email_Token: email_token
      }

      Axios({
        url: CommonConstants.MOL_APIURL + '/user/changeemail',
        method: 'POST',
        data: str_in
      }).then(res => {
        if (res.data != '') {
          var Username = sendEmail
          var email = CryptoJS.AES.encrypt(
            JSON.stringify(sendEmail),
            'my-secret-key@123'
          ).toString()
          var emailData = EmailTemplete.userEmailChange(
            email_token,
            email,
            CommonConstants.change_Email_usersperclient
          )
          var str_mail = {
            email: Username,
            html: emailData,
            subject: 'Please Confirm Your Email'
          }
          Axios({
            url: CommonConstants.MOL_APIURL + '/AdminUser/EmailSend',
            method: 'POST',
            data: str_mail
          }).then(res => {
            toast.success('Mail sent successfully.')
          })
        }
      })
      this.setState({ InvitePopup: false })
    } else {
      document.getElementById('m_emailInvite_submit').disabled = false
    }
    document.getElementById('hideloding').style.display = 'none'
  }

  render () {
    return (
      <>
        <Popupone open={this.state.InvitePopup} modal>
          <div className='modal-black'></div>
          <div className='filterPopup bigpopupcontent'>
            <div className='paddingboxTerms'>
              <div className='modal-header'>
                <h5>Send Mail</h5>
                <a className='close-ion' onClick={this.CloseInvitePopup}>
                  <span aria-hidden='true' class='la la-remove'></span>
                </a>
              </div>
              <div className='modal-content bordernone'>
                <div class='row max-cols'>
                  <label class='col-lg-4 view-lable-meet'>Email</label>
                  <div class='col-lg-8'>
                    <input
                      class='form-control m-input'
                      name='Email'
                      placeholder='Email'
                      id='change_mail'
                      type='text'
                      onBlur={this.CheckUpdateEmail.bind(this, 'Email')}
                    />
                    <span style={{ color: 'red' }}>{this.state.Message}</span>
                  </div>
                </div>
              </div>
              <div class='modal-footer'>
                <button
                  type='button'
                  class='btn btn-secondary'
                  onClick={this.CloseInvitePopup}
                  data-dismiss='modal'
                >
                  Cancel
                </button>
                <a
                  id='m_emailInvite_submit'
                  href='javascript:void(0);'
                  onClick={this.SubmitInvitePopup}
                  class='btn btn-primary btnColor'
                >
                  Send{' '}
                </a>
              </div>
            </div>
          </div>
        </Popupone>
        <div
          id='hideloding'
          className='loding-display'
          style={{ display: 'none' }}
        >
          <img src={loadingicon} />
        </div>
        <div className='adminmain'>
          <Sidebar key={this.state.ChildKey} className='' />
          <div className='bodyhome'>
              <div className='row'>
                <div className='col'>
                  <h4 className='headertitlepad pl-0 mb-0'>
                    Edit Client
                  </h4>
                </div>
                <div className='col padright'></div>
              </div>

            <div class='paddcols px-0'>
              <div className='row pt-4 px-4'>
                <div className='col formcontral'>
                  <div class='col-lg-12 row boxsinput_group py-1'>
                    <div className='col-lg-6'>
                      <div class='row'>
                        <label class='col-lg-4 view-lable-meet'>Email</label>
                        <div class='col-lg-8 pt-2'>{this.state.Email}</div>
                      </div>
                    </div>
                    <div class='col-lg-5'>
                      <a
                        href='javascript:void(0);'
                        id='lnkUpdateEmail'
                        onClick={this.OpenInvitePopup}
                        class='btn btn-primary btngroup'
                      >
                        Update Email
                      </a>
                    </div>
                  </div>

                  <div class='col-lg-6 boxsinput_group py-2'>
                    <div class='row'>
                      <label class='col-lg-4 view-lable-meet'>Password</label>
                      <div class='col-lg-8'>
                        <input
                          type='Password'
                          class='form-control m-input'
                          id='Password'
                          name='Password'
                          readOnly={true}
                          placeholder=''
                        />
                      </div>
                    </div>
                  </div>

                  <div class='col-lg-6 boxsinput_group py-2'>
                    <div class='row'>
                      <label class='col-lg-4 view-lable-meet'>First Name</label>
                      <div class='col-lg-8'>
                        <input
                          type='text'
                          class='form-control m-input'
                          id='FirstName'
                          name='unsubscribe'
                          placeholder='First name'
                          onBlur={() => {
                            this.CheckFirstName()
                          }}
                        />
                        <span style={{ color: 'red' }}>
                          {this.state.Errors['FirstName']}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class='col-lg-6 boxsinput_group py-2'>
                    <div class='row'>
                      <label class='col-lg-4 view-lable-meet'>Last Name</label>
                      <div class='col-lg-8'>
                        <input
                          type='text'
                          class='form-control m-input'
                          id='LastName'
                          name='unsubscribe'
                          placeholder='Last name'
                        />
                      </div>
                    </div>
                  </div>

                  <div class='col-lg-6 my-3'>
                    <div class='m-checkbox-inline'>
                      <label class='check_bx'>
                        <input type='checkbox' id='IsSentMeetingNotification' />
                        <span className='checkmark'></span>
                        Is Sent Meeting Notification
                      </label>
                    </div>
                  </div>
                  <div class='col-lg-6'></div>
                  <div class='col-lg-6 my-3'>
                    <div class='m-checkbox-inline'>
                      <label class='check_bx'>
                        <input
                          type='checkbox'
                          id='IsSentPotentialMeetingNotification'
                        />
                        <span className='checkmark'></span>
                        Is Sent Potential Meeting Notification
                      </label>
                    </div>
                  </div>
                  <div class='col-lg-6'></div>
                  <div class='col-lg-6 mb-2'>
                    <div class='m-checkbox-inline'>
                      <label class='check_bx'>
                        <input
                          type='checkbox'
                          id='IsSentWeeklyEmailNotification'
                        />
                        <span className='checkmark'></span>
                        Is Sent Weekly Email Notification
                      </label>
                    </div>
                  </div>

                  <div class='col-lg-6 boxsinput_group'>
                    <div class='d-flex'>
                      <div class='toggleswich mt-2'>
                        <input type='checkbox' class='checktogle' id='Is2FA' />
                        <b class='switch'>
                          <span class='checkion'></span>
                          <span class='uncheckion'></span>
                        </b>
                        <b class='track'></b>
                      </div>

                      <label class='col-lg-8 view-lable-meet'>
                        Is Two Step Verification
                      </label>
                    </div>
                  </div>

                  <ProfileImageUpload
                    profileImage={this.state.FinalImg}
                    ref={this.childRef}
                  />
                  {/* <div class="col-lg-6 boxsinput_group py-2">
                                        <div class="row">
                                            <label class="col-lg-4 view-lable-meet">
                                                Upload Profile Image
                                            </label> 
                                        
                                        <div className="col-lg-8">
                                            <input
                                                type="file"
                                                id="imageUpload"
                                                accept=".png, .jpg, .jpeg"
                                                name="avatar"
                                                onChange={this.HandleUpload.bind(this, "UserProfilePic")}
                                            />
                                            <div>
                                                <label>
                                                    {this.state.ImgFlag == true
                                                        ? this.state.FinalImg.name
                                                        : this.state.UserData.ProfileImage}
                                                </label>
                                            </div>
                                            <div id="imagePreview">
                                                <img
                                                    style={imgstyle}
                                                    className="avatar-view container2"
                                                    src={
                                                        this.state.ImgFlag == true
                                                            ? this.state.FileImg
                                                            : CommonConstants.Image_url +
                                                            this.state.UserData.ProfileImage
                                                    }
                                                    id="avtar-preview"
                                                />
                                            </div>
                                        </div>
                                        </div>
                                    </div> */}

                  </div>
              </div>
            </div>
      
            <div class='row my-3'>
              <div class='col-lg-12 pull-left'>
                <button
                  id='submit'
                  onClick={e => this.SaveProfile(e)}
                  class='btn btn-primary btn-lightgreen mr-1'
                >
                  <i class='la la-save'></i> Save
                </button>
                <a onClick={this.BackBtn} class='btn btn-secondary'>
                  Back
                </a>
              </div>
            </div>
              
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

function mapState (state) {
  const { loggingIn } = state.authentication
  return { loggingIn }
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout
}

const connectedCEditMyProfilePage = connect(
  mapState,
  actionCreators
)(CEditMyProfilePage)
export { connectedCEditMyProfilePage as CEditMyProfilePage }
