//import config from 'config';
import { authHeader } from '../_helpers';

export const userService = {
    login,
    logout,
};

function login(username, password) {
   const email = username
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'},
        body: JSON.stringify({ email, password, mode: 'no-cors' })
    };
//mode: 'no-cors'
    return fetch(`https://reqres.in/api/login`, requestOptions )
    .then(response => response.json())
    .then(user => {
        console.log(user)
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));

        return user;
    });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}