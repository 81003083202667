import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";

toast.configure();

class EditPotentialMeetingStatusPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pager: {},
      pageOfItems: [],
      files: [],
      list: [],
      columns: [],
      reset: false,
      csvData: [],
      code: "",
      fields: {},
      errors: {},
      nameAvailable: null,
      ClientID: null,
      UserID: null,
      CUserID: null,
    };

    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
    }
    this.EditPotentialMeetingStatus();
  }

  FromValidation() {
    let formIsValid = true;
    if (Object.entries(this.state.fields).length > 0) {
      let fields = this.state.fields;
      let errors = {};

      if (!fields["Status"]) {
        formIsValid = false;
        errors["Status"] = "Please enter Status";
      }
      if (this.state.nameAvailable != null) {
        formIsValid = false;
      }
      this.setState({ errors: errors });
    }
    return formIsValid;
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.Status != "") {
      this.state.errors.Status = null;
      this.state.nameAvailable = null;
      this.CheckExistStatus(e.target.value);
    } else {
      this.state.nameAvailable = null;
      this.state.errors.Status = null;
    }
  }

  CheckExistStatus(Status) {
    var str_in = {
      id: this.state.id,
      Status: Status,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/meetingsources/MeetingSourcesExists",
      method: "POST",
      data: str_in,
    }).then((resdata) => {
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.data.length > 0) {
          this.setState({ nameAvailable: resdata.data.data });
        }
      }
    });
  }

  EditPotentialMeetingStatus() {
    const id = this.props.location.state;
    this.setState({ id: id });
    var str_in = {
      id: id,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/potentialmeetingstatus/PotentialMeetingStatusEdit",
      method: "POST",
      data: str_in,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById("Status").value =
          res.data &&
          res.data &&
          res.data.data &&
          res.data.data &&
          res.data.data.Status &&
          res.data.data.Status;
      } else {
        toast.error(res.data.Message);
      }
    });
  }

  backbtn() {
    history.push("/potentialmeetingstatus");
  }
  savebtn(e) {
    e.preventDefault();
    if (this.FromValidation()) {
      var Status = document.getElementById("Status").value;
      var data = {
        Status: Status,
        LastUpdatedBy: this.state.CUserID,
        LastUpdatedDt: new Date(),
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
        OrderBy: 2,
      };

      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/potentialmeetingstatus/PotentialMeetingStatusUpdate",
        method: "POST",
        data: data,
      }).then((res) => {
        console.log(res);
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            "Potential Meeting Status updated successfully.",
            "Edit potentail status"
          );
          history.push("/potentialmeetingstatus");
        } else {
          toast.error(res.data.Message);
        }
      });
    } else {
      return;
    }
  }

  render() {
    return (
      <>
        <div className="adminmain"> 
            <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left mb-0">
                    Edit Engagement Status
                  </h4>
                </div>
              </div>

            <div class="paddcols">
              <div className="row">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Status</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter  Status"
                        onChange={this.handleChange.bind(this, "Status")}
                        value={this.state.fields["Status"]}
                        id="Status"
                        name="Status"
                        placeholder="Status"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Status"]}
                      </span>
                      {this.state.nameAvailable && (
                        <span style={{ color: "red" }}>
                          Status already exist.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div class="col-lg-6 boxsinput_group">
                            <div class="row max-cols">
                                <label class="col-lg-4 view-lable-meet">Last Name</label>
                                <div class="col-lg-8">
                                    <input class="form-control m-input" id="LastName" name="LastName" placeholder="Last name" type="text" value="" />
                                </div>
                            </div>
                        </div> */}
              </div>
            </div>

              <div class="row py-4">
                <div class="col-lg-12 pull-left">
                  <a
                    id="submit"
                    onClick={this.savebtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </a>
                  <a
                    id="backtolist"
                    onClick={this.backbtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditPotentialMeetingStatusPage = connect(
  mapState,
  actionCreators
)(EditPotentialMeetingStatusPage);
export { connectedEditPotentialMeetingStatusPage as EditPotentialMeetingStatusPage };
