import React from "react";
import Axios from "axios";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";
const moment = require("moment");
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import CreatableSelect from 'react-select/creatable';
class EditContactPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      ClientID: null,
      UserID: null,
      ContactSourceData: [],
      ClientPOCData: [],
      ReasonUnqualifiedData: [],
      ContactTagData: [],
      ContactOwnerData: [],
      ContactSalesDevlopMenData: [],
      ContactCustomFieldData: [],
      ContactStatusData: [],
      ContactSourceDefaultSelect: null,
      ContactSalesDevlopmentRepsDefaultSelect: null,
      ContactTagDefaultSelect: [],
      ContactStatusDefaultSelect: null,
      ContactOwnerDefaultSelect: null,
      ClientPOCDefaultSelect: null,
      ReasonDefaultSelect: null,
      ContactStatusDefaultSelect: null,
      IsDoNotCallContactData: null,
      ProspectIDData: "",
      Email: "",
      ProspectCustomFieldByIdArray: [],
      ContactCustomFieldArray: [],
      LastCallDate:"",
      // potentmeeting data
      PotentialMeetingData: [],
      PMRows: [],
      PMRlen: 0,
      PMFlen: 0,
      PMCountPage: 0,
      PMPage: 1,
      PMRowsPerPage: 100,
      PMSearch: "",
      PMSortField: "FirstName",
      PMSortedBy: 1,
      PMSflag: false,

      // lists prosepct
      ListData: [],
      ListRows: [],
      LRlen: 0,
      LFlen: 0,
      LCountPage: 0,
      PageL: 1,
      RowsPerPageL: 100,
      SearchL: "",
      SortFieldL: "Name",
      SortedByL: 1,
      SflagL: false,

      // contact status history
      HistoryData: [],
      HRows: [],
      HRlen: 0,
      HFlen: 0,
      HCountPage: 0,
      HPage: 1,
      HRowsPerPage: 100,
      HSearch: "",
      HSortField: "Status",
      HSortedBy: 1,
      HSflag: false,

      Page: 1,
      RowsPerPage: 100,
      Search: "",

      // potentmeeting data
      PotentialMeetingData: [],
      PMRows: [],
      PMRlen: 0,
      PMFlen: 0,
      PMCountPage: 0,
      PMPage: 1,
      PMRowsPerPage: 100,
      PMSearch: "",
      PMSortField: "FirstName",
      PMSortedBy: 1,
      PMSflag: false,
      PMNotes: [],

      // meeting data
      MeetingData: [],
      MRows: [],
      MRlen: 0,
      MFlen: 0,
      MCountPage: 0,
      MPage: 1,
      MRowsPerPage: 100,
      MSearch: "",
      MSortField: "FirstName",
      MSortedBy: 1,
      MSflag: false,
      MNotes: [],

      // call history data
      CallHistoryData: [],
      CRows: [],
      CRlen: 0,
      CFlen: 0,
      CCountPage: 0,
      CPage: 1,
      CRowsPerPage: 100,
      CSearch: "",
      CSortField: "FirstName",
      CSortedBy: 1,
      CSflag: false,

      // campaign history data
      CampaignHistoryData: [],
      CAMRows: [],
      CAMRlen: 0,
      CAMFlen: 0,
      CAMCountPage: 0,
      CAMPage: 1,
      CAMRowsPerPage: 100,
      CAMSearch: "",
      CAMSortField: "CampaignName",
      CAMSortedBy: 1,
      CAMSflag: false,

      // contact status
      ContactStatusDData: [],
      ContactData: [],
      ProspectIdForNote: "",
      ContactTagSelectArray: [],

      // Note
      Page: 1,
      RowsPerPage: 100,
      Search: "",
      SortField: "Title",
      SortedBy: 1,
      Sflag: false,
      Data: [],
      Rows: [],
      Rlen: 0,
      Flen: 0,
      CountPage: 0,
      SearchData: [],
      TitleAvailableNotes: null,
      Fields1: {},
      Errors1: {},
      PageType: "",
      Errors: {},

      // for note
      NotePage: 1,
      NoteRowsPerPage: 100,
      NoteSearch: "",
      NoteSortField: "Title",
      NoteSortedBy: 1,
      NoteSflag: false,
      NoteData: [],
      NoteRows: [],
      NoteRlen: 0,
      NoteFlen: 0,
      NoteCountPage: 0,
      OldTitleUpdate: null,

          // Call Note for
          CallNoteData: [],
          CallNoteRows: [],
          CallNoteRlen: 0,
          CallNoteFlen: 0,
          CallNoteCountPage: 0,
          CallNotePage: 1,
          CallNoteRowsPerPage: 100,
          CallNoteSearch: "",
          CallNoteSortField: "FirstName",
          CallNoteSortedBy: 1,
          CallNoteSflag: false,

           // Step histroy
           STHData: [],
           STHRows: [],
           STHRlen: 0,
           STHFlen: 0,
           STHCountPage: 0,
           STHPage: 1,
           STHRowsPerPage: 100,
           STHSearch: "",
           STHSortField: "CampaignName",
           STHSortedBy: 1,
           STHSflag: false,
      TagArray: [],
      SendEmailPopup: false,
      EditContactTagArray: [],
      defaultTagArray: [],
      contactoptions: [],
      TagNameArray: [],
      TagIDArray: [],

      errors: {},
      IsInValidWebsite: false,
      IsValidEmail: false,
      NewArrayCustomField: [],

      ShowNotePopup: false,
      Role: "",
      CUserID: null,
      Open: false,
      BtnDisabled: false,
      SalesDevelopmentRepsData: [],
      id: null,
      NotesData: [],
      ProspectNoteBtnDisabled: false,
      IsLoading: true,
      BtnDisabledUpdate: false,
            // send email
            EmailDataSend: [],
            SelectedValueEmail: "",
            fields: {},
            errors: {},
            AccountData: [],
            AccountRefreshToken: "",
            AccountEmail: "",
            SendEmailPopup: false,

          // Contact Activity Data:
          ContactActivityData:[],
          ListData:[],
          IsListFalg:false,
          ProspectStatusHData:[],
          MeetingStatusHData:[],
          PotentialMeetingCSAData:[],
          MeetingCSAData:[],
          CampaignStatushistory:[],
          Sentmailcampaigndata:[],
          Externaldomaintreackdata:[],
          Callprospecthistorydata:[],
          BlacklistEmailHistoryData:[],
          CreatedDate:null,

          TotalEmailCount:null,
          TotalReplyCount:null,
          TotalSendEmailCount:null,
          TotalTrackedEmailCount:null,
          TotalOpenCount:null,
          TotalHardBounceCount:null,
          TotalSoftBounceCount:null,
          LastEmailStep:null,
          LastEmaiCampaign:"",
          LastEmailSendDate:null,
          LastOpenDate:null,
          LastResponseDate:null,
          OldEmail:null,
          EmailAvailable:null,
          ContactDomainBlacklistedData:[],
          EditColdCallerSaleDevReps:[],
          Role:"",
          UserName:"",
          ColdCallerLoginSalesDevlopmentName:"",

          AccountNameForDisplay:"",
          AccountIdForViewAccount:null,
          FollowUpdateEdit:null,
          ContactStatusAllActivity:[],
          LastStatusChangeDate:null,
          BlacklistedDate:null,
          SendEmailRefreshToken:"",
          SendEmailAccount:null,
    };

    this.NoteGet();
    this.PotentialMeetingGet();
    this.ListsGet();
    this.MeetingGet();
    this.CallHistoryGet();
    this.CampaignHistoryGet();
    this.ContactStatusGet();
    this.ContactEdit();
    this.ContactStatusHistoryGet();
    this.CustomFieldValueGet();
    this.CallNoteGet();
    this.StepHistoryGet();
    this.ContactActivityGet()
    this.ContactUpdate = this.ContactUpdate.bind(this);
    this.ProspectNoteEditBtn = this.ProspectNoteEditBtn.bind(this);
    this.ProspectNoteSave = this.ProspectNoteSave.bind(this);
    this.ProspectNoteUpdate = this.ProspectNoteUpdate.bind(this);
    this.ChangeRowSelected = this.ChangeRowSelected.bind(this)
    this.ViewBtn = this.ViewBtn.bind(this);
    this.Handlevalidationfornotesedit = this.Handlevalidationfornotesedit.bind(this)
   
    this.HandleChangePage = this.HandleChangePage.bind(this);
    this.ChangeRowSelectedPotentialMeeting = this.ChangeRowSelectedPotentialMeeting.bind(this);
    this.HandleChangePagePotentialMeeting = this.HandleChangePagePotentialMeeting.bind(this);
    this.ChangeRowSelectedMeeting = this.ChangeRowSelectedMeeting.bind(this);
    this.HandleChangePageMeeting = this.HandleChangePageMeeting.bind(this);
    this.ChangeRowSelectedCampaignHistory = this.ChangeRowSelectedCampaignHistory.bind(this);
    this.HandleChangePageCampaignHistory = this.HandleChangePageCampaignHistory.bind(this);
    this.ChangeRowSelectedList = this.ChangeRowSelectedList.bind(this);
    this.HandleChangePageList = this.HandleChangePageList.bind(this);
    this.ChangeRowSelectedContactStatusHistory = this.ChangeRowSelectedContactStatusHistory.bind(this);
    this.HandleChangePageContactStatusHistory = this.HandleChangePageContactStatusHistory.bind(this);
    this.ChangeRowSelectedCallHistory = this.ChangeRowSelectedCallHistory.bind(this);
    this.HandleChangePageCallHistory = this.HandleChangePageCallHistory.bind(this);

  }

  componentDidMount() {
    document.title = "Edit Contact | SalesHive";

    const details = GetUserDetails();
    this.setState({ Role: details.Role });
    if (details != null) {
      this.setState({
        ClientID: details.ClientID,
        UserID: details.ParentUserID,
        CUserID: details.ChildUserID,
        Role: details.Role,
      });
    }
    this.ContactsGetIdByData();
    this.ContactInfoGetData(details.ClientID, details.ParentUserID, details.Role,details.Username);
  }

  // contact update
  ContactEdit() {
    const id = this.props.location.state;
    this.setState({ id: id });
    var str_in = {
      ProspectID: id,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactGetByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
      this.setState({ ContactData: res.data.Data });
      this.setState({ ProspectIdForNote: res.data.Data[0]?._id,
      CreatedDate:res.data.Data[0]?.CreatedDate});
      this.setState({
        FollowUpdateEdit:res.data.Data[0]?.FollowUpDate
      })
    });
  }

    // Contact Activity Get
    async ContactActivityGet() {
      try {
        this.setState({ IsLoading: true });
    
        const { ClientID, ParentUserID } = GetUserDetails();
        const { data } = this.props.location.state;
        const { EmailData } = this.props.location.state;
        const ContactActivityDataStr = {
          ClientID: ClientID,
          UserID: ParentUserID,
          ProspectId: data,
          Type: "User",
          Role: this.state.Role,
          Email:EmailData
        };
    
        const result = await Axios.post(
          `${CommonConstants.MOL_APIURL}/contacts/ContactActivityGet`,
          ContactActivityDataStr
        );
    
        this.setState({
          ContactActivityData: result.data?.Data,
          ListData:result.data?.ListData,
          IsListFalg:result.data?.ListUploadFlag,
          LastCallDate:result.data?.Callprospecthistorydata?.map(item => item?.CreatedDate).reverse()?.[0],
          // ProspectStatusHData:result.data?.ProspectStatusData.reverse(),
          // MeetingStatusHData:result.data?.MeetingStatusHistoryData.reverse(),
          // PotentialMeetingCSAData:result.data?.PotentialMeetingData.reverse(),
          // ContactDomainBlacklistedData:result.data?.ContactDomainBlacklistedData,
          // MeetingCSAData:result.data?.MeetingData.reverse(),
          // CampaignStatushistory:result.data?.CampaignStatushistory.reverse(),
          // Sentmailcampaigndata:result.data?.Sentmailcampaigndata.reverse(),
          // Externaldomaintreackdata:result.data?.Externaldomaintreackdata,
          // Callprospecthistorydata:result.data?.Callprospecthistorydata,
          // BlacklistEmailHistoryData:result.data?.BlacklistEmailHistorydata,

          
          IsLoading: false,
        });

        
        // Push ProspectStatusHData with IsProspectStatusFlag if it has data
        if (result.data?.ProspectStatusData.length > 0) {
          this.state.ContactStatusAllActivity.push(...result.data?.ProspectStatusData.map(item => ({ ...item, IsProspectStatusFlag: true })));
        }
        
        // Push CampaignStatushistory with IsProspectCampaignStatusFlag if it has data
        if (result.data?.CampaignStatushistory.length > 0) {
          this.state.ContactStatusAllActivity.push(...result.data?.CampaignStatushistory.map(item => ({ ...item, IsProspectCampaignStatusFlag: true })));
        }
        
        // Push Sentmailcampaigndata with IsSentEmailFlag if it has data
        if (result.data?.Sentmailcampaigndata.length > 0) {
          this.state.ContactStatusAllActivity.push(...result.data?.Sentmailcampaigndata.map(item => ({ ...item, IsSentEmailFlag: true })));
        }
        
         // Push Meeting status history data with IsMeetingStatusFlag if it has data
         if (result.data?.MeetingStatusHistoryData.length > 0) {
                this.state.ContactStatusAllActivity.push(...result.data?.MeetingStatusHistoryData.map(item => ({ ...item, IsMeetingStatusFlag: true })));
              }
        //    // Push potential meeting history data with IsPotentialMeetingFlag if it has data
           if (result.data?.PotentialMeetingData.length > 0) {
            this.state.ContactStatusAllActivity.push(...result.data?.PotentialMeetingData.map(item => ({ ...item, IsPotentialMeetingFlag: true })));
          }

        //   // Push  meeting  data with IsMeetingFlag if it has data
          if (result.data?.MeetingData.length > 0) {
                    this.state.ContactStatusAllActivity.push(...result.data?.MeetingData.map(item => ({ ...item, IsMeetingFlag: true })));
          }
        //    // Push contact domain blacklisr data with IsContactDomainBlacklistedFlag if it has data
           if (result.data?.ContactDomainBlacklistedData.length > 0) {
            this.state.ContactStatusAllActivity.push(...result.data?.ContactDomainBlacklistedData.map(item => ({ ...item, IsContactDomainBlacklistedFlag: true })));
          }

        // // // Push external domain track domain data with IsExternalDomainTrackFlag if it has data
          if (result.data?.Externaldomaintreackdata.length > 0) {
          this.state.ContactStatusAllActivity.push(...result.data?.Externaldomaintreackdata.map(item => ({ ...item, IsExternalDomainTrackFlag: true })));
          }

        // //           // Push call history contact data with IsContatCallHistoryFlag if it has data
                  if (result.data?.Callprospecthistorydata.length > 0) {
                    this.state.ContactStatusAllActivity.push(...result.data?.Callprospecthistorydata.map(item => ({ ...item, IsContatCallHistoryFlag: true })));
                    }

                    if (result.data?.EmailContactBlacklist.length > 0) {
                      this.state.ContactStatusAllActivity.push(...result.data?.EmailContactBlacklist.map(item => ({ ...item, IsContatEmailBlacklIstFlag: true })));
                      }

                    // Parse CreatedDate strings into Date objects before sorting
                    this.state.ContactStatusAllActivity.forEach(item => {
                      if (item.CreatedDate) {
                        item.CreatedDate = new Date(item.CreatedDate);
                      }
                    });

                      // Sort ContactStatusAllActivity by CreatedDate
                      this.state.ContactStatusAllActivity.sort((a, b) => a.CreatedDate - b.CreatedDate).reverse();

                            } catch (error) {
                              // Handle errors
                              console.error("Error fetching data:", error);
                              this.setState({ IsLoading: false });
                            }
                          }
                        handleClick = () => {
                          this.setState((prevState) => ({
                            ShowNotePopup: !prevState.ShowNotePopup,
                            Errors: {},
                            TitleAvailableNotes: null,
                            // Add other state updates as needed
                          }));
                        };

  // List edit
  ListsEdit(id) {
    history.push({ pathname: "/editlist", state: { data: id, Pagename: "ViewContact", pid: this.props.location.state.data, EmailData: this.props.location.state.EmailData } });
  }

  // handle dropdown change contact source
  HandleDropdownChangeContactSource(e) {
    this.setState({
      ContactSourceDefaultSelect: e.target.ContactSourceDefaultSelect,
    });
  }

  // handle dropdown change sales devlopment reps change
  HandleDropdownChangeContactSalesDevlopmentReps(e) {
    this.setState({
      ContactSalesDevlopmentRepsDefaultSelect: e.target.ContactSalesDevlopmentRepsDefaultSelect,
    });
  }
  // campaign add 
  CampaignAdd() {
    history.push({ pathname: "/addCampaigns", state: { Pagename: "ViewContact", dataprospect: this.state.ProspectIdForNote }, data: this.props.location.state.data, EmailData: this.props.location.state.EmailData });
  }

  // handle dropdown change contact status
  HandleDropdownChangeContactStatus(e) {
    this.setState({
      ContactStatusDefaultSelect: e.target.ContactStatusDefaultSelect,
    });
  }
  // handle dropdown change contact owner
  HandleDropdownChangeContactOwner(e) {
    this.setState({
      ContactOwnerDefaultSelect: e.target.ContactOwnerDefaultSelect,
    });
  }
  // handle dropdown change client
  HandleDropdownChangeClientPOC(e) {
    this.setState({
      ClientPOCDefaultSelect: e.target.ClientPOCDefaultSelect,
    });
  }
  // handle dropdown change contac reason
  HandleDropdownChangeReasonUnqualified(e) {
    this.setState({
      ReasonDefaultSelect: e.target.ReasonDefaultSelect,
    });
  }

  // handle change email sending
  HandleChangeCheckIsDoNotCall = (e) => {
    const { checked } = e.target;
    this.setState({
      IsDoNotCallContactData: checked,
    });
  };

  // Handle Change
  HandleChange(field, e) {
    let Fields = this.state.Fields;
    Fields[field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Website != "") {
      this.state.Errors.Website = null;
    } else {
      this.state.Errors.Website = null;
    }
  }
  handleChangeFollowUpDate = (index, event) => {
   
    const { ContactData } = this.state;
    const updatedContactData = [...ContactData];
    updatedContactData[index].FollowUpDate = event.target.value;
    this.setState({ ContactData: updatedContactData });
    // updatedContactData[index].FollowUpDate = event.target.value;
    this.setState({ FollowUpdateEdit: event.target.value });
  }

  // handleChangeWebsitecheck() {
  //   var Website = document.getElementById("Website").value.trim();
  //   if (Website != "") {
  //     let errors = {};
  //     this.state.errors.Website = null;
  //     this.CheckWebSite(Website)
  //     errors["Website"] = "Invalid website!";
  //   } else {
  //     // this.state.statusAvailable = null;
  //     this.state.errors.Website = null;
  //   }
  // }

  // change display rows contact status history
  HandleChangePageContactStatusHistory = (Event, NewPage) => {
    var Details = GetUserDetails();
    if (NewPage == this.state.HPage) {
      this.setState({ IsLoading: true });
      this.setState({ HPage: NewPage });
      this.setState({ IsLoading: false });
    } else {
      this.setState({ IsLoading: true });
      this.setState({ HPage: NewPage });
      var SearchedVal = document.getElementById("HSearch").value;
      const id = this.props.location.state.data;
      this.setState({ id: id });
      var ContactStatusHistoryData = {
        Page: NewPage,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        ProspectID: id,
        RowsPerPage: this.state.HRowsPerPage,
        Sort: true,
        Field: this.state.HSortField,
        SortBy: this.state.HSortedBy,
        Search: SearchedVal,
        Type: "User",
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ProspectstatusHistoryGet",
        method: "POST",
        data: ContactStatusHistoryData,
      }).then((reshistory) => {
        this.setState({ HistoryData: reshistory.data?.PageData });
        this.setState({ HRows: reshistory.data?.PageData });
        this.setState({ HRlen: reshistory.data?.TotalCount });
        this.setState({ HFlen: reshistory.data?.TotalCount });
        this.setState({ HCountPage: reshistory.data?.PageCount });
        this.setState({ IsLoading: false });

      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };

  ContactInfoGetData(CID, UID, Role,Email) {
    this.setState({IsLoading:true})
    var str_in = {
      ClientID: CID,
      UserID: UID,
      Role: Role,
      Email:Email
    };

    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactData",
      method: "POST",
      data: str_in,
    });
    rows.then((result) => {
      // // multiple contact sele
      let contactoptions = result.data?.ContactTagData.map((val) => {
        return { value: val.Tag, label: val.Tag, cid: val._id };
      });
      this.setState({
        contactoptions: contactoptions
      })
      this.setState({
        ContactSourceData: result.data?.ContactSourceData || [],
        ClientPOCData: result.data?.ClientPOCData || [],
        ReasonUnqualifiedData: result.data?.ReasonUnqualifiedData || [],
        ContactTagData: result.data?.ContactTagData || [],
        ContactOwnerData: result.data?.ContactOwnerData || [],
        ContactSalesDevlopMenData: result.data?.ContactSalesDevlopMenData || [],
        ContactCustomFieldArray: result.data?.ContactCustomFieldData || [],
        ContactStatusData: result.data?.ProspectStatusData || [],
        SalesDevelopmentRepsData: result.data?.SalesReplyDevlopmentData || [],
        IsLoading:false,
        EmailDataSend: result.data?.EmailData || [],
        AccountEmail: result.data?.EmailData[0]?.SMTPFromEmail || "",
        AccountData: result.data.EmailData[0]?.RefreshToken || "",
        AccountRefreshToken: result.data?.EmailData[0]?.RefreshToken || "",

      });
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  }

  // Step History get
  async StepHistoryGet() {
    try {
      this.setState({ IsLoading: true });
  
      const { ClientID, ParentUserID } = GetUserDetails();
      const { data: id } = this.props.location.state;
  
      const StepHistoryData = {
        Page: this.state.STHPage,
        ProspectID: id,
        ClientID: ClientID,
        UserID: ParentUserID,
        RowsPerPage: this.state.STHRowsPerPage,
        Sort: true,
        Field: this.state.STHSortField,
        SortBy: this.state.STHSortedBy,
        Search: this.state.STHSearch,
        Type: "User",
        Role: this.state.Role,
      };
  
      const result1 = await Axios.post(
        `${CommonConstants.MOL_APIURL}/contacts/StepHistoryGet`,
        StepHistoryData
      );
  
      this.setState({
        STHData: result1.data?.PageData,
        STHRows: result1.data?.PageData,
        STHRlen: result1.data?.TotalCount,
        STHFlen: result1.data?.TotalCount,
        STHCountPage: result1.data?.PageCount,
        IsLoading: false,
      });
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      this.setState({ IsLoading: false });
    }
  }
      // Call Note get
      async CallNoteGet() {
        try {
          this.setState({ IsLoading: true });
          const { data: id } = this.props.location.state;
          const CallNoteDataStr = {
            Page: this.state.CallNotePage,
            RowsPerPage: this.state.CallNoteRowsPerPage,
            Sort: true,
            Field: this.state.CallNoteSortField,
            SortBy: this.state.CallNoteSortedBy,
            Search: this.state.CallNoteSearch,
            ProspectID: id,
            Type: "User",
            Role: this.state.Role,
          };
          const result = await Axios.post(
            `${CommonConstants.MOL_APIURL}/contacts/CallNoteGet`,
            CallNoteDataStr
          );    
      
     
      
          this.setState({
            CallNoteData: result.data?.PageData || [], // Set CallNoteData to an empty array if no data
            CallNoteRows: result.data?.PageData || [],
            CallNoteRlen: result.data?.TotalCount || 0, // Set to 0 if undefined
            CallNoteFlen: result.data?.TotalCount || 0, // Set to 0 if undefined
            CallNoteCountPage: result.data?.PageCount || 0, // Set to 0 if undefined
            IsLoading: false,
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          this.setState({ IsLoading: false });
        }
      }
  // contacts page edit for id by data
  ContactsGetIdByData() {
    this.setState({IsLoading:true})
    const id = this.props.location.state;
    this.setState({ id: id });
    var ContactsData = {
      ProspectID: id,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactGetByID",
      method: "POST",
      data: ContactsData,
    }).then((result) => {
      if (result.data.StatusMessage == "SUCCESS") {
        let defualtoptionsvalue = []
        result.data.Data[0]?.Tagname.map((val) => {
          if (val.TagName == "" || val._id == "") {
            return (0)
          } else {
            defualtoptionsvalue.push({ value: val.TagName, label: val.TagName, cid: val.ContactTagID })
          }

        })
        this.setState({
          defaultTagArray: defualtoptionsvalue || []
        })
        this.setState({
          TagArray: result.data.Data[0]?.Tagname || "",
        });

        this.setState({
          Email: result.data.Data[0]?.Email || "",
        });
        this.setState({
          OldEmail: result.data.Data[0]?.Email || "",
        });
        this.setState({
          ProspectIDData: result.data.Data[0]?._id || "",
        });
        this.setState({
          AccountNameForDisplay: result.data.Data[0]?.Accountname?.SalesReplyAccountName,
        });
        this.setState({
          AccountIdForViewAccount: result.data.Data[0]?.Accountname?.SalesReplyAccountID,
        });
        this.setState({
          FollowUpdateEdit:result.data.Data[0]?.FollowUpDate
        })
        // document.getElementById("AccountName").value = result.data.Data[0]?.Accountname?.SalesReplyAccountName || "";

        document.getElementById("FirstName").value =
          result.data.Data[0]?.FirstName || "";
        document.getElementById("LastName").value =
          result.data.Data[0].LastName || "";
        document.getElementById("Email").value = result.data.Data[0]?.Email || "";
        document.getElementById("Title").value = result.data.Data[0]?.Title || "";
        document.getElementById("CompanyNameLong").value =
          result.data.Data[0]?.CompanyNameLong || "";
        document.getElementById("Company").value = result.data.Data[0]?.Company || "";
        document.getElementById("Website").value = result.data.Data[0]?.Website || "";
        document.getElementById("LinkedInURL").value =
          result.data.Data[0]?.LinkedInURL || "";
        document.getElementById("Industry").value =
          result.data.Data[0]?.Industry || "";
        document.getElementById("Address1").value =
          result.data.Data[0]?.Address1 || "";
        document.getElementById("Address2").value =
          result.data.Data[0]?.Address2 || "";
        document.getElementById("City").value = result.data.Data[0]?.City || "";
        document.getElementById("State").value = result.data.Data[0]?.State || "";
        document.getElementById("Zip").value = result.data.Data[0]?.Zip || "";
        document.getElementById("Country").value = result.data.Data[0]?.Country || "";
        document.getElementById("ContactCategory").value =
          result.data.Data[0]?.ContactCategory || "";
        document.getElementById("AccountCategory").value =
          result.data.Data[0]?.AccountCategory || "";
        document.getElementById("MobilePhone").value =
          result.data.Data[0]?.MobilePhone || "";
        document.getElementById("DirectPhone").value =
          result.data.Data[0]?.DirectPhone || "";
        document.getElementById("DirectPhoneExt").value =
          result.data.Data[0]?.DirectPhoneExt || "";
        document.getElementById("CompanyPhone").value =
          result.data.Data[0]?.CompanyPhone || "";
        document.getElementById("CompanyRevenue").value =
          result.data.Data[0]?.Accountname?.CompanyRevenue == null ? "" : result.data.Data[0]?.Accountname?.CompanyRevenue || "";
        document.getElementById("CompanyEmployeeCount").value =
          result.data.Data[0]?.Accountname?.CompanyEmployeeCount == null ? "" : result.data.Data[0]?.Accountname?.CompanyEmployeeCount || "";
        // document.getElementById("FollowUpDate").value =
        //   result.data.Data[0]?.FollowUpDate || "";
        document.getElementById("SalesRep").value =
          result.data.Data[0]?.SalesRep || "";
        document.getElementById("OtherPhone1").value =
          result.data.Data[0]?.OtherPhone1 || "";
        document.getElementById("OtherPhone2").value =
          result.data.Data[0]?.OtherPhone2 || "";
        document.getElementById("ExternalAccountID").value =
          result.data.Data[0]?.ExternalAccountID || "";
        document.getElementById("ExternalContactID").value =
          result.data.Data[0]?.ExternalContactID || "";
        document.getElementById("ExternalField1").value =
          result.data.Data[0]?.ExternalField1 || "";
        document.getElementById("ExternalField2").value =
          result.data.Data[0]?.ExternalField2 || "";
        document.getElementById("ExternalField3").value =
          result.data.Data[0]?.ExternalField3 || "";
        document.getElementById("IsDoNotCallContact").value =
          result.data.Data[0]?.IsDoNotCallContact || "";
        document.getElementById("ExternalField3").value =
          result.data.Data[0]?.ExternalField3 || "";

          document.getElementById("ZoomID").value =result.data.Data[0]?.ZoomID || "";
          document.getElementById("FollowUpNotes").value =result.data.Data[0]?.FollowUpNotes || "";
          document.getElementById("BestPhone").value =
          result.data.Data[0]?.BestPhone || "";
          document.getElementById("BestPhoneStatus").value =
          result.data.Data[0]?.BestPhoneStatus || "";
          document.getElementById("Phone").value =
          result.data.Data[0]?.Phone1 || "";
          document.getElementById("PhoneStatus").value =
          result.data.Data[0]?.Phone1Status || "";
          document.getElementById("Phone2").value =
          result.data.Data[0]?.Phone2 || "";
          document.getElementById("Phone2Status").value =
          result.data.Data[0]?.Phone2Status || "";
          document.getElementById("Phone3").value =
          result.data.Data[0]?.Phone3 || "";
          document.getElementById("Phone3Status").value =
          result.data.Data[0]?.Phone3Status || "";
          document.getElementById("Phone4").value =
          result.data.Data[0]?.Phone4 || "";
          document.getElementById("Phone4Status").value =
          result.data.Data[0]?.Phone4Status || "";
          document.getElementById("PRLLeadID").value =
          result.data.Data[0]?.PRLLeadID || "";

        this.setState({
          EditContactTagArray: result.data.Data[0]?.Tagname || ""
        })
        this.setState({
          ContactSourceDefaultSelect: result.data.Data[0]?.ContactSourceID || "",
        });
        this.setState({
          ReasonDefaultSelect: result.data.Data[0]?.ReasonUnqualifiedID || "",
        });
        this.setState({
          ClientPOCDefaultSelect: result.data.Data[0]?.ClientPOCID || "",
        });
        this.setState({
          ContactOwnerDefaultSelect: result.data.Data[0]?.ContactOwnerID || "",
        });


        // this.setState({
        //   ContactStatusDefaultSelect: result.data.Data[0]?.ProspectStatusDetails  
        // });
        this.setState({ ContactStatusDefaultSelect: Array.isArray(result.data.Data[0]?.ProspectStatusDetails) ? result.data.Data[0].ProspectStatusDetails[0]?.ProspectStatusID || "" : result.data.Data[0]?.ProspectStatusDetails?.ProspectStatusID || "" });
        this.setState({
          IsDoNotCallContactData: result.data.Data[0]?.IsDoNotCallContact || "",
        });
        this.setState({
          ContactSalesDevlopmentRepsDefaultSelect:result.data.Data[0]?.ColdCallersID || ""
        })

        // custom field get by id
        var CustomFiedIdData = {
          ProspectID: result.data.Data[0]?._id,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ProspectCustomFieldId",
          method: "POST",
          data: CustomFiedIdData,
        }).then((res) => {

          this.setState({
            ProspectCustomFieldByIdArray: res.data?.ProspectCustomFieldById,
          });
          this.setState({IsLoading:false})
        });
      }
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });

    // // custom field list
    // var Details = GetUserDetails();
    // var str_in = {
    //   ClientID: Details.ClientID,
    //   UserID: Details.ChildUserID
    // };

    // const rows = Axios({
    //   url: CommonConstants.MOL_APIURL + "/contacts/ContactData",
    //   method: "POST",
    //   data: str_in,
    // });
    // rows.then((result) => {
    //   this.setState({
    //     ContactCustomFieldArray: result.data?.ContactCustomFieldData,
    //   })
    // }).catch((error) => {
    //   toast.error("An error occurred. Please try again.");
    //     this.setState({ IsLoading: false });
    // });
  }
  // POtential meeting get
  async PotentialMeetingGet() {
    try {
      this.setState({ IsLoading: true });
  
      const { ClientID, ParentUserID } = GetUserDetails();
      const { EmailData } = this.props.location.state;
  
      const PotentialMeetingDataStr = {
        Page: this.state.PMPage,
        RowsPerPage: this.state.PMRowsPerPage,
        Sort: true,
        Field: this.state.MSortField,
        SortBy: this.state.PMSortedBy,
        Search: this.state.PMSearch,
        ClientID: ClientID,
        Email: EmailData,
        Type: "User",
        UserID: ParentUserID,
        Role: this.state.Role,
      };
  
      const result = await Axios.post(
        `${CommonConstants.MOL_APIURL}/contacts/PotentialMeetingGet`,
        PotentialMeetingDataStr
      );
  
      this.setState({
        PotentialMeetingData: result.data?.PageData,
        PMRows: result.data?.PageData,
        PMRlen: result.data?.TotalCount,
        PMFlen: result.data?.TotalCount,
        PMCountPage: result.data?.PageCount,
        PMNotes: result.data?.PotentialMeetingNoteData,
        IsLoading: false,
      });
    } catch (error) {
      // Handle errors
     
      this.setState({ IsLoading: false });
    }
  }
  // meetings edit
  MeetingsEdit(id) {
    history.push({ pathname: "/viewbookedmeeting", state: { data: id, Pagename: "Meeting", pid: this.props.location.state.data, EmailData: this.props.location.state.EmailData } });
  }
  // Meeting get
  async MeetingGet() {
    try {
      this.setState({ IsLoading: true });
  
      const { ClientID, ParentUserID } = GetUserDetails();
      const { EmailData } = this.props.location.state;
  
      const MeetingDataStr = {
        Page: this.state.MPage,
        RowsPerPage: this.state.MRowsPerPage,
        Sort: true,
        Field: this.state.MSortField,
        SortBy: this.state.MSortedBy,
        Search: this.state.MSearch,
        ClientID: ClientID,
        UserID: ParentUserID,
        Email: EmailData,
        Type: "User",
        Role: this.state.Role,
      };
  
      const result = await Axios.post(
        `${CommonConstants.MOL_APIURL}/contacts/MeetingGet`,
        MeetingDataStr
      );
  
      this.setState({
        MeetingData: result.data?.PageData,
        MRows: result.data?.PageData,
        MRlen: result.data?.TotalCount,
        MFlen: result.data?.TotalCount,
        MCountPage: result.data?.PageCount,
        MNotes: result.data?.MeetingNoteData,
        IsLoading: false,
      });
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      this.setState({ IsLoading: false });
    }
  }

  // Call history get
  async CallHistoryGet() {
    try {
      this.setState({ IsLoading: true });
  
      const { ClientID, ParentUserID } = GetUserDetails();
      const { data: id } = this.props.location.state;
  
      const CallHistoryDataStr = {
        Page: this.state.CPage,
        RowsPerPage: this.state.CRowsPerPage,
        Sort: true,
        Field: this.state.CSortField,
        SortBy: this.state.CSortedBy,
        Search: this.state.CSearch,
        ClientID: ClientID,
        UserID: ParentUserID,
        ProspectID: id,
        Type: "User",
        Role: this.state.Role,
      };
  
      const result = await Axios.post(
        `${CommonConstants.MOL_APIURL}/contacts/CallHistoryGet`,
        CallHistoryDataStr
      );
  
      this.setState({
        CallHistoryData: result.data?.PageData,
        CRows: result.data?.PageData,
        CRlen: result.data?.TotalCount,
        CFlen: result.data?.TotalCount,
        CCountPage: result.data?.PageCount,
        IsLoading: false,
      });
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      this.setState({ IsLoading: false });
    }
  }

  // Campaign history get
  async CampaignHistoryGet() {
    try {
      this.setState({ IsLoading: true });
  
      const { ClientID, ParentUserID, Role } = GetUserDetails();
      const { data: id } = this.props.location.state;
  
      const CampaignHistoryDataStr = {
        Page: this.state.CAMPage,
        ProspectID: id,
        RowsPerPage: this.state.CAMRowsPerPage,
        Sort: true,
        Field: this.state.CAMSortField,
        SortBy: this.state.CAMSortedBy,
        Search: this.state.CAMSearch,
        ClientID: ClientID,
        UserID: ParentUserID,
        Type: "User",
        Role: Role,
      };
  
      const result = await Axios.post(
        `${CommonConstants.MOL_APIURL}/contacts/CampaignsHistoryGet`,
        CampaignHistoryDataStr
      );
  
      this.setState({
        CampaignHistoryData: result.data?.PageData,
        CAMRows: result.data?.PageData,
        CAMRlen: result.data?.TotalCount,
        CAMFlen: result.data?.TotalCount,
        CAMCountPage: result.data?.PageCount,
        IsLoading: false,
      });
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      this.setState({ IsLoading: false });
    }
  }
  // campaign history sorting
  CampaignHistorySortData = (Field) => {
    var Details = GetUserDetails();
    const id = this.props.location.state;
    this.setState({ id: id });
    var searchedVal = document.getElementById("searchcampaigns").value;
    if (searchedVal == "") {
      this.setState({ IsLoading: true });
      this.setState({ CAMSflag: false });
      var SortField = Field;
      var SortBy;
      if (this.state.CAMSortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedBy: 1 });
      }
      this.setState({ SortField: Field });
      const id = this.props.location.state.data;
      var CampaignHistoryDataStr = {
        Page: 1,
        ProspectID: id,
        RowsPerPage: this.state.CAMRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: searchedVal,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        Type: "User",
        Role: Details.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CampaignsHistoryGet",
        method: "POST",
        data: CampaignHistoryDataStr,
      }).then((result) => {
        this.setState({ CampaignHistoryData: result.data?.PageData });
        this.setState({ CAMRows: result.data?.PageData });
        this.setState({ CAMRlen: result.data?.TotalCount });
        this.setState({ CAMFlen: result.data?.TotalCount });
        this.setState({ CAMCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    } else {
      this.setState({ IsLoading: true });
      this.setState({ CAMSflag: true });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedBy: 1 });
      }
      this.setState({ SortField: Field });
      const id = this.props.location.state.data;
      var CampaignHistoryDataStr = {
        Page: 1,
        ProspectID: id,
        RowsPerPage: this.state.CAMRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: searchedVal,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        Type: "User",
        Role: Details.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CampaignsHistoryGet",
        method: "POST",
        data: CampaignHistoryDataStr,
      }).then((result) => {
        this.setState({ CampaignHistoryData: result.data?.PageData });
        this.setState({ CAMRows: result.data?.PageData });
        this.setState({ CAMRlen: result.data?.TotalCount });
        this.setState({ CAMFlen: result.data?.TotalCount });
        this.setState({ CAMCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };
  //  campaign history change display rows
  ChangeRowSelectedCampaignHistory = (Event) => {
    this.setState({ IsLoading: true });
    var Details = GetUserDetails();
    this.setState({ CAMRowsPerPage: Number(Event.target.value), CAMPage: 1 });
    var SearchedVal = document.getElementById("searchcampaigns").value;
    const id = this.props.location.state.data;
    var CampaignHistoryDataStr = {
      Page: 1,
      ProspectID: id,
      RowsPerPage: Number(Event.target.value),
      Sort: true,
      Field: this.state.CAMSortField,
      SortBy: this.state.CAMSortedBy,
      Search: SearchedVal,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      Type: "User",
      Role: Details.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/CampaignsHistoryGet",
      method: "POST",
      data: CampaignHistoryDataStr,
    }).then((result) => {
      this.setState({ CampaignHistoryData: result.data?.PageData });
      this.setState({ CAMRows: result.data?.PageData });
      this.setState({ CAMRlen: result.data?.TotalCount });
      this.setState({ CAMFlen: result.data?.TotalCount });
      this.setState({ CAMCountPage: result.data?.PageCount });
      this.setState({ IsLoading: false });
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  };

  //  campaign hitory change page
  HandleChangePageCampaignHistory = (Event, NewPage) => {
    var Details = GetUserDetails();
    this.setState({ CAMPage: NewPage });
    if (NewPage == this.state.CAMPage) {
      this.setState({ IsLoading: true });
      this.setState({ CAMPage: NewPage });
      this.setState({ IsLoading: false });
    } else {
      this.setState({ IsLoading: true });
      const id = this.props.location.state.data;
      this.setState({ CAMPage: NewPage });
      var SearchedVal = document.getElementById("searchcampaigns").value;
      var CampaignHistoryDataStr = {
        Page: NewPage,
        ProspectID: id,
        RowsPerPage: this.state.CAMRowsPerPage,
        Sort: true,
        Field: this.state.CAMSortField,
        SortBy: this.state.CAMSortedBy,
        Search: SearchedVal,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        Type: "User",
        Role: Details.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CampaignsHistoryGet",
        method: "POST",
        data: CampaignHistoryDataStr,
      }).then((result) => {
        this.setState({ CampaignHistoryData: result.data?.PageData });
        this.setState({ CAMRows: result.data?.PageData });
        this.setState({ CAMRlen: result.data?.TotalCount });
        this.setState({ CAMFlen: result.data?.TotalCount });
        this.setState({ CAMCountPage: result.data?.PageCount });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };


  // call history search for record
  RequestSearchCallHistory(event) {
    var Details = GetUserDetails();
    if (event.key === "Enter") {
      this.setState({ IsLoading: true });
      var Searchedval = document.getElementById("searchcallhistory").value;
      const id = this.props.location.state.data;
      this.setState({ id: id });
      var CallHistoryrDataStr = {
        Page: this.state.CPage,
        RowsPerPage: this.state.CRowsPerPage,
        Sort: true,
        Field: this.state.CSortField,
        SortBy: this.state.CSortedBy,
        Search: Searchedval,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        ProspectID: id,
        Type: "User",
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CallHistoryGet",
        method: "POST",
        data: CallHistoryrDataStr,
      }).then((result) => {
        this.setState({ CallHistoryData: result.data?.PageData });
        this.setState({ CRows: result.data?.PageData });
        this.setState({ CRlen: result.data?.TotalCount });
        this.setState({ CFlen: result.data?.TotalCount });
        this.setState({ CCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });

      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  }

  // meeting change page
  HandleChangePageMeeting = (Event, NewPage) => {
    this.setState({ MPage: NewPage });
    if (NewPage == this.state.MPage) {
      this.setState({ IsLoading: true });
      this.setState({ MPage: NewPage });
      this.setState({ IsLoading: false });
    } else {
      this.setState({ IsLoading: true });
      this.setState({ MPage: NewPage });
      var SearchedVal = document.getElementById("searchMeeting").value;
      var Details = GetUserDetails();
      const Email = this.props.location.state.EmailData;
      var MeetingDataStr = {
        Page: NewPage,
        RowsPerPage: this.state.MRowsPerPage,
        Sort: true,
        Field: this.state.MSortField,
        SortBy: this.state.MSortedBy,
        Search: SearchedVal,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        Email: Email,
        Type: "User",
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/MeetingGet",
        method: "POST",
        data: MeetingDataStr,
      }).then((result) => {
        this.setState({ MeetingData: result.data?.PageData });
        this.setState({ MRows: result.data?.PageData });
        this.setState({ MRlen: result.data?.TotalCount });
        this.setState({ MFlen: result.data?.TotalCount });
        this.setState({ MCountPage: result.data?.PageCount });
        this.setState({ MNotes: result.data?.MeetingNoteData });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };

  //  call history  change page
  HandleChangePageCallHistory = (Event, NewPage) => {
    var Details = GetUserDetails();
    if (NewPage == this.state.CPage) {
      this.setState({ IsLoading: true });
      this.setState({ CPage: NewPage });
      this.setState({ IsLoading: false });
    } else {
      this.setState({ IsLoading: true });
      this.setState({ CPage: NewPage });
      var SearchedVal = document.getElementById("searchcallhistory").value;
      const id = this.props.location.state.data;
      this.setState({ id: id });
      var CallHistoryrDataStr = {
        Page: NewPage,
        RowsPerPage: Number(Event.target.value),
        Sort: true,
        Field: this.state.CSortField,
        SortBy: this.state.CSortedBy,
        Search: SearchedVal,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        ProspectID: id,
        Type: "User",
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CallHistoryGet",
        method: "POST",
        data: CallHistoryrDataStr,
      }).then((result) => {
        this.setState({ CallHistoryData: result.data?.PageData });
        this.setState({ CRows: result.data?.PageData });
        this.setState({ CRlen: result.data?.TotalCount });
        this.setState({ CFlen: result.data?.TotalCount });
        this.setState({ CCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };

  //   meeting change display rows
  ChangeRowSelectedCallHistory = (Event) => {
    var Details = GetUserDetails();
    this.setState({ IsLoading: true });
    this.setState({ CRowsPerPage: Number(Event.target.value), CPage: 1 });
    var SearchedVal = document.getElementById("searchcallhistory").value;
    const id = this.props.location.state.data;
    this.setState({ id: id });
    var CallHistoryrDataStr = {
      Page: this.state.CPage,
      RowsPerPage: Number(Event.target.value),
      Sort: true,
      Field: this.state.CSortField,
      SortBy: this.state.CSortedBy,
      Search: SearchedVal,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      ProspectID: id,
      Type: "User",
      Role: this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/CallHistoryGet",
      method: "POST",
      data: CallHistoryrDataStr,
    }).then((result) => {
      this.setState({ CallHistoryData: result.data?.PageData });
      this.setState({ CRows: result.data?.PageData });
      this.setState({ CRlen: result.data?.TotalCount });
      this.setState({ CFlen: result.data?.TotalCount });
      this.setState({ CCountPage: result.data?.PageCount });
      this.setState({ IsLoading: false });
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  };
    // handle change for send email;
    handleChange(field, e) {
      const Errors = { ...this.state.Errors };
      let fields = this.state.fields;
      fields[field] = e.target.value;
      this.setState({ fields });
      if(e.target.value == ""){
        Errors["email_account"] = `Please enter Account`;
        this.setState({SendEmailAccount:null,SendEmailRefreshToken:""});
      }else{
        Errors["email_account"] = null;
        var InputParameter = {
          AccountID: e.target.value
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/AccountDetailsGetById",
          method: "POST",
          data: InputParameter,
        }).then((res) => {
          this.setState({
            AccountData: res.data.AccountDataDetails[0]?.RefreshToken,
            SendEmailRefreshToken:res.data.AccountDataDetails[0]?.RefreshToken,
            SendEmailAccount:res.data.AccountDataDetails[0]?.SMTPFromEmail
    
          })
        }).catch((error) => {
          toast.error("An error occurred. Please try again.");
            this.setState({ IsLoading: false });
        })
      }
      this.setState({ Errors });
    }
      // checking subject and body 
  handleInputChange = (fieldName, value) => {

    const Errors = { ...this.state.Errors };
    if (value.length < 1) {
      Errors[fieldName] = `Please enter ${fieldName}`;
    } else {
      Errors[fieldName] = null;
    }
    this.setState({ Errors });
  }
  // potential meetingchange page
  HandleChangePagePotentialMeeting = (Event, NewPage) => {
    var Details = GetUserDetails();
    if (NewPage == this.state.PMPage) {
      this.setState({ IsLoading: true });
      this.setState({ PMPage: NewPage });
      this.setState({ IsLoading: false });
    } else {
      this.setState({ IsLoading: true });
      this.setState({ PMPage: NewPage });
      var SearchedVal = document.getElementById("searchpotentialmeet").value;
      const Email = this.props.location.state.EmailData;
      var PotentialMeetingDataStr = {
        Page: NewPage,
        RowsPerPage: this.state.PMRowsPerPage,
        Sort: true,
        Field: this.state.PMSortField,
        SortBy: this.state.PMSortedBy,
        Search: SearchedVal,
        ClientID: Details.ClientID,
        Email: Email,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/PotentialMeetingGet",
        method: "POST",
        data: PotentialMeetingDataStr,
      }).then((result) => {
        this.setState({ PotentialMeetingData: result.data?.PageData });
        this.setState({ PMRows: result.data?.PageData });
        this.setState({ PMRlen: result.data?.TotalCount });
        this.setState({ PMFlen: result.data?.TotalCount });
        this.setState({ PMCountPage: result.data?.PageCount });
        this.setState({ PMNotes: result.data?.PotentialMeetingNoteData });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };

  // contact btn delete
  DeleteBtnContact = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a contact.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          _id: id,
          IsDeleted: true,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: this.state.CUserID,
          ClientID:this.state.ClientID,
          UserID:this.state.UserID
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ContactDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Contact  deleted successfully.",
                "success"
              );
              history.push("/contacts");
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  };

  // contact add to blacklist domain
  ContactAddToBlackList = (Email) => {
    this.setState({IsLoading:true})
    var ContactData = {
      ClientID: this.state.ClientID,
      LastUpdatedBy: this.state.CUserID,
      Email: Email,
      UserID: this.state.UserID,
      Role: this.state.Role,
      ProspectID:this.props.location.state.data
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactToBlackList",
      method: "POST",
      data: ContactData,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        toast.success(res.data.Message);
        this.ContactEdit()
        this.CustomFieldValueGet();
        this.NoteGet();
        this.ContactStatusHistoryGet();
        this.ListsGet();
        this.PotentialMeetingGet();
        this.MeetingGet();
        this.CallHistoryGet();
        this.CampaignHistoryGet();
        this.ContactStatusGet();
        this.CallNoteGet();
        this.StepHistoryGet();
        this.ContactActivityGet();
        this.ContactInfoGetData(this.state.ClientID,this.state.UserID,this.state.Role);
        this.setState({IsLoading:false})
      }else if(res.data.StatusMessage === "EXITS"){
        toast.error(res.data.Message);
        this.setState({IsLoading:false})
      } else {
        toast.error(res.data.Message);
        this.setState({IsLoading:false})
      }
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  };
  // custom field data list
  CustomFieldValueGet() {
    const id = this.props.location.state.data;
    this.setState({ id: id });
    // prospect custom field id
    var CustomFiedIdData = {
      ProspectID: id,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ProspectCustomFieldId",
      method: "POST",
      data: CustomFiedIdData,
    }).then((res) => {
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  }
  async FromValidation() {
    
    let formIsValid = true;
    let errors = {};
    var FirstName = document.getElementById("FirstName").value.trim();
    var LastName=document.getElementById("LastName").value.trim()
    var Email = document.getElementById("Email").value.trim();
    var Company = document.getElementById("Company").value.trim();
    var Website = document.getElementById("Website").value.trim();
    let IsExistWebsite = await this.CheckWebSite(Website);
    let IsExistEmail = await this.EmailValidationCheck(Email);

    var IsExists = await this.ContactEmailExits(Email);
    if (FirstName == "") {
      formIsValid = false;
      toast.error("Please enter first name")
    }

    if (Website == "" && FirstName != "") {
      formIsValid = false;
      toast.error("Please enter Website")

    }
    // if (Email == "" && Website.length < 0 && FirstName != "") {
    //   formIsValid = false;
    //   toast.error("Please enter email")

    // }
    if (IsExistWebsite == true) {
      formIsValid = false;
      errors["Website"] = "Invalid website!";
    

    }
    if ((Email === "" && (Company === "" || LastName === "")) || (Company === "" && LastName === "" && Email === "")) {
      formIsValid = false;
      toast.error("Please enter First name, Last name and Company OR First name and Email");
    }
    if (IsExistEmail == true) {
      formIsValid = false;
      toast.error("Invalid email!")
    }
    if (IsExists === "ExistEmail") {
      formIsValid = false;
      toast.error("Email Already Exists"); // Modify the error message
    }

    let MobilePhone = document.getElementById("MobilePhone").value.trim(); 
    // Check if the value is not numeric or is negative
     if (MobilePhone !== "" && !/^[0-9]+$/.test(MobilePhone)) {
     formIsValid=false;
     errors["MobilePhone"] = "Invalid MobilePhone!";
     } else {

     errors["MobilePhone"] = null; // Reset the error if the value is valid or empty
     }

     let OtherPhone1 = document.getElementById("OtherPhone1").value.trim(); 
     // Check if the value is not numeric or is negative
      if (OtherPhone1 !== "" && !/^[0-9]+$/.test(OtherPhone1)) {
      formIsValid=false;
      errors["OtherPhone1"] = "Invalid OtherPhone1!";
      } else {
      errors["OtherPhone1"] = null; // Reset the error if the value is valid or empty
      }

      let OtherPhone2 = document.getElementById("OtherPhone2").value.trim(); 
      // Check if the value is not numeric or is negative
       if (OtherPhone2 !== "" && !/^[0-9]+$/.test(OtherPhone2)) {
       formIsValid=false;
       errors["OtherPhone2"] = "Invalid OtherPhone2!";
       } else {
       errors["OtherPhone2"] = null; // Reset the error if the value is valid or empty
       }

       let BestPhone = document.getElementById("BestPhone").value.trim(); 
       // Check if the value is not numeric or is negative
        if (BestPhone !== "" && !/^[0-9]+$/.test(BestPhone)) {
        formIsValid=false;
        errors["BestPhone"] = "Invalid BestPhone!";
        }


        let Phone = document.getElementById("Phone").value.trim(); 
        // Check if the value is not numeric or is negative
         if (Phone !== "" && !/^[0-9]+$/.test(Phone)) {
         formIsValid=false;
         errors["Phone"] = "Invalid Phone!";
         } else {
         errors["Phone"] = null; // Reset the error if the value is valid or empty
         }

         let Phone2 = document.getElementById("Phone2").value.trim(); 
         // Check if the value is not numeric or is negative
          if (Phone2 !== "" && !/^[0-9]+$/.test(Phone2)) {
          formIsValid=false;
          errors["Phone2"] = "Invalid Phone2!";
          } else {

          errors["Phone2"] = null; // Reset the error if the value is valid or empty
          }

          let Phone3 = document.getElementById("Phone3").value.trim(); 
          // Check if the value is not numeric or is negative
           if (Phone3 !== "" && !/^[0-9]+$/.test(Phone3)) {
           formIsValid=false;
           errors["Phone3"] = "Invalid Phone3!";
           } else {

           errors["Phone3"] = null; // Reset the error if the value is valid or empty
           }

           let Phone4 = document.getElementById("Phone4").value.trim(); 
           // Check if the value is not numeric or is negative
            if (Phone4 !== "" && !/^[0-9]+$/.test(Phone4)) {
            formIsValid=false;
            errors["Phone4"] = "Invalid Phone4!";
            } else {

            errors["Phone4"] = null; // Reset the error if the value is valid or empty
            }

            let DirectPhoneExt = document.getElementById("DirectPhoneExt").value.trim(); 
            // Check if the value is not numeric or is negative
             if (DirectPhoneExt !== "" && !/^[0-9]+$/.test(DirectPhoneExt)) {
             formIsValid=false;
             errors["DirectPhoneExt"] = "Invalid DirectPhoneExt!";

             } else {  

             errors["DirectPhoneExt"] = null; // Reset the error if the value is valid or empty
             }

             let CompanyPhone = document.getElementById("CompanyPhone").value.trim(); 
             // Check if the value is not numeric or is negative
              if (CompanyPhone !== "" && !/^[0-9]+$/.test(CompanyPhone)) {
              formIsValid=false;
              errors["CompanyPhone"] = "Invalid CompanyPhone!";
              } else {

              errors["CompanyPhone"] = null; // Reset the error if the value is valid or empty
              }

              let DirectPhone = document.getElementById("DirectPhone").value.trim(); 
              // Check if the value is not numeric or is negative
               if (DirectPhone !== "" && !/^[0-9]+$/.test(DirectPhone)) {
               formIsValid=false;
               errors["DirectPhone"] = "Invalid DirectPhone!";
      
               } else {

               errors["DirectPhone"] = null; // Reset the error if the value is valid or empty
               }

               let ZoomID = document.getElementById("ZoomID").value.trim(); 
     // Check if the value is negative
     if (ZoomID !== "" && parseInt(ZoomID) < 0) {
       formIsValid=false;
       errors["ZoomID"] = "Invalid ZoomID!";
     } else {

       errors["ZoomID"] = null; // Reset the error if the value is valid or empty

     }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChangeWebsitecheck() {
    var Website = document.getElementById("Website").value.trim();
    if (Website != "") {
      let errors = {};
      this.state.errors.Website = null;
      this.CheckWebSite(Website)
      errors["Website"] = "Invalid website!";
    } else {
      this.state.errors.Website = null;
    }
  }

  handleChangePhoneValidation(fieldName) {
    const value = document.getElementById(fieldName).value.trim();
    let errors = { ...this.state.errors }; // Copy the current state of errors
 // Check if the value is not numeric or is negative
 if (value !== "" && !/^[0-9]+$/.test(value)) {
  errors[fieldName] = "Invalid " + fieldName + "!";
} else {
  errors[fieldName] = null; // Reset the error if the value is valid or empty
}

  // Update the state with the new errors
    this.setState({ errors });
}

hadleChangeZoomID(fieldName){
  const value = document.getElementById(fieldName).value.trim();
  let errors = { ...this.state.errors }; // Copy the current state of errors

  // Check if the value is negative
  if (value !== "" && parseInt(value) < 0) {
    errors[fieldName] = "Invalid " + fieldName + "!";
  } else {
    errors[fieldName] = null; // Reset the error if the value is valid or empty

  }
  this.setState({ errors });
}
  // check exists by email
  async  ContactEmailExits(Email) {
    if(Email !== ""){
      var ContactExitsData = {
        ClientID: this.state.ClientID,
        Email: Email
      };
      var resdata = await    Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CheckExitsContacts",
        method: "POST",
        data: ContactExitsData,
      })
        if (resdata.data.StatusMessage == "SUCCESS") {
          if (resdata.data.Data.length > 0) {
            if(this.state.OldEmail == Email){

            }else{
              this.setState({ EmailAvailable: resdata.data.Data[0].Email });
              return "ExistEmail";
            }
  
          }
          else{
            return "AllDone";
          }
        }
    }else{
      return "BlankData";
    }


  }
  // check website name
  async CheckWebSite(Email) {
    let Errors = {};
    var regex = new RegExp(
      "^(?!(https?://)?www\\.)" + // Ensure it does not start with https://www.
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    if (!regex.test(Email) && Email != "") {
      Errors["Website"] = "Invalid website";
      this.setState({ errors: Errors });
      this.setState({ IsInValidWebsite: true });
      return true
    } else {
      this.setState({ WebsiteNotNull: true });
      return false;
    }
  }
  ChangeRowSelectedCallNote = (event) => {
    var Details = GetUserDetails();
    this.setState({ CallNoteRowsPerPage: Number(event.target.value), CallNotePage: 1 });
    this.setState({ IsLoading: true });
    let SearchCallNote = document.getElementById("SearchCallNote").value;
    const Email = this.props.location.state.EmailData;
    const id = this.props.location.state.data;
    var ProspectNoteData = {
      Page: 1,
      ClientID: Details.ClientID,
      ProspectID: id,
      Email: Email,
      RowsPerPage: Number(event.target.value),
      Sort: true,
      Field: this.state.NoteSortField,
      SortBy: this.state.NoteSortedBy,
      Search: SearchCallNote,
      Type: "User",
      UserID: Details.ParentUserID,
      Role: this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/CallNoteGet",
      method: "POST",
      data: ProspectNoteData,
    }).then((responsenote) => {
      this.setState({
        CallNoteData: responsenote.data?.PageData,
        CallNoteRows: responsenote.data?.PageData,
        CallNoteRlen: responsenote.data?.TotalCount,
        CallNoteFlen: responsenote.data?.TotalCount,
        CallNoteCountPage: responsenote.data?.PageCount,
        IsLoading: false,
      });
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    });
  };
  RequestSeachCallNoteList(Event) {
    var Details = GetUserDetails();
    if (Event.key === "Enter") {
      this.setState({ IsLoading: true });
      var SearchedVal = document.getElementById("SearchCallNote").value;
      this.setState({ SearchL: SearchedVal });
      const Email = this.props.location.state.EmailData;
    const id = this.props.location.state.data;
      var CallNoteData = {
        Page: 1,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        ProspectID: id,
        Email: Email,
        RowsPerPage: this.state.CallNoteRowsPerPage,
        Sort: true,
        Field: this.state.CallNoteSortField,
        SortBy: this.state.CallNoteSortedBy,
        Search: SearchedVal,
        Type: "User",
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CallNoteGet",
        method: "POST",
        data: CallNoteData,
      }).then((result1) => {

        this.setState({ CallNoteData: result1.data?.PageData });
        this.setState({ CallNoteRows: result1.data?.PageData });
        this.setState({ CallNoteRlen: result1.data?.TotalCount });
        this.setState({ CallNoteFlen: result1.data?.TotalCount });
        this.setState({ CallNoteCountPage: result1.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  }
  // cheack email valid
  async EmailValidationCheck(Email) {
    let regexp =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let Errors = {};
    if (!regexp.test(Email) && Email != "") {
      Errors["Email"] = "invalid email.";
      this.setState({ IsValidEmail: true });
      this.setState({ errors: Errors });
      return true;
    } else {
      this.setState({ IsValidEmail: false });

      return false;
    }
  };

  // update data
  async ContactUpdate() {

    this.setState({ BtnDisabled: true })
    var FormValidationFlag = await this.FromValidation();
    if (FormValidationFlag === true) {
      this.setState({ IsLoading: true });
      var FinalTagArray = []
      this.state?.defaultTagArray?.map((item) => {
        FinalTagArray.push({
          ContactTagID: item.cid,
          TagName: item.label
        })
      })
      var FirstName = document.getElementById("FirstName").value;
      var LastName = document.getElementById("LastName").value;
      var Email = document.getElementById("Email").value;
      var Title = document.getElementById("Title").value;
      var CompanyNameLong = document.getElementById("CompanyNameLong").value;
      var Company = document.getElementById("Company").value;
      var Website = document.getElementById("Website").value;
      var LinkedInURL = document.getElementById("LinkedInURL").value;
      var Industry = document.getElementById("Industry").value;
      var Address1 = document.getElementById("Address1").value;
      var Address2 = document.getElementById("Address2").value;
      var City = document.getElementById("City").value;
      var State = document.getElementById("State").value;
      var Zip = document.getElementById("Zip").value;
      var Country = document.getElementById("Country").value;
      var ContactCategory = document.getElementById("ContactCategory").value;
      var AccountCategory = document.getElementById("AccountCategory").value;
      var MobilePhone = document.getElementById("MobilePhone").value;
      var DirectPhone = document.getElementById("DirectPhone").value;
      var DirectPhoneExt = document.getElementById("DirectPhoneExt").value;
      var CompanyPhone = document.getElementById("CompanyPhone").value;
      var CompanyRevenue = document.getElementById("CompanyRevenue").value.trim();
      var EmployeeCount = document.getElementById("CompanyEmployeeCount").value.trim();

 

      var SalesRep = document.getElementById("SalesRep").value;
      var OtherPhone1 = document.getElementById("OtherPhone1").value;
      var OtherPhone2 = document.getElementById("OtherPhone2").value;
      var ExternalAccountID = document.getElementById("ExternalAccountID").value;
      var ExternalContactID = document.getElementById("ExternalContactID").value;
      var ExternalField1 = document.getElementById("ExternalField1").value;
      var ExternalField2 = document.getElementById("ExternalField2").value;
      var ExternalField3 = document.getElementById("ExternalField3").value;
      var ContactSourceID = document.getElementById("ContactSourceID").value;
      var ReasonUnqualifiedID = document.getElementById(
        "ReasonUnqualifiedID"
      ).value;
      var ClientPOCID = document.getElementById("ClientPOCID").value;
      var ContactOwnerID = document.getElementById("ContactOwnerID").value;
      var ContactStatusID = document.getElementById("ContactStatusID").value;
      var ColdCallersID = document.getElementById("ColdCallersID").value;
      var ZoomID = document.getElementById("ZoomID").value;
      var BestPhone = document.getElementById("BestPhone").value;
      var BestPhoneStatus = document.getElementById("BestPhoneStatus").value;
      var Phone = document.getElementById("Phone").value;
      var PhoneStatus = document.getElementById("PhoneStatus").value;
      var Phone2 = document.getElementById("Phone2").value;
      var Phone2Status = document.getElementById("Phone2Status").value;
      var Phone3 = document.getElementById("Phone3").value;
      var Phone3Status = document.getElementById("Phone3Status").value;
      var Phone4 = document.getElementById("Phone4").value;
      var Phone4Status = document.getElementById("Phone4Status").value;
      var PRLLeadID = document.getElementById("PRLLeadID").value;
      var FollowUpNotes = document.getElementById("FollowUpNotes").value;
      var CompanyEmployeeCountDecimal = document.getElementById("CompanyEmployeeCount").value;
           // Check if the trimmed value is not empty and is a valid number
           if (EmployeeCount !== "" && !isNaN(EmployeeCount)) {
            // If the value is a decimal, assign 0
          EmployeeCount = EmployeeCount?.includes(".") ? 0 : parseFloat(EmployeeCount);
      } else {
            // If the value is empty or not a valid number, assign 0
         EmployeeCount = 0;
      }
   
    
      let revenueParts = CompanyRevenue?.split("."); // Split the string into integer and decimal parts
      
      if (revenueParts?.length === 2) { // Ensure there's a decimal part
          let decimalPart = revenueParts[1]; // Get the decimal part
          if (decimalPart?.length >= 3) { // Ensure there are at least three digits after the decimal point
              let thirdDigit = parseInt(decimalPart[2]); // Get the third digit after the decimal point
              let secondDigit = parseInt(decimalPart[1]); // Get the second digit after the decimal point
      
              if (thirdDigit >= 5) { // If the third digit is greater than or equal to 5
                  secondDigit += 1; // Increment the second digit
              }
      
              // Reconstruct the revenue string with the adjusted second digit
              CompanyRevenue = revenueParts[0] + "." + decimalPart[0] + secondDigit;
          }
      }else{
        CompanyRevenue = CompanyRevenue
      }
      var  trimEmail;
      if(Email == "" || undefined){
        trimEmail = Email
      }else{
        trimEmail = Email.trim()
      }
      var ProspectStatusDetails = [];
      this.state.ContactStatusData.forEach((item) => {
        if (item._id === ContactStatusID) {
          ProspectStatusDetails.push({
            ProspectStatusID: item?._id,
            Status: item.Status
          })
        }
      })


      var IsDoNotCallContact =
        document.getElementById("IsDoNotCallContact").checked;

      var CustomFieldValueUpdateArray = [];
      this.state.ContactCustomFieldArray.map((valuecustfield) => {

        var fcustomfield = this.state.ProspectCustomFieldByIdArray?.find(
          (pcustf) => pcustf?.CustomFieldID === valuecustfield?._id
        )

        var prospectcustomfieldid = fcustomfield?._id
        var UpdateValue = document.getElementById(valuecustfield?._id)?.value || "";
        var UpdateCustomFieldId = valuecustfield?._id;
        var userid = this.state.UserID;
        if (UpdateValue.length !== 0) {
          CustomFieldValueUpdateArray.push({
            UpdateValue,
            UpdateCustomFieldId,
            userid,
            prospectcustomfieldid
          });
        } else {
          // Add a default value for blank input fields
          CustomFieldValueUpdateArray.push({
            UpdateValue: "",
            UpdateCustomFieldId,
            userid,
            prospectcustomfieldid
          });
        }
      });
      var ContactUpdateData = {
        _id: this.state.id,
        FirstName: FirstName,
        LastName: LastName,
        Email: trimEmail,
        Title: Title,
        CompanyNameLong: CompanyNameLong,
        Company: Company,
        Website: Website,
        LinkedInURL: LinkedInURL,
        Industry: Industry,
        Address1: Address1,
        Address2: Address2,
        City: City,
        State: State,
        Zip: Zip,
        Country: Country,
        ContactCategory: ContactCategory,
        AccountCategory: AccountCategory,
        MobilePhone: MobilePhone,
        DirectPhone: DirectPhone,
        DirectPhoneExt: DirectPhoneExt,
        CompanyPhone: CompanyPhone,
        CompanyRevenue: CompanyRevenue,
        FollowUpDate: moment(this.state.FollowUpdateEdit).format("MM-DD-YYYY"),
        SalesRep: SalesRep,
        OtherPhone1: OtherPhone1,
        OtherPhone2: OtherPhone2,
        ExternalAccountID: ExternalAccountID,
        ExternalContactID: ExternalContactID,
        ExternalField1: ExternalField1,
        ExternalField2: ExternalField2,
        ExternalField3: ExternalField3,
        ContactSourceID: ContactSourceID,
        ReasonUnqualifiedID: ReasonUnqualifiedID,
        ClientPOCID: ClientPOCID,
        ContactOwnerID: ContactOwnerID === "--Select--" ? null : ContactOwnerID,
        Tagname: FinalTagArray,
        ContactStatusID: ContactStatusID === "--Select--" ? null : ContactStatusID,
        IsDoNotCallContact: IsDoNotCallContact,
        LastUpdatedBy: this.state.CUserID,
        LastUpdatedDate: new Date(),
        ClientID: this.state.ClientID,
        ContactCustomUpdateArray: CustomFieldValueUpdateArray,
        Role: this.state.Role,
        ProspectStatusDetails: ProspectStatusDetails,
        ColdCallersID:ColdCallersID == "" ? null : ColdCallersID,
        UserID:this.state.UserID,
        EmployeeCount:EmployeeCount,
        ZoomID :ZoomID,
        BestPhone :BestPhone,
        BestPhoneStatus :BestPhoneStatus,
        Phone :Phone,
        PhoneStatus :PhoneStatus,
        Phone2 :Phone2,
        Phone2Status :Phone2Status,
        Phone3 :Phone3,
        Phone3Status :Phone3Status,
        Phone4 :Phone4,
        Phone4Status :Phone4Status,
        PRLLeadID :PRLLeadID,
        FollowUpNotes:FollowUpNotes,
        CompanyEmployeeCountDecimal:CompanyEmployeeCountDecimal
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ContactUpdate",
        method: "POST",
        data: ContactUpdateData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          const id = this.props.location.state.data;
          const Email = this.props.location.state.EmailData;

          toast.success("Contact updated successfully.", "Update  contact ");

          if (this.props.location.state["Pagename"] == "AccountContact") {
            history.push({
              pathname: "/editaccount",
              state: { data: this.props.location.state.AccountData },
            });
          } else if (this.props.location.state.PageName == "Potential") {
            history.push("/potentialmeeting");

          }  else if (this.props.location.state.PageName == "BookedMeeting") {
            history.push("/bookedmeetings");

          }else if (this.props.location.state.Pagename === "kpiMeeting") {
            history.push({
              pathname: "/kpis",
              state: {
                data: 2,
              },
            });
          }else if (this.props.location.state.Pagename === "Kpi7DaysPotential") {
            history.push({
              pathname: "/kpis",
              state: {
                data: 1,
              },
            });
          }else if (this.props.location.state.Pagename === "kpiEmailResponder") {
            history.push({
              pathname: "/kpis",
              state: {
                data: 5,
              },
            });
          } else if (this.props.location.state.Pagename === "kpiMeeting7days") {
            history.push({
              pathname: "/kpis",
              state: {
                data: 1,
              },
            });
          }else if (this.props.location.state.PageName == "OpportunitiesMeeting") {
            history.push("/opportunities");
          }else if (this.props.location.state.Pagename == "clientPotenial") {
            history.push("/clientpotentialmeetings");
          }else if (this.props.location.state.Pagename == "ClientRescheduleMeeting") {
            history.push("/clientrescheduling");
          } else if (this.props.location.state.Pagename == "ClientBookedMeeting") {
            history.push("/clientmeetings");
          }else if (this.props.location.state.PageName == "RescheduleMeeting") {
            history.push("/reschedules");
          } else if (this.props.location.state.Pagename == "ClientLinkedinFollow") {
            history.push("/clientlinkedinfollowUp");
          }else if (this.props.location.state.Pagename == "ColdCallingContacts") {
            history.push({ pathname: "/viewcontacts", state: { data: this.props.location.state.data, Pagename: "ColdCallingContacts", EmailData: this.props.location.state.EmailData } })
          } else if (this.props.location.state.Pagename == "Kpi7Days") {
            history.push("/kpis");
          } else {
            history.push({
              pathname: "/viewcontacts",
              state: { data: id, EmailData: Email },
            });
          }
          this.setState({ IsLoading: false });
          this.setState({ BtnDisabled: false })
        }else if (res.data.StatusMessage == "EXITS") {
          toast.error(
            <div>
            Contacts.<br/>
            Email already exits
            </div>
          );
          this.setState({ IsLoading: false});
          this.setState({ IsBtnDisabled: false });
        } else {
          toast.error(res.data.Message);
          this.setState({ IsLoading: false });
          this.setState({ BtnDisabled: false })
        }
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
          this.setState({ BtnDisabled: true })
      });
    }
    else {
      this.setState({ IsLoading: false });
      this.setState({ BtnDisabled: false })
    }
  }

  ViewBtn(id, emailid) {
    history.push({
      pathname: "/viewcontacts",
      state: { data: id, EmailData: emailid },
    });
  };

  // Lists get all
  async ListsGet() {
    try {
      this.setState({ IsLoading: true });
  
      const { ClientID, ParentUserID } = GetUserDetails();
      const { data: id } = this.props.location.state;
  
      const ListData = {
        Page: this.state.PageL,
        ProspectID: id,
        ClientID: ClientID,
        UserID: ParentUserID,
        RowsPerPage: this.state.RowsPerPageL,
        Sort: true,
        Field: this.state.SortFieldL,
        SortBy: this.state.SortedByL,
        Search: this.state.SearchL,
        Type: "User",
        Role: this.state.Role,
      };
  
      const result1 = await Axios.post(
        `${CommonConstants.MOL_APIURL}/contacts/ListsGet`,
        ListData
      );
  
      this.setState({
        ListData: result1.data?.PageData,
        ListRows: result1.data?.PageData,
        LRlen: result1.data?.TotalCount,
        LFlen: result1.data?.TotalCount,
        LCountPage: result1.data?.PageCount,
        IsLoading: false,
      });
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      this.setState({ IsLoading: false });
    }
  }

  // search for record
  RequestSearchList(Event) {
    var Details = GetUserDetails();
    if (Event.key === "Enter") {
      this.setState({ IsLoading: true });
      var SearchedVal = document.getElementById("searchlist").value;
      this.setState({ SearchL: SearchedVal });
      const { data: id } = this.props.location.state;
      var ListData = {
        Page: 1,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        RowsPerPage: this.state.RowsPerPageL,
        Sort: true,
        Field: this.state.SortFieldL,
        SortBy: this.state.SortedByL,
        Search: SearchedVal,
        Type: "User",
        Role: this.state.Role,
        ProspectID: id,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ListsGet",
        method: "POST",
        data: ListData,
      }).then((result1) => {
        this.setState({ ListData: result1.data?.PageData });
        this.setState({ ListRows: result1.data?.PageData });
        this.setState({ LRlen: result1.data?.TotalCount });
        this.setState({ LFlen: result1.data?.TotalCount });
        this.setState({ LCountPage: result1.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  }

  // change display rows
  ChangeRowSelectedList(Event) {
    var Details = GetUserDetails();
    this.setState({ IsLoading: true });
    this.setState({ RowsPerPageL: Number(Event.target.value), PageL: 1 });
    var SearchedVal = document.getElementById("searchlist").value;
    const { data: id } = this.props.location.state;
    
    var ListData = {
      Page: 1,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      RowsPerPage: Number(Event.target.value),
      Sort: true,
      Field: this.state.SortFieldL,
      SortBy: this.state.SortedByL,
      Search: SearchedVal,
      Type: "User",
      Role: this.state.Role,
      ProspectID: id,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ListsGet",
      method: "POST",
      data: ListData,
    }).then((result1) => {
      this.setState({ ListData: result1.data?.PageData });
      this.setState({ ListRows: result1.data?.PageData });
      this.setState({ LRlen: result1.data?.TotalCount });
      this.setState({ LFlen: result1.data?.TotalCount });
      this.setState({ LCountPage: result1.data?.PageCount });
      this.setState({ IsLoading: false });
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  }

  // change page
  HandleChangePageList(Event, NewPage) {
    var Details = GetUserDetails();
    const { data: id } = this.props.location.state;
    if (this && this.state && NewPage == this.state.PageL) {
      this.setState({ IsLoading: true });
      this.setState({ PageL: NewPage });
      this.setState({ IsLoading: false });
    } else {
      this.setState({ IsLoading: true });
      this.setState({ PageL: NewPage });
      var SearchedVal = document.getElementById("searchlist").value;
 

      var ListData = {
        Page: NewPage,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        RowsPerPage: this.state?.RowsPerPageL,
        Sort: true,
        Field: this.state?.SortFieldL,
        SortBy: this.state?.SortedByL,
        Search: SearchedVal,
        Type: "User",
        Role: this.state.Role,
        ProspectID: id,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ListsGet",
        method: "POST",
        data: ListData,
      }).then((result1) => {
        this.setState({ ListData: result1.data?.PageData });
        this.setState({ ListRows: result1.data?.PageData });
        this.setState({ LRlen: result1.data?.TotalCount });
        this.setState({ LFlen: result1.data?.TotalCount });
        this.setState({ LCountPage: result1.data?.PageCount });
        this.setState({ IsLoading: false });

      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  }


  // call note contact change display rows
  ChangeRowSelected = (Event) => {
    var Details = GetUserDetails();
    this.setState({ NoteRowsPerPage: Number(Event.target.value), NotePage: 1 });
    this.setState({ IsLoading: true });
    let SearchedNote = document.getElementById("SearchNote").value;
    const Email = this.props.location.state.EmailData;
    const id = this.props.location.state.data;
    this.setState({ id: id });
    var ProspectNoteData = {
      Page: 1,
      ClientID: Details.ClientID,
      ProspectID: id,
      Email: Email,
      RowsPerPage: Number(Event.target.value),
      Sort: true,
      Field: this.state.NoteSortField,
      SortBy: this.state.NoteSortedBy,
      Search: SearchedNote,
      Type: "User",
      UserID: Details.ParentUserID,
      Role: this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/NoteGet",
      method: "POST",
      data: ProspectNoteData,
    }).then((responsenote) => {
      this.setState({ NoteData: responsenote.data?.PageData });
      this.setState({ NoteRows: responsenote.data?.PageData });
      this.setState({ NoteRlen: responsenote.data?.TotalCount });
      this.setState({ NoteFlen: responsenote.data?.TotalCount });
      this.setState({ NoteCountPage: responsenote.data?.PageCount });
      this.setState({ IsLoading: false });
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  };
  // search for record contact nFVote
  RequestSearch(event) {
    if (event.key === "Enter") {
      this.setState({ IsLoading: true });
      let SearchedNote = document.getElementById("SearchNote").value;
      var Details = GetUserDetails();
      const Email = this.props.location.state.EmailData;
      const id = this.props.location.state.data;
      this.setState({ id: id });
      var ProspectNoteData = {
        Page: this.state.NotePage,
        ClientID: Details.ClientID,
        ProspectID: id,
        Email: Email,
        RowsPerPage: this.state.NoteRowsPerPage,
        Sort: true,
        Field: this.state.NoteSortField,
        SortBy: this.state.NoteSortedBy,
        Search: SearchedNote,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/NoteGet",
        method: "POST",
        data: ProspectNoteData,
      }).then((responsenote) => {
        this.setState({ NoteData: responsenote.data?.PageData });
        this.setState({ NoteRows: responsenote.data?.PageData });
        this.setState({ NoteRlen: responsenote.data?.TotalCount });
        this.setState({ NoteFlen: responsenote.data?.TotalCount });
        this.setState({ NoteCountPage: responsenote.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });

    }
  }


  // Change per row wise contact status history
  ChangeRowSelectedContactStatusHistory(Event) {
    var Details = GetUserDetails();
    this.setState({ IsLoading: true });
    this.setState({ HRowsPerPage: Number(Event.target.value), HPage: 1 });
    var SearchedVal = document.getElementById("HSearch").value;
    const id = this.props.location.state.data;
    this.setState({ id: id });
    var ContactStatusHistoryData = {
      Page: 1,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      ProspectID: id,
      RowsPerPage: Number(Event.target.value),
      Sort: true,
      Field: this.state.HSortField,
      SortBy: this.state.HSortedBy,
      Search: SearchedVal,
      Type: "User",
      Role: this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ProspectstatusHistoryGet",
      method: "POST",
      data: ContactStatusHistoryData,
    }).then((reshistory) => {
      this.setState({ HistoryData: reshistory.data?.PageData });
      this.setState({ HRows: reshistory.data?.PageData });
      this.setState({ HRlen: reshistory.data?.TotalCount });
      this.setState({ HFlen: reshistory.data?.TotalCount });
      this.setState({ HCountPage: reshistory.data?.PageCount });
      this.setState({ IsLoading: false });
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  }

  // Search for contact status history 
  RequestSearchContactHistory(event) {
    var Details = GetUserDetails();
    if (event.key === "Enter") {
      this.setState({ IsLoading: true });
      let SearchStatusHistory = document.getElementById("HSearch").value;
      const id = this.props.location.state.data;
      this.setState({ id: id });
      var ContactStatusHistoryData = {
        Page: this.state.HPage,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        ProspectID: id,
        RowsPerPage: this.state.HRowsPerPage,
        Sort: true,
        Field: this.state.HSortField,
        SortBy: this.state.HSortedBy,
        Search: SearchStatusHistory,
        Type: "User",
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ProspectstatusHistoryGet",
        method: "POST",
        data: ContactStatusHistoryData,
      }).then((reshistory) => {
        this.setState({ HistoryData: reshistory.data?.PageData });
        this.setState({ HRows: reshistory.data?.PageData });
        this.setState({ HRlen: reshistory.data?.TotalCount });
        this.setState({ HFlen: reshistory.data?.TotalCount });
        this.setState({ HCountPage: reshistory.data?.PageCount });
        this.setState({ IsLoading: false });

      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  }

  // contact status history get
  async ContactStatusHistoryGet() {
    try {
      this.setState({ IsLoading: true });
  
      const { ClientID, ParentUserID } = GetUserDetails();
      const { data: id } = this.props.location.state;
  
      this.setState({ id: id });
  
      const ContactStatusHistoryData = {
        Page: this.state.HPage,
        ClientID: ClientID,
        UserID: ParentUserID,
        ProspectID: id,
        RowsPerPage: this.state.HRowsPerPage,
        Sort: true,
        Field: this.state.HSortField,
        SortBy: this.state.HSortedBy,
        Search: this.state.HSearch,
        Type: "User",
        Role: this.state.Role,
      };
  
      const reshistory = await Axios.post(
        `${CommonConstants.MOL_APIURL}/contacts/ProspectstatusHistoryGet`,
        ContactStatusHistoryData
      );
  
      this.setState({
        HistoryData: reshistory.data?.PageData,
        HRows: reshistory.data?.PageData,
        HRlen: reshistory.data?.TotalCount,
        HFlen: reshistory.data?.TotalCount,
        HCountPage: reshistory.data?.PageCount,
        IsLoading: false,
        BlacklistStatusData:reshistory.data?.BlacklistStatusProsepctData[0],
        LastStatusChangeDate:reshistory.data?.PageData[0].ChangedDate ?  new Date(reshistory.data?.PageData[0].ChangedDate).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          // hour: 'numeric',
          // minute: 'numeric',
          // hour12: true,
          timeZone:'utc'
        }) : '',
        BlacklistedDate:reshistory.data?.BlacklistStatusProsepctData[0]?.CreatedDate ?  new Date(reshistory.data?.BlacklistStatusProsepctData[0]?.CreatedDate).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          // hour: 'numeric',
          // minute: 'numeric',
          // hour12: true
        }) : ''
      });
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      this.setState({ IsLoading: false });
    }
  }

  // List edit
  ListsAdd() {
    const cmid = this.props.location.state.data;
    history.push({ pathname: "/addlist", state: {  pid: this.props.location.state.data, EmailData: this.props.location.state.EmailData, Pagename: "ViewContact" } });
  }


  // potential meetings edit
  PotentialMeetingsEdit(id) {
    history.push({ pathname: "/viewpotentialmeeting", state: { data: id, pid: this.props.location.state.data, EmailData: this.props.location.state.EmailData, Pagename: "Contact" } });
  }

  // Back button contacts
  ContactBackBtn() {
    if (this.props.location.state["Pagename"] == "AccountContact") {
      history.push({
        pathname: "/editaccount",
        state: { data: this.props.location.state.AccountData },
      });
    } else if (this.props.location.state.Pagename == "Potential") {
      history.push("/potentialmeeting");
    } else if (this.props.location.state.Pagename == "PotentialLinkedin") {
      history.push("/linkedinfollowup");
    } else if (this.props.location.state.Pagename == "ColdCallingContacts") {
      history.push("/ColdCallingContact");
    } else if (this.props.location.state.Pagename == "Kpi7Days") {
      history.push("/kpis");
    } else if (this.props.location.state.Pagename == "BookedMeeting") {
      history.push("/bookedmeetings");
    }else if (this.props.location.state.Pagename === "kpiMeeting") {
      history.push({
        pathname: "/kpis",
        state: {
          data: 2,
        },
      });
    }
    else if (this.props.location.state.Pagename === "Kpi7DaysPotential") {
      history.push({
        pathname: "/kpis",
        state: {
          data: 1,
        },
      });
    }else if (this.props.location.state.Pagename === "kpiEmailResponder") {
      history.push({
        pathname: "/kpis",
        state: {
          data: 5,
        },
      });
    } else if (this.props.location.state.Pagename === "kpiMeeting7days") {
      history.push({
        pathname: "/kpis",
        state: {
          data: 1,
        },
      });
    }else if (this.props.location.state.Pagename == "clientPotenial") {
      history.push("/clientpotentialmeetings");
    }else if (this.props.location.state.Pagename == "ClientRescheduleMeeting") {
      history.push("/clientrescheduling");
    } else if (this.props.location.state.Pagename == "ClientBookedMeeting") {
      history.push("/clientmeetings");
    } else if (this.props.location.state.Pagename == "ClientLinkedinFollow") {
      history.push("/clientlinkedinfollowUp");
    } else if (this.props.location.state.Pagename == "OpportunitiesMeeting") {
      history.push("/opportunities");
    }else if (this.props.location.state.Pagename == "RescheduleMeeting") {
      history.push("/reschedules");
    }else if (this.props.location.state.Pagename == "EditCampaign") {
      history.push({ pathname: "/editcampaigns", state: { data: this.props.location.state.campignId,PageName:"ViewContact" } })
    }
    else {
      history.push("/contacts");
    }
  }

  // contact status get data
  async ContactStatusGet() {
    try {
      this.setState({ IsLoading: true });
  
      const { ClientID } = GetUserDetails();
      const { data: id } = this.props.location.state;
  
      const DataStr = {
        ClientID: ClientID,
        ProspectID: id,
      };
  
      const result = await Axios.post(
        `${CommonConstants.MOL_APIURL}/contacts/ContactLastEmail`,
        DataStr
      );
  
      const newArray = [result.data?.ContactStatusAllData];
  
      this.setState({
        ContactStatusDData: newArray,
        IsLoading: false,
      });
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      this.setState({ IsLoading: false });
    }
  }

  h2Change = (e) => {

    this.setState({ defaultTagArray: e });
  };

  //delete contact note id
  ProspectNoteDelete(id, PageName) {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a prospect note.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var PNoteDelete = {
          _id: id,
          Type: PageName,
          LastUpdatedDt: new Date(),
          LastUpdatedBy: this.state.UserID,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/contacts/ProspectNoteDelete",
          method: "POST",
          data: PNoteDelete,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "prospect note deleted successfully.",
                "success"
              );
              var Details = GetUserDetails();
              const Email = this.props.location.state.EmailData;
              const id = this.props.location.state.data;
              this.setState({ id: id });
              var SearchedVal = document.getElementById("SearchNote").value;
              var ProspectNoteData = {
                Page: this.state.NotePage,
                ClientID: Details.ClientID,
                UserID: Details.ParentUserID,
                ProspectID: id,
                Email: Email,
                RowsPerPage: this.state.NoteRowsPerPage,
                Sort: true,
                Field: this.state.NoteSortField,
                SortBy: this.state.NoteSortedBy,
                Search: SearchedVal,
                Type: "User",
                Role: this.state.Role
              };
              Axios({
                url: CommonConstants.MOL_APIURL + "/contacts/NoteGet",
                method: "POST",
                data: ProspectNoteData,
              }).then((responsenote) => {
                this.setState({ NoteData: responsenote.data?.PageData });
                this.setState({ NoteRows: responsenote.data?.PageData });
                this.setState({ NoteRlen: responsenote.data?.TotalCount });
                this.setState({ NoteFlen: responsenote.data?.TotalCount });
                this.setState({ NoteCountPage: responsenote.data?.PageCount });
              });
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  }


  //  handle change contact notes
  HandleChangePNotes(Field, e) {
    let Fields = this.state.Fields;
    Fields[Field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Title != "") {
      this.state.Errors.Title = null;
      this.state.TitleAvailable = null;
      this.ProspectNoteTitleCheckExist(e.target.value);
    } else {
      this.state.TitleAvailable = null;
      this.state.Errors.Title = null;
    }
  }

  // contact note get
  async NoteGet() {
    try {
      this.setState({ IsLoading: true });
  
      const { ClientID, ParentUserID } = GetUserDetails();
      const { EmailData, data: id } = this.props.location.state;
  
      this.setState({ id: id });
  
      const ProspectNoteData = {
        Page: this.state.NotePage,
        ClientID: ClientID,
        UserID: ParentUserID,
        ProspectID: id,
        Email: EmailData,
        RowsPerPage: this.state.NoteRowsPerPage,
        Sort: true,
        Field: this.state.NoteSortField,
        SortBy: this.state.NoteSortedBy,
        Search: this.state.NoteSearch,
        Type: "User",
        Role: this.state.Role,
      };
  
      const responsenote = await Axios.post(
        `${CommonConstants.MOL_APIURL}/contacts/NoteGet`,
        ProspectNoteData
      );
  
      this.setState({
        NoteData: responsenote.data?.PageData,
        NoteRows: responsenote.data?.PageData,
        NoteRlen: responsenote.data?.TotalCount,
        NoteFlen: responsenote.data?.TotalCount,
        NoteCountPage: responsenote.data?.PageCount,
        IsLoading: false,
      });
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      this.setState({ IsLoading: false });
    }
  }
  Handlevalidationfornotesedit() {
    let Errors1 = {};
    Errors1["TitlePEditNote"] = "";
    Errors1["TitlePEditNote"] = "";
    this.state.TitleAvailableNotes = null;
    this.setState({ Errors1: Errors1 });
  }
  // find id by record contact edit
  ProspectNoteEditBtn(id, PageName) {
    this.setState({ id: id });
    this.setState({ PageType: PageName })
    var ProspectNoteEdit = {
      id: id,
      Type: PageName
    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ProspectNoteByID",
      method: "POST",
      data: ProspectNoteEdit,
    }).then((res) => {

      // if (res.data.StatusMessage == "SUCCESS") {
      this.setState({ OldTitleUpdate: res.data[0]?.Title });
      document.getElementById("TitlePEditNote").value = res.data[0].Title;
      document.getElementById("NotesEditPNotes").value = res.data[0].Note;
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  }

  //get sort field data
  NoteSortData = (Field) => {
    var Details = GetUserDetails();
    var searchedVal = document.getElementById("SearchNote").value;
    var SerchBox;
    if (searchedVal == "") {
      this.setState({ IsLoading: true });
      SerchBox = false;
      this.setState({ NoteSflag: false });
      var SortField = Field;
      var SortBy;
      if (this.state.NoteSortedBy == 1) {
        SortBy = -1;
        this.setState({ NoteSortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ NoteSortedBy: 1 });
      }
      this.setState({ NoteSortField: Field });
      const Email = this.props.location.state.EmailData;
      const id = this.props.location.state.data;
      var InputParameter = {
        Page: this.state.NotePage,
        ClientID: Details.ClientID,
        ProspectID: id,
        Email: Email,
        RowsPerPage: this.state.NoteRowsPerPage,
        SerchBox: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.NoteSearch,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };
      const ContactNoteList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/NoteGet",
        method: "POST",
        data: InputParameter,
      });
      ContactNoteList.then((responsenote) => {
        this.setState({ NoteData: responsenote.data?.PageData });
        this.setState({ NoteRows: responsenote.data?.PageData });
        this.setState({ NoteRlen: responsenote.data?.TotalCount });
        this.setState({ NoteFlen: responsenote.data?.TotalCount });
        this.setState({ NoteCountPage: responsenote.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    } else {
      this.setState({ IsLoading: true });
      SerchBox = true;

      this.setState({ NoteSflag: true });

      var SortField = Field;
      var SortBy;
      if (this.state.NoteSortedBy == 1) {
        SortBy = -1;
        this.setState({ NoteSortedBy: -1 });

      } else {
        SortBy = 1;
        this.setState({ NoteSortedBy: 1 });
      }

      this.setState({ NoteSortField: Field });
      const Email = this.props.location.state.EmailData;
      const id = this.props.location.state.data;
      var InputParameter = {
        Page: this.state.NotePage,
        ClientID: Details.ClientID,
        ProspectID: id,
        RowsPerPage: this.state.NoteRowsPerPage,
        SerchBox: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.NoteSearch,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };

      const ContactNoteList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/NoteGet",
        method: "POST",
        data: InputParameter,
      });
      ContactNoteList.then((responsenote) => {
        this.setState({ NoteData: responsenote.data?.PageData });
        this.setState({ NoteRows: responsenote.data?.PageData });
        this.setState({ NoteRlen: responsenote.data?.TotalCount });
        this.setState({ NoteFlen: responsenote.data?.TotalCount });
        this.setState({ NoteCountPage: responsenote.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };

  // contact status history sort get data
  ContactStatusHistorySortData = (Field) => {
    var Details = GetUserDetails();
    var searchedVal = document.getElementById(
      "HSearch"
    ).value;
    if (searchedVal == "") {
      this.setState({ IsLoading: true });
      SerchBox = false;
      this.setState({ Sflag: false });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedBy: 1 });
      }
      this.setState({ SortField: Field });
      const id = this.props.location.state.data;
      this.setState({ id: id });

      var InputParameter = {
        Page: this.state.HPage,
        ClientID: Details.ClientID,
        UserID: this.state.UserID,
        ProspectID: id,
        RowsPerPage: this.state.HRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.HSearch,
        Type: "User",
        Role: this.state.Role
      };

      const ContactStatusHistoryList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ProspectstatusHistoryGet",
        method: "POST",
        data: InputParameter,
      });
      ContactStatusHistoryList.then((reshistory) => {
        this.setState({ HistoryData: reshistory.data?.PageData });
        this.setState({ HRows: reshistory.data?.PageData });
        this.setState({ HRlen: reshistory.data?.TotalCount });
        this.setState({ HFlen: reshistory.data?.TotalCount });
        this.setState({ HCountPage: reshistory.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    } else {
      this.setState({ IsLoading: true });
      SerchBox = true;
      this.setState({ Sflag: true });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });

      } else {
        SortBy = 1;

        this.setState({ SortedBy: 1 });
      }
      this.setState({ SortField: Field });
      const id = this.props.location.state.data;
      this.setState({ id: id });
      var Details = GetUserDetails();
      var InputParameter = {
        Page: this.state.HPage,
        ClientID: Details.ClientID,
        UserID: this.state.UserID,
        ProspectID: id,
        RowsPerPage: this.state.HRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.HSearch,
        Type: "User",
      };

      const ContactStatusHistoryList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ProspectstatusHistoryGet",
        method: "POST",
        data: InputParameter,
      });
      ContactStatusHistoryList.then((reshistory) => {
        this.setState({ HistoryData: reshistory.data?.PageData });
        this.setState({ HRows: reshistory.data?.PageData });
        this.setState({ HRlen: reshistory.data?.TotalCount });
        this.setState({ HFlen: reshistory.data?.TotalCount });
        this.setState({ HCountPage: reshistory.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };


  // lists sort get data
  ListsSortData = (Field) => {
    var Details = GetUserDetails();
    const id = this.props.location.state;
    this.setState({ id: id });
    var searchedVal = document.getElementById("searchlist").value;
    if (searchedVal == "") {
      this.setState({ IsLoading: true });
      this.setState({ SflagL: false });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedBy: 1 });
      }
      this.setState({ SortField: Field });
      var InputParameter = {
        Page: this.state.PageL,
        ClientID: Details.ClientID,
        RowsPerPage: this.state.RowsPerPageL,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.SearchL,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role,
        ProspectID: id,
      };
      const List = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ListsGet",
        method: "POST",
        data: InputParameter,
      });
      List.then((result1) => {
        this.setState({ ListData: result1.data?.PageData });
        this.setState({ ListRows: result1.data?.PageData });
        this.setState({ LRlen: result1.data?.TotalCount });
        this.setState({ LFlen: result1.data?.TotalCount });
        this.setState({ LCountPage: result1.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    } else {
      this.setState({ IsLoading: true });
      this.setState({ SflagL: true });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedBy: 1 });
      }
      this.setState({ SortField: Field });
      var InputParameter = {
        Page: this.state.PageL,
        ClientID: Details.ClientID,
        RowsPerPage: this.state.RowsPerPageL,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.SearchL,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role,
        ProspectID: id,
      };
      const List = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ListsGet",
        method: "POST",
        data: InputParameter,
      });
      List.then((result1) => {
        this.setState({ ListData: result1.data?.PageData });
        this.setState({ ListRows: result1.data?.PageData });
        this.setState({ LRlen: result1.data?.TotalCount });
        this.setState({ LFlen: result1.data?.TotalCount });
        this.setState({ LCountPage: result1.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };

  // Potential Meetings sort get data
  PotentialMeetingSortData = (Field) => {
    var Details = GetUserDetails();
    const Email = this.props.location.state.EmailData;
    var searchedVal = document.getElementById("searchpotentialmeet").value;
    if (searchedVal == "") {
      this.setState({ IsLoading: true });
      SerchBox = false;
      this.setState({ PMSflag: false });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedBy: 1 });
      }
      this.setState({ SortField: Field });
      var InputParameter = {
        Page: this.state.PMPage,
        RowsPerPage: this.state.PMRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        ClientID: Details.ClientID,
        Type: "User",
        Email: Email,
        Search: this.state.PMSearch,
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };

      const PotentailMeetingList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/PotentialMeetingGet",
        method: "POST",
        data: InputParameter,
      });
      PotentailMeetingList.then((result) => {
        this.setState({ PotentialMeetingData: result.data?.PageData });
        this.setState({ PMRows: result.data?.PageData });
        this.setState({ PMRlen: result.data?.TotalCount });
        this.setState({ PMFlen: result.data?.TotalCount });
        this.setState({ PMCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });

      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    } else {
      this.setState({ IsLoading: true });
      const Email = this.props.location.state.EmailData;
      SerchBox = true;
      this.setState({ PMSflag: true });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedBy: 1 });
      }
      this.setState({ SortField: Field });
      var InputParameter = {
        Page: this.state.PMPage,
        RowsPerPage: this.state.PMRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.PMSearch,
        ClientID: Details.ClientID,
        Email: Email,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };

      const PotentailMeetingList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/PotentialMeetingGet",
        method: "POST",
        data: InputParameter,
      });
      PotentailMeetingList.then((result) => {
        this.setState({ PotentialMeetingData: result.data?.PageData });
        this.setState({ PMRows: result.data?.PageData });
        this.setState({ PMRlen: result.data?.TotalCount });
        this.setState({ PMFlen: result.data?.TotalCount });
        this.setState({ PMCountPage: result.data?.PageCount });
        this.setState({ IsLoading: true });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };

  // Call history sort get data
  CallHistorySortData = (Field) => {
    var Details = GetUserDetails();
    const id = this.props.location.state;
    var searchedVal = document.getElementById("searchcallhistory").value;

    if (searchedVal == "") {
      this.setState({ IsLoading: true });

      this.setState({ MSflag: false });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedBy: 1 });
      }
      this.setState({ SortField: Field });
      var InputParameter = {
        Page: this.state.CPage,
        RowsPerPage: this.state.CRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.CSearch,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        ProspectID: id,
        Type: "User",
        Role: this.state.Role
      };
      const CallHistoryList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CallHistoryGet",
        method: "POST",
        data: InputParameter,
      });
      CallHistoryList.then((result) => {
        this.setState({ CallHistoryData: result.data?.PageData });
        this.setState({ CRows: result.data?.PageData });
        this.setState({ CRlen: result.data?.TotalCount });
        this.setState({ CFlen: result.data?.TotalCount });
        this.setState({ CCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });

      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    } else {
      this.setState({ IsLoading: true });
      const id = this.props.location.state;
      this.setState({ MSflag: true });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedBy: 1 });
      }
      this.setState({ SortField: Field });
      var InputParameter = {
        Page: this.state.CPage,
        RowsPerPage: this.state.CRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.CSearch,
        ClientID: Details.ClientID,
        ProspectID: id,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };
      const CallHistoryList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CallHistoryGet",
        method: "POST",
        data: InputParameter,
      });
      CallHistoryList.then((result) => {
        this.setState({ CallHistoryData: result.data?.PageData });
        this.setState({ CRows: result.data?.PageData });
        this.setState({ CRlen: result.data?.TotalCount });
        this.setState({ CFlen: result.data?.TotalCount });
        this.setState({ CCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });

      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };

  // handlechage add prospect note
  HandleChangePNotesAdd(Field, e) {
    let Fields = this.state.Fields;
    Fields[Field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Title != "") {
      this.state.Errors.Title = null;
      this.state.TitleAvailableNotes = null;
      this.ProspectNoteTitleCheckExist(e.target.value, "Prospect");
    } else {
      this.state.TitleAvailableNotes = null;
      this.state.Errors.Title = null;
    }
  }


  // save contact note
  async ProspectNoteSave() {
    this.setState({ ProspectNoteBtnDisabled: true })
    var Final_flag = await this.fromValidationNotes();
    if (Final_flag == true) {
      var Title = document.getElementById("TitlePNote").value;
      var Notes = document.getElementById("NotesPNote").value;
      var ProspectData = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        ProspectID: this.state.ProspectIdForNote,
        Title: Title,
        Note: Notes,
        CreatedBy: this.state.UserID,
        CreatedDate: new Date(),
        LastUpdatedBy: null,
        LastUpdatedDate: null,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ProspectNoteAdd",
        method: "POST",
        data: ProspectData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success("Prospect note add successfully.", "Add Note ");
          this.setState({ ProspectNoteBtnDisabled: false })
          this.setState({ ShowNotePopup: false })

          this.setState({ IsLoading: false });
          //note get data
          this.NoteGet()
        } else {

          toast.error(res.data.Message);
        }
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });

    } else {
      this.setState({ ProspectNoteBtnDisabled: false })
    }
  }

  //form validation for add prospect note
  async fromValidationNotes() {
    let formIsValid = true;
    let Errors = {};
    var Title = document.getElementById("TitlePNote").value.trim();
    var Notes = document.getElementById("NotesPNote").value.trim();
    var IsExist = await this.ProspectNoteTitleCheckExist(Title, "Prospect");
    if (Title == "") {
      formIsValid = false;
      Errors["TitlePNote"] = "Please Enter Title";
    }
    if (Notes == "") {
      formIsValid = false;
      Errors["NotesPNote"] = "Please Enter Notes";
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    this.setState({ Errors: Errors });
    return formIsValid;
  }

  // find check exits contact prospect title
  async ProspectNoteTitleCheckExist(Title, PageType) {
    var ProspectNote = {
      ClientID: this.state.ClientID,
      Title: Title,
      Type: PageType
    };
    var res = await Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ProspectNoteCheckExits",
      method: "POST",
      data: ProspectNote,
    })
    if (res.data.StatusMessage == "SUCCESS") {
      if (res.data.Data.length > 0) {
        let Errors = {};
        this.setState({ TitleAvailableNotes: res.data.Data[0].Title });
        Errors["NotesPNote"] = "";
        this.setState({ Errors: Errors });
        return true;
      } else {
        return false;
      }
    }

  }

  // handlechage add prospect note
  HandleChangePNotesEdit(Field, e) {
    let Fields = this.state.Fields;
    Fields[Field] = e.target.value;
    this.setState({ Fields });
    if (Fields.Title != "") {
      this.state.Errors1.Title = null;
      this.state.TitleAvailableNotes = null;
      this.ProspectNoteTitleCheckExist1(e.target.value, this.state.PageType);
    } else {
      this.state.TitleAvailableNotes = null;
      this.state.Errors1.Title = null;
    }
  }

  // check exists update notes
  async ProspectNoteTitleCheckExist1(Title, PageT) {
    var ProspectNote = {
      ClientID: this.state.ClientID,
      Title: Title,
      Type: PageT
    };
    var res = await Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ProspectNoteCheckExits",
      method: "POST",
      data: ProspectNote,
    })
    if (res.data.StatusMessage == "SUCCESS") {
      if (res.data.Data.length > 0) {
        if (this.state.OldTitleUpdate == Title) {
          return false;
        } else {
          let Errors1 = {};
          this.setState({ TitleAvailableNotes: res.data.Data[0].Title });
          Errors1["TitleEditNote"] = "";
          this.setState({ Errors1: Errors1 });
          return true;
        }
      } else {
        return false;
      }
    }

  }

  //  contact note update
  async ProspectNoteUpdate(e, closeCallback) {
    this.setState({ BtnDisabledUpdate: true });
    var FinalValidation = await this.fromValidationNote();
    if (FinalValidation == true) {
      var Title = document.getElementById("TitlePEditNote").value;
      var Notes = document.getElementById("NotesEditPNotes").value;

      var ProspectNoteUpdateData = {
        _id: this.state.id,
        Title: Title,
        Note: Notes,
        Type: this.state.PageType,
        LastUpdatedBy: this.state.UserID,
        LastUpdatedDate: new Date(),
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ProspectNoteUpdate",
        method: "POST",
        data: ProspectNoteUpdateData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            "Prospect note Status update successfully.",
            "Update Potential meeting Status"
          );
          closeCallback();
          var Details = GetUserDetails();
          const Email = this.props.location.state.EmailData;
          const id = this.props.location.state.data;
          this.setState({ id: id });
          var ProspectNoteData = {
            Page: this.state.NotePage,
            ClientID: Details.ClientID,
            UserID: Details.ParentUserID,
            ProspectID: id,
            Email: Email,
            RowsPerPage: this.state.NoteRowsPerPage,
            Sort: true,
            Field: this.state.NoteSortField,
            SortBy: this.state.NoteSortedBy,
            Search: this.state.NoteSearch,
            Type: "User",
            Role: this.state.Role
          };
          Axios({
            url: CommonConstants.MOL_APIURL + "/contacts/NoteGet",
            method: "POST",
            data: ProspectNoteData,
          }).then((responsenote) => {
            this.setState({ NoteData: responsenote.data?.PageData });
            this.setState({ NoteRows: responsenote.data?.PageData });
            this.setState({ NoteRlen: responsenote.data?.TotalCount });
            this.setState({ NoteFlen: responsenote.data?.TotalCount });
            this.setState({ NoteCountPage: responsenote.data?.PageCount });
          });

        } else {
          toast.error(res.data.Message);
        }
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    } else {
      document.getElementById("submitupdate").disabled = false;
    }

  }

  // formvalidation note update
  async fromValidationNote() {
    let formIsValid = true;

    let Errors1 = {};

    var Title1 = document.getElementById("TitlePEditNote").value;
    var Notes1 = document.getElementById("NotesEditPNotes").value;
    var IsExist = await this.ProspectNoteTitleCheckExist(Title1, this.state.PageType);
    if (Title1 == "") {
      formIsValid = false;
      Errors1["TitlePEditNote"] = "Please Enter Title";
    }
    if (Notes1 == "") {
      formIsValid = false;
      Errors1["NotesEditPNotes"] = "Please Enter Notes";
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    this.setState({ Errors1: Errors1 });

    return formIsValid;
  }

  // change page
  HandleChangePage = (Event, NewPage) => {
    var Details = GetUserDetails();
    this.setState({ NotePage: NewPage });
    var SearchedVal = document.getElementById("SearchNote").value;

    if (NewPage == this.state.NotePage) {
      this.setState({ IsLoading: true });
      this.setState({ NotePage: NewPage });
      this.setState({ IsLoading: false });

    } else {
      this.setState({ IsLoading: true });
      const Email = this.props.location.state.EmailData;
      const id = this.props.location.state.data;
      this.setState({ id: id });
      var ProspectNoteData = {
        Page: NewPage,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        ProspectID: id,
        Email: Email,
        RowsPerPage: this.state.NoteRowsPerPage,
        Sort: true,
        Field: this.state.NoteSortField,
        SortBy: this.state.NoteSortedBy,
        Search: SearchedVal,
        Type: "User",
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/NoteGet",
        method: "POST",
        data: ProspectNoteData,
      }).then((responsenote) => {
        this.setState({ NoteData: responsenote.data?.PageData });
        this.setState({ NoteRows: responsenote.data?.PageData });
        this.setState({ NoteRlen: responsenote.data?.TotalCount });
        this.setState({ NoteFlen: responsenote.data?.TotalCount });
        this.setState({ NoteCountPage: responsenote.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  }


  // potential meetings add
  PotentialMeetingsAdd() {
    var PushData = this.state.ContactData
    PushData["Pagename"] = "Contact"
    history.push("/addpotentialmeeting", PushData);
  }

  //  potential meeting change display rows
  ChangeRowSelectedPotentialMeeting = (Event) => {
    var Details = GetUserDetails();
    this.setState({ IsLoading: true });
    this.setState({ PMRowsPerPage: Number(Event.target.value), PMPage: 1 });
    const Email = this.props.location.state.EmailData;
    var SearchedVal = document.getElementById("searchpotentialmeet").value;
    var PotentialMeetingDataStr = {
      Page: 1,
      RowsPerPage: Number(Event.target.value),
      Sort: true,
      Field: this.state.MSortField,
      SortBy: this.state.PMSortedBy,
      Search: SearchedVal,
      ClientID: Details.ClientID,
      Email: Email,
      Type: "User",
      UserID: Details.ParentUserID,
      Role: this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/PotentialMeetingGet",
      method: "POST",
      data: PotentialMeetingDataStr,
    }).then((result) => {
      this.setState({ PotentialMeetingData: result.data?.PageData });
      this.setState({ PMRows: result.data?.PageData });
      this.setState({ PMRlen: result.data?.TotalCount });
      this.setState({ PMFle: result.data?.TotalCount });
      this.setState({ PMCountPage: result.data?.PageCount });
      this.setState({ PMNotes: result.data?.PotentialMeetingNoteData });
      this.setState({ IsLoading: false });

    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  };

  //potential meeting search for record contact note
  RequestSearchPotentialMeeting(event) {
    var Details = GetUserDetails();
    if (event.key === "Enter") {
      this.setState({ IsLoading: true });
      var SearchedVal = document.getElementById("searchpotentialmeet").value;
      this.setState({ PMSearch: SearchedVal });
      const Email = this.props.location.state.EmailData;
      var PotentialMeetingDataStr = {
        Page: 1,
        RowsPerPage: this.state.PMRowsPerPage,
        Sort: true,
        Field: this.state.MSortField,
        SortBy: this.state.PMSortedBy,
        Search: SearchedVal,
        ClientID: Details.ClientID,
        Email: Email,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/PotentialMeetingGet",
        method: "POST",
        data: PotentialMeetingDataStr,
      }).then((result) => {
        this.setState({ PotentialMeetingData: result.data?.PageData });
        this.setState({ PMRows: result.data?.PageData });
        this.setState({ PMRlen: result.data?.TotalCount });
        this.setState({ PMFlen: result.data?.TotalCount });
        this.setState({ PMCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });

      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  }

  // meetings add
  MeetingsAdd() {
    var PushData = this.state.ContactData
    history.push({ pathname: "/addbookedmeeting", state: { Pagename: "Meeting", PushData, dataprospect: this.state.ProspectIdForNote, BackPage: "/viewcontacts", data: this.props.location.state.data, EmailData: this.props.location.state.EmailData } });
  }
  //   meeting change display rows
  ChangeRowSelectedMeeting = (Event) => {
    var Details = GetUserDetails();
    this.setState({ IsLoading: true });
    this.setState({ MRowsPerPage: Number(Event.target.value), MPage: 1 });
    var SearchedVal = document.getElementById("searchMeeting").value;
    const Email = this.props.location.state.EmailData;
    var MeetingDataStr = {
      Page: 1,
      RowsPerPage: Number(Event.target.value),
      Sort: true,
      Field: this.state.MSortField,
      SortBy: this.state.MSortedBy,
      Search: SearchedVal,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      Email: Email,
      Type: "User",
      Role: this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/MeetingGet",
      method: "POST",
      data: MeetingDataStr,
    }).then((result) => {
      this.setState({ MeetingData: result.data?.PageData });
      this.setState({ MRows: result.data?.PageData });
      this.setState({ MRlen: result.data?.TotalCount });
      this.setState({ MFlen: result.data?.TotalCount });
      this.setState({ MCountPage: result.data?.PageCount });
      this.setState({ MNotes: result.data?.MeetingNoteData });
      this.setState({ IsLoading: false });
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    });
  };

  // meeting search for record contact note
  RequestSearchMeeting(event) {
    var Details = GetUserDetails();
    if (event.key === "Enter") {
      this.setState({ IsLoading: true });
      var Searchedval = document.getElementById("searchMeeting").value;
      const Email = this.props.location.state.EmailData;
      var MeetingDataStr = {
        Page: this.state.MPage,
        RowsPerPage: this.state.MRowsPerPage,
        Sort: true,
        Field: this.state.MSortField,
        SortBy: this.state.MSortedBy,
        Search: Searchedval,
        ClientID: Details.ClientID,
        Email: Email,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/MeetingGet",
        method: "POST",
        data: MeetingDataStr,
      }).then((result) => {
        this.setState({ MeetingData: result.data?.PageData });
        this.setState({ MRows: result.data?.PageData });
        this.setState({ MRlen: result.data?.TotalCount });
        this.setState({ MFlen: result.data?.TotalCount });
        this.setState({ MCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  }

  //  Meetings sort get data
  MeetingSortData = (Field) => {
    var Details = GetUserDetails();
    var searchedVal = document.getElementById("searchMeeting").value;
    if (searchedVal == "") {
      this.setState({ IsLoading: true });
      this.setState({ MSflag: false });
      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedBy: 1 });
      }
      this.setState({ SortField: Field });
      const Email = this.props.location.state.EmailData;
      var InputParameter = {
        Page: this.state.MPage,
        RowsPerPage: this.state.MRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.MSearch,
        ClientID: Details.ClientID,
        Email: Email,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };
      const MeetingList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/MeetingGet",
        method: "POST",
        data: InputParameter,
      });
      MeetingList.then((result) => {
        this.setState({ MeetingData: result.data?.PageData });
        this.setState({ MRows: result.data?.PageData });
        this.setState({ MRlen: result.data?.TotalCount });
        this.setState({ MFlen: result.data?.TotalCount });
        this.setState({ MCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });

      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    } else {
      this.setState({ IsLoading: true });

      this.setState({ MSflag: true });

      var SortField = Field;
      var SortBy;
      if (this.state.SortedBy == 1) {
        SortBy = -1;
        this.setState({ SortedBy: -1 });
      } else {
        SortBy = 1;
        this.setState({ SortedBy: 1 });
      }
      this.setState({ SortField: Field });
      var InputParameter = {
        Page: this.state.MPage,
        RowsPerPage: this.state.MRowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: this.state.MSearch,
        ClientID: Details.ClientID,
        Email: Email,
        Type: "User",
        UserID: Details.ParentUserID,
        Role: this.state.Role
      };

      const MeetingList = Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/MeetingGet",
        method: "POST",
        data: InputParameter,
      });
      MeetingList.then((result) => {
        this.setState({ MeetingData: result.data?.PageData });
        this.setState({ MRows: result.data?.PageData });
        this.setState({ MRlen: result.data?.TotalCount });
        this.setState({ MFlen: result.data?.TotalCount });
        this.setState({ MCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });

      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  };



  ViewPage = (ID) => {
    history.push({ pathname: "/viewaccount", state: { data: ID ,PageName: "ViewContact", dataprospect: this.state.ProspectIdForNote, EmailData: this.props.location.state.EmailData} });
  };
  // Edit campaign history 
  EditCampaignHistory(id) {
    history.push({ pathname: "/editcampaigns", state: { data: id, PageName: "ViewContact", dataprospect: this.state.ProspectIdForNote, EmailData: this.props.location.state.EmailData } });
  }

  //campaign history search for record campaign history
  RequestSearchCampaignHistory(event) {
    var Details = GetUserDetails();
    if (event.key === "Enter") {
      this.setState({ IsLoading: true });
      var SearchedVal = document.getElementById("searchcampaigns").value;
      this.setState({ CAMSearch: SearchedVal });
      const id = this.props.location.state.data;
      var CampaignHistoryDataStr = {
        Page: 1,
        ProspectID: id,
        RowsPerPage: this.state.CAMRowsPerPage,
        Sort: true,
        Field: this.state.CAMSortField,
        SortBy: this.state.CAMSortedBy,
        Search: SearchedVal,
        ClientID: Details.ClientID,
        UserID: Details.ParentUserID,
        Type: "User",
        Role: Details.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/CampaignsHistoryGet",
        method: "POST",
        data: CampaignHistoryDataStr,
      }).then((result) => {
        this.setState({ CampaignHistoryData: result.data?.PageData });
        this.setState({ CAMRows: result.data?.PageData });
        this.setState({ CAMRlen: result.data?.TotalCount });
        this.setState({ CAMFlen: result.data?.TotalCount });
        this.setState({ CAMCountPage: result.data?.PageCount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
      });
    }
  }

  toggleMeeting = (meetingID) => {
    this.setState((prevState) => ({
      Open: {
        ...prevState.Open,
        [meetingID]: !prevState.Open[meetingID],
      },
    }));
  };

  toggleMeeting1 = (eventID) => {

    this.setState((prevState) => ({
      Open: {
        ...prevState.Open,
        [eventID]: !prevState.Open[eventID],
      },
    }));
  };

  // SendEmailContact = async () => {
  
  //   var FromEmail = this.state.SendEmailAccount;
  //   var RefresToken = this.state.SendEmailRefreshToken;
  //   var ContactEmail = this.state.Email;
  //   var Subject = document.getElementById("Subject").value.trim();
  //   var Body = document.getElementById("Body").value.trim();
  //   // Check if all fields are blank for the first time
  //   const allFieldsBlank = !FromEmail && !Subject && !Body;
  //   if (allFieldsBlank) {
  //     this.setState({
  //       Errors: {
  //         email_account: 'Please enter account',
  //         subject: 'Please enter subject',
  //         body: 'Please enter body',
  //       },
  //     });
  //   } else {
  //       if (Subject !== "" && Body !== "") {
  //         this.setState({ Errors: { Subject: null }, IsLoading: true });
    
  //         var re =
  //           /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
  //         var Emailflg = re.test(ContactEmail);
    
  //         if (Emailflg) {
  //           var FinalEmail = CryptoJS.AES.encrypt(
  //             JSON.stringify(ContactEmail),
  //             "my-secret-key@123"
  //           ).toString();
    
  //           var EmailData = EmailTemplete.userEmailInvite(FinalEmail);
    
  //           var str_mail = {
  //             Email: EmailData,
  //             FromEmail: FromEmail,
  //             ToEmail: ContactEmail,
  //             RefresToken: RefresToken,
  //             Html: Body,
  //             Subject: Subject,
  //             PageType: "ContactSend"
  //           };
    
  //           // Uncomment the following Axios request and handle the response accordingly
  //           try {
  //             const response = await Axios.post(CommonConstants.MOL_APIURL + "/contacts/ContactEmailSend", str_mail);
  //             this.setState({
  //               SendEmailPopup: false,
  //               IsLoading: false,
  //               Errors: {
  //                 body: null,
  //                 subject: null,
  //                 email_account: null
  //               }
  //             });
  //             toast.success("Email Send Successfully!");
  //           } catch (error) {
  
  //             toast.error("An error occurred. Please try again.");
  //             this.setState({ IsLoading: false });
  //             // Handle error as needed
  //           }
  //         }
  //       } else {
  //         var Errors = {};
  //         if (Subject === "") {
  //           Errors["subject"] = "Please enter subject";
  //         }
  //         if (Body === "") {
  //           Errors["body"] = "Please enter body";
  //         }
  //         this.setState({ Errors: Errors });
  //       }
  
  //   }
  // };
  SendEmailContact = async () => {
    this.setState({IsLoading:true})
    var FromEmail = this.state.SendEmailAccount;
    var RefresToken = this.state.SendEmailRefreshToken;
    var ContactEmail = this.state.Email;
    var Subject = document.getElementById("Subject").value.trim();
    var Body = document.getElementById("Body").value.trim();
    
    // Check individual fields and set errors if necessary
    var Errors = {};
    if (!FromEmail) {
      Errors["email_account"] = "Please select account";
      this.setState({IsLoading:false})
    }
    if (!Subject) {
      Errors["subject"] = "Please enter subject";
      this.setState({IsLoading:false})
    }
    if (!Body) {
      Errors["body"] = "Please enter body";
      this.setState({IsLoading:false})
    }
  
    // If there are any errors, update state and exit
    if (Object.keys(Errors).length > 0) {
      this.setState({ Errors: Errors });
      return;
    }

    this.setState({ Errors: { subject: null }, IsLoading: true });
    var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    var Emailflg = re.test(ContactEmail);
  
    if (Emailflg && FromEmail && Subject && Body) {
      var str_mail = {
        FromEmail: FromEmail,
        ToEmail: ContactEmail,
        RefresToken: RefresToken,
        Html: Body,
        Subject: Subject,
        PageType: "ContactSend"
      };
      // Uncomment the following Axios request and handle the response accordingly
      try {
        const response = await Axios.post(CommonConstants.MOL_APIURL + "/contacts/ContactEmailSend", str_mail);
        this.setState({
          SendEmailPopup: false,
          SendEmailAccount:null,
          SendEmailRefreshToken:"",
          IsLoading: false,
          Errors: {
            body: null,
            subject: null,
            email_account: null
          }
        });
        toast.success("Email Sent Successfully!");
      } catch (error) {
        toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
        // Handle error as needed
      }
    } else {
      this.setState({
        Errors: {
          email_account: 'Invalid contact email',
        },
        IsLoading: false
      });
    }
  };

  handleDateChange = (date) => {
    this.setState({FollowUpdateEdit:date})
  }

  render() {

    return (
      <>
        {this.state.IsLoading ? (
          <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div>
        ) : (
          <></>
        )}
        {/* EDIT AND NOTES PAGE  */}
        <div className="adminmain">
          <Sidebar className="" />

          <div className="bodyhome px-3">
            <div className="my-3">
              <div className="row">
                <div className="col-md-4 col-sm-4 padd-0 d-flex align-items-center">
                  <h3 className=" float-left addpotential_header py-3">
                    Edit Contact
                  </h3>
                </div>
                <div className="col-md-8 col-sm-8 pb-4 text-right"></div>
              </div>
              <div className="bg-white px-4">
                <div className="row borderbtnm ">
                  <div className="col-md-4 col-sm-4 padd-0 d-flex align-items-center"></div>
                  <div className="col-md-8 col-sm-8 text-right">
                    <div className="listing-li float-right padb-15 iconbox-pad edited">
                      <ul>
                      <li>
                          <a className="btn btngroup m-btn" onClick={() => { this.setState({ SendEmailPopup: true }) }}>
                            <i class="flaticon-mail la-icon-font-size-13"></i>
                            <span>Send Email</span>
                          </a>
                        </li>
                        <Popup open={this.state.SendEmailPopup}>

                          <div>
                            <div className="modal-black"></div>
                            <div className="filterPopup ">
                              <div className="paddingboxTerms">
                                <div className="modal-header py-3 px-3">
                                  <h6 className="mb-0">Send Email</h6>
                                </div>
                                <div className="modal-body p-5">

                                  <div class="row mb-3">
                                    <label class="col-lg-2 col-form-label text-left">
                                      Account
                                    </label>
                                    <div class="col-lg-4">
                                      <select
                                        className="form-control  m-input"
                                        name="email_account"
                                        id="email_account"
                                        onChange={this.handleChange.bind(this, "email_account")}
                                      >
                                        <option value="" >-Select-</option>
                                        {
                                          this.state.EmailDataSend?.map((emitem) => {
                                            return (<option value={emitem._id} key={emitem._id}>
                                              {emitem.SMTPFromEmail
                                              }
                                            </option>)
                                          })
                                        }
                                      </select>
                                      <span className="email_account" style={{ color: "red", display: "flex", fontSize: 'small' }}>
                                    {this.state.Errors["email_account"]}
                                  </span>
                                    </div>
                                    <div class="col-lg-6 d-flex">
                                      <label>Contact</label>
                                      {this.state.ContactData?.map((item) => {
                                        return (<span class="ml-3" id="contactemail">{item.Email}</span>)
                                      })}
                                    </div>

                                  </div>
                                  <div>

                                    <div class="row mb-3">
                                      <label class="col-lg-2 col-form-label text-left">
                                        Subject
                                      </label>
                                      <div class="col-lg-8">
                                        <input
                                          class="form-control m-input"
                                          data-val="true"
                                          data-val-remote="Title already exist."
                                          data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                          data-val-required="Please enter title"
                                          id="Subject"
                                          name="Subject"
                                       
                                          onChange={(e) => this.handleInputChange("subject", e.target.value)}

                                          type="text"
                                        />
                                        <span className="sendmail-errors" style={{ color: "red", display: "flex", fontSize: 'small' }}>
                                          {this.state.Errors["subject"]}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div class="row">
                                      <label class="col-lg-2 col-form-label text-left">
                                        Body
                                      </label>
                                      <div class="col-lg-8">
                                        <textarea
                                          class="form-control m-input"
                                          data-val="true"
                                          id="Body"
                                          name="Body"
                              
                                          type="textarea"
                                          onChange={(e) => this.handleInputChange("body", e.target.value)}
                                        >
                                        </textarea>
                                        <span className="sendmail-errors" style={{ color: "red", display: "flex", fontSize: 'small' }}>
                                          {this.state.Errors["body"]}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="modal-content border-top py-4 px-3 text-right">
                                  <div class="row">
                                    <div class="col-lg-12 pull-right">
                                      <a
                                        id="backtolist"
                                        class="btn btn-secondary mr-2"
                                        onClick={() => {
                                        this.setState({
                                          SendEmailAccount:null,
                                          SendEmailRefreshToken:"",
                                          SendEmailPopup: false,
                                          Errors: {
                                            body: null,
                                            subject: null,
                                            email_account:null
                                          }
                                        });
                                      }}
                                      >
                                        Cancel
                                      </a>
                                      <button
                                        id="submit"
                                        class="btn btn-primary btn-lightgreen mr-1"
                                        href="javascript:void(0);"
                                        value="Save"
                                        onClick={() => this.SendEmailContact()}
                                      >
                                        <i class="la la-save"></i> Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </Popup>
                        <li>
                          <button
                            disabled={this.state.BtnDisabled}
                            onClick={this.ContactUpdate}
                            className="btn btngroup m-btn"
                          >
                            <i class="fa fa-save la-icon-font-size-13"></i>
                            <span> Save</span>
                          </button>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              this.DeleteBtnContact(this.state.ProspectIDData);
                            }}
                            className="btn btngroup m-btn"
                          >
                            <i class="flaticon-delete-1 la-icon-font-size-14"></i>
                            <span>Delete</span>
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              this.ContactAddToBlackList(this.state.Email);
                            }}
                            className="btn btngroup m-btn"
                          >
                            <i class="fa la-icon-font-size-13 fa-ban la-icon-font-size-13"></i>
                            <span>Add to Blacklist</span>
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              this.ContactBackBtn();
                            }}
                            className="btn btngroup m-btn borderbtn"
                          >
                            <i class="la la-arrow-circle-left la-icon-font-size-13"></i>
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 col-sm-4 pt-4 padd-0 d-flex align-items-center">
                    <h3 className="float-left addpotential_header">Contact Info</h3>
                  </div>
                </div>
                <div className="row py-4">
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Account Name
                      </label>
                      <div className="col-lg-7">
                      <label className="labeltext"><a
                      onClick={() => {
                        this.ViewPage(this.state.AccountIdForViewAccount);
                            }}
                            className="btn-eyesicon"
                            title="View"
                          ><u>{this.state.AccountNameForDisplay === null || "" || undefined ? "" : this.state.AccountNameForDisplay}</u></a></label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group"></div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols  d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        First Name
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter first name"
                          id="FirstName"
                          name="FirstName"
                          type="text"
                          placeholder="First name"

                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Last Name
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="LastName"
                          name="LastName"
                          type="text"
                          placeholder="Last name"


                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Email
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-regex="Invalid email."
                          data-val-remote="Email already exist."
                          data-val-remote-additionalFields="*.Email,*.PageName,*.CampaignID,*.ClientID,*.UserID"
                          data-val-remote-url="/Utility/IsProspectEmailExistsAndBlacklist"
                          data-val-required="Please enter email."
                          id="Email"
                          name="Email"
                          type="text"
                          placeholder="Email"


                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Title
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="Title"
                          name="Title"
                          type="text"
                          placeholder="Title"

                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Company
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="Company"
                          name="Company"
                          type="text"
                          placeholder="Company"

                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Company Name Long :
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="CompanyNameLong"
                          name="CompanyNameLong"
                          type="text"
                          placeholder="Company name long"

                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Website
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          id="Website"
                          name="Website"
                          type="text"
                          value={this.state.Fields["Website"]}
                          onBlur={this.handleChangeWebsitecheck.bind(this, "Website")}
                          placeholder="Website"

                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["Website"]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        LinkedIn URL
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="LinkedInURL"
                          name="LinkedInURL"
                          type="text"
                          placeholder="LinkedIn url"

                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Industry
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          name="Industry"
                          type="text"
                          id="Industry"
                          onChange={this.HandleChange.bind(this, "Industry")}
                          value={this.state.Fields["Industry"]}
                          placeholder="Industry"

                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Address 1 :
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          name="Address1"
                          type="text"
                          id="Address1"
                          value={this.state.Fields["Address1"]}
                          onChange={this.HandleChange.bind(this, "Address1")}
                          placeholder="Address 1"

                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Address 2 :
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          name="Address2"
                          type="text"
                          id="Address2"
                          value={this.state.Fields["Address2"]}
                          onChange={this.HandleChange.bind(this, "Address2")}
                          placeholder="Address 2"

                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        City :
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          name="City"
                          id="City"
                          type="text"
                          value={this.state.Fields["City"]}
                          onChange={this.HandleChange.bind(this, "City")}
                          placeholder="City"

                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        State :
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          name="State"
                          id="State"
                          type="text"
                          value={this.state.Fields["State"]}
                          onChange={this.HandleChange.bind(this, "State")}
                          placeholder="State"

                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Zip :
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          name="Zip"
                          id="Zip"
                          type="text"
                          value={this.state.Fields["Zip"]}
                          onChange={this.HandleChange.bind(this, "Zip")}
                          placeholder="Zip"

                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Country :
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          name="Country"
                          id="Country"
                          type="text"
                          value={this.state.Fields["Country"]}
                          onChange={this.HandleChange.bind(this, "Country")}
                          placeholder="Country"

                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Account Category :
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          name="AccountCategory"
                          placeholder="Account category"
                          type="text"
                          id="AccountCategory"
                          value={this.state.Fields["AccountCategory"]}
                          onChange={this.HandleChange.bind(
                            this,
                            "AccountCategory"
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Contact Category :
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          name="ContactCategory"
                          placeholder="Contact category"
                          type="text"
                          id="ContactCategory"
                          value={this.state.Fields["ContactCategory"]}
                          onChange={this.HandleChange.bind(
                            this,
                            "ContactCategory"
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Contact Source :
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          name="ContactSource"
                          id="ContactSourceID"
                          value={this.state.ContactSourceDefaultSelect}
                          onChange={(e) =>
                            this.HandleDropdownChangeContactSource(e)
                          }
                        >
                    <option value="">--Select--</option>
                          {this.state.ContactSourceData.map((value) => (
                            <option value={value._id}>
                              {value.Name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Contact Owner :
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          name="ContactOwnerID"
                          id="ContactOwnerID"
                          value={this.state.ContactOwnerDefaultSelect}
                          onChange={(e) =>
                            this.HandleDropdownChangeContactOwner(e)
                          }
                        >
                         <option value={null}>--Select--</option>
                          {this.state.ContactOwnerData.map((value) => (
                            <option value={value._id}>
                              {value.Name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Client POC :
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          name="ClientPOCID"
                          id="ClientPOCID"
                          value={this.state.ClientPOCDefaultSelect}
                          onChange={(e) => this.HandleDropdownChangeClientPOC(e)}
                        >
                        <option value="">--Select--</option>
                          {this.state.ClientPOCData.map((value) => (
                            <option value={value._id}>
                              {value.Name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Reason Unqualified :
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          name="ReasonUnqualifiedID"
                          id="ReasonUnqualifiedID"
                          value={this.state.ReasonDefaultSelect}
                          onChange={(e) =>
                            this.HandleDropdownChangeReasonUnqualified(e)
                          }
                        >
                          <option value="">--Select--</option>
                          {this.state.ReasonUnqualifiedData.map((value) => (
                            <option value={value._id}>
                              {value.Reason}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Mobile Phone :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="MobilePhone"
                          name="MobilePhone"
                          placeholder="Mobile Phone"
                          type="text"
                          value={this.state.Fields["MobilePhone"]}
                          onChange={this.HandleChange.bind(this, "MobilePhone")}
                          onBlur={() => this.handleChangePhoneValidation("MobilePhone")}
                        />
                        <span style={{ color: "red" }}>
    {this.state.errors["MobilePhone"]}
</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Direct Phone :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="DirectPhone"
                          placeholder="Direct Phone"
                          type="text"
                          value={this.state.Fields["DirectPhone"]}
                          onChange={this.HandleChange.bind(this, "DirectPhone")}
                          onBlur={() => this.handleChangePhoneValidation("DirectPhone")}

/>
<span style={{ color: "red" }}>
{this.state.errors["DirectPhone"]}
</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Direct Phone Ext :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="DirectPhoneExt"
                          placeholder="Direct Phone Ext"
                          type="text"
                          value={this.state.Fields["DirectPhoneExt"]}
                          onChange={this.HandleChange.bind(
                            this,
                            "DirectPhoneExt"
                          )}
                          onBlur={() => this.handleChangePhoneValidation("DirectPhoneExt")}

/>
<span style={{ color: "red" }}>
{this.state.errors["DirectPhoneExt"]}
</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Company Phone :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="CompanyPhone"
                          placeholder="Company Phone"
                          type="text"
                          value={this.state.Fields["CompanyPhone"]}
                          onChange={this.HandleChange.bind(this, "CompanyPhone")}
                          onBlur={() => this.handleChangePhoneValidation("CompanyPhone")}

/>
<span style={{ color: "red" }}>
{this.state.errors["CompanyPhone"]}
</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Contact Status :
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          name="ContactStatus"
                          id="ContactStatusID"
                          value={this.state.ContactStatusDefaultSelect}
                          onChange={(e) =>
                            this.HandleDropdownChangeContactStatus(e)
                          }
                        >
                          <option value={null}>--Select--</option>
                          {this.state.ContactStatusData.map((value) => (
                            <option value={value._id}>
                              {value.Status}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Company Revenue :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="CompanyRevenue"
                          placeholder="Company Revenue"
                          type="number"
                          value={this.state.Fields["CompanyRevenue"]}
                          onChange={this.HandleChange.bind(
                            this,
                            "CompanyRevenue"
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Employee Count :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="CompanyEmployeeCount"
                          type="number"
                          placeholder="Employee Count"
                          value={this.state.Fields["CompanyEmployeeCount"]}
                          onChange={this.HandleChange.bind(
                            this,
                            "CompanyEmployeeCount"
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Tag :
                      </label>
                      <div className="col-lg-7">
                        {/* <Select
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          options={this.state.contactoptions}
                          isMulti
                          onChange={(e) => this.h2Change(e)}
                          value={this.state.defaultTagArray && this.state.defaultTagArray[0] ? this.state.defaultTagArray : null}
                        /> */}

<CreatableSelect
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        options={this.state.contactoptions}
        id="ContactTagID"
        isMulti
        onChange={(e) => this.h2Change(e)}
        value={this.state.defaultTagArray && this.state.defaultTagArray[0] ? this.state.defaultTagArray : null}

      />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Follow Up Date :
                      </label>
                      <div className="col-lg-7">
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            label={false}  
                            value={this.state.FollowUpdateEdit}
                            minDate={this.state.FollowUpdateEdit ? moment(this.state.FollowUpdateEdit) : moment()}
                            onChange={(newDate) => this.handleDateChange(moment(newDate))}
                            renderInput={(params) => <TextField className="form-control m-input" {...params} />}
                            showToolbar={false} 
                          />
                      </LocalizationProvider>
                      </div>
                        {/* {
                          this.state.ContactData.map((value, index) => (
                            <div className="col-lg-7" key={index}>
                              <input
                                className="form-control m-input"
                                id={`FollowUpDate-${index}`}
                                type="date"
                                value={value?.FollowUpDate ?
                                  moment(value?.FollowUpDate).format("YYYY-MM-DD") :
                                  ""}
                                  onChange={(e) => this.handleChangeFollowUpDate(index, e)}
                              />
                            </div>
                          ))
                        } */}
             
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Sales Development Reps :
                      </label>
                      <div className="col-lg-7">
                    
                        <select
                          className="form-control  m-input"
                          name="ColdCallersID"
                          id="ColdCallersID"
                          placeholder="--Select--"
                          value={this.state?.ContactSalesDevlopmentRepsDefaultSelect}
                          onChange={(e) =>
                            this.HandleDropdownChangeContactSalesDevlopmentReps(e)
                          }
                        >
                          <option value="" disabled>--Select--</option>
                          {this.state?.SalesDevelopmentRepsData?.map((value) => (
          
                            <option value={value?._id}>
                            {value.ColdCallersName} {value?.LastName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Sales Rep :
                      </label>

                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="SalesRep"
                          placeholder="Sales Rep"
                          type="text"
                          value={this.state.Fields["SalesRep"]}
                          onChange={this.HandleChange.bind(this, "SalesRep")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Other Phone 1 :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="OtherPhone1"
                          placeholder="Other Phone 1"
                          type="text"
                          value={this.state.Fields["OtherPhone1"]}
                          onChange={this.HandleChange.bind(this, "OtherPhone1")}
                         
    onBlur={() => this.handleChangePhoneValidation("OtherPhone1")}

                        />
                        <span style={{ color: "red" }}>
    {this.state.errors["OtherPhone1"]}
</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Other Phone 2 :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="OtherPhone2"
                          type="text"
                          placeholder="Other Phone 2"
                          value={this.state.Fields["OtherPhone2"]}
                          onChange={this.HandleChange.bind(this, "OtherPhone2")}
                      
    onBlur={() => this.handleChangePhoneValidation("OtherPhone2")}

                        />
                        <span style={{ color: "red" }}>
    {this.state.errors["OtherPhone2"]}
</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Follow Up Notes
                    </label>
                    <div className="col-lg-7">
                    <textarea
                      class="form-control m-input"
                      id="FollowUpNotes"
                      name="FollowUpNotes"
                      placeholder="Follow Up Notes"
                      value={this.state.Fields["FollowUpNotes"]}
                      onChange={this.HandleChange.bind(this,"FollowUpNotes")}
                  ></textarea>
                   
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group"></div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        External Account ID :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="ExternalAccountID"
                          placeholder="External Account ID"
                          type="text"
                          value={this.state.Fields["ExternalAccountID"]}
                          onChange={this.HandleChange.bind(
                            this,
                            "ExternalAccountID"
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        External Contact ID :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="ExternalContactID"
                          placeholder="External Contact ID"
                          type="text"
                          onChange={this.HandleChange.bind(
                            this,
                            "ExternalContactID"
                          )}
                          value={this.state.Fields["ExternalContactID"]}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        External Field 1 :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="ExternalField1"
                          placeholder="External Field 1"
                          value={this.state.Fields["ExternalField1"]}
                          onChange={this.HandleChange.bind(
                            this,
                            "ExternalField1"
                          )}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        External Field 2 :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="ExternalField2"
                          placeholder="External Field 2"
                          type="text"
                          value={this.state.Fields["ExternalField2"]}
                          onChange={this.HandleChange.bind(
                            this,
                            "ExternalField2"
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        External Field 3 :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="ExternalField3"
                          placeholder="External Field 3"
                          value={this.state.Fields["ExternalField3"]}
                          onChange={this.HandleChange.bind(
                            this,
                            "ExternalField3"
                          )}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                      Zoom ID :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="ZoomID"
                          placeholder="Zoom ID"
                          value={this.state.Fields["ZoomID"]}
                          onChange={this.HandleChange.bind(
                            this,
                            "ZoomID"
                          )}
                          type="text"
                          onBlur={() => this.hadleChangeZoomID("ZoomID")}

/>
<span style={{ color: "red" }}>
{this.state.errors["ZoomID"]}
</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                      Best Phone :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="BestPhone"
                          placeholder="Best Phone"
                          value={this.state.Fields["BestPhone"]}
                          onChange={this.HandleChange.bind(
                            this,
                            "BestPhone"
                          )}
                          type="text"
                          onBlur={() => this.handleChangePhoneValidation("BestPhone")}

/>
<span style={{ color: "red" }}>
{this.state.errors["BestPhone"]}
</span>
                      </div>
                    </div>
                  </div>    
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                      Best Phone Status :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="BestPhoneStatus"
                          placeholder="Best Phone Status"
                          value={this.state.Fields["BestPhoneStatus"]}
                          onChange={this.HandleChange.bind(
                            this,
                            "BestPhoneStatus"
                          )}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>  
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                      Phone :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="Phone"
                          placeholder="Phone"
                          value={this.state.Fields["Phone"]}
                          onChange={this.HandleChange.bind(
                            this,
                            "Phone"
                          )}
                          type="text"
                          onBlur={() => this.handleChangePhoneValidation("Phone")}

/>
<span style={{ color: "red" }}>
{this.state.errors["Phone"]}
</span>
                      </div>
                    </div>
                  </div>   
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                      Phone Status :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="PhoneStatus"
                          placeholder="Phone Status"
                          value={this.state.Fields["PhoneStatus"]}
                          onChange={this.HandleChange.bind(
                            this,
                            "PhoneStatus"
                          )}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                      Phone2 :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="Phone2"
                          placeholder="Phone2"
                          value={this.state.Fields["Phone2"]}
                          onChange={this.HandleChange.bind(
                            this,
                            "Phone2"
                          )}
                          type="text"
                          onBlur={() => this.handleChangePhoneValidation("Phone2")}

/>
<span style={{ color: "red" }}>
{this.state.errors["Phone2"]}
</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                      Phone2 Status :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="Phone2Status"
                          placeholder="Phone2 Status"
                          value={this.state.Fields["Phone2Status"]}
                          onChange={this.HandleChange.bind(
                            this,
                            "Phone2Status"
                          )}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                      Phone3 :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="Phone3"
                          placeholder="Phone3"
                          value={this.state.Fields["Phone3"]}
                          onChange={this.HandleChange.bind(
                            this,
                            "Phone3"
                          )}
                          type="text"
                          onBlur={() => this.handleChangePhoneValidation("Phone3")}

/>
<span style={{ color: "red" }}>
{this.state.errors["Phone3"]}
</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                      Phone3 Status :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="Phone3Status"
                          placeholder="Phone3 Status"
                          value={this.state.Fields["Phone3Status"]}
                          onChange={this.HandleChange.bind(
                            this,
                            "Phone3Status"
                          )}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                      Phone4 :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="Phone4"
                          placeholder="Phone4"
                          value={this.state.Fields["Phone4"]}
                          onChange={this.HandleChange.bind(
                            this,
                            "Phone4"
                          )}
                          type="text"
                          onBlur={() => this.handleChangePhoneValidation("Phone4")}

/>
<span style={{ color: "red" }}>
{this.state.errors["Phone4"]}
</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                      Phone4 Status :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="Phone4Status"
                          placeholder="Phone4 Status"
                          value={this.state.Fields["Phone4Status"]}
                          onChange={this.HandleChange.bind(
                            this,
                            "Phone4Status"
                          )}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                      PRL Lead ID (SalesForce ID) :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class="form-control m-input"
                          id="PRLLeadID"
                          placeholder="PRL Lead ID (SalesForce ID)"
                          value={this.state.Fields["PRLLeadID"]}
                          onChange={this.HandleChange.bind(
                            this,
                            "PRLLeadID"
                          )}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group"></div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Do Not Call Contact :
                      </label>
                      <div className="col-lg-7">
                        <input
                          class=""
                          id="IsDoNotCallContact"
                          onChange={(e) => this.HandleChangeCheckIsDoNotCall(e)}
                          type="checkbox"
                          defaultChecked={this.state.IsDoNotCallContactData}
                        />
                      </div>
                    </div>
                  </div>

                  {/* custom column field */}
                  {this.state.ContactCustomFieldArray.length > 0 ? <div className="col-lg-12 pt-4 padd-0 d-flex align-items-center p-1">
                            <h3 className=" float-left addpotential_header">
                              Custom Fields
                            </h3>
                          </div> : null

                      }
                  {this.state.ContactCustomFieldArray?.map((value1, index) => {
                    const customfield = this.state.ProspectCustomFieldByIdArray?.find(
                      (val) => val.CustomFieldID === value1._id
                    )
                    return (
                      <>
          
                        <div className="col-lg-6 py-1  ">
                          <div
                            className="row max-cols d-flex align-items-center"
                            key={index}
                          >
                            <label className="col-lg-4">{value1.FieldName}:</label>
                            <div className="col-lg-7">
                              <input
                                className="form-control m-input"
                                id={value1?._id}
                                defaultValue={customfield?.FieldValue}
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>


            <div className="paddcols px-4 mt-3">
              <div className="row p-3 pb-0">
                <div className="col padd-0 d-flex align-items-center">
                  <h3 className=" float-left pb-4 addpotential_header">
                    Contact Status{" "}
                  </h3>
                </div>
              </div>
              {this.state.ContactData?.map((value) => (
                <div className="row">
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Created Date :
                      </label>
                      <div className="col-lg-7">
                        <label className="col-lg-4 view-lable-meet potential_text">
              

                          {
                          value?.CreatedDate == null ? "" : moment.utc(
                              new Date(value?.CreatedDate)
                            ).format("MM/DD/YYYY")
                          }
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Last Validation Date :
                      </label>
                      <div className="col-lg-7">
                        <label className="col-lg-4">
                        {
                          value?.LastValidationDate == null ? "" : moment.utc(
                              new Date(value?.LastValidationDate)
                            ).format("MM/DD/YYYY")
                          }
           
                        </label>
                      </div>
                    </div>
                  </div>
                  {this.state.ContactStatusDData?.map((valstats) => (
                    <>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Last Email Send Date :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                              {valstats?.TotalSentDate?.MailSentDate != null
                                ? valstats?.TotalSentDate?.MailSentDate : ""}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Last Open Date :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                            {valstats?.LastEmailOpentDate?.OpenedDate != null
                                ? valstats?.LastEmailOpentDate?.OpenedDate: ""}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Last Response Date :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                            {valstats?.LastResponseDate?.RepliedDate != null
                                ? valstats?.LastResponseDate?.RepliedDate : ""}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Last Direct Mail Date :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                              {valstats.TotalSentDate?.DateOfSendMail != null
                                ? moment(
                                  new Date(
                                    valstats.TotalSentDate?.DateOfSendMail
                                  ).toDateString()
                                ).format("MM/DD/YYYY")
                                : ""}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Last Letter Date :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4"></label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Last Status Change :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">{this.state.LastStatusChangeDate}</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Is Opted Out :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                              {value?.IsOptedOut == true ? "Yes" : "No"}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Is Blacklisted :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                              {value?.IsContatcBlackListed == true ? "Yes" : "No"}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Blacklist Date :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">{this.state.BlacklistedDate}</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            On Global Bounce List :
                          </label>
                          <div className="col-lg-7">
                            {value?.IsGlobalBounce == true ? "Yes" : "No"}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Total Email Count :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                 
                            {valstats?.LastEmailSentMailDate === null ? 0 : valstats?.LastEmailSentMailDate}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                          Total Touches :
                        
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                            {valstats?.LastEmailSentMailDate === null ? 0 : valstats?.LastEmailSentMailDate}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Tracked Email Count :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                              {valstats?.TrackedEmailCount === null ? 0 : valstats?.TrackedEmailCount}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Open Count :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                            {valstats?.TotalIsOpenedCount === null ? 0 : valstats?.TotalIsOpenedCount}

                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Reply Count :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                            {value?.EmailSend?.
Replies === null || " " ? 0 : value?.EmailSend?.
Replies}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Touches Before First Reply :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                            {this.state.TotalReplyCount === null ? 0 :this.state.TotalReplyCount}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Removed Count :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">0</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Soft Bounce Count :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                            {value?.EmailSend?.SoftBounce === null || " " ? 0 : value?.EmailSend?.SoftBounce}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Hard Bounce Count :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                            {value?.EmailSend?.HardBounce === null || " " ? 0 : value?.EmailSend?.HardBounce}

                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Letter Count :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4"></label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Direct Mail Count :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4"></label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Last Email Campaign :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                              {this.state.LastEmaiCampaign == "" || null || undefined ? "" : this.state.LastEmaiCampaign}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Last Email Step :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">
                            {value?.CampaignDetails?.LastEmailStep === null ? 0 : value?.CampaignDetails?.LastEmailStep}

                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            View Count :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4"></label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Call Count :
                          </label>
                          <div className="col-lg-7">
                            <label className="col-lg-4">{value.CallCount  == null || undefined ? 0 : value.CallCount}</label>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Last Call Result :
                      </label>
                      <div className="col-lg-7">
                        <label className="col-lg-4">{value.LastCallResult}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Last Call Date :
                      </label>
                      <div className="col-lg-7">
                        <label className="col-lg-4">
                        {this.state.LastCallDate == "" || this.state.LastCallDate == null || this.state.LastCallDate == undefined ? "" : moment(
                            new Date(this.state.LastCallDate).toDateString()
                          ).format("MM/DD/YYYY")}
                          {""}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

                {/*Call notes table */}

                <div className="paddcols px-4 mt-3">
              <div className="row p-3 pb-0">
                <div className="col padd-0 d-flex align-items-center">
                  <h3 className=" float-left pb-4 addpotential_header">
                    Call Notes
                  </h3>
                </div>
              </div>
              <div className="row">
              <div className="col-sm-12 col-md-6">
                  <label className="textlabelte">
                    Show
                    <select
                      name="tbl_meeting_length"
                      aria-controls="tbl_meeting"
                 onChange={this.ChangeRowSelectedCallNote}
                      className="form-control form-control-sm"
                      value={this.state.CallNoteRowsPerPage}
                    >
                    {CommonConstants.show_rows && CommonConstants.show_rows.map((value) => (
  <option key={value} value={value}>{value}</option>
))}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                  <label className="textlabelte">
                    Search:{" "}
                    <input
                      type="search"
                      id="SearchCallNote"
                      onKeyPress={(event) => this.RequestSeachCallNoteList(event)}
                      className="form-control form-control-sm ml-2"
                      placeholder=""
                      aria-controls="tbl_meeting"
                    />
                  </label>
                </div>
              </div>
              <div className="table-bordered">
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Call Notes</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
              {this.state.CallNoteData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={1} align="center">No data available in table</TableCell>
                </TableRow>
              ) : (
                this.state.CallNoteData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row?.CallNotes}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {this.state.CallNoteSflag ? (
                <div class="row">
                  <Pagination
                    component="div"
                    count={this.state.CallNoteCountPage}
                    // onChange={this.CallNoteHandleChangePage}
                    showFirstButton
                    showLastButton
                  />
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.CallNoteRlen == 0
                        ? 0
                        : (this.state.CallNotePage - 1) * this.state.CallNoteRowsPerPage + 1}{" "}
                      to{" "}
                      {this.state.CallNotePage * this.state.CallNoteRowsPerPage > this.state.CallNoteRlen
                        ? this.state.CallNoteRlen
                        : this.state.CallNotePage * this.state.CallNoteRowsPerPage}{" "}
                      of {this.state.CallNoteRlen} entries (filtered from{" "}
                      {this.state.CallNoteFlen} total entries)
                    </p>
                  </div>
                </div>
              ) : (
                <div class="row">
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.CallNoteRlen == 0
                        ? 0
                        : (this.state.CallNotePage - 1) * this.state.CallNoteRowsPerPage + 1}{" "}
                      to{" "}
                      {this.state.CallNotePage * this.state.CallNoteRowsPerPage > this.state.CallNoteRlen
                        ? this.state.CallNoteRlen
                        : this.state.CallNotePage * this.state.CallNoteRowsPerPage}{" "}
                      of {this.state.CallNoteRlen} entries
                    </p>
                  </div>
                  <div class="col pageright">
                    <Pagination
                      component="div"
                      count={this.state.CallNoteCountPage}
                      // onChange={this.CallNoteHandleChangePage}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              )}
            </div>
            {/* Notes Table */}
            <div className="paddcols px-4 mt-3">
              <div className="row p-3 pb-0">
                <div className="col padd-0 d-flex align-items-center">
                  <h3 className=" float-left pb-4 addpotential_header">Notes</h3>
                </div>
                <div className="col text-right">
                  <button onClick={this.handleClick} className="btn btngroup m-btn ">
                    <i class="la la-plus la-icon-font-size-13"></i>
                    <span className="Mobile_button">Add</span>
                  </button>
                  <Popup open={this.state.ShowNotePopup}>
                    <div>
                      <div className="modal-black"></div>
                      <div className="filterPopup largerPopup">
                        <div className="paddingboxTerms">
                          <div className="modal-header py-3 px-3">
                            <h4 className="mb-0">Note</h4>
                          </div>
                          <div className="modal-body p-5">
                            <div class="row mb-3">
                              <label class="col-lg-2 col-form-label text-left">
                                Title
                              </label>
                              <div class="col-lg-10 text-left">
                                <input
                                  class="form-control m-input"
                                  data-val="true"
                                  data-val-remote="Title already exist."
                                  data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                  data-val-required="Please enter title"
                                  id="TitlePNote"
                                  name="TitleNote"
                                  placeholder="Enter title"
                                  onBlur={this.HandleChangePNotesAdd.bind(
                                    this,
                                    "TitlePNote"
                                  )}
                                  type="text"
                                />
                                <span style={{ color: "red" }}>
                                  {this.state.Errors["TitlePNote"]}
                                </span>
                                {this.state.TitleAvailableNotes && (
                                  <span style={{ color: "red" }}>
                                    Title already exist.
                                  </span>
                                )}
                              </div>
                            </div>

                            <div class="row">
                              <label class="col-lg-2 col-form-label text-left">
                                Note
                              </label>
                              <div class="col-lg-10 text-left">
                                <textarea
                                  class="form-control m-input minheight390"
                                  data-val="true"
                                  data-val-remote="Title already exist."
                                  data-val-required="Please enter title"
                                  id="NotesPNote"
                                  name="NotesPNote"
                                  placeholder="Enter note"
                                  type="text"
                                >
                                  {" "}
                                </textarea>
                                <span style={{ color: "red" }}>
                                  {this.state.Errors["NotesPNote"]}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="modal-content bordernone text-right pt-0">
                            <div class="row">
                              <div class="col-lg-12 pull-right">
                                <a
                                  id="backtolist"
                                  class="btn btn-secondary mr-2"
                                  onClick={this.handleClick}
                                >
                                  cancel
                                </a>
                                <button
                                  disabled={this.state.ProspectNoteBtnDisabled}
                                  class="btn btn-primary btn-lightgreen mr-1"
                                  href="javascript:void(0);"
                                  value="Save"
                                  onClick={this.ProspectNoteSave}
                                >
                                  <i class="la la-save"></i> Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </Popup>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <label className="textlabelte">
                    Show
                    <select
                      name="tbl_meeting_length"
                      aria-controls="tbl_meeting"
                      onChange={this.ChangeRowSelected}
                      className="form-control form-control-sm"
                      value={this.state.NoteRowsPerPage}
                    >
                      {CommonConstants.show_rows.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                  <label className="textlabelte">
                    Search:{" "}
                    <input
                      type="search"
                      id="SearchNote"
                      onKeyPress={(event) => this.RequestSearch(event)}
                      className="form-control form-control-sm ml-2"
                      placeholder=""
                      aria-controls="tbl_meeting"
                    />
                  </label>
                </div>
              </div>
              <div className="table-bordered">
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.NoteSortData("Title");
                            }}
                          >
                            Title
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.NoteSortData("Note");
                            }}
                          >
                            Notes
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.NoteSortData("Type");
                            }}
                          >
                            Type
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.NoteSortData("CreatedDate");
                            }}
                          >
                            Created Date
                          </a>
                        </TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.NoteRows?.length === 0 ? (
                        <p className="text-center">No data available in table</p>
                      ) : (
                        this.state.NoteRows?.map((row) => (
                          <TableRow>
                            <TableCell>{row?.Title}</TableCell>
                            <TableCell>{row?.Note}</TableCell>
                            <TableCell>{row?.PageName}</TableCell>
                            <TableCell>
                              {moment(
                                new Date(row?.CreatedDate).toDateString()
                              ).format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell>
                              <a
                                onClick={() => {
                                  this.ProspectNoteEditBtn(row._id, row.PageName);
                                }}
                              >
                                <Popup
                                  trigger={
                                    <i class="la flaticon-edit-1 edit-icon"></i>
                                  }
                                  modal
                                  nested
                                >
                                  {(close) => (
                                    <div>
                                      <div className="modal-black"></div>
                                      <div className="filterPopup largerPopup">
                                        <div className="paddingboxTerms">
                                          <div className="modal-header py-3 px-3">
                                            <h4 className="mb-0">Note</h4>
                                          </div>
                                          <div className="modal-body p-5">
                                            <div class="row mb-3">
                                              <label class="col-lg-2 col-form-label text-left">
                                                Title
                                              </label>
                                              <div class="col-lg-10">
                                                <input
                                                  class="form-control m-input"
                                                  data-val="true"
                                                  data-val-remote="Title already exist."
                                                  data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                                  data-val-required="Please enter title"
                                                  id="TitlePEditNote"
                                                  name="TitlePEditNote"
                                                  placeholder="Enter title"
                                                  type="text"
                                                  onBlur={this.HandleChangePNotesEdit.bind(
                                                    this,
                                                    "TitlePEditNote"
                                                  )}

                                                />
                                                <span style={{ color: "red" }}>
                                                  {
                                                    this.state.Errors1[
                                                    "TitlePEditNote"
                                                    ]
                                                  }
                                                </span>
                                                {this.state.TitleAvailableNotes && (
                                                  <span style={{ color: "red" }}>
                                                    Title already exist.
                                                  </span>
                                                )}
                                              </div>
                                            </div>

                                            <div class="row">
                                              <label class="col-lg-2 col-form-label text-left">
                                                Note
                                              </label>
                                              <div class="col-lg-10">
                                                <textarea
                                                  class="form-control m-input minheight390"
                                                  data-val="true"
                                                  data-val-remote="Title already exist."
                                                  data-val-required="Please enter title"
                                                  id="NotesEditPNotes"
                                                  name="NotesEditPNotes"
                                                  placeholder="Enter note"
                                                  type="text"
                                                  value={
                                                    this.state.Fields[
                                                    "NotesEditPNotes"
                                                    ]
                                                  }
                                                >
                                                  {" "}
                                                </textarea>
                                                <span style={{ color: "red" }}>
                                                  {
                                                    this.state.Errors1[
                                                    "NotesEditPNotes"
                                                    ]
                                                  }
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="modal-content bordernone text-right">
                                            <div class="row">
                                              <div class="col-lg-12 pull-right">
                                                <a
                                                  id="backtolist"
                                                  class="btn btn-secondary mr-2"
                                                  onClick={() => {
                                                    close(); this.Handlevalidationfornotesedit();
                                                  }}
                                                >
                                                  cancel
                                                </a>
                                                <button
                                                  id="submitupdate"
                                                  class="btn btn-primary btn-lightgreen mr-1"
                                                  href="javascript:void(0);"
                                                  value="Save"

                                                  onClick={(e) => {
                                                    this.ProspectNoteUpdate(e, () => {
                                                      close(); // Call the close function when the update is successful
                                                    });
                                                  }}
                                                >
                                                  <i class="la la-save"></i> Save
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Popup>
                              </a>
                              <a
                                className="btn-eyesicon"
                                onClick={() => {
                                  this.ProspectNoteDelete(row._id, row.PageName);
                                }}
                              >
                                <i class="la flaticon-delete-1 delete-icon"></i>
                              </a>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {this.state.NoteSflag ? (
                <div class="row">
                  <Pagination
                    component="div"
                    count={this.state.NoteCountPage}
                    onChange={this.HandleChangePage}
                    showFirstButton
                    showLastButton
                  />
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.NoteRlen == 0
                        ? 0
                        : (this.state.NotePage - 1) * this.state.NoteRowsPerPage + 1} to{" "}
                      {this.state.NotePage * this.state.NoteRowsPerPage > this.state.NoteRlen
                        ? this.state.NoteRlen
                        : this.state.NotePage * this.state.NoteRowsPerPage} of {this.state.NoteRlen}{" "} entries (filtered from {this.state.NoteFlen} total entries)
                    </p>
                  </div>
                </div>
              ) : (
                <div class="row">
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.NoteRlen == 0
                        ? 0
                        : (this.state.NotePage - 1) * this.state.NoteRowsPerPage + 1} to{" "}
                      {this.state.NotePage * this.state.NoteRowsPerPage > this.state.NoteRlen
                        ? this.state.NoteRlen
                        : this.state.NotePage * this.state.NoteRowsPerPage}
                     {" "} of {this.state.NoteRlen} entries
                    </p>
                  </div>
                  <div class="col pageright">
                    <Pagination
                      component="div"
                      count={this.state.NoteCountPage}
                      onChange={this.HandleChangePage}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              )}
            </div>

          {/* Potential Meeting Table */}
          <div className="paddcols px-4 mt-3">
              <div className="row p-3 pb-0">
                <div className="col padd-0 d-flex align-items-center">
                  <h3 className=" float-left pb-4 addpotential_header">
                Potential Meetings
                  </h3>
                </div>

                <div className="col text-right">
                  <button onClick={() => { this.PotentialMeetingsAdd() }} className="btn btngroup m-btn ">
                    <i class="la la-plus la-icon-font-size-13"></i>
                    <span className="Mobile_button">Add</span>
                  </button>
                </div>
              </div>
              <div className="row padt-25">
                <div className="col-sm-12 col-md-6">
                  <label className="textlabelte">
                    Show
                    <select
                      name="tbl_meeting_length"
                      aria-controls="tbl_meeting"
                      className="form-control form-control-sm"
                      onChange={this.ChangeRowSelectedPotentialMeeting}
                      value={this.state.PMRowsPerPage}
                    >
                      {CommonConstants.show_rows.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                  <label className="textlabelte">
                    Search:{" "}
                    <input
                      type="search"
                      id="searchMeeting"
                      onKeyPress={(event) => this.RequestSearchPotentialMeeting(event)}
                      className="form-control form-control-sm ml-2"
                      placeholder=""
                      aria-controls="tbl_meeting"
                    />
                  </label>
                </div>
              </div>
              <div className="table-bordered">
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.PotentialMeetingSortData("FirstName");
                            }}
                          >
                            Contact Name
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.PotentialMeetingSortData("Email");
                            }}
                          >
                            Email
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.PotentialMeetingSortData("Company");
                            }}
                          >
                            Company
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.PotentialMeetingSortData("Title");
                            }}
                          >
                            Title
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.PotentialMeetingSortData("EngagamentStatusID");
                            }}
                          >
                            Engagement Status
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.PotentialMeetingSortData("MeetingSourceID");
                            }}
                          >
                            Meeting Sources
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.PotentialMeetingSortData("MeetingOwnerID");
                            }}
                          >
                            Owner
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.PotentialMeetingSortData(
                                "ResponderEmailAccount"
                              );
                            }}
                          >
                            Reminder Email Account
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.PotentialMeetingSortData("CreatedDate");
                            }}
                          >
                            Created Date
                          </a>
                        </TableCell>
                        <TableCell>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state?.PotentialMeetingData &&
                        this.state.PotentialMeetingData?.length === 0 ? (
                        <p className="text-center">No data available in table</p>
                      ) : (
                        this.state?.PotentialMeetingData &&
                        this.state?.PotentialMeetingData.map((row34) => (
                          <>

                            <TableCell align="center">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => this.toggleMeeting(row34._id)}

                              >
                                {this.state.Open[row34._id] ? (
                                  <i
                                    className="fa fa-minus-circle minusl"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-plus-circle plusbl"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell>{row34?.ContactName}</TableCell>
                              <TableCell>{row34?.Email}</TableCell>
                              <TableCell>{row34?.Company}</TableCell>
                              <TableCell>{row34?.Title}</TableCell>
                              <TableCell>{row34?.EnguagementStatus}</TableCell>
                              <TableCell>{row34?.MeetingSource}</TableCell>
                              <TableCell>{row34?.Owner}</TableCell>
                              <TableCell>{row34?.ReminderEmailAccount}</TableCell>
                              <TableCell>
                                {moment(
                                  new Date(row34?.CreatedDate).toDateString()
                                ).format("MM/DD/YYYY")}
                              </TableCell>
                              <TableCell>
                                <a className="btn-eyesicon"
                                  onClick={() => {
                                    this.PotentialMeetingsEdit(row34._id);
                                  }}
                                ><i class="la flaticon-edit-1 edit-icon"></i></a>
                              </TableCell>
                            <TableRow>
                              <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={12}
                              >
                                <Collapse
                                  in={this.state.Open[row34._id]}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  {this.state.Open[row34._id] && (
                                    <Box margin={1} className="innertables">
                                      <Table size="small" aria-label="purchases">
                                        <TableHead></TableHead>
                                        {this.state.PMNotes.map((note) =>
                                          note?.PotentialMeetingID ==
                                            row34?._id ? (
                                            <TableRow>
                                              <TableCell><strong>{note?.Title}</strong></TableCell>
                                              <TableCell> <strong>{moment(
                                                new Date(note?.CreatedDate).toDateString()
                                              ).format("MM/DD/YYYY")}</strong>      </TableCell>
                                              <TableCell scope="row">
                                                {note.Note}
                                              </TableCell>
                                              <TableCell
                                                className="labeltds"
                                                scope="row"
                                              ></TableCell>
                                              <TableCell
                                                className="labeltds"
                                                scope="row"
                                              ></TableCell>
                                            </TableRow>
                                          ) : null
                                        )}
                                        {!this.state?.PMNotes.some(
                                          (note) =>
                                            note?.PotentialMeetingID === row34?._id
                                        ) && (
                                            <TableRow>
                                              <TableCell colSpan={4}>
                                              No notes available
                                              </TableCell>
                                            </TableRow>
                                          )}
                                      </Table>
                                    </Box>
                                  )}
                                </Collapse>
                              </TableCell>
                            </TableRow>

                          </>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {this.state.PMSflag ? (
                <div class="row">
                  <Pagination
                    component="div"
                    count={this.state.PMCountPage}
                    onChange={this.HandleChangePagePotentialMeeting}
                    showFirstButton
                    showLastButton
                  />
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.PMRlen == 0
                        ? 0
                        : (this.state.PMPage - 1) * this.state.PMRowsPerPage +
                        1}{" "}
                      to{" "}
                      {this.state.PMPage * this.state.PMRowsPerPage >
                        this.state.PMRlen
                        ? this.state.PMRlen
                        : this.state.PMPage * this.state.PMRowsPerPage}{" "}
                      of {this.state.PMRlen} entries (filtered from{" "}
                      {this.state.PMFlen} total entries)
                    </p>
                  </div>
                </div>
              ) : (
                <div class="row">
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.PMRlen == 0
                        ? 0
                        : (this.state.PMPage - 1) * this.state.PMRowsPerPage +
                        1}{" "}
                      to{" "}
                      {this.state.Page * this.state.PMRowsPerPage >
                        this.state.PMRlen
                        ? this.state.PMRlen
                        : this.state.PMPage * this.state.PMRowsPerPage}{" "}
                      of {this.state.PMRlen} entries
                    </p>
                  </div>
                  <div class="col pageright">
                    <Pagination
                      component="div"
                      count={this.state.PMCountPage}
                      onChange={this.HandleChangePagePotentialMeeting}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Meeting Table*/}
            <div className="paddcols px-4 mt-3">
              <div className="row p-3 pb-0">
                <div className="col padd-0 d-flex align-items-center">
                  <h3 className=" float-left pb-4 addpotential_header">
                    Meetings
                  </h3>
                </div>

                <div className="col text-right">
                  <button onClick={() => { this.MeetingsAdd() }} className="btn btngroup m-btn ">
                    <i class="la la-plus la-icon-font-size-13"></i>
                    <span className="Mobile_button">Add</span>
                  </button>
                </div>
              </div>
              <div className="row padt-25">
                <div className="col-sm-12 col-md-6">
                  <label className="textlabelte">
                    Show
                    <select
                      name="tbl_meeting_length"
                      aria-controls="tbl_meeting"
                      className="form-control form-control-sm"
                      onChange={this.ChangeRowSelectedMeeting}
                      value={this.state.MRowsPerPage}
                    >
                      {CommonConstants.show_rows.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                  <label className="textlabelte">
                    Search:{" "}
                    <input
                      type="search"
                      id="searchMeeting"
                      onKeyPress={(event) => this.RequestSearchMeeting(event)}
                      className="form-control form-control-sm ml-2"
                      placeholder=""
                      aria-controls="tbl_meeting"
                    />
                  </label>
                </div>
              </div>
              <div className="table-bordered">
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.MeetingSortData("FirstName");
                            }}
                          >
                            Contact Name
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.MeetingSortData("Email");
                            }}
                          >
                            Email
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.MeetingSortData("Company");
                            }}
                          >
                            Company
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.MeetingSortData("Title");
                            }}
                          >
                            Title
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.MeetingSortData("Status");
                            }}
                          >
                            Meeting Status
                          </a>{" "}
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.MeetingSortData("MeetingSourceName");
                            }}
                          >
                            {" "}
                            Meeting Sources
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.MeetingSortData("MeetingOwnerName");
                            }}
                          >
                            Owner
                          </a>{" "}
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.MeetingSortData("EventStartDt");
                            }}
                          >
                            Meeting Date
                          </a>{" "}
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.MeetingSortData("CreatedDt");
                            }}
                          >
                            Meeting Set Date
                          </a>{" "}
                        </TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state?.MeetingData &&
                        this.state.MeetingData?.length === 0 ? (
                        <p className="text-center">No data available in table</p>
                      ) : (
                        this.state?.MeetingData &&
                        this.state?.MeetingData.map((row34) => (
                          <>

                            <TableCell align="center">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => this.toggleMeeting1(row34._id)}

                              >
                                {this.state.Open[row34._id] ? (
                                  <i
                                    className="fa fa-minus-circle minusl"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-plus-circle plusbl"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell>{row34?.ContactName}</TableCell>
                            <TableCell>{row34?.Email}</TableCell>
                            <TableCell>{row34?.Company}</TableCell>
                            <TableCell>{row34?.Title}</TableCell>
                            <TableCell>{row34?.MeetingStatus}</TableCell>
                            <TableCell>{row34?.MeetingSource}</TableCell>
                            <TableCell>{row34?.MeetingOwner}</TableCell>
                            <TableCell>{row34?.MeetingDate != null ? moment(row34?.MeetingDate).format("MM/DD/YYYY hh:mm A"): ""}</TableCell>
                            <TableCell>{row34?.MeetingSetDate != null? moment(row34?.MeetingSetDate).format("MM/DD/YYYY"): ""}</TableCell>
                            <TableCell>
                              <a className="btn-eyesicon"
                                onClick={() => {
                                  this.MeetingsEdit(row34?._id);
                                }}
                              ><i class="la flaticon-edit-1 edit-icon"></i></a>
                            </TableCell>
                            <TableRow>
                              <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={12}
                              >
                                <Collapse
                                  in={this.state.Open[row34._id]}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  {this.state.Open[row34._id] && (
                                    <Box margin={1} className="innertables">
                                      <Table size="small" aria-label="purchases">
                                        <TableHead></TableHead>
                                        {this.state.MNotes.map((note) =>
                                          note?.MeetingID ==
                                            row34?._id ? (
                                            <TableRow>
                                              <TableCell><strong>{note?.Title}</strong></TableCell>
                                              <TableCell> <strong>{moment(
                                                new Date(note?.CreatedDate).toDateString()
                                              ).format("MM/DD/YYYY")}</strong>      </TableCell>
                                              <TableCell scope="row">
                                                {note.Note}
                                              </TableCell>
                                              <TableCell
                                                className="labeltds"
                                                scope="row"
                                              ></TableCell>
                                              <TableCell
                                                className="labeltds"
                                                scope="row"
                                              ></TableCell>
                                            </TableRow>
                                          ) : null
                                        )}
                                        {!this.state?.MNotes.some(
                                          (note) =>
                                            note?.MeetingID === row34?._id
                                        ) && (
                                            <TableRow>
                                              <TableCell colSpan={4}>
                                                No data available
                                              </TableCell>
                                            </TableRow>
                                          )}
                                      </Table>
                                    </Box>
                                  )}
                                </Collapse>
                              </TableCell>
                            </TableRow>

                          </>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {this.state.MSflag ? (
                <div class="row">
                  <Pagination
                    component="div"
                    count={this.state.MCountPage}
                    onChange={this.HandleChangePageMeeting}
                    showFirstButton
                    showLastButton
                  />
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.MRlen == 0
                        ? 0
                        : (this.state.MPage - 1) * this.state.MRowsPerPage +
                        1}{" "}
                      to{" "}
                      {this.state.MPage * this.state.MRowsPerPage >
                        this.state.MRlen
                        ? this.state.MRlen
                        : this.state.MPage * this.state.MRowsPerPage}{" "}
                      of {this.state.MRlen} entries (filtered from{" "}
                      {this.state.MFlen} total entries)
                    </p>
                  </div>
                </div>
              ) : (
                <div class="row">
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.MRlen == 0
                        ? 0
                        : (this.state.MPage - 1) * this.state.MRowsPerPage +
                        1}{" "}
                      to{" "}
                      {this.state.Page * this.state.MRowsPerPage >
                        this.state.MRlen
                        ? this.state.MRlen
                        : this.state.MPage * this.state.MRowsPerPage}{" "}
                      of {this.state.MRlen} entries
                    </p>
                  </div>
                  <div class="col pageright">
                    <Pagination
                      component="div"
                      count={this.state.MCountPage}
                      onChange={this.HandleChangePageMeeting}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              )}
            </div>

                        {/* Step History */}
            <div className="paddcols px-4 mt-3">
              <div className="row p-3 pb-0">
                <div className="col padd-0 d-flex align-items-center">
                  <h3 className=" float-left pb-4 addpotential_header">
                  Step History
                  </h3>
                </div>
              </div>
              <div className="row padt-25">
                <div className="col-sm-12 col-md-6">
                  <label className="textlabelte">
                    Show
                    <select
                      name="tbl_meeting_length"
                      aria-controls="tbl_meeting"
                      className="form-control form-control-sm"
                      onChange={this.ChangeRowSelectedMeeting}
                      value={this.state.STHRowsPerPage}
                    >
                      {CommonConstants.show_rows.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                  <label className="textlabelte">
                    Search:{" "}
                    <input
                      type="search"
                      id="searchMeeting"
                      onKeyPress={(event) => this.RequestSearchMeeting(event)}
                      className="form-control form-control-sm ml-2"
                      placeholder=""
                      aria-controls="tbl_meeting"
                    />
                  </label>
                </div>
              </div>
              <div className="table-bordered">
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.MeetingSortData("CampaignName");
                            }}
                          >
                            Campaign Name
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.MeetingSortData("Days");
                            }}
                          >
                            Days
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.MeetingSortData("Subject");
                            }}
                          >
                            Subject
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.MeetingSortData("Sent");
                            }}
                          >
                            Sent
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.MeetingSortData("Open");
                            }}
                          >
                            Open
                          </a>{" "}
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.MeetingSortData("Reply");
                            }}
                          >
                            {" "}
                            Reply
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.MeetingSortData("SoftBounce");
                            }}
                          >
                            Soft Bounce
                          </a>{" "}
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.MeetingSortData("Hard Bounce");
                            }}
                          >
                            Hard Bounce
                          </a>{" "}
                        </TableCell>
 
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state?.STHData &&
                        this.state.STHData?.length === 0 ? (
                        <p className="text-center">No data available in table</p>
                      ) : (
                        this.state?.STHData &&
                        this.state?.STHData.map((row34) => (
                          <>

                            <TableCell align="center">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => this.toggleMeeting1(row34._id)}

                              >
                                {this.state.Open[row34._id] ? (
                                  <i
                                    className="fa fa-minus-circle minusl"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-plus-circle plusbl"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell>{row34?.CampaignName}</TableCell>
                            <TableCell>{row34?.DayOfSendMail}</TableCell>
                            <TableCell>{row34?.Subject}</TableCell>
                            <TableCell>{row34?.TotalSent}</TableCell>
                            <TableCell>{row34?.TotalOpen}</TableCell>
                            <TableCell>{row34?.TotalReply}</TableCell>
                            <TableCell>{row34?.TotalSoftBounce}</TableCell>
                            <TableCell>{row34?.TotalHardBounce}</TableCell>                
                            <TableRow>
                              <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={12}
                              >
                                  <Collapse
                                  in={this.state.Open[row34._id]}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  {this.state.Open[row34._id] && (
                                    <Box margin={1} className="innertables">
                                      {this.state.STHData.length > 0 ? (
                                        <Table size="small" aria-label="purchases">
                                          <TableHead>
                                          <TableCell>
                          <a
                   
                          ><strong>
                    Subject
                    </strong> </a>
                        </TableCell>
                        <TableCell>
                          <a
                   
                          ><strong>
                    Body
                    </strong> </a>
                        </TableCell>
                                          </TableHead>
                                          {this.state.STHData.map((note) =>
                                            note._id === row34._id ? (
                                              <TableRow>
                                                <TableCell><strong>{note?.Subject}</strong></TableCell>
                                            
                                                <TableCell  style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word"
                               }}><div dangerouslySetInnerHTML={{ __html: note?.Body }} /></TableCell>
                                             
                                              </TableRow>
                                            ) : null
                                          )}

                                          {!this.state.STHData.some(
                                            (note) =>
                                            note?._id === row34?._id
                                          )
                                          }
                                        </Table>
                                      ) : (
                                        <p className="text-center">No data available in table</p>
                                      )}
                                    </Box>
                                  )}
                                </Collapse>
                              </TableCell>
                            </TableRow>

                          </>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {this.state.STHSflag ? (
                <div class="row">
                  <Pagination
                    component="div"
                    count={this.state.STHCountPage}
                    onChange={this.HandleChangePageMeeting}
                    showFirstButton
                    showLastButton
                  />
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.STHRlen == 0
                        ? 0
                        : (this.state.STHPage - 1) * this.state.STHRowsPerPage +
                        1}{" "}
                      to{" "}
                      {this.state.STHPage * this.state.STHRowsPerPage >
                        this.state.STHRlen
                        ? this.state.STHRlen
                        : this.state.STHPage * this.state.STHRowsPerPage}{" "}
                      of {this.state.STHRlen} entries (filtered from{" "}
                      {this.state.STHFlen} total entries)
                    </p>
                  </div>
                </div>
              ) : (
                <div class="row">
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.STHRlen == 0
                        ? 0
                        : (this.state.STHPage - 1) * this.state.STHRowsPerPage +
                        1}{" "}
                      to{" "}
                      {this.state.STHPage * this.state.STHRowsPerPage >
                        this.state.STHRlen
                        ? this.state.STHRlen
                        : this.state.STHPage * this.state.STHRowsPerPage}{" "}
                      of {this.state.STHRlen} entries
                    </p>
                  </div>
                  <div class="col pageright">
                    <Pagination
                      component="div"
                      count={this.state.STHCountPage}
                      onChange={this.HandleChangePageMeeting}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              )}
            </div>
            {/* Campaign History Table*/}

            <div className="paddcols px-4 mt-3">
              <div className="row p-3 pb-0">
                <div className="col padd-0 d-flex align-items-center">
                  <h3 className=" float-left pb-4 addpotential_header">
                    Campaign History
                  </h3>
                </div>
                <div className="col text-right">
                  <button
                    onClick={() => { this.CampaignAdd() }}
                    className="btn btngroup m-btn ">
                    <i class="la la-plus la-icon-font-size-13"></i>
                    <span className="Mobile_button">Add</span>
                  </button>
                </div>
              </div>
              <div className="row padt-25">
                <div className="col-sm-12 col-md-6">
                  <label className="textlabelte">
                    Show
                    <select
                      name="tbl_meeting_length"
                      aria-controls="tbl_meeting"
                      className="form-control form-control-sm"
                      onChange={this.ChangeRowSelectedCampaignHistory}
                      value={this.state.CAMRowsPerPage}
                    >
                      {CommonConstants.show_rows.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                  <label className="textlabelte">
                    Search:{" "}
                    <input
                      type="search"
                      id="searchcampaigns"
                      onKeyPress={(event) =>
                        this.RequestSearchCampaignHistory(event)
                      }
                      className="form-control form-control-sm ml-2"
                      placeholder=""
                      aria-controls="tbl_meeting"
                    />
                  </label>
                </div>
              </div>
              <div className="table-bordered">
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell><a onClick={() => {
                          this.CampaignHistorySortData("CampaignName")
                        }}>Name</a></TableCell>
                        <TableCell><a onClick={() => {
                          this.CampaignHistorySortData("CreatedDate")
                        }}>Created Date</a></TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.CampaignHistoryData &&
                        this.state.CampaignHistoryData.length === 0 ? (
                        <p className="text-center">No data available in table</p>
                      ) : (
                        this.state.CampaignHistoryData &&
                        this.state.CampaignHistoryData.map((row34) => (
                          <TableRow>
                            <TableCell>{row34?.CampaignName}</TableCell>
                            <TableCell>
                              {moment(
                                new Date(row34?.CreatedDate).toDateString()
                              ).format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell>
                              <a className="btn-eyesicon"
                                onClick={() => {
                                  this.EditCampaignHistory(row34.CampaignID);
                                }}
                              ><i class="la flaticon-edit-1 edit-icon"></i></a>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {this.state.CAMSflag ? (
                <div class="row">
                  <Pagination
                    component="div"
                    count={this.state.CAMCountPage}
                    onChange={this.HandleChangePageCampaignHistory}
                    showFirstButton
                    showLastButton
                  />
                  <div class="col dataTables_info">
                    <p>
                      Showing
                      {this.state.CAMRlen == 0
                        ? 0
                        : (this.state.CAMPage - 1) * this.state.CAMRowsPerPage +
                        1}{" "}
                      to{" "}
                      {this.state.CAMPage * this.state.CAMRowsPerPage >
                        this.state.CAMRlen
                        ? this.state.CAMRlen
                        : this.state.CAMPage * this.state.CAMRowsPerPage}{" "}
                      of {this.state.CAMRlen} entries (filtered from{" "}
                      {this.state.CAMFlen} total entries)
                    </p>
                  </div>
                </div>
              ) : (
                <div class="row">
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.CAMRlen == 0
                        ? 0
                        : (this.state.CAMPage - 1) * this.state.CAMRowsPerPage +
                        1}{" "}
                      to{" "}
                      {this.state.CAMPage * this.state.CAMRowsPerPage >
                        this.state.CAMRlen
                        ? this.state.CAMRlen
                        : this.state.CAMPage * this.state.CAMRowsPerPage}{" "}
                      of {this.state.CAMRlen} entries
                    </p>
                  </div>
                  <div class="col pageright">
                    <Pagination
                      component="div"
                      count={this.state.CAMCountPage}
                      onChange={this.HandleChangePageCampaignHistory}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              )}
            </div>
            {/* Lists */}
            <div className="paddcols px-4 mt-3">
              <div className="row p-3 pb-0">
                <div className="col padd-0 d-flex align-items-center">
                  <h3 className=" float-left pb-4 addpotential_header">Lists</h3>
                </div>
                <div className="col text-right">
                  <button onClick={() => { this.ListsAdd() }} className="btn btngroup m-btn ">
                    <i class="la la-plus la-icon-font-size-13"></i>
                    <span className="Mobile_button">Add</span>
                  </button>
                </div>
              </div>
              <div className="row padt-25">
                <div className="col-sm-12 col-md-6">
                  <label className="textlabelte">
                    Show
                    <select
                      name="tbl_meeting_length"
                      aria-controls="tbl_meeting"
                      className="form-control form-control-sm"
                      onChange={this.ChangeRowSelectedList}
                      value={this.state.RowsPerPageL}
                    >
                      {CommonConstants.show_rows.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                  <label className="textlabelte">
                    Search:{" "}
                    <input
                      type="search"
                      id="searchlist"
                      onKeyPress={(event) => this.RequestSearchList(event)}
                      className="form-control form-control-sm ml-2"
                      placeholder=""
                      aria-controls="tbl_meeting"
                    />
                  </label>
                </div>
              </div>
              <div className="table-bordered">
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.ListsSortData("Name");
                            }}
                          >
                            Name
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.ListsSortData("Created Date");
                            }}
                          >
                            Created Date
                          </a>
                        </TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.ListRows && this.state.ListRows.length === 0 ? (
                        <p className="text-center">No data available in the table</p>
                      ) : (
                        this.state.ListRows &&
                        this.state.ListRows.map((row34) => {
                          // Extracting only the file name from the full Name using a dynamic regular expression
                          const fileNameMatch = row34 && row34?.Name.match(/^([a-zA-Z0-9_\s]+?)\d+\.(csv)$/);
                          const fileName = fileNameMatch ? fileNameMatch[1] : '';

                          return (
                            <TableRow key={row34?._id}>
                              <TableCell>{row34?.Name}</TableCell>
                              <TableCell>
                                {row34 &&
                                  moment.utc(new Date(row34?.CreatedDate)).format(
                                    "MM/DD/YYYY"
                                  )}
                              </TableCell>
                              <TableCell>
                                <a
                                  onClick={() => {
                                    this.ListsEdit(row34 && row34._id);
                                  }}
                                >
                                  <i class="la flaticon-edit-1 edit-icon"></i>
                                </a>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )}


                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {this.state.SflagL ? (
                <div class="row">
                  <Pagination
                    component="div"
                    count={this.state.LCountPage}
                    onChange={this.HandleChangePageList}
                    showFirstButton
                    showLastButton
                  />
                  <div class="col dataTables_info">
                    <p>
                      Showing
                      {this.state.LRlen == 0
                        ? 0
                        : (this.state.PageL - 1) * this.state.RowsPerPageL +
                        1}
                      to
                      {this.state.PageL * this.state.RowsPerPageL >
                        this.state.LRlen
                        ? this.state.LRlen
                        : this.state.PageL * this.state.RowsPerPageL}{" "}
                      of {this.state.LRlen} entries (filtered from{" "}
                      {this.state.LFlen} total entries)
                    </p>
                  </div>
                </div>
              ) : (
                <div class="row">
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.LRlen == 0
                        ? 0
                        : (this.state.PageL - 1) * this.state.RowsPerPageL + 1}{" "}
                      to{" "}
                      {this.state.PageL * this.state.RowsPerPageL > this.state.LRlen
                        ? this.state.LRlen
                        : this.state.PageL * this.state.RowsPerPageL}{" "}
                      of {this.state.LRlen} entries
                    </p>
                  </div>
                  <div class="col pageright">
                    <Pagination
                      component="div"
                      count={this.state.LCountPage}
                      onChange={this.HandleChangePageList}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Contact status history */}
            <div className="paddcols p-4 mt-3">
              <div className="row p-3 pb-0">
                <div className="col padd-0 d-flex align-items-center">
                  <h3 className=" float-left addpotential_header">
                    Contact Status History
                  </h3>
                </div>
                <div className="col text-right"></div>
              </div>
              <div className="row padt-25">
                <div className="col-sm-12 col-md-6">
                  <label className="textlabelte">
                    Show
                    <select
                      name="tbl_meeting_length"
                      aria-controls="tbl_meeting"
                      className="form-control form-control-sm"
                      onChange={this.ChangeRowSelectedContactStatusHistory}
                      value={this.state.HRowsPerPage}
                    >
                      {CommonConstants.show_rows.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                  <label className="textlabelte">
                    Search:{" "}
                    <input
                      type="Search"
                      id="HSearch"
                      onKeyPress={(event) =>
                        this.RequestSearchContactHistory(event)
                      }
                      className="form-control form-control-sm ml-2"
                      placeholder=""
                      aria-controls="tbl_meeting"
                    />
                  </label>
                </div>
              </div>
              <div className="table-bordered">
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.ContactStatusHistorySortData("p1Status");
                            }}
                          >
                            Old Status
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.ContactStatusHistorySortData("p2Status");
                            }}
                          >
                            New Status
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.ContactStatusHistorySortData("ChangedDate");
                            }}
                          >
                            Change Date
                          </a>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.HRows?.length === 0 ? (
                        <p className="text-center">No data available in table</p>
                      ) : (
                        this.state.HRows?.map((row) => (
                          <TableRow>
                            <TableCell>{row?.p1Status}</TableCell>
                            <TableCell>{row?.p1Status}</TableCell>
                            <TableCell>{
                              row?.ChangedDate == null ? "" : moment.utc(
                                new Date(row?.ChangedDate)
                              ).format("MM/DD/YYYY")
                            }

                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {this.state.HSflag ? (
                <div class="row">
                  <Pagination
                    component="div"
                    page={this.state.HPage}
                    count={this.state.HCountPage}
                    onChange={this.HandleChangePageContactStatusHistory}
                    showFirstButton
                    showLastButton
                  />
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.HRlen == 0
                        ? 0
                        : (this.state.HPage - 1) * this.state.HRowsPerPage + 1}{" "}
                      to{" "}
                      {this.state.HPage * this.state.HRowsPerPage > this.state.HRlen
                        ? this.state.HRlen
                        : this.state.HPage * this.state.HRowsPerPage}{" "}
                      of {this.state.HRlen} entries (filtered from{" "}
                      {this.state.HFlen} total entries)
                    </p>
                  </div>
                </div>
              ) : (
                <div class="row">
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.HRlen == 0
                        ? 0
                        : (this.state.HPage - 1) * this.state.HRowsPerPage + 1}{" "}
                      to{" "}
                      {this.state.HPage * this.state.HRowsPerPage > this.state.HRlen
                        ? this.state.HRlen
                        : this.state.HPage * this.state.HRowsPerPage}{" "}
                      of {this.state.HRlen} entries
                    </p>
                  </div>
                  <div class="col pageright">
                    <Pagination
                      component="div"
                      page={this.state.HPage}
                      count={this.state.HCountPage}
                      onChange={this.HandleChangePageContactStatusHistory}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Call history Table*/}
            <div className="paddcols px-4 mt-3">
              <div className="row p-3 pb-0">
                <div className="col padd-0 d-flex align-items-center">
                  <h3 className=" float-left pb-4 addpotential_header">
                    Call History
                  </h3>
                </div>
              </div>
              <div className="row padt-25">
                <div className="col-sm-12 col-md-6">
                  <label className="textlabelte">
                    Show
                    <select
                      name="tbl_meeting_length"
                      aria-controls="tbl_meeting"
                      className="form-control form-control-sm"
                      onChange={this.ChangeRowSelectedCallHistory}
                      value={this.state.CRowsPerPage}
                    >
                      {CommonConstants.show_rows.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                    entries
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 full-right">
                  <label className="textlabelte">
                    Search:{" "}
                    <input
                      type="search"
                      id="searchcallhistory"
                      onKeyPress={(event) => this.RequestSearchCallHistory(event)}
                      className="form-control form-control-sm ml-2"
                      placeholder=""
                      aria-controls="tbl_meeting"
                    />
                  </label>
                </div>
              </div>
              <div className="table-bordered">
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {/* <TableCell>
                          <a
                            onClick={() => {
                              this.CallHistorySortData("Name");
                            }}
                          >
                            Name
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.CallHistorySortData("Title");
                            }}
                          >
                            Title
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.CallHistorySortData("Company");
                            }}
                          >
                            Company
                          </a>
                        </TableCell> */}
              
                        <TableCell>
                          <a
                            onClick={() => {
                              this.CallHistorySortData("Status");
                            }}
                          >
                            Result
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.CallHistorySortData("Duration");
                            }}
                          >
                            Duration
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.CallHistorySortData(
                                "RecordingFileCreatedDate"
                              );
                            }}
                          >
                            Call Time / Date
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.CallHistorySortData("RecordingFileName");
                            }}
                          >
                            Recording
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              this.CallHistorySortData("Note");
                            }}
                          >
                            Note
                          </a>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
  {this.state?.CallHistoryData && this.state.CallHistoryData.length === 0 ? (
    <p className="text-center">No data available in table</p>
  ) : (
    this.state?.CallHistoryData &&
    this.state?.CallHistoryData.map((row34) => {
      const changedDate = row34?.CreatedDate
        ? new Date(row34.CreatedDate).toLocaleString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            timeZone: 'UTC',
          })
        : '';
      return (
        <TableRow key={row34.id}>
          {/* <TableCell>{row34?.ColdCallersName}</TableCell>
          <TableCell>{row34?.Title}</TableCell>
          <TableCell>{row34?.Company}</TableCell> */}
          <TableCell>{row34?.Status}</TableCell>
          <TableCell>{row34?.Duration}</TableCell>
   
          <TableCell>{changedDate}</TableCell>
          <TableCell><audio controls style={{ outline: 'none' }} type='audio/mp3'><source src={CommonConstants.CallRecoingURL + row34?.RecordingFileName + ".mp3"} /></audio></TableCell>
          {/* <TableCell>{row34?.RecordingFileCreatedDate}</TableCell>
          <TableCell>{row34?.RecordingFileName}</TableCell> */}
          <TableCell>{row34?.Note}</TableCell>
        </TableRow>
      );
    })
  )}
</TableBody>

                  </Table>
                </TableContainer>
              </div>
              {this.state.MSflag ? (
                <div class="row">
                  <Pagination
                    component="div"
                    count={this.state.CCountPage}
                    onChange={this.HandleChangePageCallHistory}
                    showFirstButton
                    showLastButton
                  />
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.CRlen == 0
                        ? 0
                        : (this.state.CPage - 1) * this.state.CRowsPerPage +
                        1}{" "}
                      to{" "}
                      {this.state.CPage * this.state.CRowsPerPage >
                        this.state.CRlen
                        ? this.state.CRlen
                        : this.state.CPage * this.state.CRowsPerPage}{" "}
                      of {this.state.CRlen} entries (filtered from{" "}
                      {this.state.CFlen} total entries)
                    </p>
                  </div>
                </div>
              ) : (
                <div class="row">
                  <div class="col dataTables_info">
                    <p>
                      Showing{" "}
                      {this.state.CRlen == 0
                        ? 0
                        : (this.state.CPage - 1) * this.state.CRowsPerPage +
                        1}{" "}
                      to{" "}
                      {this.state.Page * this.state.CRowsPerPage >
                        this.state.CRlen
                        ? this.state.CRlen
                        : this.state.CPage * this.state.CRowsPerPage}{" "}
                      of {this.state.CRlen} entries
                    </p>
                  </div>
                  <div class="col pageright">
                    <Pagination
                      component="div"
                      count={this.state.CCountPage}
                      onChange={this.HandleChangePageCallHistory}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Contact Activity */}
              {/* Contact Activity */}
              <div class="paddcols px-4 mt-3 pb-4">
              <div class="row">
                <div class="col-md-12">
                  <h4 className="headertitlebd pl-0">Contact Activity</h4>
                </div>
              </div>
              
             {/* Call History Activity */}
             {/* {
              this.state.Callprospecthistorydata?.length > 0 ? 
              this.state?.Callprospecthistorydata?.map((item) => {
                const changedDate = item?.CreatedDate ? new Date(item?.CreatedDate).toLocaleString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                  timeZone: 'UTC'
                }) : '';
                return (
                  <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Call Status </b>  : -{" "}
                      <b>{item?.Status}</b> <b>{item?.Duration}</b>
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                );
              }) : null
             } */}
                       {/* External domain activity */}
             {/* {
              this.state.Externaldomaintreackdata?.length > 0 ? 
              this.state?.Externaldomaintreackdata?.map((item) => {
                const changedDate = item?.CreatedDate ? new Date(item?.CreatedDate).toLocaleString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                  timeZone: 'UTC'
                }) : '';
                return (
                  <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>External track </b> domain : -{" "}
                      <b>{item?.ExternelDomainClickTrackDoamin}</b> 
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                );
              }) : null
             } */}
                     {/* Sent Mail status activity */}
             {/* {
              this.state.Sentmailcampaigndata?.length > 0 ? 
              this.state?.Sentmailcampaigndata?.map((item) => {
                const changedDate = item?.CreatedDate ? new Date(item?.CreatedDate).toLocaleString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                  timeZone: 'UTC'
                }) : '';
                return (
                  <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Sent</b> email : -{" "}
                      <b>{item?.Campaign}</b> :- <b>{item?.StepNumber}</b>
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                );
              }) : null
             } */}
                 {/* Campaign status data */}
             
             {/* {
              this.state.CampaignStatushistory?.length > 0 ? 
              this.state?.CampaignStatushistory?.map((item) => {
                const changedDate = item?.CreatedDate ? new Date(item?.CreatedDate).toLocaleString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                  timeZone: 'UTC'
                }) : '';
                return (
                  <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Prospect Campaign Status</b> Update : -{" "}
                      <b>{item?.Status}</b>
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                );
              }) : null
             } */}
                       {/* Meeting status history data */}
             {/* {
              this.state.MeetingStatusHData?.length > 0 ? 
              this.state?.MeetingStatusHData?.map((item) => {
                const changedDate = item?.CreatedDate ? new Date(item?.CreatedDate).toLocaleString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                  timeZone: 'UTC'
                }) : '';
                return (
                  <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Meeting Status</b> Update : -{" "}
                      <b>{item?.MeetingStatus}</b>
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                );
              }) : null
             } */}
             
                           {/* Meeting created date */}
             {/* {
              this.state.MeetingCSAData?.length > 0 ? 
              this.state?.MeetingCSAData?.map((item) => {
                const changedDate = item?.CreatedDate ? new Date(item?.CreatedDate).toLocaleString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                  timeZone: 'UTC'
                }) : '';
                return (
                  <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>
 Meeting</b> Created : -{" "}
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                );
              }) : null
             } */}
                          {/* Potential meeting created date  */}
             {/* {
              this.state.PotentialMeetingCSAData?.length > 0 ? 
              this.state?.PotentialMeetingCSAData?.map((item) => {
                const changedDate = item?.CreatedDate ? new Date(item?.CreatedDate).toLocaleString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                  timeZone: 'UTC'
                }) : '';
                return (
                  <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>
Potential Meeting</b> Created : -{" "}
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                );
              }) : null
             } */}

             {/* Domain Blacklisted date */}
             {/* {
              this.state.ContactDomainBlacklistedData?.length > 0 ? 
              this.state?.ContactDomainBlacklistedData?.map((item) => {
                const changedDate = item?.CreatedDate ? new Date(item?.CreatedDate).toLocaleString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                  timeZone: 'UTC'
                }) : '';
                return (
                  <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-ban" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Contact Domain</b> Added to : -{" "}
                      <b>Blacklist</b>
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                );
              }) : null
             } */}
               {/* Prospect status history activity */}
             
             {/* {
              this.state.ProspectStatusHData?.length > 0 ? 
              this.state?.ProspectStatusHData?.map((item) => {
                const changedDate = item?.CreatedDate ? new Date(item?.CreatedDate).toLocaleString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                  timeZone: 'UTC'
                }) : '';
                return (
                  <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Prospect Status</b> Update : -{" "}
                      <b>{item?.Status}</b>
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                );
              }) : null
             } */}
             {/* {
              this.state.BlacklistEmailHistoryData?.length > 0 ? 
              this.state?.BlacklistEmailHistoryData?.map((item) => {
                const changedDate = item?.CreatedDate ? new Date(item?.CreatedDate).toLocaleString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                  timeZone: 'UTC'
                }) : '';
                return (
                  <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-ban" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Contact</b> Added to : -{" "}
                      <b>Blacklist</b>
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                );
              }) : null
             } */}

             {/* Full Contact Status Activity */}

             {this.state.ContactStatusAllActivity?.length > 0 ? 
              this.state.ContactStatusAllActivity?.map((value) => {
                const changedDate = value?.CreatedDate ? new Date(value?.CreatedDate).toLocaleString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                  timeZone: 'UTC'
                }) : '';
                if(value.IsContatEmailBlacklIstFlag === true){
                  return(
                    <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-ban" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Contact</b> Added to : -{" "}
                      <b>Blacklist</b>
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div> 
                  )
                }
                if(value.IsProspectStatusFlag === true){
                  return(
                    <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Prospect Status</b> Update : -{" "}
                      <b>{value?.Status}</b>
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                  )
                }
                if(value.IsContactDomainBlacklistedFlag === true){
                  return(
                    <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-ban" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Contact Domain</b> Added to : -{" "}
                      <b>Blacklist</b>
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                  )
                }
                if(value.IsPotentialMeetingFlag === true){
                  return(
                    <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>
Potential Meeting</b> Created : -{" "}
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                  )
                }
                if(value.IsMeetingFlag === true){
                  return(
                    <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>
 Meeting</b> Created : -{" "}
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                  )
                }

                if(value.IsMeetingStatusFlag === true){
                  return(
                    <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Meeting Status</b> Update : -{" "}
                      <b>{value?.MeetingStatus}</b>
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                  )
                }

                
                if(value.IsProspectCampaignStatusFlag === true){
                  return(
                    <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Prospect Campaign Status</b> Update : -{" "}
                      <b>{value?.Status}</b>
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                  )
                }

                if(value.IsSentEmailFlag === true){
                  return(
                    <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Sent</b> email : -{" "}
                      <b>{value?.Campaign}</b> :- <b>{value?.StepNumber}</b>
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                  )
                }

                if(value.IsExternalDomainTrackFlag === true){
                  return(
                    <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>External track </b> domain : -{" "}
                      <b>{value?.ExternelDomainClickTrackDoamin}</b> 
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                  )
                }

                if(value.IsContatCallHistoryFlag === true){
                  return(
                    <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Call duration </b>  : -{" "}{value?.Duration} <b>{value?.Status}</b> 
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                  )
                }
                
              }) : null
             }
              {/* List Activity */}
              {
                this.state?.IsListFalg === true ? 
                this.state?.ListData?.map((item) => {
                  const changedDate = item?.CreatedDate ? new Date(item?.CreatedDate).toLocaleString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                  timeZone: 'UTC'
                }) : '';
                return (
                  <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-user-plus" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div class="col-md-8">
                      <b>Contact</b> Created via : - <b>List Upload</b>
                    </div>
                    <div class="col-md-3">{changedDate}</div>
                  </div>
                );
                }) : <>
          
              
                <div class="row">
                    <div class="col-md-1">
                      <span>
                        <i class="fa fa-user-plus" aria-hidden="true"></i>
                      </span>
                    </div>
         

                    <div class="col-md-8">
                      <b>Contact</b> Created via : - <b>Manually</b>
                    </div>
                    <div class="col-md-3">{this.state.CreatedDate ? new Date(this.state.CreatedDate).toLocaleString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                  timeZone: 'UTC'
                }) : ""}</div>
                  </div>
                </>
              }
            
 

    
          
      
    
            </div>

          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditContactPage = connect(
  mapState,
  actionCreators
)(EditContactPage);
export { connectedEditContactPage as EditContactPage };
