import React, { useEffect } from "react";

export default function Child(props) {

    useEffect(() => {
        console.log(props)
      }, [])

    return (
        <div>
        </div>
    )
}