import React, { useEffect, useState } from "react";
import Axios from "axios";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import ReactApexChart from 'react-apexcharts';
import StyleHeader from "../StickyHeader/StickyHeader";




export default function Keywords({ ObjKeywords, DisplayDomain }) {

  const [SeoKeywordData, SetSeoKeywordData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Rlen, SetRlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [CountPage, SetCountPage] = React.useState(0);

  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("position");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(false)

  const [KeywordWeek, SetKeywordWeek] = React.useState([])
  const [Top5Keyword, SetTop5Keyword] = React.useState([])
  const [OtherKeyword, SetOtherKeyword] = React.useState([])
  const [PositionsWeek, SetPositionsWeek] = React.useState([])
  const [Positions, SetPositions] = React.useState([
    { name: '', color: '#111c43', data: [] },
    { name: '', color: '#283255', data: [] },
    { name: '', color: '#404968', data: [] },
    { name: '', color: '#70768e', data: [] },
    { name: '', color: '#9fa4b3', data: [] },
  ]);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    var UserDetails = GetUserDetails();
    if (UserDetails != null) {
      if (ObjKeywords.IsAnalyze) {
        Getkeywords(UserDetails.ClientID);
      }
    }
  }, [ObjKeywords.date, ObjKeywords.IsAnalyze])

  useEffect(() => {
    document.title = `Keywords | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
    }

    Getkeywords(Details.ClientID);

  }, [Search, Page, RowsPerPage, SortedBy]);

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
    }


    Getkeywordgraph(Details.ClientID);
    GetkeywordPostiongraph(Details.ClientID);

  }, []);

  useEffect(() => {
    console.log(Positions)


  }, [Positions]);


  const Getkeywords = (ClientID) => {
    SetIsLoading(true)
    var requestData = {
      RowsPerPage: RowsPerPage,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      ClientID: ClientID,
      Page: Page,
      Date: ObjKeywords.IsAnalyze == true ? ObjKeywords.date : null

    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/seoreporting/seokeywordget",
      method: "POST",
      data: requestData,
    }).then(response => {
      if (response.data.StatusMessage == "SUCCESS") {
        if (response.data.PageData.length > 0) {
          DisplayDomain(response.data.Website)
          SetSeoKeywordData(response.data.PageData);
          SetRlen(response.data.TotalCount);
          SetFlen(response.data.TotalCount);
          SetCountPage(response.data.PageCount);
          SetIsLoading(false)
          setIsDataLoaded(true);

        } else {
          SetSeoKeywordData([])
          SetRlen(0)
          SetFlen(0)
          SetCountPage(0)
          SetIsLoading(false)
          setIsDataLoaded(true);

        }
      } else {
        SetSeoKeywordData([])
        SetRlen(0)
        SetFlen(0)
        SetCountPage(0)
        SetIsLoading(false)
        setIsDataLoaded(true);

        
      }
    }).catch(error => {
      console.error('Error fetching Get keywords:', error);
    });
  };

  const Getkeywordgraph = (ClientID) => {
    var requestData = {
      ClientID: ClientID,
      Date: ObjKeywords.date

    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/seoreporting/seokeywordgraph",
      method: "POST",
      data: requestData,
    }).then(response => {
      if (response.data.StatusMessage == "SUCCESS") {
        if (response.data.PageData.length > 0) {
          var lstkeywordweek = [], lstTop5Keyword = [], lstOtherKeyword = [];
          for (var i = 0; i < response.data.PageData.length; i++) {
            var item = response.data.PageData[i]
            lstkeywordweek.push(item.WeekDay)
            lstTop5Keyword.push(item.Top5Keyword)
            lstOtherKeyword.push(item.OtherKeyword)
          }
          SetKeywordWeek(lstkeywordweek)
          SetTop5Keyword(lstTop5Keyword);
          SetOtherKeyword(lstOtherKeyword);

        } else {
          SetKeywordWeek([])
          SetTop5Keyword([])
          SetOtherKeyword([])

        }
      } else {
        SetKeywordWeek([])
        SetTop5Keyword([])
        SetOtherKeyword([])

      }
    }).catch(error => {
      console.error('Error fetching Get keywords:', error);
    });
  };
  const GetkeywordPostiongraph = (ClientID) => {
    var requestData = {
      ClientID: ClientID,
      Date: ObjKeywords.date

    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/seoreporting/seokeywordpositiongraph",
      method: "POST",
      data: requestData,
    }).then(response => {
      if (response.data.StatusMessage == "SUCCESS") {
        if (response.data.PageData.length > 0) {
          debugger;
          var lstkeywordPostionweek = []
          for (var i = 0; i < response.data.PageData.length; i++) {
            var item = response.data.PageData[i]
            lstkeywordPostionweek.push(item.WeekDay)
            const newPositions = [...Positions];
            if (item.Postion.length > 0) {
              for (var j = 0; j < item.Postion.length; j++) {
                newPositions[j].data.push(item.Postion[j].TotalKeyWordPosition);
                newPositions[j].name = "Positions " + item.Postion[j].Position;
              }
            }
            SetPositions(newPositions);

          }
          SetPositionsWeek(lstkeywordPostionweek)
        } else {
          SetPositionsWeek([])
        }
      } else {
        SetPositionsWeek([])
      }
    }).catch(error => {
      console.error('Error fetching Get keywords:', error);
    });
  };

  //page change
  const PageChange = (event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  //Search table list
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      var SearchedVal = document.getElementById("Search").value;
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };

  //change row
  const ChangeRowSelected = (event) => {
    SetRowsPerPage(Number(event.target.value));
    SetPage(1);
  };

  //sort data with title
  const SortData = (Field) => {
    debugger;
    if (SortedBy == 1) {
      SetSortedBy(-1);
    } else {
      SetSortedBy(1);
    }
    SetSortField(Field);

  };

  var graphkeywordcount =
    [

      {
        name: 'Others',
        color: '#404968',
        data: OtherKeyword
      },
      {
        name: 'Top 5',
        color: '#70768e',
        data: Top5Keyword
      }


    ]
  var graphkeywordopation = {
    chart: {
      type: 'bar',
      height: 380,
      stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: 0,
          offsetY: 0
        }
      }
    }],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '13px',
              fontWeight: 900
            }
          }
        }
      },
    },
    xaxis: {
      //type: 'datetime',
      categories: KeywordWeek,
    },
    legend: {
      position: "top",
      offsetY: 0,
      offsetX: 0,
    },
    fill: {
      opacity: 1
    }
  }

  var graphPositionopation = {
    chart: {
      type: 'bar',
      height: 380,
      stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: 0,
          offsetY: 0
        }
      }
    }],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '13px',
              fontWeight: 900
            }
          }
        }
      },
    },
    xaxis: {
      //type: 'datetime',
      categories: PositionsWeek,
    },
    legend: {
      position: "top",
      offsetY: 0,
      offsetX: 0,
    },
    fill: {
      opacity: 1
    }
  }

  const ExportKeywords = (ClientID) => {
    SetIsLoading(true)
    var requestData = {
      ClientID: ClientID,
      date: ObjKeywords.IsAnalyze == true ? ObjKeywords.date : null
    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/seoreporting/exportseokeyword",
      method: "POST",
      data: requestData,
    }).then((Result) => {
      if (Result.data.StatusMessage = "SUCCESS") {
        var url = Result.data.PageData;
        const a = document.createElement('a');
        a.href = url;
        a.click();
        URL.revokeObjectURL(url);

        toast.success(<div className="toastsize">Keywords  <br />  Data exported successfully.</div>);
        SetIsLoading(false)
      }
      else {
        SetIsLoading(false)
      }

    }).catch(error => {
      console.error('Error fetching export BackLinks', error);
      SetIsLoading(false)
    });
  }

  return (
    <div>

      <div className="row">
        <div className="col mb-3">
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="bg-white h-100">
                <div className="chartboxsmet">
                  <h6 className="chartheading mb-5">Keywords Count</h6>
                  <ReactApexChart options={graphkeywordopation} series={graphkeywordcount} type="bar" height={350} />
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="bg-white h-100">
                <div className="chartboxsmet">
                  <h6 className="chartheading mb-5">Keywords Count (Top 5 Positions)</h6>
                  <div id="chart">
                    {Positions.length > 0 ? <ReactApexChart options={graphPositionopation} series={Positions} type="bar" height={350} /> : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div className="bg-white px-4 pb-4">
        {
          IsLoading ? <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div> : <></>
        }

        <div className="row">
          <div className="col"></div>
          <div className="col-lg-6">
            <div className="listing-li float-right pt-2">
              <ul>
                <li>
                  <a onClick={() => ExportKeywords(ClientID)} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                    <i class="la la-icon-font-size-13 la-download"></i>Export
                  </a>

                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row padt-25">
          <div className="col-sm-12 col-md-6">
            <label className="textlabelte">
              Show
              <select
                name="tbl_meeting_length"
                onChange={ChangeRowSelected}
                aria-controls="tbl_meeting"
                class="form-control form-control-sm"
                value={RowsPerPage}
              >
                {CommonConstants.show_rows.map((value) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
              entries
            </label>
          </div>
          <div className="col-sm-12 col-md-6 full-right">
            <label class="textlabelte">
              Search:{" "}
              <input
                type="Search"
                id="Search"
                onKeyPress={RequestSearch}
                class="form-control form-control-sm ml-2"
                placeholder=""
                aria-controls="tbl_meeting"
              />
            </label>
          </div>
        </div>
        <div className="table-bordered">
          <TableContainer component={Paper}>
          <StyleHeader isDataLoaded={isDataLoaded} />
            <Table className="table-ref" aria-label="sticky table">
              <TableHead>
                <TableRow>

                  <TableCell><a onClick={() => { SortData("keyword"); }}>Keyword<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "keyword" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "keyword" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell><a onClick={() => { SortData("position"); }}>Position<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "position" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "position" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell><a onClick={() => { SortData("searchVolume"); }}>Search Volume<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "searchVolume" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "searchVolume" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell className="wrap"><a onClick={() => { SortData("url"); }}>Url<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "url" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "url" ? "active" : null} />
                  </span></a></TableCell>

                  <TableCell><a onClick={() => { SortData("traffic"); }}>Traffic %<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "traffic" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "traffic" ? "active" : null} />
                  </span></a></TableCell>

                  <TableCell><a onClick={() => { SortData("competition"); }}>Competition<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "competition" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "competition" ? "active" : null} />
                  </span></a></TableCell>

                  <TableCell><a onClick={() => { SortData("numberOfResults"); }}>Number of Results<span className="shorting">
                    <ArrowUpward className={SortedBy === 1 && SortField === "numberOfResults" ? "active" : null} />
                    <ArrowDownward className={SortedBy === -1 && SortField === "numberOfResults" ? "active" : null} />
                  </span></a></TableCell>
                  <TableCell>Data Pull Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {SeoKeywordData.length === 0 ? (
                  <p className="text-center">No data available in table</p>
                ) : (
                  SeoKeywordData?.map((row) => (
                    <TableRow>
                      <TableCell>{row?.keyword}</TableCell>
                      <TableCell>{row?.position}</TableCell>
                      <TableCell>{row?.searchVolume}</TableCell>
                      <TableCell className="wrap">{row?.url}</TableCell>
                      <TableCell>{row?.traffic}</TableCell>
                      <TableCell>{row?.competition}</TableCell>
                      <TableCell>{row?.numberOfResults}</TableCell>
                      <TableCell>{row?.Date}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {Sflag ? (
          <div class="row">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={PageChange}
              showFirstButton
              showLastButton
            />
            <div class="col dataTables_info">
              <p>
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
                entries (filtered from {Flen} total entries)
              </p>
            </div>
          </div>
        ) : (
          <div class="row">
            <div class="col dataTables_info">
              <p>
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
                entries
              </p>
            </div>
            <div class="col pageright">
              <Pagination
                component="div"
                count={CountPage}
                page={Page}
                onChange={PageChange}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </div>

    </div>
  );
}