import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import SalesRep from "../../_components/user/SalesRep/SalesRepTable.js";
//import CollapsibleTable from '../_components/usertable';

import { history } from "../../_helpers";

class SalesRepPage extends React.Component {
  AddBtn() {
    history.push("/addsalesrep");
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitle float-left pl-0">Sales Reps</h4>
                <div className="listing-li float-right padb-15">
                  <ul>
                    <li>
                      <a
                        onClick={this.AddBtn}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="paddcols">
              <div className="row">
                <div className="col">
                  <SalesRep />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedSalesRepPage = connect(mapState, actionCreators)(SalesRepPage);
export { connectedSalesRepPage as SalesRepPage };
