import React, { useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
const moment = require("moment");

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import StyleHeader from "../StickyHeader/StickyHeader";

const usRRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable() {
  const [Open, SetOpen] = React.useState(false);
  const [data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Name");
  const [SortedBy, setSortedBy] = React.useState(-1);
  const [sortedColumn, setSortedColumn] = React.useState(null);
  const [Notes, SetNotes] = React.useState([]);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Status, SetStatus] = React.useState([]);
  const [EngagamentstatusID, SetEngagamentstatusID] = React.useState(null);
  const [Role, SetRole] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    document.title = `LinkedIn Follow Up | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    GetLinkedinList(Details.ClientID, Details.ParentUserID, Details.Role);
    OwnerDropDown(Details.ClientID, Details.Role);
  }, [Search, Page, RowsPerPage, EngagamentstatusID]);

  //get LinkedIn follow up meeting
  const GetLinkedinList = (CID, UID, URole) => {
    SetIsLoading(true);
    var InputParameters = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      Sortby: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const Rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/linkedinmeeting/GetLinkedinMeeting",
      method: "POST",
      data: InputParameters,
    });
    Rows1.then((result) => {
      SetData(result.data?.PageData);
      SetRows(result.data?.PageData);
      SetRlen(result.data?.TotalCount);
      SetFlen(result.data?.TotalCount);
      SetCountPage(result.data?.PageCount);
      SetIsLoading(false);
      setIsDataLoaded(true);

      //document.getElementById("hideloding").style.display = "none";
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
      setIsDataLoaded(true);

    });
  };

  //OwnerData Drop down list
  const OwnerDropDown = (CId, URole) => {
    var InputParameters = {
      ClientID: CId,
      Role: URole,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/linkedinmeeting/MeetingOwnerGet",
      method: "POST",
      data: InputParameters,
    });
    rows1
      .then((Result) => {
        SetStatus(Result.data?.MeetingStatus);
        SetNotes(Result.data?.NotesDetails);
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
  };

  //Search for record
  const RequestSearch = (Event) => {
    try {
      if (Event.key === "Enter") {
        var SearchedVal = document.getElementById("Search").value;
        if (SearchedVal == Search) {
          SetSearch(SearchedVal);
          SetPage(1);
        } else {
          SetSearch(SearchedVal);
          SetPage(1);
          //document.getElementById("hideloding").style.display = "block";
        }
      }
    } catch (error) {
      toast.error("An error occurred while searching. Please try again.");
    }
  };

  //delete linkedin meeting
  const DeleteBtn = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a LinkedIn follow up.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          var data = {
            _id: id,
            LastUpdatedDate: new Date(),
            LastUpdatedBy: CUserID,
            Role: Role,
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/linkedinmeeting/DeleteLinkedinMeeting",
            method: "POST",
            data: data,
          }).then((res) => {
            if (res) {
              if ((res.statusText = "Ok")) {
                Swal.fire(
                  "Deleted!",
                  "linkedin meeting deleted successfully.",
                  "success"
                );

                //document.getElementById("hideloding").style.display = "block";
                GetLinkedinList(ClientID, UserID, Role);
                history.push("/linkedinfollowup");
                SetPage(1);
                SetSearch("");
                document.getElementById("Search").value = "";
              } else {
              }
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
      });
  };

  //Handle page change
  const HandleChangePage = (event, newPage) => {
    document.body.scrollTop = 0;
    if (newPage == Page) {
      SetPage(newPage);
    } else {
      SetPage(newPage);
      //document.getElementById("hideloding").style.display = "block";
    }
  };

  //set page
  const startPage = (strdata, Pages, rowcount) => {
    if (strdata == "normal") {
      var InputParameters = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        serchbox: false,
        sort: true,
        field: SortField,
        sortby: SortedBy,
        type: "User",
      };
    } else if (strdata == "Search") {
      var InputParameters = {
        Page: Pages,
        RowsPerPage: rowcount,
        serchbox: false,
        sort: true,
        field: SortField,
        sortby: SortedBy,
        type: "User",
      };
    }
    const Rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/linkedinmeeting/GetLinkedinMeeting",
      method: "POST",
      data: InputParameters,
    });
    Rows1.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
    });
  };

  //row selection
  const ChangeRowSelected = (event) => {
    SetRowsPerPage(event.target.value);
    SetPage(1);
    //document.getElementById("hideloding").style.display = "block";
  };

  //Update on change option
  const Potentialstatusselect = (event, id) => {
    try {
      //update in potential meeting
      let ID = event.target.value;
      var data = {
        _id: id,
        EngagamentstatusID: event.target.value,
        LastUpdatedBy: CUserID,
        LastUpdatedDate: new Date(),
        Role: Role,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/linkedinmeeting/UpdateLinkedinMeetingdropdown",
        method: "POST",
        data: data,
      })
        .then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div className="toastsize">
                LinkedIn Follow Up <br />
                Engagement status successfully changed.
              </div>
            );
            //document.getElementById("hideloding").style.display = "block";
            GetLinkedinList(ClientID, UserID, Role);
          } else {
            toast.error(res.data.Message);
          }
        })
        .catch((error) => {
          toast.error("An error occurred. Please try again.");
          SetIsLoading(false);
        });
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    }
  };

  //render on view Page
  // const ViewPage = (id) => {
  //   history.push("/viewpotentialmeeting", id);
  // };

  const ViewPage = (id) => {
    history.push({
      pathname: "/viewpotentialmeeting",
      state: { data: id, Pagename: "LinkedInFollowUps" },
    });
  };

  const MeetingsConvertAdd = (ids) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to convert LinkedIn follow up in to meeting?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, convert it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var str_in = {
          id: ids,
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/potentialmeeting/PotentialMeetingByID",
          method: "POST",
          data: str_in,
        }).then((res) => {
          history.push({
            pathname: "/addbookedmeeting",
            state: {
              Pagename: "LinkedInFollowUps",
              data: res.data[0],
              BackPage: "/bookedmeetings",
            },
          });
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  //get sort field data
  const SortData = (Field) => {
    try {
      SetIsLoading(true);
      // document.getElementById("hideloding").style.display = "block";
      var SearchedVal = document.getElementById("Search").value;
      var SerchBox;
      if (SearchedVal == "") {
        SerchBox = false;
        SetSflag(false);
        var SortField = Field;
        var SortBy;
        if (SortedBy == 1) {
          SortBy = -1;
          setSortedBy(-1);
        } else {
          SortBy = 1;
          setSortedBy(1);
        }
        SetSortField(Field);
        var InputParameter = {
          Page: Page,
          RowsPerPage: RowsPerPage,
          Sort: true,
          Field: SortField,
          Sortby: SortBy,
          Search: SearchedVal,
          Type: "User",
          ClientID: ClientID,
          UserID: UserID,
          Role: Role,
        };
        const meetingList = Axios({
          url:
            CommonConstants.MOL_APIURL + "/linkedinmeeting/GetLinkedinMeeting",
          method: "POST",
          data: InputParameter,
        });
        meetingList
          .then((result) => {
            SetData(result.data.PageData);
            SetRows(result.data.PageData);
            SetRlen(result.data.TotalCount);
            SetFlen(result.data.TotalCount);
            SetCountPage(result.data.PageCount);
            SetIsLoading(false);
            //document.getElementById("hideloding").style.display = "none";
          })
          .catch((error) => {
            toast.error("An error occurred. Please try again.");
            SetIsLoading(false);
          });
      } else {
        SerchBox = true;
        SetSflag(true);
        var SortField = Field;
        var SortBy;
        if (SortedBy == 1) {
          SortBy = -1;
          setSortedBy(-1);
        } else {
          SortBy = 1;
          setSortedBy(1);
        }
        SetSortField(Field);
        var InputParameter = {
          Page: Page,
          RowsPerPage: RowsPerPage,
          Sort: true,
          Field: SortField,
          SerchBox: SerchBox,
          Sortby: SortBy,
          Search: SearchedVal,
          Type: "User",
          ClientID: ClientID,
          UserID: UserID,
          Role: Role,
        };
        const meetingList = Axios({
          url:
            CommonConstants.MOL_APIURL + "/linkedinmeeting/GetLinkedinMeeting",
          method: "POST",
          data: InputParameter,
        });
        meetingList
          .then((result) => {
            SetData(result.data.PageData);
            SetRows(result.data.PageData);
            SetRlen(result.data.TotalCount);
            SetFlen(result.data.TotalCount);
            SetCountPage(result.data.PageCount);
            SetIsLoading(false);
            // document.getElementById("hideloding").style.display = "none";
          })
          .catch((error) => {
            toast.error("An error occurred. Please try again.");
            SetIsLoading(false);
          });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      SetIsLoading(false);
    }
  };

  const EditAccountHandle = (id) => {
    if (id !== undefined && id !== null && id !== "000000000000000000000000") {
      history.push({
        pathname: "/viewaccount",
        state: { data: id, Pagename: "PotentialLinkedin" },
      });
    }
  };

  const EditContactHandle = (id, Email) => {
    if (id !== undefined && id !== null && id !== "000000000000000000000000") {
      history.push({
        pathname: "/viewcontacts",
        state: { data: id, EmailData: Email, Pagename: "PotentialLinkedin" },
      });
    }
  };

  return (
    <div className="px-3 pb-4">
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25 ">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="Search"
              id="Search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
      <TableContainer component={Paper}> 
              <StyleHeader isDataLoaded={isDataLoaded} />
              <Table className="table-ref" aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Notes</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Convert</TableCell>
                <TableCell
                  onClick={() => {
                    SortData("AccountName");
                    setSortedColumn("AccountName");
                  }}
                >
                  Account Name
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "AccountName"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "AccountName"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("Company");
                    setSortedColumn("Company");
                  }}
                >
                  Company
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "Company"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "Company"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  className="Engagement Status"
                  onClick={() => {
                    SortData("EngagementStatus");
                    setSortedColumn("EngagementStatusID");
                  }}
                >
                  Engagement Status
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "EngagementStatusID"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "EngagementStatusID"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("ContactName");
                    setSortedColumn("ContactName");
                  }}
                >
                  Contact Name
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "ContactName"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "ContactName"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("Email");
                    setSortedColumn("Email");
                  }}
                >
                  Email
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "Email"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "Email"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("Title");
                    setSortedColumn("Title");
                  }}
                >
                  Title
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "Title"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "Title"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("LinkedInUrl");
                    setSortedColumn("LinkedInUrl");
                  }}
                >
                  LinkedIn Url
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "LinkedInUrl"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "LinkedInUrl"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("MeetingSourceName");
                    setSortedColumn("MeetingSourceID");
                  }}
                >
                  Meeting Source Name
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "MeetingSourceID"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "MeetingSourceID"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("MeetingOwnerName");
                    setSortedColumn("MeetingOwnerID");
                  }}
                >
                  Owner Name
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "MeetingOwnerID"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "MeetingOwnerID"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("ReminderEmailAccount");
                    setSortedColumn("ReminderEmailAccountID");
                  }}
                >
                  Reminder Email Account
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 &&
                        sortedColumn === "ReminderEmailAccountID"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 &&
                        sortedColumn === "ReminderEmailAccountID"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("DirectPhone");
                    setSortedColumn("DirectPhone");
                  }}
                >
                  Direct Phone
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "DirectPhone"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "DirectPhone"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("CompanyPhone");
                    setSortedColumn("CompanyPhone");
                  }}
                >
                  Company Phone
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "CompanyPhone"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "CompanyPhone"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell
                  onClick={() => {
                    SortData("CreatedDate");
                    setSortedColumn("CreatedDate");
                  }}
                >
                  Created Date
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        SortedBy === 1 && sortedColumn === "CreatedDate"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        SortedBy === -1 && sortedColumn === "CreatedDate"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows.map((row) => (
                  <React.Fragment key={row._id}>
                    <TableRow>
                      <TableCell align="center">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            SetOpen((prev) => ({
                              ...prev,
                              [row._id]: !prev[row._id],
                            }));
                          }}
                        >
                          {Open[row._id] ? (
                            <i
                              className="fa fa-minus-circle minusl"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              className="fa fa-plus-circle plusbl"
                              aria-hidden="true"
                            ></i>
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <div className="d-flex">
                          <a
                            onClick={() => {
                              ViewPage(row._id);
                            }}
                            className="btn-eyesicon"
                          >
                            <i className="la flaticon-eye edit-icon"></i>
                          </a>
                          <a
                            onClick={() => {
                              DeleteBtn(row._id);
                            }}
                            className="btn-eyesicon"
                          >
                            <i className="la flaticon-delete-1 delete-icon"></i>
                          </a>
                        </div>
                      </TableCell>
                      <TableCell scope="row">
                        <a
                          onClick={() => {
                            MeetingsConvertAdd(row._id);
                          }}
                          className="btn-exchangeicon"
                        >
                          <i className="la la-exchange"></i>
                        </a>
                      </TableCell>
                      <TableCell className="labeltds" scope="row">
                        <a
                          onClick={() => {
                            EditAccountHandle(
                              row.SalesReplyAccountPotentialDetails
                                ?.SalesReplyAccountID
                            );
                          }}
                        >
                          <u>
                            {row?.SalesReplyAccountPotentialDetails ==
                              undefined ||
                            row?.SalesReplyAccountPotentialDetails
                              ?.AccountName == ""
                              ? ""
                              : row?.SalesReplyAccountPotentialDetails
                                  ?.AccountName}
                          </u>
                        </a>
                      </TableCell>
                      <TableCell>{row?.Company}</TableCell>
                      <TableCell className="labeltds" scope="row">
                        <select
                          onChange={(e) => {
                            Potentialstatusselect(e, row._id);
                          }}
                          className="form-control  m-input"
                          id="Potentialstatus"
                          name="Potentialstatus"
                          autoComplete="off"
                          value={row.EngagamentStatusID}
                        >
                          {/* <option value="">--Select--</option> */}
                          {Status.map((value) => (
                            <option key={value._id} value={value._id}>
                              {value.Status}
                            </option>
                          ))}
                        </select>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <a
                          onClick={() => {
                            EditContactHandle(
                              row?.ProspectPotentialDetails?.ProspectID,
                              row.Email
                            );
                          }}
                        >
                          <u>{row?.ContactName}</u>
                        </a>
                      </TableCell>
                      <TableCell>{row?.Email}</TableCell>
                      <TableCell>{row?.Title}</TableCell>
                      <TableCell>{row?.LinkedInUrl}</TableCell>
                      <TableCell>{row?.MeetingSourceName}</TableCell>
                      <TableCell>{row?.MeetingOwnerName}</TableCell>
                      <TableCell>{row?.ReminderEmailAccount}</TableCell>
                      <TableCell>{row?.DirectPhone}</TableCell>
                      <TableCell>{row?.CompanyPhone}</TableCell>

                      <TableCell>
                        {moment(
                          new Date(row?.CreatedDate).toDateString()
                        ).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell>
                        <a
                          onClick={() => {
                            ViewPage(row._id);
                          }}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-eye edit-icon"></i>
                        </a>
                        <a
                          onClick={() => {
                            DeleteBtn(row._id);
                          }}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-delete-1 delete-icon"></i>
                        </a>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={10}
                      >
                        <Collapse
                          in={Open[row._id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {Open[row._id] && (
                            <Box margin={1} className="innertables">
                              <Table size="small" aria-label="purchases">
                                <TableHead></TableHead>
                                {Notes.map((note) =>
                                  note?.PotentialMeetingID.toString() ===
                                  row?._id.toString() ? (
                                    <TableRow key={note._id}>
                                      <TableCell className="font-weight-bold">
                                        {note?.Title}
                                      </TableCell>
                                      <TableCell className="labeltds font-weight-bold">
                                        {moment(
                                          new Date(
                                            note?.CreatedDate
                                          ).toDateString()
                                        ).format("MM/DD/YYYY")}
                                      </TableCell>
                                      <TableCell className="notes" scope="row">
                                        {note?.Note}
                                      </TableCell>
                                    </TableRow>
                                  ) : null
                                )}

                                {!Notes.some(
                                  (note) =>
                                    note?.PotentialMeetingID.toString() ===
                                    row?._id.toString()
                                ) && (
                                  <TableRow>
                                    <TableCell colSpan={4}>
                                      No data available
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Table>
                            </Box>
                          )}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
  );
}
