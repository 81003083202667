import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Axios from "axios";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import CAddAccounts from "../../../_components/clients/accountclientstatus/accountcategory/AddAccountCategories";

import "react-toastify/dist/ReactToastify.css";

class CAddAccountsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      StatusAvailable: null,
      TitleNotNull: false,
      IsExist: false,
      ClientID: null,
      UserID: null,
      Role: null,
    };
  }
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="adminmain mt-3"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="paddcols pb-4">
              <CAddAccounts account={this.props.location.state} />
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddAccountsPage = connect(
  mapState,
  actionCreators
)(CAddAccountsPage);
export { connectedAddAccountsPage as CAddAccountsPage };
