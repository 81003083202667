import React from "react";
import Axios from "axios";
import DatePicker from "react-datepicker";

import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { toast } from "react-toastify";
toast.configure();

class CAddMeetingGoalPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      SelectDate: "",
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      Role: Details.Role,
      UserIDby: Details.ChildUserID,
      Fields: {},
      Errors: {},
      IsButtonDisabled:false
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.SelectedDate = this.SelectedDate.bind(this);
  }
  componentDidMount() {
    document.title = `Add Meeting Goal | SalesHive`;
    document.getElementById("date").style.display = "none";
    // document.getElementById("goal_err").style.display = "none";
  }

  // back button
  BackBtn() {
    history.push("/CMeetinggoal");
  }

  // form validation
  async FromValidation() {
    document.getElementById("date").style.display = "none";
    let FormIsValid = true;
    let Errors = {};
    var Goal = document.getElementById("Goal").value.trim();
    var IsExist = await this.CheckExistGoalName(this.state.SelectDate)
    if (Goal == "") {
      FormIsValid = false;
      Errors["Goal"] = "Please Enter Goal";
    }
    if (this.state.SelectDate == "") {
      FormIsValid = false;
      document.getElementById("date").style.display = "block";
    }
    if(IsExist == true){
      FormIsValid = false;
    }

    this.setState({ Errors: Errors });
    return FormIsValid;
  }

  //check exist name
async CheckExistGoalName(WeekData) {
  var str_in = {
    ClientID: this.state.ClientID,
    Week: WeekData,
    Role: this.state.Role,
  };
  var resdata = await Axios({
    url:
      CommonConstants.MOL_APIURL +
      "/meetinggoal/CheckExistWeek",
    method: "POST",
    data: str_in,
  })

    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        this.setState({ SelectDate: "" });
        toast.error("Goal aleady exist in selected week,select other week.", "Add Meeting Goal");
        return true;
      } else {
        return false;
      }
    }
 
}

  // meeting goal save
  async SaveBtn(e) {
    // e.preventDefault();
    // e.currentTarget.disabled = true;
    let todayweekday = document.getElementById("Week").value
    this.setState({IsButtonDisabled:true});
    var Final_flag = await this.FromValidation();
    if (Final_flag == true) {
      var Goal = document.getElementById("Goal").value.trim();
      var data = {
        Goal: Goal,
        Week: todayweekday,
        type: "User",
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
        CreatedBy: this.state.UserIDby,
        CreatedDt: new Date(),
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/meetinggoal/MeetingGoalAdd",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div>Add meeting goal<br/>
          Meeting goal added successfully.</div>);
          history.push("/CMeetinggoal");
        } else {
          toast.error(res.data.Message);
          this.setState({IsButtonDisabled:false});
        }
      });
    } else {
      this.setState({IsButtonDisabled:false});
      //document.getElementById("submit").disabled = false;
    }
  }

  // select date
  SelectedDate(date) {
    this.setState({ SelectDate: date });
    this.CheckExistGoalName(date)
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
            <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left pl-0 mb-0">
                    Add Meeting Goals
                  </h4>
                </div>
              </div>

            <div class="paddcols px-3">
              <div className="row py-2">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-3 view-lable-meet">Week</label>
                    <div class="col-lg-8 datepickerfull">
                      <DatePicker
                        selected={
                          this.state.SelectDate == null
                            ? ""
                            : this.state.SelectDate
                        }
                        onChange={(date) => this.SelectedDate(date)}
                        name="startDate"
                        filterDate={(date) => date.getDay() === 1}
                        className="form-control m-input w-100"
                        id="Week"
                      />
                      <p id="date" className="errorinput">
                        please select date
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-3 view-lable-meet">Goal</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        id="Goal"
                        name="LastName"
                        placeholder="Enter goal"
                        type="number"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Goal"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row my-3">
                <div class="col-lg-12 pull-left">
                  <a
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.IsButtonDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </a>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};


const connectedCAddMeetingGoalPage = connect(
  mapState,
  actionCreators
)(CAddMeetingGoalPage);
export { connectedCAddMeetingGoalPage as CAddMeetingGoalPage };
