import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Axios from "axios";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

import "react-toastify/dist/ReactToastify.css";
toast.configure();


class CAddCustomVariablePage extends React.Component { 
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      NameAvailable: null,
      ClientID: null,
      UserID: null,
      Role: null,
      VariableType: [],
      VariableTypeID: null,
      CustomVariable: {},
      IsExist: false,
      VariableTypeName: "",
      UserIDby:null,
      IsButtonDisabled: false,
        };

    this.backbtn = this.backbtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = "Add Custom Variable | SalesHive";

    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.Role = Details.Role;
      this.state.UserIDby = Details.ChildUserID;
    }
    this.VariableTypeGet();
  }
  // form validation
  async FromValidations() {
    let FormIsValid = true;
    let Errors = {};
    var Name = document.getElementById("Name").value.trim();
    var VariableType = document.getElementById("VariableType").value;
    var IsExist = await this.CustomVariableCheckExist(Name);
    if (Name == "") {
      FormIsValid = false;
      Errors["Name"] = "The Name field is required.";
    }
    if (VariableType == "") {
      FormIsValid = false;
      Errors["VariableType"] = "Please select Variable type";
    }
    if (IsExist == true) {
      FormIsValid = false;
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

  // handle change
  HandleChange(Field, E) {
    let Fields = this.state.Fields;
    Fields[Field] = E.target.value;
    this.setState({ Fields });
    if (Fields.Name != "") {
      this.state.Errors.Name = null;
      this.state.NameAvailable = null;
      this.CustomVariableCheckExist(E.target.value);
    } else {
      this.state.NameAvailable = null;
      this.state.Errors.Name = null;
    }
  }

    // find check exits custom variable
    async CustomVariableCheckExist(Name) {
      var CustomVariable = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Name: Name,
      };
      var resdata = await Axios({
        url: CommonConstants.MOL_APIURL + "/customvariable/CustomVariableExists",
        method: "POST",
        data: CustomVariable,
      })
      
        if (resdata.data.StatusMessage == "SUCCESS") {
          if (resdata.data.Data.length > 0) {
            this.setState({ NameAvailable: resdata.data.Data[0].Name });
            return true;
          } else {
            this.setState({ IsExist: false });
            return false;
          }
        }
    }

  // variable type get
  VariableTypeGet() {
    Axios({
      url: CommonConstants.MOL_APIURL + "/customvariable/GetVariableTypeList",
      method: "POST",
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        this.setState({ VariableType: res.data.Data });
      } else {
        toast.error(res.data.Message);
      }
    });
  }


  async VariableTypeselect(event) {
    this.setState({ VariableTypeID: event.target.value });
    await this.FromValidations()
  }

  backbtn() {
    history.push("/CCustomVariable");
  }

    // save custom variable
    async SaveBtn(e) {
      this.setState({ IsButtonDisabled: true });
      var Final_flag = await this.FromValidations();
      if (Final_flag == true) {
        var Name = document.getElementById("Name").value.trim();
        var Data = {
          Name: Name,
          ClientID: this.state.ClientID,
          UserID: this.state.UserID,
          VariableTypeID: this.state.VariableTypeID,
          CreatedBy: this.state.UserIDby,
          CreatedDate: new Date(),
          Role:this.state.Role
        };
        
        Axios({
          url: CommonConstants.MOL_APIURL + "/customvariable/AddCustomVariable",
          method: "POST",
          data: Data,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div>
               Custom Variable Add
                <br />
                Custom variable added successfully.
              </div>
            );
            history.push("/CCustomVariable");
            this.setState({ IsButtonDisabled: false });
          } else {
            toast.error(res.data.Message);
            this.setState({ IsButtonDisabled: false });
          }
        });
      } else {
        this.setState({ IsButtonDisabled: false });
      }
    }
  render() {
    return (
      <>
        <div className="adminmain"> 
          <Sidebar className="" /> 
          <div className="bodyhome mheight">
            
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left mb-0">
                  Add Custom Variable 
                  </h4>
                </div> 
              </div>
            
            <div className="paddcols">
              <div className="row">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Name</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter name"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        id="Name"
                        name="Name"
                        placeholder="Enter Name"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                      {this.state.NameAvailable && (
                        <span style={{ color: "red" }}>
                        Custom variable already exist.
                        </span>
                      )}
                    
                    </div>
                  </div>
                </div>
                <div class="col-lg-6"></div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Variable Type
                    </label>
                    <div class="col-lg-8">
                      <select
                        onChange={(e) => {
                          this.VariableTypeselect(e);
                        }}
                        className="form-control  m-input"
                        id="VariableType"
                        name="VariableType"
                        autoComplete="off"
                      >
                        {<option value="">--Select--</option>}
                        {this.state.VariableType.map((value) => (
                          <option value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["VariableType"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row mb-4 mt-4 px-3">
                <div class="pull-left">
                <button
                  id="submit"
                  onClick={this.SaveBtn}
                  class="btn btn-primary btn-lightgreen mr-1"
                  disabled={this.state.IsButtonDisabled}
                >
                  <i class="la la-save"></i> Save
                </button>
                <button
                  id="backtolist"
                  onClick={this.backbtn}
                  class="btn btn-secondary"
                >
                  <i class="la la-arrow-circle-left"></i> Back
                </button>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCAddCustomVariablePage = connect(
  mapState,
  actionCreators
)(CAddCustomVariablePage);
export { connectedCAddCustomVariablePage as CAddCustomVariablePage };
