import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Axios from "axios";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import AddAccounts from "../../../_components/user/AccountClientStatus/AccountCategory/AddAccountCategories";

import "react-toastify/dist/ReactToastify.css";



class AddAccountsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      StatusAvailable: null,
      TitleNotNull: false,
      IsExist: false,
      ClientID: null,
      UserID: null,
      Role: null,
    };

 
  }
  componentDidMount() {
  }

  



  render() {
    return (
      <>
        <div className="adminmain mt-3 "> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="whiterow pb-4"> 
              <AddAccounts account={this.props.location.state}/>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddAccountsPage = connect(
  mapState,
  actionCreators
)(AddAccountsPage);
export { connectedAddAccountsPage as AddAccountsPage };
