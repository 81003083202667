import React from "react";
import { connect } from "react-redux";

import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import ClientAccountTabing from "../../_components/user/ClientAccounts/ClientAccountTabing";
import CollapsibleTable from "../../_components/usertable";

class ClientAccountPage extends React.Component {
  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col padd-0">
                <h4 className="headertitlebd">All Client Account</h4>
              </div>
              <div className="col padright"></div>
            </div>
             
            <div className="col padd-0">
              <ClientAccountTabing />
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedClientAccountPage = connect(
  mapState,
  actionCreators
)(ClientAccountPage);
export { connectedClientAccountPage as ClientAccountPage };
