import React from "react";
import PropTypes from "prop-types";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import AccountsTable from "./AccountsTable";
import ReportingTable from "./ReportingTable";
import ImageUploading from "react-images-uploading";

import { history } from "../../../_helpers";
import { CSVReader, CSVDownloader } from "react-papaparse";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, SetValue] = React.useState(0);
  const [ImportProps,SetImportProps]=React.useState(false);

  const handleChange = (event, newValue) => {
    SetValue(newValue);
    SetImportProps(false)
  };

  const [Images, SetImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    SetImages(imageList);
  };

  const hideimportcsvaccount = () => {
    document.getElementById("import_account").style.display = "none";
  };

  const showimportcsvaccount = () => {
    SetImportProps(true)
    document.getElementById("import_account").style.display = "block";
    document.getElementById("total_div_display").style.display = "none";
  };

  // email account page
  const EmailAccountPage = () => {
    history.push("/addemailaccounts");
  };

  return (
    <div className="tabdes">
      <div>
        <TabPanel value={value} className="tagpaddnone" index={0}>
          <div className="row">
            <div className="col">
              <h4 className="headertitle float-left pl-0">Email Accounts</h4>
            </div>
            <div className="col">
              <div className="listing-li float-right padb-15">
                <ul>
                  <li>
                    <a
                      onClick={showimportcsvaccount}
                      className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                    >
                      <i class="la la-icon-font-size-13 la-upload"></i>
                      <span>Import</span>
                    </a>
                  </li>

                  <li>
                    <a
                      onClick={EmailAccountPage}
                      className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                    >
                      <i class="la la-icon-font-size-13 la-plus"></i>
                      <span>Add</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

      
          <AppBar position="static" className="p-0 mx-1">
          <Tabs
            value={value}
            onChange={handleChange} variant="scrollable"
            aria-label="simple tabs example"
           
          >
            <Tab label="Accounts" {...a11yProps(0)} >
              Overviewasdasd
            </Tab>
            <Tab label="Reporting" {...a11yProps(1)} />

          </Tabs>
        </AppBar>

        <div className="bg-white px-3 pb-3">
          <div className="row px-2">
            <div className="col">
                <AccountsTable ImportOpenCsv={ImportProps} setImportOpenCsv={SetImportProps}/> 
            </div>
          </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="tagpaddnone" index={1}>

        <div className="row">
            <div className="col">
              <h4 className="headertitle float-left pl-0">Accounts</h4>
            </div>
            <div className="col">
              <div className="listing-li float-right padb-15">
                <ul>
                  <li>
                    <a
                      // onClick={showimportcsvaccount}
                      className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                    >
                      <i class="la la-icon-font-size-13 la-upload"></i>
                      <span>Import</span>
                    </a>
                  </li>

                  <li>
                    <a
                       onClick={EmailAccountPage}
                      className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                    >
                      <i class="la la-icon-font-size-13 la-plus"></i>
                      <span>Add</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
          <AppBar position="static" className="p-0 mx-1">
          <Tabs
            value={value}
            onChange={handleChange} variant="scrollable"
            aria-label="simple tabs example"
           
          >
            <Tab label="Accounts" {...a11yProps(0)} >
              Overviewasdasd
            </Tab>
            <Tab label="Reporting" {...a11yProps(1)} />

          </Tabs>
        </AppBar>

        <div className="bg-white px-3 pb-3">
          <div className="row px-2">
            <div className="col">
              <ReportingTable />
            </div>
          </div>
        </div>
        </TabPanel>
      </div>

    </div>
  );
}
