import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../../_actions';
import Sidebar from '../../../_components/user/NavigationSidebar/Sidebar';
import Axios from 'axios';
import { CommonConstants } from '../../../_constants/common.constants';
import { history } from '../../../_helpers';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import { error } from 'jquery';

toast.configure()

class AddCountryBlacklistPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            Errors: {},
            CountryAvailable:null,
            UserID:null,
            ClientID:null,
            CUserID:null,
            Role: null,
            IsExist: false,
            BtnDisabled:false,
            IsLoading:false
        };
        this.backbtn = this.backbtn.bind(this);
        this.SaveBtn = this.SaveBtn.bind(this);
    }

    componentDidMount() { 
        document.title = 'Add Country Blacklist | SalesHive'
        var Details = GetUserDetails();
        if (Details != null) {
          this.state.ClientID = Details.ClientID;
          this.state.UserID = Details.ParentUserID;
          this.state.CUserID = Details.ChildUserID;
          this.state.Role=Details.Role
        }
        this.setState({ IsLoading: false});
    }


  // form validation
  async FromValidation() {
    let FormIsValid = true;
    let Errors = {};
    var Country = document.getElementById("Country").value.trim();
    var IsExists = await this.CheckExistCountry(Country);
    
    if (IsExists == "BlankData" && Country === "") {
      FormIsValid = false;
      Errors["Country"] = "Please enter Country"; 
    }
  
    if (IsExists === "ExistCountry") {
      FormIsValid = false;
      Errors["Country"] = "Country already exist.";
    }
  
    this.setState({ Errors: Errors });
    return FormIsValid;
  }
    // handle change
    async HandleChange(field, e) {
        let Errors = { ...this.state.Errors };
        const value = e.target.value.trim();
        let IsExists;
    
      
        if (field === "Country") { // Change "Country" to "Name"
          IsExists = await this.CheckExistCountry(value); // Change "CoutryNameCheckExist" to "ClientPocNameCheckExist"
      
          if (IsExists === "BlankData" && value === "") {
            Errors.Country = "Please enter Country"; // Change "Country" to "Name"
          } else if (IsExists === "ExistCountry") {
            Errors.Country = "Country already exist."; // Change "Country" to "Name"
          } else if (IsExists === "AllDone") {
            Errors.Country = null; // Change "Country" to "Name"
          }
        }
      
        this.setState({ Errors });
      }

        // check contact tag exists
  async CheckExistCountry(Country) {
    try {
        if(Country !== ""){
            
            var CountryData = {
              ClientID: this.state.ClientID,
              Country: Country,
              Role:this.state.Role
            };
            var resdata = await   Axios({
              url: CommonConstants.MOL_APIURL + "/countryblacklist/CountryBlacklistExists",
              method: "POST",
              data: CountryData   ,
            })
              // if (resdata.data.StatusMessage == "SUCCESS") {
                if (resdata.data?.Data?.length > 0) {
                  this.setState({ CountryAvailable: resdata.data.Data[0]?.Country });
                  return "ExistCountry";
                }else{
                  return "AllDone";
                }
              // }
          }else{
            return "BlankData";
          }  
    } catch (error) {
        toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
    }

  }

      backbtn() {
        history.push('/countryblacklist')
    }

      // save button
  async SaveBtn() { 
    
   try {
    this.setState({BtnDisabled:true})
    var Final_flag =  await this.FromValidation()
      if (Final_flag == true) {
        this.setState({ IsLoading: true});

        var Country = document.getElementById("Country").value.trim();
        var Abbreviation = document.getElementById("Abbreviation").value.trim();
        var ContactTagData = {
          ClientID: this.state.ClientID,
          UserID: this.state.UserID,
          Country: Country,
          Abbreviation: Abbreviation,
          CreatedBy: this.state.CUserID,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/countryblacklist/CountryBlacklistAdd",
          method: "POST",
          data: ContactTagData,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(<div className="toastsize">Country Added <br/>Country added successfully</div>);
            history.push("/countryblacklist");
            this.setState({ IsLoading: true});
          } else {
            toast.error(res.data.Message);
            this.setState({BtnDisabled:false});
            this.setState({ IsLoading: true});
          }
        }).catch((error) => {
            toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false })
        this.setState({BtnDisabled:false});
        });
      }else{
    this.setState({BtnDisabled:false})
    this.setState({ IsLoading: false});
      }
   } catch (error) {
    toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
        this.setState({BtnDisabled:false})
   }

  }

    render() {

        return (
            <>
             {
        this.state.IsLoading === true ? <>
        <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
        </> : <></>
      }
                <div className="adminmain mheight"> 
                    <Sidebar className="" /> 
                    <div className="bodyhome"> 
                        <div className="row">
                            <div className="col">
                                <h4 className="headertitlepad float-left mb-0">Add</h4>
                            </div>
                        </div>

                        <div class="paddcols">
                            <div className="row py-3">
                                <div class="col-lg-6 boxsinput_group">
                                    <div class="row max-cols">
                                        <label class="col-lg-4 view-lable-meet">Country</label>
                                        <div class="col-lg-8">
                                            <input class="form-control m-input" data-val="true" data-val-required="Please enter country"   onBlur={this.HandleChange.bind(this, "Country")} id="Country" name="Country" placeholder="Enter country" type="text" />

                                            <span style={{ color: "red" }}>
                        {this.state.Errors["Country"]}
                      </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 boxsinput_group">
                                    <div class="row max-cols">
                                        <label class="col-lg-4 view-lable-meet">Abbreviation</label>
                                        <div class="col-lg-8">
                                            <input class="form-control m-input" data-val="true" data-val-required="Please enter abbreviation"  id="Abbreviation" name="Abbreviation"  placeholder="Enter abbreviation" type="text" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                         

                            </div>

                            <div class="row py-4">
                                <div class="col-lg-12 pull-left">
                                    <button id="submit"  disabled={this.state.BtnDisabled} onClick={this.SaveBtn}  class="btn btn-primary btn-lightgreen mr-1"><i class="la la-save"></i> Save</button>
                                    <a id="backtolist" onClick={this.backbtn} class="btn btn-secondary"><i class="la la-arrow-circle-left"></i> Back</a>
                                </div>
                            </div>
                        </div>
                    </div>





            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedcountryblacklist = connect(mapState, actionCreators)(AddCountryBlacklistPage);
export { connectedcountryblacklist as AddCountryBlacklistPage };