import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import Footer from "../../_components/user/footer/footer";
import DefaultSlackNotification from '../../_components/user/DefaultSlackNotification/DefaultSlackNotificationTabing';
import CollapsibleTable from '../../_components/usertable';
 

class DefaultSlackNotificationPage extends React.Component {
    
      

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitlebd pl-0">Default Slack Notification</h4>
                        </div>
                    </div>
                    <span className="devider"></span>
                    <div className="col padd-0">
                        <DefaultSlackNotification />
                      
                    </div>
                </div>

                </div>
        <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedDefaultSlackNotificationPage = connect(mapState, actionCreators)(DefaultSlackNotificationPage);
export { connectedDefaultSlackNotificationPage as DefaultSlackNotificationPage };