import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { GetUserDetails } from "../_helpers/Utility";
import { history } from '../_helpers'; 
import { withRouter } from 'react-router-dom';
import ScrollToTop from '../_helpers/ScrollToTop';
import { alertActions } from '../_actions';
import { PrivateRoute } from '../_components';
import { HomePage } from '../user/HomePage';
import { RegisterPage } from '../RegisterPage';
import { LoginPage } from '../LoginPage';
import { TwoStepVerificationPage } from '../LoginPage/TwoStepVerificationPage'
import { EditMyProfilePage } from '../user/MyProfile';
import { ResetPasswordPage } from '../user/ResetPasswordPage';
import { KPIsPage } from '../user/KPIsPage';
import { AccountsPage } from '../user/AccountsPage';
import { AddAccountPage } from '../user/AccountsPage/AddAccountPage';
import { ViewAccountPage } from '../user/AccountsPage/ViewAccountPage/ViewAccountPage';
import { EditAccountsPage } from '../user/AccountsPage/EditAccountsPage/EditAccountsPage';
import { ContactsPage } from '../user/ContactsPage';
import { AddContactPage } from '../user/ContactsPage/AddContactPage/AddContactPage';
import { EditContactPage } from '../user/ContactsPage/EditContactPage/EditContactPage';
import { ViewContactPage } from '../user/ContactsPage/ViewContactPage/ViewContactPage';
import { PotentialMeetingsPage } from '../user/PotentialMeetingsPage/PotentialMeetingsPage';
import { BookedMeetingsPage } from '../user/BookedMeetingsPage/BookedMeetingsPage';
import { AddBookedMeetingPage } from '../user/BookedMeetingsPage/AddBookedMeetingPage/AddBookedMeetingPage';
import { ViewBookedMeetingPage } from '../user/BookedMeetingsPage/ViewBookedMeetingPage/ViewBookedMeetingPage';
import { EditBookedMeetingPage } from '../user/BookedMeetingsPage/EditBookedMeetingPage/EditBookedMeetingPage'
import { AddPotentialMeetingsPage } from '../user/PotentialMeetingsPage/AddPotentialMeetingsPage';
import { EditPotentialMeetingsPage } from '../user/PotentialMeetingsPage/EditPotentialMeetingsPage';
import { ViewPotentialMeetingsPage } from '../user/PotentialMeetingsPage/ViewPotentialMeetingsPage';
import { OpportunitiesPage } from '../user/OpportunitiesPages/OpportunitiesPage';
import { ViewOpportunitiesPage } from '../user/OpportunitiesPages/ViewOpportunitiesPage'
import { AddOpportunitiesPage } from '../user/OpportunitiesPages/AddOpportunitiesPage'
import { EditOpportunitiesPage } from '../user/OpportunitiesPages/EditOpportunitiesPage'
import { LinkedInFollowUpPage } from '../user/LinkedInFollowUpPages/LinkedInFollowUpPage';
import { ReschedulingPage } from '../user/ReschedulingPage';
import { ColdCallingContactPage } from '../user/ColdCallingContactPage';
import { AddColdCallingContactPage } from '../user/ColdCallingContactPage/AddColdCallingContactPage';
import { CallRecordingsPage } from '../user/CallRecordingsPage';
import { CallHistoryPage } from '../user/CallHistoryPage';
import { ColdCallingReportingPage } from '../user/ColdCallingReportingPage';
import { AddSalesHiveContactPage } from '../user/AddSalesHiveContactPage';
import { AccountCustomColumnPage } from '../user/AccountCustomColumnPage';
import { ResponderPage } from '../user/Responder/ResponderPage';
import { Listspage } from '../user/Listspage';
import { AddListspage } from '../user/Listspage/AddListspage/AddListspage';
import { EditListspage } from '../user/Listspage/EditListspage/EditListspage';
import { EditResponderPage } from '../user/Responder/EditResponderPage';
import { AddResponderPage } from '../user/Responder/AddResponderPage';
import { UpdateEmailResponderPage } from '../user/Responder/UpdateEmailResponderPage';
import { SeoPage } from '../user/SeoUserPage/SeoPage';
import { EditSeoPage } from '../user/SeoUserPage/EditSeoPage';
import { AddSeoPage } from '../user/SeoUserPage/AddSeoPage';
import { UpdateEmailSeoPage } from '../user/SeoUserPage/UpdateEmailSeoPage';
import { UpdateEmailColdCallersPage } from '../user/ColdCallers/UpdateEmailColdCallersPage'
import { AddColdCallersPage } from '../user/ColdCallers/AddColdCallersPage';
import { ColdCallersPage } from '../user/ColdCallers/ColdCallersPage';
import { EditColdCallersPage } from '../user/ColdCallers/EditColdCallersPage';
import { ColdCallerTeamsPage } from '../user/ColdCallerTeam/ColdCallerTeamsPage';
import { SalesStrategistsPage } from '../user/SalesStrategists/SalesStrategistsPage';
import { EditSalesStrategistsPage } from '../user/SalesStrategists/EditSalesStrategistsPage';
import { AddSalesStrategistsPage } from '../user/SalesStrategists/AddSalesStrategistsPage';
import { UpdateEmailSalesStrategistsPage } from '../user/SalesStrategists/UpdateEmailSalesStrategistsPage';
import { AdminUserPage } from '../user/AdminUser/AdminUserPage';
import { AddAdminUserPage } from '../user/AdminUser/AddAdminUserPage';
import { EditAdminUserPage } from '../user/AdminUser/EditAdminUserPage';
import { UpdateEmailAdminUserPage } from '../user/AdminUser/UpdateEmailAdminUserPage';
import { AccountCategoryPage } from '../user/AccountCategory/AccountCategoryPage';
import { AddAccountsPage } from '../user/AccountCategory/AddAccountPage/AddAccountPage';
import { AddAccountCategoryPage } from '../user/AccountCategory/AddAccountCategoryPage';
import { EditAccountCategoryPage } from '../user/AccountCategory/EditAccountCategoryPage';
import { AccountClientStatusPage } from '../user/AccountClientStatus/AccountClientStatusPage';
import { AddAccountClientStatusPage } from '../user/AccountClientStatus/AddAccountClientStatusPage';
import { EditAccountClientStatusPage } from '../user/AccountClientStatus/EditAccountClientStatusPage';
import { AccountClientStatusOrderPage } from '../user/AccountClientStatus/AccountClientStatusOrderPage';
import { MeetingGoalPage } from '../user/MeetingGoal/MeetingGoalPage';
import { AddMeetingGoalPage } from '../user/MeetingGoal/AddMeetingGoalPage';
import { EditMeetingGoalPage } from '../user/MeetingGoal/EditMeetingGoalPage';
import { VariablePage } from '../user/VariablePage';
import { CustomVariablePage } from '../user/CustomVariablePage/CustomVariablePage';
import { AddCustomVariablePage } from '../user/CustomVariablePage/AddCustomVariablePage';
import { EditCustomVariablePage } from '../user/CustomVariablePage/EditCustomVariablePage';
import { CallToActionPage } from '../user/CallToActionPage';
import { CustomVariableSetPage } from '../user/CustomVariableSetPage';
import { EmailHistoryPage } from '../user/EmailHistoryPage';
import { EmailResponcesPage } from '../user/EmailResponces';
import { MeetingStatusesPage } from '../user/MeetingStatuses/MeetingStatusesPage';
import { AddMeetingStatusesPage } from '../user/MeetingStatuses/AddMeetingStatusesPage';
import { EditMeetingStatusesPage } from '../user/MeetingStatuses/EditMeetingStatusesPage';
import { MeetingStatusesOrderPage } from '../user/MeetingStatuses/MeetingStatusesOrderPage';
import { MeetingOwnerPage } from '../user/MeetingOwner/MeetingOwnerPage';
import { AddMeetingOwnerPage } from '../user/MeetingOwner/AddMeetingOwnerPage';
import { EditMeetingOwnerPage } from '../user/MeetingOwner/EditMeetingOwnerPage';
import { ActionItemsPage } from '../user/ActionItemsPage';
import { AddActionItemsPage } from '../user/ActionItemsPage/AddActionItemsPage';
import { EditActionItemsPage } from '../user/ActionItemsPage/EditActionItemsPage';
import { DocumentsPage } from '../user/DocumentsPage';
import { AddDocumentsPage } from '../user/DocumentsPage/AddDocumentsPage';
import { EditDocumentsPage } from '../user/DocumentsPage/EditDocumentsPage';
import { DirectMailPage } from '../user/DirectMailPage';
import { AddDirectMailPage } from '../user/DirectMailPage/AddDirectMailPage';
import { EditDirectMailPage } from '../user/DirectMailPage/EditDirectMailPage';
import { ResearchPage } from '../user/ResearchPage';
import { AddResearchPage } from '../user/ResearchPage/AddResearchPage';
import { EditResearchPage } from '../user/ResearchPage/EditResearchPage';
import { InsightsPage } from '../user/InsightsPage';
import { CampaignSummaryPage } from '../user/CampaignSummaryPage';
import { AutomatorPage } from '../user/AutomatorPage';
import { DespammerPage } from '../user/DespammerPage';
import { EmailValidationPage } from '../user/EmailValidationPage';
import { DeliverabilityTestingPage } from '../user/DeliverabilityTestingPage';
import { AddDeliverabilityTestingPage } from '../user/DeliverabilityTestingPage/AddDeliverabilityTestingPage';
import { EditDeliverabilityTestingPage } from '../user/DeliverabilityTestingPage/EditDeliverabilityTestingPage';
import { OpenRateReportingPage } from '../user/OpenRateReportingPage';
import { ClickTrackReportingPage } from '../user/ClickTrackReportingPage';
import { GlobalBouncePage } from '../user/GlobalBouncePage';
import { EmailWarmUpPage } from '../user/EmailWarmUpPage';
import { EditEmailWarmUpPage } from '../user/EmailWarmUpPage/EditEmailWarmUpPage';
import { EmailWarmUpHistoryPage } from '../user/EmailWarmUpPage/EmailWarmUpHistoryPage';
import { ContactBulkImportRequestPage } from '../user/ContactBulkImportRequestPage';
import { ContactBulkExportRequestPage } from '../user/ContactBulkExportRequestPage';
import { ObjectionsPage } from '../user/ObjectionsPage';
import { AddObjectionsPage } from '../user/ObjectionsPage/AddObjectionsPage';
import { EditObjectionsPage } from '../user/ObjectionsPage/EditObjectionsPage';
import { UsersPerClientPage } from '../user/UsersPerClientPage';
import { EditUsersPerClientPage } from '../user/UsersPerClientPage/EditUsersPerClientPage';
import { AddUsersPerClientPage } from '../user/UsersPerClientPage/AddUsersPerClientPage';
import { UpdateUsersPerClientPage } from '../user/UsersPerClientPage/UpdateUsersPerClientPage';
import { ClientpocPage } from '../user/ClientpocPages/ClientpocPage';
import { AddClientPocPage } from '../user/ClientpocPages/AddClientpocPage';
import { EditClientPocPage } from '../user/ClientpocPages/EditClientpocPage';
import { ClientPocSetOrder } from '../user/ClientpocPages/SetOrderClientpocPage';
// import { ContactReasonUnqualifiedPage } from '../user/ContactReasonUnqualified/ContactReasonUnqualifiedPage'; 
import { ContactSourcePage } from '../user/ContactSourcePage/ContactSource/ContactSourcePage';
import { AddContactSourcePage } from '../user/ContactSourcePage/AddContactSourcePage/AddContactSourcePage'
import { EditContactSourcePage } from '../user/ContactSourcePage/EditContactSourcePage/EditContactSourcePage'
import { ContactSourceSetOrder } from '../user/ContactSourcePage/ContactSourceSetOrderPage/ContactSourceSetOrder'
import { TagPage } from '../user/TagPage';
import { AddTagPage } from '../user/TagPage/AddTagPage';
import { BlacklistsPage } from '../user/BlacklistsPage';
import { EmailAccountsPage } from '../user/EmailAccountsPage';
import { AddEmailAccountsPage } from '../user/EmailAccountsPage/AddEmailAccountsPage';
import { EditEmailAccountsPage } from '../user/EmailAccountsPage/EditEmailAccountsPage';
import { IndexCampaignCategoriesPage } from '../user/CampaignCategories/IndexCampaignCategoriesPage';
import { AddCampaignCategoriesPage } from '../user/CampaignCategories/AddCampaignCategoriesPage';
import { AddCampaignInCategoriesPage } from '../user/CampaignCategories/AddCampaignInCategoriesPage';
import { EditCampaignCategoriesPage } from '../user/CampaignCategories/EditCampaignCategoriesPage';
import { IndexSendingSchedularPage } from '../user/SendingSchedular/IndexSendingSchedularPage';
import { AddSendingSchedularPage } from '../user/SendingSchedular/AddSendingSchedularPage';
import { EditSendingSchedularPage } from '../user/SendingSchedular/EditSendingSchedularPage';
import { EmailSignaturePage } from '../user/EmailSignaturePage';
import { CountryBlacklistPage } from '../user/CountryBlacklistPage';
import { IndexEngagementStatusPage } from '../user/EngagementStatus/IndexEngagementStatusPage';
import { AddEngagementStatusPage } from '../user/EngagementStatus/AddEngagementStatusPage';
import { EditEngagementStatusPage } from '../user/EngagementStatus/EditEngagementStatusPage';
import { EngagementStatusOrderPage } from '../user/EngagementStatus/EngagementStatusOrderPage';
import { AddCountryBlacklistPage } from '../user/CountryBlacklistPage/AddCountryBlacklistPage';
import { EditCountryBlacklistPage } from '../user/CountryBlacklistPage/EditCountryBlacklistPage';
import { IndexCountryBlacklistPage } from '../user/CountryBlacklistPage/IndexCountryBlacklistPage';
import { MeetingCustomFieldPage } from '../user/MeetingCustomField/MeetingCustomFieldPage';
import { AddMeetingCustomFieldPage } from '../user/MeetingCustomField/AddMeetingCustomFieldPage';
import { EditMeetingCustomFieldPage } from '../user/MeetingCustomField/EditMeetingCustomFieldPage';
import { FirstViewApiKeyPage } from '../user/ApiKeyPages/FirstViewApiKeyPage';
import { ApikeyPage } from '../user/ApiKeyPages/ApikeyPage';
import { EditApiKeyPage } from '../user/ApiKeyPages/EditApiKeyPage';
import { ViewApiKeyPage } from '../user/ApiKeyPages/ViewApiKeyPage';
import { ClientsPage } from '../user/ClientsPage';
import { AddClientsPage } from '../user/ClientsPage/AddClientsPage';
import { EditClientsPage } from '../user/ClientsPage/EditClientsPage';
import { ClientAccountPage } from '../user/ClientAccountPage';
import { ClientContactPage } from '../user/ClientContactPage';
import { ClientCampaignsPage } from '../user/ClientCampaignsPage';
import { ClientResponsesPage } from '../user/ClientResponsesPage';
import { ClientLinkedInFollowUpPage } from '../user/ClientLinkedInFollowUpPage';
import { ClientEmailAccountsPage } from '../user/ClientEmailAccountsPage/ClientEmailAccountsPage';
import { CampaignsPage } from '../user/CampaignsPage';
import { AddCampaignsPage } from '../user/CampaignsPage/AddCampaignsPage';
import { EditCampaignsPage } from '../user/CampaignsPage/EditCampaignsPage/EditCampaignsPage';
import { ClientMeetingsPage } from '../user/ClientMeetingsPage';
import { ClientMeetingReminderErrorsPage } from '../user/ClientMeetingReminderErrorsPage'
import { AddClientMeetingsPage } from '../user/ClientMeetingsPage/AddClientMeetingsPage';
import { ClientReschedulingPage } from '../user/ClientReschedulingPage';
import { ClientPotentialMeetingsPage } from '../user/ClientPotentialMeetingsPage';
import { AddClientPotentialMeetingsPage } from '../user/ClientPotentialMeetingsPage/AddClientPotentialMeetingsPage/AddClientPotentialMeetingsPage';
import { ViewClientPotentialMeetingsPage } from '../user/ClientPotentialMeetingsPage/ViewClientPotentialMeetingsPage/ViewClientPotentialMeetingsPage';
import { EditClientPotentialMeetingsPage } from '../user/ClientPotentialMeetingsPage/EditClientPotentialMeetingsPage/EditClientPotentialMeetingsPage';
import { ClientKPIsPage } from '../user/ClientKPIsPage';
import { LeaderBoardPage } from '../user/LeaderBoardPage';
import { PendingInvitesPage } from '../user/PendingInvitesPage/PendingInvitesPage';
import { VariableReportingPage } from '../user/VariableReportingPage/VariableReportingPage';
import { UserPerClientsPage } from '../user/UserPerClientsPage/UserPerClientsPage';
import { ClientActionItemsPage } from '../user/ClientActionItemsPage/ClientActionItemsPage';
import { AddClientActionItemsPage } from '../user/ClientActionItemsPage/AddClientActionItemsPage';
import { EditClientActionItemsPage } from '../user/ClientActionItemsPage/EditClientActionItemsPage';
import { ClientDirectMailPage } from '../user/ClientDirectMailPage/ClientDirectMailPage';
import { AddClientDirectMailPage } from '../user/ClientDirectMailPage/AddClientDirectMailPage';
import { EditClientDirectMailPage } from '../user/ClientDirectMailPage/EditClientDirectMailPage';
import { ClientResearchPage } from '../user/ClientResearchPage/ClientResearchPage';
import { AddClientResearchPage } from '../user/ClientResearchPage/AddClientResearchPage/AddClientResearchPage';
import { EditClientResearchPage } from '../user/ClientResearchPage/EditClientResearchPage/EditClientResearchPage';
import { ClientLoginPage } from '../user/ClientLoginPage/ClientLoginPage';
import { EditClientLoginPage } from '../user/ClientLoginPage/EditClientLoginPage/EditClientLoginPage';
import { ClientAPIKeysPage } from '../user/ClientAPIKeysPage/ClientAPIKeysPage';
import { ClientStatusesPage } from '../user/ClientStatusesPage/ClientStatus/ClientStatusesPage';
import { AddClientStatusPage } from '../user/ClientStatusesPage/AddClientStatusPage/AddClientStatusPage'
import { EditClientStatusPage } from '../user/ClientStatusesPage/EditClientStatusPage/EditClientStatusPage'
import { ClientStatusSetOrderPage } from '../user/ClientStatusesPage/ClientStatusSetOrderPage/ClientStatusSetOrderPage'
import { ResearchTeamsPage } from '../user/ResearchTeamsPage/ResearchTeamsPage';
import { AddResearchTeamsPage } from '../user/ResearchTeamsPage/AddResearchTeamsPage/AddResearchTeamsPage';
import { EditResearchTeamsPage } from '../user/ResearchTeamsPage/EditResearchTeamsPage/EditResearchTeamsPage';
import { ContactTagPage } from '../user/ContactTag/ContactTags';
import { AddContactTagPage } from '../user/ContactTag/AddContactTag';
import { EditContactTagPage } from '../user/ContactTag/EditContactTag';
import { CoreWebVitalsPage } from '../user/CoreWebVitalsPage/CoreWebVitalsPage';
import { BacklinksPage } from '../user/BacklinksPage/BacklinksPage';
import { KeywordsPage } from '../user/KeywordsPage/KeywordsPage';
import { BacklinksPipelinePage } from '../user/BacklinksPipeline/BacklinksPipeline';
import { AddBacklinksPipeline } from '../user/BacklinksPipeline/AddBacklinksPipeline/AddBacklinksPipeline.jsx';
import { EditBacklinksPipeline } from '../user/BacklinksPipeline/EditBacklinksPipeline/EditBacklinksPipeline.jsx';

import { ContentPipelinePage } from '../user/ContentPipeline/ContentPipeline';
import { AddContentPipeline } from '../user/ContentPipeline/AddContentPipeline/AddContentPipeline.jsx';
import { EditContentPipeline } from '../user/ContentPipeline/EditContentPipeline/EditContentPipeline.jsx';
import { SeoOverviewPage } from '../user/SeoOverview';



import { DefaultVariablesPage } from '../user/DefaultVariablesPage/DefaultVariablesPage';
import { GlobalBounceListPage } from '../user/GlobalBounceListPage/GlobalBounceListPage';
import { AddGlobalBounceListPage } from '../user/GlobalBounceListPage/AddGlobalBounceListPage';
import { EditGlobalBounceListPage } from '../user/GlobalBounceListPage/EditGlobalBounceListPage';
import { GenericEmailDomainPage } from '../user/GenericEmailDomainPage/GenericEmailDomainPage';
import { AddGenericEmailDomainPage } from '../user/GenericEmailDomainPage/AddGenericEmailDomainPage';
import { EditGenericEmailDomainPage } from '../user/GenericEmailDomainPage/EditGenericEmailDomainPage';
import { DefaultObjectionsPage } from '../user/DefaultObjectionsPage/DefaultObjectionsPage';
import { AddDefaultObjectionsPage } from '../user/DefaultObjectionsPage/AddDefaultObjectionsPage';
import { EditDefaultObjectionsPage } from '../user/DefaultObjectionsPage/EditDefaultObjectionsPage';
import { DefaultTrackingDomainPage } from '../user/DefaultTrackingDomainPage/DefaultTrackingDomainPage';
import { AddDefaultTrackingDomainPage } from '../user/DefaultTrackingDomainPage/AddDefaultTrackingDomainPage';
import { EditDefaultTrackingDomainPage } from '../user/DefaultTrackingDomainPage/EditDefaultTrackingDomainPage';
import { DeliverabilityTrackingPage } from '../user/DeliverabilityTrackingPage';
import { SalesRepPage } from '../user/SalesRepPage/SalesRepPage';
import { AddSalesRepPage } from '../user/SalesRepPage/AddSalesRepPage';
import { EditSalesRepPage } from '../user/SalesRepPage/EditSalesRepPage';

import { TriggerPhrasesPage } from '../user/TriggerPhrasesPage/TriggerPhrasesPage';
import { SmartVariablePage } from '../user/SmartVariablePage/SmartVariablePage';
import { GlobalBlacklistPage } from '../user/GlobalBlacklistPage/GlobalBlacklistPage';
import { AddGlobalBlacklistPage } from '../user/GlobalBlacklistPage/AddGlobalBlacklistPage';
import { EditGlobalBlacklistPage } from '../user/GlobalBlacklistPage/EditGlobalBlacklistPage';
import { AddGlobalContactBlacklistPage } from '../user/GlobalBlacklistContactPage/AddGlobalContactBlacklistPage';
import { EditGlobalContactBlacklistPage } from '../user/GlobalBlacklistContactPage/EditGlobalContactBlacklistPage';
import { DefaultCountryBlacklistPage } from '../user/DefaultCountryBlacklistPage/DefaultCountryBlacklistPage';
import { AddDefaultCountryBlacklistPage } from '../user/DefaultCountryBlacklistPage/AddDefaultCountryBlacklistPage';
import { EditDefaultCountryBlacklistPage } from '../user/DefaultCountryBlacklistPage/EditDefaultCountryBlacklistPage';
import { ColdCallerPhoneBurnerLoginPage } from '../user/ColdCallerPhoneBurnerLoginPage/ColdCallerPhoneBurnerLoginPage';
import { DefaultSlackNotificationPage } from '../user/DefaultSlackNotificationPage/DefaultSlackNotificationPage';
import { MeetingSlackNotificationPage } from '../user/MeetingSlackNotificationPage/MeetingSlackNotificationPage';
import { CustomMeetingPage } from '../user/CustomMeetingPage/CustomMeetingPage';
import { AddCustomMeetingPage } from '../user/CustomMeetingPage/AddCustomMeetingPage/AddCustomMeetingPage';
import { EditCustomMeetingPage } from '../user/CustomMeetingPage/EditCustomMeetingPage/EditCustomMeetingPage';
import { EditClientPage } from '../user/EditClientPage/EditClientPage';

import { CkpisPage } from '../clients/CkpisPage/CkpisPage';
import { CAccountsPage } from '../clients/CAccountsPage/CAccountsPage';
import { CAddAccountPage } from '../clients/CAccountsPage/CAddAccountPage/CAddAccountPage';
import { CEditAccountsPage } from '../clients/CAccountsPage/CEditAccountsPage/CEditAccountsPage';
import { CViewAccountPage } from '../clients/CAccountsPage/CViewAccountPage/CViewAccountPage';
import { CcontactsPage } from '../clients/CcontactsPage/CcontactsPage';
import { CAddContactPage } from '../clients/CcontactsPage/CAddContactPage/CAddContactPage';
import { CEditContactPage } from '../clients/CcontactsPage/CEditContactPage/CEditContactPage';
import { CViewContactPage } from '../clients/CcontactsPage/CViewContactPage/CViewContactPage';
import { CPotentialMeetingsPage } from '../clients/CPotentialMeetingsPage/CPotentialMeetingsPage';
import { CAddPotentialMeetingsPage } from '../clients/CPotentialMeetingsPage/CAddPotentialMeetingsPage';
import { CViewPotentialMeetingsPage } from '../clients/CPotentialMeetingsPage/CViewPotentialMeetingsPage';
import { CEditPotentialMeetingsPage } from '../clients/CPotentialMeetingsPage/CEditPotentialMeetingsPage';
import { CBookedMeetingsPage } from '../clients/CBookedMeetingsPage/CBookedMeetingsPage';
import { CAddBookedMeetingPage } from '../clients/CBookedMeetingsPage/CAddBookedMeetingPage/CAddBookedMeetingPage';
import { CEditBookedMeetingPage } from '../clients/CBookedMeetingsPage/CEditBookedMeetingPage/CEditBookedMeetingPage';
import { CViewBookedMeetingPage } from '../clients/CBookedMeetingsPage/CViewBookedMeetingPage/CViewBookedMeetingPage';
import { COpportunitiesPage } from '../clients/COpportunitiesPage/COpportunitiesPage';
import { CAddOpportunitiesPage } from '../clients/COpportunitiesPage/CAddOpportunitiesPage';
import { CEditOpportunitiesPage } from '../clients/COpportunitiesPage/CEditOpportunitiesPage';
import { CViewOpportunitiesPage } from '../clients/COpportunitiesPage/CViewOpportunitiesPage';
import { CLinkedInFollowUpPage } from '../clients/CLinkedInFollowUpPage/CLinkedInFollowUpPage';
import { CReschedulingPage } from '../clients/CReschedulingPage/CReschedulingPage';
import { CCampaignsPage } from '../clients/CCampaignsPage/CCampaignsPage';
import { CAddCampaignsPage } from '../clients/CCampaignsPage/CAddCampaignsPage/CAddCampaignsPage';
import { CEditCampaignsPage } from '../clients/CCampaignsPage/CEditCampaignsPage/CEditCampaignsPage';
import { CColdCallingContactPage } from '../clients/CColdCallingContactPage/ColdCallingContactPage';
import { CColdCallingReportingPage } from '../clients/CColdCallingReportingPage/ColdCallingReportingPage';
import { CCallHistoryPage } from '../clients/CCallHistoryPage/CallHistoryPage';
import { CCallRecordingsPage } from '../clients/CCallRecordingsPage/CallRecordingsPage';

import { CListsPage } from '../clients/CListsPage/CListsPage';
import { CAddListspage } from '../clients/CListsPage/CAddListspage';
import { CEditListspage } from '../clients/CListsPage/CEditListspage';
import { CVariablePage } from '../clients/CVariablePage/CVariablePage';
import { CCustomVariablePage } from '../clients/CCustomVariablePage/CCustomVariablePage';
import { CAddCustomVariablePage } from '../clients/CCustomVariablePage/CAddCustomVariablePage';
import { CEditCustomVariablePage } from '../clients/CCustomVariablePage/CEditCustomVariablePage';
import { CCustomVariableSetPage } from '../clients/CCustomVariableSetPage/CCustomVariableSetPage';
import { CCallToActionPage } from '../clients/CCallToActionPage/CCallToActionPage';
import { CEmailHistoryPage } from '../clients/CEmailHistoryPage/CEmailHistoryPage';
import { CEmailResponcesPage } from '../clients/CEmailResponces/CEmailResponces';
import { CDocumentsPage } from '../clients/CDocumentsPage/CDocumentsPage';
import { CAddDocumentsPage } from '../clients/CDocumentsPage/CAddDocumentsPage';
import { CEditDocumentsPage } from '../clients/CDocumentsPage/CEditDocumentsPage';

import { CUsersPerClientPage } from '../clients/CUsersPerClientPage/CUsersPerClientPage';
import { CEditUsersPerClientPage } from '../clients/CUsersPerClientPage/CEditUsersPerClientPage';
import { CAddUsersPerClientPage } from '../clients/CUsersPerClientPage/CAddUsersPerClientPage';
import { CUpdateUsersPerClientPage } from '../clients/CUsersPerClientPage/CUpdateUsersPerClientPage';
import { CAccountCategoryPage } from '../clients/CAccountCategoryPage/CAccountCategoryPage';
import { CAddAccountsPage } from '../clients/CAccountCategoryPage/CAddAccountPage/CAddAccountPage';
import { CAddAccountCategoryPage } from '../clients/CAccountCategoryPage/CAddAccountCategoryPage';
import { CEditAccountCategoryPage } from '../clients/CAccountCategoryPage/CEditAccountCategoryPage';
import { CAccountStatusPage } from '../clients/CAccountStatusPage/CAccountStatusPage';
import { CAddAccountStatusPage } from '../clients/CAccountStatusPage/CAddAccountStatusPage';
import { CEditAccountStatusPage } from '../clients/CAccountStatusPage/CEditAccountStatusPage';
import { CAccountStatusOrderPage } from '../clients/CAccountStatusPage/CAccountStatusOrderPage';
import { CReasonUnqualifiedPage } from '../clients/CReasonUnqualifiedPage/CReasonUnqualifiedPage';
import { CAddReasonUnqualifiedPage } from '../clients/CReasonUnqualifiedPage/CAddReasonUnqualifiedPage';
import { CEditReasonUnqualifiedPage } from '../clients/CReasonUnqualifiedPage/CEditReasonUnqualifiedPage';
import { CReasonUnqualifiedOrderPage } from '../clients/CReasonUnqualifiedPage/CReasonUnqualifiedOrderPage';
import { CCustomAccountsFieldPage } from '../clients/CCustomAccountsFieldPage/CCustomAccountsFieldPage';
import { CAddCustomAccountsFieldPage } from '../clients/CCustomAccountsFieldPage/CAddCustomAccountsFieldPage';
import { CEditCustomAccountsFieldPage } from '../clients/CCustomAccountsFieldPage/CEditCustomAccountsFieldPage';
import { CContactReasonUnqualifiedPage } from '../clients/CContactReasonUnqualifiedPage/CContactReasonUnqualifiedPage';
import { CAddContactReasonUnqualifiedPage } from '../clients/CContactReasonUnqualifiedPage/CAddContactReasonUnqualifiedPage';
import { CEditContactReasonUnqualifiedPage } from '../clients/CContactReasonUnqualifiedPage/CEditContactReasonUnqualifiedPage';
import { CContactReasonUnqualifiedSetOrderPage } from '../clients/CContactReasonUnqualifiedPage/CContactReasonUnqualifiedSetOrderPage/CContactReasonUnqualifiedSetOrderPage';
import { CClientPOCPage } from '../clients/CClientPOCPage/CClientPOCPage';
import { CAddClientPOCPage } from '../clients/CClientPOCPage/CAddClientPOCPage';
import { CEditClientPOCPage } from '../clients/CClientPOCPage/CEditClientPOCPage';
import { CClientPOCSetOrderPage } from '../clients/CClientPOCPage/CClientPOCSetOrderPage/CClientPOCSetOrderPage';
import { CContactSourcepage } from '../clients/CContactSourcepage/CContactSourcepage';
import { CAddContactSourcepage } from '../clients/CContactSourcepage/CAddContactSourcepage';
import { CEditContactSourcepage } from '../clients/CContactSourcepage/CEditContactSourcepage';
import { CContactSourceSetOrderPage } from '../clients/CContactSourcepage/CContactSourceSetOrderPage/CContactSourceSetOrderPage';
import { CContactsCustomFieldpage } from '../clients/CCustomContactsFieldpage/CContactsCustomFieldpage';
import { CAddContactsCustomFieldpage } from '../clients/CCustomContactsFieldpage/CAddContactsCustomFieldpage';
import { CEditContactsCustomFieldpage } from '../clients/CCustomContactsFieldpage/CEditContactsCustomFieldpage';
import { CContactTagpage } from '../clients/CContactTagpage/CContactTagpage';
import { CAddContactTagpage } from '../clients/CContactTagpage/CAddContactTagpage';
import { CEditContactTagpage } from '../clients/CContactTagpage/CEditContactTagpage';
import { CBlacklistsPage } from '../clients/CBlacklistsPage/CBlacklistsPage';
import { CEmailAccountsPage } from '../clients/CEmailAccountsPage/CEmailAccountsPage';
import { EditCEmailAccountsPage } from '../clients/CEmailAccountsPage/EditCEmailAccountsPage';
import { AddCEmailAccountsPage } from '../clients/CEmailAccountsPage/AddCEmailAccountsPage';
import { CCampaignCategoriesPage } from '../clients/CCampaignCategoriesPage/CCampaignCategoriesPage';
import { CEditCampaignCategoriesPage } from '../clients/CCampaignCategoriesPage/CEditCampaignCategoriesPage';
import { CAddCampaignCategoriesPage } from '../clients/CCampaignCategoriesPage/CAddCampaignCategoriesPage';
import { CAddCampaignInCategoriesPage } from '../clients/CCampaignCategoriesPage/CAddCampaignInCategoriesPage';
import { CSendingSchedularPage } from '../clients/CSendingSchedularPage/CSendingSchedularPage';
import { CAddSendingSchedularPage } from '../clients/CSendingSchedularPage/CAddSendingSchedularPage';
import { CEditSendingSchedularPage } from '../clients/CSendingSchedularPage/CEditSendingSchedularPage';
import { CEmailSignaturePage } from '../clients/CEmailSignaturePage/CEmailSignaturePage';
import { CDefaultCountryBlackListPage } from '../clients/CDefaultCountryBlackListPage/CDefaultCountryBlackListPage';
import { CAddDefaultCountryBlacklistPage } from '../clients/CDefaultCountryBlackListPage/CAddDefaultCountryBlacklistPage';
import { CEditDefaultCountryBlacklistPage } from '../clients/CDefaultCountryBlackListPage/CEditDefaultCountryBlacklistPage';
import { CMeetingOwnerPage } from '../clients/CMeetingOwnerPage/CMeetingOwnerPage';
import { CAddMeetingOwnerPage } from '../clients/CMeetingOwnerPage/CAddMeetingOwnerPage';
import { CEditMeetingOwnerPage } from '../clients/CMeetingOwnerPage/CEditMeetingOwnerPage';
import { CMeetingGoalPage } from '../clients/CMeetingGoalPage/CMeetingGoalPage';
import { CAddMeetingGoalPage } from '../clients/CMeetingGoalPage/CAddMeetingGoalPage';
import { CEditMeetingGoalPage } from '../clients/CMeetingGoalPage/CEditMeetingGoalPage';
import { CMeetingStatusPage } from '../clients/CMeetingStatusPage/CMeetingStatusPage';
import { CAddMeetingStatusPage } from '../clients/CMeetingStatusPage/CAddMeetingStatusPage';
import { CEditMeetingStatusPage } from '../clients/CMeetingStatusPage/CEditMeetingStatusPage';
import { CMeetingStatusesOrderPage } from '../clients/CMeetingStatusPage/CMeetingStatusesOrderPage';

import { CMeetingSourcePage } from '../clients/CMeetingSourcePage/CMeetingSourcePage';
import { CAddMeetingSourcePage } from '../clients/CMeetingSourcePage/CAddMeetingSourcePage';
import { CEditMeetingSourcePage } from '../clients/CMeetingSourcePage/CEditMeetingSourcePage';
import { CMeetingReminderPage } from '../clients/CMeetingReminderPage/CMeetingReminderPage';
import { CSlackNotificationsPage } from '../clients/CSlackNotificationsPage/CSlackNotificationsPage';
import { CCustomMeetingPage } from '../clients/CCustomMeetingPage/CCustomMeetingPage';
import { CAddCustomMeetingPage } from '../clients/CCustomMeetingPage/CAddCustomMeetingPage';
import { CEditCustomMeetingPage } from '../clients/CCustomMeetingPage/CEditCustomMeetingPage';
import { CPotentialMeetingStatusPage } from '../clients/CPotentialMeetingStatusPage/CPotentialMeetingStatusPage';
import { CAddPotentialMeetingStatusPage } from '../clients/CPotentialMeetingStatusPage/CAddPotentialMeetingStatusPage';
import { CEditPotentialMeetingStatusPage } from '../clients/CPotentialMeetingStatusPage/CEditPotentialMeetingStatusPage';
import { CPotentialMeetingStatusOrderPage } from '../clients/CPotentialMeetingStatusPage/CPotentialMeetingStatusOrderPage'
import { CApiKeyPage } from '../clients/CApiKeyPage/CApiKeyPage';
import { CCampaignSummaryPage } from '../clients/CCampaignSummaryPage/CCampaignSummaryPage';
import { CDespammerPage } from '../clients/CDespammerPage/CDespammerPage';
import { CEmailValidationPage } from '../clients/CEmailValidationPage/CEmailValidationPage';
import { CEditClientPage } from '../clients/CEditClientPage/CEditClientPage';

import { MeetingSourcesPage } from '../user/MeetingSources/MeetingSourcesrPage/MeetingSourcesPage'
import { AddMeetingSourcesPage } from '../user/MeetingSources/AddMeetingSourcesPage/AddMeetingSourcesPage'
import { EditMeetingSourcesPage } from '../user/MeetingSources/EditMeetingSourcesPage/EditMeetingSourcesPage'

import { MeetingReminderPage } from '../user/MeetingReminderPage/MeetingReminderPage'

import { PotentialMeetingStatusPage } from '../user/PotentialMeetingStatusPage/PotentialMeetingStatusPage/PotentialMeetingStatusPage.jsx'
import { AddPotentialMeetingStatusPage } from '../user/PotentialMeetingStatusPage/AddPotentialMeetingStatusPage/AddPotentialMeetingStatusPage'
import { EditPotentialMeetingStatusPage } from '../user/PotentialMeetingStatusPage/EditPotentialMeetingStatusPage/EditPotentialMeetingStatusPage'

import { CoReasonUnqualifiedPage } from '../user/ContactReasonUnqualified/ContactReasonUnqualifiedPage/CoReasonUnqualifiedPage'
import { AddContactReasonUnqualifiedPage } from '../user/ContactReasonUnqualified/AddContactReasonUnqualifiedPage/AddContactReasonUnqualifiedPage'
import { EditContactReasonUnqualifiedPage } from '../user/ContactReasonUnqualified/EditContactReasonUnqualifiedPage/EditContactReasonUnqualifiedPage'

import { ContactReasonSetOrderPage } from '../user/ContactReasonUnqualified/ContactReasonSetOrderPage/ContactReasonSetOrderPage'
import { PotentialMeetingStatusOrderPage } from '../user/PotentialMeetingStatusPage/PotentialMeetingStatusOrderPage/PotentialMeetingStatusOrderPage'


import { AccountReasonUnqualifiedPage } from '../user/AccountReasonUnqualified/AccountReasonUnqualifiedPage/AccountReasonUnqualifiedPage'
import { AddAccountReasonUnqualified } from '../user/AccountReasonUnqualified/AddAccountReasonUnqualified/AddAccountReasonUnqualified'
import { AccountReasonSetOrderPage } from '../user/AccountReasonUnqualified/AccountReasonSetOrder/AccountReasonSetOrderPage'
import { EditAccountReasonUnqualified } from '../user/AccountReasonUnqualified/EditAccountReasonUnqualified/EditAccountReasonUnqualified'

import { Accountcustomfield } from '../user/AccountCustomfield/Accountcustomfield/Accountcustomfield'
import { AddAccountcustomfield } from '../user/AccountCustomfield/AddAccountCustomField/AddAccountcustomfield'
import { EditAccountCustomfield } from '../user/AccountCustomfield/EditAccountCustomField/EditAccountCustomfield'

import { ContactCustomfieldPage } from '../user/ContactCustomfield/ContactCustomfieldPage/ContactCustomfieldPage'
import { AddContactCustomPage } from '../user/ContactCustomfield/AddContactCustomfieldPage/AddContactCustomPage'
import { EditContactCustomPage } from '../user/ContactCustomfield/EditContactCustomfieldPage/EditContactCustomPage'

import { ContactCustomColumnPage } from '../user/ContactCustomColumnPage/ContactCustomColumnPage'
import { CContactCustomColumnPage } from '../clients/CContactCustomColumnPage/CContactCustomColumnPage'
import { BookedMeetingCustomColumnPage } from '../user/BookedMeetingCustomColumnPage/BookedMeetingCustomColumnPage'
import { CAccountCustomColumnPage } from '../clients/CAccountCustomColumnPage/CAccountCustomColumnPage'

import { AddColdCallerTeam } from '../user/ColdCallerTeam/AddColdCallerTeam/AddColdCallerTeam'
import { EditColdCallerTeam } from '../user/ColdCallerTeam/EditColdCallerTeam/EditColdCallerTeam'
import { HiveAiPage } from '../user/HiveAiPage/HiveAiPage'
import { EmodProfilePage } from '../user/EmodProfilePage/EmodProfilePage'
import { AddEmodProfilePage } from '../user/EmodProfilePage/AddEmodProfilePage/AddEmodProfilePage'
import { EditEmodProfilePage } from '../user/EmodProfilePage/EditEmodProfilePage/EditEmodProfilePage'

import { CEditMyProfilePage } from '../clients/CMyProfile/CEditMyProfilePage'

import ChatbotComponent from "../_components/common/ChatbotComponent.js"
import Cookies from 'js-cookie';
class App extends React.Component {
    constructor(props) {
        super(props);

    }
    

    componentDidMount() {
        
     window.addEventListener('focus', this.handleFocus);
       const pathname = window.location.pathname;
       if (pathname == "/") {
            var Details = GetUserDetails();
            if (Details != null) {
                history.push("/kpis");
            }
            else {
                history.push("/");
            }
        }
        if(pathname !== "/addresponder" && pathname !== "/addcoldcallers" && pathname !== "/addadmin" && 
        pathname !== "/addsalesstrategists" && pathname !== "/addclientusers" && pathname !== "/addseo" && pathname !== "/resetpassword" &&  
        pathname !== "/updateemailresponder" && pathname !== "/updateemailcoldcallers" && pathname !== "/updateemailstrategists" && 
        pathname !== "/updateemailadmin" && pathname !== "/updateusersperclient" && pathname !== "/updateemailseo"){
            this.checkLoginStatus()
        }
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
    }
    checkLoginStatus = () => {
        const loginTimestamp = localStorage.getItem('loginTimestamp');
        const pathname = window.location.pathname;
        

        if (!loginTimestamp) {
            // No login timestamp found, redirect to login page
            this.redirectToLogin();
            return;
        }

        const loginDate = new Date(loginTimestamp);
        const now = new Date();

        // Compare the stored date with the current date
        if (this.isDifferentDay(loginDate, now)) {
            // It's a new day, redirect to login page
            this.redirectToLogin();
        } else {
            // User is logged in for the current day
            
        }
    };

    isDifferentDay = (date1, date2) => {
        return date1.getFullYear() !== date2.getFullYear() ||
               date1.getMonth() !== date2.getMonth() ||
               date1.getDate() !== date2.getDate();
    };

    redirectToLogin = () => {
        // Clear the stored login timestamp
        localStorage.removeItem('loginTimestamp');
        localStorage.removeItem('LoginData');

        // Redirect to the login page
        history.push('/login');
    };

    handleFocus = () => {
        if (Cookies.get('dialSession') === 'Started') {
          Cookies.remove('dialSession', { path: '/' });
          setTimeout(() => {
            window.location.reload();
          }, 5000); // 5000 milliseconds = 5 seconds
        }
      };

    handleVisibilityChange = () => {
        
        if (document.visibilityState === 'visible') {
            var Details = GetUserDetails();
            if(window.location.pathname !== "/twostepverification" && Details == null){
                history.push("/");
            }
           else if (Details == null) {
                history.push("/");
            }
        }
    };
  
    render() {
        const { alert } = this.props;
        var Chatbot = <ChatbotComponent /> 
        return (
            <div className="jumbotron table-set">
 
                <Router history={history}>
                    {Chatbot}
                    <ScrollToTop>
                   
                    <Switch>
                        <Route exact path="/home" component={HomePage} />
                        <Route exact path="/register" component={RegisterPage} />
                        <Route exact path="/twostepverification" component={TwoStepVerificationPage} />
                        <Route exact path="/editmyprofile" component={EditMyProfilePage} />
                        <Route exact path="/kpis" component={KPIsPage} />
                        {/* <Route path="/data/:data"  ><KPIsPage/></Route> */}
                        <Route exact path="/accounts" component={AccountsPage} />
                        <Route exact path="/addaccount" component={AddAccountPage} />
                        <Route exact path="/viewaccount" component={ViewAccountPage} />
                        <Route exact path="/editaccount" component={EditAccountsPage} />
                        <Route exact path="/contacts" component={ContactsPage} />
                        <Route exact path="/addcontacts" component={AddContactPage} />
                        <Route exact path="/editcontacts" component={EditContactPage} />
                        <Route exact path="/viewcontacts" component={ViewContactPage} />
                        <Route exact path="/potentialmeeting" component={PotentialMeetingsPage} />
                        <Route exact path="/bookedmeetings" component={BookedMeetingsPage} />
                        <Route exact path="/addbookedmeeting" component={AddBookedMeetingPage} />
                        <Route exact path="/viewbookedmeeting" component={ViewBookedMeetingPage} />
                        <Route exact path="/editbookedmeeting" component={EditBookedMeetingPage} />
                        <Route exact path="/addpotentialmeeting" component={AddPotentialMeetingsPage} />
                        <Route exact path="/editpotentialmeeting" component={EditPotentialMeetingsPage} />
                        <Route exact path="/viewpotentialmeeting" component={ViewPotentialMeetingsPage} />
                        <Route exact path="/opportunities" component={OpportunitiesPage} />
                        <Route exact path="/addoppotunities" component={AddOpportunitiesPage} />
                        <Route exact path="/viewoppotunities" component={ViewOpportunitiesPage} />
                        <Route exact path="/editoppotunities" component={EditOpportunitiesPage} />
                        <Route exact path="/linkedinfollowup" component={LinkedInFollowUpPage} />
                        <Route exact path="/reschedules" component={ReschedulingPage} />
                        <Route exact path="/list" component={Listspage} />
                        <Route exact path="/addlist" component={AddListspage} />
                        <Route exact path="/editlist" component={EditListspage} />
                        <Route exact path="/coldcallingContact" component={ColdCallingContactPage} />
                        <Route exact path="/addcoldcallingContact" component={AddColdCallingContactPage} />
                        <Route exact path="/callrecordings" component={CallRecordingsPage} />
                        <Route exact path="/callhistory" component={CallHistoryPage} />
                        <Route exact path="/coldcallingreporting" component={ColdCallingReportingPage} />
                        <Route exact path="/addsalesHivecontact" component={AddSalesHiveContactPage} />
                        <Route exact path="/accountcustomcolumn" component={AccountCustomColumnPage} />
                        <Route exact path="/responder" component={ResponderPage} />
                        <Route exact path="/editresponder" component={EditResponderPage} />
                        <Route exact path="/addresponder" component={AddResponderPage} />
                        <Route exact path="/updateemailresponder" component={UpdateEmailResponderPage} />
                        <Route exact path="/coldcallers" component={ColdCallersPage} />
                        <Route exact path="/editcoldcallers" component={EditColdCallersPage} />
                        <Route exact path="/addcoldcallers" component={AddColdCallersPage} />
                        <Route exact path="/coldcallerteams" component={ColdCallerTeamsPage} />
                        <Route exact path="/addcoldcallerteams" component={AddColdCallerTeam} />
                        <Route exact path="/editcoldcallerteams" component={EditColdCallerTeam} />


                        <Route exact path="/updateemailcoldcallers" component={UpdateEmailColdCallersPage} />
                        <Route exact path="/salesstrategists" component={SalesStrategistsPage} />
                        <Route exact path="/editsalesstrategists" component={EditSalesStrategistsPage} />
                        <Route exact path="/addsalesstrategists" component={AddSalesStrategistsPage} />
                        <Route exact path="/updateemailstrategists" component={UpdateEmailSalesStrategistsPage} />
                        <Route exact path="/adminuser" component={AdminUserPage} />
                        <Route exact path="/addadmin" component={AddAdminUserPage} />
                        <Route exact path="/editadmin" component={EditAdminUserPage} />
                        <Route exact path="/updateemailadmin" component={UpdateEmailAdminUserPage} />
                        <Route exact path="/seo" component={SeoPage} />
                        <Route exact path="/editseo" component={EditSeoPage} />
                        <Route exact path="/addseo" component={AddSeoPage} />
                        <Route exact path="/updateemailseo" component={UpdateEmailSeoPage} />
                        {/* <Route exact path="/affiliate" component={AffiliatePage} />
                        <Route exact path="/upload" component={UploadPage} />
                        <Route exact path="/dataupdate" component={DataUpdatePage} />
                        <Route exact path="/dataupdatenew" component={DataUpdateNewPage} /> */}
                        <Route exact path="/" component={LoginPage} />
                        <Route exact path="/resetpassword" component={ResetPasswordPage} />
                        {/* <Route exact path="/listing" component={ListingPage} /> */}
                        <Route exact path="/accountcategory" component={AccountCategoryPage} />
                        <Route exact path="/addaccountspage" component={AddAccountsPage} />
                        <Route exact path="/addaccountcategory" component={AddAccountCategoryPage} />
                        <Route exact path="/editaccountcategory" component={EditAccountCategoryPage} />
                        <Route exact path="/accountclientstatus" component={AccountClientStatusPage} />
                        <Route exact path="/addaccountclientstatus" component={AddAccountClientStatusPage} />
                        <Route exact path="/editaccountclientstatus" component={EditAccountClientStatusPage} />
                        <Route exact path="/accountclientstatusorder" component={AccountClientStatusOrderPage} />
                        <Route exact path="/meetinggoal" component={MeetingGoalPage} />
                        <Route exact path="/addmeetinggoal" component={AddMeetingGoalPage} />
                        <Route exact path="/editmeetinggoal" component={EditMeetingGoalPage} />
                        <Route exact path="/variable" component={VariablePage} />
                        <Route exact path="/customvariable" component={CustomVariablePage} />
                        <Route exact path="/addcustomvariable" component={AddCustomVariablePage} />
                        <Route exact path="/editcustomvariable" component={EditCustomVariablePage} />
                        <Route exact path="/calltoaction" component={CallToActionPage} />
                        <Route exact path="/customvariableset" component={CustomVariableSetPage} />
                        <Route exact path="/emailhistory" component={EmailHistoryPage} />
                        <Route exact path="/emailresponses" component={EmailResponcesPage} />
                        <Route exact path="/meetingstatuses" component={MeetingStatusesPage} />
                        <Route exact path="/addmeetingstatuses" component={AddMeetingStatusesPage} />
                        <Route exact path="/editmeetingstatuses" component={EditMeetingStatusesPage} />
                        <Route exact path="/meetingstatusesorder" component={MeetingStatusesOrderPage} />
                        <Route exact path="/meetingowner" component={MeetingOwnerPage} />
                        <Route exact path="/addmeetingowner" component={AddMeetingOwnerPage} />
                        <Route exact path="/editmeetingowner" component={EditMeetingOwnerPage} />
                        <Route exact path="/actionitems" component={ActionItemsPage} />
                        <Route exact path="/addactionitems" component={AddActionItemsPage} />
                        <Route exact path="/editactionitems" component={EditActionItemsPage} />
                        <Route exact path="/documents" component={DocumentsPage} />
                        <Route exact path="/adddocuments" component={AddDocumentsPage} />
                        <Route exact path="/editdocuments" component={EditDocumentsPage} />
                        <Route exact path="/directmail" component={DirectMailPage} />
                        <Route exact path="/adddirectmail" component={AddDirectMailPage} />
                        <Route exact path="/editdirectmail" component={EditDirectMailPage} />
                        <Route exact path="/research" component={ResearchPage} />
                        <Route exact path="/addresearch" component={AddResearchPage} />
                        <Route exact path="/editresearch" component={EditResearchPage} />
                        <Route exact path="/insights" component={InsightsPage} />
                        <Route exact path="/campaignsummary" component={CampaignSummaryPage} />
                        <Route exact path="/automator" component={AutomatorPage} />
                        <Route exact path="/despammer" component={DespammerPage} />
                        <Route exact path="/emailvalidation" component={EmailValidationPage} />
                        <Route exact path="/deliverabilitytesting" component={DeliverabilityTestingPage} />
                        <Route exact path="/adddeliverabilitytesting" component={AddDeliverabilityTestingPage} />
                        <Route exact path="/editdeliverabilitytesting" component={EditDeliverabilityTestingPage} />
                        <Route exact path="/deliverabilitytracking" component={DeliverabilityTrackingPage} />
                        <Route exact path="/openratereporting" component={OpenRateReportingPage} />
                        <Route exact path="/clicktrackreporting" component={ClickTrackReportingPage} />
                        <Route exact path="/globalbounce" component={GlobalBouncePage} />
                        <Route exact path="/emailwarmup" component={EmailWarmUpPage} />
                        <Route exact path="/emailwarmuphistory" component={EmailWarmUpHistoryPage} />
                        <Route exact path="/editemailwarmup" component={EditEmailWarmUpPage} />
                        <Route exact path="/ContactBulkImportRequest" component={ContactBulkImportRequestPage} />
                        <Route exact path="/ContactBulkExportRequest" component={ContactBulkExportRequestPage} />
                        <Route exact path="/objections" component={ObjectionsPage} />
                        <Route exact path="/addobjections" component={AddObjectionsPage} />
                        <Route exact path="/editobjections" component={EditObjectionsPage} />
                        <Route exact path="/usersperclient" component={UsersPerClientPage} />
                        <Route exact path="/addclientusers" component={AddUsersPerClientPage} />
                        <Route exact path="/editusersperclient" component={EditUsersPerClientPage} />
                        <Route exact path="/updateusersperclient" component={UpdateUsersPerClientPage} />
                        <Route exact path="/clientpoc" component={ClientpocPage} />
                        <Route exact path="/addclientpoc" component={AddClientPocPage} />
                        <Route exact path="/editclientpoc" component={EditClientPocPage} />
                        <Route exact path="/setorderclientpoc" component={ClientPocSetOrder} />
                        {/* <Route exact path="/contactreasonunqualified" component={ContactReasonUnqualifiedPage} />   */}
                        <Route exact path="/contactsource" component={ContactSourcePage} />
                        <Route exact path="/addcontactsource" component={AddContactSourcePage} />
                        <Route exact path="/editcontactsource" component={EditContactSourcePage} />
                        <Route exact path="/contactsourcesetOrder" component={ContactSourceSetOrder} />
                        <Route exact path="/tag" component={TagPage} />
                        <Route exact path="/addtag" component={AddTagPage} />
                        <Route exact path="/blacklists" component={BlacklistsPage} />
                        <Route exact path="/emailaccounts" component={EmailAccountsPage} />
                        <Route exact path="/addemailaccounts" component={AddEmailAccountsPage} />
                        <Route exact path="/editemailaccounts" component={EditEmailAccountsPage} />
                        <Route exact path="/campaigncategories" component={IndexCampaignCategoriesPage} />
                        <Route exact path="/addcampaigncategories" component={AddCampaignCategoriesPage} />
                        <Route exact path="/AddCampaigninCategories" component={AddCampaignInCategoriesPage} />
                        <Route exact path="/editcampaigncategories" component={EditCampaignCategoriesPage} />
                        <Route exact path="/sendingschedular" component={IndexSendingSchedularPage} />
                        <Route exact path="/addsendingschedular" component={AddSendingSchedularPage} />
                        <Route exact path="/editsendingschedular" component={EditSendingSchedularPage} />
                        <Route exact path="/emailsignature" component={EmailSignaturePage} />
                        <Route exact path="/countryblacklist" component={CountryBlacklistPage} />
                        <Route exact path="/addcountryblacklist" component={AddCountryBlacklistPage} />
                        <Route exact path="/editcountryblacklist" component={EditCountryBlacklistPage} />
                        <Route exact path="/engagementstatus" component={IndexEngagementStatusPage} />
                        <Route exact path="/addengagementstatus" component={AddEngagementStatusPage} />
                        <Route exact path="/editengagementstatus" component={EditEngagementStatusPage} />
                        <Route exact path="/engagementstatusorder" component={EngagementStatusOrderPage} />
                        {/* <Route exact path="/addcountryblacklist" component={AddCountryBlacklistPage} /> */}
                        <Route exact path="/meetingcustomfield" component={MeetingCustomFieldPage} />
                        <Route exact path="/addmeetingcustomfield" component={AddMeetingCustomFieldPage} />
                        <Route exact path="/editmeetingcustomfield" component={EditMeetingCustomFieldPage} />
                        <Route exact path="/viewapiKeys" component={FirstViewApiKeyPage} />
                        <Route exact path="/apiKey" component={ApikeyPage} />
                        <Route exact path="/editapiKey" component={EditApiKeyPage} />
                        <Route exact path="/viewapiKey" component={ViewApiKeyPage} />
                        <Route exact path="/clients" component={ClientsPage} />
                        <Route exact path="/addclients" component={AddClientsPage} />
                        <Route exact path="/editclients" component={EditClientsPage} />
                        <Route exact path="/clientaccount" component={ClientAccountPage} />
                        <Route exact path="/clientcontact" component={ClientContactPage} />
                        <Route exact path="/clientcampaigns" component={ClientCampaignsPage} />
                        <Route exact path="/clientresponses" component={ClientResponsesPage} />
                        <Route exact path="/clientlinkedinfollowUp" component={ClientLinkedInFollowUpPage} />
                        <Route exact path="/clientemailaccounts" component={ClientEmailAccountsPage} />
                        <Route exact path="/campaigns" component={CampaignsPage} />
                        <Route exact path="/addcampaigns" component={AddCampaignsPage} />
                        <Route exact path="/editcampaigns" component={EditCampaignsPage} />
                        <Route exact path="/clientmeetingremindererros" component={ClientMeetingReminderErrorsPage} />
                        <Route exact path="/clientmeetings" component={ClientMeetingsPage} />
                        <Route exact path="/addclientmeetings" component={AddClientMeetingsPage} />
                        <Route exact path="/clientrescheduling" component={ClientReschedulingPage} />
                        <Route exact path="/clientpotentialmeetings" component={ClientPotentialMeetingsPage} />
                        <Route exact path="/addclientpotentialmeetings" component={AddClientPotentialMeetingsPage} />
                        <Route exact path="/viewclientpotentialmeetings" component={ViewClientPotentialMeetingsPage} />
                        <Route exact path="/editclientpotentialmeetings" component={EditClientPotentialMeetingsPage} />
                        <Route exact path="/clientkpis" component={ClientKPIsPage} />
                        <Route exact path="/leaderboard" component={LeaderBoardPage} />
                        <Route exact path="/pendinginvites" component={PendingInvitesPage} />
                        <Route exact path="/variablereporting" component={VariableReportingPage} />
                        <Route exact path="/userperclients" component={UserPerClientsPage} />
                        <Route exact path="/clientactionitems" component={ClientActionItemsPage} />
                        <Route exact path="/addclientactionitems" component={AddClientActionItemsPage} />
                        <Route exact path="/editclientactionitems" component={EditClientActionItemsPage} />
                        <Route exact path="/clientdirectmail" component={ClientDirectMailPage} />
                        <Route exact path="/addclientdirectmail" component={AddClientDirectMailPage} />
                        <Route exact path="/editclientdirectmail" component={EditClientDirectMailPage} />
                        <Route exact path="/clientresearch" component={ClientResearchPage} />
                        <Route exact path="/addclientresearch" component={AddClientResearchPage} />
                        <Route exact path="/editclientresearch" component={EditClientResearchPage} />
                        <Route exact path="/clientlogin" component={ClientLoginPage} />
                        <Route exact path="/editclientlogin" component={EditClientLoginPage} />
                        <Route exact path="/clientapikeys" component={ApikeyPage} />
                        <Route exact path="/clientstatuses" component={ClientStatusesPage} />
                        <Route exact path="/addclientstatuses" component={AddClientStatusPage} />
                        <Route exact path="/editclientstatuses" component={EditClientStatusPage} />
                        <Route exact path="/setorderclientstatuses" component={ClientStatusSetOrderPage} />
                        <Route exact path="/ResearchTeam" component={ResearchTeamsPage} />
                        <Route exact path="/addResearchTeam" component={AddResearchTeamsPage} />
                        <Route exact path="/editResearchTeam" component={EditResearchTeamsPage} />
                        <Route exact path="/defaultvariables" component={DefaultVariablesPage} />
                        <Route exact path="/globalbouncelist" component={GlobalBounceListPage} />
                        <Route exact path="/addglobalbouncelist" component={AddGlobalBounceListPage} />
                        <Route exact path="/editglobalbouncelist" component={EditGlobalBounceListPage} />
                        <Route exact path="/genericemaildomain" component={GenericEmailDomainPage} />
                        <Route exact path="/addgenericemaildomain" component={AddGenericEmailDomainPage} />
                        <Route exact path="/editgenericemaildomain" component={EditGenericEmailDomainPage} />
                        <Route exact path="/defaultobjections" component={DefaultObjectionsPage} />
                        <Route exact path="/adddefaultobjections" component={AddDefaultObjectionsPage} />
                        <Route exact path="/editdefaultobjections" component={EditDefaultObjectionsPage} />
                        <Route exact path="/defaulttrackingdomain" component={DefaultTrackingDomainPage} />
                        <Route exact path="/adddefaulttrackingdomain" component={AddDefaultTrackingDomainPage} />
                        <Route exact path="/editdefaulttrackingdomain" component={EditDefaultTrackingDomainPage} />
                        <Route exact path="/salesrep" component={SalesRepPage} />
                        <Route exact path="/addsalesrep" component={AddSalesRepPage} />
                        <Route exact path="/editsalesrep" component={EditSalesRepPage} />
                        <Route exact path="/triggerphrases" component={TriggerPhrasesPage} />
                        <Route exact path="/smartvariable" component={SmartVariablePage} />
                        <Route exact path="/globalblacklist" component={GlobalBlacklistPage} />
                        <Route exact path="/addglobalblacklist" component={AddGlobalBlacklistPage} />
                        <Route exact path="/editglobalblacklist" component={EditGlobalBlacklistPage} />
                        <Route exact path="/addglobalblacklistcontact" component={AddGlobalContactBlacklistPage} />
                        <Route exact path="/editglobalblacklistcontact" component={EditGlobalContactBlacklistPage} />
                        <Route exact path="/defaultcountryblacklist" component={DefaultCountryBlacklistPage} />
                        <Route exact path="/adddefaultcountryblacklist" component={AddDefaultCountryBlacklistPage} />
                        <Route exact path="/editdefaultcountryblacklist" component={EditDefaultCountryBlacklistPage} />
                        <Route exact path="/coldcallerphoneburnerlogin" component={ColdCallerPhoneBurnerLoginPage} />
                        <Route exact path="/defaultslacknotification" component={DefaultSlackNotificationPage} />
                        <Route exact path="/meetingslacknotification" component={MeetingSlackNotificationPage} />
                        <Route exact path="/custommeeting" component={CustomMeetingPage} />
                        <Route exact path="/addcustommeeting" component={AddCustomMeetingPage} />
                        <Route exact path="/editcustommeeting" component={EditCustomMeetingPage} />
                        <Route exact path="/editclient" component={EditClientPage} />
                        <Route exact path="/contacttag" component={ContactTagPage} />
                        <Route exact path="/addcontacttag" component={AddContactTagPage} />
                        <Route exact path="/editcontacttag" component={EditContactTagPage} />
                        <Route exact path="/corewebvitals" component={CoreWebVitalsPage} />
                        <Route exact path="/backlinks" component={BacklinksPage} />
                        <Route exact path="/keywords" component={KeywordsPage} />
                        <Route exact path="/backlinkspipeline" component={BacklinksPipelinePage} />
                        <Route exact path="/addbacklinkspipeline" component={AddBacklinksPipeline} />
                        <Route exact path="/editbacklinkspipeline" component={EditBacklinksPipeline} />

                        <Route exact path="/contentpipeline" component={ContentPipelinePage} />
                        <Route exact path="/addcontentpipeline" component={AddContentPipeline} />
                        <Route exact path="/editcontentpipeline" component={EditContentPipeline} />

                        <Route exact path="/seooverview" component={SeoOverviewPage} />
                        
                        {/* Meeting source route */}
                        <Route exact path="/meetingsource" component={MeetingSourcesPage} />
                        <Route exact path="/addmeetingsource" component={AddMeetingSourcesPage} />
                        <Route exact path="/editmeetingsource" component={EditMeetingSourcesPage} />
                        <Route exact path="/meetingreminder" component={MeetingReminderPage} />
                        <Route exact path="/potentialmeetingstatus" component={PotentialMeetingStatusPage} />
                        <Route exact path="/addpotentialmeetingstatus" component={AddPotentialMeetingStatusPage} />
                        <Route exact path="/editpotentialmeetingstatus" component={EditPotentialMeetingStatusPage} />
                        <Route exact path="/setorder" component={PotentialMeetingStatusOrderPage} />
                        {/* Account Reason Unqualified Page */}
                        <Route exact path="/accountreasonunqualified" component={AccountReasonUnqualifiedPage} />
                        <Route exact path="/addaccountreasonunqualified" component={AddAccountReasonUnqualified} />
                        <Route exact path="/setorderaccount" component={AccountReasonSetOrderPage} />
                        <Route exact path="/editaccountresonunqalified" component={EditAccountReasonUnqualified} />
                        <Route exact path="/HiveAi" component={HiveAiPage} />
                        <Route exact path="/emodprofile" component={EmodProfilePage} />
                        <Route exact path="/addemodprofile" component={AddEmodProfilePage} />
                        <Route exact path="/editemodprofile" component={EditEmodProfilePage} />


                        {/* Cliens page start */}
                        <Route exact path="/ckpis" component={CkpisPage} />
                        <Route exact path="/caccounts" component={CAccountsPage} />
                        <Route exact path="/caddaccounts" component={CAddAccountPage} />
                        <Route exact path="/ceditaccounts" component={CEditAccountsPage} />
                        <Route exact path="/cviewaccounts" component={CViewAccountPage} />
                        <Route exact path="/ccontacts" component={CcontactsPage} />
                        <Route exact path="/caddcontacts" component={CAddContactPage} />
                        <Route exact path="/ceditcontacts" component={CEditContactPage} />
                        <Route exact path="/cviewcontacts" component={CViewContactPage} />
                        <Route exact path="/cpotentialmeetings" component={CPotentialMeetingsPage} />
                        <Route exact path="/caddpotentialmeetings" component={CAddPotentialMeetingsPage} />
                        <Route exact path="/ceditpotentialmeetings" component={CEditPotentialMeetingsPage} />
                        <Route exact path="/cviewpotentialmeetings" component={CViewPotentialMeetingsPage} />
                        <Route exact path="/cbookedmeetings" component={CBookedMeetingsPage} />
                        <Route exact path="/caddbookedmeetings" component={CAddBookedMeetingPage} />
                        <Route exact path="/ceditbookedmeetings" component={CEditBookedMeetingPage} />
                        <Route exact path="/cviewbookedmeetings" component={CViewBookedMeetingPage} />
                        <Route exact path="/copportunities" component={COpportunitiesPage} />
                        <Route exact path="/caddopportunities" component={CAddOpportunitiesPage} />
                        <Route exact path="/ceditopportunities" component={CEditOpportunitiesPage} />
                        <Route exact path="/cviewopportunities" component={CViewOpportunitiesPage} />
                        <Route exact path="/clinkedinfollowup" component={CLinkedInFollowUpPage} />
                        <Route exact path="/crescheduling" component={CReschedulingPage} />
                        <Route exact path="/ccampaigns" component={CCampaignsPage} />
                        <Route exact path="/caddcampaigns" component={CAddCampaignsPage} />
                        <Route exact path="/ceditcampaigns" component={CEditCampaignsPage} />
                        <Route exact path="/ccoldcallingContact" component={CColdCallingContactPage} />
                        <Route exact path="/ccoldcallingReporting" component={CColdCallingReportingPage} />
                        <Route exact path="/ccallhistory" component={CCallHistoryPage} />
                        <Route exact path="/ccallrecordings" component={CCallRecordingsPage} />
                        <Route exact path="/clists" component={CListsPage} />
                        <Route exact path="/caddlists" component={CAddListspage} />
                        <Route exact path="/ceditlists" component={CEditListspage} />
                        <Route exact path="/cvariable" component={CVariablePage} />
                        <Route exact path="/ccustomVariable" component={CCustomVariablePage} />
                        <Route exact path="/caddcustomvariable" component={CAddCustomVariablePage} />
                        <Route exact path="/ceditcustomvariable" component={CEditCustomVariablePage} />
                        <Route exact path="/ccustomvariableSet" component={CCustomVariableSetPage} />
                        <Route exact path="/ccalltoaction" component={CCallToActionPage} />
                        <Route exact path="/cEmailHistory" component={CEmailHistoryPage} />
                        <Route exact path="/CEmailResponces" component={CEmailResponcesPage} />
                        <Route exact path="/cdocuments" component={CDocumentsPage} />
                        <Route exact path="/cadddocuments" component={CAddDocumentsPage} />
                        <Route exact path="/ceditdocuments" component={CEditDocumentsPage} />
                        <Route exact path="/cusersperclient" component={CUsersPerClientPage} />
                        <Route exact path="/ceditusersperClient" component={CEditUsersPerClientPage} />
                        <Route exact path="/caddUsersPerClient" component={CAddUsersPerClientPage} />
                        <Route exact path="/cupdateusersperclient" component={CUpdateUsersPerClientPage} />
                        <Route exact path="/caccountcategory" component={CAccountCategoryPage} />
                        <Route exact path="/caddaccountcategory" component={CAddAccountCategoryPage} />
                        <Route exact path="/caddaccountsincategory" component={CAddAccountsPage} />
                        <Route exact path="/ceditaccountcategory" component={CEditAccountCategoryPage} />
                        <Route exact path="/caccountstatus" component={CAccountStatusPage} />
                        <Route exact path="/caddaccountStatus" component={CAddAccountStatusPage} />
                        <Route exact path="/ceditaccountStatus" component={CEditAccountStatusPage} />
                        <Route exact path="/caccountstatusOrder" component={CAccountStatusOrderPage} />
                        <Route exact path="/caccountreasonUnqualified" component={CReasonUnqualifiedPage} />
                        <Route exact path="/caddaccountreasonUnqualified" component={CAddReasonUnqualifiedPage} />
                        <Route exact path="/ceditaccountreasonUnqualified" component={CEditReasonUnqualifiedPage} />
                        <Route exact path="/caccountreasonUnqualifiedOrder" component={CReasonUnqualifiedOrderPage} />
                        <Route exact path="/ccustomaccountsfield" component={CCustomAccountsFieldPage} />
                        <Route exact path="/caddcustomaccountsfield" component={CAddCustomAccountsFieldPage} />
                        <Route exact path="/ceditcustomaccountsField" component={CEditCustomAccountsFieldPage} />
                        <Route exact path="/ccontactreasonUnqualified" component={CContactReasonUnqualifiedPage} />
                        <Route exact path="/caddcontactreasonUnqualified" component={CAddContactReasonUnqualifiedPage} />
                        <Route exact path="/ceditcontactreasonUnqualified" component={CEditContactReasonUnqualifiedPage} />
                        <Route exact path="/ccontactreasonunqualifiedsetorder" component={CContactReasonUnqualifiedSetOrderPage} />
                        <Route exact path="/cclientpoc" component={CClientPOCPage} />
                        <Route exact path="/caddclientpoc" component={CAddClientPOCPage} />
                        <Route exact path="/ceditclientpoc" component={CEditClientPOCPage} />
                        <Route exact path="/cclientpocsetorder" component={CClientPOCSetOrderPage} />
                        <Route exact path="/ccontactsource" component={CContactSourcepage} />
                        <Route exact path="/caddcontactsource" component={CAddContactSourcepage} />
                        <Route exact path="/ceditcontactsource" component={CEditContactSourcepage} />
                        <Route exact path="/ccontactsourcesetorder" component={CContactSourceSetOrderPage} />
                        <Route exact path="/ccontactscustomField" component={CContactsCustomFieldpage} />
                        <Route exact path="/caddcontactscustomfield" component={CAddContactsCustomFieldpage} />
                        <Route exact path="/ceditcontactscustomfield" component={CEditContactsCustomFieldpage} />
                        <Route exact path="/ccontacttag" component={CContactTagpage} />
                        <Route exact path="/caddcontacttag" component={CAddContactTagpage} />
                        <Route exact path="/ceditcontacttag" component={CEditContactTagpage} />
                        <Route exact path="/cblacklists" component={CBlacklistsPage} />
                        <Route exact path="/cemailaccounts" component={CEmailAccountsPage} />
                        <Route exact path="/ceditemailaccounts" component={EditCEmailAccountsPage} />
                        <Route exact path="/caddemailaccounts" component={AddCEmailAccountsPage} />
                        <Route exact path="/ccampaigncategories" component={CCampaignCategoriesPage} />
                        <Route exact path="/caddcampaigncategories" component={CAddCampaignCategoriesPage} />
                        <Route exact path="/caddcampaignincategories" component={CAddCampaignInCategoriesPage} />
                        <Route exact path="/ceditcampaigncategories" component={CEditCampaignCategoriesPage} />
                        <Route exact path="/csendingschedular" component={CSendingSchedularPage} />
                        <Route exact path="/caddsendingschedular" component={CAddSendingSchedularPage} />
                        <Route exact path="/ceditsendingschedular" component={CEditSendingSchedularPage} />
                        <Route exact path="/cemailsignature" component={CEmailSignaturePage} />
                        <Route exact path="/cdefaultcountryblacklist" component={CDefaultCountryBlackListPage} />
                        <Route exact path="/cadddefaultcountryblacklist" component={CAddDefaultCountryBlacklistPage} />
                        <Route exact path="/ceditdefaultcountryblacklist" component={CEditDefaultCountryBlacklistPage} />
                        <Route exact path="/cmeetingowner" component={CMeetingOwnerPage} />
                        <Route exact path="/caddmeetingowner" component={CAddMeetingOwnerPage} />
                        <Route exact path="/ceditmeetingowner" component={CEditMeetingOwnerPage} />
                        <Route exact path="/cmeetinggoal" component={CMeetingGoalPage} />
                        <Route exact path="/caddmeetinggoal" component={CAddMeetingGoalPage} />
                        <Route exact path="/ceditmeetinggoal" component={CEditMeetingGoalPage} />
                        <Route exact path="/cmeetingstatus" component={CMeetingStatusPage} />
                        <Route exact path="/caddmeetingstatus" component={CAddMeetingStatusPage} />
                        <Route exact path="/ceditmeetingstatus" component={CEditMeetingStatusPage} />
                        <Route exact path="/cmeetingstatusesorder" component={CMeetingStatusesOrderPage} />
                        <Route exact path="/cmeetingsource" component={CMeetingSourcePage} />
                        <Route exact path="/caddmeetingsource" component={CAddMeetingSourcePage} />
                        <Route exact path="/ceditmeetingsource" component={CEditMeetingSourcePage} />
                        <Route exact path="/cmeetingreminder" component={CMeetingReminderPage} />
                        <Route exact path="/cslacknotifications" component={CSlackNotificationsPage} />
                        <Route exact path="/ccustommeeting" component={CCustomMeetingPage} />
                        <Route exact path="/caddcustommeeting" component={CAddCustomMeetingPage} />
                        <Route exact path="/ceditcustommeeting" component={CEditCustomMeetingPage} />
                        <Route exact path="/cpotentialmeetingStatus" component={CPotentialMeetingStatusPage} />
                        <Route exact path="/caddpotentialmeetingstatus" component={CAddPotentialMeetingStatusPage} />
                        <Route exact path="/ceditpotentialmeetingstatus" component={CEditPotentialMeetingStatusPage} />
                        <Route exact path="/cpotentialmeetingstatusorder" component={CPotentialMeetingStatusOrderPage} />
                        <Route exact path="/capiKey" component={CApiKeyPage} />
                        <Route exact path="/ccampaignsummary" component={CCampaignSummaryPage} />
                        <Route exact path="/cdespammer" component={CDespammerPage} />
                        <Route exact path="/cemailvalidation" component={CEmailValidationPage} />
                        <Route exact path="/ceditclient" component={CEditClientPage} />
                        <Route exact path="/contactreasonunqualified" component={CoReasonUnqualifiedPage} />
                        <Route exact path="/addcontactreasonunqualified" component={AddContactReasonUnqualifiedPage} />
                        <Route exact path="/editcontactreasonunqualified" component={EditContactReasonUnqualifiedPage} />
                        <Route exact path="/setcontactreasonunqualified" component={ContactReasonSetOrderPage} />

                        <Route exact path="/customaccountfield" component={Accountcustomfield} />
                        <Route exact path="/addcustoaccount" component={AddAccountcustomfield} />
                        <Route exact path="/editcustomaccount" component={EditAccountCustomfield} />

                        <Route exact path="/contactcustomfield" component={ContactCustomfieldPage} />
                        <Route exact path="/addcontactcustomfield" component={AddContactCustomPage} />
                        <Route exact path="/editcontactcustomfield" component={EditContactCustomPage} />

                        <Route exact path="/contactcustomcolumn" component={ContactCustomColumnPage} />
                        <Route exact path="/ccontactcustomcolumn" component={CContactCustomColumnPage} />
                        <Route exact path="/bookedmeetingcustomcolumn" component={BookedMeetingCustomColumnPage} />
                        <Route exact path="/caccountcustomcolumn" component={CAccountCustomColumnPage} />
                        <Route exact path="/ceditmyprofile" component={CEditMyProfilePage} />

                        <Redirect from="*" to="/" />
                    </Switch>
                    </ScrollToTop>
                </Router>
            </div>
        );
    }
}

/* mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };*/

export default App;