import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import ColdcallersTable from "./ColdcallersTable";
import InvitesTable from "./InvitesTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs({listupdate}) {
  const classes = useStyles();
  const [value, SetValue] = React.useState(0);

//handle new value
  const HandleChange = (event, newValue) => {
    SetValue(newValue);
  };

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  return (
    <div className="tabdes">
      <div>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={HandleChange} variant="scrollable"
            aria-label="simple tabs example"
          >
            <Tab label="Sales Development Reps" {...a11yProps(0)}>
              Overviewasdasd
            </Tab>
            <Tab label="Invites" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone whitebg" index={0}>
          <div className="tabmainerinner px-4 pt-0">
            <div className="row borderbtnm">
              <div className="col padd-0">
                <h4 className="headertitlebd pt-1">Sales Development Reps</h4>
              </div>
              <div className="col padright"></div>
            </div>

            <div className="row">
              <div className="col px-0">
                <ColdcallersTable />
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} className="whitebg" index={1}>
          <div className="tabmainerinner px-4 pt-0">
            <div className="row borderbtnm">
              <div className="col padd-0">
                <h4 className="headertitlebd pt-1">Invites</h4>
              </div>
              <div className="col padright"></div>
            </div>

            <div className="row">
              <div className="col px-0">
                <InvitesTable listupdate={listupdate} />
              </div>
            </div>
          </div>
        </TabPanel>
      </div> 
    </div>
  );
}
