import React, { useEffect } from "react";
import Swal from "sweetalert2";
import Axios from "axios";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { makeStyles } from "@material-ui/core/styles";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function MeetingStatusTable(props) {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("OrderBy");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Meetingstatus, SetMeetingStatus] = React.useState([]);
  const [CUserID,SetCUserID] = React.useState(0)
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [sortedColumn, setSortedColumn] = React.useState("OrderBy");

  useEffect(() => {
    document.title="Meeting Statuses | SalesHive";
    let Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

    MeetingStatusListGet(Details.ClientID, Details.ParentUserID, Details.Role);
  }, [Search, Page, RowsPerPage]);

  const imgstyle = {
    height: "40px",
    width: "40px",
    borderradius: "50%",
  };

  // meeting status list
  const MeetingStatusListGet = (CID, UID, URole) => {
    SetIsLoading(true)
    let MeetingStatusGet = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const ApiResponse = Axios({
      url: CommonConstants.MOL_APIURL + "/meetingstatus/MeetingStatusGet",
      method: "POST",
      data: MeetingStatusGet,
    });
    ApiResponse.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetMeetingStatus(result.data.MeetingStatus);
      SetIsLoading(false)
    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      let SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
       
      }
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
     
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  
  };

  // edit buttton
  const EditBtn = (id) => {
    history.push({ pathname: "/editmeetingstatuses", state: { data: id } });
  };

  //get sort field data
  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
  
    SetSortField(Field);
    SetSflag(SearchedVal !== "");
    let InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Search: SearchedVal,
      Sort: true,
      Field: Field,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
    };
    const ApiResponse = Axios({
      url: CommonConstants.MOL_APIURL + "/meetingstatus/MeetingStatusGet",
      method: "POST",
      data: InputParameter,
    });
    ApiResponse.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetPage(1)
      SetIsLoading(false)
    });


    // let SearchedVal = document.getElementById("search").value;
    // SetIsLoading(true)
    // // SetPage(1);
    // // SetRowsPerPage(10);
    // let SerchBox;
    // if (SearchedVal == "") {
    //   SerchBox = false;
    //   SetSflag(false);
    //   let SortField = Field;
    //   let SortBy;
    //   if (SortedBy == 1) {
    //     SortBy = -1;
    //     SetSortedBy(-1);
    //   } else {
    //     SortBy = 1;
    //     SetSortedBy(1);
    //   }
    //   SetSortField(Field);
    //   let InputParameter = {
    //     Page: Page,
    //     RowsPerPage: RowsPerPage,
    //     SerchBox: false,
    //     Archive: false,
    //     Sort: true,
    //     Field: SortField,
    //     SortBy: SortBy,
    //     Type: "User",
    //     ClientID: ClientID,
    //     UserID: UserID,
    //     Role: Role,
    //   };
    //   const ApiResponse = Axios({
    //     url: CommonConstants.MOL_APIURL + "/meetingstatus/MeetingStatusGet",
    //     method: "POST",
    //     data: InputParameter,
    //   });
    //   ApiResponse.then((Result) => {
        
    //     SetRows(Result.data.PageData);
    //     SetRlen(Result.data.TotalCount);
    //     SetFlen(Result.data.TotalCount);
    //     SetCountPage(Result.data.PageCount);
    //     SetIsLoading(false)
    //   });
    // } else {
    //   SerchBox = true;
    //   SetSflag(true);
    //   let SortField = Field;
    //   let SortBy;
    //   if (SortedBy == 1) {
    //     SortBy = -1;
    //     SetSortedBy(-1);
    //   } else {
    //     SortBy = 1;
    //     SetSortedBy(1);
    //   }
    //   SetSortField(Field);
    //   let InputParameter = {
    //     Page: Page,
    //     RowsPerPage: RowsPerPage,
    //     search: SearchedVal,
    //     SerchBox: SerchBox,
    //     Archive: false,
    //     Sort: true,
    //     Field: SortField,
    //     SortBy: SortBy,
    //     Type: "User",
    //     ClientID: ClientID,
    //     UserID: UserID,
    //     Role: Role,
    //   };
    //   const ApiResponse = Axios({
    //     url: CommonConstants.MOL_APIURL + "/meetingstatus/MeetingStatusGet",
    //     method: "POST",
    //     data: InputParameter,
    //   });
    //   ApiResponse.then((Result) => {
    //     SetRows(Result.data.PageData);
    //     SetRlen(Result.data.TotalCount);
    //     SetFlen(Result.data.TotalCount);
    //     SetCountPage(Result.data.PageCount);
    //     SetIsLoading(false)
    //   });
    // }
  };


    // delete meeting status
    const DeleteBtn =async (id,Name) => {
      let InputParameters = {
        ID: id,
        Type: "User",
        ClientID: ClientID,
        Field: SortField,
        SortBy: SortedBy,
      };
      const MeetingStatus = await Axios({
        url: CommonConstants.MOL_APIURL + "/meetingstatus/MeetingStatusDeleteDropDown",
        method: "POST",
        data: InputParameters,
      });
      
      let Datas = MeetingStatus.data.Data.filter(obj => obj._id !== id);
      let MeetingStatusDropDown = [];
      Datas?.map((val) => {
        MeetingStatusDropDown[val._id] = `${val.Status}`;
      });
     
      Swal.fire({
        title: "Are you sure?",
        text: "you want to delete a Meeting statuses.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) { 
          Swal.fire({
            title: "Replace Status",
            text: "Which value replace with '" + Name + "' meeting statuses?",
            confirmButtonColor: "#360947",
            cancelButtonColor: "#34bfa3",
            confirmButtonText: "Save",
            cancelButtonText: "Cancel",
            position: 'top',
            reverseButtons: true,
            input: "select",
            inputOptions: MeetingStatusDropDown,
            inputPlaceholder: MeetingStatusDropDown[0],
            showCancelButton: true,
            showCloseButton: true,
            customClass: {
              title: 'titelleft',
              popup: 'container-500',
            },
          }).then((Success) => {
          
            if (Success.isConfirmed) {
              
              let UpdateId = Success.value;
              let UpdatingParameters = {
                _id: id,
                NewMeetingStatusID: UpdateId,
                Status:MeetingStatusDropDown[Success.value],
                LastUpdatedBy: CUserID,
                LastUpdatedDt: new Date(),
                Role:Role,
                ClientID:ClientID
              };
             
              Axios({
                url:
                  CommonConstants.MOL_APIURL + "/meetingstatus/MeetingStatusDelete",
                method: "POST",
                data: UpdatingParameters,
              }).then(async (res) => {
                if (res) {
                  if ((res.statusText = "Ok")) {
                    Swal.fire(
                      "Deleted!",
                      "Meeting Status deleted successfully.",
                      "success"
                    );
                   
                    MeetingStatusListGet(ClientID, UserID, Role);
                    history.push("/meetingstatuses")
                    SetPage(1)
                    SetSearch("")
                    document.getElementById("search").value = ""
                  } else {
                  }
                }
              });
            } else if (Success.dismiss === Swal.DismissReason.cancel) {
              Swal.fire("Cancelled", "Your data is safe :)", "error");
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    };

  return (
    <div>
    {
      IsLoading ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : <></>
    }
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
              <TableCell className="w-250" onClick={() => { SortData("Status"); setSortedColumn("Status"); }}>
              Status
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Status" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Status" ? "active" : null} />
                </span>
              </TableCell>

              <TableCell className="w-250" onClick={() => { SortData("StatusType"); setSortedColumn("StatusType"); }}>
              Status Type
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "StatusType" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "StatusType" ? "active" : null} />
                </span>
              </TableCell>

              <TableCell className="w-250" onClick={() => { SortData("SimpleStatus"); setSortedColumn("SimpleStatus"); }}>
              Simple Meeting Status
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "SimpleStatus" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "SimpleStatus" ? "active" : null} />
                </span>
              </TableCell>

              
            <TableCell className="w-250" onClick={() => { SortData("Probability"); setSortedColumn("Probability"); }}>
            Probability
                <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Probability" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Probability" ? "active" : null} />
                </span>
              </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>{row?.Status}</TableCell>

                    <TableCell>
                      {row?.StatusType != undefined ? row.StatusType : ""}
                    </TableCell>
                    <TableCell>
                      {row?.SimpleStatus != undefined ? row.SimpleStatus : ""}
                    </TableCell>

                    <TableCell>{row.Probability}</TableCell>
                    <TableCell>
                      <a
                        onClick={() => {
                          EditBtn(row._id);
                        }}
                        className="btn-eyesicon" title="Edit"
                      >
                        <i className="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      {
                        row.Status.toLowerCase() == 'set' || row.Status.toLowerCase() == 'rescheduling' || row.Status.toLowerCase() == 'rescheduling - 1st follow up' || row.Status.toLowerCase() == 'rescheduling - 2nd follow up' || row.Status.toLowerCase() == 'rescheduling - 3rd follow up' || row.Status.toLowerCase() == 'rescheduling - 4th follow up' ? null :  <a
                        onClick={() => {
                          DeleteBtn(row._id,row?.Status);
                        }}
                        className="btn-eyesicon" title="Delete"
                      >
                        <i className="la flaticon-delete-1 delete-icon"></i>
                      </a>
                      }
                     
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
     
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
    </div>
  );
}
