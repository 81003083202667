import { userConstants } from "../_constants";
import { userService } from "../_services";
import { alertActions } from "./";
import { history } from "../_helpers";
import Axios from "axios";
import { CommonConstants } from "../_constants/common.constants";

export const userActions = {
  login,
  logout,
  getLoggerUserDetails,
};

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));

    userService.login(username, password).then(
      (user) => {
        dispatch(success(user));
        history.push("/");
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  history.push("/login");
  return { type: userConstants.LOGOUT };
}

function getLoggerUserDetails(CUID, Type) {
  return (dispatch) => {
    const InputParameters = {
      ChildUserID: CUID,
      AccountType: Type,
    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/login/GetLoginUserDetails",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      if (res.data.Data !== undefined) {
        dispatch(setLoggerUserDetails(res.data.Data));
      }
    });
  };

  function setLoggerUserDetails(data) {
    return { type: userConstants.SET_LOGGER_USER_DETAILS, data };
  }
}
