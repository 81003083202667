import React, { useEffect } from "react";
import Axios from "axios";
const moment = require('moment');
import { CSVDownloader } from "react-papaparse";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from "@material-ui/lab/Pagination";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export default function AccountAllTable({ ToDate, FromDate }) {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("MailSentDateByTimeZone");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [UserIDby, SetUserIDby] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [FromDates, SetFromDates] = React.useState("");
  const [ToDates, SetToDates] = React.useState("");
  const [Export, SetExport] = React.useState([]);
  const [ClientName, SetClientName] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false)
  const [open, setOpen] = React.useState(false);
  const [notes, setNotes] = React.useState([]);

  useEffect(() => {

    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetUserIDby(Details.ChildUserID);
      SetRole(Details.Role);
    }

    SetFromDates(FromDate);
    SetToDates(ToDate);
    NoteGet(Details.ParentUserID, Details.ClientID, Details.Role);

    HistoryGet(Details.ClientID, Details.ParentUserID, Details.Role, ToDate, FromDate);
    ExportHistory(Details.ClientID, Details.ParentUserID, Details.Role, FromDate, ToDate);
  }, [Search, Page, RowsPerPage, ToDate, FromDate]);



  // History list
  const HistoryGet = (CID, UID, URole, Tdate, FDate) => {
    SetIsLoading(true)
    var HistoryData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      ToDate: Tdate,
      FromDate: FDate
    };

    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/history/HistoryGet",
      method: "POST",
      data: HistoryData,
    });
    rows1.then((result) => {
      if (result.data.StatusMessage == "SUCCESS") {
        SetData(result.data.PageData);
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
        SetIsLoading(false)
      }
      else {
        SetIsLoading(false)
      }

    });
  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);

  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }

  };
  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true)

    var SearchedVal = document.getElementById("search").value;
    // SetPage(1);
    // SetRowsPerPage(10);
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        ToDate: ToDates,
        FromDate: FromDates
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/history/HistoryGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        if (Result.data.StatusMessage == "SUCCESS") {
          SetRows(Result.data.PageData);
          SetRlen(Result.data.TotalCount);
          SetFlen(Result.data.TotalCount);
          SetCountPage(Result.data.PageCount);
          SetIsLoading(false)
        }
        else {
          SetIsLoading(false)
        }

      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        ToDate: ToDates,
        FromDate: FromDates
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/history/HistoryGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false)

      });
    }
  };

  //GEt Notes detail

  const NoteGet = (UID, CID, ROLE) => {
    var HistoryData = {

      ClientID: CID,
      UserID: UID,
      Role: ROLE,
    };

    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/history/GetNotes",
      method: "POST",
      data: HistoryData,
    });
    rows1.then((result) => {
      console.log(result)
      setNotes(result.data.Data);
    });
  }

  //Export CSV
  const ExportCsv = () => { }

  //Export Data
  const ExportHistory = (CID, UID, Roles, FromDate, ToDate) => {
    var HistoryData = {
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: Roles,
      ToDate: ToDate,
      FromDate: FromDate,
      Search: Search,
    };

    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/history/ExportHistory",
      method: "POST",
      data: HistoryData,
    });
    rows1.then((result) => {
      SetExport(result.data.Data);
      SetClientName(result.data.ClientName.Name);

    });
  }
  return (
    <>
      {
        IsLoading ?
          <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div> : <></>
      }
      <div className="row">
        <div className="col">
          <div className="listing-li float-right padb-15">
            <ul>
              <li>

                <a onClick={ExportCsv} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                  <i class="la la-icon-font-size-13 la-download"></i>
                  <span>
                    <CSVDownloader className="px-0"
                      data={Export}
                      filename={`${ClientName}-Email-History`}
                      bom={true}
                    >Export</CSVDownloader></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell> <a
                  onClick={() => {
                    SortData("MailTo");
                  }}>Email</a></TableCell>
                <TableCell> <a
                  onClick={() => {
                    SortData("MailFrom");
                  }}>Sender</a></TableCell>
                <TableCell> <a
                  onClick={() => {
                    SortData("Name");
                  }}>Campaign Name</a></TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Status");
                    }}>Step</a></TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("MailSentDateByTimeZone");
                    }}>Send Date</a></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (Rows.map((row) => (
                <>
                  <TableRow key={row._id} row={row}>
                    <TableCell align="center">
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() =>

                          setOpen((prev) => ({
                            // ...prev,
                            [row._id]:
                              !prev[row._id],
                          }))
                        }
                      >
                        {open[row._id] ? (
                          <i
                            className="fa fa-minus-circle minusl"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-plus-circle plusbl"
                            aria-hidden="true"
                          ></i>
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell>{row.MailTo}</TableCell>
                    <TableCell>{row.MailFrom}</TableCell>
                    <TableCell>{row.Name}</TableCell>
                    <TableCell>{row?.Status + "-" + String.fromCharCode(64 + row?.CST?.OrderBy)}</TableCell>
                    <TableCell>{moment(new Date(row.MailSentDateByTimeZone).toDateString()).format("MM/DD/YYYY h:mm:ss A")}</TableCell>

                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        in={open[row._id]}
                        timeout="auto"
                        unmountOnExit
                      >
                        {open[row._id] && (
                          <Box margin={1} className="innertables">
                            <Table size="small" aria-label="purchases">
                              <TableHead></TableHead>

                              {notes.map((note) =>
                                note.CampaignStepHistoryID?.toString() === row._id?.toString() ? (
                                  <TableRow key={note._id}>
                                    <TableCell className="font-weight-bold">
                                      {String.fromCharCode(64 + row?.CST?.OrderBy)}
                                    </TableCell>
                                    <TableCell className="labeltds font-weight-bold">

                                    </TableCell>
                                    <TableCell scope="row">
                                      {console.log(note?.SalesReply_Payload[1])}
                                      <div className="clear-div" dangerouslySetInnerHTML={{ __html: note?.SalesReply_Payload[1]?.BodyDataPlain }} />
                                    </TableCell>
                                    <TableCell className="labeltds"></TableCell>
                                    <TableCell className="labeltds"></TableCell>
                                  </TableRow>
                                ) : null
                              )}

                              {!notes.some(
                                (note) =>
                                  note._id ===
                                  row._id
                              ) && (
                                  <TableRow>
                                    <TableCell colSpan={4}>
                                      No data available
                                    </TableCell>
                                  </TableRow>
                                )}
                            </Table>
                          </Box>
                        )}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              )))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
