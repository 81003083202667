import React from "react";
import Axios from "axios";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { CommonConstants } from "../../../_constants/common.constants";
import { arrayMoveImmutable } from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../../_helpers/Utility";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import loadingicon from "../../../images/loading.gif";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const SortableItem = SortableElement(({ value }) => (
  <li className="boxcorder">{value}</li>
));

const SortableList = SortableContainer(({ Items }) => {
  return (
    <ul>
      {Items.map((value, Index) => (
        <SortableItem
          id={Index}
          key={`item-${Index}`}
          index={Index}
          value={value.Status}
        />
      ))}
    </ul>
  );
});
class MeetingStatusesOrderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Items: [],
      ClientID: null,
      UserID: null,
      BtnDisabled: false,
      IsLoading:true
    };
    this.SaveBtn = this.SaveBtn.bind(this);
    this.BackBtn = this.BackBtn.bind(this);
  }

  componentDidMount() {
    document.title = "Meeting Status Order | SalesHive";
    let Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ChildUserID;
    }
    this.MetingStatusOrderGet();
  }

  // meeting status order list get
  MetingStatusOrderGet() {
    this.setState({IsLoading:true})
    let MeetingStatusData = {
      Page: 1,
      RowsPerPage: 100,
      Field: "OrderBy",
      SortBy: 1,
      Type: "User",
      ClientID: this.state.ClientID,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/meetingstatus/MeetingStatusGet",
      method: "POST",
      data: MeetingStatusData,
    });
    rows1.then((result) => {
      this.setState({ Items: result.data.PageData });
      this.setState({IsLoading:false})

    });
  }

  // sort on end
  OnSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      Items: arrayMoveImmutable(this.state.Items, oldIndex, newIndex),
    });
  };

  // save btm
  async SaveBtn() {
    this.setState({ BtnDisabled: true,IsLoading:true });

    document.getElementById("submit").disabled = true;

    let OrderBy = 0;
    for (let i = 0; i < this.state.Items.length; i++) {
      this.state.Items[i].OrderBy = OrderBy + 1;
      OrderBy++;
    }
    await this.SaveOrderData();
  }

  // Save to update order data
  async SaveOrderData() {
    let MeetingStatusOrderData = {
      MeetingStatusList: this.state.Items,
    };
    const result = await Axios({
      url: CommonConstants.MOL_APIURL + "/meetingstatus/MeetingStatusSetOrder",
      method: "POST",
      data: MeetingStatusOrderData,
    });
    if (result.data.StatusMessage == "SUCCESS") {
      toast.success(
        <div className="toastsize">
          success
          <br />
          Meeting statuses order updated successfully.
        </div>
      );
      history.push("/meetingstatuses");
    } else {
      toast.error(res.data.Message);
      this.setState({ BtnDisabled: false,IsLoading:false });
    }
    this.MetingStatusOrderGet();
    this.setState({ BtnDisabled: false,IsLoading:false });
  }

  // back button
  BackBtn() {
    history.push("/meetingstatuses");
  }

  render() {
    return (
      <>
      {
        this.state.IsLoading ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> :<></>
      }
        
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
           
            <div className="row">
              <div className="col">
                <h4 className="headertitlepad float-left">
                  Meeting Status Order
                </h4>
              </div>
            </div>

            <div className="whiterow px-3 pt-3">
              <div className="row">
                <div className="col-xl-12 offset-xl-12">
                  <span className="alertinfo">
                    &nbsp;<b>NOTE</b> : Please drag & drop item to change order
                    of meeting status and press save button.
                  </span>

                  <div className="spacupgroup">
                    <SortableList
                      Items={this.state.Items}
                      onSortEnd={this.OnSortEnd}
                    />
                  </div>
                </div>
              </div> 
            </div>

              <div className="row py-4 px-3">
                <div className="pull-left">
                  <button
                    id="submit"
                    disabled={this.state.BtnDisabled}
                    onClick={() => {
                      this.SaveBtn();
                    }}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save order
                  </button>
                  <a
                    id="backtolist"
                    onClick={() => {
                      this.BackBtn();
                    }}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedMeetingStatusesOrderPage = connect(
  mapState,
  actionCreators
)(MeetingStatusesOrderPage);
export { connectedMeetingStatusesOrderPage as MeetingStatusesOrderPage };
