import React from "react";
import Axios from "axios";

import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import "react-toastify/dist/ReactToastify.css";

import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

class CAddDefaultCountryBlacklistPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      CountryNameAvailable: false,
      ClientID: null,
      UserID: null,
      IsExist: false,
      Role:null,
      UserIDby:null
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserIDby = Details.ChildUserID;
      this.state.Role = Details.ChildUserID;
      this.state.UserID = Details.ParentUserID;
    }
  }

    // form validation
   async FromValidation() {
      let FormIsValid = true;
      let Errors = {};
     var Country = document.getElementById("Country").value.trim();
    var IsExists = await this.CoutryNameCheckExist(Country)
      if (Country == "") {
        FormIsValid = false;
        Errors["Country"] = "Please enter country";
      }
      if(IsExists == true){
        FormIsValid = false;
      }
      if (this.state.IsExist ) {
        FormIsValid = false;
      }
      this.setState({ Errors: Errors });
      return FormIsValid;
    }

    
    // handle change
    HandleChange(field, e) {
      let Fields = this.state.Fields;
      Fields[field] = e.target.value;
      this.setState({ Fields });
      if (Fields.Country != "") {
        this.state.Errors.Country = null;
        this.CoutryNameCheckExist(e.target.value);
      } else {
        this.state.Errors.Country = null;
      }
    }

  // check exit name
 async CoutryNameCheckExist(Country) {
    var CheckExitsData = {
      ClientID: this.state.ClientID,
      UserID: this.state.UserID,
      Country: Country,
      Role:this.state.Role
    };
   let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/defaultcountryblacklist/DefaultCountryBlacklistExists",
        method: "POST",
        data: CheckExitsData,
    })
      if (resdata.data.Data.length > 0) {
        this.setState({ CountryNameAvailable:resdata.data.Data});
        this.setState({ IsExist: true });
        return true;
      }else{
        this.setState({ IsExist: false });
        return false;
      }
  }

  // back button
  BackBtn() {
    history.push("/CDefaultCountryBlackList");
  }

  // defualt country black list add
 async SaveBtn(e) {
  document.getElementById("submit").disabled = true;

  this.state.Errors.Country = null;
    this.state.CountryNameAvailable = null;
    e.preventDefault();
    e.currentTarget.disabled = true;
    var Final_flag =  await this.FromValidation()
    if(Final_flag ==true){
     var Country = document.getElementById("Country").value;
     var Abbreviation = document.getElementById("Abbreviation").value;
      var DefualtCountryBlackListData = {
        UserID: this.state.UserID,
        Country: Country,
        Abbreviation: Abbreviation,
        CreatedDate: new Date(),
        CreatedBy: this.state.UserIDby,
        Role:this.state.Role,
        ClientID:this.state.ClientID
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/defaultcountryblacklist/DefaultCountryBlacklistAdd",
        method: "POST",
        data: DefualtCountryBlackListData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div>Country blacklist will be added soon.</div>);
          history.push("/CDefaultCountryBlackList");
        } else {
          toast.error(res.data.Message);
      document.getElementById("submit").disabled = false;

        }
      });
    
    }else{
      document.getElementById("submit").disabled = false;
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad pl-0 mb-0">
                    Add
                  </h4>
                </div>
              </div>

              <div class="paddcols">
                <div className="row">
                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols d-flex align-items-baseline">
                      <label class="col-lg-4 view-lable-meet">Country </label>
                      <div class="col-lg-8">
                        <input
                          class="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter category Country"
                          id="Country"
                          onBlur={this.HandleChange.bind(this, "Country")}
                          // onChange={(e)=>{this.CoutryNameCheckExist1(e.target.value)}}
                          name="Country"
                          placeholder="Enter country"
                          type="text"
                        />
                        <span style={{ color: "red" }}>
                          {this.state.Errors["Country"]}
                        </span>
                        {this.state.CountryNameAvailable && (
                          <span style={{ color: "red" }}>
                            Country name already exist.
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols d-flex align-items-baseline">
                      <label class="col-lg-4 view-lable-meet">
                        Abbreviation{" "}
                      </label>
                      <div class="col-lg-8">
                        <input
                          class="form-control m-input"
                          data-val="true"
                          data-val-required="Please enter abbreviation"
                          id="Abbreviation"
                          name="Abbreviation"
                          placeholder="Enter abbreviation"
                          type="text"
                        />
                        <span style={{ color: "red" }}>
                          {this.state.Errors["Abbreviation"]}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row my-4">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCAddDefaultCountryBlacklistPage = connect(
  mapState,
  actionCreators
)(CAddDefaultCountryBlacklistPage);
export { connectedCAddDefaultCountryBlacklistPage as CAddDefaultCountryBlacklistPage };
