import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LastdaysTabing from '../Kpis/LastdaysTabing';

import ClientsTable from './ClientsTable';
import DomainsTable from './DomainsTable';
import EmailAccountsTable from './EmailAccountsTable'; 

import ImageUploading from 'react-images-uploading';
import Popup from "reactjs-popup";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [popupval,setpopup] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="tabdes"> 
      <div> 
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} variant="scrollable" aria-label="simple tabs example" className='px-1'> 
            <Tab label="Clients" {...a11yProps(0)}>Overviewasdasd</Tab>
            <Tab label="Domains" {...a11yProps(1)} /> 
            <Tab label="Email Accounts" {...a11yProps(1)} /> 
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone inpt-0 bg-white" index={0}>
          <Box>
     
            <div className="row px-2">
              <div className="col">
                <div className="">
                  <ClientsTable />
                </div>
              </div>
            </div>
            </Box>
        </TabPanel>
        <TabPanel value={value} className="tagpaddnone inpt-0 bg-white" index={1}> 
            <div className="row px-2">
            <div className="col">
              <div className="">
                <DomainsTable />
              </div>
            </div>
          </div>
        </TabPanel> 
        <TabPanel value={value} className="tagpaddnone inpt-0 bg-white" index={2}> 
            <div className="row px-2">
            <div className="col">
              <div className="">
                <EmailAccountsTable />
              </div>
            </div>
          </div>
        </TabPanel> 

      </div>
  
    </div>
  );
}