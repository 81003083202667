import React from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";

toast.configure();

class AddClientPocPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      TitleNotNull: false,
      IsExist: false,
      StatusAvailable: null,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      Role: Details.Role,
      BtnDisabled:false
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = `Add | SalesHive`;

  }

   // form validation
   async FromValidation() {
    let FormIsValid = true;
    let Errors = {};
    var Name = document.getElementById("Name").value.trim();
    var IsExists = await this.ClientPocNameCheckExist(Name); // Change 'Country' to 'Name'
    
    if (IsExists == "BlankData" && Name === "") { // Check for an empty string or a space in the name
      FormIsValid = false;
      Errors["Name"] = "Please enter name "; // Modify the error message
    }
  
    if (IsExists === "ExistCountry") {
      FormIsValid = false;
      Errors["Name"] = "Name already exists."; // Change 'Country' to 'Name'
    }
  
    this.setState({ Errors: Errors });
    return FormIsValid;
  }
  

    // handle change
    async HandleChange(field, e) {
      let Errors = { ...this.state.Errors };
      const value = e.target.value.trim();
      let IsExists;

    
      if (field === "Name") { // Change "Country" to "Name"
        IsExists = await this.ClientPocNameCheckExist(value); // Change "CoutryNameCheckExist" to "ClientPocNameCheckExist"
    
        if (IsExists === "BlankData" && value === "") {
          Errors.Name = "Please enter name"; // Change "Country" to "Name"
        } else if (IsExists === "ExistCountry") {
          Errors.Name = "Name already exists."; // Change "Country" to "Name"
        } else if (IsExists === "AllDone") {
          Errors.Name = null; // Change "Country" to "Name"
        }
      }
    
      this.setState({ Errors });
    }
    

  // Clientpoc check exits
  async  ClientPocNameCheckExist(Name) {
    if(Name !== ""){
      var str_in = {
        ClientID: this.state.ClientID,
        Name: Name,
        Role:this.state.Role
      };
      let resdata = await  Axios({
        url: CommonConstants.MOL_APIURL + "/clientpoc/ClientPocExists",
        method: "POST",
        data: str_in,
      }) 
      // if (resdata.data.StatusMessage == "SUCCESS") {
          if (resdata.data.Data.length > 0) {
            this.setState({ StatusAvailable: resdata.data.Data[0]?.Name });
            return "ExistCountry";
      
          } else {
            return "AllDone";
          }
        // }
    }else{
      return "BlankData";
    }
  }

  // back button
  BackBtn() {
    history.push("/clientpoc");
  }

  // save button
  async SaveBtn() {

    this.setState({BtnDisabled:true})
    var Final_flag = await this.FromValidation();
    if (Final_flag == true) {
      var Name = document.getElementById("Name").value.trim();
      var ClientPocData = {
        Name: Name,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
        CreatedBy: this.state.CUserID,
        CreatedDt: new Date(),
        type: "User",
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/clientpoc/ClientPocAdd",
        method: "POST",
        data: ClientPocData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
             Client poc
              <br />
              Client poc added successfully.
            </div>
          );
          history.push("/clientpoc");
        } else {
          toast.error(res.data.Message);
          this.setState({BtnDisabled:false})
        }
      });
    }else {

      this.setState({BtnDisabled:false})
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            
            <div className="row pt-4 pb-3">
              <div className="col px-3">
                <h4 className="headertitlenop float-left px-1">
                  Add 
                </h4>
              </div>
            </div>

            <div class="whiterow">
              <div className="row py-3 mb-4 px-4">
                <div class="col-lg-6 boxsinput_group px-3">
                  <div class="row max-cols px-1">
                    <label class="col-lg-3 view-lable-meet">Name</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter field name"
                        id="Name"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        name="Name"
                        placeholder="Enter name"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                  
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-3 pb-4">
              <div class="col-lg-12 pull-left mx-1 pb-2">
                <button
                  id="submit"
                  disabled={this.state.BtnDisabled}
                  onClick={this.SaveBtn}
                  class="btn btn-primary btn-lightgreen mr-1"
                >
                  <i class="la la-save"></i> Save
                </button>
                <button
                  id="backtolist"
                  onClick={this.BackBtn}
                  class="btn btn-secondary"
                >
                  <i class="la la-arrow-circle-left"></i> Back
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer /> 
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddClientPocPage = connect(
  mapState,
  actionCreators
)(AddClientPocPage);
export { connectedAddClientPocPage as AddClientPocPage };
