import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { GetUserDetails } from "../../../_helpers/Utility";
import Axios from "axios";
import { CommonConstants } from "../../../_constants/common.constants";
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";

import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import Userimg from "../../../images/avatar/1.jpg";

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import loadingicon from "../../../images/loading.gif";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});



function Row(props) {
  const { row, SetSearch1, SetPage, PhoneburnerLoginListGet } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const ReAuthenticatebtn = (row) => {
    
    var Details = GetUserDetails();
    var InputParameter = {
      UserID: Details.ParentUserID,
      ClientID: row.ClientID,
      ColdCallerID: row.ColdCallerID,
      IsAuthFromOldPlatform:row.IsAuthFromOldPlatform,
      IsAuthenticated:row.IsAuthenticated,
      redirectPage: "coldcallerphoneburnerlogin",
    };
    
    Axios({
      url: CommonConstants.MOL_APIURL + "/Utility/GetPhoneBurnerAuthCode",
      method: "POST",
      data: InputParameter,
      withCredentials: true,
    }).then((res) => {
      if (res.data.StatusCode == "Valid") {
        window.location.href = res.data.Url;
      }
    });
  };
  //Delete record of phone burner
  //delete action item
  const DeleteAuths = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a cold caller phoneburner login.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then((Result) => {
      if (Result.isConfirmed) {
        var Details = GetUserDetails();
        var InputData = {
          ID: ID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: Details.ChildUserID,
          Role: Details.Role,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/login/DeletePhoneBurner",
          method: "POST",
          data: InputData,
        }).then((Res) => {
          if (Res) {
            if ((Res.data.StatusMessage = "SUCCESS")) {
              Swal.fire(
                "Deleted!",
                "Cold caller phoneburner login deleted successfully.",
                "success"
              );
              // document.getElementById("hideloding").style.display = "block";
              SetSearch1("");
              SetPage(1);
              var Details = GetUserDetails();
              var InputData = {
                ID: ID,
                LastUpdatedDate: new Date(),
                LastUpdatedBy: Details.ChildUserID,
                Role: Details.Role,
              };
              PhoneburnerLoginListGet();
              // document.getElementById("search").value = ""
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        {!row.IsAuthenticated ? (
          <TableCell>
            <a
              className="btn btn-sm btn-primary btnColor  m-btn"
              onClick={() => ReAuthenticatebtn(row)}
              title="Authenticate"
            >
              Authenticate
            </a>
          </TableCell>
        ) : (
          <TableCell>
            <button
              style={{
                backgroundColor: "#3DAF8F",
                color: "white",
                fontSize: "0.9rem",
                border: "1px solid transparent",
                textDecoration: "none",
                padding: "0.25rem 0.5rem",
                lineHeight: "1.5",
                borderRadius: "0.2rem",
                fontWeight: "300",
                WebkitTextFillColor: "white",
              }}
              title="Working"
            >
              Working
            </button>
          </TableCell>
        )}
        <TableCell>{row.ColdCallersName}</TableCell>
        <TableCell>{row.ClientName}</TableCell>
        <TableCell> {row.Username} </TableCell>
        <TableCell>
          <a
            onClick={() => {
              DeleteAuths(row._id);
            }}
            className="btn-eyesicon"
          >
            <i class="la flaticon-delete-1 delete-icon"></i>
          </a>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    Userimg: PropTypes.number.isRequired,
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default function AccountAllTable() {

  const [page, setPage] = React.useState(1);
  const [loading, SetIsLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);
  const [UserEmail, SetUserEmail] = React.useState("");

  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("ColdCallersName");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [isLoadingPB, setisLoadingPB] = React.useState(false);
  const [SortedColumn, setSortedColumn] = React.useState(null);
  const [UserID, SetUserID] = React.useState(null)



  useEffect(() => {
    document.title = "Sales Development Reps Phone Burner Login | SalesHive";

    SetIsLoading(true);
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
      SetUserEmail(Details.Username);
      PhoneburnerLoginListGet(Details.ChildUserID,Details.ParentUserID,Details.Role,Details.ClientID)
    }
  }, [RowsPerPage,Search,Page]);


  const PhoneburnerLoginListGet = (CUID,UID,Role,CID) => {

    SetIsLoading(true);

    var str_in = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      ChildUserID: CUID,
      ClientID: CID,
      UserID: UID,
      Role: Role,
    };

    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/login/GetPhoneBurnerlist",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      SetData(result.data?.PageData);
      SetRows(result.data?.PageData);
      SetRlen(result.data?.TotalCount);
      SetFlen(result.data?.TotalCount);
      SetCountPage(result.data?.PageCount);
      SetIsLoading(false);
    });
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    console.log(NewPage)
    if (NewPage == page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

    // search for record
    const RequestSearch = (Event) => {
      if (Event.key === "Enter") {
        var SearchedVal = document.getElementById("Search").value;
        SetSearch(SearchedVal?.trim());
        SetPage(1);
        setisLoadingPB(true)
      }
    };
  
    // change display rows
    const ChangeRowSelected = (Event) => {
      SetRowsPerPage(Number(Event.target.value));
      SetPage(1);
      setisLoadingPB(true)
    };

    //get sort field data
    const SortData = (Field) => {
      SetIsLoading(true)
      var SearchedVal = document.getElementById("Search").value;
      var SerchBox;
      if (SearchedVal == "") {
        SerchBox = false;
        var SortField = Field;
        var SortBy;
        if (SortedBy == 1) {
          SortBy = -1;
          SetSortedBy(-1);
        } else {
          SortBy = 1;
          SetSortedBy(1);
        }
        SetSortField(Field);
        var str_in = {
          Page: Page,
          RowsPerPage: RowsPerPage,
          Sort: true,
          Field: SortField,
          SortBy: SortedBy,
          Search: Search,
          ChildUserID: CUserID,
          ClientID: ClientID,
          UserID: UserID,
          Role: Role,
        };
    
        const rows1 = Axios({
          url: CommonConstants.MOL_APIURL + "/login/GetPhoneBurnerlist",
          method: "POST",
          data: str_in,
        });
        rows1.then((result) => {
          SetData(result.data?.PageData);
          SetRows(result.data?.PageData);
          SetRlen(result.data?.TotalCount);
          SetFlen(result.data?.TotalCount);
          SetCountPage(result.data?.PageCount);
          SetIsLoading(false);
        });
      } else {
        SerchBox = true;
        var SortField = Field;
        var SortBy;
        if (SortedBy == 1) {
          SortBy = -1;
          SetSortedBy(-1);
        } else {
          SortBy = 1;
          SetSortedBy(1);
        }
        SetSortField(Field);
        var str_in = {
          Page: Page,
          RowsPerPage: RowsPerPage,
          Sort: true,
          Field: SortField,
          SortBy: SortedBy,
          Search: Search,
          ChildUserID: CUserID,
          ClientID: ClientID,
          UserID: UserID,
          Role: Role,
        };
    
        const rows1 = Axios({
          url: CommonConstants.MOL_APIURL + "/login/GetPhoneBurnerlist",
          method: "POST",
          data: str_in,
        });
        rows1.then((result) => {
          SetData(result.data?.PageData);
          SetRows(result.data?.PageData);
          SetRlen(result.data?.TotalCount);
          SetFlen(result.data?.TotalCount);
          SetCountPage(result.data?.PageCount);
          SetIsLoading(false);
        });
      }
    };


    const ReAuthenticatebtn = (row) => {
      var Details = GetUserDetails();
      var InputParameter = {
        UserID: Details.ParentUserID,
        ClientID: row.C?._id,
        ColdCallerID: row.CCC?.ColdCallersID,
        IsAuthFromOldPlatform:row.IsAuthFromOldPlatform,
        IsAuthenticated:row.IsAuthenticated,
        redirectPage: "coldcallerphoneburnerlogin",
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/Utility/GetPhoneBurnerAuthCode",
        method: "POST",
        data: InputParameter,
        withCredentials: true,
      }).then((res) => {
        if (res.data.StatusCode == "Valid") {
          window.location.href = res.data.Url;
        }
      });
    };
    //Delete record of phone burner
    //delete action item
    const DeleteAuths = (ID) => {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to delete a cold caller phoneburner login.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
      }).then((Result) => {
        if (Result.isConfirmed) {
          var Details = GetUserDetails();
          var InputData = {
            ID: ID,
            LastUpdatedDate: new Date(),
            LastUpdatedBy: Details.ChildUserID,
            Role: Details.Role,
          };
          Axios({
            url: CommonConstants.MOL_APIURL + "/login/DeletePhoneBurner",
            method: "POST",
            data: InputData,
          }).then((Res) => {
            if (Res) {
              if ((Res.data.StatusMessage = "SUCCESS")) {
                Swal.fire(
                  "Deleted!",
                  "Cold caller phoneburner login deleted successfully.",
                  "success"
                );
                // document.getElementById("hideloding").style.display = "block";
                SetSearch("");
                SetPage(1);
                var Details = GetUserDetails();
                var InputData = {
                  ID: ID,
                  LastUpdatedDate: new Date(),
                  LastUpdatedBy: Details.ChildUserID,
                  Role: Details.Role,
                };
                PhoneburnerLoginListGet(
                  UserID,
                  CUserID,
                  Role,
                  ClientID);
                // document.getElementById("search").value = ""
              } else {
              }
            }
          });
        } else if (Result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    };


  return (
    <>
      {loading ? <div id="hideloding" className="loding-display"> <img src={loadingicon} /></div>:  <></> }

        <div className="row padt-25">
            <div className="col-sm-12 col-md-6">
              <label className="textlabelte">
                Show
                <select
                  name="tbl_meeting_length"
                  onChange={ChangeRowSelected}
                  aria-controls="tbl_meeting"
                  className="form-control form-control-sm"
                  value={RowsPerPage}
                >
                  {CommonConstants.show_rows.map((value) => (
                    <option key={value} value={value}>{value}</option>
                  ))}
                </select>
                entries
              </label>
            </div>
            <div className="col-sm-12 col-md-6 full-right">
              <label className="textlabelte">
                Search:{" "}
                <input
                  type="Search"
                  id="Search"
                  onKeyPress={RequestSearch}
                  className="form-control form-control-sm ml-2"
                  placeholder=""
                  aria-controls="tbl_meeting"
                />
              </label>
            </div>
          </div>
        <div className="table-bordered">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell onClick={()=>{SortData("ColdCallersName"); setSortedColumn("ColdCallersName");}}>Sales Development Reps <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortedColumn === "ColdCallersName" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortedColumn === "ColdCallersName" ? "active" : null} />
                    </span></TableCell>
                  <TableCell onClick={()=>{SortData("ClientName"); setSortedColumn("ClientName");}}>Client <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortedColumn === "ClientName" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortedColumn === "ClientName" ? "active" : null} />
                    </span></TableCell>
                  <TableCell onClick={()=>{SortData("Username"); setSortedColumn("Username");}}>User Email <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortedColumn === "Username" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortedColumn === "Username" ? "active" : null} />
                    </span></TableCell>
                  <TableCell> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Rows.length === 0 ? <p>No data available in table</p> : (
                  Rows.map((row) => (
                    <TableRow>
        {!row.IsAuthenticated ? (
          <TableCell>
            <a
              className="btn btn-sm btn-primary btnColor  m-btn"
              onClick={() => ReAuthenticatebtn(row)}
              title="Authenticate"
            >
              Authenticate
            </a>
          </TableCell>
        ) : (
          <TableCell>
            <button
              style={{
                backgroundColor: "#3DAF8F",
                color: "white",
                fontSize: "0.9rem",
                border: "1px solid transparent",
                textDecoration: "none",
                padding: "0.25rem 0.5rem",
                lineHeight: "1.5",
                borderRadius: "0.2rem",
                fontWeight: "300",
                WebkitTextFillColor: "white",
              }}
              title="Working"
            >
              Working
            </button>
          </TableCell>
        )}
        <TableCell>{row.ColdCallersName}</TableCell>
        <TableCell>{row.ClientName}</TableCell>
        <TableCell> {row.Username} </TableCell>
        <TableCell>
          <a
            onClick={() => {
              DeleteAuths(row._id);
            }}
            className="btn-eyesicon"
          >
            <i class="la flaticon-delete-1 delete-icon"></i>
          </a>
        </TableCell>
                     </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
          <Pagination
                  component="div"
                  count={CountPage}
                  onChange={HandleChangePage}
                  page={Page}
                  showFirstButton
                  showLastButton
                />
          </div>
        </div>
    </>
  );
}
