import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import MeetingsSeniority from '../_components/MeetingsSeniority';
import MeetingsMonth from '../_components/MeetingsMonth';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="tabdes">
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} variant="scrollable" aria-label="simple tabs example">
            <Tab label="Meetings by Seniority" {...a11yProps(0)} />
            <Tab label="Meetings by Month" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone" index={0}>

          <div class="row">
            <div class="col border-bottom">
              <h4 class="headertitle float-left">Meetings by Seniority</h4>
            </div>
          </div>

          <div className="row tab-contenttop">
          <div className="col-sm-12 col-md-6">
              <label className="textlabelte">Show 
                <select name="tbl_meeting_length" aria-controls="tbl_meeting" class="form-control form-control-sm">
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                </select> 
                entries</label>
          </div>
          <div className="col-sm-12 col-md-6 full-right">
            <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
          </div>
        </div>

        <div class="row">
          <div className="col table-bordered padd-0">
            <MeetingsSeniority />
          </div>
        </div>

        <div className="row">
          <div className="col dataTables_info">
            <p>Showing 1 to 71 of 71 entries</p>
          </div>
          <div className="col dataTables_pagination">
          </div>
        </div>


        </TabPanel>
        <TabPanel value={value} index={1}>
          

        <div class="row">
          <div class="col border-bottom">
            <h4 class="headertitle float-left">Meetings by Month</h4>
          </div>
        </div>

        <div className="row tab-contenttop">
        <div className="col-sm-12 col-md-6">
            <label className="textlabelte">Show 
              <select name="tbl_meeting_length" aria-controls="tbl_meeting" class="form-control form-control-sm">
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              </select> 
              entries</label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
           <label className="textlabelte">Search: <input type="search" class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
        </div>
      </div>

      <div class="row">
        <div className="col table-bordered padd-0">
          <MeetingsMonth />
        </div>
      </div>

      <div className="row">
        <div className="col dataTables_info">
          <p>Showing 1 to 71 of 71 entries</p>
        </div>
        <div className="col dataTables_pagination">
        </div>
      </div>
          

        </TabPanel>
      </div>
    </div>
  );
}