import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";
import loadingicon from "../../../images/loading.gif";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { Avatar } from "@material-ui/core";
import { toast } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import Userimg from "../../../images/avatar/1.jpg";
import Userionimg from "../../../images/default.png";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable({
  ToDatess,
  FromDatess,
  IsOldClientss,
}) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("ColdCallersName");
  const [sortedColumn, setSortedColumn] = React.useState("ColdCallersName");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID, SetCUserID] = React.useState(0);
  const [SalesDevelopmentRepsCount, SetSalesDevelopmentRepsCount] =
    React.useState([]);
  const [ExportColdCallers, SetExportColdCallers] = React.useState([]);

  const classes = useRowStyles();
  const imgstyle = {
    height: "40px",
    width: "40px",
    borderradius: "50%",
  };

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

    ClientReschedulingGet(
      Details.ClientID,
      Details.ParentUserID,
      Details.Role,
      ToDatess,
      FromDatess,
      IsOldClientss
    );
    SalesDevelopmentResCountMethod(Details.ParentUserID, FromDatess, ToDatess);
  }, [Search, Page, RowsPerPage, ToDatess, FromDatess, IsOldClientss]);

  const Exportdetails = async(UID, FromDate, ToDate,IsOldClientss) => {
    const InputParameters = {
      UserID: UID,
      ToDate: ToDate,
      FromDate: FromDate,
      IncludeOldClient: IsOldClientss,
    };
    const rows1 = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/leader_board/SalesDevelopmentRepsExports",
      method: "POST",
      data: InputParameters,
    })
    if (rows1.data.StatusMessage === "SUCCESS") {
      return rows1.data;
    }
  };


  const exportcsv = async () => {
    try {
      document.getElementById("hideloding").style.display = "block";
      const result = await Exportdetails(UserID, FromDatess, ToDatess,IsOldClientss);
      if (result.StatusMessage === "SUCCESS") {
        const data = result.Data;
        if (data.length > 0) {
          //setexportdta(data);
          const downloader = document.createElement("a");
          const csvContent =
            "data:text/csv;charset=utf-8," +
            encodeURIComponent(convertToCSV(data));
          downloader.setAttribute("href", csvContent);
          downloader.setAttribute(
            "download",
            "LeaderBoard-ColCallersMeeting.csv"
          );
          downloader.click();
          toast.success(<div>Data exported successfully.</div>);
          document.getElementById("hideloding").style.display = "none";
        } else {
          toast.error("No data available for export.");
          document.getElementById("hideloding").style.display = "none";
        }
      } else {
        toast.error("Failed to export data. Please try again.");
        document.getElementById("hideloding").style.display = "none";
      }
    } catch (error) {
      console.error("ExportCsv error:", error);
      document.getElementById("hideloding").style.display = "none";
    }
  };

  function convertToCSV(data) {
    const headers = Object.keys(data[0]);

    const csvRows = [];
    csvRows.push(headers.join(','));

    data.forEach(item => {
        const values = headers.map(header => {
            // Check if the value is a string before attempting to replace characters
            const value = typeof item[header] === 'string' ? item[header] : String(item[header]);
            const escapedValue = value.replace(/"/g, '""'); // Escape double quotes
            return `"${escapedValue}"`; // Enclose value in double quotes
        });
        csvRows.push(values.join(','));
    });

    return csvRows.join('\n');
}

  const ColdCallersCsv = () => {};

  const SalesDevelopmentResCountMethod = (UID, FromDate, ToDate) => {
    const InputParameters = {
      UserID: UID,
      ToDate: ToDate,
      FromDate: FromDate,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/leader_board/SalesDevelopmentRepsCount",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      SetSalesDevelopmentRepsCount(Res.data.Data);
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
     // SetIsLoading(false);
    })
  };

  // Client rescheduling get list
  const ClientReschedulingGet = (
    CID,
    UID,
    URole,
    ToDate,
    FromDate,
    IsOldClientss
  ) => {
    document.getElementById("hideloding").style.display = "block";

    var InputParameters = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      ToDate: ToDate,
      FromDate: FromDate,
      IncludeOldClient: IsOldClientss,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/leader_board/SalesDevelopmentRepsGet",
      method: "POST",
      data: InputParameters,
    });
    rows1.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      document.getElementById("hideloding").style.display = "none";
    }).catch((error) => {
      document.getElementById("hideloding").style.display = "none";
     // SetIsLoading(false);
    })
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };
  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        ToDate: ToDatess,
        FromDate: FromDatess,
        IncludeOldClient: IsOldClientss,
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL + "/leader_board/SalesDevelopmentRepsGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        ToDate: ToDatess,
        FromDate: FromDatess,
        IncludeOldClient: IsOldClientss,
      };
      const AccountCategoriesList = Axios({
        url:
          CommonConstants.MOL_APIURL + "/leader_board/SalesDevelopmentRepsGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
      });
    }
  };
  return (
    <>
      <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
      <div className="row">
        <div className="w-100 whiterow py-5 px-3">
          <div className="row">
            <div className="col">
              <div className="bg-light-sky boxcardcounter align-center">
                <div class="line-title">
                  <span class="clr-sky box-main-title">
                    {SalesDevelopmentRepsCount.MeetingCount
                      ? SalesDevelopmentRepsCount.MeetingCount
                      : 0}
                  </span>
                </div>
                <p class="clr-sky">Total Meetings</p>
              </div>
            </div>
            <div className="col">
              <div className="bg-light-sky boxcardcounter align-center">
                <div class="line-title">
                  <span class="clr-sky box-main-title">
                    {SalesDevelopmentRepsCount.LWMeetingCount
                      ? SalesDevelopmentRepsCount.LWMeetingCount
                      : 0}
                  </span>
                </div>
                <p class="clr-sky">Meeting Set LW</p>
              </div>
            </div>
            <div className="col">
              <div className="bg-light-sky boxcardcounter align-center">
                <div class="line-title">
                  <span class="clr-sky box-main-title">
                    {SalesDevelopmentRepsCount.TWMeetingCount
                      ? SalesDevelopmentRepsCount.TWMeetingCount
                      : 0}
                  </span>
                </div>
                <p class="clr-sky">Meeting Set TW</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col border-bottom">
          <h4 className="headertitle float-left pl-0 mb-0">
            Sales Development Reps
          </h4>
          <div className="listing-li float-right padb-15">
            <ul>
              <li>
                <a
                  onClick={exportcsv}
                  className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                >
                  <i class="la la-icon-font-size-13 la-download"></i>
                  <span> Export</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col padd-0"></div>
          </div>
          <div className="row padt-25">
            <div className="col-sm-12 col-md-6">
              <label className="textlabelte">
                Show
                <select
                  name="tbl_meeting_length"
                  onChange={ChangeRowSelected}
                  aria-controls="tbl_meeting"
                  class="form-control form-control-sm"
                  value={RowsPerPage}
                >
                  {CommonConstants.show_rows.map((value) => (
                    <option value={value}>{value}</option>
                  ))}
                </select>
                entries
              </label>
            </div>
            <div className="col-sm-12 col-md-6 full-right">
              <label class="textlabelte">
                Search:
                <input
                  type="Search"
                  id="search"
                  onKeyPress={RequestSearch}
                  class="form-control form-control-sm ml-2"
                  placeholder=""
                  aria-controls="tbl_meeting"
                />
              </label>
            </div>
          </div>

          <div className="table-bordered">
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell  onClick={() => {SortData("ColdCallersName"); setSortedColumn("ColdCallersName");}}>
                      Sales Development Reps
                        <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "ColdCallersName" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "ColdCallersName" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell  onClick={() => {SortData("Meetingcount"); setSortedColumn("Meetingcount");}}>
                        Meetings
                        <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "Meetingcount" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "Meetingcount" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell  onClick={() => {SortData("AllMeetingCount"); setSortedColumn("AllMeetingCount");}}>
                  Meetings All-Time
                        <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "AllMeetingCount" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "AllMeetingCount" ? "active" : null} />
                    </span>
                  </TableCell>
                  <TableCell  onClick={() => {SortData("ClientCount"); setSortedColumn("ClientCount");}}>
                  Total Clients
                        <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "ClientCount" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "ClientCount" ? "active" : null} />
                    </span>
                  </TableCell>

                  <TableCell  onClick={() => {SortData("LWMeetingCount"); setSortedColumn("LWMeetingCount");}}>
                  Meetings LW
                        <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "LWMeetingCount" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "LWMeetingCount" ? "active" : null} />
                    </span>
                  </TableCell>

                  <TableCell  onClick={() => {SortData("TWMeetingCount"); setSortedColumn("TWMeetingCount");}}>
                  Meetings TW
                        <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && sortedColumn === "TWMeetingCount" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && sortedColumn === "TWMeetingCount" ? "active" : null} />
                    </span>
                  </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {Rows.map((row) => (
                    <TableRow key={row.name} row={row}>
                      <TableCell>
                        <img
                          style={imgstyle}
                          src={
                            row.ProfileImage == ""
                              ? CommonConstants.Image_url + "default.png"
                              : CommonConstants.Image_url + row.ProfileImage
                          }
                        ></img>
                        {" " + row.ColdCallersName}
                      </TableCell>
                      <TableCell>
                        {row.Meetingcount ? row.Meetingcount : 0}
                      </TableCell>
                      <TableCell>{row.AllMeetingCount}</TableCell>
                      <TableCell>{row.ClientCount}</TableCell>
                      <TableCell>
                        {row.LWMeetingCount ? row.LWMeetingCount : 0}
                      </TableCell>
                      <TableCell>
                        {row.TWMeetingCount ? row.TWMeetingCount : 0}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {Sflag ? (
            <div class="row">
              <Pagination
                component="div"
                count={CountPage}
                onChange={HandleChangePage}
                showFirstButton
                showLastButton
              />
              <div class="col dataTables_info">
                <p>
                  Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                  {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                  {Rlen} entries (filtered from {Flen} total entries)
                </p>
              </div>
            </div>
          ) : (
            <div class="row">
              <div class="col dataTables_info">
                <p>
                  Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                  {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                  {Rlen} entries
                </p>
              </div>
              <div class="col pageright">
                <Pagination
                  component="div"
                  count={CountPage}
                  onChange={HandleChangePage}
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
