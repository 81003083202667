import React from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { history } from "../../../_helpers";
import { userActions } from "../../../_actions";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

toast.configure();

class AddListspage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);
    this.state = {
      Fields: {},
      Errors: {},
      StatusAvailable: null,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      Role: Details.Role,
      IsExist: false,
    };
    
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    this.state.StatusAvailable;
  }

  //form validation
  async FromValidation() {
    let formIsValid = true;
    var Name = document.getElementById("Name").value.trim();
    var IsExist = await this.CheckExistListName(Name);
    let Errors = {};

      if (Name == "" && Name.indexOf(" ") != "") {
        formIsValid = false;
        Errors["Name"] = "Please enter Name";
      }
      if (IsExist == true) {
        formIsValid = false;
      }
      this.setState({ Errors: Errors });

    return formIsValid;
  }

  //change to check error
  HandleChange() {
    var Name = document.getElementById("Name").value.trim();
    // let Fields = this.state.Fields;
    // Fields[Field] = e.target.value;
    // this.setState({ Fields });
    if (Name != "" && Name.indexOf(" ") != "") {
      this.state.Errors.Name = null;
      this.state.StatusAvailable = null;
      this.CheckExistListName(Name);
    } else {
      this.state.StatusAvailable = null;
      this.state.Errors.Name = null;
    }
  }

  //check exist Status
  async CheckExistListName(Name) {
    var str_in = {
      ClientID: this.state.ClientID,
      Name: Name,
      UserID: this.state.UserID,
      Role:this.state.Role

    };
    var ResListData = await  Axios({
      url: CommonConstants.MOL_APIURL + "/list/CheckExistList",
      method: "POST",
      data: str_in,
    })
      if (ResListData.data.StatusMessage == "SUCCESS") {
        if (ResListData.data.Data.length > 0) {
          this.setState({ StatusAvailable: ResListData.data.Data[0].Name });
          this.setState({ IsExist: true });
          return true;
        }else {
          this.setState({ IsExist: false });
          return false;
        }
      }

  }

  //Add List Name
  async SaveBtn(e) { 
    this.state.Errors.Name = null;
    this.state.StatusAvailable = null;
    e.preventDefault();
    e.currentTarget.disabled = true;
    var Final_flag = await this.FromValidation();
    if (Final_flag == true) {
      var Name = document.getElementById("Name").value.trim();
      let ProspectID=this.props.location.state["data"]
      var AddField = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
        Name: Name,
        CreatedDate: new Date(),
        CreatedBy: this.state.CUserID,
        ProspectID:ProspectID
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/list/ListAdd",
        method: "POST",
        data: AddField,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div>
              List
              <br />
              List added successfully.
            </div>
          );
          if (this.props.location.state["Pagename"] == "AccountContact") {
            history.push({
              pathname: "/viewaccount",
              state: { data: this.props.location.state?.data},
            });
          }else if(this.props.location.state["Pagename"] == "ViewContact"){
            history.push({
              pathname: "/viewcontacts",
              state: { data: this.props.location.state?.pid,EmailData:this.props.location.state.EmailData},
            });
          }
          else if(this.props.location.state["Pagename"] == "EditContact"){
            history.push({
              pathname: "/editcontacts",
              state: { data: this.props.location.state?.data},
            });
          }
          else if(this.props.location.state["Pagename"] == "editcontacts"){
            history.push({
              pathname: "/editcontacts",
              state: { data: this.props.location.state?.data},
            });
          }
          else{
            history.push("/list");
          }
        } else {
          toast.error(res.data.Message);
        }
      });
    } else {
      document.getElementById("submit").disabled = false;
    }
  }

  BackBtn() {
    if (this.props.location.state["Pagename"] == "AccountContact") {
      history.push({
        pathname: "/viewaccount",
        state: { data: this.props.location.state?.data},
      });
    }
    else if(this.props.location.state["Pagename"] == "editcontacts"){
      history.push({
        pathname: "/editcontacts",
        state: { data: this.props.location.state?.data},
      });
    } else if(this.props.location.state["Pagename"] == "ViewContact"){
      history.push({
        pathname: "/viewcontacts",
        state: { data: this.props.location.state?.pid,EmailData:this.props.location.state.EmailData},
      });
    }
    else if(this.props.location.state["Pagename"] == "AddList"){
      history.push("/list");
    }else{
      history.push("/list");
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome px-3"> 
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left mb-0 py-4">Add List</h4>
                </div>
              </div>
              <div className="bg-white px-4">
              <div className="row pt-4 pb-4">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols px-2">
                    <label class="col-lg-3 view-lable-meet">List Name</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        id="Name"
                        name="Name"
                        placeholder=""
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                      {this.state.StatusAvailable && (
                        <span style={{ color: "red" }}>
                          List name already exist.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              </div>

              <div class="row py-4">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <button
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </button>
                </div>
              </div>
 
          </div>
        </div>
        <Footer/>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedPotentialMeetingStatusPage = connect(
  mapState,
  actionCreators
)(AddListspage);
export { connectedPotentialMeetingStatusPage as AddListspage };
