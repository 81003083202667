import React from "react";
import { connect } from "react-redux";
import Axios from "axios";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../../_helpers/Utility";
import { userActions } from "../../../_actions";
import loadingicon from "../../../images/loading.gif";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class EditMeetingSourcesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      StatusAvailable: null,
      ClientID: null,
      UserID: null,
      CUserID: null,
      Role: null,
      VariableType: [],
      VariableTypeID: null,
      CustomVariable: {},
      IsExist: false,
      Checked: true,
      VariableTypeName: "",
      DefaultSelectChannelType: "",
      DefaultSelectEng: null,
      OldName: null,
      IsButtonDisabled: false,
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
  }
  componentDidMount() {
    document.title = `Edit Meeting Source | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      this.setState({ ClientID: Details.ClientID });
      this.setState({ UserID: Details.ParentUserID });
      this.setState({ CUserID: Details.ChildUserID });
      this.setState({ Role: Details.Role });
    }
    this.Editmeetingsource();
  }

  // form validation
  async FromValidations() {
    let FormIsValid = true;
    let Fields = this.state.Fields;
    let Errors = {};
    var Name = document.getElementById("Name").value.trim();
    var VariableType = document.getElementById("VariableType").value;
    var IsExist = await this.MeetingSourceCheckExist(Name);

    if (VariableType == "") {
      FormIsValid = false;
      Errors["VariableType"] = "Please select meeting source type";
    }
    if (IsExist == "ExistStatus") {
      FormIsValid = false;
      Errors["Name"] = "Name already exist.";
    }

    if (IsExist == "BlankData" && Name == "") {
      FormIsValid = false;
      Errors["Name"] = "Please enter Name";
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

  // form validation
  async HandleChangeBlur() {
    let Errors = { ...this.state.Errors };
    var Name = document.getElementById("Name").value.trim();
    var IsExist = await this.MeetingSourceCheckExist(Name);
    if (IsExist == "ExistStatus") {
      Errors["Name"] = "Name already exist.";
    }

    if (IsExist == "BlankData" && Name == "") {
      Errors["Name"] = "Please enter Name";
    }

    if (IsExist == "AllDone") {
      Errors["Name"] = null;
    }
    this.setState({ Errors: Errors });
  }

  // handle change
  HandleChange(Field, E) {
    let Fields = this.state.Fields;
    Fields[Field] = E.target.value;
    this.setState({ Fields });
    if (Fields.Name != "") {
      this.state.Errors.Name = null;
      this.state.StatusAvailable = null;
      this.MeetingSourceCheckExist(E.target.value);
    } else {
      this.state.StatusAvailable = null;
      this.state.Errors.Name = null;
    }
  }

  // back buttton
  BackBtn() {
    history.push("/meetingsource");
  }

  // find check exits meeting source
  async MeetingSourceCheckExist(Name) {
    if (Name != "") {
      var MeetingSource = {
        ClientID: this.state.ClientID,
        Name: Name,
        Role: this.state.Role,
      };
      var resdata = await Axios({
        url: CommonConstants.MOL_APIURL + "/meetingsource/MeetingSourcesExists",
        method: "POST",
        data: MeetingSource,
      });

      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          if (this.state.OldName == Name) {
            // console.log(this.state.OldName,Name)
            // this.setState({ IsExist: false });
            return "AllDone";
          } else {
            this.setState({ StatusAvailable: resdata.data.Data[0].Name });
            return "ExistStatus";
          }
        } else {
          return "AllDone";
        }
      }
    } else {
      return "BlankData";
    }
  }

  // edit meeting source
  Editmeetingsource() {
    const id = this.props.location.state?.data;
    this.setState({ id: id });
    var MeetingIdData = {
      id: id,
      Role: this.state.Role,
    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/meetingsource/MeetingSourceFindByID",
      method: "POST",
      data: MeetingIdData,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById("Name").value = res.data.Data[0]?.Name;
        document.getElementById("include_source").checked =
          res.data.Data[0].IsSetByPipestry;
        this.setState({
          DefaultSelectEng: res.data.Data[0].MeetingSourceTypeID,
        });
        this.setState({
          DefaultSelectChannelType: res.data.Data[0].MeetingChannelType,
        });
        this.setState({ OldName: res.data.Data[0].Name });
        Axios({
          url: CommonConstants.MOL_APIURL + "/meetingsource/MeetingChannelGet",
          method: "POST",
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            this.setState({ VariableType: res.data.Data });
            document.getElementById("hideloding").style.display = "none";
          } else {
            toast.error(res.data.Message);
          }
        });
      } else {
        toast.error(res.data.Message);
      }
    });
  }

  // handle change list
  HandleChangeList = (e) => {
    this.setState({ DefaultSelectEng: e.target.DefaultSelectEng });
    var MeetingTypeName = {
      FieldID: e.target.value,
      Role: this.state.Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/meetingsource/MeetingTypenameGet",
      method: "POST",
      data: MeetingTypeName,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        this.setState({ VariableTypeName: res.data?.ChannelTypeData });
      } else {
        toast.error(res.data.Message);
      }
    });
  };

  // save edit meeting source
  async SaveBtn(e) {
    e.preventDefault();
    // this.state.Errors.Name = null;
    // this.state.StatusAvailable = null;
    this.setState({ IsButtonDisabled: true });
    // e.currentTarget.disabled = true;
    var Final_flag = await this.FromValidations();
    if (Final_flag == true) {
      var Name = document.getElementById("Name").value.trim();
      var VariableType = document.getElementById("VariableType").value;
      var MeetingEditSave = {
        _id: this.state.id,
        Name: Name,
        MeetingChannelType:
          this.state.VariableTypeName == ""
            ? this.state.DefaultSelectChannelType
            : this.state.VariableTypeName,
        MeetingSourceTypeID: VariableType,
        type: "User",
        IsSetByPipestry: this.state.Checked,
        Role: this.state.Role,
        ClientID:this.state.ClientID
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/meetingsource/MeetingSourceUpdate",
        method: "POST",
        data: MeetingEditSave,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Meeting Sources
              <br />
              Meeting Sources updated successfully.
            </div>
          );
          history.push("/meetingsource");
        } else {
          this.setState({ IsButtonDisabled: false });
          toast.error(res.data.Message);
        }
      });
    } else {
      this.setState({ IsButtonDisabled: false });
      // document.getElementById("submit").disabled = false;
    }
  }

  // handle chane check
  HandleChangeCheck = (e) => {
    // const { Checked } = e.target;
    // this.setState({
    //   Checked: Checked,
    // });
    if (document.getElementById("include_source").checked) {
      this.setState({
        Checked: true,
      });
    } else {
      this.setState({
        Checked: false,
      });
    }
  };

  render() {
    return (
      <>
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            
            <div className="row">
              <div className="col px-4">
                <h4 className="headertitlepad float-left">
                  Edit Meeting Sources
                </h4>
              </div>
            </div>

            <div class="paddcols">
              <div className="row py-4 px-3 mb-3">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Name</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter name"
                        id="Name"
                        onBlur={this.HandleChangeBlur}
                        name="Name"
                        placeholder="Name"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                      {/* {this.state.StatusAvailable && (
                        <span style={{ color: "red" }}>
                          Name already exist.
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Meeting Channel
                    </label>
                    <div class="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="VariableType"
                        name="VariableType"
                        autoComplete="off"
                        onChange={(e) => this.HandleChangeList(e)}
                        // onBlur={this.HandleChangeBlur}
                        value={this.state.DefaultSelectEng}
                      >
                        {/* {<option value="">--Select--</option>} */}
                        {this.state.VariableType.map((value) => (
                          <option value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.Errors["VariableType"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="d-flex align-items-start">
                    <label class="check_bx">
                      <input
                        id="include_source"
                        type="checkbox"
                        onChange={(e) => this.HandleChangeCheck(e)}
                        // defaultChecked={this.state.Checked}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label>Count In Reporting?</label>
                  </div>
                </div>
              </div>
            </div>

              <div class="row mb-4 pt-4">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.IsButtonDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <button
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </button>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditMeetingSourcesPage = connect(
  mapState,
  actionCreators
)(EditMeetingSourcesPage);
export { connectedEditMeetingSourcesPage as EditMeetingSourcesPage };
