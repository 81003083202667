import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import DayTable from '../CallToAction/DayTable'; 
import TimeTable from '../CallToAction/TimeTable'; 
import LengthTable from '../CallToAction/LengthTable'; 
import ActionTable from '../CallToAction/ActionTable'; 


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function SimpleTabs({daterange,updateFromChild}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  useEffect(()=>{
    document.title = "Call To Action | SalesHive";
  },[daterange]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  

  return (
    <div className="tabdes">   
      <div> 
        <AppBar className="col-tabs" position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Day" {...a11yProps(0)}>Overviewasdasd</Tab>
            <Tab label="Time" {...a11yProps(1)} /> 
            <Tab label="Length" {...a11yProps(2)} /> 
            <Tab label="Action" {...a11yProps(3)} /> 
          </Tabs>
        </AppBar>
        <TabPanel value={value} className="tagpaddnone bg-white" index={0}>
          <div className="tabmainerinner py-0 px-1"> 
            <div className="row">
              <div className="col">
                  <DayTable FeatureName={"Day"} FeatureFieldID={"1"} dater={daterange} updateData={updateFromChild}/> 
              </div>
            </div> 
          </div>

        </TabPanel>
        
        <TabPanel value={value} className="bg-white" index={1} > 
        <div className="tabmainerinner py-0 px-1"> 
            <div className="row">
              <div className="col">
                  <DayTable FeatureName={"Time"} FeatureFieldID={"2"} dater={daterange} updateData={updateFromChild}/>
                </div> 
            </div> 
          </div>
         
         
        </TabPanel>

        <TabPanel value={value} className="bg-white" index={2}>
        <div className="tabmainerinner py-0 px-1"> 
            <div className="row">
              <div className="col"> 
                  <DayTable FeatureName={"Length"} FeatureFieldID={"3"} dater={daterange} updateData={updateFromChild}/> 
              </div>
            </div> 
          </div>
         
        </TabPanel>

        <TabPanel value={value} className="bg-white" index={3}>
        <div className="tabmainerinner py-0 px-1"> 
              <div className="row">
                <div className="col"> 
                    <DayTable FeatureName={"Action"} FeatureFieldID={"4"} dater={daterange} updateData={updateFromChild}/> 
                </div>
              </div> 
            </div>
        </TabPanel>
       
      </div> 

    </div>
  );
}