import Pagination from "@material-ui/lab/Pagination";

const Pagenations = ({
    Sflag,
    CountPage,
    HandleChangePage,
    Rlen,
    Page,
    RowsPerPage,
    Flen
  }) => {
    return (
      <>
        {Sflag ? (
          <div className="row">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
            <div className="col dataTables_info">
              <p>
                Showing {Rlen === 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
                entries (filtered from {Flen} total entries)
              </p>
            </div>
          </div>
        ) : (
          <div className="row px-2">
            <div className="col dataTables_info">
              <p>
                Showing {Rlen === 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
                entries
              </p>
            </div>
            <div className="col pageright">
              <Pagination
                component="div"
                count={CountPage}
                onChange={HandleChangePage}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </>
    );
  };
  export default Pagenations;