import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Avatar } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import Userimg from "../images/avatar/1.jpg";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      { date: "2020-01-05", customerId: "11091700", amount: 3 },
      { date: "2020-01-02", customerId: "Anonymous", amount: 1 },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <i class="fa fa-minus-circle minusl" aria-hidden="true"></i>
            ) : (
              <i class="fa fa-plus-circle plusbl" aria-hidden="true"></i>
            )}
          </IconButton>
        </TableCell>
        <TableCell scope="row">
          <a href="#" className="btn-eyesicon">
            <i class="la flaticon-eye edit-icon"></i>
          </a>
        </TableCell>
        <TableCell className="labeltds" scope="row">
          jayeshrana
        </TableCell>
        <TableCell className="labeltds" scope="row">
          A_Company761
        </TableCell>
        <TableCell>8 June 2021</TableCell>
        <TableCell> test test GOOGLE GOOGLE</TableCell>
        <TableCell>test</TableCell>
        <TableCell>12/15/2020</TableCell>
        <TableCell>
          <select>
            <option>Set</option>
            <option>Set</option>
            <option>Set</option>
          </select>
        </TableCell>
        <TableCell>test</TableCell>
        <TableCell>testr@gmail.com</TableCell>
        <TableCell>
          <a href="#">gmail.com</a>
        </TableCell>
        <TableCell>Advertising - Facebook</TableCell>
        <TableCell>test owner</TableCell>
        <TableCell>12/16/2020 03:50 PM</TableCell>
        <TableCell>Yes</TableCell>
        <TableCell>
          <a href="">//in.linkedin.com/company/gvm-technologies</a>
        </TableCell>
        <TableCell>10</TableCell>
        <TableCell>real estate</TableCell>
        <TableCell>22</TableCell>
        <TableCell>123</TableCell>
        <TableCell>178</TableCell>
        <TableCell>12/15/2020</TableCell>
        <TableCell>12/15/2020</TableCell>
        <TableCell>Manager</TableCell>
        <TableCell>1</TableCell>
        <TableCell>2 </TableCell>
        <TableCell> 3</TableCell>
        <TableCell>4 </TableCell>
        <TableCell>
          <a href="#" className="btn-eyesicon">
            <i class="la flaticon-eye edit-icon"></i>
          </a>
          <a href="#" className="btn-eyesicon">
            <i class="la flaticon-delete-1 delete-icon"></i>
          </a>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} className="innertables">
              <Table size="small" aria-label="purchases">
                <TableHead></TableHead>
                <TableRow>
                  <TableCell align="center"></TableCell>
                  <TableCell scope="row"></TableCell>
                  <TableCell className="labeltds" scope="row"></TableCell>
                  <TableCell className="labeltds" scope="row"></TableCell>
                  <TableCell>8 June 2021</TableCell>
                  <TableCell> </TableCell>
                  <TableCell> </TableCell>
                  <TableCell>12/15/2020</TableCell>
                  <TableCell></TableCell>
                  <TableCell> </TableCell>
                  <TableCell> </TableCell>
                  <TableCell> </TableCell>
                  <TableCell> </TableCell>
                  <TableCell> </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    Userimg: PropTypes.number.isRequired,
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0, 3.99),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 4.99),
  createData("Eclair", 262, 16.0, 24, 6.0, 3.79),
  createData("Cupcake", 305, 3.7, 67, 4.3, 2.5),
  createData("Gingerbread", 356, 16.0, 49, 3.9, 1.5),
];

export default function CollapsibleTable() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Notes</TableCell>
            <TableCell>Action</TableCell>
            <TableCell>Meeting Set By</TableCell>
            <TableCell>Account Name</TableCell>
            <TableCell>Closed Won Date</TableCell>
            <TableCell>Contact Name</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Meeting Set Date</TableCell>
            <TableCell>Meeting Status</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Domain</TableCell>
            <TableCell>Meeting Source</TableCell>
            <TableCell>Owner</TableCell>
            <TableCell>Meeting Date</TableCell>
            <TableCell>Send Reminder</TableCell>
            <TableCell>LinkedIn Url</TableCell>
            <TableCell>Contract Value</TableCell>
            <TableCell>Industry</TableCell>
            <TableCell>Direct Phone</TableCell>
            <TableCell>Company Phone</TableCell>
            <TableCell>Days In Stage</TableCell>
            <TableCell>Last Status Change Date</TableCell>
            <TableCell>Created Date</TableCell>
            <TableCell>Seniority</TableCell>
            <TableCell>1</TableCell>
            <TableCell>Last Email Campaign</TableCell>
            <TableCell>Last Email Step</TableCell>
            <TableCell>Direct Phone Ext</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
