import React from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { userActions } from "../../../_actions";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";

toast.configure();

class CAddClientPOCPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      TitleNotNull: false,
      IsExist: false,
      StatusAvailable: null,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      UserIDby: Details.ChildUserID,
      Role: Details.Role,
      BtnDisabled:false
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title = `Add | SalesHive`;
  }

   // form validation
   async FromValidation() {
    let FormIsValid = true;
    var Name = document.getElementById("Name").value.trim();
    var IsExist = await this.ClientPocNameCheckExist(Name);
    let Errors = {};
    if (Name == "" && Name.indexOf(" ") != "") {
      FormIsValid = false;
      Errors["Name"] = "Please enter name";
    }

    if (IsExist == true) {
      FormIsValid = false;
    }
    this.setState({ Errors: Errors });

    return FormIsValid;
  }

    // handle change
    HandleChange() {
    var Name = document.getElementById("Name").value.trim();

      if (Name != "" && Name.indexOf(" ") != "") {
        this.state.Errors.Name = null;
        this.state.StatusAvailable = null;
        this.ClientPocNameCheckExist(Name);
      } else {
        this.state.StatusAvailable = null;
        this.state.Errors.Name = null;
      }
    }

  // Clientpoc check exits
  async  ClientPocNameCheckExist(Name) {
    var str_in = {
      ClientID: this.state.ClientID,
      Name: Name,
      Role: this.state.Role,
    };
    var resdata = await  Axios({
      url: CommonConstants.MOL_APIURL + "/clientpoc/ClientPocExists",
      method: "POST",
      data: str_in,
    }) 
    if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          this.setState({ StatusAvailable: resdata.data.Data[0]?.Name });
          this.setState({ IsExist: true });
          return true;
        } else {
          this.setState({ IsExist: false });
          return false;
        }
      }
  }

  // back button
  BackBtn() {
    history.push("/CClientPOC");
  }

  // save button
  async SaveBtn() {
    this.setState({BtnDisabled:true})

    var Final_flag = await this.FromValidation();
    if (Final_flag == true) {
      var Name = document.getElementById("Name").value.trim();
      var ClientPocData = {
        Name: Name,
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
        CreatedBy: this.state.UserIDby,
        CreatedDt: new Date(),
        type: "User",
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/clientpoc/ClientPocAdd",
        method: "POST",
        data: ClientPocData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
             Client poc
              <br />
              Client poc added successfully.
            </div>
          );
          history.push("/CClientPOC");
        } else {
          toast.error(res.data.Message);
          this.setState({BtnDisabled:false})
        }
      });
    }else {
      this.setState({BtnDisabled:false})
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
            <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad pl-0 mb-0">
                    Add 
                  </h4>
                </div>
              </div>

            <div className="paddcols">
              <div className="row">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-3 view-lable-meet">Name</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter field name"
                        id="Name"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        name="Name"
                        placeholder="Name"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                      {this.state.StatusAvailable && (
                        <span style={{ color: "red" }}>
                          Name already exist.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div className="row mb-3 mt-3">
                <div className="col-lg-12 pull-left">
                  <button
                    id="submit"
                    disabled={this.state.BtnDisabled}
                    onClick={this.SaveBtn}
                    className="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <button
                    id="backtolist"
                    onClick={this.BackBtn}
                    className="btn btn-secondary"
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </button>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCAddClientPOCPage = connect(
  mapState,
  actionCreators
)(CAddClientPOCPage);
export { connectedCAddClientPOCPage as CAddClientPOCPage };
