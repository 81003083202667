import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { alert } from './alert.reducer';
import { client } from './client.reducer';

const rootReducer = combineReducers({
    authentication,
    alert,
    client
});

export default rootReducer;