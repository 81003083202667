import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Pagination from "@material-ui/lab/Pagination";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

import { makeStyles } from "@material-ui/core/styles";
import Switch from "@mui/material/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

const label = { inputProps: { "aria-label": "Size switch demo" } };

import Pagenations from "../../Pagenations";


const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDt");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [PUserID, SetPUserID] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const imgstyle = {
    height: "40px",
    width: "40px",
    borderradius: "50%",
  };

  useEffect(() => {
    document.title = "Users Per Client | SalesHive";

    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ChildUserID);
      SetPUserID(Details.ParentUserID);
      SetRole(Details.Role);
    }
    UsersPerClient(Details.ClientID, Details.ParentUserID, Details.Role);
  }, [Search, Page, RowsPerPage]);

  // User per client Get list
  const UsersPerClient = (CID, UID, URole) => {
    SetIsLoading(true);
    var HardBounceData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/users_per_client/UsersPerClientGet",
      method: "POST",
      data: HardBounceData,
    });
    rows1.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetIsLoading(false);
    });
  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };
  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("search").value;
    SetIsLoading(true);

    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: PUserID,
        Role: Role,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/users_per_client/UsersPerClientGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: PUserID,
        Role: Role,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/users_per_client/UsersPerClientGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      });
    }
  };

  // Delete method
  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a client users.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No,cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          ID: ID,
          LastUpdatedBy: UserID,
          LastUpdatedDate: new Date(),
          Role: Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/users_per_client/UsersPerClientDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Client users deleted successfully.",
                "success"
              );
              UsersPerClient(ClientID, PUserID, Role);
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // Edit btn
  const EditBtn = (id) => {
    history.push("/editusersperclient", { id: id, page: "UsersPerClient" });
  };

  // two step verification update
  const ToggleBtnHandle = (e, ID) => {
    var InputParameter = {
      ID: ID,
      IsTwoStepVerification: e.target.checked,
      LastUpdatedBy: UserID,
      LastUpdatedDate: new Date(),
      Role: Role,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/users_per_client/TwoStepVerificationUpdate",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res) {
        if ((res.statusText = "Ok")) {
          if (res.data.Data.IsTwoStepVerification == true) {
            toast.success(
              <div className="toastsize">
                Client users
                <br /> Two Step Verification Activated successfully.{" "}
              </div>
            );
          } else {
            toast.success(
              <div className="toastsize">
                Client users
                <br /> Two Step Verification Deactivated successfully.{" "}
              </div>
            );
          }
          UsersPerClient(ClientID, PUserID, Role);
        } else {
        }
      }
    });
  };
  return (
    <>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}

      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row pt-4">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Is Two Step Verification</TableCell>
                <TableCell>
                  {" "}
                  <a
                    onClick={() => {
                      SortData("UsersPerClientName");
                    }}
                  >
                    Name
                  </a>
                </TableCell>
                <TableCell>
                  {" "}
                  <a
                    onClick={() => {
                      SortData("Email");
                    }}
                  >
                    Email
                  </a>
                </TableCell>
                <TableCell>
                  {" "}
                  <a
                    onClick={() => {
                      SortData("IsSentMeetingNotification");
                    }}
                  >
                    Is Sent Meeting Notification
                  </a>
                </TableCell>
                <TableCell>
                  {" "}
                  <a
                    onClick={() => {
                      SortData("IsSentPotentialMeetingNotification");
                    }}
                  >
                    Is Sent Potential Meeting Notification
                  </a>
                </TableCell>
                <TableCell>
                  {" "}
                  <a
                    onClick={() => {
                      SortData("IsSentWeeklyEmailNotification");
                    }}
                  >
                    Is Sent Weekly Email Notification
                  </a>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows.map((row) => (
                  <TableRow key={row._id} row={row}>
                    <TableCell>
                      {row?.IsTwoStepVerification ? (
                        <div className="toggleswich">
                          <input
                            type="checkbox"
                            className="checktogle"
                            onChange={(e) => {
                              ToggleBtnHandle(e, row._id);
                            }}
                            defaultChecked
                          />
                          <b className="switch">
                            <span className="checkion"></span>
                            <span className="uncheckion"></span>
                          </b>
                          <b className="track"></b>
                        </div>
                      ) : (
                        <div className="toggleswich">
                          <input
                            type="checkbox"
                            className="checktogle"
                            onChange={(e) => {
                              ToggleBtnHandle(e, row._id);
                            }}
                          />
                          <b className="switch">
                            <span className="checkion"></span>
                            <span className="uncheckion"></span>
                          </b>
                          <b className="track"></b>
                        </div>
                      )}
                    </TableCell>
                    <TableCell
                      className="labeltds d-flex align-items-center"
                      scope="row"
                    >
                      <div class="carduser_pic">
                        <img
                          style={imgstyle}
                          src={
                            row.ProfileImage == ""
                              ? CommonConstants.Image_url + "default.png"
                              : CommonConstants.Image_url + row.ProfileImage
                          }
                        ></img>
                      </div>
                      {row?.UsersPerClientName}
                    </TableCell>

                    <TableCell>{row?.Email}</TableCell>
                    <TableCell>
                      {row?.IsSentMeetingNotification ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      {row?.IsSentPotentialMeetingNotification ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      {row?.IsSentWeeklyEmailNotification ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      <a title="Edit"
                        onClick={() => {
                          EditBtn(row?._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i class="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      <a title="Delete"
                        onClick={() => {
                          DeleteBtn(row?._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i class="la flaticon-delete-1 delete-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Pagenations
         Sflag={Sflag}
         CountPage={CountPage}
         HandleChangePage={HandleChangePage}
         Rlen={Rlen}
         Page={Page}
         RowsPerPage={RowsPerPage}
         Flen={Flen}
      />
      {/* {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )} */}
    </>
  );
}
