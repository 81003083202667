import React,{useState,useEffect} from 'react';
import moment from 'moment';
import Axios from "axios";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails, DomainValidation } from "../../../_helpers/Utility";
const ViewAccountStats = ({Role,SalesReplyAccountID,UserID,ClientID,EmailDomain,CreatedDate}) => {
    const [GetAccountStats,SetGetAccountStats] = useState([]);
  // console.log({Role,SalesReplyAccountID,UserID,ClientID,EmailDomain,CreatedDate})
    useEffect(()=>{
        GetAccountsStats(Role,SalesReplyAccountID,UserID,ClientID,EmailDomain)
    },[SalesReplyAccountID,UserID,ClientID,EmailDomain])

   const GetAccountsStats = async(Role,SalesReplyAccountID,UserID,ClientID,EmailDomain) => {
        var InputParameters = {
          Role: Role,
          SalesReplyAccountID: SalesReplyAccountID,
          UserID:UserID,
          ClientID:ClientID,
          EmailDomain:EmailDomain
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/accounts/GetAccountStatsDetails",
          method: "POST",
          data: InputParameters,
        }).then((res) => {
            if(res.data.StatusMessage === 'SUCCESS'){
                SetGetAccountStats(res.data.Data)
            }else{
                SetGetAccountStats({})
            //   toast.error(res.data.Message)
            }
        });
      }

   const renderDate=(date) =>{
        return date ? moment(date).format("MM/DD/YYYY") : "";
      }
  return (
   <>
    <div className="paddcols px-4 mt-3">
            <div className="row p-3 pb-0">
              <div className="col padd-0 d-flex align-items-center">
                <h3 className=" float-left pb-4 addpotential_header">Account Stats</h3>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text"> Created Date :</label>
                  <div className="col-lg-7">
                    <label className="col-lg-4 view-lable-meet potential_text">
                     <b>{renderDate(CreatedDate)}</b> 
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">Last Validation Date :</label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"><b>{renderDate(GetAccountStats?.LastValidationDate)}</b> </label>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">Last Email Send Date :</label>
                  <div className="col-lg-7">
                    <label className="col-lg-4">
                    <b>{renderDate(GetAccountStats?.LastEmailSendDate)}</b>
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">Last Open Date :</label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"><b>{renderDate(GetAccountStats?.LastOpenDate)}</b></label>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">Last Response Date</label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"><b>{renderDate(GetAccountStats?.LastResponsesDate)}</b></label>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text"> Last Direct Mail Date</label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"><b>{renderDate(GetAccountStats?.LastResponsesDate)}</b></label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text"> Last Letter Date</label>
                  <div className="col-lg-7"><label className="col-lg-4"></label></div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text"> Last Status Change</label>
                  <div className="col-lg-7">
                    {/* <label className="col-lg-4"><b>{renderDate(parentValueLastStatusChageValue)}</b> </label> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text"> Is Blacklisted :</label>
                  <div className="col-lg-7"><label className="col-lg-4"><b>{GetAccountStats?.IsBlackListed}</b></label></div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">Total Email Count :</label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"><b>{GetAccountStats?.TotalEmailSent == null || undefined || "" ? 0 : GetAccountStats?.TotalEmailSent}</b> </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">Total Touches :</label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"><b>{GetAccountStats?.TotalTouches == null || undefined || "" ? 0 : GetAccountStats?.TotalTouches}</b></label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Tracked Email Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"><b>{GetAccountStats?.TotalTrackedEmail == null || undefined || "" ? 0 : GetAccountStats?.TotalTrackedEmail}</b></label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Open Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"><b>{GetAccountStats?.OpenedCount == null || undefined || "" ? 0 : GetAccountStats?.OpenedCount}</b></label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Reply Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"><b>{GetAccountStats?.TotalReply == null || undefined || "" ? 0 : GetAccountStats?.TotalReply}</b></label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Touches Before First Reply :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"><b>{GetAccountStats?.TouchesBeforeFirstReply == null || undefined || "" ? 0 : GetAccountStats?.TouchesBeforeFirstReply}</b></label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Removed Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4">0</label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Soft Bounce Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"><b>{GetAccountStats?.TotalSoftBounce == null || undefined || "" ? 0 : GetAccountStats?.TotalSoftBounce}</b></label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Hard Bounce Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4"><b>{GetAccountStats?.TotalHardBounce == null || undefined || "" ? 0 : GetAccountStats?.TotalHardBounce}</b></label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Letter Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4">0</label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    Direct Mail Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4">0</label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-1 px-2">
                <div className="row max-cols d-flex align-items-center">
                  <label className="col-lg-4 view-lable-meet potential_text">
                    View Count :
                  </label>
                  <div className="col-lg-7">
                    <label className="col-lg-4">0</label>
                  </div>
                </div>
              </div>
            </div>
          </div> 
   </>
  )
}

export default ViewAccountStats