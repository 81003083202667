import React from "react";
import Axios from "axios";

import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";

import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

class CEditMeetingOwnerPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      MeetingEmailAvailable: null,
      ClientID: null,
      UserID: null,
      CUserID: null,
      OldName: null,
      IsButtonDisabled:false,
      loadingState: true, 
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
  }

  componentDidMount() {
    document.title = `Edit Meeting Owner | SalesHive`;
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
    this.MeetingOwnersEdit();
  }

  // form validation
  async FromValidation() {
    let FormIsValid = true;
    let Fields = this.state.Fields;
    let Errors = {};
    var Name = document.getElementById("Name").value.trim();
    var Email = document.getElementById("Email").value.trim();
    let IsExist = await this.MeetingOwnersCheckExist(Email);
    if (Name == "") {
      FormIsValid = false;
      Errors["Name"] = "Please enter first name";
    }
    if (IsExist == "InvalidEmail") {
      FormIsValid = false;
      Errors["Email"] = "invalid email.";
    }
    if (IsExist == "ExistEmail") {
      FormIsValid = false;
      Errors["Email"] = "Email Already Exist";
    }

    if (IsExist == "BlankData" && Email == "") {
      FormIsValid = false;
      Errors["Email"] = "Please enter email";
    }
    // if (!this.state.IsValid) {
    //   FormIsValid = false;
    //   Errors["Email"] = "invalid email";
    // }
    this.setState({ Errors: Errors });

    return FormIsValid;
  }

  // form validation
  async HandleChangeBlur(NameEmail) {
    let Errors = {...this.state.Errors};
    var Name = document.getElementById("Name").value.trim();
    var Email = document.getElementById("Email").value.trim();

    if(NameEmail=="EmailDetails"){
      let IsExist = await this.MeetingOwnersCheckExist(Email);
      if (IsExist == "InvalidEmail") {
        Errors["Email"] = "invalid email.";
      }
      if (IsExist == "ExistEmail") {
        Errors["Email"] = "Email Already Exist";
      }
  
      if (IsExist == "BlankData" && Email == "") {
        Errors["Email"] = "Please enter email";
      }

      if (IsExist == "AllDone") {
        Errors["Email"] = null
      }
    }

    if(NameEmail=="NameDetails"){
      if (Name == "") {
        Errors["Name"] = "Please enter first name";
      }else{
        Errors["Name"] = null
      }
    }
    
   
    this.setState({ Errors: Errors });
  }

  // handle change
  HandleChange(Field, e) {
    let Fields = this.state.Fields;
    Fields[Field] = e.target.value;
    this.setState({ Fields });
    var Email = document.getElementById("Email").value.trim();
    if (Email != "") {
      this.state.Errors.Email = null;
      this.state.MeetingEmailAvailable = null;
      this.MeetingOwnersCheckExist(Email);
    } else {
      this.state.MeetingEmailAvailable = null;
      this.state.Errors.Email = null;
    }
  }

  // find exits meeting owner Email
  async MeetingOwnersCheckExist(Email) {
    let regexp =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let Errors = {};
    if (Email != "") {
    if (!regexp.test(Email)) {
      //Errors["Email"] = "invalid email";
      // this.setState({ Errors: Errors });
      // return true;
      return "InvalidEmail";
    } else {
      // this.setState({ IsExist: false });
      var MeetingSource = {
        ClientID: this.state.ClientID,
        Email: Email,
        Role: this.state.Role,
      };
      var resdata = await Axios({
        url: CommonConstants.MOL_APIURL + "/meetingowner/MeetingOwnerExists",
        method: "POST",
        data: MeetingSource,
      });
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          if(this.state.OldName == Email){
            return "AllDone";
          }else{
            this.setState({ MeetingEmailAvailable: resdata.data.Data[0].Name });
            return "ExistEmail";
          }
        } else {
          return "AllDone";
        }
      }
    }
  }else {
    return "BlankData";
  }
    
  }

  // find record by id for edit
  MeetingOwnersEdit() {
    const id = this.props.location.state;
    this.setState({ id: id });
    var str_in = {
      id: id,
      Role: this.state.Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/meetingowner/MeetingownerByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById("Name").value = res.data.Data[0]?.FirstName;
        document.getElementById("LastName").value = res.data.Data[0]?.LastName ||""
        document.getElementById("Conference").value = res.data.Data[0]?.ConferenceLineDialIn ?? "";
        document.getElementById("Email").value = res.data.Data[0]?.Email || ""
        document.getElementById("MeetingPlatformName").value = res.data.Data[0]?.MeetingPlatformName || ""

        this.setState({ OldName: res.data.Data[0]?.Email });
        this.setState({ loadingState: false })
      } else {
        toast.error(res.data.Message);
        this.setState({ loadingState: false })
      }
    });
  }

  // back button
  BackBtn() {
    history.push("/CMeetingowner");
  }

  // meeting owner save
  async SaveBtn(e) {
    this.setState({IsButtonDisabled:true});
    // e.preventDefault();
    // e.currentTarget.disabled = true;
   
    var Final_flag = await this.FromValidation();

    if (Final_flag == true) {
      this.setState({ loadingState: true })
      var Name = document.getElementById("Name").value;
      var lastName = document.getElementById("LastName").value;
      var email = document.getElementById("Email").value;
      var Conference = document.getElementById("Conference").value;
      var MeetingPlatformName = document.getElementById("MeetingPlatformName").value;
     
      var MeetingOwnerEdit = {
        _id: this.state.id,
        ClientID: this.state.ClientID,
        Name: Name,
        IsDeleted: false,
        LastUpdatedDt: new Date(),
        LastUpdatedBy: this.state.CUserID,
        Email: email,
        ConferenceLineDialIn: Conference,
        IsOmitMeetingOwnerSentence: false,
        FirstName: Name,
        LastName: lastName,
        UserID: this.state.UserID,
        Role: this.state.Role,
        MeetingPlatformName:MeetingPlatformName
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/meetingowner/MeetingOwnerUpdate",
        method: "POST",
        data: MeetingOwnerEdit,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">Meeting Owner updated successfully.</div>
          );
          history.push("/CMeetingowner");
          this.setState({ loadingState: false })
        } else {
          toast.error(res.data.Message);
          
          this.setState({IsButtonDisabled:false});
          this.setState({ loadingState: false })
        }
      });
    } else {
      this.setState({IsButtonDisabled:false});
      this.setState({ loadingState: false })
      //document.getElementById("submit").disabled = false;
    }
  }

  render() {
    return (
      <>
    {this.state.loadingState ? (
      <div id="hideloding" className="loding-display">
      <img src={loadingicon} />
    </div>
      ) : null}
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 

          <div className="bodyhome">
            
            <div className="row">
              <div className="col">
                <h4 className="headertitlepad float-left">
                  Edit Meeting Owner
                </h4>
              </div>
            </div>

            <div class="paddcols">
              <div className="row py-4">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">First Name</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter  name"
                        // onChange={this.HandleChange.bind(this, "Name")}
                        // value={this.state.Fields["Name"]}
                        onBlur={() =>
                          this.HandleChangeBlur("NameDetails")}
                        id="Name"
                        name="Name"
                        placeholder="Name"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Last Name</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter LastName"
                        onChange={this.HandleChange.bind(this, "LastName")}
                        value={this.state.Fields["LastName"]}
                        id="LastName"
                        // onBlur={this.handleChange.bind(this, "LastName")}
                        name="LastName"
                        placeholder="LastName"
                        type="text"
                      />
                      <span
                        id="lastname_e"
                        style={{ color: "red", display: "none" }}
                      >
                        Please enter LastName.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Email</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter  Email"
                         onBlur={() =>
                          this.HandleChangeBlur("EmailDetails")}
                        //onBlur={this.HandleChange.bind(this, "Email")}
                        // value={this.state.Fields["Email"]}
                        id="Email"
                        name="Email"
                        placeholder="Email"
                        type="text"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Email"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                      Conference Line Dial In
                    </label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter Conference"
                        onChange={this.HandleChange.bind(this, "Conference")}
                        value={this.state.Fields["Conference"]}
                        id="Conference"
                        // onBlur={this.handleChange.bind(this, "Conference")}
                        name="Conference"
                        placeholder="Conference Line Dial In"
                        type="text"
                      />
                      <span
                        id="conf_e"
                        style={{ color: "red", display: "none" }}
                      >
                        Please enter Conference.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">
                    Meeting Platform Name 
                    </label>
                    <div class="col-lg-8">
                    <input
                        class="form-control m-input"
                        data-val="true"
                        // data-val-required="Please enter first MeetingPlatformName"
                        id="MeetingPlatformName"
                        onBlur={() =>
                          this.HandleChangeBlur("MeetingPlatformNameDetails")}
                        name="MeetingPlatformName"
                        placeholder="Enter Meeting Platform Name"
                        type="text"
                      />
                    
                      <span style={{ color: "red" }}>
                        {this.state.Errors["MeetingPlatformName"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row mt-4">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.IsButtonDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <button
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </button>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};


const connectedCEditMeetingOwnerPage = connect(
  mapState,
  actionCreators
)(CEditMeetingOwnerPage);
export { connectedCEditMeetingOwnerPage as CEditMeetingOwnerPage };
