import React from 'react';
import { CSVDownloader } from "react-papaparse";
import Axios from "axios";
import { toast } from 'react-toastify';

import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from "../../_components/user/footer/footer";
import SendingSchedularTable from '../../_components/clients/sendingschedular/sendingschedular';
import CollapsibleTable from '../../_components/usertable';
import { history } from "../../_helpers";
import { GetUserDetails } from "../../_helpers/Utility";
import { CommonConstants } from "../../_constants/common.constants";


class CSendingSchedularPage extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        ExportData: [],
        ClientID: null,
        UserID: null,
        Role:null
      };
      this.ExportCsv = this.ExportCsv.bind(this);
    }
  
    SendingschedularAdd() {
      history.push("/CAddSendingSchedular");
  
    }
    ExportCsv() {
      toast.success(<div>Sending Schedular <br/>Data exported successfully.</div>)
    }
  
    componentDidMount() {
      var Details = GetUserDetails();
      if (Details != null) {
        this.state.ClientID = Details.ClientID;
        this.state.UserID = Details.ParentUserID;
        this.state.Role = Details.Role;
      }
      var str_in1 = {
        ClientID: this.state.ClientID,
        UserID:this.state.UserID,
        Role:this.state.Role
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/sendingschedular/SendingSchedularExport",
        method: "POST",
        data: str_in1,
      }).then((res) => {
        this.setState({ ExportData: res.data });
        
      });
     
  
    }
     //Reload page while delete perform
 UpdateFromChild = (value) => {
  if(value == true){
    this.componentDidMount()
  }
}
    render() {
      return (
        <>
          <div className="adminmain mheight"> 
            <Sidebar className="" /> 
  
            <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitle float-left px-0">Sending Schedules</h4>
                </div>
                
                <div className="col">
                  <div className="listing-li float-right pt-1">
                    <ul>
                      <li>
                      <CSVDownloader className='px-0'
                              data={this.state.ExportData}
                              filename={this.state.ClientID+"Sending-Schedular"}
                              bom={true}
                            >
                        <a
                          onClick={this.ExportCsv}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-download"></i>
                          <span>
                              Export
                          </span>
                        </a>
                        </CSVDownloader>
                      </li>
                      <li>
                        <a
                          onClick={this.SendingschedularAdd.bind(this)}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-plus"></i>
                          <span>Add</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="paddcols px-0 pb-4">
                <div className="row">
                  <div className="col px-5">
                    <SendingSchedularTable updateFromChild={this.UpdateFromChild}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <Footer/>
        </>
      );
    }
  }
  

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCSendingSchedularPage = connect(mapState, actionCreators)(CSendingSchedularPage);
export { connectedCSendingSchedularPage as CSendingSchedularPage };