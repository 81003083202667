import React, { useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});


export default function MeetingSourceTable() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Name");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [DeleteDataDropdown,SetDeleteDataDropdown] = React.useState([]);
  const [CUserID,SetCUserID] = React.useState(0)
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [sortedColumn, setSortedColumn] = React.useState("Name");
  const [TotalMeetingSources , SetTotalMeetingSources] = React.useState(0)


  useEffect(() => {
    document.title = `Meeting Source | SalesHive`;
    let Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

    MeetingSourceGet(Details.ClientID,Details.ParentUserID,Details.Role);
  }, [Search, Page, RowsPerPage]);

  // get meeting source list
  const MeetingSourceGet = (CID,UID,URole) => {
    SetIsLoading(true)
    let InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/meetingsource/MeetingSourceGet",
      method: "POST",
      data: InputParameter,
    });
    rows1.then((result) => {
      if(result.data?.StatusMessage === "SUCCESS"){
        SetData(result.data.PageData);
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
        SetTotalMeetingSources(result.data.TotalMeetingSources);
        SetDeleteDataDropdown(result.data.DeleteDropDownData);
        SetIsLoading(false)
      }else{
        SetIsLoading(false)
      }
    });
  };

  // delete meeting source
  const DeleteBtn = (ID,MSName) => {
    let Datas = DeleteDataDropdown.filter(obj => obj._id !== ID);;
    let MeetingSourceOption =[]
    Datas.map((source) => {
      MeetingSourceOption[source._id] = `${source.Name} `;
    });

    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a Meeting source.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title:"Replace Contact Source",
          text:"Which value replace with '"+MSName+"' meeting source?",
          confirmButtonColor: "#34bfa3",
          cancelButtonColor: "#34bfa3",
          confirmButtonText: "Save",
          cancelButtonText: "Cancel",
          reverseButtons: true,
          showCancelButton: true,
          input: "select",
          inputOptions: MeetingSourceOption,
          inputPlaceholder: MeetingSourceOption[0],
          position: 'top',
          showCloseButton: true,
          customClass: { 
            popup: 'replaceaccountpopup', 
            confirmButton: 'btn btn-primary btnColor',
          }
  
         }).then((Suecces)=>{
          if(Suecces.isConfirmed){
            let UpdateId = Suecces.value;
            let InputParameter = {
              MeetingSourceID: ID,
              LastUpdatedBy: CUserID,
              LastUpdatedDt: new Date(),
              Role:Role
            };
            Axios({
              url:
                CommonConstants.MOL_APIURL + "/meetingsource/MeetingSourceDelete",
              method: "POST",
              data: InputParameter,
            }).then(async (res) => {
              if (res) {
                if ((res.statusText = "Ok")) {
                  let str_in = {
                    OldID:ID,
                    NewID:UpdateId,
                    ClientID:ClientID,
                    LastUpdatedBy:CUserID,
                    LastUpdatedDate: new Date()
                  }
                  const MeetingSource = await Axios({
                    url: CommonConstants.MOL_APIURL + "/bookedmeeting/MeetingSourceUpdate",
                    method: "POST",
                    data: str_in,
                  });
                  if(MeetingSource.data?.StatusMessage == "SUCCESS"){
                    Swal.fire(
                      "Deleted!",
                      "Meeting source deleted successfully.",
                      "success"
                    );
                  
                    MeetingSourceGet(ClientID,UserID,Role);
                    history.push("/CMeetingSource")
                    SetPage(1)
                    SetSearch("")
                    document.getElementById("search").value = ""
                  }
                } else {
                }
              }
            });
          }else if (Suecces.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelled", "Your data is safe :)", "error");
          }
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // edit btn
  const EditBtn = (ID) => {
    history.push({ pathname: "/CEditMeetingSource", state: { data: ID } });
  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      let SearchedVal = document.getElementById("search").value;
      if(SearchedVal==Search){
        SetSearch(SearchedVal);
        SetPage(1);
      }else{
        SetSearch(SearchedVal);
        SetPage(1);
       
      }
    }
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if(NewPage==Page){
      SetPage(NewPage);
    }else{
      SetPage(NewPage);
      
    }
  };

// change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
    
  };

//get sort field data
const SortData = (Field) => {
  setSortedColumn(Field);
  SetIsLoading(true);
  const SearchedVal = document.getElementById("search").value?.trim();
  const isNewSortField = Field !== SortField;
  let SortBy = SortedBy === 1 ? -1 : 1;

  if (isNewSortField) {
    SetSortedBy(1);
    SortBy = 1;
  } else {
    SetSortedBy(SortBy);
  }

  SetSortField(Field);
  SetSflag(SearchedVal !== "");
  let InputParameter = {
    Page: Page,
    RowsPerPage: RowsPerPage,
    Search: SearchedVal,
    Sort: true,
    Field: Field,
    SortBy: SortBy,
    Type: "User",
    ClientID: ClientID,
    UserID: UserID,
    Role: Role,
  };
  const ApiResponse = Axios({
    url:CommonConstants.MOL_APIURL +"/meetingsource/MeetingSourceGet",
    method: "POST",
    data: InputParameter,
  });
  ApiResponse.then((Result) => {
    SetRows(Result.data.PageData);
    SetRlen(Result.data.TotalCount);
    SetFlen(Result.data.TotalCount);
    SetCountPage(Result.data.PageCount);
    SetTotalMeetingSources(Result.data.TotalMeetingSources);
    SetPage(1);
    SetIsLoading(false)
  });

  // let SearchedVal = document.getElementById("search").value;
  // SetIsLoading(true)
  // // SetPage(1);
  // // SetRowsPerPage(10);
  // let SerchBox;
  // if (SearchedVal == "") {
  //   SerchBox = false;
  //   SetSflag(false);
  //   let SortField = Field;
  //   let SortBy;
  //   if (SortedBy == 1) {
  //     SortBy = -1;
  //     SetSortedBy(-1);
  //   } else {
  //     SortBy = 1;
  //     SetSortedBy(1);
  //   }
  //   SetSortField(Field);
  //   let InputParameter = {
  //     Page: Page,
  //     RowsPerPage: RowsPerPage,
  //     SerchBox: false,
  //     Search: SearchedVal,
  //     Archive: false,
  //     Sort: true,
  //     Field: SortField,
  //     SortBy: SortBy,
  //     Type: "User",
  //     ClientID: ClientID,
  //     UserID: UserID,
  //     Role: Role,
  //   };
  //   const ApiResponse = Axios({
  //     url:CommonConstants.MOL_APIURL +"/meetingsource/MeetingSourceGet",
  //     method: "POST",
  //     data: InputParameter,
  //   });
  //   ApiResponse.then((Result) => {
  //     SetRows(Result.data.PageData);
  //     SetRlen(Result.data.TotalCount);
  //     SetFlen(Result.data.TotalCount);
  //     SetCountPage(Result.data.PageCount);
  //     SetIsLoading(false)
  //   });
  // } else {
  //   SerchBox = true;
  //   SetSflag(true);
  //   let SortField = Field;
  //   let SortBy;
  //   if (SortedBy == 1) {
  //     SortBy = -1;
  //     SetSortedBy(-1);
  //   } else {
  //     SortBy = 1;
  //     SetSortedBy(1);
  //   }
  //   SetSortField(Field);
  //   let InputParameter = {
  //     Page: Page,
  //     RowsPerPage: RowsPerPage,
  //     Search: SearchedVal,
  //     SerchBox: SerchBox,
  //     Archive: false,
  //     Sort: true,
  //     Field: SortField,
  //     SortBy: SortBy,
  //     Type: "User",
  //     ClientID: ClientID,
  //     UserID: UserID,
  //     Role: Role,
  //   };
  //   const ApiResponse = Axios({
  //     url:CommonConstants.MOL_APIURL +"/meetingsource/MeetingSourceGet",
  //     method: "POST",
  //     data: InputParameter,
  //   });
  //   ApiResponse.then((Result) => {
  //     SetRows(Result.data.PageData);
  //     SetRlen(Result.data.TotalCount);
  //     SetFlen(Result.data.TotalCount);
  //     SetCountPage(Result.data.PageCount);
  //     SetIsLoading(false)
  //   });
  // }
};

  return (
    <div>
     {
      IsLoading ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : <></>
    }
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
              <TableCell  onClick={() => {SortData("Name"); setSortedColumn("Name");}}>
                    Name
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Name" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Name" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell  onClick={() => {SortData("FieldName"); setSortedColumn("FieldName");}}>
              Meeting Channel
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "FieldName" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "FieldName" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell  onClick={() => {SortData("MeetingChannelType"); setSortedColumn("MeetingChannelType");}}>
              Meeting Channel Type
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "MeetingChannelType" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "MeetingChannelType" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell  onClick={() => {SortData("IsSetByPipestry"); setSortedColumn("IsSetByPipestry");}}>
              Count In Reporting?
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "IsSetByPipestry" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "IsSetByPipestry" ? "active" : null} />
                </span>
              </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow  key={row?._id}>
                    <TableCell>{row?.Name}</TableCell>
                    <TableCell>{row?.FieldName}</TableCell>
                    <TableCell>{row?.MeetingChannelType}</TableCell>
                    <TableCell>{row?.IsSetByPipestry==true?"Yes":"No" }</TableCell>
                    <TableCell>
                      <a
                        onClick={() => {
                          EditBtn(row._id);
                        }}
                        className="btn-eyesicon"
                        title="Edit"
                      >
                        <i className="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      {
                      TotalMeetingSources <= 1 ? <></> : <a
                      onClick={() => {
                        DeleteBtn(row._id,row.Name);
                      }}
                      className="btn-eyesicon"
                      title="Delete"
                    >
                      <i className="la flaticon-delete-1 delete-icon"></i>
                    </a>
                     } 
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
      
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
    </div>
  );
}
