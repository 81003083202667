import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";
import Popup from "reactjs-popup";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Avatar } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function AccountAllTable() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [PUserID, SetPUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsArchive, SetIsArchive] = React.useState(false);

  const [AnchorEl, SetAnchorEl] = React.useState(null);
  const [AnchorElf, setfAnchorEl] = React.useState(null);
  const [AID, SetAID] = React.useState("");
  const [CheckS, SetCheckS] = React.useState(false);
  const [OrignalData, SetOrignalData] = React.useState([]);

  const [ArchivePopUp, SetArchivePopUp] = React.useState(false);
  const [CancelArchivePopUpVal, SetCancelArchivePopUpVal] =
    React.useState(false);
  const [ArchivedPopup, SetArchivedPopup] = React.useState(false);
  const [Question, SetQuestion] = React.useState("");
  const [Msg, SetMsg] = React.useState("");
  const [Type, SetType] = React.useState("");
  const [PopUpButton, SetPopUpButton] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false);

  useEffect(() => {
    document.title = "Sales Reps | SalesHive";

    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ChildUserID);
      SetPUserID(Details.ParentUserID);
      SetRole(Details.Role);
    }

    SalesRepsGet(Details.ClientID, Details.ParentUserID, Details.Role);
  }, [IsArchive, Search, Page, RowsPerPage]);

  const imgstyle = {
    height: "40px",
    width: "40px",
    borderradius: "50%",
  };

  //Sales Reps get list
  const SalesRepsGet = (CID, UID, URole) => {
    SetIsLoading(true);
    var MeetingGoalData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search?.trim(),
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      IsArchive: IsArchive,
    };

    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/salesrep/SalesRepsGet",
      method: "POST",
      data: MeetingGoalData,
    });
    rows1.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false);
    });
  };

  //Search Box
  // const SearchData = () => {
  //   SetIsLoading(true)
  //   let IsArchived = document.getElementById("includeArchived").checked;
  //   SetIsArchive(IsArchived);
  //   SetIsLoading(false)
  // };
  const SearchData = () => {
    SetIsLoading(true);
    let IsArchived = document.getElementById("includeArchived").checked;
    SetIsArchive(IsArchived);

    // Introduce a brief delay (e.g., 100 milliseconds) before turning off the loader
    setTimeout(() => {
      SetIsLoading(false);
    }, 200);
  };

  // Reset filter
  const ResetFilter = () => {
    SetIsLoading(true);
    let IsArchived = (document.getElementById(
      "includeArchived"
    ).checked = false);
    SetIsArchive(IsArchived);
    SalesRepsGet(ClientID, PUserID, Role);
    setTimeout(() => {
      SetIsLoading(false);
    }, 200);
  };

  //search for record
  const RequestSearch = (event) => {
    // SetIsLoading(true)
    if (event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value?.trim();
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
    // SetIsLoading(false)
  };

  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  //Sales Reps delete
  const DeleteBtn = (e, id) => {
    SetAnchorEl(null);
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a Sales Reps.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          SalesRepID: AID,
          LastUpdatedBy: UserID,
          LastUpdatedDate: new Date(),
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/salesrep/SalesRepsDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Sales Reps deleted successfully.",
                "success"
              );
              SetSearch("");
              SetPage(1);
              document.getElementById("search").value = "";
              SalesRepsGet(ClientID, PUserID, Role);
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // Edit btn
  const EditBtn = (id) => {
    history.push("/editsalesrep", id);
  };

  //get sort field data
  const SortData = (Field) => {
    SetIsLoading(true);

    var SearchedVal = document.getElementById("search").value?.trim();
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Search: SearchedVal,
        IsArchive: IsArchive,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: PUserID,
        Role: Role,
      };

      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/salesrep/SalesRepsGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        IsArchive: IsArchive,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: PUserID,
        Role: Role,
      };

      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/salesrep/SalesRepsGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      });
    }
  };

  //handle click
  const HandleClick = (event, id) => {
    SetAID(id);
    SetAnchorEl(event.currentTarget);
  };

  //handle close
  const HandleClose = () => {
    SetAnchorEl(null);
  };

  //handle change anchrEl
  const HandleClickOne = (event, id) => {
    SetAID(id);
    setfAnchorEl(event.currentTarget);
  };

  //handle change anchrEl
  const HandleCloseOne = () => {
    setfAnchorEl(null);
  };

  //Search click
  const ClickOnSearch = () => {
    SetIsLoading(true);

    var Check = document.getElementById("includeArchived");
    if (Check.checked == true) {
      SetCheckS(true);
      var str_in = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        ClientID: ClientID,
        UserID: PUserID,
        IsDeleted: false,
        SearchBox: false,
        IsArchive: true,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Type: "User",
      };
      const Rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/salesrep/SalesRepsGet",
        method: "POST",
        data: str_in,
      });
      Rows1.then((Result) => {
        SetData(Result.data.PageData);
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      });
    } else {
      SetCheckS(false);
      var str_in = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        ClientID: ClientID,
        UserID: PUserID,
        IsDeleted: false,
        SearchBox: false,
        IsArchive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Type: "User",
      };
      const Rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/salesrep/SalesRepsGet",
        method: "POST",
        data: str_in,
      });
      Rows1.then((Result) => {
        SetData(Result.data.PageData);
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      });
    }
  };

  //Archive User
  const ArchiveUser = () => {
    var str_in = {
      ID: AID,
      Flag: "Archive",
      LastUpdatedBy: UserID,
      LastUpdatedDate: new Date(),
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/salesrep/SalesRepsArchive",
      method: "POST",
      data: str_in,
    }).then((res) => {
      HandleCloseOne();
      SetAID("");
      ClickOnSearch();
      // OriginalDataStore();
    });
  };

  //Unarchive user
  const UnArchiveUser = () => {
    var str_in = {
      ID: AID,
      Flag: "UnArchive",
      LastUpdatedBy: UserID,
      LastUpdatedDate: new Date(),
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/salesrep/SalesRepsArchive",
      method: "POST",
      data: str_in,
    }).then((res) => {
      HandleClose();
      ClickOnSearch();
    });
  };

  //Achive open
  const ArchiveOpen = (popupType) => {
    SetType(popupType);
    if (popupType == "Archive") {
      HandleCloseOne();
      SetMsg("you want to Archive a Sales Reps.");
      SetQuestion("Are you sure?");
      SetPopUpButton("Yes, Archive it!");
    } else if (popupType == "UnArchive") {
      HandleClose();
      SetMsg("you want to UnArchive a Sales Reps.");
      SetQuestion("Are you sure?");
      SetPopUpButton("Yes, UnArchive it!");
    } else {
      HandleClose();
      SetMsg("you want to Delete a Sales Reps.");
      SetQuestion("Are you sure?");
      SetPopUpButton("Yes, delete it!");
    }
    SetArchivePopUp(true);
  };

  //Archive submit
  const ArchiveSubmit = () => {
    SetArchivePopUp(false);
    if (Type == "Archive") {
      SetMsg("Sales Reps Archived successfully.");
      SetQuestion("Archived!");
      SetArchivedPopup(true);
      ArchiveUser();
    } else if (Type == "UnArchive") {
      SetMsg("Sales Reps UnArchived successfully.");
      SetQuestion("UnArchived!");
      SetArchivedPopup(true);
      UnArchiveUser();
    } else {
      SetMsg("Sales Reps Delete successfully.");
      SetQuestion("Delete");
      SetArchivedPopup(true);
      DeleteUser();
    }
  };

  //archive cancle
  const ArchiveCancle = () => {
    SetArchivePopUp(false);
    OpenArchivePopUp();
  };

  //archive cancle
  const OpenArchivePopUp = () => {
    SetCancelArchivePopUpVal(true);
  };

  //cancle archive popup
  const CancleArchivePopUp = () => {
    SetCancelArchivePopUpVal(false);
  };

  //responder archive
  const ResponderArchive = () => {
    SetArchivedPopup(false);
  };

  return (
    <>
      {IsLoading === true ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}

      <Popup
        open={ArchivePopUp}
        modal
        className="d-flex align-items-center justify-content-center"
      >
        <div className="modal-black"></div>
        <div className="filterPopup maxpopup">
          <div className="paddingboxTerms">
            <div className="modal-content bordernone aligncentertext pb-4">
              <div className="swal2-icon swal2-warning swal2-animate-warning-icon">
                <span className="swal2-icon-text">!</span>
              </div>
              <h3>{Question}</h3>
              <p>{Msg}</p>
              <div className="swal2-actions mb-2">
                <button
                  type="button"
                  onClick={ArchiveCancle}
                  className="swal2-cancel btn btn-secondary m-btn m-btn--custom mr-2"
                >
                  No, cancel!
                </button>
                <button
                  type="button"
                  onClick={ArchiveSubmit}
                  className="swal2-confirm btn btn-success m-btn m-btn--custom"
                >
                  {PopUpButton}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <Popup
        open={CancelArchivePopUpVal}
        modal
        className="d-flex align-items-center justify-content-center"
      >
        <div className="modal-black"></div>
        <div className="filterPopup maxpopup ">
          <div className="paddingboxTerms">
            <div className="modal-content bordernone aligncentertext pb-4">
              <div className="swal2-icon swal2-error swal2-animate-error-icon d-flex">
                <span className="swal2-x-mark">
                  <span className="swal2-x-mark-line-left"></span>
                  <span className="swal2-x-mark-line-right"></span>
                </span>
              </div>
              <h3>Cancelled</h3>
              <p>Your data is safe :)</p>
              <div className="swal2-actions mb-2">
                {/* <button Type="button" className="swal2-cancel btn btn-secondary m-btn m-btn--custom mr-2">No, cancel!</button> */}
                <button
                  onClick={CancleArchivePopUp}
                  type="button"
                  className="swal2-confirm btn btn-success m-btn m-btn--custom"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <Popup open={ArchivedPopup} modal>
        <div className="modal-black"></div>
        <div className="filterPopup maxpopup">
          <div className="paddingboxTerms">
            <div className="modal-content bordernone aligncentertext pb-4">
              <div className="swal2-icon swal2-success swal2-animate-success-icon">
                <div className="swal2-success-circular-line-left"></div>
                <span className="swal2-success-line-tip"></span>
                <span className="swal2-success-line-long"></span>
                <div className="swal2-success-ring"></div>
                <div className="swal2-success-fix"></div>
                <div className="swal2-success-circular-line-right"></div>
              </div>
              <h3>{Question}</h3>
              <p>{Msg}</p>
              <div className="swal2-actions mb-2">
                {/* <button Type="button" className="swal2-cancel btn btn-secondary m-btn m-btn--custom mr-2">No, cancel!</button> */}
                <button
                  type="button"
                  onClick={ResponderArchive}
                  className="swal2-confirm btn btn-success m-btn m-btn--custom"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <div className="row my-3 borderbtnm pb-4">
        <div className="col col-lg-3">
          <button class="btn btn-primary btnColor ml-0" onClick={SearchData}>
            <i class="la la-search"></i>Search
          </button>
          <a class="ml-3 btn btn-secondary" onClick={ResetFilter}>
            <i class="la la-close"></i> Reset
          </a>
        </div>
        <div className="col col-lg-3 pt-2">
          <label class="check_bx">
            <input type="checkbox" id="includeArchived" />
            <span className="checkmark"></span>
            Include archived sales reps
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered my-4">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("Name");
                    }}
                  >
                    Sales Rep Name
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    onClick={() => {
                      SortData("CreatedDate");
                    }}
                  >
                    Created Date
                  </a>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row, index) => (
                  <TableRow key={row._id}>
                    <TableCell>{row.Name}</TableCell>
                    <TableCell>
                      {moment(new Date(row.CreatedDate).toDateString()).format(
                        "MM/DD/YYYY"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.IsArchive ? (
                        <a className="btn-eyesicon dopdownwe">
                          <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(e) => {
                              HandleClick(e, row._id);
                            }}
                          >
                            <i className="la la-ellipsis-h add-icon"></i>
                          </Button>
                          <Menu className="droptable"
                            id="simple-menu"
                            anchorEl={AnchorEl}
                            keepMounted
                            open={Boolean(AnchorEl)}
                            onClose={HandleClose}
                          >
                            <MenuItem
                              onClick={(e) => {
                                DeleteBtn(e, row._id);
                              }}
                            >
                              <i className="la flaticon-delete-1 mr-3">
                                Delete
                              </i>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                ArchiveOpen("UnArchive");
                              }}
                            >
                              {" "}
                              Unarchive
                            </MenuItem>
                          </Menu>
                        </a>
                      ) : (
                        <div>
                          <a
                            onClick={() => {
                              EditBtn(row._id);
                            }}
                            className="btn-eyesicon"
                          >
                            <i className="la flaticon-edit-1 edit-icon"></i>
                          </a>
                          <a className="btn-eyesicon dopdownwe">
                            <Button
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                              onClick={(e) => {
                                HandleClickOne(e, row._id);
                              }}
                            >
                              <i className="la la-ellipsis-h add-icon"></i>
                            </Button>
                            <Menu className="droptable"
                              id="simple-menu1"
                              anchorEl={AnchorElf}
                              keepMounted
                              open={Boolean(AnchorElf)}
                              onClose={HandleCloseOne}
                            >
                              <MenuItem
                                onClick={() => {
                                  ArchiveOpen("Archive");
                                }}
                              >
                                <i className="la flaticon-download mr-3"></i>{" "}
                                Archive
                              </MenuItem>
                            </Menu>
                          </a>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            page={Page}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              page={Page}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
