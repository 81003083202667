import React, { useEffect } from "react";
import Axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StyleHeader from "../StickyHeader/StickyHeader";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

//import Userimg from '../images/avatar/1.jpg';

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function CollapsibleTable() {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [flen, setFlen] = React.useState(0);
  const [sflag, setSflag] = React.useState(false);
  const [rlen, setRlen] = React.useState(0);
  const [countpage, setCountPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [search, setSearch] = React.useState("");
  const [aID, setAID] = React.useState("");
  const [checkS, setCheckS] = React.useState(false);
  const [originalData, setOriginalData] = React.useState([]);
  const [sortField, setSortField] = React.useState("FieldName");
  const [sortedBy, setSortedBy] = React.useState(1);
  const [sortedColumn, setSortedColumn] = React.useState("FieldName");
  const [isLoading, setIsLoading] = React.useState(false);
  const [clientID, setClientID] = React.useState(0);
  const [userID, setUserID] = React.useState(0);
  const [role, setRole] = React.useState("");
  const [cUserID, setCUserID] = React.useState(0);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    const getAccountDetails = async () => {
      const userDetails = await GetUserDetails();
      if (userDetails !== null) {
        setClientID(userDetails.ClientID);
        setUserID(userDetails.ParentUserID);
        setCUserID(userDetails.ChildUserID);
        setRole(userDetails.Role);
      }
      GetSeniorityList(userDetails.ClientID, userDetails.ParentUserID, userDetails.Role);
    };

    getAccountDetails();
  }, [search, page, rowsPerPage, sortField, sortedBy]);

  const GetSeniorityList = (CID, UID, URole) => {
    setIsLoading(true);
    const strIn = {
      page: page,
      rowsPerPage: rowsPerPage,
      sort: true,
      field: sortField,
      sortby: sortedBy,
      search: search,
      type: "User",
      ClientID: CID,
      Role: URole
    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetSeniority",
      method: "POST",
      data: strIn
    }).then((result) => {
       // Extracting "StatusCount" and "Percentage" from each object and adding them as separate properties
      const modifiedData = result.data.pagedata.map(item => ({
        ...item,
        MeetingStatusCount: item.Statusdetails[0]?.StatusCount,
        MeetingPercentage: Math.round(item.Statusdetails[0]?.Percentage)
      }));

      setRows(modifiedData);
      setData(modifiedData);
      setRlen(result.data.totalcount);
      setFlen(result.data.totalcount);
      setCountPage(result.data.pagecount);
      setIsLoading(false);
      setIsDataLoaded(true);
    });
  };

  const chengeRowSelected = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  };

  const requestSearch = (event) => {
    if (event.key === "Enter") {
      const searchedVal = document.getElementById("search").value;
      if (searchedVal === search) {
        setSearch(searchedVal);
        setPage(1);
      } else {
        setSearch(searchedVal);
        setPage(1);
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const toggleSort = (column) => {
    setIsLoading(true);
    if (sortedColumn === column) {
      setSortedBy(sortedBy === 1 ? -1 : 1);
      setIsLoading(false);
    } else {
      setSortedColumn(column);
      setSortedBy(1);
      setIsLoading(false);
    }
  };

  const sortRows = (column) => {
    const sortedRows = [...rows];

    sortedRows.sort((a, b) => {
      if (a[column] < b[column]) return -1;
      if (a[column] > b[column]) return 1;
      return 0;
    });

    if (sortedColumn === column && sortedBy === -1) {
      return sortedRows.reverse();
    }

    return sortedRows;
  };



  return (
    <div>
      {isLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : null}

      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={chengeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={rowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={requestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>

      <div className="table-bordered">
      <TableContainer component={Paper}> 
              <StyleHeader isDataLoaded={isDataLoaded} />
              <Table className="table-ref" aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell onClick={() => toggleSort("FieldName")}>
                Field Name
                <span className="shorting">
                  <ArrowUpward className={sortedBy === 1 && sortedColumn === "FieldName" ? "active" : null} />
                  <ArrowDownward className={sortedBy === -1 && sortedColumn === "FieldName" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => toggleSort("MeetingStatusCount")}>
                Total Meeting
                <span className="shorting">
                  <ArrowUpward className={sortedBy === 1 && sortedColumn === "MeetingStatusCount" ? "active" : null} />
                  <ArrowDownward className={sortedBy === -1 && sortedColumn === "MeetingStatusCount" ? "active" : null} />
                </span>
              </TableCell>
              <TableCell onClick={() => toggleSort("MeetingPercentage")}>
                Meeting %
                <span className="shorting">
                  <ArrowUpward className={sortedBy === 1 && sortedColumn === "MeetingPercentage" ? "active" : null} />
                  <ArrowDownward className={sortedBy === -1 && sortedColumn === "MeetingPercentage" ? "active" : null} />
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
            <TableBody>
              {rows?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3} className="text-center">
                    No data available in table
                  </TableCell>
                </TableRow>
              ) : (
                sortRows(sortedColumn).map((row, index) => (
                  <TableRow key={row?._id}>
                    <TableCell>{row?.FieldName}</TableCell>
    
                    <TableCell>
                      {row.Statusdetails.length == 0
                        ? 0
                        : row?.MeetingStatusCount}
                    </TableCell>
                    <TableCell>
                      {row.Statusdetails.length == 0
                        ? 0
                        : row?.MeetingPercentage}
                      %
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {sflag ? (
        <div className="row">
          <Pagination component="div" count={countpage} onChange={handleChangePage} showFirstButton showLastButton />
          <div className="col dataTables_info">
            <p>
              Showing {rlen === 0 ? 0 : (page - 1) * rowsPerPage + 1} to{" "}
              {page * rowsPerPage > rlen ? rlen : page * rowsPerPage} of {rlen} entries (filtered from {flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {rlen === 0 ? 0 : (page - 1) * rowsPerPage + 1} to{" "}
              {page * rowsPerPage > rlen ? rlen : page * rowsPerPage} of {rlen} entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination component="div" count={countpage} onChange={handleChangePage} showFirstButton showLastButton />
          </div>
        </div>
      )}
    </div>
  );
}

