import React from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

toast.configure();

class EditAccountCustomfield extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      StatusAvailable: null,
      ClientID: null,
      UserID: null,
      CUserID: null,
      IsExist: false,
      OldField:null,
      Role:null,
      IsButtonDisabled:false
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    document.title= "Edit Custom Account Field | SalesHive"
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
    this.EditCustomMeetingfield();
  }

  // form validation
  async FromValidation() {
    let FormIsValid = true;
    let Errors = {};
    var FieldName = document.getElementById("Name").value.trim();
    var IsExist = await this.CheckExistAccountClientName(FieldName);
    if (FieldName == "") {
      
          FormIsValid = false;
          Errors["Name"] = "Please enter field name";
        
    }

    if (IsExist == true) {
      FormIsValid = false;
    }
    this.setState({ Errors: Errors });
    return FormIsValid;
  }

    // handle change
    HandleChange(field, e) {
      let Fields = this.state.Fields;
      Fields[field] = e.target.value;
      this.setState({ Fields });
      if (Fields.Name != "" && Fields.Name.indexOf(" ") != 0) {
        this.state.Errors.Name = null;
        this.state.StatusAvailable = null;
        this.CheckExistAccountClientName(e.target.value);
      } else {
        this.state.StatusAvailable = null;
        this.state.Errors.Name = null;
      }
    }

  //check exist Status
  async CheckExistAccountClientName(Name) {
      var str_in = {
        ClientID: this.state.ClientID,
        FieldName: Name,
        Role:this.state.Role
      };
     let resdata = await Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/customaccounntlist/CustomAccountExists",
        method: "POST",
        data: str_in,
      })
       
        if (resdata.data.StatusMessage == "SUCCESS") {
          if (resdata.data.Data.length > 0) {
            if(this.state.OldField == Name){
              this.setState({ IsExist: false });
          return false;

            }else{
              this.setState({ StatusAvailable: resdata.data.Data[0]?.FieldName });
              this.setState({ IsExist: true });
          return true;

            }
          } else {
            this.setState({ IsExist: false });
          return false;

          }
        }
      
  }



 // Edit Function Name
 EditCustomMeetingfield() {
  const id = this.props.location.state;
  this.setState({ id: id });
  var str_in = {
    id: id,
  };
  Axios({
    url:
      CommonConstants.MOL_APIURL +
      "/customaccounntlist/GetCustomAccountFieldWithID",
    method: "POST",
    data: str_in,
  }).then((res) => {
    if (res.data?.StatusMessage == "SUCCESS") {
      document.getElementById("Name").value = res.data?.Data[0].FieldName;
      document.getElementById("hideloding").style.display = "none";
      this.setState({OldField:res.data?.Data[0].FieldName})
    } else {
      toast.error(res.data?.Message);
      document.getElementById("hideloding").style.display = "none";
    }
  });
}

//back
BackBtn() {
  history.push("/customaccountfield");
}

//Update custom field
async SaveBtn(e) {
  // this.state.Errors.Name = null;
  // this.state.StatusAvailable = null;
  e.preventDefault();
  // e.currentTarget.disabled = true;
  this.setState({IsButtonDisabled:true});
  var Final_flag =  await this.FromValidation()
  if (Final_flag == true) {
    var FieldName = document.getElementById("Name").value.trim();
    var data = {
      id: this.state.id,
      FieldName: FieldName,
      LastUpdatedBy: this.state.CUserID,
      LastUpdatedDate: new Date(),
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/customaccounntlist/CustomAccountUpdate",
      method: "POST",
      data: data,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        toast.success(<div className="toastsize" >Custom Account Field Edit<br/>Custom account field updated successfully.</div>);
        history.push("/customaccountfield");
      } else {
        toast.error(res.data.Message);
        this.setState({IsButtonDisabled:false});
      }
    });
  }  else{
    // document.getElementById("submit").disabled = false;
    this.setState({IsButtonDisabled:false});
  }
}

  render() {
    const mainTtile = CommonConstants.Title;
    document.title = "Edit Custom Account Field" + mainTtile;
    return (
      <>
      <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
           
              <div className="row pt-4 pb-3">
                <div className="col px-3">
                  <h4 className="headertitlenop float-left px-1">
                    Edit Custom Account Field
                  </h4>
                </div>
              </div>

            <div class="whiterow">
              <div className="row pt-3 pb-3">
                <div class="col-lg-6 boxsinput_group px-5">
                  <div class="row max-cols">
                    <label class="col-lg-3 view-lable-meet">Field Name</label>
                    <div className="col-lg-7 pl-4">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter name"
                        onBlur={this.HandleChange.bind(this, "Name")}
                        // onChange={(e)=>{this.CheckExistAccountClientName1(e)}}

                        id="Name"
                        name="Name"
                        placeholder="Enter field name"
                        type="text"
                      />
                      {this.state.StatusAvailable && (
                        <span style={{ color: "red" }}>
                          Field name already exist.
                        </span>
                      )}
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Name"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row pb-2 pt-2">
                <div class="col-lg-12 pull-left py-4">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                    disabled={this.state.IsButtonDisabled}
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <button
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </button>
                </div>
              </div>
            
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditAccountCustomfield = connect(
  mapState,
  actionCreators
)(EditAccountCustomfield);
export { connectedEditAccountCustomfield as EditAccountCustomfield };
