const moment = require("moment");

import React from "react";
import Axios from "axios";

import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from '../../../_components/user/footer/footer';
import "react-toastify/dist/ReactToastify.css";

import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { toast } from "react-toastify";
import { TramOutlined } from "@material-ui/icons";

toast.configure();

var atob = require("atob");

class AddEmailAccountsPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      Fields: {},
      Errors: {},
      ErrorValid: {},
      EmailNameAvailable: 0,
      ClientID: Details.ClientID,
      ChildUserID: Details.ChildUserID,
      ParentUserID: Details.ParentUserID,
      Role: Details.Role,
      Typelist: [],
      VariableTypeID: null,
      CustomVariable: {},
      IsExist: false,
      AccountType: [],
      AccountSslType: [],
      AccountaAuthType: [],
      SmtpHost: "smtp.gmail.com",
      ImapHost: "imap.gmail.com",
      SmtpPort: "465",
      ImapPort: "993",
      ThrottlestrVolumne: 15,
      ThrottleEndingVolume: 100,
      EmailDelay: 66,
      DaystillFullThrottle: 20,
      FieldID: 2,
      EmailWarmingReceiving: true,
      EmailWarmingSending: true,
      SendingSchedul: [],
      RefreshToken: null,
      IsDispalyAuthonicate: true,
      IsEmailExist: false,
      IsValid: false,
      IsValidImap: false,
      BtnDisabled: false,
      AliasTitle: "",
      FullName: "",
      Authenticationsuccess: false,
      AuthenticationError: false,
      oldemail: "",
      authtenticatedemail: "",
      readOnly: false,
      AccountTypeID: "",
      ScheduleStartUTCTimeID:""
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.EmailAuthenicate = this.EmailAuthenicate.bind(this);
  }
  componentDidMount() {
    document.title = "Add | SalesHive";
    this.CheckAccountAuthonicate();
    this.AllCommonFieldGet();
    this.SendingSchedulingTimeGet();
            // Conditionally remove items from localStorage based on current location
    const { search } = window.location;
          
    if (search === "") {
        localStorage.removeItem('AliasTitle');
        localStorage.removeItem('FullName');
     }
    const storedValue = localStorage.getItem('AliasTitle');
    if (storedValue) {
      this.setState({ AliasTitle: storedValue });
    }

    const FullNameStored = localStorage.getItem('FullName');
    if (storedValue) {
      this.setState({ FullName: FullNameStored });
    }

  }

  async CheckAccountAuthonicate() {

    var queryparamter = window.location.search.substring(1);
    var CheckKeyword = queryparamter.includes("data=");
    if (queryparamter != "" && CheckKeyword) {
    

      var ResultMessage = atob(queryparamter.split("data=")[1]);
      var pagename = ResultMessage.split("page=")[1].split("message=")[0];
      if (pagename != "addemailaccountpage") {
        history.push(
          "/editemailaccounts?data=" + queryparamter.split("data=")[1]
        );
      } else {
      
        var RefreshToken = ResultMessage.split("page=")[0];
        var Emailfromauth = ResultMessage.split("email=")[1];
        if (RefreshToken != null || RefreshToken != "") {
          this.setState({
            RefreshToken: RefreshToken,
          });
          var tabIdentifier = this.getTabIdentifier();
          var storageKey = "EmailAccountPage_" + tabIdentifier;
          //var localStoragedata = "EmailAccountPage_"+Emailfromauth;
          let EmailData = JSON.parse(localStorage.getItem(storageKey));
          if (EmailData && EmailData != null) {
            
            document.getElementById("SMTPFromEmail").value = EmailData.SMTPFromEmail;
            // document.getElementById("FullName").value = EmailData?.FullName === undefined?"":EmailData?.FullName;
            // document.getElementById("AccountAuthTypeID").value = EmailData?.AccountAuthTypeID === undefined?"":EmailData?.AccountAuthTypeID;
            // document.getElementById("ThrottleStartingVolume").value = EmailData?.ThrottleStartingVolume === undefined?"":EmailData?.ThrottleStartingVolume;
            // document.getElementById("ThrottleEndingVolume").value = EmailData?.ThrottleEndingVolume === undefined?"":EmailData?.ThrottleEndingVolume;
            // document.getElementById("EmailDelay").value = EmailData?.EmailDelay === undefined?"":EmailData?.EmailDelay;
            // document.getElementById("DaysTillFullThrottle").value = EmailData?.DaysTillFullThrottle === undefined?"":EmailData?.DaysTillFullThrottle;
            //document.getElementById("SendingSchedularID").value = EmailData?.SendingSchedularID === undefined?"":EmailData?.SendingSchedularID;
            //document.getElementById("AccountTypeID").value = EmailData?.AccountTypeID === undefined?"":EmailData?.AccountTypeID;
            document.getElementById("AliasTitle").value = EmailData?.AliasTitle === undefined?"":EmailData?.AliasTitle;
            this.setState({
              ScheduleStartUTCTimeID:EmailData?.SendingSchedularID === undefined?"":EmailData?.SendingSchedularID,
              AccountTypeID: EmailData?.AccountTypeID === undefined?"":EmailData?.AccountTypeID,
              FullName:EmailData?.FullName === undefined?"":EmailData?.FullName,
              ThrottlestrVolumne: EmailData?.ThrottleStartingVolume === undefined?"":EmailData?.ThrottleStartingVolume,
              ThrottleEndingVolume: EmailData?.ThrottleEndingVolume === undefined?"":EmailData?.ThrottleEndingVolume,
              EmailDelay: EmailData?.EmailDelay === undefined?"":EmailData?.EmailDelay,
              DaystillFullThrottle: EmailData?.DaysTillFullThrottle === undefined?"":EmailData?.DaysTillFullThrottle,
              FieldID: EmailData?.AccountAuthTypeID === undefined?"":EmailData?.AccountAuthTypeID,
              EmailWarmingReceiving: EmailData?.EmailWarmingReceiving === undefined?true:EmailData?.EmailWarmingReceiving,
              EmailWarmingSending: EmailData?.EmailWarmingSending === undefined?true:EmailData?.EmailWarmingSending})

            function normalizeEmail(email) {
              const [local, domain] = email.split('@');

              const normalizedLocal = local.split('+')[0].replace(/\./g, '');

              return normalizedLocal + '@' + domain;
            }
           // const emailfromstorage = await normalizeEmail(EmailData.SMTPFromEmail)


            if (Emailfromauth == EmailData.SMTPFromEmail) {
              const dataParam = ResultMessage.split("page=")[1].split("message=")[1].split("email=")[0];
              if (dataParam && dataParam == "SUCCESS") {
                this.setState({
                  Authenticationsuccess: true,
                });
                this.setState({
                  readOnly: true,
                });
                
              }
              this.setState({
                IsDispalyAuthonicate: false,
              });
            } else {
              const dataParam = ResultMessage.split("page=")[1].split("message=")[1].split("email=")[0];
              if (dataParam && dataParam == "SUCCESS") {
                this.setState({
                  AuthenticationError: true,

                  RefreshToken: null,

                });
              }

            }
          }
          localStorage.removeItem(storageKey);
          localStorage.removeItem("FullName")
        }
      }
    }

  }

  // start Authenticate email
  EmailAuthenicate() {

    var SMTPFromEmail = document.getElementById("SMTPFromEmail").value;
    let FullName = document.getElementById("FullName").value.trim();
    var AccountAuthTypeID = document.getElementById("AccountAuthTypeID").value;
    var ThrottleStartingVolume = document.getElementById("ThrottleStartingVolume").value.trim();
    var ThrottleEndingVolume = document.getElementById("ThrottleEndingVolume").value.trim();
    var EmailDelay = document.getElementById("EmailDelay").value.trim();
    var DaysTillFullThrottle = document.getElementById("DaysTillFullThrottle").value.trim();
    var SendingSchedularID = document.getElementById("SendingSchedularID").value
    var AccountTypeID = document.getElementById("AccountTypeID").value;
    var AliasTitle = document.getElementById("AliasTitle").value;

    if (SMTPFromEmail != "" && this.state.IsExist===false && this.state.StatusAvailable === null) {
      var ObjLoginData = {
        SMTPFromEmail: SMTPFromEmail,
        FullName:FullName,
        AccountAuthTypeID:AccountAuthTypeID,
        ThrottleStartingVolume:ThrottleStartingVolume,
        ThrottleEndingVolume:ThrottleEndingVolume,
        EmailDelay:EmailDelay,
        DaysTillFullThrottle:DaysTillFullThrottle,
        EmailWarmingSending:this.state.EmailWarmingSending,
        EmailWarmingReceiving:this.state.EmailWarmingReceiving,
        SendingSchedularID:SendingSchedularID,
        AccountTypeID:AccountTypeID,
        AliasTitle:AliasTitle
      };
      if (this.state.IsExist != true) {
        var tabIdentifier = this.getTabIdentifier(); // Get or generate the tab identifier
        var storageKey = "EmailAccountPage_" + tabIdentifier; // Dynamic storage key

        //   let EmailData = JSON.parse(localStorage.getItem("EmailAccountPage_"));
        // var localStoragedata = "EmailAccountPage_" + SMTPFromEmail
        localStorage.setItem(storageKey, JSON.stringify(ObjLoginData));
        var loginHint = SMTPFromEmail;
        var scope = encodeURIComponent(CommonConstants.SCOPE);
        var redirect_uri_encode = encodeURIComponent(
          CommonConstants.REDIRECT_URL
        );
        var client_id = encodeURIComponent(CommonConstants.CLIENT_ID);
        var response_type = "code";
        var access_type = "offline";
        var state = "addemailaccountpage";
        const host = window.location.href;

        var Url =
          "https://accounts.google.com/o/oauth2/auth?scope=" +
          scope +
          "&redirect_uri=" +
          redirect_uri_encode +
          "&response_type=" +
          response_type +
          "&client_id=" +
          client_id +
          "&state=" +
          host + "/" + state +
          "&access_type=" +
          access_type +
          "&approval_prompt=force&login_hint=" +
          loginHint +
          "";
        window.location.href = Url;
      }
    } else {
      if(SMTPFromEmail ==="" && this.state.Errors.SMTPFromEmail === null && this.state.StatusAvailable === null){
      let Errors = {};
      Errors["SMTPFromEmail"] = "Please Enter email";
      this.setState({ Errors: Errors });
      }
      //  toast.error("Please Enter email");
    }
  }

  getTabIdentifier() {
    var currentURL = window.location.href;
    var urlParams = new URLSearchParams(currentURL.split('?')[1]);
    var tabIdentifier = urlParams.get('tab');



    if (!tabIdentifier) {
      tabIdentifier = Math.random().toString(36).substring(7);
      var newURL = new URL(currentURL);
      newURL.searchParams.set('tab', tabIdentifier);
      window.history.replaceState({}, document.title, newURL.href);
    }

    return tabIdentifier;
  }
  // handle change throttle ending volume
  HandleThrottleEndingVolume(e) {
    this.setState({
      ThrottleEndingVolume: e.target.value,
    });
    let ThrottleStartingVolume = document.getElementById("ThrottleStartingVolume").value.trim();
    let ThrottleEndingVolume = document.getElementById("ThrottleEndingVolume").value.trim();
    if (parseInt(ThrottleStartingVolume) > parseInt(ThrottleEndingVolume)) {
      // let Errors={}
      // Errors["ThrottleEndingVolume"] = "Throttle ending volume must be greater than throttle starting volume.";
      // this.setState({ Errors: Errors });
      this.setState({
        Errors: {
          ...this.state.Errors,
          ThrottleEndingVolume: "Throttle ending volume must be greater than throttle starting volume."
        }
      })

    }else{
      this.setState({
        Errors: {
          ...this.state.Errors,
          ThrottleEndingVolume: ""
        }
      })
    }

    if (parseInt(ThrottleStartingVolume) == parseInt(ThrottleEndingVolume) && parseInt(ThrottleStartingVolume) !=0 && parseInt(ThrottleEndingVolume) !=0) {
      // let Errors={}
      // Errors["ThrottleEndingVolume"] = "";
      // this.setState({ Errors: Errors });
      this.setState({
        Errors: {
          ...this.state.Errors,
          ThrottleEndingVolume: ""
        }
      })

    }else if (parseInt(ThrottleEndingVolume) == 0) {
      this.setState({
       Errors: {
         ...this.state.Errors,
         ThrottleEndingVolume: "Please enter a value greater than or equal to 1."
       }
     })
  } else if (ThrottleEndingVolume == "") {
   this.setState({
     Errors: {
       ...this.state.Errors,
       ThrottleEndingVolume: "Please enter throttle ending volume"
     }
   })

  } else if (parseInt(ThrottleEndingVolume) > 1000) {
      // let Errors={}
      // Errors["ThrottleStartingVolume"] = "Please enter value less than or equal to 1000.";
      // this.setState({ Errors: Errors });
      this.setState({
        Errors: {
          ...this.state.Errors,
          ThrottleEndingVolume: "Please enter value less than or equal to 1000."
        }
      })

    }
  }
  // handle change smtphost
  HandleChangeSmtpHost(e) {
    this.setState({
      SmtpHost: e.target.value,
    });
  }

  // handle change imaphost
  HandleChangeImapHost(e) {
    this.setState({
      ImapHost: e.target.value,
    });
  }

  // handle change smtp port
  HandleChangeSmptPort(e) {
    this.setState({
      SmtpPort: e.target.value,
    });
  }

  // handle change imap port
  HandleChangeImapPort(e) {
    this.setState({
      ImapPort: e.target.value,
    });
  }

  // handle change throttleste volume
  HandleChangeThrottlestrVolume(e) {
    this.setState({
      ThrottlestrVolumne: e.target.value,
    });
    let ThrottleStartingVolume = document.getElementById("ThrottleStartingVolume").value.trim();
    let ThrottleEndingVolume = document.getElementById("ThrottleEndingVolume").value.trim();


    if (parseInt(ThrottleStartingVolume) > parseInt(ThrottleEndingVolume)) {
      // let Errors={}
      // Errors["ThrottleStartingVolume"] = "Throttle starting volume must be less than throttle ending volume.";
      // this.setState({ Errors: Errors });
      this.setState({
        Errors: {
          ...this.state.Errors,
          ThrottleStartingVolume: "Throttle starting volume must be less than throttle ending volume."
        }
      })

    }else{
      this.setState({
        Errors: {
          ...this.state.Errors,
          ThrottleStartingVolume: ""
        }
      })
    }

    if (parseInt(ThrottleStartingVolume) == parseInt(ThrottleEndingVolume && parseInt(ThrottleStartingVolume) !=0 && parseInt(ThrottleEndingVolume) !=0) ) {
      // let Errors={}
      // Errors["ThrottleStartingVolume"] = "";
      // this.setState({ Errors: Errors });
      this.setState({
        Errors: {
          ...this.state.Errors,
          ThrottleStartingVolume: ""
        }
      })

    }else if (parseInt(ThrottleStartingVolume) == 0) {
         this.setState({
          Errors: {
            ...this.state.Errors,
            ThrottleStartingVolume: "Please enter a value greater than or equal to 1."
          }
        })
     }else if (ThrottleStartingVolume == "") {
      this.setState({
        Errors: {
          ...this.state.Errors,
          ThrottleStartingVolume: "Please enter throttle starting volume"
        }
      })

     } else if (parseInt(ThrottleStartingVolume) > 200) {
      // let Errors={}
      // Errors["ThrottleStartingVolume"] = "Please enter value less than or equal to 200.";
      // this.setState({ Errors: Errors });
      this.setState({
        Errors: {
          ...this.state.Errors,
          ThrottleStartingVolume: "Please enter value less than or equal to 200."
        }
      })

    }

  }

  // handle change email delay
  HandleChangeEmailDelay(e) {
    this.setState({
      EmailDelay: e.target.value,
    });
    var EmailDelay = document.getElementById("EmailDelay").value.trim();

    if (EmailDelay == "") {
      this.setState({
        Errors: {
          ...this.state.Errors,
          EmailDelay: "Please enter email delay"
        }
      })
    }

    if (parseInt(EmailDelay) == 0) {
      this.setState({
        Errors: {
          ...this.state.Errors,
          EmailDelay: "Please enter a value greater than or equal to 1."
        }
      })
    }

    if (parseInt(EmailDelay) > 0) {
      this.setState({
        Errors: {
          ...this.state.Errors,
          EmailDelay: ""
        }
      })
    }
  }

  // handle change still full throttle
  HandleChangeDayStillFullThrottle(e) {
    this.setState({
      DaystillFullThrottle: e.target.value,
    });

    var DaysTillFullThrottle = document.getElementById("DaysTillFullThrottle").value.trim()

    if (DaysTillFullThrottle == "") {
      this.setState({
        Errors: {
          ...this.state.Errors,
          DaysTillFullThrottle: "Please enter days till full throttle"
        }
      })
    }

    if (parseInt(DaysTillFullThrottle) == 0) {
      this.setState({
        Errors: {
          ...this.state.Errors,
          DaysTillFullThrottle: "Please enter a value greater than or equal to 1."
        }
      })
    }

    if (parseInt(DaysTillFullThrottle) > 0) {
      this.setState({
        Errors: {
          ...this.state.Errors,
          DaysTillFullThrottle: ""
        }
      })
    }
    
  }

  HandleChangeAccountTypeID(e){
    this.setState({
      AccountTypeID: e.target.value,
    });
  }
  HandleChangeSendingSchedule(e){
    this.setState({
      ScheduleStartUTCTimeID: e.target.value,
    })
  }

  // handle change field
  HandledropdownChangeInput(e) {
    this.setState({
      FieldID: e.target.value,
    });
    if (e.target.value == 2) {
      this.setState({
        IsDispalyAuthonicate: true,
      });
      this.setState({
        EmailWarmingReceiving: true,
      });
      this.setState({
        EmailWarmingSending: true,
      });
    } else {
      this.setState({
        IsDispalyAuthonicate: false,
      });
      this.setState({
        EmailWarmingReceiving: false,
      });
      this.setState({
        EmailWarmingSending: false,
      });
    }
  }

  // commonfield get
  AllCommonFieldGet() {
    var CommonDataAccount = {
      IsDeleted: false,
      Role: this.state.Role
    };
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/emailaccount/AccountTypeGetAll",
      method: "POST",
      data: CommonDataAccount,
    });
    rows.then((result) => {
      this.setState({
        AccountType: result.data.Accounttype,
        AccountSslType: result.data.AccountSsltype,
        AccountaAuthType: result.data.Accountauthtype,
      });
    });
  }

  // sending scheduling time get
  async SendingSchedulingTimeGet() {
    var SendingSchedulTimeData = {
      ClientID: this.state.ClientID,
      Role: this.state.Role

    };
    const rows = await Axios({
      url: CommonConstants.MOL_APIURL + "/emailaccount/SendingschedulingGet",
      method: "POST",
      data: SendingSchedulTimeData,
    });
    this.setState({
      SendingSchedul: rows?.data?.PageData,
    });
  }

  // check box fun
  HandleChangeCheckEmailSend = (e) => {
    const { checked } = e.target;
    this.setState({
      EmailWarmingSending: checked,
    });
  };
  // email receive
  HandleChangeCheckEmailReceiv = (e) => {
    const { checked } = e.target;
    this.setState({
      EmailWarmingReceiving: checked,
    });
  };

  // // form validation
  // fromValidation() {
  //   let formIsValid = true;
  //   let Fileds = this.state.Fileds;
  //   let Errors = {};
  //   let Email = document.getElementById('SMTPFromEmail').value;

  //   if (Email.length <= 0) {
  //     formIsValid = false;
  //     Errors["SMTPFromEmail"] = "Please Enter SMTPFromEmail";
  //   }

  //   // if (this.state.EmailNameAvailable != null) {
  //   //   formIsValid = false;
  //   // }
  //   if (this.state.IsEmailExist == true) {
  //     formIsValid = false;
  //     Errors["SMTPFromEmail"] = "Email address is already exits!";
  //     // this.setState({IsEmailExist:true})
  //   }

  //   this.setState({ Errors: Errors });
  //   return formIsValid;
  // }

  async FormValidation() {
    let formIsValid = true;
    let Errors = {};
    this.setState({ IsValid: false })
    this.setState({ IsValidImap: false })

    let Email = document.getElementById("SMTPFromEmail").value.trim();
    let FullName = document.getElementById("FullName").value.trim();
    var AccountAuthTypeID = document.getElementById("AccountAuthTypeID").value;
    let IsExist = await this.CheckExistEmailAccountsName(Email);
    var ThrottleStartingVolume = document.getElementById("ThrottleStartingVolume").value.trim();
    var ThrottleEndingVolume = document.getElementById("ThrottleEndingVolume").value.trim();
    var EmailDelay = document.getElementById("EmailDelay").value.trim();
    var DaysTillFullThrottle = document.getElementById("DaysTillFullThrottle").value.trim();

    if (ThrottleStartingVolume == "") {
      formIsValid = false;
      Errors["ThrottleStartingVolume"] = "Please enter throttle starting volume";
    }

    if (ThrottleEndingVolume == "") {
      formIsValid = false;
      Errors["ThrottleEndingVolume"] = "Please enter throttle ending volume";
    }

    if (EmailDelay == "") {
      formIsValid = false;
      Errors["EmailDelay"] = "Please enter email delay";
    }

    if (DaysTillFullThrottle == "") {
      formIsValid = false;
      Errors["DaysTillFullThrottle"] = "Please enter days till full throttle";
    }

    if (parseInt(EmailDelay) == 0) {
      formIsValid = false;
      Errors["EmailDelay"] = "Please enter a value greater than or equal to 1.";
    }

    if (parseInt(DaysTillFullThrottle) == 0) {
      formIsValid = false;
      Errors["DaysTillFullThrottle"] = "Please enter a value greater than or equal to 1.";
    }

    if (Email == "") {
      formIsValid = false;
      Errors["SMTPFromEmail"] = "Please enter from email";
    }

    if (FullName == "") {
      formIsValid = false;
      Errors["FullName"] = "Please enter full name";
    }
    if (this.state.IsValid == true) {
      formIsValid = false;
      Errors["SMTPFromEmail"] = "Invalid from email.";
    }

    if (IsExist == true) {
      formIsValid = false;
    }
    if (AccountAuthTypeID == 1) {
      var SMTPHost = document.getElementById("SMTPHost").value.trim();
      var IMAPHost = document.getElementById("IMAPHost").value.trim();
      var SMTPPort = document.getElementById("SMTPPort").value.trim();
      var IMAPPort = document.getElementById("IMAPPort").value.trim();
      var SMTPSSLID = document.getElementById("SMTPSSLID").value;
      var IMAPSSLID = document.getElementById("IMAPSSLID").value;
      let IMAPEmail = document.getElementById("IMAPEmail").value.trim();

      let IsExists = await this.MeetingImapCheckExist(IMAPEmail);

      if (SMTPHost == "") {
        formIsValid = false;
        Errors["SMTPHost"] = "Please enter from SMTPHost";
      }

      if (IMAPHost == "") {
        formIsValid = false;
        Errors["IMAPHost"] = "Please enter from IMAPHost";
      }

      if (SMTPPort == "") {
        formIsValid = false;
        Errors["SMTPPort"] = "Please enter from SMTPPort";
      }

      if (IMAPPort == "") {
        formIsValid = false;
        Errors["IMAPPort"] = "Please enter from IMAPPort";
      }

      if (IsExists == true) {
        formIsValid = false;
      }
      if (this.state.IsValidImap == true) {
        formIsValid = false;
        Errors["IMAPEmail"] = "Invalid imap email.";
      }

      if (SMTPSSLID == "") {
        formIsValid = false;
        Errors["SMTPSSLID"] = "Please enter from SMTPSSLID";
      }

      if (IMAPSSLID == "") {
        formIsValid = false;
        Errors["IMAPSSLID"] = "Please enter from IMAPSSLID";
      }
    }
    if (parseInt(ThrottleStartingVolume) > parseInt(ThrottleEndingVolume)) {
      // if (formIsValid) {
        formIsValid = false;
        Errors["ThrottleStartingVolume"] = "Throttle starting volume must be less than throttle ending volume.";
        Errors["ThrottleEndingVolume"] = "Throttle ending volume must be greater than throttle starting volume.";
     // }
    }
    if (parseInt(ThrottleStartingVolume) == parseInt(ThrottleEndingVolume) && parseInt(ThrottleStartingVolume) !=0 && parseInt(ThrottleEndingVolume) !=0) {
     // if (formIsValid) {
        // formIsValid = false;
        Errors["ThrottleStartingVolume"] = "";
        Errors["ThrottleEndingVolume"] = "";
    //  }
    }

    if (parseInt(ThrottleStartingVolume) == 0) {
      // if (formIsValid) {
         formIsValid = false;
         Errors["ThrottleStartingVolume"] = "Please enter a value greater than or equal to 1.";
     //  }
     }

     if (parseInt(ThrottleEndingVolume) == 0) {
      // if (formIsValid) {
         formIsValid = false;
         Errors["ThrottleEndingVolume"] = "Please enter a value greater than or equal to 1.";
     //  }
     }

     if (ThrottleStartingVolume == "") {
      // if (formIsValid) {
         formIsValid = false;
         Errors["ThrottleStartingVolume"] = "Please enter throttle starting volume";
     //  }
     }

     if (ThrottleEndingVolume == "") {
      // if (formIsValid) {
         formIsValid = false;
         Errors["ThrottleEndingVolume"] = "Please enter throttle ending volume";
     //  }
     }

    if (parseInt(ThrottleStartingVolume) > 200) {
      formIsValid = false;
      Errors["ThrottleStartingVolume"] = "Please enter value less than or equal to 200.";
    }

    if (parseInt(ThrottleEndingVolume) > 1000) {
      formIsValid = false;
      Errors["ThrottleEndingVolume"] = "Please enter value less than or equal to 1000.";
    }
    this.setState({ Errors: Errors });

    return formIsValid;
  }

  // HandleChange
  HandleChange(Field, e) {
    let Fields = this.state.Fields;
    Fields[Field] = e.target.value;
    this.setState({ Fields });

    if (Fields.SMTPFromEmail != "") {
      // this.state.Errors.SMTPFromEmail = null;
      // this.state.StatusAvailable = null;
      this.CheckExistEmailAccountsName(e.target.value);
    } else {
      this.state.StatusAvailable = null;
      let Errors = {};
      Errors["SMTPFromEmail"] = "Please Enter email";
      this.setState({ Errors: Errors })
    }
  }

  // check exit name
  async CheckExistEmailAccountsName(FromEmail) {
    
    let regexp =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    var str_in = {
      ClientID: this.state.ClientID,
      SMTPFromEmail: FromEmail,
      ParentUserID:this.state.ParentUserID,
      Role: this.state.Role

    };
    let resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/emailaccount/EmailAccountExists",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        let Errors = {};
        Errors["SMTPFromEmail"] = null;
        this.setState({ Errors: Errors });
        this.setState({ StatusAvailable: resdata.data.Data[0]?.SMTPFromEmail });
        this.setState({ IsExist: true });
        return true;
      } else {
        this.state.StatusAvailable = null;
        let Errors = {};
        if (!regexp.test(FromEmail) && FromEmail != "") {
          Errors["SMTPFromEmail"] = "Invalid from email.";
          this.setState({ Errors: Errors });
          this.setState({ IsValid: true });
          this.setState({ IsExist: true });
          return true;
        } else {
          Errors["SMTPFromEmail"] = null;
          this.setState({ Errors: Errors });
          this.setState({ IsExist: false });
          return false;
        }
      }
    }
  }

  // HandleChange
  HandleImapChange(Field, e) {
    let Fields = this.state.Fields;
    Fields[Field] = e.target.value;
    this.setState({ Fields });

    if (Fields.IMAPEmail != "") {
      this.state.Errors.IMAPEmail = null;
      // this.state.StatusAvailable = null;
      this.MeetingImapCheckExist(e.target.value);
    } else {
      // this.state.StatusAvailable = null;
      this.state.Errors.IMAPEmail = null;
      // let Errors = {};
      // Errors["SMTPFromEmail"] = "Please enter from email";
      // this.setState({ Errors: Errors })
    }
  }


  async MeetingImapCheckExist(Email) {
    let regexp =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let Errors = {};
    if (!regexp.test(Email) && Email != "") {
      Errors["IMAPEmail"] = "Invalid imap email.";
      this.setState({ Errors: Errors });
      this.setState({ IsValidImap: true })
      return true;
    } else {
      // this.setState({ IsExist: false });
      return false;
    }
  }

  // back button
  BackBtn() {
    localStorage.removeItem('AliasTitle');
    localStorage.removeItem('FullName');
    history.push("/EmailAccounts");
  }
  // save button data
  async SaveBtn(e) {
    e.preventDefault();
    this.setState({ BtnDisabled: true })
    var Final_flag = await this.FormValidation();

    if (Final_flag == true) {
      var AccountAuthTypeID =
        document.getElementById("AccountAuthTypeID").value;

      if (AccountAuthTypeID == 2) {
        var SendingSchedularID =
          document.getElementById("SendingSchedularID").value;
        var SMTPFromEmail = document.getElementById("SMTPFromEmail").value;
        var AliasTitle = document.getElementById("AliasTitle").value;
        var Name = document.getElementById("FullName").value;
        var ThrottleStartingVolume = document.getElementById(
          "ThrottleStartingVolume"
        ).value;
        var ThrottleEndingVolume = document.getElementById(
          "ThrottleEndingVolume"
        ).value;
        var EmailDelay = document.getElementById("EmailDelay").value;
        var AccountTypeID = document.getElementById("AccountTypeID").value;
        var DaysTillFullThrottle = document.getElementById(
          "DaysTillFullThrottle"
        ).value;

        // var SMTPEmail = document.getElementById("SMTPEmail").value;
        // var IMAPEmail = document.getElementById("IMAPEmail").value;
        // var SMTPPassword = document.getElementById("SMTPPassword").value;
        // var IMAPPassword = document.getElementById("IMAPPassword").value;
        // var SMTPHost = document.getElementById("SMTPHost").value;
        // var IMAPHost = document.getElementById("IMAPHost").value;
        // var SMTPPort = document.getElementById("SMTPPort").value;
        // var IMAPPort = document.getElementById("IMAPPort").value;

        // var SMTPSSLID = document.getElementById("SMTPSSLID").value;
        // var IMAPSSLID = document.getElementById("IMAPSSLID").value;
        var data = {
          ClientID: this.state.ClientID,
          UserID: this.state.ChildUserID,
          ParentUserID: this.state.ParentUserID,
          SMTPFromEmail: SMTPFromEmail,
          AliasTitle: AliasTitle,
          Name: Name,
          AccountAuthTypeID: AccountAuthTypeID,
          SMTPEmail: "",
          IMAPEmail: "",
          SMTPPassword: "",
          IMAPPassword: "",
          SMTPHost: this.state.SmtpHost,
          IMAPHost: this.state.ImapHost,
          SMTPPort: this.state.SmtpPort,
          IMAPPort: this.state.ImapPort,
          CampaignMailSentToday: ThrottleStartingVolume,
          ThrottleStartingVolume: ThrottleStartingVolume,
          ThrottleEndingVolume: ThrottleEndingVolume,
          EmailDelay: EmailDelay,
          DaysTillFullThrottle: DaysTillFullThrottle,
          SMTPSSLID: "2",
          IMAPSSLID: "2",
          SendingSchedularID: SendingSchedularID,
          AccountTypeID: AccountTypeID,
          IsDeleted: false,
          IsEmailWarmingSending: this.state.EmailWarmingSending,
          IsEmailWarmingReceiving: this.state.EmailWarmingReceiving,
          CreatedDt: new Date(),
          CreatedBy: this.state.ChildUserID,
          RefreshToken: this.state.RefreshToken,
          IsAuthenticated: this.state.IsDispalyAuthonicate == true ? false : true,
          IsWorking: true,
          Role: this.state.Role

        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/emailaccount/EmailAccountsAdd",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            localStorage.removeItem('AliasTitle');
            localStorage.removeItem('FullName');
            toast.success(<div className="toastsize">Account Add <br />Account added successfully.</div>);

            history.push("/EmailAccounts");
          } else {
            toast.error(res.data.Message);
            this.setState({ BtnDisabled: false })
          }
        });
      } else if (AccountAuthTypeID == 1) {
        var SMTPFromEmail = document.getElementById("SMTPFromEmail").value;
        var AliasTitle = document.getElementById("AliasTitle").value;
        var Name = document.getElementById("FullName").value;
        var ThrottleStartingVolume = document.getElementById(
          "ThrottleStartingVolume"
        ).value;
        var ThrottleEndingVolume = document.getElementById(
          "ThrottleEndingVolume"
        ).value;
        var EmailDelay = document.getElementById("EmailDelay").value;
        var DaysTillFullThrottle = document.getElementById(
          "DaysTillFullThrottle"
        ).value;
        // var SMTPSSLID = document.getElementById("SMTPSSLID").value;
        // var IMAPSSLID = document.getElementById("IMAPSSLID").value;
        var SendingSchedularID = document.getElementById("SendingSchedularID").value;
        var AccountTypeID = document.getElementById("AccountTypeID").value;
        var SMTPEmail = document.getElementById("SMTPEmail").value;
        var IMAPEmail = document.getElementById("IMAPEmail").value;
        var SMTPPassword = document.getElementById("SMTPPassword").value;
        var IMAPPassword = document.getElementById("IMAPPassword").value;
        var SMTPSSLID = document.getElementById("SMTPSSLID").value;
        var IMAPSSLID = document.getElementById("IMAPSSLID").value;

        var data = {
          ClientID: this.state.ClientID,
          UserID: this.state.ChildUserID,
          ParentUserID: this.state.ParentUserID,
          SMTPFromEmail: SMTPFromEmail,
          AliasTitle: AliasTitle,
          Name: Name,
          AccountAuthTypeID: AccountAuthTypeID,
          SMTPEmail: SMTPEmail,
          IMAPEmail: IMAPEmail,
          SMTPPassword: SMTPPassword,
          IMAPPassword: IMAPPassword,
          SMTPHost: "smtp.gmail.com",
          IMAPHost: "imap.gmail.com",
          SMTPPort: 465,
          IMAPPort: 993,
          ThrottleStartingVolume: ThrottleStartingVolume,
          CampaignMailSentToday: ThrottleStartingVolume,
          ThrottleEndingVolume: ThrottleEndingVolume,
          EmailDelay: EmailDelay,
          DaysTillFullThrottle: DaysTillFullThrottle,
          SMTPSSLID: SMTPSSLID,
          IMAPSSLID: IMAPSSLID,
          SendingSchedularID: SendingSchedularID,
          AccountTypeID: AccountTypeID,
          IsDeleted: false,
          IsEmailWarmingSending: this.state.EmailWarmingSending,
          IsEmailWarmingReceiving: this.state.EmailWarmingReceiving,
          CreatedDt: new Date(),
          CreatedBy: this.state.ChildUserID,
          RefreshToken: this.state.RefreshToken,
          IsAuthenticated: false,
          IsWorking: false,
          Role: this.state.Role

        };

        Axios({
          url: CommonConstants.MOL_APIURL + "/emailaccount/EmailAccountsAdd",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            localStorage.removeItem('AliasTitle');
            localStorage.removeItem('FullName');
            toast.success(<div className="toastsize">Account Add <br />Account added successfully.</div>);

            history.push("/EmailAccounts");
          } else {
            toast.error(res.data.Message);
            this.setState({ BtnDisabled: false })
          }
        });
      }
    } else {
      this.setState({ BtnDisabled: false })

    }
  }

  // Update the value and store it in local storage
  handleChangeAliasTitle = (event) => {
    const value = event.target.value;
    this.setState({ AliasTitle: value })
    localStorage.setItem('AliasTitle', value);
  };

  handleChangeFullName = (event) => {
    const value = event.target.value;
    this.setState({ FullName: value })
    if (value.length > 0) {
      let Errors = {}
      Errors["FullName"] = "";
      this.setState({ Errors: Errors });
    }
    if (value.length == 0) {
      let Errors = {}

      Errors["FullName"] = "Please enter full name";
      this.setState({ Errors: Errors });
    }
    localStorage.setItem('FullName', value);
  };
  //   handlemessgae
  handleclearmessages = async () => {
    ;
    this.setState({
      Authenticationsuccess: false,
      AuthenticationError: false
    });
    const { pathname, search } = location;
    const urlParams = new URLSearchParams(search);
    urlParams.delete("data");

    history.push({
      pathname: pathname,
      search: urlParams.toString(),
    });
  };

  render() {
    return (
      <>
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="row pt-4 pb-3">
              <div className="col">
                <h4 className="headertitlenop float-left px-1">
                  Add
                </h4>
              </div>
            </div>

            <div class="whiterow pb-3">
              <div className="row borderbtnm py-4 px-4">
                <div className="col px-3">
                  <h4 className="headertitlenop float-left px-1 mb-0">
                    Email Configuration
                  </h4>
                </div>
              </div>
              <div className="pt-4 px-4">
                {this.state.Authenticationsuccess ? (
                  <div
                    style={{
                      backgroundColor: "#fff",

                      color: "#34bfa3",
                      fontWeight: 400,
                      padding: "0.85rem 1.5rem",
                      borderRadius: "4px",
                      position: "relative",
                      marginBottom: "1rem",
                      border: "1px solid transparent",
                      borderColor: "#34bfa3",
                    }}
                  >
                    {" "}
                    <strong> Well Done! </strong>Authentication of your account is done.{" "}
                    <button
                      type="button"
                      style={{
                        float: "right",
                        fontSize: "1.5rem",
                        fontWeight: 700,
                        lineHeight: 1,
                        color: "#34bfa3",
                        textShadow: "0 1px 0 #fff",
                        opacity: 0.5,
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      data-dismiss="alert"
                      aria-label="Close"
                      onClick={this.handleclearmessages}
                    >
                      x
                    </button>
                  </div>
                ) : null}
                {this.state.AuthenticationError ? (
                  <div
                    style={{
                      backgroundColor: "#fff",

                      color: "#f70f0f",
                      fontWeight: 400,
                      padding: "0.85rem 1.5rem",
                      borderRadius: "4px",
                      position: "relative",
                      marginBottom: "1rem",
                      border: "1px solid transparent",
                      borderColor: "#f70f0f",
                    }}
                  >
                    {" "}
                    <strong> Failed! </strong>Please authenticate with similar email account.{" "}
                    <button
                      type="button"
                      style={{
                        float: "right",
                        fontSize: "1.5rem",
                        fontWeight: 700,
                        lineHeight: 1,
                        color: "#f70f0f",
                        textShadow: "0 1px 0 #fff",
                        opacity: 0.5,
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      data-dismiss="alert"
                      aria-label="Close"
                      onClick={this.handleclearmessages}
                    >
                      x
                    </button>
                  </div>
                ) : null}
                <div className="row px-2">
                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">From Email </label>
                      <div class="col-lg-8">
                        <input
                          class="form-control m-input"
                          placeholder=" "
                          type="email"
                          id="SMTPFromEmail"
                          onChange={this.HandleChange.bind(this, "SMTPFromEmail")}
                          onBlur={this.HandleChange.bind(this, "SMTPFromEmail")}
                          readOnly={this.state.readOnly}
                        />
                        <span style={{ color: "red" }}>
                          <small>
                            {this.state.Errors["SMTPFromEmail"]}
                          </small>
                        </span>
                        {this.state.StatusAvailable && (
                          <span style={{ color: "red" }}>
                            <small>
                              From email already exist.
                            </small>
                          </span>
                        )}
                        {/* <span
                        id="email_invalid"
                        style={{ color: "red", display: "none" }}
                      >
                        Invalid Email.
                      </span>
                      <span
                        id="email_name"
                        style={{ color: "red", display: "none" }}
                      >
                        Please enter Email.
                      </span>

                      {this.state.EmailNameAvailable && (
                        <span style={{ color: "red" }}>
                          Email already exist.
                        </span>
                      )} */}
                      </div>
                    </div>
                  </div>
                  {this.state.IsDispalyAuthonicate == true ? (
                    <>
                      <div
                        class="col-lg-6 boxsinput_group"
                        id="authenticate_btn"
                        onClick={this.EmailAuthenicate}
                      >
                        <div class="row max-cols">
                          <a class="btn btn-primary btnColor">
                            <span>Authenticate</span>
                          </a>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div class="col-lg-6 boxsinput_group">
                        <div class="row max-cols"></div>
                      </div>
                    </>
                  )}

                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">Alias Title </label>
                      <div class="col-lg-8">
                        <input
                          class="form-control m-input"
                          placeholder=""
                          type="text"
                          id="AliasTitle"
                          value={this.state.AliasTitle}
                          onChange={this.handleChangeAliasTitle}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 boxsinput_group"> </div>

                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">Full Name </label>
                      <div class="col-lg-8">
                        <input
                          class="form-control m-input"
                          placeholder=" "
                          type="text"
                          id="FullName"
                          value={this.state.FullName}
                          onChange={this.handleChangeFullName}
                        />
                        <span style={{ color: "red" }}>
                          <small>
                            {this.state.Errors["FullName"]}
                          </small>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">
                        Account Auth Type{" "}
                      </label>
                      <div class="col-lg-8">
                        <select
                          class="form-control  m-input"
                          data-val="true"
                          data-val-number="The field MeetingStatusID must be a number."
                          id="AccountAuthTypeID"
                          name="AccountAuthTypeID"
                          value={this.state.FieldID}
                          onChange={this.HandledropdownChangeInput.bind(this)}
                        >
                          {this.state.AccountaAuthType.map((value) => (
                            <option value={value.FieldID}>
                              {value.FieldName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {this.state.FieldID == 1 ? (
                    <>
                      {" "}
                      <div class="col-lg-6 boxsinput_group" id="smtp_email">
                        <div class="row max-cols">
                          <label class="col-lg-4 view-lable-meet">
                            SMTP Email
                          </label>
                          <div class="col-lg-8">
                            <input
                              class="form-control m-input"
                              placeholder=" "
                              type="text"
                              id="SMTPEmail"
                            />
                            <span style={{ color: "red" }}>
                              <small>
                                {this.state.Errors["SMTPEmail"]}
                              </small>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 boxsinput_group" id="imap_email">
                        <div class="row max-cols">
                          <label class="col-lg-4 view-lable-meet">
                            IMAP Email
                          </label>
                          <div class="col-lg-8">
                            <input
                              class="form-control m-input"
                              placeholder=" "
                              type="text"
                              id="IMAPEmail"
                              onBlur={this.HandleImapChange.bind(this, "IMAPEmail")}
                            />
                            <span style={{ color: "red" }}>
                              <small>
                                {this.state.Errors["IMAPEmail"]}
                              </small>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 boxsinput_group" id="smtp_password">
                        <div class="row max-cols">
                          <label class="col-lg-4 view-lable-meet">
                            SMTP Password
                          </label>
                          <div class="col-lg-8">
                            <input
                              class="form-control m-input"
                              placeholder=" "
                              type="password"
                              id="SMTPPassword"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 boxsinput_group" id="imap_password">
                        <div class="row max-cols">
                          <label class="col-lg-4 view-lable-meet">
                            IMAP Password
                          </label>
                          <div class="col-lg-8">
                            <input
                              class="form-control m-input"
                              placeholder=" "
                              type="password"
                              id="IMAPPassword"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 boxsinput_group" id="smtp_host">
                        <div class="row max-cols">
                          <label class="col-lg-4 view-lable-meet">
                            SMTP Host
                          </label>
                          <div class="col-lg-8">
                            <input
                              class="form-control m-input"
                              placeholder=" "
                              type="text"
                              id="SMTPHost"
                              value={this.state.SmtpHost}
                              onChange={this.HandleChangeSmtpHost.bind(this)}
                            />
                            <span style={{ color: "red" }}>
                              <small>
                                {this.state.Errors["SMTPHost"]}
                              </small>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 boxsinput_group" id="imap_host">
                        <div class="row max-cols">
                          <label class="col-lg-4 view-lable-meet">
                            IMAP Host
                          </label>
                          <div class="col-lg-8">
                            <input
                              class="form-control m-input"
                              placeholder=" "
                              type="text"
                              id="IMAPHost"
                              value={this.state.ImapHost}
                              onChange={this.HandleChangeImapHost.bind(this)}
                            />
                            <span style={{ color: "red" }}>
                              <small>
                                {this.state.Errors["IMAPHost"]}
                              </small>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 boxsinput_group" id="smtp_port">
                        <div class="row max-cols">
                          <label class="col-lg-4 view-lable-meet">
                            SMTP Port
                          </label>
                          <div class="col-lg-8">
                            <input
                              class="form-control m-input"
                              placeholder=" "
                              type="text"
                              id="SMTPPort"
                              value={this.state.SmtpPort}
                              onChange={this.HandleChangeSmptPort.bind(this)}
                            />
                            <span style={{ color: "red" }}>
                              <small>
                                {this.state.Errors["SMTPPort"]}
                              </small>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 boxsinput_group" id="imap_port">
                        <div class="row max-cols">
                          <label class="col-lg-4 view-lable-meet">
                            IMAP Port
                          </label>
                          <div class="col-lg-8">
                            <input
                              class="form-control m-input"
                              placeholder=" "
                              type="text"
                              id="IMAPPort"
                              value={this.state.ImapPort}
                              onChange={this.HandleChangeImapPort.bind(this)}
                            />
                            <span style={{ color: "red" }}>
                              <small>
                                {this.state.Errors["IMAPPort"]}
                              </small>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 boxsinput_group">
                        <div class="row max-cols">
                          <label class="col-lg-4 view-lable-meet">
                            SMTP Security{" "}
                          </label>
                          <div class="col-lg-8">
                            <select
                              class="form-control  m-input"
                              data-val="true"
                              data-val-number="The field SMTPSSLID must be a number."
                              id="SMTPSSLID"
                            >
                              <option value="">--Select--</option>

                              {this.state.AccountSslType.map((value) => (
                                <option value={value.FieldID}>
                                  {value.FieldName}
                                </option>
                              ))}
                            </select>
                            <span style={{ color: "red" }}>
                              <small>
                                {this.state.Errors["SMTPSSLID"]}
                              </small>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 boxsinput_group">
                        <div class="row max-cols">
                          <label class="col-lg-4 view-lable-meet">
                            IMAP Security
                          </label>
                          <div class="col-lg-8">
                            <select
                              class="form-control  m-input"
                              data-val="true"
                              data-val-number="The field IMAPSSLID must be a number."
                              id="IMAPSSLID"
                            >
                              <option value="">--Select--</option>

                              {this.state.AccountSslType.map((value) => (
                                <option value={value.FieldID}>
                                  {value.FieldName}
                                </option>
                              ))}
                            </select>
                            <span style={{ color: "red" }}>
                              <small>
                                {this.state.Errors["IMAPSSLID"]}
                              </small>
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">
                        Throttle Starting Volume{" "}
                      </label>
                      <div class="col-lg-8">
                        <input
                          class="form-control m-input"
                          placeholder=""
                          type="number"
                          id="ThrottleStartingVolume"
                          value={this.state.ThrottlestrVolumne}
                          onChange={this.HandleChangeThrottlestrVolume.bind(this)}
                          min="1"
                        />

                        <span style={{ color: "red" }}>
                          <small>
                            {this.state.Errors["ThrottleStartingVolume"]}
                          </small>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">
                        Throttle Ending Volume
                      </label>
                      <div class="col-lg-8">
                        <input
                          class="form-control m-input"
                          placeholder=""
                          type="number"
                          id="ThrottleEndingVolume"
                          value={this.state.ThrottleEndingVolume}
                          onChange={this.HandleThrottleEndingVolume.bind(this)}
                          min="1"
                        />
                        <span style={{ color: "red" }}>
                          <small>
                            {this.state.Errors["ThrottleEndingVolume"]}
                          </small>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">Email Delay </label>
                      <div class="col-lg-8">
                        <input
                          class="form-control m-input"
                          id="EmailDelay"
                          placeholder=""
                          type="number"
                          value={this.state.EmailDelay}
                          onChange={this.HandleChangeEmailDelay.bind(this)}
                        />
                        <span style={{ color: "red" }}>
                          <small>
                            {this.state.Errors["EmailDelay"]}
                          </small>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">
                        DaysTill Full Throttle{" "}
                      </label>
                      <div class="col-lg-8">
                        <input
                          class="form-control m-input"
                          placeholder=""
                          id="DaysTillFullThrottle"
                          type="number"
                          value={this.state.DaystillFullThrottle}
                          onChange={this.HandleChangeDayStillFullThrottle.bind(
                            this
                          )}
                        />
                        <span style={{ color: "red" }}>
                          <small>
                            {this.state.Errors["DaysTillFullThrottle"]}
                          </small>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">
                        Sending Schedule{" "}
                      </label>
                      <div class="col-lg-8">
                        <select
                          class="form-control  m-input"
                          id="SendingSchedularID"
                          data-val="true"
                          data-val-number="The field IMAPSSLID must be a number."
                          value={this.state.ScheduleStartUTCTimeID}
                          onChange={this.HandleChangeSendingSchedule.bind(
                            this
                          )}
                        >
                          {this.state.SendingSchedul?.map((value) => (
                          <option key={value._id} value={value._id}>
                          {value?.ScheduleStartTime!=null?moment(value?.ScheduleStartTime,'HH:mm').format('h:mm A'):("")}
                          {" "}To{" "}
                          {value?.ScheduleEndTime!=null?moment(value?.ScheduleEndTime,'HH:mm').format('h:mm A'):("")}
                        </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 boxsinput_group">
                    <div class="row max-cols">
                      <label class="col-lg-4 view-lable-meet">
                        Account Type{" "}
                      </label>
                      <div class="col-lg-8">
                        <select class="form-control  m-input" id="AccountTypeID" value={this.state.AccountTypeID} onChange={this.HandleChangeAccountTypeID.bind(
                            this
                          )}>
                          <option value="">--Select--</option>

                          {this.state.AccountType.map((value) => (
                            <option value={value.FieldID}>
                              {value.FieldName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {this.state.FieldID == 2 ? (
                    <>
                      <div class="col-lg-6 my-3">
                        <div class="m-checkbox-inline">
                          <label class="check_bx">
                            <input
                              type="checkbox"
                              id="IsEmailWarmingSending"
                              onChange={(e) => this.HandleChangeCheckEmailSend(e)}
                              checked={this.state.EmailWarmingSending}
                            />
                            <span class="checkmark"></span>
                            Email Warming Sending
                          </label>
                        </div>
                      </div>

                      <div class="col-lg-6 my-3">
                        <div class="m-checkbox-inline">
                          <label class="check_bx">
                            <input
                              type="checkbox"
                              id="IsEmailWarmingReceiving"
                              onChange={(e) =>
                                this.HandleChangeCheckEmailReceiv(e)
                              }
                              checked={this.state.EmailWarmingReceiving}
                            />
                            <span class="checkmark"></span>
                            Email Warming Receiving
                          </label>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>

            <div class="row py-4">
              <div class="row px-3">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.BtnDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddEmailAccountsPage = connect(
  mapState,
  actionCreators
)(AddEmailAccountsPage);
export { connectedAddEmailAccountsPage as AddEmailAccountsPage };
