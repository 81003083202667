import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
var CryptoJS = require("crypto-js");
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import logo from "../../../images/SalesHive.png";
import logobgs from "../../../images/Loginvector.svg";

import { history } from "../../../_helpers/history";
import { CommonConstants } from "../../../_constants/common.constants";

class UpdateEmailSalesStrategistsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Email: "",
      id: "",
      OldEmail: "",
      SalesHackerUserID:"63a42bd2c41e8bfacd32cdbd",
      BtnDisabled:false

    };
    this.SubmittedData = this.SubmittedData.bind(this);
    this.BackButton = this.BackButton.bind(this);
  }
  componentDidMount() {
    document.title="Update Email | SalesHive";

    const QueryParams = new URLSearchParams(window.location.search);
   ;
    const code = QueryParams.get("code");
    const TmpEmail = QueryParams.get("email");
    const TmpUserUID = QueryParams.get("UserUID");
    const TmplastUpdatedBy = QueryParams.get("lastUpdatedBy");
    const TmpUID = QueryParams.get("UID");

    var FinalEmail = TmpEmail.split(" ").join("+");
    var FinalUserUID = TmpUserUID.split(" ").join("+");
    var FinallastUpdatedBy = TmplastUpdatedBy.split(" ").join("+");
    var FinalUID = TmpUID.split(" ").join("+");
    var FinalCode= code.split(" ").join("+");

    var EmailBytes = CryptoJS.AES.decrypt(FinalEmail, "my-secret-key@123");
    var UserUIDBytes = CryptoJS.AES.decrypt(FinalUserUID, "my-secret-key@123");
    var UIDBytes = CryptoJS.AES.decrypt(FinalUID, "my-secret-key@123");
    var lastUpdatedByBytes = CryptoJS.AES.decrypt(FinallastUpdatedBy, "my-secret-key@123");
    
    var Email = JSON.parse(EmailBytes.toString(CryptoJS.enc.Utf8));
    var UID = JSON.parse(UIDBytes.toString(CryptoJS.enc.Utf8));
    
    // var LastUpdatedBy = JSON.parse(lastUpdatedByBytes.toString(CryptoJS.enc.Utf8));
    // 
    // var UserUID = JSON.parse(UserUIDBytes.toString(CryptoJS.enc.Utf8));
    
      document.getElementById('Email').value=Email;
    this.setState({ Email: Email });
    var str_in = {
      EmailToken: FinalCode,
      // Email:Email
    };
    
    Axios({
      url: CommonConstants.MOL_APIURL + "/user/finduseremailcode",
      method: "POST",
      data: str_in,
    }).then((res) => {
      
      this.setState({ id: res.data[0]?._id });
      this.setState({ OldEmail: res.data[0]?.Username });
      

    });
  }

  //back button
  BackButton() {
    history.push("/salesstrategists");
  }

  //Save email
  SubmittedData() {
    var Email = document.getElementById("Email").value;
    if (this.state.Email == Email) {
  
          var str_in = {
            ID:this.state.id,
            Email: Email,
            OldEmail: this.state.OldEmail,
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +"/sales_strategists_profile/EmailUpdate",
            method: "POST",
            data: str_in,
          }).then((res) => {
            
            if(res.data.StatusMessage == "SUCCESS"){
              // toast.success(<div className="toastsize">Sales Development Reps <br/>Email updated successfully.</div>);
              localStorage.removeItem("LoginData");
                   localStorage.removeItem("id");
                   history.push('/');
              
            }
          });
         
    }
  }

  render() {
    return (
      <div class="login-main">
        <div className="row h-100">
          <div className="col-xs-12 col-md-6 logo-bgbod padding-btn">
            <img src={logobgs} class="login-vector" />

            <div class="m-login__logo">
              <a href="/">
                <img src={logo} class="main-logo" />
              </a>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 d-flex">
            {/* Login Form in sign in page*/}
            <div className="full-hegiht">
              <div id="login" className="centerheigh ">
                {/* <Alert id="a_login" variant="filled" severity="success" className="erorrheader">login successfull.</Alert>
                                <Snackbar  className="erorrheader" anchorOrigin={{ vertical:'top' ,horizontal: 'center' }} open={this.state.open} autoHideDuration={6000} onClose={this.handleClose}>
                                    <Alert variant="filled" severity="error" className="erorrheader">Please enter Correct Email or password.</Alert>
                                </Snackbar> */}
                <div className="heading">
                  <h1 class="lefter_title">Update Email</h1>
                </div>
                <form name="login" className="form">
                  <div class="input-control">
                    <label for="Email" className="input-label" hidden>
                      Email Address
                    </label>
                    <input
                      type="Email"
                      id="Email"
                      name="Email"
                      className="input-field"
                      placeholder="Email Address"
                      readOnly={true}
                    />
                    {/* <p id="Email_e" className="errorinput"> Please enter Email </p> */}
                  </div>

                  <div class="input-control btn-center">
                    <button
                      type="button"
                      name="submit"
                      disabled={this.state.BtnDisabled}
                      className="input-submit"
                      value="Save"
                      onClick={() => this.SubmittedData()}
                    >
                 <i class="la la-save"></i>Save
                    </button>


                    <a
                      type="button"
                      name="submit"
                      className="input-submit"
                      value="Save"
                      onClick={() => this.BackButton()}
                    >
                      <i class="la la-arrow-circle-left"></i> Back
                    </a>
                    {/* <p id="err_user"> Please enter Correct Email or password </p> */}
                  </div>
                </form>
              </div>

              {/* Forgot password Form in sign in page*/}
              {/* <div id="forgot" className="centerheigh">
                                <Alert id="forgotpass_u" variant="filled" severity="success" className="erorrheader">Email send. Please check your Email.</Alert>
                                <Snackbar  className="erorrheader" anchorOrigin={{ vertical:'top' ,horizontal: 'center' }} open={this.state.forgotpassOpen} autoHideDuration={6000} onClose={this.handleforgotpassClose}>
                                    <Alert variant="filled" severity="error" className="erorrheader">No User found.</Alert>
                                </Snackbar>
                                <div className="heading">
                                    <h1 class="login_title">Forgotten Password ?</h1>
                                    <p className="login_pereg">Enter your Email to reset your password?</p>
                                </div>
                                <form name="login" className="form">
                                    <div class="input-control">
                                        <label for="Email" className="input-label" hidden>Email Address</label>
                                        <input type="Email" name="Email" id="f_Email" className="input-field" placeholder="Email Address" />
                                        <p id="f_Email_e" className="errorinput"> Please enter Email </p>
                                        <p id="e_user" className="errorinput">No User found</p>
                                        <p id="Email_user" className="errorinput">Email send. Please check your Email.</p>
                                    </div>
                                    <div class="input-control btn-center">
                                        <input type="button" name="Request" onClick={this.submitforgot} className="input-submit" value="Request" />
                                        <input type="button" name="Cancel" onClick={this.cancleforgot} className="input-submit" value="Cancel" />
                                    </div>
                                </form>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  return state;
}

const actionCreators = {};

const connectedUpdateEmailSalesStrategistsPage = connect(
  mapState,
  actionCreators
)(UpdateEmailSalesStrategistsPage);
export { connectedUpdateEmailSalesStrategistsPage as UpdateEmailSalesStrategistsPage };
