import React, { useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";
const moment = require("moment");

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

export default function WhitelistTable(props) {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [EditData, SetEditData] = React.useState([]);
  const [OldDomainData, SetOldDomainData] = React.useState("");

  const [Fields, SetFields] = React.useState({});
  const [Errors, SetErrors] = React.useState({});
  // Domain tab
  const [IsExist, SetIsExist] = React.useState(false);
  const [IsValid, SetIsValid] = React.useState(false);
  const [PUserID, SetPUserID] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(false);
  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetRole(Details.Role);
      SetPUserID(Details.ChildUserID)
    }
    WhiteListDomaiGet(Details.ClientID,Details.ParentUserID,Details.Role);
  }, [Search, Page, RowsPerPage, ClientID, UserID, props.addWhiteListed]);

  // whitelist domain get
  const WhiteListDomaiGet = (CID,UID,Role) => {
    SetIsLoading(true)
    var WhiteListData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role:Role
    };

    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/whitelistedcontact/WhiteListedGet",
      method: "POST",
      data: WhiteListData,
    });
    rows1.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetIsLoading(false)
    });
  };

  // Delete for domain function
  const DeleteBtn = (id, ContactEmail) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a Whitelist .",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        SetIsLoading(true)
        var data = {
          SalesReplyWhiteListedContactID: id,
          IsDeleted: true,
          OldDomainName: ContactEmail,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: UserID,
          Role:Role
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/whitelistedcontact/WhiteListedDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Whitelist deleted successfully.",
                "success"
              );
              WhiteListDomaiGet(ClientID,UserID,Role);
              props.updateFromChild(true, "Whitelist");
              SetIsLoading(false)
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // For search whitelist domain
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if(SearchedVal==Search){
        SetSearch(SearchedVal);
        SetPage(1);
      }else{
        SetSearch(SearchedVal);
        SetPage(1);
       
      }
    }
  };

  // Show entries for
  const ChangeRowSelected = (event) => {
    SetRowsPerPage(event.target.value);
    SetPage(1);
    document.getElementById("hideloding").style.display = "block";
  };

  // handle change page
  const HandleChangePage = (event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      document.getElementById("hideloding").style.display = "block";
    }
  };

  // Edit desing change
  const EditDomain = (SalesReplyWhiteListedContactID) => {
    SetIsLoading(true)
    document.getElementById(
      SalesReplyWhiteListedContactID + "labelbox"
    ).style.display = "none";
    document.getElementById(
      SalesReplyWhiteListedContactID + "text"
    ).style.display = "block";
    document.getElementById(
      SalesReplyWhiteListedContactID + "editicon1"
    ).style.display = "none";
    document.getElementById(
      SalesReplyWhiteListedContactID + "saveicon1"
    ).style.display = "block";
    document.getElementById(
      SalesReplyWhiteListedContactID + "editicon2"
    ).style.display = "none";
    document.getElementById(
      SalesReplyWhiteListedContactID + "saveicon2"
    ).style.display = "block";

    var str_in = {
      SalesReplyWhiteListedContactID: SalesReplyWhiteListedContactID,
      Role:Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/whitelistedcontact/WhitelistByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        document.getElementById(SalesReplyWhiteListedContactID + "text").value =
          res.data.Data?.ContactEmail;
        SetOldDomainData(res.data.Data?.ContactEmail);
        SetEditData(res.data?.data);
        SetIsLoading(false)
      } else {
        toast.error(res.data?.Message);
      }
    });
  };

  // hide display
  const HideEditDomain = (SalesReplyWhiteListedContactID) => {
    document.getElementById(
      SalesReplyWhiteListedContactID + "labelbox"
    ).style.display = "block";
    document.getElementById(
      SalesReplyWhiteListedContactID + "text"
    ).style.display = "none";
    document.getElementById(
      SalesReplyWhiteListedContactID + "editicon1"
    ).style.display = "block";
    document.getElementById(
      SalesReplyWhiteListedContactID + "saveicon1"
    ).style.display = "none";
    document.getElementById(
      SalesReplyWhiteListedContactID + "editicon2"
    ).style.display = "block";
    document.getElementById(
      SalesReplyWhiteListedContactID + "saveicon2"
    ).style.display = "none";
  };

  // Update domain data
  const WhiteListDomainUpdateBtn = async (id) => {
    var Final_flag = await WhiteTabFormValidation(id);
    var ContactEmail = document.getElementById(id + "text").value;
    if (Final_flag == true) {
      SetIsLoading(true)
      var WhiteListData = {

        SalesReplyWhiteListedContactID: id,
        ContactEmail: ContactEmail,
        LastUpdatedDate: new Date(),
        LastUpdatedBy: UserID,
        OldContact: OldDomainData,
        Role:Role,
        ClientID:ClientID
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/whitelistedcontact/WhiteListedUpdate",
        method: "POST",
        data: WhiteListData,
      }).then((result) => {
        if (result.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div>
              Whitelist<br/>
              Whitelist updated successfully.
            </div>
          );
          HideEditDomain(id);
          document.getElementById("hideloding").style.display = "block";
          WhiteListDomaiGet(ClientID,UserID,Role);
          SetIsLoading(false)
        } else {
          toast.error(result.data.Message);
          SetIsLoading(false)
        }
      });
    }
  };

  // formvalidation for white tab
  const WhiteTabFormValidation = async (id) => {
    ;
    let formIsValid = true;
    let errors = {};
    var Name = document.getElementById(id + "text").value;
    let IsExist = await WhiteEmailCheckExist(Name);
    let FLAGISVALID = await WhiteTabEmailCheck(Name);

    if (Name == "" && Name.indexOf(" ") != "") {
      formIsValid = false;
      toast.error("Please enter email");
      SetIsLoading(false)
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    if (FLAGISVALID == true && Name != "") {
      formIsValid = false;
      toast.error("Invalid email");
    }
    SetErrors(errors);
    return formIsValid;
  };
  //Email Check for domain
  const WhiteTabEmailCheck = async (Name) => {
    var regexp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regexp.test(Name)) {
      SetIsValid(true);
      return true;
    } else {
      SetIsValid(false);
      return false;
    }
  };

  // cheack exists for contact email tab
  const WhiteEmailCheckExist = async (Name) => {
    var str_in = {
      ClientID: ClientID,
      UserID: UserID,
      ContactEmail: Name,
      Role:Role
    };
    let resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/whitelistedcontact/WhiteListedCheackExists",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (OldDomainData == Name) {
          SetIsExist(false);
          return false;
        } else {
          toast.error("Email is already exists!");
          SetIsExist(true);
          SetIsLoading(false)
          return true;
        }
      } else {
        SetIsExist(false);
        SetIsLoading(false)
        return false;
      }
    }
  };

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: SearchedVal,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role:Role
      };
      const WhiteListedGet = Axios({
        url: CommonConstants.MOL_APIURL + "/whitelistedcontact/WhiteListedGet",
        method: "POST",
        data: InputParameter,
      });
      WhiteListedGet.then((Result) => {
        SetData(Result.data.PageData);
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: SearchedVal,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role:Role
      };
      const WhiteListedGet = Axios({
        url: CommonConstants.MOL_APIURL + "/whitelistedcontact/WhiteListedGet",
        method: "POST",
        data: InputParameter,
      });
      WhiteListedGet.then((Result) => {
        SetData(Result.data.PageData);
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
      });
    }
  };

  return (
    <div>
   { IsLoading == true ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>: <></>}
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {" "}
                  <a
                    onClick={() => {
                      SortData("ContactEmail");
                    }}
                  >
                    Whitelist
                  </a>
                </TableCell>
                <TableCell>
                  {" "}
                  <a
                    onClick={() => {
                      SortData("CreatedDate");
                    }}
                  >
                    Created Date
                  </a>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>
                      <label
                        id={row?._id + "labelbox"}
                      >
                        {row.ContactEmail}{" "}
                      </label>{" "}
                      <input
                        type="textbox"
                        id={row?._id + "text"}
                        class="form-control m-input"
                        style={{ display: "none" }}
                        value={Fields["ContactEmail"]}
                        autocomplete="off"
                      />
                    </TableCell>
                    <TableCell>
                      {moment(new Date(row.CreatedDate).toDateString()).format(
                        "MM/DD/YYYY"
                      )}
                    </TableCell>
                    <TableCell className="px-0 d-flex">
                      <a
                        onClick={() => {
                          EditDomain(row?._id);
                        }}
                        id={row?._id + "editicon1"}
                        className="btn-eyesicon" title="Edit"
                      >
                        <i class="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      <a
                        onClick={() => {
                          DeleteBtn(
                            row._id,
                            row.ContactEmail
                          );
                        }}
                        id={row._id + "editicon2"}
                        className="btn-eyesicon" title="Delete"
                      >
                        <i class="la flaticon-delete-1 delete-icon"></i>
                      </a>
                      <a
                        onClick={() => {
                          WhiteListDomainUpdateBtn(
                            row?._id
                          );
                        }}
                        style={{ display: "none" }}
                        id={row?._id + "saveicon1"}
                        className="btn-eyesicon" title="Save"
                      >
                        <i className="la la-save delete-icon"></i>
                      </a>
                      <a
                        onClick={() => {
                          HideEditDomain(row?._id);
                        }}
                        style={{ display: "none" }}
                        id={row?._id + "saveicon2"}
                        className="btn-eyesicon" title="Cancel"
                      >
                        <i className="la flaticon-cancel delete-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
  );
}
