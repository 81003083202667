import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from "../../_components/user/footer/footer";
import BlacklistsTabing from '../../_components/clients/blacklists/blacklistsTabing';



class CBlacklistsPage extends React.Component {
   

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitle pl-0 mb-0">Blacklists</h4> 
                        </div>
                    </div> 
                    <div class="row">  
                        <div className="col">
                            <BlacklistsTabing />   
                        </div>   
                    </div>

                </div> 
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCBlacklistsPage = connect(mapState, actionCreators)(CBlacklistsPage);
export { connectedCBlacklistsPage as CBlacklistsPage };