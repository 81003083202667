import React, { useEffect } from "react";
import Axios from "axios";

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableFooter from '@material-ui/core/TableFooter';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from "@material-ui/lab/Pagination";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import loadingicon from "../../../images/loading.gif";

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});


export default function CollapsibleTable() {
  const [open, setOpen] = React.useState(false);
  const [data, setdata] = React.useState([]);
  const [flen, setflen] = React.useState(0);
  const [sflag, setsflag] = React.useState(false);
  const [rlen, setrlen] = React.useState(0);
  const [countpage, setCountPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [search, setSearch] = React.useState("");
  const [aID, setAID] = React.useState("");
  const [checkS, setcheckS] = React.useState(false);
  const [totalcount, setTotalCount] = React.useState([]);
  const [sortField, setsortField] = React.useState("Name");
  const [sortedBy, setsortedBy] = React.useState(1);
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [Role, setRole] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0);
  const [sortedColumn, setSortedColumn] = React.useState("Name");
  useEffect(() => {
    var getaccountcatelist = GetUserDetails();
    if (getaccountcatelist != null) {
      setClientID(getaccountcatelist.ClientID);
      setUserID(getaccountcatelist.ParentUserID);
      SetCUserID(getaccountcatelist.ChildUserID);
      setRole(getaccountcatelist.Role);
    }
    GetMeetingByMonthList(
      getaccountcatelist.ClientID,
      getaccountcatelist.ParentUserID,
      getaccountcatelist.Role
    );
  }, [search, page, RowsPerPage]);

  //get seniority meeting
  const GetMeetingByMonthList = (CID, UID, URole) => {
    SetIsLoading(true);
    var str_in = {
      page: page,
      rowsPerPage: RowsPerPage,
      sort: true,
      field: sortField,
      sortby: sortedBy,
      search: search,
      type: "User",
      ClientID: CID,
      Role: URole,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetMeetingBySource",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      const modifiedData = result.data.pagedata.map((item) => ({
        ...item,
        January: item.counts[0]?.count || 0,
        February: item.counts[1]?.count || 0,
        March: item.counts[2]?.count || 0,
        April: item.counts[3]?.count || 0,
        May: item.counts[4]?.count || 0,
        June: item.counts[5]?.count || 0,
        July: item.counts[6]?.count || 0,
        August: item.counts[7]?.count || 0,
        September: item.counts[8]?.count || 0,
        October: item.counts[9]?.count || 0,
        November: item.counts[10]?.count || 0,
        December: item.counts[11]?.count || 0,
      }));

      setdata(modifiedData);
      setRows(modifiedData);
      setrlen(result.data.totalcount);
      setflen(result.data.totalcount);
      setCountPage(result.data.pagecount);
      setTotalCount(result.data.totalmonthcount);
      SetIsLoading(false);
      //document.getElementById("hideloding").style.display = "none";
    });
  };

  const chengeRowSelected = (event) => {
    SetRowsPerPage(Number(event.target.value));
    setPage(1);
    // document.getElementById("hideloding").style.display = "block";
  };

  //search
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == search) {
        setSearch(SearchedVal);
        setPage(1);
      } else {
        setSearch(SearchedVal);
        setPage(1);
        // document.getElementById("hideloding").style.display = "block";
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    if (newPage == page) {
      setPage(newPage);
    } else {
      setPage(newPage);
      //document.getElementById("hideloding").style.display = "block";
    }
  };

  const toggleSort = (column) => {
    SetIsLoading(true);
    if (sortedColumn === column) {
      setsortedBy(sortedBy === 1 ? -1 : 1);
      SetIsLoading(false);
    } else {
      setSortedColumn(column);
      setsortedBy(1);
      SetIsLoading(false);
    }
  };

  const sortRows = (column) => {
    const sortedRows = [...rows];

    sortedRows.sort((a, b) => {
      if (a[column] < b[column]) return -1;
      if (a[column] > b[column]) return 1;
      return 0;
    });

    if (sortedColumn === column && sortedBy === -1) {
      return sortedRows.reverse();
    }

    return sortedRows;
  };

  return (
    <div>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={chengeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              Type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell onClick={() => toggleSort("Name")}>
                  Meeting Source
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedBy === 1 && sortedColumn === "Name"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedBy === -1 && sortedColumn === "Name"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell onClick={() => toggleSort("January")}>
                  January
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedBy === 1 && sortedColumn === "January"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedBy === -1 && sortedColumn === "January"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell onClick={() => toggleSort("February")}>
                  February
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedBy === 1 && sortedColumn === "February"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedBy === -1 && sortedColumn === "February"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell onClick={() => toggleSort("March")}>
                  March
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedBy === 1 && sortedColumn === "March"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedBy === -1 && sortedColumn === "March"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell onClick={() => toggleSort("April")}>
                  April
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedBy === 1 && sortedColumn === "April"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedBy === -1 && sortedColumn === "April"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell onClick={() => toggleSort("May")}>
                  May
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedBy === 1 && sortedColumn === "May"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedBy === -1 && sortedColumn === "May"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell onClick={() => toggleSort("June")}>
                  June
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedBy === 1 && sortedColumn === "June"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedBy === -1 && sortedColumn === "June"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell onClick={() => toggleSort("July")}>
                  July
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedBy === 1 && sortedColumn === "July"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedBy === -1 && sortedColumn === "July"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell onClick={() => toggleSort("August")}>
                  August
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedBy === 1 && sortedColumn === "August"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedBy === -1 && sortedColumn === "August"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell onClick={() => toggleSort("September")}>
                  September
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedBy === 1 && sortedColumn === "September"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedBy === -1 && sortedColumn === "September"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell onClick={() => toggleSort("October")}>
                  October
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedBy === 1 && sortedColumn === "October"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedBy === -1 && sortedColumn === "October"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell onClick={() => toggleSort("November")}>
                  November
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedBy === 1 && sortedColumn === "November"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedBy === -1 && sortedColumn === "November"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
                <TableCell onClick={() => toggleSort("December")}>
                  December
                  <span className="shorting">
                    <ArrowUpward
                      className={
                        sortedBy === 1 && sortedColumn === "December"
                          ? "active"
                          : null
                      }
                    />
                    <ArrowDownward
                      className={
                        sortedBy === -1 && sortedColumn === "December"
                          ? "active"
                          : null
                      }
                    />
                  </span>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                sortRows(sortedColumn).map((row) => (
                  <TableRow key={row?._id}>
                    <TableCell>{row.Name}</TableCell>
                    <TableCell>{row.January}</TableCell>
                    <TableCell>{row.February}</TableCell>
                    <TableCell>{row.March}</TableCell>
                    <TableCell>{row.April}</TableCell>
                    <TableCell>{row.May}</TableCell>
                    <TableCell>{row.June}</TableCell>
                    <TableCell>{row.July}</TableCell>
                    <TableCell>{row.August}</TableCell>
                    <TableCell>{row.September}</TableCell>
                    <TableCell>{row.October}</TableCell>
                    <TableCell>{row.November}</TableCell>
                    <TableCell>{row.December}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
            <TableFooter>
              {totalcount.map((row) => (
                <TableRow key={row?._id}>
                  <TableCell>{row?.Name}</TableCell>
                  <TableCell>
                    {" "}
                    {row.count.length == 0 ? 0 : row.count[0]?.count}
                  </TableCell>
                  <TableCell>
                    {" "}
                    {row.count.length == 0 ? 0 : row.count[1]?.count}
                  </TableCell>
                  <TableCell>
                    {" "}
                    {row.count.length == 0 ? 0 : row.count[2]?.count}
                  </TableCell>
                  <TableCell>
                    {" "}
                    {row.count.length == 0 ? 0 : row.count[3]?.count}
                  </TableCell>
                  <TableCell>
                    {" "}
                    {row.count.length == 0 ? 0 : row.count[4]?.count}
                  </TableCell>
                  <TableCell>
                    {" "}
                    {row.count.length == 0 ? 0 : row.count[5]?.count}
                  </TableCell>
                  <TableCell>
                    {" "}
                    {row.count.length == 0 ? 0 : row.count[6]?.count}
                  </TableCell>
                  <TableCell>
                    {" "}
                    {row.count.length == 0 ? 0 : row.count[7]?.count}
                  </TableCell>
                  <TableCell>
                    {" "}
                    {row.count.length == 0 ? 0 : row.count[8]?.count}
                  </TableCell>
                  <TableCell>
                    {" "}
                    {row.count.length == 0 ? 0 : row.count[9]?.count}
                  </TableCell>
                  <TableCell>
                    {" "}
                    {row.count.length == 0 ? 0 : row.count[10]?.count}
                  </TableCell>
                  <TableCell>
                    {" "}
                    {row.count.length == 0 ? 0 : row.count[11]?.count}
                  </TableCell>
                </TableRow>
              ))}
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      {sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={countpage}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
              {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of {rlen}{" "}
              entries (filtered from {flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
              {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of {rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={countpage}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
  );
}
