import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import { toast } from "react-toastify";

import { CSVReader, CSVDownloader } from "react-papaparse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StyleHeader from "../StickyHeader/StickyHeader";

const SortingIcons = ({SortedBy,sortedColumn,ColumnName}) => {
  return (
    <span className="shorting">
        <ArrowUpward className={SortedBy === 1 && sortedColumn === ColumnName? "active": null}/>
        <ArrowDownward className={SortedBy === -1 && sortedColumn === ColumnName? "active": null}/>
    </span>
  )
}


export default function AccountAllTable({ ToDate, FromDate }) {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("MailSentDateByTimeZone");
  const [sortedColumn, setSortedColumn] = React.useState("MailSentDateByTimeZone");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [FromDates, SetFromDates] = React.useState("");
  const [ToDates, SetToDates] = React.useState("");
  const [Export, SetExport] = React.useState([]);
  const [ClientName, SetClientName] = React.useState("");
  const [IsLoading, SetIsLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [notes, setNotes] = React.useState([]);
  const [CUserID, SetCUserID] = React.useState(0);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {

        document.title = "Email History | SalesHive";
        const details = GetUserDetails();

        if (details != null) {
          SetClientID(details.ClientID);
          SetUserID(details.ParentUserID);
          SetCUserID(details.ChildUserID);
          SetRole(details.Role);
        }

        SetFromDates(FromDate);
        SetToDates(ToDate);

          HistoryGet(
            details.ClientID,
            details.ParentUserID,
            details.Role,
            FromDate,
            ToDate
          )
     
  }, [Search, Page, RowsPerPage, ToDate, FromDate]);

  useEffect(() => {
        const details = GetUserDetails();
        if (details != null) {
          SetClientID(details.ClientID);
          SetUserID(details.ParentUserID);
          SetCUserID(details.ChildUserID);
          SetRole(details.Role);
          NoteGet(details.ParentUserID, details.ClientID, details.Role)
        }
  }, []);

  // History list
  const HistoryGet = async (CID, UID, URole, From, To) => {
    SetIsLoading(true);
    if (From.trim() !== "" && To.trim() !== "") {
      try {
        let HistoryData = {
          Page: Page,
          RowsPerPage: RowsPerPage,
          Sort: true,
          Field: SortField,
          SortBy: SortedBy,
          Search: Search,
          Type: "User",
          ClientID: CID,
          UserID: UID,
          Role: URole,
          ToDate: To,
          FromDate: From,
        };

        const rows1 = await Axios({
          url: CommonConstants.MOL_APIURL + "/history/HistoryGet",
          method: "POST",
          data: HistoryData,
        });
        if (rows1.data.StatusMessage == "SUCCESS") {
          SetData(rows1.data.PageData);
          SetRows(rows1.data.PageData);
          SetRlen(rows1.data.TotalCount);
          SetFlen(rows1.data.TotalCount);
          SetCountPage(rows1.data.PageCount);
          SetIsLoading(false);
          setIsDataLoaded(true);

        }
        else {
          SetIsLoading(false);
          setIsDataLoaded(true);

        }
      } catch (error) {
        console.error("Error in HistoryGet:", error);
        SetIsLoading(false);
        setIsDataLoaded(true);

      }
    }
  };

  //GEt Notes detail
  const NoteGet = (UID, CID, ROLE) => {
    let HistoryData = {
      ClientID: CID,
      UserID: UID,
      Role: ROLE,
    };

    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/history/GetNotes",
      method: "POST",
      data: HistoryData,
    });
    rows1.then((result) => {
      setNotes(result.data.Data);
    });
  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      let SearchedVal = document.getElementById("search").value?.trim();
      SetSearch(SearchedVal);
      SetPage(1)
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };
  //get sort field data
  const SortData = (Field) => {

    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
  
    SetSortField(Field);
    SetSflag(SearchedVal !== "");


      let InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
        ToDate: ToDates,
        FromDate: FromDates,
        Search: SearchedVal || "",
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/history/HistoryGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        if (Result.data.StatusMessage == "SUCCESS") {
          SetRows(Result.data.PageData);
          SetRlen(Result.data.TotalCount);
          SetFlen(Result.data.TotalCount);
          SetCountPage(Result.data.PageCount);
          SetPage(1)
          SetIsLoading(false);
        } else {
          SetIsLoading(false);
        }
      });
   
  };

  //Export CSV
  const ExportCsv = () => {
    toast.success(
      <div className="toastsize">
        Email History <br />
        Data exported successfully.
      </div>
    );
  };

  //Export Data
  const ExportHistory = async(CID, UID, Roles, FromDate, ToDate) => {
    if (FromDate.trim() !== "" && ToDate.trim() !== "") {
      let HistoryData = {
        Type: "User",
        ClientID: CID,
        UserID: UID,
        Role: Roles,
        ToDate: ToDate,
        FromDate: FromDate,
        Search: Search,
      };

      const rows1 = await Axios({
        url: CommonConstants.MOL_APIURL + "/history/ExportHistory",
        method: "POST",
        data: HistoryData,
      });
      if (rows1.data.StatusMessage === "SUCCESS") {
        return rows1?.data
      }
      // rows1.then((result) => {
      //   SetExport(result.data.Data);
      //   SetClientName(result.data.ClientName.Name);
      // });
    }
  };

  const exportcsv = async() => {
    try {
     SetIsLoading(true)
      const result = await ExportHistory(ClientID, UserID, Role, FromDate, ToDate);
      if (result.StatusMessage === "SUCCESS") {
        const data = result.Data;
        if (data.length > 0) {
          SetExport(data);
          const downloader = document.createElement("a");
          const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(convertToCSV(data));
          downloader.setAttribute("href", csvContent);
          downloader.setAttribute("download", result.ClientName.Name+"-Email-History.csv");
          downloader.click();
          toast.success(<div>Data exported successfully.</div>);
         SetIsLoading(false)
        } else {
          toast.error("No data available for export.");
         SetIsLoading(false)
        }
      } else {
        toast.error("Failed to export data. Please try again.");
       SetIsLoading(false)
      }
    } catch (error) {
      SetIsLoading(false)
      toast.error("An error occurred while exporting data.");
    }
  };

  
  function convertToCSV(data) {
    const headers = Object.keys(data[0]);

    const csvRows = [];
    csvRows.push(headers.join(','));

    data.forEach(item => {
        const values = headers.map(header => {
            // Check if the value is a string before attempting to replace characters
            const value = typeof item[header] === 'string' ? item[header] : String(item[header]);
            const escapedValue = value.replace(/"/g, '""'); // Escape double quotes
            return `"${escapedValue}"`; // Enclose value in double quotes
        });
        csvRows.push(values.join(','));
    });

    return csvRows.join('\n');
}

  return (
    <>
      {IsLoading == true ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}

      <div className="row pt-2">
        <div className="col">
          <div className="listing-li float-right">
            {/* <CSVDownloader
              className="px-0"
              data={Export}
              filename={`${ClientName}-Email-History`}
              bom={true}
            > */}
              <ul>
                <li>
                  <a
                     onClick={exportcsv}
                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                  >
                    <i class="la la-icon-font-size-13 la-download"></i>
                    <span>Export</span>
                  </a>
                </li>
              </ul>
            {/* </CSVDownloader> */}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
          <Table className="table-ref" aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell onClick={() => {SortData("MailTo");}}> Email <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"MailTo"} /></TableCell>
                <TableCell onClick={() => {SortData("MailFrom");}}>Sender <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"MailFrom"} /></TableCell>
                <TableCell >Campaign Name </TableCell>
                <TableCell > Step </TableCell>
                <TableCell onClick={() => {SortData("MailSentDateByTimeZone");}}>Send Date <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn}  ColumnName={"MailSentDateByTimeZone"} /></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows.map((row) => (
                  <>
                    <TableRow key={row?._id} row={row}>
                      <TableCell align="center">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() =>
                            setOpen((prev) => ({
                              // ...prev,
                              [row._id]: !prev[row._id],
                            }))
                          }
                        >
                          {open[row._id] ? (
                            <i
                              className="fa fa-minus-circle minusl"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              className="fa fa-plus-circle plusbl"
                              aria-hidden="true"
                            ></i>
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>{row.MailTo}</TableCell>
                      <TableCell>{row.MailFrom}</TableCell>
                      <TableCell>{row.Name}</TableCell>
                      <TableCell>
                        {row?.Status +
                          "-" +
                          String.fromCharCode(64 + row?.CST?.OrderBy)}
                      </TableCell>
                      <TableCell>
                        {moment
                          .utc(row.MailSentDateByTimeZone)
                          .format("MM/DD/YYYY h:mm:ss A")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse
                          in={open[row._id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {open[row._id] && (
                            <Box margin={1} className="innertables">
                              <Table size="small" aria-label="purchases">
                                <TableHead></TableHead>
                                {notes?.map((note) =>
                                  note?.CampaignStepHistoryID.toString() ===
                                    row?._id.toString() ? (
                                    <TableRow key={note.CampaignStepHistoryID}>
                                      <TableCell className="font-weight-bold">
                                        {String.fromCharCode(64 + row?.CST?.OrderBy)}
                                      </TableCell>
                                      <TableCell className="labeltds font-weight-bold">
                                        {note?.Subject}
                                      </TableCell>
                                      <TableCell scope="row">
                                        {note?.SalesReply_Payload[1]
                                          ?.BodyDataPlain ? (
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                note?.SalesReply_Payload[1]
                                                  ?.BodyDataPlain,
                                            }}
                                          />
                                        ) : (
                                          <div></div>
                                        )}
                                      </TableCell>
                                      <TableCell className="labeltds"></TableCell>
                                      <TableCell className="labeltds"></TableCell>
                                    </TableRow>
                                  ) : null
                                )}

                                {!notes.some(
                                  (note) =>
                                    note?.CampaignStepHistoryID === row?._id
                                ) && (
                                    <>
                                      {Rows?.map((note) =>
                                        note?._id.toString() ===
                                          row?._id.toString() ? (
                                          <TableRow key={note?._id}>
                                            <TableCell className="font-weight-bold">
                                              <span className="btn btn-metal m-btn m-btn--icon btn-sm m-btn--pill small-radious">
                                                {String.fromCharCode(
                                                  64 + row?.CST?.OrderBy
                                                )}
                                              </span>
                                            </TableCell>
                                            <TableCell className="labeltds font-weight-bold">
                                              {note?.Subject}
                                            </TableCell>
                                            <TableCell scope="row">
                                              {note?.Body ? (
                                                <div className="clear-div"
                                                  style={{
                                                    textWrap: "balance",
                                                    paddingBottom: "1rem",
                                                  }}
                                                  dangerouslySetInnerHTML={{
                                                    __html: note?.Body,
                                                  }}
                                                />
                                              ) : (
                                                <div></div>
                                              )}
                                            </TableCell>
                                            <TableCell className="labeltds"></TableCell>
                                            <TableCell className="labeltds"></TableCell>
                                          </TableRow>
                                        ) : null
                                      )}
                                    </>
                                  )}
                              </Table>
                            </Box>
                          )}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div class="row">
        <div class="col dataTables_info">
          <p>
            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
            entries
          </p>
        </div>
        <div class="col pageright">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </>
  );
}
