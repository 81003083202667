import React, { useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";
const moment = require("moment");
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import { CommonConstants } from "../../../_constants/common.constants";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetClientDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

toast.configure();

export default function AccountAllTable(props) {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Name");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [AccountTypeD1, SetAccountTypeD1] = React.useState([]);
  const [HideInactiveAccount, SetHideInactiveAccount] = React.useState(true);
  const [ExportData, SetExportData] = React.useState([]);
  const [CName, SetCName] = React.useState("");
  const [CUserID,SetCUserID] = React.useState(0)

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    GetClientDetails(Details.ClientID).then((result) => {
      SetCName(result[0].Name);
    });
    ClientEmailAccountGet(Details.ParentUserID);
  }, [Search, Page, RowsPerPage]);
  
  // Email account get
  const ClientEmailAccountGet = (Uid) => {
    document.getElementById("hideloding").style.display = "block";
    var IsOldIncludetemp = document.getElementById("include_archive");
    var EmailAccountData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      UserID: Uid,
      HideInactiveAccountParam: HideInactiveAccount,
      IsOldInclude:IsOldIncludetemp.checked
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/emailaccount/ClientEmailaccountsGet",
      method: "POST",
      data: EmailAccountData,
    });
    rows1.then((result) => {
      
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetAccountTypeD1(result.data.AccountTypeData);
      document.getElementById("hideloding").style.display = "none";
    });
  };

  const HideInActiveAccount = (e) => {
    var HideInActiveP = document.getElementById(
      "include_inactive_account"
    ).checked;
    document.getElementById("hideloding").style.display = "block";
    var IsOldIncludetemp = document.getElementById("include_archive");

    props.HandleExportwithSearch(HideInActiveP,IsOldIncludetemp.checked)
    var EmailAccountData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      UserID: UserID,
      HideInactiveAccountParam: HideInActiveP,
      IsOldInclude:IsOldIncludetemp.checked
    };
    
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/emailaccount/ClientEmailaccountsGet",
      method: "POST",
      data: EmailAccountData,
    });
    rows1.then((result) => {
      
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetAccountTypeD1(result.data.AccountTypeData);
      document.getElementById("hideloding").style.display = "none";

    });
  };

  //update potential status dropdown
  const AccountTypeSelectID = (event, id) => {
    var data = {
      AccountID: id,
      AccountTypeID: event.target.value,
      LastUpdatedBy: CUserID,
      LastUpdatedDt: new Date(),
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/emailaccount/UpdateAccountType",
      method: "POST",
      data: data,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        ClientEmailAccountGet(UserID);
        toast.success(
          <div className="toastsize">Accont type successfully updated.</div>
        );
      } else {
        toast.error(res.data.Message);
      }
    });
  };

  // handle change email recieve
  const HandleChangeIsRemove = (e, Aid) => {
    if (e.target.checked) {
      var IsRemoveFromDeliverabilityTesting = (document.getElementById(
        "IsRemoveFromDeliverabilityTesting"
      ).checked = true);
      

      Swal.fire({
        title: "Are you sure?",
        text: "you want to Remove email account from deliverability testing.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          var InputParameter = {
            AccountID: Aid,
            IsRemoveFromDeliverabilityTesting:
              IsRemoveFromDeliverabilityTesting,
            LastUpdatedBy: CUserID,
            LastUpdatedDt: new Date(),
          };
          
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/emailaccount/UpdateIsRemoveDeliveblity",
            method: "POST",
            data: InputParameter,
          }).then((res) => {
            if (res) {
              if (res.data.StatusMessage == "SUCCESS") {
                toast.success(
                  <div className="toastsize">
                    {" "}
                    Email Account Removed email account from deliverability
                    testing successfully.
                  </div>
                );
              } else {
                toast.error(res.data.Message);
              }
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else if (!e.target.checked) {
      var IsRemoveFromDeliverabilityTesting = (document.getElementById(
        "IsRemoveFromDeliverabilityTesting"
      ).checked = false);
      

      Swal.fire({
        title: "Are you sure?",
        text: "you want to Remove email account from deliverability testing.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          var InputParameter = {
            AccountID: Aid,
            IsRemoveFromDeliverabilityTesting:
              IsRemoveFromDeliverabilityTesting,
            LastUpdatedBy: CUserID,
            LastUpdatedDt: new Date(),
          };
          
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/emailaccount/UpdateIsRemoveDeliveblity",
            method: "POST",
            data: InputParameter,
          }).then((res) => {
            if (res) {
              if (res.data.StatusMessage == "SUCCESS") {
                toast.success(
                  <div className="toastsize">
                    {" "}
                    Email Account Removed email account from deliverability
                    testing successfully.
                  </div>
                );
              } else {
                toast.error(res.data.Message);
              }
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    }
  };
  // Delete btn email accounts
  const DeleteBtn = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a account.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          _id: id,
          IsDeleted: true,
          LastUpdatedDt: new Date(),
          LastUpdatedBy: CUserID,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/emailaccount/EmailAccountDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Email account deleted successfully.",
                "success"
              );
              ClientEmailAccountGet(UserID);
              props.updatechild(true);
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };
  // email accounts edit
  const EmailAccountsEdit = (id) => {
    history.push("/editemailaccounts", {
      id: id,
      pagename: "clientemailaccounts",
    });
  };

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("search").value;
    // SetPage(1);
    // SetRowsPerPage(10);
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var IsOldIncludetemp = document.getElementById("include_archive");
      var EmailAccountData = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
        UserID: UserID,
        HideInactiveAccountParam: HideInactiveAccount,
        IsOldInclude:IsOldIncludetemp.checked
      };

      const rows1 = Axios({
        url:
          CommonConstants.MOL_APIURL + "/emailaccount/ClientEmailaccountsGet",
        method: "POST",
        data: EmailAccountData,
      });
      rows1.then((result) => {
        
        SetData(result.data.PageData);
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
        SetAccountTypeD1(result.data.AccountTypeData);
        document.getElementById("hideloding").style.display = "block";
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var IsOldIncludetemp = document.getElementById("include_archive");
      var EmailAccountData = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
        UserID: UserID,
        HideInactiveAccountParam: HideInactiveAccount,
        IsOldInclude:IsOldIncludetemp.checked
      };
      
      const rows1 = Axios({
        url:
          CommonConstants.MOL_APIURL + "/emailaccount/ClientEmailaccountsGet",
        method: "POST",
        data: EmailAccountData,
      });
      rows1.then((result) => {
        
        SetData(result.data.PageData);
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
        SetAccountTypeD1(result.data.AccountTypeData);
        document.getElementById("hideloding").style.display = "block";
      });
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {

    if (NewPage == Page) {
      SetPage(NewPage);
    } else if(HideInactiveAccount === true){
      SetPage(NewPage); 
      SetHideInactiveAccount(false)
    }
    else {
      SetPage(NewPage);
    }

  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      document.getElementById("hideloding").style.display = "block";
    }
  };

  //Tooltip hover
  const TooltipHover = (ID) => {
    let InputParameter = {
      AccountID: ID,
      Role: Role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/emailaccount/GetSendMailCount",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        let NewList = Rows;
        const updatedArray = NewList.filter((obj) => {
          if (obj._id == ID) {
            obj["LastWeekEmailsSent"] = res.data?.LastWeekEmailsSent;
            obj["YesterDayEmailSent"] = res.data?.YesterDayEmailSent;
            obj["AvgEmailsPerDay"] = res.data?.AvgEmailsPerDay;
          }
          return obj;
        });
        SetRows(updatedArray);
      }
      
    });

  };

  return (
    <>
      <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>

      <div className="row pb-3 px-4 borderbtnm">
        <div className="col"></div>
        <div className="col padright">
          <div className="float-right">
            <label class="check_bx floatrighter mr-3">
              <input id="include_archive" type="checkbox" />
              <span className="checkmark"></span>
              Include Old Clients
            </label>
            <label class="check_bx floatrighter mr-3">
              <input
                id="include_inactive_account"
                defaultChecked={HideInactiveAccount}
                type="checkbox"
              />
              <span className="checkmark"></span>
              Hide Inactive Accounts
            </label>
            <button
              type="submit"
              onClick={(e) => HideInActiveAccount(e)}
              class="btn btn-primary btnColor m-btn float-left"
            >
              <span>
                <i class="la flaticon-search"></i>
                <span>Search</span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      
      <div className="table-bordered">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Is Remove from Deliverability Testing</TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("IsAuthenticated");
                  }}
                >
                  Authenticate
                </a>
              </TableCell>
              <TableCell>SMTP Status</TableCell>
              <TableCell>IMAP Status</TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Name");
                  }}
                >
                  Client
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("ClientStatusName");
                  }}
                >
                  Client Status
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Name");
                  }}
                >
                  Name
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Email");
                  }}
                >
                  Domain
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("AliasTitle");
                  }}
                >
                  Alias Title
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("AccountTypeID");
                  }}
                >
                  Account Type
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("LastWeekEmailsSent");
                  }}
                >
                  Last Week Emails Sent
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("SMTPFromEmail");
                  }}
                >
                  From Email
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("SMTPEmail");
                  }}
                >
                  SMTP Email
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("SMTPHost");
                  }}
                >
                  SMTP Host
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("SMTPPort");
                  }}
                >
                  SMTP Port
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("IMAPEmail");
                  }}
                >
                  IMAP Email
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("IMAPHost");
                  }}
                >
                  IMAP Host
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("IMAPPort");
                  }}
                >
                  IMAP Port
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("ThrottleStartingVolume");
                  }}
                >
                  Throttle Starting Volume
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("ThrottleEndingVolume");
                  }}
                >
                  Throttle Ending Volume
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("EmailDelay");
                  }}
                >
                  Email Delay
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("DaysTillFullThrottle");
                  }}
                >
                  Days Till Full Throttle
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Name");
                  }}
                >
                  Today's Potential Volume
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("SMTPSSLType");
                  }}
                >
                  SMTP Security
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("IMAPSSLType");
                  }}
                >
                  IMAP Security
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("StartTime");
                  }}
                >
                  Sending Schedule
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("IsEmailWarmingSending");
                  }}
                >
                  Email Warming Sending
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("IsEmailWarmingReceiving");
                  }}
                >
                  Email Warming Receiving
                </a>
              </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Rows?.length === 0 ? (
              <p className="text-center">No data available in table</p>
            ) : (
              Rows.map((row) => (
                <TableRow>
                  <TableCell>
                    <input
                      type="checkbox"
                      id="IsRemoveFromDeliverabilityTesting"
                      defaultChecked={
                        row.IsRemoveFromDeliverabilityTesting == true
                          ? true
                          : false
                      }
                      onChange={(e) => HandleChangeIsRemove(e, row?._id)}
                    />
                  </TableCell>
                  <TableCell>{row?.IsAuthenticated ? "Yes" : "No"}</TableCell>
                  <TableCell>
                    {row?.SMTPStatus ? "Active" : "Inactive"}
                  </TableCell>
                  <TableCell>
                    {row?.IMAPStatus ? "Active" : "Inactive"}
                  </TableCell>
                  <TableCell>{row?.ClientName}</TableCell>
                  <TableCell>
                    {row?.ClientStatusDetails?.ClientStatusName}
                  </TableCell>
                  <TableCell>{row?.Name}</TableCell>
                  <TableCell>{row.Email?.split("@")}</TableCell>
                  <TableCell>{row?.AliasTitle}</TableCell>
                  <TableCell>
                    <select
                      onChange={(e) => {
                        AccountTypeSelectID(e, row?._id);
                      }}
                      class="form-control  m-input"
                      id="AccountTypeID"
                      name="AccountTypeID"
                      autoComplete="off"
                      value={row.AccountTypeID}
                    >
                      {AccountTypeD1.map((value) => (
                        <option value={value.FieldID}>{value.FieldName}</option>
                      ))}
                    </select>
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={
                        <>
                          <span>
                            Last Week Email Sent :{row?.LastWeekEmailsSent}
                          </span>
                          <br />
                          <span>
                            Avg Emails Per Day : {row?.YesterDayEmailSent}
                          </span>
                          <br />
                          <span>
                            YesterDay Emails Sent : {row?.AvgEmailsPerDay}
                          </span>
                          <br />
                        </>
                      }
                      placement="right"
                    >
                      <span
                        onMouseEnter={() => {
                          TooltipHover(row?._id);
                        }}
                      >
                        <i className="la flaticon-plus delete-icon"></i>
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{row?.SMTPFromEmail}</TableCell>
                  <TableCell>{row?.SMTPEmail}</TableCell>
                  <TableCell>{row?.SMTPHost}</TableCell>
                  <TableCell>{row?.SMTPPort}</TableCell>
                  <TableCell>{row?.IMAPEmail}</TableCell>
                  <TableCell>{row?.IMAPHost}</TableCell>
                  <TableCell>{row?.IMAPPort}</TableCell>
                  <TableCell>{row?.ThrottleStartingVolume}</TableCell>
                  <TableCell>{row?.ThrottleEndingVolume}</TableCell>
                  {/* <TableCell>{row?.TodayPotentialVolume}</TableCell> */}
                  <TableCell>{row?.EmailDelay} </TableCell>
                  <TableCell>{row?.DaysTillFullThrottle}</TableCell>
                  <TableCell>{row?.CampaignMailSentTodaypotential}</TableCell>
                  <TableCell>{row?.SMTPSSLType}</TableCell>
                  <TableCell>{row?.IMAPSSLType}</TableCell>
                  <TableCell>
                    <TableCell>
                      {row.StartTime}{" "}
                      To{" "}
                      {row.EndTime}
                    </TableCell>
                  </TableCell>

                  <TableCell>
                    {row?.IsEmailWarmingSending == true ? "Yes" : "No"}
                  </TableCell>
                  <TableCell>
                    {row?.IsEmailWarmingReceiving == true ? "Yes" : "No"}
                  </TableCell>
                  <TableCell>
                    <a
                      onClick={() => {
                        EmailAccountsEdit(row._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i class="la flaticon-edit-1 edit-icon"></i>
                    </a>
                    <a
                      onClick={() => {
                        DeleteBtn(row._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i class="la flaticon-delete-1 delete-icon"></i>
                    </a>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      </div>

      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
