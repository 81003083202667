import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import Axios from "axios";
import { history } from "../../../_helpers";
//import CallHistory from '../_components/coldcalling/callhistory';
//import CollapsibleTable from '../_components/usertable';

import ReactApexchart from "react-apexcharts";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class AddTagPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      pager: {},
      pageOfItems: [],
      files: [],
      list: [],
      columns: [],
      reset: false,
      csvData: [],
      code: "",
      fields: {},
      errors: {},
      NameAvailable: null,
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      CUserID: Details.ChildUserID,
      Typelist: [],
      Typelist1: [],
      VariableTypeID: null,
      CustomVariable: {},
      IsExist: false,
    };
    this.GetDocmeeting();
    this.Getresearchteam();
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }
  componentDidMount() {}

  GetDocmeeting() {
    Axios({
      url: CommonConstants.MOL_APIURL + "/research/GetProjectStatus",
      method: "POST",
    }).then((res) => {
      // console.log(res.data.DocummentsStatus,"type");
      this.setState({ Typelist: res.data.ProjectStatus });
    });
  }

  Getresearchteam() {
    var data = {
      ClientID: this.state.ClientID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/research/GetResearchTeam",
      method: "POST",
      data: data,
    }).then((res) => {
      console.log(res, "bnjszfisfh");
      // console.log(res.data.DocummentsStatus,"type");
      this.setState({ Typelist1: res.data.researchStatus });
    });
  }

  FromValidation() {
    let formIsValid = true;
    if (Object.entries(this.state.fields).length > 0) {
      let fields = this.state.fields;
      let errors = {};

      if (!fields["ProjectName"]) {
        formIsValid = false;
        errors["ProjectName"] = "Please enter ProjectName";
      }
      if (this.state.NameAvailable != null) {
        formIsValid = false;
      }
      this.setState({ errors: errors });
    }
    return formIsValid;
  }

  // handlechange
  handleChange(field, e) {
    // document.getElementById("blank_error").style.display = "none";
    // console.log("textbox");
    console.log(e.target.value);
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.ProjectName != "") {
      this.state.errors.ProjectName = null;
      this.state.NameAvailable = null;
      this.CheckExistDocumentsTitle(e.target.value);
    } else {
      this.state.NameAvailable = null;
      this.state.errors.ProjectName = null;
    }
  }

  // check exit name
  CheckExistDocumentsTitle(ProjectName) {
    var str_in = {
      id: this.state.ClientID,
      ProjectName: ProjectName,
    };
    console.log(str_in);

    Axios({
      url: CommonConstants.MOL_APIURL + "/research/ResearchStatusExists",
      method: "POST",
      data: str_in,
    }).then((resdata) => {
      console.log(resdata);
      if (resdata.data.length > 0) {
        this.setState({ NameAvailable: resdata.data[0].ProjectName });
        console.log(this.state.NameAvailable);
        // this.setState({ IsExist: true });
      }
    });
  }

  backbtn() {
    history.push("/research");
  }
  savebtn() {
    var ProjectName = document.getElementById("ProjectName").value;
    var CompletedBy = document.getElementById("CompletedBy").value;
    var RequestedDate = document.getElementById("RequestedDate").value;
    var FinishedDate = document.getElementById("FinishedDate").value;
    var Hours = document.getElementById("Hours").value;
    var ProjectStatusID = document.getElementById("ProjectStatusID").value;
    var ContactsFound = document.getElementById("ContactsFound").value;
    var ResearchTeamID = document.getElementById("ResearchTeamID").value;
    // var OrderDate = document.getElementById("OrderDate").value;
    // console.log(MailType);
    // console.log(Quantity);
    // console.log(Spend);
    //   console.log(Description);
    // console.log(OrderDate);

    if (
      ProjectName == undefined ||
      CompletedBy == undefined ||
      RequestedDate == undefined ||
      FinishedDate == undefined ||
      Hours == undefined ||
      ProjectStatusID == undefined ||
      ContactsFound == undefined ||
      ResearchTeamID == undefined
    ) {
      // document.getElementById("blank_error").style.display = "block";
      console.log("Please enter name");
    } else {
      var data = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        ProjectName: ProjectName,
        CompletedBy: CompletedBy,
        RequestedDate: RequestedDate,
        FinishedDate: FinishedDate,
        Hours: Hours,
        ProjectStatusID: ProjectStatusID,
        ContactsFound: ContactsFound,
        ResearchTeamID: ResearchTeamID,
        IsDeleted: false,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
        LastUpdatedBy: null,
        LastUpdatedDate: null,
      };
      console.log(data);
      Axios({
        url: CommonConstants.MOL_APIURL + "/research/AddResearch",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success("Add Research successfully.", "Add Research Status");
          history.push("/research");
          console.log("successfull data status", res.data);
        } else {
          toast.error(res.data.Message);
        }
      });
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
            <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad float-left mb-0">Tag</h4>
                </div>
              </div>

              <div class="paddcols">
              <div className="row">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Tag</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        id="ProjectName"
                        name="ProjectName"
                        placeholder="Enter Tag"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
 
                 
                
              </div>
            </div>

              <div class="row py-4 ">
                <div class="col-lg-12 pull-left">
                  <a
                    id="submit"
                    onClick={this.savebtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </a>
                  <a
                    id="backtolist"
                    onClick={this.backbtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddDirectMailPage = connect(
  mapState,
  actionCreators
)(AddTagPage);
export { connectedAddDirectMailPage as AddTagPage };
