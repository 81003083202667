import React from "react";
import Axios from "axios";
import DatePicker from "react-datepicker";
const moment = require("moment");
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif";
toast.configure();

class AddMeetingGoalPage extends React.Component {
  constructor(props) {
    var Details = GetUserDetails();
    super(props);

    this.state = {
      SelectDate: "",
      ClientID: Details.ClientID,
      UserID: Details.ChildUserID,
      CUserID: Details.ParentUserID,
      Role: Details.Role,
      Fields: {},
      Errors: {},
      IsButtonDisabled:false,
      WeekStatus:false,
      GoalStatus:false,
      IsLoading: false,
    };

    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.SelectedDate = this.SelectedDate.bind(this);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
  }
  componentDidMount() {
    document.title = `Add Meeting Goal | SalesHive`;
    // document.getElementById("date").style.display = "none";
    // document.getElementById("goal_err").style.display = "none";
  }

  // back button
  BackBtn() {
    history.push("/meetinggoal");
  }

  // form validation
  // async FromValidation() {
  //  let Goal = document.getElementById("Goal").value.trim()
  //   let FormIsValid = true;
  //   let Errors = {...this.state.Errors};
  //   var IsExist = await this.CheckExistGoalName(this.state.SelectDate)
  //   console.log(Goal == "")
  //   if (Goal == "") {
  //     FormIsValid = false;
  //     Errors["Goal"] = "Please enter goal";
  //   }else{
  //     if (Goal >100000000) {
  //       FormIsValid = false;
  //       Errors["Goal"] = "Please enter valid input";
  //     }else{
  //       FormIsValid = true;
  //       Errors["Goal"] = "";
  //     }
  //   }
   
  //   if (this.state.SelectDate == "") {
  //     FormIsValid = false;
  //     Errors["Week"] = "Please select week";
  //   }else{
  //     FormIsValid = false;
  //     Errors["Week"] = "";
  //   }
  //   if(IsExist == true){
  //     FormIsValid = false;
  //   }
  //   console.log(Errors)
  //   this.setState({ Errors: Errors });
  //   return FormIsValid;
  // }

  handleDateBlur = async () => {
    let Errors = { ...this.state.Errors };
    if (this.state.SelectDate === "") {
      Errors['Week'] = "Please select a week";
      this.setState({ WeekStatus: false });
    } else {
      var IsExist = await this.CheckExistGoalName(this.state.SelectDate);
      if (IsExist) {
        Errors['Week'] = "Week already exists"; // Set a message for week existence
        this.setState({ WeekStatus: false });
      } else {
        this.setState({ WeekStatus: true });
        Errors['Week'] = ""; // Clear the error message
      }
    }
    this.setState({ Errors: Errors });
  };
  
  HandleChangeBlur = () => {
    let Goal = document.getElementById("Goal").value.trim();
    let Errors = { ...this.state.Errors };
    if (Goal === "") {
      Errors["Goal"] = "Please enter a goal";
      this.setState({ GoalStatus: false });
    } else {
      // Replace this logic with your desired validation criteria
      // Example: checking if it's a valid number
      if (isNaN(Goal) || parseInt(Goal) < 0) {
        Errors["Goal"] = "Please enter a valid positive number";
        this.setState({ GoalStatus: false });
      } else {
        this.setState({ GoalStatus: true });
        Errors["Goal"] = "";
      }
    }
    this.setState({ Errors: Errors });
  };

  //check exist name
async CheckExistGoalName(WeekData) {
  this.setState({IsLoading:true})
  let WeekDates=moment(new Date(WeekData)).format("MM/DD/YYYY");
  const dateFromWeekDates = moment(WeekDates);
  const formattedWeekDates = dateFromWeekDates.format('YYYY-MM-DD');
  var str_in = {
    ClientID: this.state.ClientID,
    Week: formattedWeekDates,
    Role: this.state.Role,
  };
  var resdata = await Axios({
    url:
      CommonConstants.MOL_APIURL +
      "/meetinggoal/CheckExistWeek",
    method: "POST",
    data: str_in,
  })

    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        let Errors = {...this.state.Errors};
        this.setState({ SelectDate: "" });
        toast.error(<div className="toastsize">Add Meeting Goal<br/>Goal already exist in selected week,select other week.</div>);
        Errors['Week'] ="";
        this.setState({Errors:Errors,WeekStatus:false})
        this.setState({IsLoading:false})
        return true;
      } else {
        this.setState({IsLoading:false})
        return false;
      }
    }else{
      this.setState({IsLoading:false})
    }
 
}

  // meeting goal save
  async SaveBtn(e) {
  this.setState({IsButtonDisabled:true});
  let todayweekday = document.getElementById("Week").value
    // var Final_flag = await this.FromValidation();
    if (this.state.WeekStatus === true && this.state.GoalStatus === true) {
      var Goal = document.getElementById("Goal").value.trim();
      var data = {
        Goal: Goal,
        Week: todayweekday,
        type: "User",
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
        CreatedBy: this.state.CUserID,
        CreatedDt: new Date(),
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/meetinggoal/MeetingGoalAdd",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {

          toast.success(<div>Add meeting goal<br/>
          Meeting goal added successfully.</div>);
          history.push("/meetinggoal");
        } else {
          toast.error(res.data.Message);
          this.setState({IsButtonDisabled:false});
        }
      });
    } else {
        let Errors = {...this.state.Errors};
        if(this.state.WeekStatus === false){
          Errors['Week'] = "Please select a week";
        }
        if(this.state.GoalStatus === false){
          Errors["Goal"] = "Please enter a goal";

        }
     this.setState({Errors:Errors})
    this.setState({IsButtonDisabled:false});

    }
  }

  // select date
  SelectedDate=async(date)=>{
    await this.setState({ SelectDate: date,WeekStatus:true });
    await this.CheckExistGoalName(date)
  }


  render() {
    return (
      <>
       {this.state.IsLoading ? (
          <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div>
        ) : (
          <></>
        )}
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col px-4">
                  <h4 className="headertitlepad float-left">
                    Add Meeting Goals
                  </h4>
                </div>
              </div>

            <div class="paddcols">
              <div className="row py-2 px-3">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-3 view-lable-meet">Week</label>
                    <div class="col-lg-8 datepickerfull">
                      <DatePicker
                        selected={
                          this.state.SelectDate == null ? "" : this.state.SelectDate
                        }
                        onChange={(date) => this.SelectedDate(date)}
                        dateFormat="MM/dd/yyyy"
                        name="startDate"
                        filterDate={(date) => date.getDay() === 1}
                        onBlur={this.handleDateBlur}
                        className="form-control m-input w-100"
                         id="Week"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Week"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-3 view-lable-meet">Goal</label>
                    <div class="col-lg-8">
                      <input
                        class="form-control m-input"
                        id="Goal"
                        name="LastName"
                        placeholder="Enter goal"
                        type="number"
                        onBlur={this.HandleChangeBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["Goal"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row mt-3">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.IsButtonDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <button
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </button>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddMeetingGoalPage = connect(
  mapState,
  actionCreators
)(AddMeetingGoalPage);
export { connectedAddMeetingGoalPage as AddMeetingGoalPage };
