import axios from 'axios';
import { CommonConstants } from "../_constants/common.constants";

//eMod AI 
const TokeneMod=process.env.REACT_APP_EMODAUTHTOKEN
const URLeMod=process.env.REACT_APP_EMODAUTHURL

export async function makeAxiosCall(payload)  {
  try {
    const headers = {
        "auth-token": TokeneMod,
        "Content-Type": "application/json"
      };
      const response = await axios.post(URLeMod, payload, { headers })
      if (response.status == 200) {
        //throw new Error(`HTTP error! Status: ${response.status}`);
        return response.data;
      }
  
      
  } catch (error) {
    console.error('API call failed:', error.message);
   // throw error; // Rethrow the error to handle it in the calling code if needed
    return null
  }
};